import {
    MODIFICANOMEROTANOHEADER, MODIFICAPESQUISATESTE, MODIFICABERTURAMENU
} from '../actions/types';

const INITIAL_STATE = {
    nomeRota: '',
    testePesquisa: false,
    abrirMenu: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICANOMEROTANOHEADER:
            return { ...state, nomeRota: action.payload };
        case MODIFICAPESQUISATESTE:
            return { ...state, testePesquisa: action.payload };
        case MODIFICABERTURAMENU:
            return { ...state, abrirMenu: action.payload };
        default:
            return state;
    }
};