import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { withRouter } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FlatList from 'flatlist-react';
import FlatListClientes from "./FlatListClientes";
import ReactTooltip from "react-tooltip";

import styled from 'styled-components';
import SelectSearch from 'react-select-search';
import { toastr } from 'react-redux-toastr';
import MoonLoader from "react-spinners/MoonLoader";
import Dropzone from 'react-dropzone';
import imgTeste from '../../imagens/userPadrao.png';
import imglink from '../../imagens/link.png'
import imgunlink from '../../imagens/unlink.png';
import imgexcluirCliente from '../../imagens/delete-user.png';
import ClipLoader from "react-spinners/ClipLoader";
import { modificaPesquisaTeste, modificaNomeRotaNoHeader } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    modificaFiltroClientesTelaClientes, alteraValorSwitch, modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro,
    modificaEmpresaClienteTelaCadastroCliente, modificaNomeClienteTelaCadastroCliente, modificaEnderecoTelaCAdastroCliente, modificaTelefoneTelaCAdastroCliente,
    
    mudaVisibilidadeModalExcluirCliente, alteraEmpresaSelecionadoTelaAlterarCliente, modificaNomeClienteSelecionadoTelaAlterarCliente,
    modificaVisibleModalNovoCLiente, consultaCliente2, consultaClienteComCliente,
    modificaClienteSelecionadoTelaConsultaCliente, modificaNomeClienteSelecionadoTelaConsultaCliente,
   insereFotoAlterarCliente, modificaArquivoCompletoParaUpar, zeraInformacoesCadastroClienteExport,

    modificaEmpresaClienteTelaCadastroClienteNovoCadastro, modificaNomeClienteTelaCadastroClienteNovoCadastro, modificaEnderecoTelaCAdastroClienteNovoCadastro, modificaTelefoneTelaCAdastroClienteNovoCadastro, alteraValorSwitchNovoCadastro,
    modificaEmailClienteTelaCadastroClienteNovoCadastro, modificaSenhaClienteTelaCadastroClienteNovoCadastro, modificaConfirmaClienteTelaCadastroClienteNovoCadastro,
    insereFotoCadastroCliente, modificaArquivoCompletoParaUparNovoCadastro, alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro,
    modificaRetornaTelaClienteParaNovoAtendimento
} from "../../actions/ChamadoAction";

import { modificaNomeClienteTelaPerfil, insereFotoAlterarUsuario,alteraPerfil,modificaEmailClienteTelaCadastroCliente,
    modificaSenhaClienteTelaCadastroCliente,modificaConfirmaClienteTelaCadastroCliente } from "../../actions/PerfilAction";

import {
    ContainerDadosCliente, Container, IconeSair, ContainerInputSearch, SubContainer, SubContainerCliente,
    ContainerImagemAlterarCliente, ContainerPrincipalDadosCliente, InputEmpresa, SubContainerClientePermissao,
    ContainerPermitir, TextPermitir, ContainerDadosLoginCliente, ContainerBotaoAlterar, ButtonEntrar,
    DivIconeExcluirCliente, IconeExcluirCliente, DivContainerInputSenha, IconeMostrarSenha,
    IconeEmpresaVinculada, DivContainerInputEmpresaVinculada, ContainerModal, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerImagemVincularEmpresa, ContainerExplicacaoVincular,
    ButtonVincular, TextVincular, ImagePerfil, IconeEmpresaVinculadaOff, ContainerModalNovoCliente,
    ContainerDadosNovoCliente,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,

    ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa, ImageLoading, ContainerChamadoLoading, TextClienteLoading, ImagemPerfilLoading,
    InputLoadingEmpresa, ContainerDadosClienteLoading, SubContainerClientePermissaoLoading, ContainerListaVazia
} from './styled';


class Clientes extends React.Component {
    state = {

        filtroTeste: false,
        hasMoreItems: false,
        modalDesvinculaCliente: false,
        offset: 0,
        fotoCliente: imgTeste,
        search: '',
        inputFicticio: '',
        checkFicticio: false,
        maisDados: [],
        tipoInputSenha: 'password',
        tipoInputConfirmaSenha: 'password',
        loading: true,
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        pesquisaCliente: false,
        pesquisaClienteNovoCadastro: '',
        modalNovoCliente: false,
        modalPesquisaCliente: false,
        testeEmpresas: [
            { value: 'Hera', name: 'Hera' },
            { value: 'Mr Automac', name: 'Mr Automac' },
            { value: 'Autocom', name: 'Autocom' },
        ],

    }
  
    componentDidMount() {

        ReactTooltip.rebuild();
        if (this.props.nomeRota !== 'Perfil') {
            this.props.modificaNomeRotaNoHeader('Perfil')
        }
        /*  let EmpresaVinculada;
         if (this.props.clienteEmpresaPrincipal) {
             EmpresaVinculada = false;
         } else {
             if (this.props.empresaSelecionadasTelaAlterarCliente.length == 0) {
                 EmpresaVinculada = false;
             } else {
                 EmpresaVinculada = true;
             }
         }
         //EmpresaVinculada = (this.props.clienteSelecionadoConsultaClienteTelaCAdastraCliente.length == 0) ? false : true;
         this.setState({ pesquisaCliente: EmpresaVinculada })
         console.log(EmpresaVinculada) */

    }
    componentWillReceiveProps(nextProps) {
    }
    _toggleMostrarSenha() {
        this.setState({ visaoInputSenha: !this.state.visaoInputSenha });
    }
    _toggleSectionedPesquisaCliente() {
        this.setState({ pesquisaCliente: !this.state.pesquisaCliente });
    }
    _toggleSectionedPesquisaClienteNovoCadastro() {
        this.setState({ pesquisaClienteNovoCadastro: !this.state.pesquisaClienteNovoCadastro });
    }

    _toggleModalPesquisarCliente() {
        this.setState({ modalPesquisaCliente: !this.state.modalPesquisaCliente });
    }
    _toggleModalDesvincularCliente() {
        this.setState({ modalDesvinculaCliente: !this.state.modalDesvinculaCliente });
    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleVisualizaConfirmaSenha(value) {
        let state = this.state;
        state.tipoInputConfirmaSenha = value;
        this.setState({ state });
    }
    handleModalNovoCliente(value) {
        let state = this.state;
        state.modalNovoCliente = value;
        this.setState({ state });
    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    renderAreaEmailSenha() {
        if (this.props.valorSwitch) {
            return (
                <ContainerDadosLoginCliente>
                    {/* <div> */}
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12 }}>Email<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <InputEmpresa

                        type="text"
                        placeholder="Email"
                        colorDinamica={this.props.coresLetraPadrao}
                        placeholderDinamico={this.props.coresLetraSecundario}
                        borderDinamico={this.props.corBotaoPadrao}
                        value={this.props.emailCliente}
                        onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                    //  onFocus={() => { this.handleInputEmailFocus() }}
                    // onBlur={() => { this.handleInputEmailBlur() }}
                    />
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputSenha}
                            placeholder="Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.senhaCliente}
                            onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                    <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 5 }}>Confirmar Senha<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></p>
                    <DivContainerInputSenha>
                        <InputEmpresa

                            type={this.state.tipoInputConfirmaSenha}
                            placeholder="Confirma Senha"
                            colorDinamica={this.props.coresLetraPadrao}
                            placeholderDinamico={this.props.coresLetraSecundario}
                            borderDinamico={this.props.corBotaoPadrao}
                            value={this.props.confirmaSenhaCliente}
                            onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                        //  onFocus={() => { this.handleInputEmailFocus() }}
                        // onBlur={() => { this.handleInputEmailBlur() }}
                        />
                        {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                        {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                    </DivContainerInputSenha>
                </ContainerDadosLoginCliente>
            )
        } else {
            return <ContainerDadosLoginCliente></ContainerDadosLoginCliente>
        }
    }
    
    renderIconeEmpresaModal() {

        if (this.state.pesquisaClienteNovoCadastro == false) {

            return (
                <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
            )
        }

        else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    renderIconeEmpresa() {

        if (this.state.pesquisaCliente == false) {
            if (this.props.clienteEmpresaPrincipal) {
                return (
                    <IconeEmpresaVinculadaOff data-tip="Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra" data-for="tip-top" colorDinamica='#9fadb7' className="icon-hotel" onClick={() => { toastr.warning('Atenção', 'Há Clientes Vinculados á essa Empresa, portanto essa Empresa NÃO pode ser Vinculada á nenhuma outra') }} />
                )
            } else {
                return (
                    <IconeEmpresaVinculada data-tip="Vincular este cliente á uma empresa já cadastrada" data-for="tip-top" colorDinamica={this.props.corBotaoPadrao} className="icon-hotel" onClick={() => { this._toggleModalPesquisarCliente() }} />
                )
            }

        } else {
            return (
                <IconeEmpresaVinculada style={{ marginLeft: 5 }} data-tip="Desvincular Cliente" data-for="tip-top" colorDinamica="red" className="icon-close" onClick={() => { this._toggleModalDesvincularCliente() }} />
            )
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.alteraPerfil(this.props.empresa, this.props.nome, this.props.foto, this.props.emailClienteTelaPerfil, this.props.senha, this.props.confirmaSenha,this.props.arquivoCompletoParaUpar)
        /*   this.props.alteraCliente(this.props.nomeEmpresaCliente, this.props.nomeCliente, this.props.emailCliente, this.props.valorSwitch, this.props.senhaCliente, this.props.confirmaSenhaCliente, this.props.fotoImagemClienteTelaAlterar,
              this.props.keyCliente, this.state.pesquisaCliente, this.props.empresaSelecionadasTelaAlterarCliente,
              this.props.nomeEmpresaSelecionadoConsultaTelaAlterarCliente, this.props.clienteEmpresaPrincipal, this.props.telefoneCliente, this.props.enderecoCliente, this.props.arquivoCompletoParaUpar) */
    }
    alterarClienteLocal(e) {
        e.preventDefault();
    }
 
    removeFiltrosAll() {
        this.props.modificaClienteSelecionadoTelaConsultaCliente([]);
        this.props.modificaNomeClienteSelecionadoTelaConsultaCliente('');
        this.props.modificaFiltroClientesTelaClientes('')
        this.props.consultaCliente2(0, '', []);
    }
    exibeUltimosClientes() {
        /* console.log('dentro do exibe os ultimos clientes') */
        if (this.props.loadingTelaConsultaCliente == false) {
            if (this.props.nomeClienteSelecionadoTelaConsultaCliente == '') {
                this.props.consultaCliente2(1, this.props.referenciaUltimaKeyTelaConsultaCliente, this.props.clientes);
            }
        }
    }
    escolheArquivoChamado(res) {
        if (res.length > 0) {
           /*  console.log(res) */
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            /* console.log(res) */
            this.props.modificaArquivoCompletoParaUpar(res[0])
            this.props.insereFotoAlterarUsuario(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    escolheArquivoChamadoNovoCadastro(res) {
        if (res.length > 0) {
           /*  console.log(res) */
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            /* console.log(res) */
            this.props.modificaArquivoCompletoParaUparNovoCadastro(res[0])
            this.props.insereFotoCadastroCliente(res[0].uri);
        } else {
            toastr.error('Error ao Anexar', 'Selecione uma foto com no máximo 5mb');

        }
    }
    renderImagem() {
        if (this.props.foto == null) {
            return (
                <div style={{ height: 150, width: 150, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <Dropzone
                    accept="image/*"
                    multiple={false}
                    noKeyboard
                    maxSize={5e+6}
                    noDrag
                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                    {({ getRootProps, getInputProps, isDragActive }) => {

                        return (
                            /*     <section> */
                            <div  {...getRootProps()}>
                                <input {...getInputProps()} />
                                <ImagePerfil bordaDinamico={this.props.corBotaoPadrao} disabled={this.props.loadingBotaoAlterarCliente} src={this.props.foto} alt="foto" style={{}} />
                            </div>
                            /*   </section> */
                        )
                    }}
                </Dropzone>
            )
        }
    }
    renderLoadingClientes() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;
        if (this.props.loadingTelaConsultaCliente) {
            return (
                <Container>


                    <ContainerPrincipalDadosCliente backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerCliente>
                            <ContainerImagemAlterarCliente>
                                <ImagemPerfilLoading backgroundinamico={this.props.backgroundSegundo}></ImagemPerfilLoading>
                                {/*   <ImagePerfil onClick={() => { alert('trocar imagem') }} src={this.state.fotoCliente} alt="foto cliente" style={{}} /> */}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosClienteLoading>

                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                                <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>

                            </ContainerDadosClienteLoading>
                        </SubContainerCliente>
                        <SubContainerClientePermissaoLoading>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                            <InputLoadingEmpresa backgroundinamico={this.props.backgroundSegundo}></InputLoadingEmpresa>
                        </SubContainerClientePermissaoLoading>
                    </ContainerPrincipalDadosCliente>

                </Container>
            )
        } else {
            return (
                <Container>

                    <ContainerPrincipalDadosCliente onSubmit={(e) => this.handleSubmit(e)} backGroundDinamico={this.props.backgroundSegundo}>

                        <SubContainerCliente>

                            <ContainerImagemAlterarCliente>
                                {this.renderImagem()}
                            </ContainerImagemAlterarCliente>
                            <ContainerDadosCliente>


                                {/*  </div> */}
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Nome: </p>
                                <InputEmpresa
                                    disabled={this.props.loadingBotaoAlterarCliente}
                                    type="text"
                                    placeholder="Nome"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.nome}
                                    onChange={(e) => { this.props.modificaNomeClienteTelaPerfil(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Email: </p>
                                <InputEmpresa

                                    type="text"
                                    placeholder="Email"
                                    colorDinamica={this.props.coresLetraPadrao}
                                    placeholderDinamico={this.props.coresLetraSecundario}
                                    borderDinamico={this.props.corBotaoPadrao}
                                    value={this.props.emailClienteTelaPerfil}
                                    onChange={(e) => { this.props.modificaEmailClienteTelaCadastroCliente(e.target.value) }}
                                //  onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Senha:</p>
                                <DivContainerInputSenha>
                                    <InputEmpresa

                                        type={this.state.tipoInputSenha}
                                        placeholder="Senha"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.senha}
                                        onChange={(e) => { this.props.modificaSenhaClienteTelaCadastroCliente(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />
                                    {this.state.tipoInputSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                                    {this.state.tipoInputSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                                </DivContainerInputSenha>
                                <p style={{ color: this.props.corLetraDescricaoDosCampos, fontWeight: 'bold', fontSize: 12, marginTop: 15 }}>Confirmar Senha: </p>
                                <DivContainerInputSenha>
                                    <InputEmpresa

                                        type={this.state.tipoInputConfirmaSenha}
                                        placeholder="Confirma Senha"
                                        colorDinamica={this.props.coresLetraPadrao}
                                        placeholderDinamico={this.props.coresLetraSecundario}
                                        borderDinamico={this.props.corBotaoPadrao}
                                        value={this.props.confirmaSenha}
                                        onChange={(e) => { this.props.modificaConfirmaClienteTelaCadastroCliente(e.target.value) }}
                                    //  onFocus={() => { this.handleInputEmailFocus() }}
                                    // onBlur={() => { this.handleInputEmailBlur() }}
                                    />
                                    {this.state.tipoInputConfirmaSenha == 'password' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('text') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-semvisao" />}
                                    {this.state.tipoInputConfirmaSenha == 'text' && < IconeMostrarSenha onClick={() => { this.handleVisualizaConfirmaSenha('password') }} colorDinamico={this.props.coresLetraPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-comvisao" />}
                                </DivContainerInputSenha>
                            </ContainerDadosCliente>

                        </SubContainerCliente>
                        <ContainerBotaoAlterar>
                            {this.props.loadingBotaoAlterarCliente == false &&
                                <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="submit" onClick={(e) => this.handleSubmit(e)}>
                                    Alterar
                                   </ButtonEntrar>}
                            {this.props.loadingBotaoAlterarCliente == true &&
                                <ClipLoader
                                    css={{ marginBottom: 10 }}
                                    size={35}
                                    color={this.props.corBotaoPadrao}
                                    loading={this.props.loadingBotaoAlterarCliente}
                                />}
                        </ContainerBotaoAlterar>

                    </ContainerPrincipalDadosCliente>
                </Container>
            )
        }
    }

    render() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;
        return (
            <>
                {this.renderLoadingClientes()}

            </>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,

            chamados: state.ChamadoReducer.chamados,
            verificaConexaoInternetListaChamadoAberto: state.ChamadoReducer.verificaConexaoInternetListaChamadoAberto,
            loadingTelaChamadoAberto: state.ChamadoReducer.loadingTelaChamadoAberto,
            refreshControlFlatListChamadoAberto: state.ChamadoReducer.refreshControlFlatListChamadoAberto,
            referenciaUltimaKey: state.ChamadoReducer.referenciaUltimaKey,
            loadingFimDaPagina: state.ChamadoReducer.loadingFimDaPagina,
            premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            //  contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionado: state.ChamadoReducer.filtroSelecionado,
            filtroClientes: state.ChamadoReducer.filtroClientes,
            clientesSectionedConsulta: state.ChamadoReducer.clientesSectionedConsulta,
            nomeClienteSelecionadoTelaCadastroCLiente: state.ChamadoReducer.nomeClienteSelecionadoTelaCadastroCLiente,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            fotoImagemClienteTelaAlterar: state.ChamadoReducer.fotoImagemClienteTelaAlterar,
            nomeEmpresaCliente: state.ChamadoReducer.nomeEmpresaCliente,
            nomeCliente: state.ChamadoReducer.nomeCliente,
            enderecoCliente: state.ChamadoReducer.enderecoCliente,
            telefoneCliente: state.ChamadoReducer.telefoneCliente,
            emailCliente: state.ChamadoReducer.emailCliente,
            senhaCliente: state.ChamadoReducer.senhaCliente,
            confirmaSenhaCliente: state.ChamadoReducer.confirmaSenhaCliente,
            mensagemerrorAoCadastrarClienteTelaAlterar: state.ChamadoReducer.mensagemerrorAoCadastrarClienteTelaAlterar,
            loadingBotaoAlterarCliente: state.ChamadoReducer.loadingBotaoAlterarCliente,
            valorSwitch: state.ChamadoReducer.valorSwitch,
            backgroundMensagemErrorTelaAlterarCliente: state.ChamadoReducer.backgroundMensagemErrorTelaAlterarCliente,
            keyCliente: state.ChamadoReducer.keyCliente,
            visibleModalExcluirCliente: state.ChamadoReducer.visibleModalExcluirCliente,
            clienteEmpresaPrincipal: state.ChamadoReducer.clienteEmpresaPrincipal,
            empresaSelecionadasTelaAlterarCliente: state.ChamadoReducer.empresaSelecionadasTelaAlterarCliente,
            modalNovoCliente: state.ChamadoReducer.modalNovoCliente,
            loadingBotaoCadastrarCliente: state.ChamadoReducer.loadingBotaoCadastrarCliente,
            clientes: state.ChamadoReducer.clientes,
            inputDigitoConsulta: state.ChamadoReducer.inputDigitoConsulta,
            mensagemSucessoAoCadastrarClienteTelaAlterar: state.ChamadoReducer.mensagemSucessoAoCadastrarClienteTelaAlterar,
            verificaInternetConsultaCliente: state.ChamadoReducer.verificaInternetConsultaCliente,
            loadingTelaConsultaCliente: state.ChamadoReducer.loadingTelaConsultaCliente,
            referenciaUltimaKeyTelaConsultaCliente: state.ChamadoReducer.referenciaUltimaKeyTelaConsultaCliente,
            loadingFimDaPAginaTelaConsultaCliente: state.ChamadoReducer.loadingFimDaPAginaTelaConsultaCliente,
            loadingCarregarClientesSectionedConsultaCliente: state.ChamadoReducer.loadingCarregarClientesSectionedConsultaCliente,
            clienteSelecionadoTelaConsultaCliente: state.ChamadoReducer.clienteSelecionadoTelaConsultaCliente,
            nomeClienteSelecionadoTelaConsultaCliente: state.ChamadoReducer.nomeClienteSelecionadoTelaConsultaCliente,
            refreshControlConsultaCliente: state.ChamadoReducer.refreshControlConsultaCliente,
            nomeEmpresaSelecionadoConsultaTelaAlterarCliente: state.ChamadoReducer.nomeEmpresaSelecionadoConsultaTelaAlterarCliente,
            listaEmpresasTelaAlterarCliente: state.ChamadoReducer.listaEmpresasTelaAlterarCliente,
            uriImagemPadraoTelaCadastroCliente: state.ChamadoReducer.uriImagemPadraoTelaCadastroCliente,
            arquivoCompletoParaUpar: state.ChamadoReducer.arquivoCompletoParaUpar,


            nomeEmpresaClienteNovoCadastro: state.ChamadoReducer.nomeEmpresaClienteNovoCadastro,
            nomeClienteNovoCadastro: state.ChamadoReducer.nomeClienteNovoCadastro,
            enderecoClienteNovoCadastro: state.ChamadoReducer.enderecoClienteNovoCadastro,
            telefoneClienteNovoCadastro: state.ChamadoReducer.telefoneClienteNovoCadastro,
            emailClienteNovoCadastro: state.ChamadoReducer.emailClienteNovoCadastro,
            senhaClienteNovoCadastro: state.ChamadoReducer.senhaClienteNovoCadastro,
            confirmaSenhaClienteNovoCadastro: state.ChamadoReducer.confirmaSenhaClienteNovoCadastro,
            valorSwitchNovoCadastro: state.ChamadoReducer.valorSwitchNovoCadastro,
            empresaSelecionadasTelaCadastraCliente: state.ChamadoReducer.empresaSelecionadasTelaCadastraCliente,
            arquivoCompletoParaUparNovoCadastro: state.ChamadoReducer.arquivoCompletoParaUparNovoCadastro,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,

            nome: state.PerfilReducer.nome,
            foto: state.PerfilReducer.foto,
            empresa: state.PerfilReducer.empresa,
            loadingBotaoAlterarPerfil: state.PerfilReducer.loadingBotaoAlterarPerfil,
            mensagemErrorAlterarPerfil: state.PerfilReducer.mensagemErrorAlterarPerfil,
            premium: state.PremiumReducer.premium,
            senha: state.PerfilReducer.senha,
            email: state.PerfilReducer.email,
            emailClienteTelaPerfil: state.PerfilReducer.emailClienteTelaPerfil,
            confirmaSenha: state.PerfilReducer.confirmaSenha,
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    modificaFiltroClientesTelaClientes, alteraValorSwitch,
    modificaEmpresaClienteTelaCadastroCliente, modificaNomeClienteTelaCadastroCliente, modificaEnderecoTelaCAdastroCliente, modificaTelefoneTelaCAdastroCliente,
    modificaEmailClienteTelaCadastroCliente, modificaSenhaClienteTelaCadastroCliente, modificaConfirmaClienteTelaCadastroCliente,
    mudaVisibilidadeModalExcluirCliente, alteraEmpresaSelecionadoTelaAlterarCliente, modificaNomeClienteSelecionadoTelaAlterarCliente,
    modificaVisibleModalNovoCLiente, modificaNomeRotaNoHeader, consultaCliente2, consultaClienteComCliente,
    modificaClienteSelecionadoTelaConsultaCliente, modificaNomeClienteSelecionadoTelaConsultaCliente,
 insereFotoAlterarCliente, modificaArquivoCompletoParaUpar, zeraInformacoesCadastroClienteExport,
    modificaEmpresaClienteTelaCadastroClienteNovoCadastro, modificaNomeClienteTelaCadastroClienteNovoCadastro, modificaEnderecoTelaCAdastroClienteNovoCadastro, modificaTelefoneTelaCAdastroClienteNovoCadastro, alteraValorSwitchNovoCadastro,
    modificaEmailClienteTelaCadastroClienteNovoCadastro, modificaSenhaClienteTelaCadastroClienteNovoCadastro, modificaConfirmaClienteTelaCadastroClienteNovoCadastro,
    insereFotoCadastroCliente, modificaArquivoCompletoParaUparNovoCadastro, alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro, modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro,
    modificaRetornaTelaClienteParaNovoAtendimento, modificaNomeClienteTelaPerfil, insereFotoAlterarUsuario,alteraPerfil
})(withRouter(Clientes));
