import firebase from '../servicos/FirebaseConnection';
import _ from 'lodash';
import moment from "moment";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { toastr } from 'react-redux-toastr';
import api from '../http_client/http.client';

import {
    MODIFICAEMAILLOGIN, MODIFICASENHALOGIN, MODIFICACONFIRMASENHA, MODIFICACOMPANIA, MODIFICANOME,
    MENSAGEMCADASTRAEMAILERROR, ZERAINFORMACOESNOMECOMPANIACONFIRMASENHA, MENSAGEMCADASTRAEMAILSUCESSO,
    ATIVALOADINGBOTAOCADASTRARTELACADASTRARCOMPANIA, MENSAGEMLOGAREMAILERROR, ATIVALOADINGBOTAOLOGARTELALOGIN,
    DESATIVALOADINGBOTAOLOGARTELALOGIN, ZERAMENSAGEMDESUCRSSO, LOGINSEMINTERNET,
    SEMINTERNETCADASTROUSUARIO, ZERAINFORMACOESLOGIN, VERIFICAINTERNETESQUECEUSENHA, ATIVADESATIVALOADINGBOTAOESQUECEUSENHA,
    MODIFICACORBORDAEMAILLOGIN, MODIFICACORBORDASENHALOGIN, MODIFICAERRORSENHALOGIN, MODIFICACORICONEEMAILLOGIN,
    MODIFICACORICONESENHALOGIN,
    MODIFICACORICONEINPUTEMPRESACADASTRO, MODIFICACORBORDAEMPRESACADASTRO,
    MODIFICACORICONEINPUTEMAILCADASTRO, MODIFICACORBORDAEMAILCADASTRO,
    MODIFICACORICONENOMECADASTRO, MODIFICACORBORDANOMECADASTRO,
    MODIFICACORICONESENHACADASTRO, MODIFICACORBORDASENHACADASTRO,
    MODIFICACORICONECONFIRMASENHACADASTRO, MODIFICACORBORDACONFIRMASENHACADASTRO,
    MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO, MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO,
    MODIFICAMENSAGEMERRORINPUTNOMECADASTRO, MODIFICAMENSAGEMERRORINPUTSENHACADASTRO,
    MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO, MODIFICACHECKBOXCONTINUARCONECTADO,
    MODIFICAMODALESQUECISENHA, ENVIASEATENDENTEEHADMINISTRADOR, ENVIAPERMISSOESACESSARTELASATENDENTE,
    ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, MODIFICACODIGOREFERENCIA,
    MODIFICACORICONEINPUTCODIGOREFERENCIACADASTRO, MODIFICACORBORDAINPUTCODIGOREFERENCIACADASTRO,
    MODIFICAMENSAGEMERRORINPUTCODREFERENCIA, MODIFICAISADMIN,UPDATECODEREFERENCE
} from './types';



export const esqueciSenha = (email2) => {
    return dispatch => {
        const email = email2.trim();
        ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, true);
        if (email === '') {
            toastr.error('Error', 'Digite o email.');
            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
        } else {
            firebase.auth().sendPasswordResetEmail(email)
                .then(function (user) {
                    toastr.success('Instrução enviada', 'Instrução para redefinir a senha foi enviada para seu email, por favor verifique seu email.');
                    //    alert('Instrução para redefinir a senha foi enviada para seu email, por favor verifique seu email.');
                    dispatch({ type: MODIFICAMODALESQUECISENHA, value: false })
                    ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                }).catch(function (error) {
                    switch (error.code) {
                        case 'auth/invalid-email':
                            toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                            break;
                        case 'auth/email-already-in-use':
                            toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                            break;
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                            break;
                        case 'auth/user-not-found':
                            toastr.error('Error', 'Email inexistente, por favor verifique.');
                            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            ativaDesativaLoadingBotaoEsqueceuSenha(dispatch, false);
                            break;
                    }
                })

        }
    }
}
export const logarUsuario = (email2, senha2, history, conectarAutomaticamente) => {
    const email = email2.trim();
    const senha = senha2.trim();
    /*  console.log(email, senha) */
    return dispatch => {
        let emailValido = validarEmail(email);
        /*  console.log(emailValido) */
        if (emailValido == false && senha === '') {
            modificaErrorLoginTelaLoginLocal(dispatch, 'Digite um e-mail válido');
            modificaCorIconeInputEmailLoginLocal(dispatch, 'red');
            modificaCorBordaInputEmailLoginLocal(dispatch, 'red');
            modificaErrorSenhaLoginLocal(dispatch, 'Digite sua senha')
            modificaCorIconeInputSenhaLoginLocal(dispatch, 'red');
            modificaCorBordaInputSenhaLoginLocal(dispatch, 'red');
        }
        else if (emailValido == false) {
            /*   console.log('Digite um e-mail válido'); */
            modificaErrorLoginTelaLoginLocal(dispatch, 'Digite um e-mail válido');
            modificaCorIconeInputEmailLoginLocal(dispatch, 'red');
            modificaCorBordaInputEmailLoginLocal(dispatch, 'red');

        } else if (senha.trim() === '') {
            /*  console.log('Digite sua senha'); */
            modificaErrorSenhaLoginLocal(dispatch, 'Digite sua senha')
            modificaCorIconeInputSenhaLoginLocal(dispatch, 'red');
            modificaCorBordaInputSenhaLoginLocal(dispatch, 'red');

        } else if (senha.length < 6) {
            /*   console.log('Digite uma senha maior que 6 digitos'); */
            modificaErrorSenhaLoginLocal(dispatch, 'Digite uma senha com mais de 6 digitos')
            modificaCorIconeInputSenhaLoginLocal(dispatch, 'red');
            modificaCorBordaInputSenhaLoginLocal(dispatch, 'red');
        } else {
            /*  console.log('no else'); */
            //info
            //success
            //error
            //warning
            ativaLoadingBotaoLogarTelaLogin(dispatch);

            /*   console.log('antes do auth') */
            firebase.auth().signInWithEmailAndPassword(email.trim(), senha.trim()).then(
                async (user) => {
                    
                    let useruid = returnUidUserLogged(user)
                    firebase.database().ref(`usuariosALL/${useruid}`).once('value').then(async (snapshot) => {

                        if (snapshot.val()) {
                            /*  console.log('sucesso') */
                            const dadosUsuario = _.first(_.values(snapshot.val()));
                            /*   console.log(dadosUsuario.admin) */
                            //se quem tiver logando for admin, pode logar normalmente, caso seja cliente vai para o else
                            if (dadosUsuario.admin == 0) {
                                AsyncStorage.setItem(`chaveUnicaCliente`, JSON.stringify(dadosUsuario.chaveUnica));
                                const token = await user.user.getIdToken(true);
                                await loginServer(token, user.user);
                                modificaIsAdmin(dispatch, false);
                                const arrayEmailSenha = [{
                                    key: email,
                                    email,
                                    senha
                                }];
                                /*     console.log(dadosUsuario.chaveUnica) */
                                firebase.database().ref(`${dadosUsuario.chaveUnica}/clientes/`).orderByChild('email').equalTo(email).once('value').then((snapshot2) => {
                                    let keyUsuario = Object.keys(snapshot2.val()).toString();
                                    /*  console.log(keyUsuario) */
                                    firebase.database().ref(`${dadosUsuario.chaveUnica}/clientes/${keyUsuario}`).update({ senha: senha }).then(
                                        () => {


                                            AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                            desativaLoadingBotaoLogarTelaLogin(dispatch);
                                            history.push('/atendimentos');
                                            /*  console.log('final') */
                                        })
                                })
                            } else {
                                modificaIsAdmin(dispatch, true);
                                /*  console.log('é administrador') */
                                toastr.error('Error', 'Essa conta é uma conta atendente, por favor entre pelo www.dropdesk.com.br');
                                firebase.auth().signOut();
                                desativaLoadingBotaoLogarTelaLogin(dispatch);
                            }
                        } else {
                            modificaIsAdmin(dispatch, false);
                            /*    console.log('sem dados') */
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                }
            ).catch((error) => {
                console.log('no catch')
                console.log(error)
                switch (error.code) {
                    case 'auth/user-disabled':
                        toastr.error('Error', 'Sem permissão para acessar, entre em contato com o seu Atendente!');
                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                        break;
                    case 'auth/invalid-email':
                        toastr.error('Error', 'Email inválido.');
                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                        break;
                    case 'auth/wrong-password':
                        toastr.error('Error', 'Email ou senha incorretos..');
                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                        break;
                    case 'auth/network-request-failed':
                        toastr.error('Error', 'Sem conexão com a internet');
                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                        break;
                    default:
                        toastr.error('Error', 'Email ou senha incorretos..');
                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                        break;
                }
            })



        }


    }
}
export const cadastrarUsuarioCliente = (email2, senha2, confirmarSenha2, empresa2, nome2, codigoReferencia3, history) => {
    /*     console.log(email2, senha2, confirmarSenha2, empresa2, nome2) */
    const email = email2.trim();
    const senha = senha2.trim();
    const confirmarSenha = confirmarSenha2.trim();
    const empresa = empresa2.trim();
    const nome = nome2.trim();
    const nomePesquisa = nome2.trim().toLowerCase();
    let emailValido = validarEmail(email);
    let codigoReferencia2 = codigoReferencia3.trim();

    let dataAtual = retornaDataAtual();
    let dataBonusPremium = moment(dataAtual, 'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY');
    return dispatch => {
        if (codigoReferencia2.trim() === '') {
            dispatch({ type: MODIFICACORBORDAINPUTCODIGOREFERENCIACADASTRO, payload: 'red' })
            dispatch({ type: MODIFICACORICONEINPUTCODIGOREFERENCIACADASTRO, payload: 'red' })
            dispatch({ type: MODIFICAMENSAGEMERRORINPUTCODREFERENCIA, payload: 'Digite o código de referência' })

        }
        else if (empresa.trim() === '') {
            modificaCorIconeInputEmpresaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputEmpresaCadastroLocal(dispatch, 'red');
            modificaMensagemErrorInputEmpresaCadastroLocal(dispatch, 'Digite o nome da sua Empresa');
        }
        else if (empresa.trim().length < 2) {
            modificaCorIconeInputEmpresaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputEmpresaCadastroLocal(dispatch, 'red');
            modificaMensagemErrorInputEmpresaCadastroLocal(dispatch, 'Sua empresa deve possuir mais de 1 letra');
        }
        else if (email.trim() === '') {
            modificaMensagemErrorInputEmailCadastroLocal(dispatch, 'Digite seu e-mail');
            modificaCorIconeInputEmailCadastroLocal(dispatch, 'red');
            modificaCorBordaInputEmailCadastroLocal(dispatch, 'red');

        } else if (emailValido == false) {
            modificaMensagemErrorInputEmailCadastroLocal(dispatch, 'Digite um e-mail válido');
            modificaCorIconeInputEmailCadastroLocal(dispatch, 'red');
            modificaCorBordaInputEmailCadastroLocal(dispatch, 'red');
        }
        else if (nome.trim() === '') {
            modificaMensagemErrorInputNomeCadastroLocal(dispatch, 'Digite seu nome');
            modificaCorIconeInputNomeCadastroLocal(dispatch, 'red');
            modificaCorBordaInputNomeCadastroLocal(dispatch, 'red');
        }
        else if (nome.trim().length < 2) {
            modificaMensagemErrorInputNomeCadastroLocal(dispatch, 'Seu nome deve possuir mais de 1 letra');
            modificaCorIconeInputNomeCadastroLocal(dispatch, 'red');
            modificaCorBordaInputNomeCadastroLocal(dispatch, 'red');
        }
        else if (senha.trim() === '') {
            modificaMensagemErrorInputSenhaCadastroLocal(dispatch, 'Digite sua senha')
            modificaCorIconeInputSenhaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputSenhaCadastroLocal(dispatch, 'red');
        }
        else if (senha.trim().length < 6) {
            modificaMensagemErrorInputSenhaCadastroLocal(dispatch, 'Digite uma senha com mais de 6 digitos')
            modificaCorIconeInputSenhaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputSenhaCadastroLocal(dispatch, 'red');
        }
        else if (confirmarSenha.trim() === '') {
            modificaMensagemErrorInputConfirmaSenhaCadastroLocal(dispatch, 'Confirme sua senha')
            modificaCorIconeInputConfirmaSenhaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputConfirmaSenhaCadastroLocal(dispatch, 'red');
        }
        else if (senha !== confirmarSenha) {
            modificaMensagemErrorInputConfirmaSenhaCadastroLocal(dispatch, 'Senhas diferentes, por favor verifique')
            modificaCorIconeInputConfirmaSenhaCadastroLocal(dispatch, 'red');
            modificaCorBordaInputConfirmaSenhaCadastroLocal(dispatch, 'red');
        }
        else {
            ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, true);
            let codigoReferencia = parseInt(codigoReferencia2);
            /* console.log(codigoReferencia) */
            let chaveUnica;
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'Accept': 'application/json',
                },

                data: { codigoReferencia: codigoReferencia },
                url: 'https://us-central1-dark-4b307.cloudfunctions.net/getUniqueKeyFromIdCompany',
            };
            axios(options).then((snapshot) => {
                snapshot = snapshot.data;
                if (snapshot !== null) {
                    const arrayOfKeys = Object.keys(snapshot);
                    const result = snapshot[arrayOfKeys[0]];
                    const numeroExclusivoCadastraCliente = result.numeroExclusivoCadastraCliente;
                    const chaveUnica = result.chaveUnica;
                    firebase.auth().createUserWithEmailAndPassword(email, senha).then(async user => {
                        localStorage.setItem(`chaveUnicaCliente`, JSON.stringify(chaveUnica));
                        const token = await user.user.getIdToken(true);
                        await loginServer(token, user.user);
                        let useruid = returnUidUserLogged(user)
                        //cadastrando o cliente na tabela principal
                        firebase.database().ref(`${chaveUnica}/clientes`).push({
                            nome: nome,
                            email: email,
                            empresa: empresa,
                            senha: senha,
                            permissaoAPP: true,
                            useruid: useruid,
                            alterado: 0,
                            notifNovoAtendimento: 1,
                            notifRespNosAtendimentos: 1,
                            urlImagemNuvem: ""
                        }).then(dadosCliente => {
                            //cadastrando o nome do usuario na tabela principal (email base 64)
                            firebase.database().ref(`usuariosALL/${useruid}`).push({ chaveUnica: chaveUnica, admin: 0 }).then(() => {
                                setTimeout(() => {
                                    ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, false);
                                    zeraInformacoesNomeCompaniaConfirmaSenha(dispatch);
                                    history.push('/atendimentos');
                                    toastr.success('Conta criada com sucesso!!', 'Seja muito bem vindo!');
                                }, 0);



                            }).catch(error => {
                                // console.log(error.code)
                                //error.code
                                //error.message
                                ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, false);
                                switch (error.code) {
                                    case "auth/invalid-email":
                                        toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                        break;
                                    case "auth/email-already-in-use":
                                        toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                        break;
                                    case "auth/network-request-failed":
                                        toastr.error('Error', "Sem conexão com a internet");
                                        break;
                                    default:
                                        toastr.error('Error', "Erro ao cadastrar, tente novamente mais tarde");
                                        break;
                                }
                            });
                        }).catch(error => {
                            // console.log(error.code)
                            //error.code
                            //error.message
                            ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, false);
                            switch (error.code) {
                                case "auth/invalid-email":
                                    toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                    break;
                                case "auth/email-already-in-use":
                                    toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                    break;
                                case "auth/network-request-failed":
                                    toastr.error('Error', "Sem conexão com a internet");
                                    break;
                                default:
                                    toastr.error('Error', "Erro ao cadastrar, tente novamente mais tarde");
                                    break;
                            }
                        });
                    }).catch(error => {
                        //console.log(error.code);
                        //error.code
                        //error.message
                        ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, false);
                        switch (error.code) {
                            case "auth/invalid-email":
                                toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                break;
                            case "auth/email-already-in-use":
                                toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                break;
                            case "auth/network-request-failed":
                                toastr.error('Error', "Sem conexão com a internet");
                                break;
                            default:
                                toastr.error('Error', "Erro ao cadastrar, tente novamente mais tarde");
                                break;
                        }
                    });

                } else {
                    /*     console.log('sem codigo'); */
                    toastr.error('Error', 'CÓDIGO DE REFERÊNCIA inexistente por favor verifique com seu suporte!');
                    ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania(dispatch, false);
                }
            }).catch((err) => {
                /* console.log(err) */
            })

        }

    }

}

export const verificaSeUsuarioEstaLogadoSeNaoEstiverLoga = (history) => {
    return dispatch => {
        AsyncStorage.getItem('login').then(
            (dadosLogin) => {
                /*  console.log(dadosLogin) */
                if (dadosLogin !== null) {

                    const arrayEmail = JSON.parse(dadosLogin);
                    if (arrayEmail[0].conectarAutomaticamente == true) {
                        ativaLoadingBotaoLogarTelaLogin(dispatch);
                        dispatch({ type: MODIFICACHECKBOXCONTINUARCONECTADO, payload: true })
                        /*    console.log('tem dados no asyncstorage: ');
                           console.log(arrayEmail) */
                        const email = arrayEmail[0].email.trim();
                        const senha = arrayEmail[0].senha.trim();
                        modificaEmailLoginLocal(dispatch, email);
                        modificaSenhaLoginLocal(dispatch, senha);
                        firebase.auth().signInWithEmailAndPassword(email, senha).then(
                            () => {
                                const { currentUser } = firebase.auth();
                                /*       console.log(currentUser.uid) */
                                AsyncStorage.getItem('chaveUnica').then(
                                    (chaveUnica2) => {
                                        if (chaveUnica2 !== null) {
                                            let chaveUnica = chaveUnica2.replace(/"/g, "");
                                            /*  console.log(chaveUnica) */
                                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(currentUser.uid).once("value").then(snapshot => {
                                                let arrayNomeQuemComprouLicenca = [];
                                                snapshot.forEach(childItem => {
                                                    arrayNomeQuemComprouLicenca.push({
                                                        clienteBoqueadoDevidoLimiteExcedido: childItem.val().clienteBoqueadoDevidoLimiteExcedido,
                                                    });

                                                });
                                                if (arrayNomeQuemComprouLicenca[0].clienteBoqueadoDevidoLimiteExcedido == 1) {
                                                    // Alert.alert('Limite de Atendente Atingido', 'Este atendente está com o acesso bloqueado, Entre na conta Principal e assine a VERSÃO PREMIUM para liberar o acesso para mais atendentes!');

                                                    firebase.database().ref(`${chaveUnica}/usuarios/`).limitToFirst(1).once('value').then((snapshot) => {

                                                        if (snapshot.val()) {
                                                            /*  console.log('PRIMEIRO USUARIOS------');
                                                             console.log(snapshot.val()); */
                                                            let dadosUsuarioPrincipal = [];
                                                            snapshot.forEach(childItem => {
                                                                dadosUsuarioPrincipal.push({
                                                                    email: childItem.val().email,
                                                                })
                                                            })

                                                            toastr.error('Limite de Atendentes Atingido', `Este atendente está com o acesso bloqueado porque a QUANTIDADE DE ATENDENTES LIBERADOS PARA CADASTRO foi Atingido, Entre na conta Principal (${dadosUsuarioPrincipal[0].email}) e assine a VERSÃO PREMIUM para liberar o acesso para esse e mais atendentes!`);
                                                            desativaLoadingBotaoLogarTelaLogin(dispatch);
                                                            firebase.auth().signOut();

                                                        }
                                                    })
                                                } else {
                                                    history.push("/dashboard");
                                                    desativaLoadingBotaoLogarTelaLogin(dispatch);
                                                }
                                            })


                                        } else {
                                            /*    console.log('sem chave unica') */
                                        }
                                    })
                            }).catch((error) => {
                                switch (error.code) {
                                    case 'auth/network-request-failed':

                                        toastr.error('Error', 'Sem conexão com a INTERNET.');
                                        desativaLoadingBotaoLogarTelaLogin(dispatch);

                                        break;
                                    default:
                                        toastr.error('Error', 'Houve um erro ao logar, tente novamente.');
                                        desativaLoadingBotaoLogarTelaLogin(dispatch);
                                        break;
                                }
                            })

                    } else {
                        /* console.log('não é pra logar automaticamente') */
                    }

                } else {
                    /*  console.log(' nao tem dados no localstorage') */
                }
            })

    }

}
const enviaSeAtendenteEhAdministrador = (dispatch, value) => {
    dispatch({
        type: ENVIASEATENDENTEEHADMINISTRADOR,
        payload: value
    });
};
const enviaPermissoesAcessarTelasAtendente = (dispatch, permissoes) => {
    dispatch({
        type: ENVIAPERMISSOESACESSARTELASATENDENTE,
        payload: permissoes
    });
};
const modificaIsAdmin = (dispatch, user) => {
    dispatch({
        type: MODIFICAISADMIN,
        payload: user
    });
};
const desativaLoadingBotaoLogarTelaLogin = (dispatch) => {
    dispatch({
        type: DESATIVALOADINGBOTAOLOGARTELALOGIN,
        payload: false
    });
};

const verificaInternetEsqueceuSenhaLocal = (dispatch, valor) => {
    dispatch({
        type: VERIFICAINTERNETESQUECEUSENHA,
        payload: valor
    });
};

export const modificaModalEsqueciSenha = (value) => {
    return {
        type: MODIFICAMODALESQUECISENHA,
        payload: value
    }
};
export const zeraVerificaInternetEsqueceuSenhaLocal = () => {
    return {
        type: VERIFICAINTERNETESQUECEUSENHA,
        payload: ''
    }
};



const ativaLoadingBotaoLogarTelaLogin = (dispatch) => {
    dispatch({
        type: ATIVALOADINGBOTAOLOGARTELALOGIN,
        payload: true
    });
};
export const ativaDesativaLoadingBotaoLogarTelaLogin = (value) => {
    return {
        type: ATIVALOADINGBOTAOLOGARTELALOGIN,
        payload: value
    };
};
const ativaDesativaLoadingBotaoEsqueceuSenha = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOESQUECEUSENHA,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoCadastrarTelaCadastraCompania = (dispatch, status) => {
    dispatch({
        type: ATIVALOADINGBOTAOCADASTRARTELACADASTRARCOMPANIA,
        payload: status
    });
};
const desativaLoadingBotaoCadastrarTelaCadastraCompania = (dispatch) => {
    dispatch({
        type: MENSAGEMCADASTRAEMAILERROR,
        payload: false
    });
};
const mensagemLogarEmailError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMLOGAREMAILERROR,
        payload: error
    });
};

const mensagemCasdastraEmailError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMCADASTRAEMAILERROR,
        payload: error
    });
};
const mensagemCadastraEmailSucesso = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMCADASTRAEMAILSUCESSO,
        payload: valor
    });
};
export const modificaCodigoReferencia = (codigo) => {
    return {
        type: MODIFICACODIGOREFERENCIA,
        payload: codigo
    };
};
const zeraInformacoesNomeCompaniaConfirmaSenha = (dispatch) => {
    dispatch({
        type: ZERAINFORMACOESNOMECOMPANIACONFIRMASENHA,
        payload: 'zeraInformacoes'
    });
};
export const zeraInformacoesLogin = () => {
    return {
        type: ZERAINFORMACOESLOGIN,
        payload: true
    }
}
/* export const modificaStatusConexao = (status) => {
    return {
        type: MODIFICASTATUSCONEXAO,
        payload: status
    };
}; */

const mensagemErroToastLogin = (dispatch, erro) => {
    dispatch({
        type: LOGINSEMINTERNET,
        payload: erro
    })
};

export const zeraMensagemErroToastLogin = () => {
    return {
        type: LOGINSEMINTERNET,
        payload: ''
    };
};
const modificaEmailLoginLocal = (dispatch, email) => {
    dispatch({
        type: MODIFICAEMAILLOGIN,
        payload: email
    });
};
const modificaSenhaLoginLocal = (dispatch, senha) => {
    dispatch({
        type: MODIFICASENHALOGIN,
        payload: senha
    });
};

const modificaErrorSenhaLoginLocal = (dispatch, error) => {
    dispatch({
        type: MODIFICAERRORSENHALOGIN,
        payload: error
    });
};
const modificaErrorLoginTelaLoginLocal = (dispatch, error) => {
    dispatch({
        type: MENSAGEMLOGAREMAILERROR,
        payload: error
    });
};

const modificaCorIconeInputSenhaLoginLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONESENHALOGIN,
        payload: cor
    });
};
const modificaCorIconeInputEmailLoginLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONEEMAILLOGIN,
        payload: cor
    });
};

const modificaCorBordaInputSenhaLoginLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDASENHALOGIN,
        payload: cor
    });
};
const modificaCorBordaInputEmailLoginLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDAEMAILLOGIN,
        payload: cor
    });
};
export const modificaCheckBoxContinuarConectado = (value) => {
    /*  console.log(value) */
    return {
        type: MODIFICACHECKBOXCONTINUARCONECTADO,
        payload: value
    };
};

export const modificaErrorSenhaLogin = (error) => {
    return {
        type: MODIFICAERRORSENHALOGIN,
        payload: error
    };
};
export const modificaErrorLoginTelaLogin = (error) => {
    return {
        type: MENSAGEMLOGAREMAILERROR,
        payload: error
    };
};

export const modificaCorIconeInputSenhaLogin = (cor) => {
    return {
        type: MODIFICACORICONESENHALOGIN,
        payload: cor
    };
};
export const modificaCorIconeInputEmailLogin = (cor) => {
    return {
        type: MODIFICACORICONEEMAILLOGIN,
        payload: cor
    };
};

export const modificaCorBordaInputSenhaLogin = (cor) => {
    return {
        type: MODIFICACORBORDASENHALOGIN,
        payload: cor
    };
};
export const modificaCorBordaInputEmailLogin = (cor) => {
    return {
        type: MODIFICACORBORDAEMAILLOGIN,
        payload: cor
    };
};


export const modificaEmailLogin = (email) => {
    return {
        type: MODIFICAEMAILLOGIN,
        payload: email
    };
};
export const modificaSenhaLogin = (senha) => {
    return {
        type: MODIFICASENHALOGIN,
        payload: senha
    };
};
export const modificaConfirmaSenha = (confirmaSenha) => {
    return {
        type: MODIFICACONFIRMASENHA,
        payload: confirmaSenha
    };
};
export const modificaCompania = (empresa) => {
    return {
        type: MODIFICACOMPANIA,
        payload: empresa
    };
};
export const modificaNome = (nome) => {
    return {
        type: MODIFICANOME,
        payload: nome
    };
};

export const modificaCorBordaInputCodigoReferenciaCadastro = (cor) => {
    return {
        type: MODIFICACORBORDAINPUTCODIGOREFERENCIACADASTRO,
        payload: cor
    };
};
export const modificaCorIconeInputCodigoReferenciaCadastro = (cor) => {
    return {
        type: MODIFICACORICONEINPUTCODIGOREFERENCIACADASTRO,
        payload: cor
    };
};
//partir daqui
export const modificaCorIconeInputEmpresaCadastro = (cor) => {
    return {
        type: MODIFICACORICONEINPUTEMPRESACADASTRO,
        payload: cor
    };
};
export const modificaCorBordaInputEmpresaCadastro = (cor) => {
    return {
        type: MODIFICACORBORDAEMPRESACADASTRO,
        payload: cor
    };
};
const modificaCorIconeInputEmpresaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONEINPUTEMPRESACADASTRO,
        payload: cor
    });
};
const modificaCorBordaInputEmpresaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDAEMPRESACADASTRO,
        payload: cor
    });
};
const modificaMensagemErrorInputEmpresaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO,
        payload: cor
    });
};


export const modificaCorIconeInputEmailCadastro = (cor) => {
    return {
        type: MODIFICACORICONEINPUTEMAILCADASTRO,
        payload: cor
    };
};

export const modificaCorBordaInputEmailCadastro = (cor) => {
    return {
        type: MODIFICACORBORDAEMAILCADASTRO,
        payload: cor
    };
};
//2
const modificaCorIconeInputEmailCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONEINPUTEMAILCADASTRO,
        payload: cor
    });
};
const modificaCorBordaInputEmailCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDAEMAILCADASTRO,
        payload: cor
    });
};
const modificaMensagemErrorInputEmailCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO,
        payload: cor
    });
};



export const modificaCorIconeInputNomeCadastro = (cor) => {
    return {
        type: MODIFICACORICONENOMECADASTRO,
        payload: cor
    };
};

export const modificaCorBordaInputNomeCadastro = (cor) => {
    return {
        type: MODIFICACORBORDANOMECADASTRO,
        payload: cor
    };
};
//3

const modificaCorIconeInputNomeCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONENOMECADASTRO,
        payload: cor
    });
};
const modificaCorBordaInputNomeCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDANOMECADASTRO,
        payload: cor
    });
};
const modificaMensagemErrorInputNomeCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICAMENSAGEMERRORINPUTNOMECADASTRO,
        payload: cor
    });
};

export const modificaCorIconeInputSenhaCadastro = (cor) => {
    return {
        type: MODIFICACORICONESENHACADASTRO,
        payload: cor
    };
};

export const modificaCorBordaInputSenhaCadastro = (cor) => {
    return {
        type: MODIFICACORBORDASENHACADASTRO,
        payload: cor
    };
};

//4
const modificaCorIconeInputSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONESENHACADASTRO,
        payload: cor
    });
};

const modificaCorBordaInputSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDASENHACADASTRO,
        payload: cor
    });
};
const modificaMensagemErrorInputSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICAMENSAGEMERRORINPUTSENHACADASTRO,
        payload: cor
    });
};


export const modificaCorIconeInputConfirmaSenhaCadastro = (cor) => {
    return {
        type: MODIFICACORICONECONFIRMASENHACADASTRO,
        payload: cor
    };
};

export const modificaCorBordaInputConfirmaSenhaCadastro = (cor) => {
    return {
        type: MODIFICACORBORDACONFIRMASENHACADASTRO,
        payload: cor
    };
};
//5
const modificaCorIconeInputConfirmaSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORICONECONFIRMASENHACADASTRO,
        payload: cor
    });
};
const modificaCorBordaInputConfirmaSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICACORBORDACONFIRMASENHACADASTRO,
        payload: cor
    });
};
const modificaMensagemErrorInputConfirmaSenhaCadastroLocal = (dispatch, cor) => {
    dispatch({
        type: MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO,
        payload: cor
    });
};


export const modificaMensagemErrorInputEmpresaCadastro = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTEMPRESACADASTRO,
        payload: cor
    };
};
export const modificaMensagemErrorInputCodReferencia = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTCODREFERENCIA,
        payload: cor
    };
};
export const modificaMensagemErrorInputEmailCadastro = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTEMAILCADASTRO,
        payload: cor
    };
};
export const modificaMensagemErrorInputNomeCadastro = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTNOMECADASTRO,
        payload: cor
    };
};
export const modificaMensagemErrorInputSenhaCadastro = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTSENHACADASTRO,
        payload: cor
    };
};
export const modificaMensagemErrorInputConfirmaSenhaCadastro = (cor) => {
    return {
        type: MODIFICAMENSAGEMERRORINPUTCONFIRMASENHACADASTRO,
        payload: cor
    };
};

export const modificaisCodeReference = (value) => {
    return {
        type: UPDATECODEREFERENCE,
        payload: value
    };
};

function retornaDataAtual() {
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
}
function validarEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
function ordenaObjeto(obj) {
    var arr = [];
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({
                'key': prop,
                'value': obj[prop]
            });
        }
    }
    arr.sort(function (a, b) { return a.value - b.value; });
    //arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
    return arr.reverse(); // returns array
}

function retornaDataTimeStamp(data) {
    var myDate = data;
    myDate = myDate.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
}

function retornaMedia(arrayComValores) {
    const total = arrayComValores.reduce((acc, c) => acc + c, 0);
    return total / arrayComValores.length;
}
function calculaDias2(date1, date2) {
    //formato do brasil 'pt-br'
    // moment.locale('pt-br');
    //setando data1
    let data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    let data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    // let diff = data2.diff(data1, 'days');
    let diff = moment.duration(data2.diff(data1)).asDays();

    return diff;
}
function returnUidUserLogged(user) {
    if (user !== null) {
        if (user.uid) {
            return user.uid;
        } else {
            return user.user.uid;
        }
    } else {
        return 'usernotlogged';
    }

}

async function loginServer(token, user) {
    const decodedToken = jwt_decode(token);
    console.log('loginServer decodedToken', decodedToken);
    if (!decodedToken || !decodedToken.idCompany || decodedToken.idCompany === 'f36e2d6a-5bec-4612-adb3-2a98520db74a'||
    decodedToken.idCompany === 'null') {
        console.log('fazendo loginServer');
        await api.post('login');
        const newToken = await user.getIdToken(true);
     console.log('newToken loginServer', jwt_decode(newToken));
        localStorage.setItem('token', newToken);
    }
}