import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { shade, opacify, lighten, } from 'polished';
import { Modal } from 'react-responsive-modal';

export const Title = styled.h1`
   font-size: 35px;
`;

export const Container = styled.div`
 display:flex;
 position:relative;
   width:100%;
   flex-direction:column;
   flex:1;
   overflow:auto;
`;
export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:row;
height:30px;
min-height:30px;

width:435px;
margin-left:15px;
`;
export const ContainerDetalhesModalNovoAtendimento = styled.div`
 display:flex;
   flex:1;
   flex-direction:column;
   justify-content:space-around;
`;

export const TabsModified = styled(Tabs)`

flex:1;
`;
export const TabListModified = styled(TabList)`
    display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  
`;
export const TabModified = styled(Tab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
   flex-grow: 1;
   font-size:13px;
   text-align: center;
   height:40px;
   min-height:40px;
   list-style: none;

   cursor: pointer;
   color: #888;

   &:hover{
   background:rgba(255,255,255,0.2);
   border-radius:5px;
}
   &:first-child {
     border-left: none;
   }
 
   &.selected {
      i{
         color:${props => props.coriconedinamicoselecionado};
      }
      p{
    color:${props => props.coriconedinamicoselecionado};
   }
      color:${props => props.coriconedinamicoselecionado};
     border-bottom: 1px solid ${props => props.coriconedinamicoselecionado} ;
   }
 
   &.disabled {
     color: #e0e0e0;
     cursor: not-allowed;
   }
 `;
 export const TabModifiedTodos = styled(Tab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
display:flex;
   flex-grow: 1;
   font-size:13px;
   height:40px;
   min-height:40px;
   list-style: none;
   align-items:center;
justify-content:center;
   cursor: pointer;
   color: #888;

   &:hover{
   background:rgba(255,255,255,0.2);
   border-radius:5px;
}
   &:first-child {
     border-left: none;
   }
 
   &.selected {
      i{
         color:${props => props.coriconedinamicoselecionado};
      }
      p{
    color:${props => props.coriconedinamicoselecionado};
   }
      color:${props => props.coriconedinamicoselecionado};
     border-bottom: 1px solid ${props => props.coriconedinamicoselecionado} ;
   }
 
   &.disabled {
     color: #e0e0e0;
     cursor: not-allowed;
   }
 `;
export const TabPanelModified = styled(TabPanel).attrs({ selectedClassName: 'selected' })`
  display: flex;
  
  &.selected {
    display: flex;
    
  }
`;
export const TabPanelModifiedTodos = styled(TabPanel).attrs({ selectedClassName: 'selected' })`
display: flex;
flex:1;
align-items:center;
flex-direction:column;
margin-top:15px;
&.selected {
  display: flex;
  flex:1;
  align-items:center;
  flex-direction:column;
}
`;

export const ModalModified = styled(Modal).attrs({ selectedClassName: 'modal' })`
  &.modal{ max-width: 800px;
  position: relative;
  padding: 1.2rem;
  background: #ffffff;
  background-clip: padding-box;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  }
`;
export const ContainerModal = styled.div`
 display:flex;
   overflow:auto;
   width:550px;
   flex:1;
   height:calc(100vh - 30px);
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
margin-left:8px;
color:${props => props.backgroundDinamico};
cursor: pointer;
&:hover{
  color:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const TextArquivoAnexado = styled.p`
font-size:12px;
max-width:350px;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const IconeClip = styled.i`
font-size:18px;

color:${props => props.backgroundDinamico};
cursor: pointer;
&:hover{
  color:${props => shade(0.2, props.backgroundDinamico)};
}
`;

export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const TextAddDetalhe = styled.strong`
font-size:15px;
color:${props => props.corLetraDinamico};
margin-left:15px;
`;

export const DivAdicionarMaisDetalhes = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px;
margin-top:10px;
margin-left:10px;
margin-right:10px;
cursor: pointer;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;

export const SubContainerNovoAtendimento = styled.div`
 display:flex;
 margin-top:3px;
   flex-direction:column;
align-items:flex-start;
justify-content:flex-start;

`;
export const DivBotaoNovoAtendimento = styled.div`
 display:flex;
 height:40px;
 min-height:40px;
flex-direction:row;
justify-content:center;
align-items:center;
margin:10px;
`;

export const BotaoCadastrarAtendimento = styled.button`
display:flex;
width:50%;
height:40px;
min-height:40px;
justify-content:center;
align-items:center;
border:0;
border-radius: 7px;
transition: background-color 0.2s;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const TextCadastrar = styled.strong`
font-size:20px;
color:${props => props.corLetraDinamico};
font-weight:bold;
letter-spacing: 1px;
`;
export const ContainerInputDescricaoChamado = styled.div`
 display:flex;
   flex-direction:column;
   padding:10px;
`;


export const InputDescricaoChamado = styled.textarea`
display:flex;
background-Color:transparent;
text-transform: none;
height:70px;
min-height:70px;
border:0;
padding:5px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;

export const BotaoAdd = styled.button`
display:flex;
width:30px;
height:30px;
min-height:30px;
justify-content:center;
align-items:center;
margin-left:5px;
border:0;
border-radius: 7px;
transition: background-color 0.2s;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;

export const TextTab = styled.p`
color:${props => props.colorDinamico};
`;
export const IconeTab = styled.i`
font-size:17px;
color:${props => props.colorDinamico};
`;
export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;

export const InputDataVencimento = styled.input`
display:flex;
flex:1;
height:30px;
min-height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};
border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }
`;
export const IconeSetaPraBaixoDataVencimento = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 52px;
width: 11px;
height: 11px;
min-height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const ContainerAviso = styled.div`
  flex-direction:row;
 padding:10px;
  justify-content:flex-start;
  padding-left:10px;
  padding-right:10px;
  align-items:center;
  display:flex;
  background-color:#F9BF3B;
  margin:5px;
  border-radius:2px;
`;
export const TextAviso = styled.strong`
   color: #000;
   font-size:14px;
`;

/* daqui pra la */
