import React from 'react';
import { connect } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';

import '../../fontello/css/fontello.css';
import logo from '../../imagens/logodropdesk2.png';
import userPadrao from '../../imagens/userPadrao.png';


import {

} from '../../actions/LoginAction';

import {
    Container, ButtonMenu, ButtonExpandeMenu, BorderBottomMenu, HeaderPadrao,
    TelaInteira,SubContainer
} from './styled'


class TelaPadrao extends React.Component {
    state = {
        menuOpen: true
    }
   
    _toggleDrawerMenu() {
        let state = this.state;
        state.menuOpen = !this.state.menuOpen;
        this.setState({ state })
    }
    render() {
        return (
            <Container >

                <ProSidebar
                    style={{ width: 40 }}
                    onToggle={(value) => { alert(value) }}
                    collapsed={this.state.menuOpen}
                //   image={logo3}
                >
                    <SidebarHeader >
                        <ButtonMenu>

                            <ButtonExpandeMenu type="submit" onClick={() => { this._toggleDrawerMenu() }}>
                                <i className="icon-open-menu1" style={{
                                    color: '#FFF', fontSize: 17,
                                    //backgroundColor:'rgba(173, 173, 173, 0.2)',padding:5,borderRadius:5
                                }} />
                            </ButtonExpandeMenu>

                            {/* <ContainerHeaderMenu>
                            <ContainerImagemPerfil>
                                <img src={userPadrao} alt="Background DropDesk" style={{ height: 40, width: 40, borderRadius: 35 }} />
                            </ContainerImagemPerfil>
                            <ContainerDadosUsuarioPerfil>
                                <p style={{ fontSize: 10, color: '#FFF' }}>auheuhiauhauheuauea1231231231231231231231231231231231231231313123123123123123123123</p>
                                <span>Text to break together</span>
                            </ContainerDadosUsuarioPerfil>
                        </ContainerHeaderMenu> */}
                        </ButtonMenu>
                    </SidebarHeader>
                    <Menu iconShape="round">
                        <MenuItem active={true} title='Dashboard' icon={<i className="icon-dashboardoff" style={{ color: '#FFF', fontSize: 18 }} />}>Dashboard  <Link to="/dashboard" /> </MenuItem>
                        <MenuItem active={false} title='Atendimentos' icon={<i className="icon-headset" style={{ color: '#FFF', fontSize: 18 }} />}>Atendimentos <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Versão Premium' style={{ borderBottomWidth: 1, borderBottomColor: '#FFF' }} icon={<i className="icon-vip" style={{ color: '#FFF', fontSize: 18 }} />}>Versão Premium <Link to="/dashboard" /></MenuItem>
                        <BorderBottomMenu ></BorderBottomMenu>
                        <MenuItem active={false} title='Clientes' icon={<i className="icon-man-user" style={{ color: '#FFF', fontSize: 18 }} />}>Clientes <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Atendentes' icon={<i className="icon-atendimentos" style={{ color: '#FFF', fontSize: 18 }} />}>Atendentes <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Setores' icon={<i className="icon-icon" style={{ color: '#FFF', fontSize: 18 }} />}>Setores <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Mural de Avisos' icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Relatórios' icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios <Link to="/dashboard" /></MenuItem>
                        <BorderBottomMenu ></BorderBottomMenu>
                        <MenuItem active={false} title='Configurações' icon={<i className="icon-settings-gears" style={{ color: '#FFF', fontSize: 18 }} />}>Configurações <Link to="/dashboard" /></MenuItem>
                        <MenuItem active={false} title='Sair' icon={<i className="icon-logout" style={{ color: '#FFF', fontSize: 18 }} />}>Sair <Link to="/dashboard" /></MenuItem>

                        {/*     <SubMenu title="Components" icon={<i className="icon-dashboardoff" style={{ color: '#FFF' }} />}>
                            <MenuItem>Component 1</MenuItem>
                            <MenuItem>Component 2</MenuItem>
                        </SubMenu> */}
                    </Menu>
                    {/*   <SidebarFooter>
                        <img src={logo} alt="Background DropDesk" height="100%" width="100%" />
                    </SidebarFooter> */}
                </ProSidebar>
                  <SubContainer >
                    <HeaderPadrao backgroundDinamico={this.props.backgroundPrimeiro}>
                    <p>Dashboard</p>
                    </HeaderPadrao>
                   {/*  <TelaInteira backgroundDinamico={this.props.backgroundSegundo}>
                        <p>Tela Inicial</p>
                    </TelaInteira> */}
                </SubContainer>  

            </Container>

        );
    }
}


const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,

        }
    );
};
export default connect(mapStateToProps, {

})(TelaPadrao);
