//import firebase from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
//import "firebase/analytics";
//import "firebase/auth";
//import "firebase/database";
//import "firebase/storage";
//import "firebase/messaging";

let config = {
    /*     apiKey: "AIzaSyASVJAyWCrYvQOcEncMgSpFKw_lAyczM_g",
        authDomain: "dark-4b307.firebaseapp.com",
        databaseURL: "https://dark-4b307.firebaseio.com",
        projectId: "dark-4b307",
        storageBucket: "dark-4b307.appspot.com",
        messagingSenderId: "430764253127",
        appId: "1:430764253127:web:f5b3eed58f414ce37dc3e2" */
    apiKey: "AIzaSyASVJAyWCrYvQOcEncMgSpFKw_lAyczM_g",
    authDomain: "dark-4b307.firebaseapp.com",
    databaseURL: "https://dark-4b307.firebaseio.com",
    projectId: "dark-4b307",
    storageBucket: "dark-4b307.appspot.com",
    messagingSenderId: "430764253127",
    appId: "1:430764253127:web:f5b3eed58f414ce37dc3e2",
    measurementId: "G-F7RDY9N1WP"
};
function initFirebase() {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
       // firebase.analytics();
    }
}
initFirebase();

export default firebase;