import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten } from 'polished';


const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Container = styled.div`
 display:flex;
 flex: 1;
 background:${props => props.backGroundDinamico};
 flex-direction:column;
 position: relative;
`;
export const ContainerTimeLine = styled.div`
 display:flex;
 flex:1;
 background:${props => props.backGroundDinamico};
 flex-direction:column;
`;
export const ContainerTimeLineLoading = styled.div`
 display:flex;
 flex:1;
 background:${props => props.backGroundDinamico};
 flex-direction:column;
 align-items:center;
 justify-content:center;
`;

export const ContainerAcoes = styled.div`
 display:flex;
 position: relative;
 background:${props => props.backGroundDinamico};
 flex-direction:row;
 height:51px;
 min-height:51px;
padding-right:20px;
padding-left:20px;
`;
export const ContainerComentario = styled.div`
 display:flex;
 background:${props => props.backGroundDinamico};
 flex-direction:row;
 height:32px;
padding-right:20px;
padding-left:20px;
`;
export const ContainerDadosDoChamado = styled.div`
 display:flex;
 background:${props => props.backGroundDinamico};
 flex-direction:row;
justify-content:space-around;
`;

export const ButtonVariosNovos = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:space-around;
height:33px;
width:100px;
margin-right:10px;

align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ButtonVariosNovosLoading = styled.div`
display:flex;
flex-direction:row;
justify-content:space-around;
height:33px;
width:100px;
margin-right:10px;
align-items:center;
border-radius:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;

export const IconeVariosNovos = styled.i`
  color:${props => props.colorPrimario};
  font-size:14px;
  padding-left:5px;
 font-weight:bold;
`;
export const IconesLateras = styled.i`
  color:${props => props.colorPrimario};
  font-size:22px;
  padding-left:5px;
 font-weight:bold;
 cursor: pointer;
`;
export const IconeAlterar = styled.i`
  color:${props => props.colorPrimario};
  font-size:18px;
  padding-left:5px;
 font-weight:bold;
 cursor: pointer;
`;


export const TextBotaoNovo = styled.span`
  color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const TextBotaoNovoTempoGasto = styled.span`
  color:${props => props.colorDinamico};
  font-size:13px;
 font-weight:bold;
 flex:1;
 position: relative;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const TextProblema = styled.strong`
font-size:15px;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;
export const TextProblemaLoading = styled.div`
display:flex;
flex:1;
border-radius:5px;
height:17px;
margin-top:5px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextDataHoraAberturaLoading = styled.div`
display:flex;
flex:1;
height:13px;
border-radius:5px;
margin-bottom:3px;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextDataHoraAbertura = styled.p`
font-size:11px;
font-weight:bold;
color:${props => props.corLetraDinamica};
margin-left:2px;

`;
export const ContainerDataAbertura = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
`;
export const ContainerDadosAbertura = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
`;

export const TextAbertura = styled.p`
font-size:11px;
color:${props => props.corLetraDinamica};
`;

export const ContainerDadosChamadoPrimeiro = styled.div`
display:flex;
flex-direction:column;
position: relative;
flex:1;
justify-content:space-between;
padding-right:15px;
padding-left:20px;
min-height:65px;
`;
export const ContainerDadosChamadoSegundo = styled.div`
display:flex;
flex-direction:row;
width:250px;
justify-content:center;
align-items:center;

`;
export const ContainerDadosProblema = styled.div`
flex-direction:row;

justify-content:flex-start;
align-items:flex-start;
color:${props => props.corLetraDinamica};
`;
export const DivTruncarTexto = styled.div`


display: block;
 display: -webkit-box;
 max-width: 100%;
 margin: 0 auto;
 -webkit-line-clamp:${props => {
    if (!props.truncarTexto) {
      return 2
    } else {
      return 10
    }
  }};
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
 color:${props => props.corLetraDinamica};
`;

export const ContainerDadosAberturaFechamento = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
`;
export const ContainerIconeMaisInformacoes = styled.div`
display:flex;
height:25px;
width:25px;
border-radius:4px;
flex-direction:row;
margin-left:4px;
justify-content:center;
cursor:pointer;
align-items:center;
background:${props => props.backgroundBotao};
&:hover{
  background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ContainerIconeMaisInformacoesLoading = styled.div`
display:flex;
height:25px;
width:25px;
border-radius:4px;
flex-direction:row;
margin-left:4px;
justify-content:center;
align-items:center;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const ContainerIconeLateral = styled.div`
display:flex;
height:25px;
width:25px;
border-radius:4px;
flex-direction:row;
justify-content:center;
margin-left:12px;
align-items:center;
background:${props => props.backgroundBotao};
cursor: pointer;
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;

export const ContainerBotoesAcoes = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
flex:1;
`;
export const ContainerIconesLaterais = styled.div`
display:flex;
flex-direction:row;
width:150px;
justify-content:flex-end;
align-items:center;
`;
export const DivAdicionarComentario = styled.div`
 display:flex;
 background:${props => props.backGroundDinamico};
 height:25px;
 margin-bottom:7px;
 border-radius:4px;
 align-items:center;
 width:650px;
 cursor:pointer;
 &:hover{
  border:${props => ` 1px solid ${props.borderColorDinamico}`};
}
`;
export const DivAdicionarComentarioDesativado = styled.div`
 display:flex;
 background:${props => props.backGroundDinamico};
 height:25px;
 margin-bottom:7px;
 border-radius:4px;
 align-items:center;
 width:650px;
 cursor:no-drop;

`;
export const DivAdicionarComentarioLoading = styled.div`
 display:flex;
 height:25px;
 margin-bottom:7px;
 border-radius:4px;
 align-items:center;
 width:650px;
 background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;

export const TextAddComent = styled.p`
font-size:13px;
color:${props => props.corLetraDinamica};
margin-left:7px;
`;
export const ContainerMaisInformacoes = styled.div`
 display:flex;
 flex-direction:column;
 background:${props => props.backGroundDinamico};

 padding:10px;
 justify-content:center;
 align-items:center;
 width:100%;

`;
export const SubContainerMaisInformacoes = styled.div`
 display:flex;
 flex-direction:column;
 background:${props => props.backGroundDinamico};
`;
export const DivLAbelCliente = styled.div`
 display:flex;
 height:30px;
 justify-content:center;
 align-items:center;
 width:350px;
`;
export const DivCliente = styled.div`
 display:flex;
 height:30px;
 justify-content:center;
 align-items:center;
 width:350px;
`;
export const LabelCliente = styled.p`
font-size:13px;
width:300px;
text-align:center;
overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
color:${props => props.corLetraDinamica};
`;
export const DivSeparadoraMaisInformacoes = styled.div`
 display:flex;
 border-bottom:${props => `1px solid ${props.corBordaDinamica}`};
`;

export const TextMensagem = styled.p`
font-size:13px;
color:${props => props.corLetraDinamica};
`;
export const TextTitulo = styled.h4`

color:${props => props.corLetraDinamica};
`;
export const TextAvaliar = styled.p`
font-size:13px;
background:#00BFFF;
color:${props => props.corLetraDinamica};
width:150px;
margin-top:5px;
border-radius:5px;
cursor:pointer;
padding:5px;
`;
export const ImagemComentario = styled.img`
height:170px;
width:170px;
margin:15px;
border-radius:20px;
background:#9fadb7;
cursor:pointer;
`;
export const TextBaixarArquivo = styled.span`
color:#00BFFF;
transition: background-color 0.2s;
margin-top:20px;
text-decoration:underline;
cursor: pointer;
&:hover{
   color: ${shade(0.2, '#00BFFF')};
}
`;
export const ContainerClipBaixarArquivo = styled.div`
padding:3px;
flex-direction:row;
margin-top:5px;
border:${props => `1px solid ${props.corBordaDinamica}`};
border-radius:5px;
cursor: pointer;
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const ContainerModal = styled.div`
 display:flex;
   width:550px;
   height:calc(100vh - 30px);
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalAvaliacoes = styled.div`
 display:flex;
   width:550px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;

export const ContainerModalAlterar = styled.div`
display:flex;
   overflow:auto;
   width:550px;
   flex:1;
   height:calc(100vh - 30px);
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;

export const ContainerModalStatusAtendimento = styled.div`
 display:flex;
   width:550px;
  height:120px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;

export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const TextCabecalhoModalAcoes = styled.strong`
font-size:18px;
color:${props => props.corLetraDinamico};
text-align:center;
`;
export const ContainerTextCabecalhoModalAcoes = styled.div`
 display:flex;
 flex-direction:column;
padding-top:15px;
padding-bottom:15px;
 align-items:center;
 justify-content:center;
`;

export const TextAddDetalhe = styled.strong`
font-size:15px;
color:${props => props.corLetraDinamico};
margin-left:15px;
`;
export const IconeClip = styled.i`
font-size:18px;
margin-right:8px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const DivAdicionarMaisDetalhes = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px;
margin:10px;
cursor: pointer;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;

export const ContainerDetalhesModalNovoAtendimento = styled.div`
 display:flex;
   flex:1;
   flex-direction:column;
   align-items:flex-start;
   justify-content:space-around;
`;
export const SubContainerNovoAtendimento = styled.div`
 display:flex;
 margin-top:10px;
   flex-direction:column;
align-items:flex-start;
justify-content:space-around;
`;
export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:row;
height:30px;
width:435px;
margin-left:15px;
`;
export const BotaoAdd = styled.button`
display:flex;
width:30px;
height:30px;
justify-content:center;
align-items:center;
margin-left:5px;
border:0;
border-radius: 7px;
transition: background-color 0.2s;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const ContainerInputDescricaoChamado = styled.div`
 display:flex;
   flex-direction:column;
   padding:10px;
`;
export const InputDescricaoChamado = styled.textarea`
display:flex;
background-Color:transparent;
height:70px;
border:0;
padding:5px;
width:450px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
   
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;
export const DivBotaoNovoAtendimento = styled.div`
 display:flex;
 height:40px;
flex-direction:row;
justify-content:center;
align-items:center;
margin:10px;
`;

export const BotaoCadastrarAtendimento = styled.button`
display:flex;
width:50%;
height:40px;
justify-content:center;
align-items:center;
border:0;
border-radius: 7px;
transition: background-color 0.2s;
background:${props => props.backgroundDinamico};
&:hover{
  background:${props => shade(0.2, props.backgroundDinamico)};
}
`;
export const TextCadastrar = styled.strong`
font-size:20px;
color:${props => props.corLetraDinamico};
font-weight:bold;
letter-spacing: 1px;
`;
export const DivAtendentes = styled.div`
 display:flex;
padding:10px;
justify-content:center;
align-items:center;
`;
export const TextAtendentes = styled.strong`
font-size:15px;
color:${props => props.corLetraDinamico};
font-weight:bold;
cursor:pointer;
&:hover{
  color:${props => props.corLetraBotaoPadrao};
}
`;

export const InputCancelamento = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
border:0;
color:${ props => props.corLetraDinamico};
border-radius:5px;
padding-left:5px;
width:350px;
border: ${props => ` 1px solid ${props.corLetraDinamico}`};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBordaDinamica};
  }
`;

export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:flex-end;
`;
export const DivTextoReabrirAtendimento = styled.div`
 display:flex;
 flex:1;
justify-content:center;
align-items:center;
`;

export const DivInputComentarChamado = styled.div`
 display:flex;
 flex-direction:column;
justify-content:center;
align-items:center;
`;
export const DivBotaoSalvarComentario = styled.div`
 display:flex;
 flex:1;
 width:550px;
justify-content:flex-end;
align-items:flex-end;
`;
export const DivBotaoComentarioInternoPublico = styled.div`
 display:flex;
justify-content:center;
align-items:center;
`;

export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const ButtonSalvarComentario = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
width:100px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
`;
export const ButtonSalvarComentarioSemComent = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
width:100px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
cursor:default;
`;

export const ButtonNaoCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
border:${props => ` 1px solid ${props.borderColorDinamico}`};

&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}

`;

export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const TextBotaoSalvarComentar = styled.span`
  color:${props => props.backgroundBotao};
  font-size:14px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const BotaoSelecionarCOment = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:center;
height:35px;
width:190px;
align-items:center;
background:${props => props.backGroundDinamico};
border-radius:5px;
transition: background-color 0.2s;
/* &:focus{
  background:${props => props.backGroundDinamico};
} */
`;
export const TextComentario = styled.span`
  color:${props => props.colorDinamico};
  font-size:14px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;


export const ContainerInputEBotaoComent = styled.div`
 display:flex;
justify-content:center;
flex:1;
margin-top:40px;
flex-direction:column;
align-items:center;
`;
export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};


border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;
`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const BotaoDesativado = styled.button`
display:flex;
flex-direction:row;
border:0;
justify-content:space-around;
height:33px;
width:100px;
margin-right:10px;

align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
cursor:no-drop;
/* &:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
} */
`;
export const ContainerIconeLateralDesativado = styled.div`
display:flex;
height:30px;
width:30px;
border-radius:4px;
flex-direction:row;
justify-content:center;
margin-left:12px;
align-items:center;
background:${props => props.backgroundBotao};
cursor: no-drop;

`;
export const ContainerIconeLateralLoading = styled.div`
display:flex;
height:30px;
width:30px;
border-radius:4px;
flex-direction:row;
justify-content:center;
margin-left:12px;
align-items:center;
background:${props => `linear-gradient(to right, ${lighten(0.2, props.backgroundinamico)} 10%, ${lighten(0.2, props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const InputDataVencimento = styled.input`
display:flex;
flex:1;
height:30px;
width:400px;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};
border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }
`;
export const IconeSetaPraBaixoDataVencimento = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 52px;
width: 11px;
height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;

export const ContainerPermitir = styled.div`
 display:flex;
 flex-direction:row;
 height:40px;
 margin:10px;
align-items:center;
`;
export const TextPermitir = styled.p`
font-size:15px;
text-align:center;
font-weight:bold;
color:${props => props.corLetraDinamica};
`;