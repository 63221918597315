import {
    MODIFICAEMAILLOGIN, MODIFICACORBORDALISTATAREFAS,

    MODIFICACORFUNDOPRINCIPAL, MODIFICACORFUNDOSECUNDARIO, MODIFICACORFUNDOITENSSELECIONAVEIS, MODIFICACORFUNDOBOTAOPRINCIPAL,
    MODIFICACORFUNDOBOTAOSECUNDARIO, MODIFICACORLETRAPADRAO, MODIFICACORLETRAPRINCIPAL, MODIFICACORLETRASECUNDARIA,
    MODIFICACORLETRADESCRICAODOSCAMPOS, MODIFICACORFUNDOMENU, MODIFICACORLETRAMENU,
    MODIFICALOGOTIPOTELACONFIGURACAO, MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
    MODIFICACORPRINCIPALCHAT
} from '../actions/types';

const INITIAL_STATE = {
    backgroundPrimeiro: '#29454D',
    backgroundSegundo: '#3F535A',
    backgroundMaisEscuro: '#223033',
    coresLetraPadrao: '#FFF',
    coresLetraSecundario: '#9fadb7',
    corBotaoPadrao: '#00FF7F',
    // corBordaPadrao:'#FFF',
    //  corBordaDinamica:'#FFF',
    letraBotaoPadrao: '#000',
    corSegundoBotaoPadrao: '#F0f0f7',
    corLetraDescricaoDosCampos: '#F0FFF0',
    corFundoMenu: '#1d1d1d',
    corLetraMenu: '#FFF',
    logotipo: null,
    arquivoLogoTipoCompleto: '',
    corPrincipalChat: '#39a3f4'

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICACORFUNDOPRINCIPAL:
            return { ...state, backgroundPrimeiro: action.payload };
        case MODIFICACORBORDALISTATAREFAS:
            return { ...state, corBordaPadrao: action.payload };
        case MODIFICACORFUNDOSECUNDARIO:
            return { ...state, backgroundSegundo: action.payload };
        case MODIFICACORFUNDOITENSSELECIONAVEIS:
            return { ...state, backgroundMaisEscuro: action.payload };
        case MODIFICACORFUNDOBOTAOPRINCIPAL:
            return { ...state, corBotaoPadrao: action.payload };
        case MODIFICACORFUNDOBOTAOSECUNDARIO:
            return { ...state, corSegundoBotaoPadrao: action.payload };
        case MODIFICACORLETRAPADRAO:
            return { ...state, letraBotaoPadrao: action.payload };
        case MODIFICACORLETRAPRINCIPAL:
            return { ...state, coresLetraPadrao: action.payload };
        case MODIFICACORLETRASECUNDARIA:
            return { ...state, coresLetraSecundario: action.payload };
        case MODIFICACORLETRADESCRICAODOSCAMPOS:
            return { ...state, corLetraDescricaoDosCampos: action.payload };
        case MODIFICACORFUNDOMENU:
            return { ...state, corFundoMenu: action.payload };
        case MODIFICACORLETRAMENU:
            return { ...state, corLetraMenu: action.payload };

        case MODIFICALOGOTIPOTELACONFIGURACAO:
            return { ...state, logotipo: action.payload };
        case MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO:
            return { ...state, arquivoLogoTipoCompleto: action.payload };
        case MODIFICACORPRINCIPALCHAT:
            return { ...state, corPrincipalChat: action.payload };
        default:
            return state;
    }
};