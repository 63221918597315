import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import app from "./FirebaseConnection";
import ClipLoader from "react-spinners/ClipLoader";
import jwt_decode from "jwt-decode";
import { toastr } from 'react-redux-toastr';
import api from '../http_client/http.client';
import { listaConfiguracoesDaEmpresa, modificaLoadingConfiguracoesEmpresaExport } from "../actions/DashBoardAction";
import { sairDoApp } from "../actions/PerfilAction";


export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const backgroundPrimeiro = useSelector(state => state.CoresPadraoSistema.backgroundPrimeiro)
  const corBotaoPadrao = useSelector(state => state.CoresPadraoSistema.corBotaoPadrao)
  const loadingConfiguracoesEmpresa = useSelector(state => state.DashBoardReducer.loadingConfiguracoesEmpresa)
  let history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
    // console.log('onAuthStateChanged',user);
    
      setCurrentUser(user);
      setPending(false);
      if (user) {
        try {
        /*   app.auth().signOut();
          localStorage.removeItem('token');
          localStorage.removeItem('chaveUnicaCliente'); */
          const token = await user.getIdToken();
          /* console.log('onAuthStateChanged token',token); */
          const decodedToken = token ? jwt_decode(token) : null;
        /*   console.log('onAuthStateChanged decodedToken',decodedToken); */
          if (!decodedToken || !decodedToken.idCompany || decodedToken.idCompany === 'f36e2d6a-5bec-4612-adb3-2a98520db74a'||
          decodedToken.idCompany === 'null') {
         /*    console.log('fazendo login'); */
            await api.post('login');
            const newToken = await user.getIdToken(true);
          /*   console.log('newToken',jwt_decode(newToken)); */
            localStorage.setItem('token', newToken);
          } 
          dispatch(listaConfiguracoesDaEmpresa(history));
        } catch (e) {
          dispatch(sairDoApp());
          toastr.error('Error', 'Erro ao se autenticar');
        }
      } else {
        dispatch(modificaLoadingConfiguracoesEmpresaExport(false))
      }

    });
  }, []);

  if (loadingConfiguracoesEmpresa) {
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: JSON.parse(localStorage.getItem('backgroundPrimeiro')) == null ? backgroundPrimeiro : JSON.parse(localStorage.getItem('backgroundPrimeiro')), height: '100%' }}>
        <ClipLoader
          //  css={override}
          size={40}
          color={JSON.parse(localStorage.getItem('corBotaoPadrao')) == null ? corBotaoPadrao : JSON.parse(localStorage.getItem('corBotaoPadrao'))}
          loading={true}
        />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};