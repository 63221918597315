import styled, { keyframes } from 'styled-components';
import { shade, opacify, lighten, } from 'polished';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal } from 'react-responsive-modal';


const animacaoLoading = keyframes`
   0%{
        background-position: 0% 0%;
     }
     100%{
        background-position: -135% 0%;
     }
  }
`;
export const Title = styled.h1`
   font-size: 35px;
`;

export const Container = styled.div`
 display:flex;
 position:relative;
 align-items:center;
   width:100%;
   flex-direction:column;
   background:${props => props.backgroundDinamico};
`;
export const ContainerCfg = styled.div`
 display:flex;
   flex-direction:column;
   background:${props => props.backgroundDinamico};
   margin:10px;
   border-radius:10px;
`;
export const DivCabecalhoCfg = styled.div`
 display:flex;
   width:900px;
   flex-direction:column;
   justify-content:center;
   height:50px;
   margin:10px;
   padding:16px;
border-bottom:${props => `1px solid ${props.bordaDinamica}`};
`;
export const TextCabecalho = styled.h4`
color:${props => props.corLetraDinamica};
`;
export const DivConfiguracoes = styled.div`
 display:flex;
   width:900px;
   flex-direction:column;
   justify-content:center;
   padding:0px 0px 16px 16px;
`;

export const ContainerPermitir = styled.div`
 display:flex;
 justify-content:space-between;
 flex-direction:row;
 margin-top:10px;
`;
export const TextPermitir = styled.p`
font-size:16px;
text-align:center;
color:${props => props.corLetraDinamica};
`;
export const DivInputComentarChamado = styled.div`
 display:flex;
 flex-direction:column;
`;
export const InputDescricaoChamado = styled.textarea`
display:flex;
background-Color:transparent;
height:70px;
border:0;
padding:5px;
width:500px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBotaoPadrao};
  }
`;
export const InputDescricaoChamado2 = styled.textarea`
display:flex;
background-Color:transparent;
height:70px;
border:0;
padding:5px;
width:870px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBotaoPadrao};
  }
`;

export const TextMensagem = styled.p`
font-size:13px;
font-weight:bold;
color:${props => props.corLetraDinamica};
padding-left:5px;
margin-top:10px;
`;

export const InputDescricaoEmpresa = styled.textarea`
display:flex;
background-Color:transparent;
height:30px;
border:0;
padding:5px;
width:600px;
border-radius:4px;
border: 1px solid ${props => props.corLetraSecundaria};
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3;
}
&:focus {
  border:1px solid ${props => props.corBotaoPadrao};
  }
`;
export const IconeInterrogacao = styled.i`
font-size:15px;
margin-left:3px;
color:${props => props.colorDinamico};
cursor: pointer;

`;


export const DivColor = styled.div`
  padding: 5px;
          border-radius: 1px;
          display: inline-block;
          cursor: pointer;
`;
export const DivCor = styled.div`
  width: 36px;
          height: 16px;
          border-radius: 2px;
          border: 1px solid #CCC;
          background:${props => props.corSelecionadoDinamico};
`;

export const DivColorPiciker = styled.div`
  position: absolute;
          z-index: 2;
`;
export const DivCSelectColor = styled.div`
  position: fixed;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
`;

export const DivLetraeCor = styled.div`
 display:flex;
 flex-direction:row;
 width:500px;
 justify-content:space-between;
 align-items:center;
 padding-left:10px;
 padding-right:10px;
 margin-top:10px;
`;
export const TextDescCor = styled.p`
font-size:13px;
font-weight:bold;
color:${props => props.corLetraDinamica};
padding-left:5px;

`;
export const ContainerCores = styled.div`
 display:flex;
 flex-direction:column;
 width:800px;
 justify-content:space-between;
 border:1px solid ${props => props.corLetraSecundaria};
 padding-left:10px;
 padding-right:10px;

 border-radius:5px;
 padding-bottom:5px;
`;
export const ContainerSelectSearch = styled.div`
display:flex;
flex-direction:column;
flex:1;
padding-left:15px;
padding-right:15px;
padding-bottom:15px;
border-radius:5px;
`;
export const InputSearch = styled.input`
display:flex;
flex:1;
height:30px;
width:100%;
border-radius:5px;
padding-left:7px;
font-size:14px;
border:0;
color:${props => props.corLetraDinamico};
background-color: ${props => props.backgroundDinamico};

border:${props => `outset 0.5px ${props.corLetraDinamico}`};
cursor: pointer;
&::placeholder{
   color:${props => props.placeholderColor};
   font-style: italic;
}
&:hover{
  border:${props => `solid 0.5px ${props.corBordaDinamica}`};
 }

`;

export const DivInputSearch = styled.div`
 display:flex;
  flex-direction:row;
  align-items:center;

`;
export const IconeSetaPraBaixo = styled.i`
font-size: 12px;
position: absolute;
top:8px;
right: 19px;
width: 11px;
height: 11px;
color:${props => props.colorDinamico};
pointer-events: none;
`;
export const DivBotaoSalvarComentario = styled.div`
 display:flex;
 flex:1;
justify-content:flex-end;
align-items:flex-end;
`;
export const ButtonSalvarComentario = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
width:120px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
   background: ${props => shade(0.2, props.backgroundBotao)};
}
`;
export const TextBotaoSalvarComentar = styled.span`
  color:${props => props.backgroundBotao};
  font-size:14px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:5px;
 margin-left:5px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;
export const ContainerPermitirProblemaPReDefinido = styled.div`
 display:flex;
 justify-content:space-between;
 flex-direction:column;
 margin-top:10px;
 border-radius:5px;
border: 1px solid ${props => props.corBordaDinamica};

`;

export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:260px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;
export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;
export const ContainerInputEBotaoComent = styled.div`
 display:flex;
justify-content:center;
flex:1;
margin-top:40px;
flex-direction:column;
align-items:center;
`;
export const ButtonSalvarComentarioSemComent = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:40px;
width:120px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
cursor:default;
`;
export const ContainerBotaoAlterar = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;
`;
export const ButtonEntrar = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
letter-spacing: 1px;
font-size:21px;
font-weight:bold;
width:30%;
border:0;
transition: background-color 0.2s;
height:40px;
margin:10px;
margin-top:20px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;
export const ButtonResetCores = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
font-size:12px;
font-weight:bold;
width:80px;
border:0;
transition: background-color 0.2s;
height:30px;
margin:10px;
margin-top:20px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;
export const ButtonEscolherLogoTipo = styled.button`
display:flex;
justify-content:center;
align-items:center;

background-color:${props => props.backGroundDinamico};
color:${props => props.corLetraBotao};
font-size:14px;
font-weight:bold;
width:150px;
border:0;
transition: background-color 0.2s;
height:40px;
margin:10px;
margin-top:20px;
border-radius: 5px;
&:hover{
   background: ${props => shade(0.2, props.backGroundDinamico)};
}

`;
export const TextCabecalhoLoading = styled.div`
margin-left:3px;
height:20px;
border-radius:5px;
width:200px;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2,props.backgroundinamico)} 10%, ${lighten(0.2,props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextOpcaoLoading = styled.div`
margin-left:3px;
height:20px;
margin-top:10px;
border-radius:5px;
width:95%;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2,props.backgroundinamico)} 10%, ${lighten(0.2,props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextInputLoading = styled.div`
margin-left:3px;
height:70px;
margin-top:20px;
border-radius:5px;
width:870;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2,props.backgroundinamico)} 10%, ${lighten(0.2,props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextOpcaoCorLoading = styled.div`
margin-left:3px;
height:20px;
margin-top:10px;
border-radius:5px;
width:500px;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2,props.backgroundinamico)} 10%, ${lighten(0.2,props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
export const TextNomeEmpresaLoading = styled.div`
margin-left:3px;
height:20px;
margin-top:10px;
border-radius:5px;
width:600px;
max-width:95%;
background:${props => `linear-gradient(to right, ${lighten(0.2,props.backgroundinamico)} 10%, ${lighten(0.2,props.backgroundinamico)} 28%, ${props.backgroundinamico} 33%)`};
animation:${animacaoLoading} 1.2s linear infinite;
  background-size: 400% 400%; 
`;
