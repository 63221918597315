import {
    MODIFICANOMEROTANOHEADER,MODIFICAPESQUISATESTE,MODIFICABERTURAMENU
} from './types';

export const modificaNomeRotaNoHeader = (nome) => {
    return {
        type: MODIFICANOMEROTANOHEADER,
        payload: nome
    }
};
export const modificaPesquisaTeste = (pesq) => {
    return {
        type: MODIFICAPESQUISATESTE,
        payload: pesq
    }
};
export const modificaAberturaMenu = (value) => {
    return {
        type: MODIFICABERTURAMENU,
        payload: value
    }
};