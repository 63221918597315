
import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { Howl, Howler } from 'howler';
import {
  MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO, VISIBLESELECTSEARCH, MODIFICANOMEROTANOHEADER,
  MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO,
  MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO, MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO,
  MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO, MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO,
  MODIFICAVISIBILIDADEMODALCOMENTARIo, MODIFICAINPUTCOMENTARIO, MODIFICACOMENTARIOPUBLICOSIMOUNAO,
  MODIFICAARQUIVOCOMOPLETOPARAUPAR,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
  ZERAMENSAGEMSEMINTERNETCHAMADOABERTO,
  ENVIASETORPARAREDUCER,
  ALTERASETOR,
  ALTERAPRIORIDADE,
  ALTERALOCALATENDIMENTO,
  ALTERADESCRICAOCHAMADO,
  ENVIAFOTOCLIENTEPARAREDUCER,
  INSEREFOTOCADASTROCLIENTE,
  MODIFICANOMECLIENTETELACADASTROCLIENTE,
  MODIFICAEMPRESACLIENTETELACADASTROCLIENTE,
  MODIFICAEMAILCLIENTETELACADASTROCLIENTE,
  MODIFICASENHACLIENTETELACADASTROCLIENTE,
  MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE,
  MENSAGEMCADASTRACLIENTEERROR,
  MENSAGEMCADASTRACLIENTESUCESSO,
  ATIVALOADINGBOTAOCADASTRARCLIENTE,
  ALTERAVALORDIGITOCONSULTA,
  ALTERAVALORSWITCHTELACLIENTE,
  ZERAINFORMACOESCADASTROCLIENTE,
  INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA,
  MODIFICACLIENTESELECIONADO,
  ENVIACLIENTEPARAREDUCER,
  ALTERAMAISDETALHESTELANOVOCHAMADO,
  ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
  ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
  ENVIAATENDENTEPARAREDUCER,
  MODIFICAATENDENTESELECIONADO,
  MODIFICAPRIORIDADESELECIONADO,
  MODIFICALOCALSELECIONADO,
  ATIVALOADINGCARREGARSETORTELANOVOCHAMADO,
  MODIFICASETORSELECIONADO,
  ALTERAEMPRESACLIENTETELAALTERAR,
  ALTERANOMECLIENTETELAALTERAR,
  ALTERAREMAILCLIENTETELAALTERAR,
  ALTERARPERMISSAOCLIENTETELAALTERAR,
  ALTERASENHACLIENTETELAALTERAR,
  ALTERACONFIRMASENHACLIENTETELAALTERAR,
  ALTERAFOTOCLIENTETELAALTERAR,
  INSEREFOTOALTERARCLIENTE,
  ALTERAKEYCLIENTETELAALTERAR,
  ATIVALOADINGBOTAOALTERARCLIENTE,
  MENSAGEMCADASTRACLIENTEERRORTELAALTERAR,
  MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR,
  ZERAMENSAGEMDESUCESSOEERRO,
  ZERAMSGSUCESSOEERROCADASTRACLIENTE,
  SEMCONEXAOINTERNETCONSULTACLIENTE,
  SEMCONEXAOINTERNETCADASTRACLIENTE,
  SEMCONEXAOINTERNETALTERACLIENTE,
  ATIVALOADINGBOTAOCADASTRARCHAMADO,
  SEMCONEXAOINTERNETCADASTRACHAMADO,
  CHAMADOCADASTRADOSUCESSO,
  MODIFICANOMEARQUIVO1,
  MODIFICANOMEARQUIVO2,
  MODIFICANOMEARQUIVO3,
  MODIFICARESARQUIVO1,
  MODIFICARESARQUIVO2,
  MODIFICARESARQUIVO3,
  LISTACHAMADOABERTO,
  VERIFICACONEXAOINTERNETCHAMADOABERTO,
  ATIVALOADINGTELACHAMADOABERTO,
  ENVIAULTIAMREFERENCIAKEY,
  MODIFICANOMECLIENTESELECIONADO,
  MODIFICANOMEATENDENTESELECIONADO,
  ZERACADASTROCHAMADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
  MODIFICAMOSTRARMAISCHAMADOS,
  ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO,
  ENVIACHAMADOEMATENDIMENTO,
  ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
  ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
  ENVIACHAMADOFECHADO,
  ATIVADESATIVALOADINGTELACHAMADOFECHADO,
  VERIFICACONEXAOINTERNETCHAMADOFECHADO,
  ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO,
  ENVIAULTIAMREFERENCIAKEYFECHADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
  ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO,
  ENVIACHAMADOCANCELADO,
  VERIFICACONEXAOINTERNETCHAMADOCANCELADO,
  ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
  ENVIAULTIAMREFERENCIAKEYCANCELADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
  ENVIACHAMADOTODOS,
  ATIVADESATIVALOADINGTELACHAMADOTODOS,
  MODIFICAPOSICAOATUALFILA,
  ZERAMENSAGEMSEMINTERNETCHAMADOTODOS,
  ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE,
  ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO,
  ENVIAULTIAMREFERENCIACHAMADOFECHADO,
  ENVIAULTIAMREFERENCIACHAMADOCANCELADO,
  ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
  INDICAFIMDALISTA,
  ENVIAULTIAMREFERENCIAKEYTODOS,
  ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS,
  ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS,
  MODIFICACLIENTESELECIONADOTELACHAMADOTODOS,
  MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS,
  ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS,
  ATIVADESATIVALOADINGTELACONSULTACLIENTE,
  ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE,
  ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE,
  ENVIACLIENTESECTIONEDTELACONSULTACLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE,
  MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE,
  MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE,
  ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO,
  ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO,
  MODIFICADATAVENCIMENTOTELACHAMADOABERTURA,
  MODIFICAARQUIVOUPADO1,
  MODIFICAARQUIVOUPADO2,
  MODIFICAARQUIVOUPADO3,
  MODIFICACONTAGEMARQUIVO,
  MODIFICATAMANHOARQUIVO1,
  MODIFICATAMANHOARQUIVO2,
  MODIFICATAMANHOARQUIVO3,
  ALTERANUMEROATENDIMENTO,
  ALTERAKEYCHAMADO,
  ENVIAATENDIMENTOPARAREDUCER,
  ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
  ENVIAUSUARIOSTELAACOESATENDIMENTO,
  VERIFICAERRORTELAALTERARCHAMADO,
  VERIFICACHAMADOALTERADOSUCESSO,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2,
  ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3,
  ENVIAACOESATENDIMENTOPARAREDUCER,
  MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
  MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO,
  MODIFICAVISIBILIDADEMODALCANCELAR,
  ATIVADESATIVASWITCHCOMENTARIO,
  MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
  MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
  ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
  ZERAINFORMACOESCOMENTARIO,
  ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO,
  ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO,
  ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO,
  ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO,
  DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL,
  ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE,
  MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE,
  DESATIVALOADINGBOTAOCADASTRARCLIENTE,
  MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
  VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
  ALTERACLIENTESELECIONADOTELACADASTRACLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE,
  ATIVADESATIVALOADINGTELAALTERARCLIENTE, MODIFICATABINDEX,
  ENVIAEMPRESAPRINCIPALCLIENTE, MODIFICAENDERECOTELACADASTROCLIENTE, MODIFICATELEFONETELACADASTROCLIENTE,
  ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE, ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
  MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE, MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO, MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO,
  ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE, MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
  ENVIACLIENTEPARATELARELATORIODETALHADO, LOADINGCLIENTETELARELATORIODETALHADO,
  MODIFICANOMESETORSELECIONADO, MODIFICAMODALFILTROCHAMADO, MODIFICAFILTROCHAMADO, MODIFICASELECTTEXTCHAMADO, MODIFICAPESQUISATEXTCHAMADO,
  MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO, MODIFICAFILTROSELECIONADOTELACHAMADOABERTO, MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO,
  MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO, MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO, MODIFICAFILTROSELECIONADOTELACHAMADOTODOS,
  ATIVADESATIVALOADINTESTETSTE, MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO, MODIFICAFILTROCLIENTESTELACLIENTES,
  MODIFICAVISIBLEMODALNOVOCLIENTE,
  MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO, ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
  MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO,
  MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO, MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO,
  MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO, MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
  ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO, MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
  MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO, ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE,
  ATIVADEASTIVALOADINGTELASOLICITACOESEMPRESA, ATIVADESATIVALOADIINGFIMDAPAGINASOLICITACOESEMPRESA,
  ENVIACHAMADOSSOLICITACOESEMPRESA, ENVIAULTIMAREFERENCIAKEYSOLICITACOESEMPRESA, ATIVADESATIVALOADINGFINALFLATLISTSOLICITACOESEMPRESA,
  ENVIASEFEZAVALIACAOCHAMADO, ENVIASETEMAUTORIZACAOPARAAVALIARCHAMADO, ABREMODALAVALIARCHAMADO, MODIFICACOMENTARIOAVALIACAO,
  ENVIAPROBLEMASESTATICOSPARAREDUCER, MODIFICANOMEPROBLEMAESTATICOSELECIONADO, MODIFICAPROBLEMAESTATICOSELECIONADO,
  ATIVADESATIVALOADINGCARREGAPROBLEMASESTATICOS, MODIFICATELAATUALCHAT, ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
  ENVIASEFEZAVALIACAOCHAMADOCHAT, ENVIASETEMAUTORIZACAOPARAAVALIARCHAMADOCHAT, ENVIAATENDIMENTOPARAREDUCERCHAT,
  MODIFICAKEYATENDIMENTOCHAT, ALTERACOMENTARIOCHAT, MODIFICAUPANDOARQUIVOCHAT, MODIFICATEXTARQUIVOCHAT, MODIFICAATENDIMENTOONLINEVISIBLE,
  MODIFICAVISIBILIDADETELACHATOFFLINE, ZERAINFORMACOESENVIOARQUIVOCHAT, ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
  MODIFICARESOLUCAOPROBLEMA, ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADO, ENVIAREFERENCIAULTIMAKEYACOESCHAMADO,
  ENVIACONVERSASANTIGASCHAT,
  ENVIAIDRESPOSTAANTIGA, ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS,

  ENVIAIDRESPOSTAANTIGAACOESCHAMADO, ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO,
  ENVIACONVERSASANTIGASCHATACOESCHAMADO, ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO,
  ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO
} from './types';
import { toastr } from 'react-redux-toastr';
import soundnotification from '../imagens/notificationsound.mp3';


export const deletaMensagemDropDesk = (dadosMensagem, keyChamado, viaChat, isLastMessage) => {
  return dispatch => {
    let keyAcoesChamado = dadosMensagem.key;
    console.log(dadosMensagem, keyChamado, keyAcoesChamado, viaChat)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      //firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamado}`).update({ deleteMessage: true })
      if (isLastMessage === true) {
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamado}`).update({ deleteMessage: true, /* mensagem: 'Mensagem Apagada', tipo: 'mensagem', audio: false */ })
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({ lastMessageChat: 'Mensagem Apagada'/* , lastmessageChatType: 'mensagem' */ })

      } else {
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${keyAcoesChamado}`).update({ deleteMessage: true,/*  mensagem: 'Mensagem Apagada', tipo: 'mensagem', audio: false */ })
      }
    })
  }
}


export const desligaOuvintesChamadosFirebase = (tela, atendenteVeApenasProprioChamado, agruparAtendenteSetor, contaPrincipal, criarProblemaEstatico) => {
  return dispatch => {
    //console.log(tela, atendenteVeApenasProprioChamado, agruparAtendenteSetor, contaPrincipal, criarProblemaEstatico)
    AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
      if (chaveUnica2) {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        if (tela == 'atendimentoAberto') {
          const { currentUser } = firebase.auth();
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString()
            firebase.database().ref(`${chaveUnica}/chamados`).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").equalTo(key).limitToLast(28).off("value");
            //  console.log('ouvintes de ATENDIMENTOS ABERTO DESLIGADOS')
          })
        }
        else if (tela == 'atendimentoEmAtendimento') {
          //  console.log('ouvintes atendimento em Atendimento')
          if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
            // console.log('1');
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
              let key = Object.keys(snapshot.val()).toString();
              const filtro = `Atendendo${key}`;
              //  console.log(filtro)
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                .endAt(filtro).limitToLast(8).off("value");
            })
          } else {
            //console.log('2');
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Atendendo").limitToLast(7).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Atendendo").endAt("Atendendo").limitToLast(8).off("value");
            // console.log('ouvintes desligados em Atendimento')
          }
        }
        else if (tela == 'atendimentoFechado') {
          // console.log('ouvintes atendimento em Fechado')
          if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
            // console.log('1');
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
              let key = Object.keys(snapshot.val()).toString();
              const filtro = `Fechado${key}`;
              //  console.log(filtro)
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                .endAt(filtro).limitToLast(8).off("value");
            })
          } else {
            // console.log('2');
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Fechado").limitToLast(7).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Fechado").endAt("Fechado").limitToLast(8).off("value");
            // console.log('ouvintes desligados em Fechado')
          }
        }
        else if (tela == 'atendimentoCancelado') {
          //console.log('ouvintes atendimento em Cancelado')
          if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
            // console.log('1');
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
              let key = Object.keys(snapshot.val()).toString();
              const filtro = `Cancelado${key}`;
              // console.log(filtro)
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").equalTo(filtro).limitToLast(7).off("value");
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente").startAt(filtro)
                .endAt(filtro).limitToLast(8).off("value");
            })
          } else {
            //console.log('2');
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").equalTo("Cancelado").limitToLast(7).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status").startAt("Cancelado").endAt("Cancelado").limitToLast(8).off("value");
            // console.log('ouvintes desligados em Cancelado')
          }
        }
        else if (tela == 'atendimentoTodos') {
          // console.log('ouvintes atendimento Todos')
          if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
            //  console.log('1');
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
              let key = Object.keys(snapshot.val()).toString();
              const filtro = key;
              //  console.log(filtro)
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('atendente').equalTo(filtro)
                .limitToLast(7).off("value");
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('atendente').startAt(filtro).endAt(filtro)
                .limitToLast(8).off("value");
            })
          } else {
            // console.log('2');
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().limitToLast(7).off("value");
            // console.log('ouvintes desligados em Todos')
          }
        }
        else if (tela == 'telaAberturaChamados') {
          if (agruparAtendenteSetor == true) {
            firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
          }
          if (criarProblemaEstatico == true) {
            firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).off("value");
          }
          // console.log('ouvintes de tela abertura chamados DESLIGADOS')

        }
        else if (tela == 'atendimentos') {
          const { currentUser } = firebase.auth();
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString()
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome,
                nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
                keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
              })
            })
            let keyEmpresaVinculada = arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada;
            // console.log(key)
            // console.log(keyEmpresaVinculada)

            firebase.database().ref(`${chaveUnica}/chamados`).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("cliente").equalTo(key).limitToLast(28).off("value");
            // console.log('ouvintes de ATENDIMENTOS DESLIGADOS')
          })

        }
        else if (tela == 'solicitacoesempresa') {
          const { currentUser } = firebase.auth();
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString()
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome,
                nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
                keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
              })
            })
            let keyEmpresaVinculada = arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada;
            // console.log(key)
            // console.log(keyEmpresaVinculada)
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").equalTo(keyEmpresaVinculada).limitToLast(7).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").startAt(keyEmpresaVinculada).endAt(keyEmpresaVinculada).limitToLast(8).off("value");
            //  console.log('ouvintes de SOLICITACOES EMPRESA DESLIGADOS')
          })

        }
        else if (tela == 'atendimentossolicitacoesempresa') {
          const { currentUser } = firebase.auth();
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString()
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome,
                nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
                keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
              })
            })
            let keyEmpresaVinculada = arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada;
            // console.log(key)
            // console.log(keyEmpresaVinculada)
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").equalTo(keyEmpresaVinculada).limitToLast(7).off("value");
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").startAt(keyEmpresaVinculada).endAt(keyEmpresaVinculada).limitToLast(8).off("value");
            //  console.log('ouvintes de ATENDIMENTOS SOLICITACOES EMPRESA DESLIGADOS')
          })

        }
        else if (tela == 'chat') {
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
            let key = Object.keys(snapshot.val()).toString();
            const filtro = `Atendendo${key}true`;
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo(filtro).off("value");
            firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).off("value");
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
            firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
            if (agruparAtendenteSetor == true && contaPrincipal == false) {
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild(`usuariosVisualizaAtend/dadosAtendVinc/${key}Chat`).equalTo(true).off("value");
            } else {
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo("AbertoNão informadotrue").off("value");

            }
            // console.log('ouvintes de CHAT DESLIGADOS')
          })
        }
        else if (tela == 'clientes') {
          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(null).limitToLast(10).off("value");
          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("excluido").startAt(null).endAt(null).limitToLast(11).off("value");
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
          // console.log('ouvintes de CLIENTES DESLIGADOS')
        }
        else if (tela == 'atendentes') {
          firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).limitToFirst(10).off("value");
          firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("excluido").startAt(null).endAt(null).limitToFirst(11).off("value");
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          // console.log('ouvintes de ATENDENTES DESLIGADOS')
        }
        else if (tela == 'setores') {
          firebase.database().ref(`${chaveUnica}/setores/`).orderByChild('excluido').equalTo(null).limitToLast(10).off("value");
          firebase.database().ref(`${chaveUnica}/setores/`).orderByChild("excluido").startAt(null).endAt(null).limitToLast(11).off("value");
          firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
          /// console.log('ouvintes de SETORES DESLIGADOS')
        }
        else if (tela == 'cadastrosetor') {
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          // console.log('ouvintes de CADASTRO SETORES DESLIGADOS')
        }
        else if (tela == 'alterasetor') {
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          //  console.log('ouvintes de ALTERAR SETORES DESLIGADOS')
        }
        else if (tela == 'consultaavisos') {
          firebase.database().ref(`${chaveUnica}/avisos/`).orderByKey().limitToLast(10).off("value");
          // firebase.database().ref(`${chaveUnica}/avisos/`).orderByKey().limitToLast(11).off("value");
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
          // console.log('ouvintes de CONSULTA AVISOS DESLIGADOS')
        }
        else if (tela == 'relatorioatendimentodetalhado') {
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          firebase.database().ref(`${chaveUnica}/setores`).orderByChild("nome").off("value");
          //console.log('ouvintes de RELATORIO ATENDIMENTO DETALHADO DESLIGADOS')
        }
        else if (tela == 'relatorioavaliacaoatendente') {
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          // console.log('ouvintes de RELATORIO AVALIACAO ATENDENTE DESLIGADOS')
        }
        else if (tela == 'relatorioavaliacaotodas') {
          firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild("nome").off("value");
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild("empresa").off("value");
          firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().limitToLast(9).off("value");
          // firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByKey().endAt(null).limitToLast(10).off("value");
          //  console.log('ouvintes de RELATORIO AVALIACAO TODAS DESLIGADOS')
        }
        else if (tela == 'configuracaoatendimento') {
          firebase.database().ref(`${chaveUnica}/respostapadrao`).orderByKey().off("value");
          firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().off("value");
          //  console.log('ouvintes de  CONFIGURACAO ATENDIMENTO DESLIGADOS')
        }
        else if (tela == 'corlogomarca') {
          firebase.database().ref(`${chaveUnica}/configuracao/cores`).off("value");
          // console.log('ouvintes de  COR E LOGO MARCA DESLIGADOS')
        }
        else if (tela == 'configuracaochat') {
          firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).off("value");
          // console.log('ouvintes de  CONFIGURACAO CHAT DESLIGADOS')
        }

        else if (tela == 'chamados') {
          firebase.database().ref(`${chaveUnica}/chamados`).off("value");
        }
        else {
          // console.log('no else desliga ouvintes')
        }
      } else {
        // console.log('chave Unica null desativando os ouvintes')
      }
    })
  }
}


export const listaChamadosDaEmpresa = (valor, referenciaUltimaKey, destino, chamadosAnteriores) => {
  return dispatch => {
    // console.log(`antes do check fechado`);
    let referenceToOldestKey, keyEmpresaVinculada;

    // console.log(`depois do check fechado`);
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (valor == 0) {
        // if initial fetch
        //   console.log(`will moun`);
        ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
        ativaDesativaLoadingFimDaPAginaSolicitacoesEmpresa(dispatch, true);
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome,
              nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
              keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
            })
          })
          // console.log('dentro do snapshot primeira consulta')
          // console.log(arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada)
          keyEmpresaVinculada = arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada;

          // console.log('key da empresa vinculada')
          // console.log(keyEmpresaVinculada + 'aqui');
          if (keyEmpresaVinculada == undefined || keyEmpresaVinculada == '' || keyEmpresaVinculada == 'Não Vinculado') {
            // console.log('cliente sem empresa vinculada')
            ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
            ativaDesativaLoadingFimDaPAginaSolicitacoesEmpresa(dispatch, false);
            enviaChamadosSolicitacoesEmpresa(dispatch, []);
          } else {
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").equalTo(keyEmpresaVinculada).limitToLast(7).on("value", snapshot => {
              if (snapshot.val() == null) {
                enviaChamadosSolicitacoesEmpresa(dispatch, []);
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                ativaDesativaLoadingFimDaPAginaSolicitacoesEmpresa(dispatch, false);
                // console.log(`sem chamado vinculado`);
              } else {
                // console.log('tem chamados vinculado')
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                let chamados = [];
                let arrayChamado = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                for (let i = 0; i < results.length; i++) {
                  let cliente = results[i].cliente;
                  let atendente = results[i].atendente;
                  let setor = results[i].setor;
                  promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                  promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                  promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                  arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                Promise.all(arrayChamado).then(responses => {
                  for (let i = 0; i < responses.length; i += 3) {
                    chamados.push({
                      key: arrayOfKeys[aux],
                      //cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      //  empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                      cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                      atendente: responses[i].val() == null ? "Não informado" : responses[i].val(),
                      setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      status: results[aux].status,
                      prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      keyEmpresaVinculada: results[aux].keyEmpresaVinculada,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,

                    });
                    aux++;
                  }
                  if (chamados.length == 7) {
                    ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, true);
                  } else {
                    ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, false);
                  }
                  enviaChamadosSolicitacoesEmpresa(dispatch, chamados);
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                  ativaDesativaLoadingFimDaPAginaSolicitacoesEmpresa(dispatch, false);
                  enviaUltimaReferenciaKeySolicitacoesEmpresa(dispatch, referenceToOldestKey);
                  //  console.log(`com chamado vinculado`);
                  //  console.log(chamados)
                });
              }
            })
          }
        })
      } else {
        // console.log('referencia ultima key')
        // console.log(referenciaUltimaKey)
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome,
                nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
                keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
              })
            })
            //   console.log('dentro do snapshot segunda consulta')
            //  console.log(arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada)
            keyEmpresaVinculada = arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada;
            if (keyEmpresaVinculada == undefined || keyEmpresaVinculada == '' || keyEmpresaVinculada == 'Não Vinculado') {
              // console.log('cliente sem empresa vinculada2')

            } else {
              //   console.log('key da empresa vinculada 2')
              //  console.log(keyEmpresaVinculada + 'aqui');
              firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("keyEmpresaVinculada").startAt(keyEmpresaVinculada).endAt(keyEmpresaVinculada, referenciaUltimaKey).limitToLast(8).on("value", snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, false);
                } else {
                  //ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, true);
                  // changing to reverse chronological order (latest first)
                  // & removing duplicate
                  let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // updating reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  let chamados = [];
                  let arrayChamado = [];
                  let promisseCliente;
                  let promisseAtendente;
                  let promisseSetor;
                  for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                  }
                  let aux = 0;
                  Promise.all(arrayChamado).then(responses => {
                    for (let i = 0; i < responses.length; i += 3) {
                      chamados.push({
                        key: arrayOfKeys[aux],
                        //  cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        //  empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        atendente: responses[i].val() == null ? "Não informado" : responses[i].val(),
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade: results[aux].prioridade === "" ? "Não informado" : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        dataHoraCancelamento: results[aux].dataHoraCancelamento,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        keyEmpresaVinculada: results[aux].keyEmpresaVinculada,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                      aux++;
                    }
                    // ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, false);
                    enviaChamadosSolicitacoesEmpresa(dispatch, chamadosAnteriores.concat(chamados));
                    enviaUltimaReferenciaKeySolicitacoesEmpresa(dispatch, referenceToOldestKey);
                    //console.log('com chamados segunda consulta')
                  });
                }
              })
            }
          })
        } else {
          ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa(dispatch, false);
        }
      }
    });

  }
}

export const listaOsChamados = (valor, referenciaUltimaKey, chamadoAbertoAnterior, chamadoEmAtendimentoAnterior, chamadoFechadoAnterior, chamadoCanceladoAnterior, chamadoTodosAnteiror) => {
  return dispatch => {
    // console.log(valor, referenciaUltimaKey, chamadoAbertoAnterior, chamadoEmAtendimentoAnterior, chamadoFechadoAnterior, chamadoCanceladoAnterior, chamadoTodosAnteiror)
    let referenceToOldestKey;
    let primeiraVerificacao = false;

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (valor == 0) {
        ativaLoadingTelaAberturaChamado(dispatch)
        ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, true);
        ativaDesativaLoadingTelaChamadoAtendimento(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, true);
        ativaDesativaLoadingTelaChamadoFechado(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, true);
        ativaDesativaLoadingTelaChamadoCancelado(dispatch, true);
        ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, true);
        ativaDesativaLoadingTelaChamadoTodos(dispatch, true);



        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot2) => {
          let key = Object.keys(snapshot2.val()).toString()
          // console.log(key)
          firebase.database().ref(`${chaveUnica}/chamados/`)
            .orderByChild("cliente")
            .equalTo(key)
            .limitToLast(7)
            //.limitToLast(28)
            .on("value", snapshot => {
              // console.log(snapshot)
              if (snapshot.val() == null) {
                //  console.log('null na primeira consulta')
                enviaChamadoAberto(dispatch, []);
                desativaLoadingTelaChamadoAberto(dispatch);
                ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                enviaChamadoEmAtendimento(dispatch, []);
                ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, false);
                enviaChamadoFechado(dispatch, []);
                ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, false);
                enviaChamadoCancelado(dispatch, []);
                ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, false);
                enviaChamadoTodos(dispatch, []);
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
              } else {

                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys
                  .map((key) => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                // console.log(arrayOfKeys);
                //  console.log(results);
                let chamados = [];
                let chamadosAtendimento = [];
                let chamadosFechado = [];
                let chamadosCancelado = [];
                let todosChamados = []
                let arrayChamado = [];
                let promisseCliente;
                let promisseAtendente
                let promisseSetor;
                for (let i = 0; i < results.length; i++) {
                  let cliente = results[i].cliente;
                  let atendente = results[i].atendente;
                  let setor = results[i].setor;
                  promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                  promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                  promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                  arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                Promise.all(arrayChamado).then(responses => {
                  for (let i = 0; i < responses.length; i += 3) {

                    if (results[aux].status == 'Aberto') {
                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,

                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                    }
                    if (results[aux].status == 'Atendendo') {
                      chamadosAtendimento.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                    }
                    if (results[aux].status == 'Fechado') {
                      chamadosFechado.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                    }
                    if (results[aux].status == 'Cancelado') {
                      chamadosCancelado.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraCancelamento: results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                    }
                    todosChamados.push({
                      key: arrayOfKeys[aux],
                      cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                      atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                      setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      status: results[aux].status,
                      prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    });
                    aux++

                  }
                  //aberto
                  if (chamados.length == []) {
                    enviaChamadoAberto(dispatch, []);
                  } else {
                    enviaChamadoAberto(dispatch, chamados);
                  }
                  //atendimento
                  if (chamadosAtendimento.length == []) {
                    enviaChamadoEmAtendimento(dispatch, []);
                  } else {
                    enviaChamadoEmAtendimento(dispatch, chamadosAtendimento);
                  }
                  //fechado
                  if (chamadosFechado.length == []) {
                    enviaChamadoFechado(dispatch, []);
                  } else {
                    enviaChamadoFechado(dispatch, chamadosFechado);
                  }
                  //cancelado
                  if (chamadosCancelado.length == []) {
                    enviaChamadoCancelado(dispatch, []);
                  } else {
                    enviaChamadoCancelado(dispatch, chamadosCancelado);
                  }
                  //todos
                  if (todosChamados.length == []) {
                    enviaChamadoTodos(dispatch, []);
                  } else {
                    enviaChamadoTodos(dispatch, todosChamados);
                  }
                  //   console.log(chamados.length)
                  //  console.log(chamadosAtendimento.length)
                  //  console.log(chamadosFechado.length)
                  //  console.log(chamadosCancelado.length)
                  let somaChamados = chamados.length + chamadosAtendimento.length + chamadosFechado.length + chamadosCancelado.length;
                  //  console.log('somaChamados')
                  // console.log(somaChamados)
                  //console.log(todosChamados.length)
                  if (somaChamados == 7) {
                    // console.log('enviando referencia primeiro metodo')
                    // console.log(referenceToOldestKey)
                    enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  }
                  desativaLoadingTelaChamadoAberto(dispatch)
                  ativaDesativaLoadingRefreshControlChamadoAberto(dispatch, false);
                  //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                  ativaDesativaLoadingTelaChamadoAtendimento(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoEmAtendimento(dispatch, false);
                  ativaDesativaLoadingTelaChamadoFechado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoFechado(dispatch, false);
                  ativaDesativaLoadingTelaChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingRefreshControlChamadoCancelado(dispatch, false);
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                });

                //  console.log(referenceToOldestKey)
                //    enviaChamadoAberto(dispatch, chamados);
                //enviaUltimaReferenciaKey(dispatch, referenceToOldestKey)
              }
            })
        })
        /* .catch((error) => { console.log(error) }); */
      } else {
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
          ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, true);
          const { currentUser } = firebase.auth();
          firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot2) => {
            let key = Object.keys(snapshot2.val()).toString()
            // console.log(key)
            firebase.database().ref(`${chaveUnica}/chamados/`)
              .orderByChild("cliente")
              .startAt(key)
              .endAt(key, referenciaUltimaKey)
              .limitToLast(29)
              .once("value").then((snapshot) => {
                //   console.log(snapshot.val())
                if (snapshot.val() == null) {
                  //   console.log('sem resultados segunda consulta chamados')
                  ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                } else {

                  //ativar loading fim da pagina

                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  //.reverse();
                  // transforming to array
                  let results = arrayOfKeys
                    .map((key) => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                  // console.log(arrayOfKeys);
                  // console.log(results);
                  let chamados = [];
                  let chamadosAtendimento = [];
                  let chamadosFechado = [];
                  let chamadosCancelado = [];
                  let todosChamados = []
                  let arrayChamado = [];
                  let promisseCliente;
                  let promisseAtendente
                  let promisseSetor;
                  for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                  }
                  let aux = 0;
                  Promise.all(arrayChamado).then(responses => {
                    for (let i = 0; i < responses.length; i += 3) {

                      if (results[aux].status == 'Aberto') {
                        chamados.push({
                          key: arrayOfKeys[aux],
                          cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                          urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                          atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                          setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          status: results[aux].status,
                          prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                      }
                      if (results[aux].status == 'Atendendo') {
                        chamadosAtendimento.push({
                          key: arrayOfKeys[aux],
                          cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                          urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                          atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                          setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          status: results[aux].status,
                          prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                      }
                      if (results[aux].status == 'Fechado') {
                        chamadosFechado.push({
                          key: arrayOfKeys[aux],
                          cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                          urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                          atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                          setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          status: results[aux].status,
                          prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          dataHoraFechamento: results[aux].dataHoraFechamento,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                      }
                      if (results[aux].status == 'Cancelado') {
                        chamadosCancelado.push({
                          key: arrayOfKeys[aux],
                          cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                          urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                          atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                          setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          status: results[aux].status,
                          prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          dataHoraCancelamento: results[aux].dataHoraCancelamento,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                      }
                      todosChamados.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        atendente: (responses[i].val() == null) ? 'Não informado' : responses[i].val(),
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade: (results[aux].prioridade === '') ? 'Não informado' : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        dataHoraCancelamento: results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                      aux++

                    }
                    //aberto
                    if (chamados.length == []) {
                      // enviaChamadoAberto(dispatch, [{ clientes: 'semChamadosAbertos', alterado: 0, key: '1' }]);
                    } else {
                      // console.log(chamados)
                      if (chamadoAbertoAnterior.length == 0) {
                        enviaChamadoAberto(dispatch, chamados);
                      } else {
                        enviaChamadoAberto(dispatch, chamadoAbertoAnterior.concat(chamados));
                      }

                    }
                    //atendimento
                    if (chamadosAtendimento.length == []) {
                      // enviaChamadoEmAtendimento(dispatch, [{ clientes: 'semChamadosEmAtendimento', alterado: 0, key: '1' }]);
                    } else {

                      if (chamadoEmAtendimentoAnterior.length == 0) {
                        enviaChamadoEmAtendimento(dispatch, chamadosAtendimento);
                      } else {
                        enviaChamadoEmAtendimento(dispatch, chamadoEmAtendimentoAnterior.concat(chamadosAtendimento));
                      }

                    }
                    //fechado
                    if (chamadosFechado.length == []) {
                      //enviaChamadoFechado(dispatch, [{ clientes: 'semChamadosEmAtendimento', alterado: 0, key: '1' }]);
                    } else {

                      if (chamadoFechadoAnterior.length == 0) {
                        enviaChamadoFechado(dispatch, chamadosFechado);
                      } else {
                        enviaChamadoFechado(dispatch, chamadoFechadoAnterior.concat(chamadosFechado));
                      }
                    }
                    //cancelado
                    if (chamadosCancelado.length == []) {
                      //enviaChamadoCancelado(dispatch, [{ clientes: 'semChamadosEmAtendimento', alterado: 0, key: '1' }]);
                    } else {

                      if (chamadoCanceladoAnterior.length == 0) {
                        enviaChamadoCancelado(dispatch, chamadosCancelado);
                      } else {
                        enviaChamadoCancelado(dispatch, chamadoCanceladoAnterior.concat(chamadosCancelado));
                      }
                    }
                    //todos
                    if (todosChamados.length == []) {
                      // enviaChamadoTodos(dispatch, [{ clientes: 'semChamadosEmAtendimento', alterado: 0, key: '1' }]);
                    } else {
                      if (chamadoTodosAnteiror.length == 0) {
                        enviaChamadoTodos(dispatch, todosChamados);
                      } else {
                        enviaChamadoTodos(dispatch, chamadoTodosAnteiror.concat(todosChamados));
                      }
                    }
                    let somaChamados = chamados.length + chamadosAtendimento.length + chamadosFechado.length + chamadosCancelado.length;
                    //   console.log('somaChamados')
                    //  console.log(somaChamados)
                    //console.log(todosChamados.length)
                    if (somaChamados == 28) {
                      // console.log('enviando referencia segundo metodo')
                      //  console.log(referenceToOldestKey)
                      enviaUltimaReferenciaKey(dispatch, referenceToOldestKey);
                    } else {
                      enviaUltimaReferenciaKey(dispatch, '');
                    }
                    ativaDesativaLoadingMaisResultadosChamadosCliente(dispatch, false);
                  });
                }
              })
          })
        }
      }



    })


  }
}
export const navegaTelaALterarChamadoNotificacao = (keyChamado, numeroAtendimento2) => {
  return dispatch => {
    //  console.log(dispatch)
    //  console.log(NavigationActions)
    let numeroAtendimento = parseInt(numeroAtendimento2);
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //   dispatch(NavigationActions.navigate({ routeName: "AcoesAtendimento" }));
  };
};


export const modificaAtendimentoOnlineVisible = key => {
  return {
    type: MODIFICAATENDIMENTOONLINEVISIBLE,
    payload: key
  };
};
export const alteraKeyAtendimento = key => {
  // console.log('no altera key')
  //console.log(key)
  return {
    type: ALTERAKEYCHAMADO,
    payload: key
  };
};
export const alteraNumeroAtendimento = key => {
  return {
    type: ALTERANUMEROATENDIMENTO,
    payload: key
  };
};








export const consultaClienteComCliente = keyCliente => {
  return dispatch => {

    ativaDesativaLoadingTelaConsultaCliente(dispatch, true);
    ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/clientes/${keyCliente}`).limitToLast(1).on("value", snapshot => {
        // console.log("consulta CLIENTE COM CLIENTE");
        const dadoscliente = [];

        dadoscliente.push({
          key: snapshot.key,
          nome: snapshot.val().nome,
          empresa: snapshot.val().empresa,
          email: snapshot.val().email,
          permissaoAPP: snapshot.val().permissaoAPP,
          senha: snapshot.val().senha,
          alterado: snapshot.val().alterado,
          telefoneCliente: snapshot.val().telefoneCliente == undefined ? '' : snapshot.val().telefoneCliente,
          enderecoCliente: snapshot.val().enderecoCliente == undefined ? '' : snapshot.val().enderecoCliente,
          empresaPrincipal: snapshot.val().empresaPrincipal == undefined ? false : snapshot.val().empresaPrincipal,
          keyEmpresaVinculada: snapshot.val().keyEmpresaVinculada == undefined ? "Não Vinculado" : snapshot.val().keyEmpresaVinculada,
          chaveUnica: chaveUnica
        });

        enviaFotoClienteParaReducer(dispatch, dadoscliente);
        ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
      });
    });

  };
};


export const consultaCliente2 = (
  valor,
  referenciaUltimaKey,
  clientesAnteriores
) => {
  return dispatch => {
    // console.log(valor, referenciaUltimaKey, clientesAnteriores)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (valor == 0) {
        // if initial fetch
        ativaDesativaLoadingTelaConsultaCliente(dispatch, true);
        ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, true);
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('excluido').equalTo(null).limitToLast(10).on("value", snapshot => {
          // console.log("na primeira consulta cliente");
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaFotoClienteParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
            ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, false);
          } else {
            let arrayOfKeys = Object.keys(snapshot.val())
              .sort()
              .reverse();
            // transforming to array
            let results = arrayOfKeys.map(key => snapshot.val()[key]);
            // storing reference
            let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
            const dadoscliente = [];
            snapshot.forEach(childItem => {

              dadoscliente.push({
                key: childItem.key,
                nome: childItem.val().nome,
                empresa: childItem.val().empresa,
                email: childItem.val().email,
                permissaoAPP: childItem.val().permissaoAPP,
                senha: childItem.val().senha,
                alterado: childItem.val().alterado,
                urlImagemNuvem: childItem.val().urlImagemNuvem,
                excluido: childItem.val().excluido == undefined ? 0 : childItem.val().excluido,
                telefoneCliente: childItem.val().telefoneCliente == undefined ? '' : childItem.val().telefoneCliente,
                enderecoCliente: childItem.val().enderecoCliente == undefined ? '' : childItem.val().enderecoCliente,
                empresaPrincipal: childItem.val().empresaPrincipal == undefined ? false : childItem.val().empresaPrincipal,
                keyEmpresaVinculada: childItem.val().keyEmpresaVinculada == undefined ? "Não Vinculado" : childItem.val().keyEmpresaVinculada,
                chaveUnica: chaveUnica
              });
              /*   if(dadoscliente.length == 1){
                  enviaDadosClienteTelaAlterar(
                    dadoscliente[0].key,
                    dadoscliente[0].empresa,
                    dadoscliente[0].nome,
                    dadoscliente[0].email,
                    dadoscliente[0].permissaoAPP,
                    dadoscliente[0].senha,
                    dadoscliente[0].senha,
                    dadoscliente[0].key,
                    clienteSelecionadoConsultaClienteTelaCAdastraCliente,
                    clienteEmpresaPrincipal,
                    telefoneCliente,
                    enderecoCliente
                  )
                } */

            });
            // console.log(dadoscliente)
            enviaFotoClienteParaReducer(dispatch, dadoscliente);
            enviaUltimaReferenciaKeyTelaConsultaCliente(dispatch, referenceToOldestKey);
            ativaDesativaLoadingTelaConsultaCliente(dispatch, false);
            ativaDesativaLoadingRefreshControlConsultaCliente(dispatch, false);
            ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, true);
          }
        });
      } else {
        if (referenciaUltimaKey != undefined && referenciaUltimaKey != "") {
          /*   ativaDesativaLoadingFinalFlatListTelaConsultaCliente(
              dispatch,
              true
            ); */
          firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild("excluido")
            .startAt(null)
            .endAt(null, referenciaUltimaKey).limitToLast(11).on("value", snapshot => {
              if (snapshot.val() == null) {
                ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse()
                  .slice(1);
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                const dadoscliente = [];

                for (let i = 0; i < results.length; i++) {

                  dadoscliente.push({
                    key: arrayOfKeys[i],
                    nome: results[i].nome,
                    empresa: results[i].empresa,
                    email: results[i].email,
                    permissaoAPP: results[i].permissaoAPP,
                    senha: results[i].senha,
                    alterado: results[i].alterado,
                    urlImagemNuvem: results[i].urlImagemNuvem,
                    excluido: results[i].excluido == undefined ? 0 : results[i].excluido,
                    telefoneCliente: results[i].telefoneCliente == undefined ? '' : results[i].telefoneCliente,
                    enderecoCliente: results[i].enderecoCliente == undefined ? '' : results[i].enderecoCliente,
                    empresaPrincipal: results[i].empresaPrincipal == undefined ? false : results[i].empresaPrincipal,
                    keyEmpresaVinculada: results[i].keyEmpresaVinculada == undefined ? "Não Vinculado" : results[i].keyEmpresaVinculada,
                    chaveUnica: chaveUnica
                  });
                }


                enviaFotoClienteParaReducer(dispatch, clientesAnteriores.concat(dadoscliente));
                enviaUltimaReferenciaKeyTelaConsultaCliente(dispatch, referenceToOldestKey);

              }
            });
        } else {
          ativaDesativaLoadingFinalFlatListTelaConsultaCliente(dispatch, false);
        }
      }
    });

  };
};
export const reabrirChamado = (keyChamado, keyAtendente, keySetor, dadosAtendimento) => {
  return dispatch => {
    console.log(keyChamado, keyAtendente, keySetor)
    console.log(dadosAtendimento)
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let Aberto = '';
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    let setorAtendimento = keySetor.toString() == "" ? "Não informado" : keySetor.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }
    AbertoVencimento = { Aberto: AbertoVencimento }
    let AbertoVencimentoAtendente = {};
    AbertoVencimentoAtendente[atendenteAtendimento] = Aberto = AbertoVencimento;
    // console.log(AbertoVencimento)


    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          })
        })
        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        // console.log(arrayNomeQuemAbriuChamado)
        //console.log(arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado)

        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
          tipo: 'mensagem',
          titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
          mensagem: 'Atendimento reaberto por ' + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
          dataHoraAtual: dataHoraAtual,
          dataAtualPostagem: dataAtualPostagem,
          time: timeAtual,
          lineColor: '#39a3f4',
          circleColor: '#39a3f4',
          visivelATodos: 1,
          keyDeQuemAlterouChamado
        }).then(() => {
          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
            status: 'Aberto',
            status_atendente: `Aberto${keyAtendente}`,
            status_cliente: `Aberto${dadosAtendimento.keyCliente}`, //filtro cliente comum
            status_setor: `Aberto${dadosAtendimento.keySetor}`, //filtro setor comum
            status_prioridade: `Aberto${dadosAtendimento.prioridade}`, // filtro prioridade comum
            status_local: `Aberto${dadosAtendimento.local}`, // filtro local comum
            status_dataVencimento: AbertoVencimento, // filtro local comum
            status_numeroChamado: `Aberto_${dadosAtendimento.numeroChamado}`,

            status_atendente_cliente: `Aberto${atendenteAtendimento}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
            status_atendente_setor: `Aberto${atendenteAtendimento}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
            status_atendente_prioridade: `Aberto${atendenteAtendimento}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
            status_atendente_local: `Aberto${atendenteAtendimento}${dadosAtendimento.local}`, // filtro local vê proprio chamado
            status_atendente_dataVencimento: AbertoVencimentoAtendente, // filtro local vê proprio chamado
            status_atendente_numeroChamado: `Aberto_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,

            lastMessageChat: 'Atendimento reaberto por ' + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
            lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
            lastmessageChatType: 'mensagem',
            lastmessageChatStatus: 'sent',
            lastMessageLineColor: '#39a3f4'
          }).then(
            () => {
              if (setorAtendimento !== "Não informado") {
                // console.log('setor diferente de naão informado');
             //   let dadosAtendVinc;
                //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                 // dadosAtendVinc = snapshotSetor.val();
                 // console.log(dadosAtendVinc)
                
                  //  console.log(snapshotSetor.val());
                  if (snapshotSetor.val()) {
                    let dadosAtendVinc = snapshotSetor.val();
                      let keyAtendentesVinculados = Object.keys(snapshotSetor.val());
                  console.log(keyAtendentesVinculados)
                  for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                    if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                      dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                    }
                  }
                    //tem atendentes vinculados ao setor

                    const dadosAtendentesVinculadosSetor = [];
                    snapshotSetor.forEach((childItem) => {
                      dadosAtendentesVinculadosSetor.push({
                        key: childItem.key,
                      });
                    })
                    // console.log(dadosAtendentesVinculadosSetor)

                    let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                    if (atendenteAtendimento !== 'Não informado') {
                      //verifico se o atendente do chamado nao esta vinculado a este setor
                      for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                        //  console.log(dadosAtendentesVinculadosSetor[i].key)
                        if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                          verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                        }
                      }
                      //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                      if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                        // console.log('atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado')
                        let objetocadastro = {};
                        firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                          let key = snapshot.key;
                          let codigoInterno = snapshot.val().codigoInterno;
                          objetocadastro[codigoInterno] = codigoInterno;
                          objetocadastro[codigoInterno + "status"] = codigoInterno + "Aberto";
                          //objetocadastro[key] = true;
                          //  console.log(objetocadastro)
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })

                      } else {
                        //creio que ja cai na situaçaão acima
                        //  console.log('creio que ja cai na situaçaão acima NAO SEI SE EH PRA CHEGAR AQUI')
                      }
                    }

                    firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                  } else {
                    // console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                      //  console.log(snapshot.val())
                      const idTodosAtendentes = [];
                      let objetocadastro = {};
                      snapshot.forEach((childItem) => {
                        idTodosAtendentes.push({
                          key: childItem.key,
                          codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                        });
                      })
                      //  console.log(idTodosAtendentes)
                      for (let i = 0; i < idTodosAtendentes.length; i++) {
                        // objetocadastro[idTodosAtendentes[i].key] = true;
                        objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                        objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                      }
                      // console.log(objetocadastro)
                      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })

                  }
                })
              } else {
                //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                //console.log('SETOR NAO INFORMADOOOOO')
                let objetocadastro = {}
                if (atendenteAtendimento != "Não informado") {
                  //  console.log('atendente informadoooooo')
                  //  console.log(atendenteAtendimento)
                  objetocadastro[atendenteAtendimento] = true;
                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}/codigoInterno/`).once('value').then((snapshot) => {
                    console.log(snapshot.val())
                    let codigoInterno = snapshot.val();
                    objetocadastro[codigoInterno] = codigoInterno;
                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Aberto";
                    console.log(objetocadastro)
                    let dadosAtendVinc = objetocadastro;
                    firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                  })
                  // console.log(objetocadastro)
                  /*     let dadosAtendVinc = objetocadastro;
                      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc }); */

                }
              }
              ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              //  console.log('sucesso')
            })
        })
      })
    })
  }
}
/* export const reabrirChamado = (keyChamado, keyAtendente, keySetor, dadosAtendimento) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    let setorAtendimento = keySetor.toString() == "" ? "Não informado" : keySetor.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Aberto';
    AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Aberto: AbertoVencimento }

    console.log(AbertoVencimento)

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase
            .database()
            .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
            .push({
              tipo: "mensagem",
              titulo:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              mensagem:
                "Atendimento reaberto por " +
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              dataHoraAtual: dataHoraAtual,
              dataAtualPostagem: dataAtualPostagem,
              time: timeAtual,
              lineColor: "#00FF7F",
              circleColor: "#00FF7F",
              visivelATodos: 1,
              keyDeQuemAlterouChamado
            })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .update({
                  status: "Aberto", status_atendente: `Aberto${keyAtendente}`,
                  status_cliente: `Aberto${dadosAtendimento.keyCliente}`, //filtro cliente comum
                  status_setor: `Aberto${dadosAtendimento.keySetor}`, //filtro setor comum
                  status_prioridade: `Aberto${dadosAtendimento.prioridade}`, // filtro prioridade comum
                  status_local: `Aberto${dadosAtendimento.local}`, // filtro local comum
                  status_dataVencimento: AbertoVencimento, // filtro local comum
                  status_numeroChamado: `Aberto_${dadosAtendimento.numeroChamado}`,

                  status_atendente_cliente: `Aberto${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                  status_atendente_setor: `Aberto${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                  status_atendente_prioridade: `Aberto${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                  status_atendente_local: `Aberto${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                  status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                  status_atendente_numeroChamado: `Aberto_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                })
                .then(() => {
                  if (setorAtendimento !== "Não informado") {
                    console.log('setor diferente de naão informado');
                    //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                    firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                      let dadosAtendVinc = snapshotSetor.val();
                      console.log(snapshotSetor.val());
                      if (snapshotSetor.val()) {
                        //tem atendentes vinculados ao setor
                        const dadosAtendentesVinculadosSetor = [];
                        snapshotSetor.forEach((childItem) => {
                          dadosAtendentesVinculadosSetor.push({
                            key: childItem.key,
                          });
                        })
                        console.log(dadosAtendentesVinculadosSetor)

                        let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                        if (atendenteAtendimento !== 'Não informado') {
                          //verifico se o atendente do chamado nao esta vinculado a este setor
                          for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                            console.log(dadosAtendentesVinculadosSetor[i].key)
                            if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                              verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                            }
                          }
                          //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                          if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                            console.log('atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado')
                            let objetocadastro = {};
                            firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                              let key = snapshot.key;
                              console.log(key)
                              objetocadastro[key] = true;
                              console.log(objetocadastro)
                              firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                            })

                          } else {
                            //creio que ja cai na situaçaão acima
                            console.log('creio que ja cai na situaçaão acima NAO SEI SE EH PRA CHEGAR AQUI')
                          }
                        }

                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                      } else {
                        console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                          console.log(snapshot.val())
                          const idTodosAtendentes = [];
                          let objetocadastro = {};
                          snapshot.forEach((childItem) => {
                            idTodosAtendentes.push({
                              key: childItem.key,
                            });
                          })
                          console.log(idTodosAtendentes)
                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                            objetocadastro[idTodosAtendentes[i].key] = true;
                          }
                          console.log(objetocadastro)
                          firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })

                      }
                    })
                  } else {
                    //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                    console.log('SETOR NAO INFORMADOOOOO')
                    let objetocadastro = {}
                    if (atendenteAtendimento != "Não informado") {
                      console.log('atendente informadoooooo')
                      console.log(atendenteAtendimento)
                      objetocadastro[atendenteAtendimento] = true;
                      console.log(objetocadastro)
                      let dadosAtendVinc = objetocadastro;
                      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/usuariosVisualizaAtend/`).update({ dadosAtendVinc })
                    }
                  }
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                });
            });
        });
    });
  };
}; */
/* export const cancelarChamado = (keyChamado, motivo2, keyAtendente, dadosAtendimento) => {
  return dispatch => {
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    const motivo = motivo2.trim();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }

    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Cancelado';
    AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Cancelado: AbertoVencimento }


    console.log(AbertoVencimento)

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (motivo.trim() == "") {
        modificaMensagemErroMotivoCancelamento(
          dispatch,
          "Informe um motivo de Cancelamento!"
        );
      } else {
        modificavisibilidadeModalCancelarLocal(dispatch, false);
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " Cancelou este atendimento pelo motivo: " +
                  motivo,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    status: "Cancelado",
                    usuariosVisualizaAtend: dadosAtendVinc,
                    dataCancelamento: dataAtualPostagem,
                    dataHoraCancelamento: dataHoraAtual,
                    status_atendente: `Cancelado${keyAtendente}`,

                    status_cliente: `Cancelado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                    status_setor: `Cancelado${dadosAtendimento.keySetor}`, //filtro setor comum
                    status_prioridade: `Cancelado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                    status_local: `Cancelado${dadosAtendimento.local}`, // filtro local comum
                    status_dataVencimento: AbertoVencimento, // filtro local comum
                    status_numeroChamado: `Cancelado_${dadosAtendimento.numeroChamado}`,

                    status_atendente_cliente: `Cancelado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                    status_atendente_setor: `Cancelado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                    status_atendente_prioridade: `Cancelado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                    status_atendente_local: `Cancelado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                    status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                    status_atendente_numeroChamado: `Cancelado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                  })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    modificaMensagemErroMotivoCancelamento(dispatch, "");
                    modificaMotivoCancelamentoTelaAcoesAtendimentoLocal(
                      dispatch,
                      ""
                    );
                  });
              });
          });
      }
    });
  };
}; */

export const cancelarChamado = (keyChamado, motivo2, keyAtendente, dadosAtendimento) => {
  return dispatch => {
    let Cancelado = '';
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    const motivo = motivo2.trim();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }
    AbertoVencimento = { Cancelado: AbertoVencimento }
    let AbertoVencimentoAtendente = {};
    AbertoVencimentoAtendente[atendenteAtendimento] = Cancelado = AbertoVencimento;
    // console.log(AbertoVencimento)


    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (motivo.trim() == "") {
        modificaMensagemErroMotivoCancelamento(dispatch, "Informe um motivo de Cancelamento!");
      }
      else {
        modificavisibilidadeModalCancelarLocal(dispatch, false);
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            })
          })
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          // console.log(arrayNomeQuemAbriuChamado)
          //  console.log(arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado)

          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: 'mensagem',
            titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' Cancelou este atendimento pelo motivo: ' + motivo,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: '#39a3f4',
            circleColor: '#39a3f4',
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          }).then(() => {
            let dadosAtendVinc = { vazio: 'vazio' };
            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
              status: 'Cancelado', status_atendente: `Cancelado${atendenteAtendimento}`,
              // usuariosVisualizaAtend: dadosAtendVinc, 
              dataCancelamento: dataAtualPostagem,
              dataHoraCancelamento: dataHoraAtual,
              status_cliente: `Cancelado${dadosAtendimento.keyCliente}`, //filtro cliente comum
              status_setor: `Cancelado${dadosAtendimento.keySetor}`, //filtro setor comum
              status_prioridade: `Cancelado${dadosAtendimento.prioridade}`, // filtro prioridade comum
              status_local: `Cancelado${dadosAtendimento.local}`, // filtro local comum
              status_dataVencimento: AbertoVencimento, // filtro local comum
              status_numeroChamado: `Cancelado_${dadosAtendimento.numeroChamado}`,

              status_atendente_cliente: `Cancelado${atendenteAtendimento}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Cancelado${atendenteAtendimento}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
              status_atendente_prioridade: `Cancelado${atendenteAtendimento}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
              status_atendente_local: `Cancelado${atendenteAtendimento}${dadosAtendimento.local}`, // filtro local vê proprio chamado
              status_atendente_dataVencimento: AbertoVencimentoAtendente, // filtro local vê proprio chamado
              status_atendente_numeroChamado: `Cancelado_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,

              lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' Cancelou este atendimento pelo motivo: ' + motivo,
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
              lastmessageChatType: 'mensagem',
              lastmessageChatStatus: 'sent',
              lastMessageLineColor: '#39a3f4'
            }).then(
              () => {
                let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                let keyAtendimento = dadosAtendimento.key;

                console.log(setorAtendimento, atendenteAtendimento, keyAtendimento)
                if (setorAtendimento !== "Não informado") {
                  //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                  firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                   
                    console.log(dadosAtendVinc)
                    if (snapshotSetor.val()) {
                      let dadosAtendVinc = {};
                      console.log(dadosAtendVinc)
                      let keyAtendentesVinculados = Object.keys(snapshotSetor.val());
                      console.log(keyAtendentesVinculados)
                      for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                        if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                          dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Cancelado";
                        }
                      }
                      //tem atendentes vinculados ao setor
                      const dadosAtendentesVinculadosSetor = [];
                      snapshotSetor.forEach((childItem) => {
                        dadosAtendentesVinculadosSetor.push({
                          key: childItem.key,
                        });
                      })

                      let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                      if (atendenteAtendimento !== 'Não informado') {
                        //verifico se o atendente do chamado nao esta vinculado a este setor
                        for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                          if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                            verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                          }
                        }
                        //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                        if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                          let objetocadastro = {};
                          firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                            let key = snapshot.key;
                            let codigoInterno = snapshot.val().codigoInterno;
                            //  objetocadastro[codigoInterno] = codigoInterno;
                            objetocadastro[codigoInterno + "status"] = codigoInterno + "Cancelado";

                            // objetocadastro[key] = true;

                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          })

                        } else {
                          //creio que ja cai na situaçaão acima
                        }
                      }

                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                    } else {
                      //   console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                        const idTodosAtendentes = [];
                        let objetocadastro = {};
                        snapshot.forEach((childItem) => {
                          idTodosAtendentes.push({
                            key: childItem.key,
                            codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                          });
                        })
                        //    console.log(idTodosAtendentes)
                        for (let i = 0; i < idTodosAtendentes.length; i++) {
                          // objetocadastro[idTodosAtendentes[i].key] = true;
                          //objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                          objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Cancelado";

                        }
                        //console.log(objetocadastro)
                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                      })

                    }
                  })
                } else if (atendenteAtendimento !== "Não informado") {
                  let objetocadastro = {};
                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                    let key = snapshot.key;
                    let codigoInterno = snapshot.val().codigoInterno;
                    //  objetocadastro[codigoInterno] = codigoInterno;
                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Cancelado";

                    console.log(codigoInterno)
                    //objetocadastro[key] = true;

                    // console.log(objetocadastro)
                    // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                  })
                } else {
                  let dadosAtendVinc = { vazio: 'vazio' };
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                    .update({ usuariosVisualizaAtend: dadosAtendVinc })
                }
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                // console.log('sucesso');
                modificaMensagemErroMotivoCancelamento(dispatch, "");
                modificaMotivoCancelamentoTelaAcoesAtendimentoLocal(dispatch, "");
              })
          })
        })
      }
    })

  }
}

export const alterarDataVencimentoAtendimento = (
  keyChamado,
  dataVencimentoAtual,
  dataVencimenoPassada,
  dadosAtendimento
) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);

    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let dataVencimentoUnix = '';
    let AbertoVencimento = dataVencimentoAtual.toString() == "Não informado" ? 0 : dataVencimentoAtual;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      dataVencimentoUnix = AbertoVencimento;
    } else {
      AbertoVencimento = false;
    }
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = '';
    if (dadosAtendimento.status == 'Aberto') {
      Aberto = 'Aberto';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Aberto: AbertoVencimento }
      // AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Aberto = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Atendendo') {
      Aberto = 'Atendendo';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Atendendo: AbertoVencimento }
      //AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Atendendo = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Fechado') {
      Aberto = 'Fechado';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
      // AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Fechado = AbertoVencimento;
    }
    if (dadosAtendimento.status == 'Cancelado') {
      Aberto = 'Cancelado';
      AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Cancelado: AbertoVencimento }
      //  AbertoVencimentoAtendente[dadosAtendimento.keyAtendente] = Cancelado = AbertoVencimento;
    }

    //console.log(AbertoVencimento)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: "mensagem",
            titulo:
              arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem:
              " Alterou a data de vencimento de " +
              dataVencimenoPassada +
              " para " +
              dataVencimentoAtual,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: "#00FF7F",
            circleColor: "#00FF7F",
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .update({
                  dataVencimento: dataVencimentoAtual, status_dataVencimento: AbertoVencimento,
                  status_atendente_dataVencimento: testeObjeto, atendente_dataVencimento: AbertoVencimentoAtendente,
                  dataVencimentoUnix: dataVencimentoUnix,

                })
                .then(() => {
                  ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                });
            });
        });
    });
  };
};
export const alterarPrioridadeAtendimento = (
  keyChamado,
  prioridade,
  prioridadeAnterior,
  dadosAtendimento
) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);

    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (prioridade == "Baixa") {
        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " Alterou prioridade do atendimento de " +
                  prioridadeAnterior +
                  " para Baixa",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({ prioridade: "Baixa", status_prioridade: `${dadosAtendimento.status}Baixa`, status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Baixa`, atendente_prioridade: `${dadosAtendimento.keyAtendente}Baixa` })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      } else if (prioridade == "Normal") {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " Alterou prioridade do atendimento de " +
                  prioridadeAnterior +
                  " para Normal",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({ prioridade: "Normal", status_prioridade: `${dadosAtendimento.status}Normal`, status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Normal`, atendente_prioridade: `${dadosAtendimento.keyAtendente}Normal` })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      } else if (prioridade == "Alta") {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " Alterou prioridade do atendimento de " +
                  prioridadeAnterior +
                  " para Alta",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({ prioridade: "Alta", status_prioridade: `${dadosAtendimento.status}Alta`, status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Alta`, atendente_prioridade: `${dadosAtendimento.keyAtendente}Alta` })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      }
      //Urgente
      else {
        firebase
          .database()
          .ref(`${chaveUnica}/usuarios/`)
          .orderByChild("uid")
          .equalTo(firebase.auth().currentUser.uid)
          .once("value")
          .then(snapshot => {
            let arrayNomeQuemAbriuChamado = [];
            snapshot.forEach(childItem => {
              arrayNomeQuemAbriuChamado.push({
                nomeDeQuemAbriuChamado: childItem.val().nome
              });
            });
            let keyDeQuemAlterouChamado = Object.keys(
              snapshot.val()
            ).toString();

            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " Alterou prioridade do atendimento de " +
                  prioridadeAnterior +
                  " para Urgente",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 0,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({ prioridade: "Urgente", status_prioridade: `${dadosAtendimento.status}Urgente`, status_atendente_prioridade: `${dadosAtendimento.status}${dadosAtendimento.keyAtendente}Urgente`, atendente_prioridade: `${dadosAtendimento.keyAtendente}Urgente` })
                  .then(() => {
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                  });
              });
          });
      }
    });
  };
};
export const atribuiStatusAtendimento = (keyChamado, status, dataAbertura, dadosChamado, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp, viaChat) => {
  return dispatch => {
    // console.log(keyChamado, status, dataAbertura, dadosChamado, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp)
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    console.log('channel chamado teste')
    let channelChamado2 = dadosChamado.channelChamado == undefined ? "" : dadosChamado.channelChamado;
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    let dataImprovisada = '05/06/2019'
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let Fechado = '';
    if (viaChat == true) {
      if (keyAtendente === 'Não informado') {
        modificaTelaAtualChat(dispatch, 'avaliacaoFeitaSucesso');
      } else {
        modificaTelaAtualChat(dispatch, 'chatFinalizado');
      }
    }


    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          })
        })
        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        // console.log(arrayNomeQuemAbriuChamado)
        // console.log(arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado);
        let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
        if (AbertoVencimento !== 0) {
          AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
        } else {
          AbertoVencimento = false;
        }
        AbertoVencimento = { Fechado: AbertoVencimento }
        let AbertoVencimentoAtendente = {};
        if (viaChat == true && dadosChamado.status == 'Aberto') {
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/${keyChamado}`)
            .update({
              channelChamado: '',
            })
        }
        AbertoVencimentoAtendente[atendenteAtendimento] = Fechado = AbertoVencimento;
        // console.log(AbertoVencimento)
        if (status == 'Fechado') {
          //calculo a quantidade de dias para resolver o chamado e populo no banco
          let diferencaDeDias;
          // console.log(dataAbertura)
          // console.log(dataAtualPostagem)
          let total = calculaDias(dataAbertura, dataAtualPostagem)
          // console.log('total diferenca de dias: ' + total)
          switch (total) {
            case 0:
              diferencaDeDias = '<1'
              break;
            case 1:
              diferencaDeDias = '1'
              break;
            case 2:
              diferencaDeDias = '2'
              break;
            case 3:
              diferencaDeDias = '3'
              break;
            case 4:
              diferencaDeDias = '4'
              break;
            case 5:
              diferencaDeDias = '5'
              break;
            case 6:
              diferencaDeDias = '6'
              break;
            case 7:
              diferencaDeDias = '7'
              break;
            default:
              diferencaDeDias = '>7'
              break;
          }
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: 'mensagem',
            titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: '#39a3f4',
            circleColor: '#39a3f4',
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          }).then(() => {
            let dadosAtendVinc = { vazio: 'vazio' };

            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
              status: 'Fechado',
              status_atendente: `Fechado${atendenteAtendimento}`,
              // usuariosVisualizaAtend: dadosAtendVinc,
              dataFechamento: dataAtualPostagem,
              dataHoraFechamento: dataHoraAtual,
              periodoResolucaoChamado: diferencaDeDias,
              periodoResolucaoChamado: diferencaDeDias,
              status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
              status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
              status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
              status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
              status_dataVencimento: AbertoVencimento, // filtro local comum
              status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

              status_atendente_cliente: `Fechado${atendenteAtendimento}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Fechado${atendenteAtendimento}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
              status_atendente_prioridade: `Fechado${atendenteAtendimento}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
              status_atendente_local: `Fechado${atendenteAtendimento}${dadosAtendimento.local}`, // filtro local vê proprio chamado
              status_atendente_dataVencimento: AbertoVencimentoAtendente, // filtro local vê proprio chamado
              status_atendente_numeroChamado: `Fechado_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
              status_atendente_abertoviachat: dadosAtendimento.status === 'Atendendo' ? `Atendendo${dadosAtendimento.keyAtendente}true` : '',
              // channelChamado: ''
              lastMessageChat: viaChat === true ? arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' encerrou o chat.' : arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' finalizou o atendimento.',
              lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
              lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
              lastmessageChatType: 'mensagem',
              lastmessageChatStatus: 'sent',
              lastMessageLineColor: '#39a3f4'
            }).then(
              () => {
                let setorAtendimento = dadosAtendimento.keySetor.toString() == "" ? "Não informado" : dadosAtendimento.keySetor.toString();
                let atendenteAtendimento = dadosAtendimento.keyAtendente.toString() == "" ? "Não informado" : dadosAtendimento.keyAtendente.toString();
                let keyAtendimento = dadosAtendimento.key;
                if (setorAtendimento !== "Não informado") {
                  //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                  firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                    let dadosAtendVinc = {};
                    console.log(dadosAtendVinc)
                    let keyAtendentesVinculados = Object.keys(snapshotSetor.val());
                    console.log(keyAtendentesVinculados)
                    for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                      if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                        dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Fechado";
                      }
                    }
                    console.log(dadosAtendVinc)
                    if (snapshotSetor.val()) {
                      //tem atendentes vinculados ao setor
                      const dadosAtendentesVinculadosSetor = [];
                      snapshotSetor.forEach((childItem) => {
                        dadosAtendentesVinculadosSetor.push({
                          key: childItem.key,
                        });
                      })

                      let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                      if (atendenteAtendimento !== 'Não informado') {
                        //verifico se o atendente do chamado nao esta vinculado a este setor
                        for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                          if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                            verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                          }
                        }
                        //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                        if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                          let objetocadastro = {};
                          firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                            let key = snapshot.key;
                            let codigoInterno = snapshot.val().codigoInterno;
                            // objetocadastro[codigoInterno] = codigoInterno;
                            objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";

                            //  objetocadastro[key] = true;

                            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          })

                        } else {
                          //creio que ja cai na situaçaão acima
                        }
                      }

                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                    } else {
                      //   console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                        const idTodosAtendentes = [];
                        let objetocadastro = {};
                        snapshot.forEach((childItem) => {
                          idTodosAtendentes.push({
                            key: childItem.key,
                            codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                          });
                        })
                        //    console.log(idTodosAtendentes)
                        for (let i = 0; i < idTodosAtendentes.length; i++) {
                          // objetocadastro[idTodosAtendentes[i].key] = true;
                          // objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                          objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Fechado";

                        }
                        //console.log(objetocadastro)
                        firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                      })

                    }
                  })
                } else if (atendenteAtendimento !== "Não informado") {
                  let objetocadastro = {};
                  firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                    let key = snapshot.key;
                    let codigoInterno = snapshot.val().codigoInterno;
                    // objetocadastro[codigoInterno] = codigoInterno;
                    objetocadastro[codigoInterno + "status"] = codigoInterno + "Fechado";

                    console.log(codigoInterno)
                    // objetocadastro[key] = true;

                    // console.log(objetocadastro)
                    // firebase.database().ref(`${chaveUnica}/dadosAtendVinc/${key}/${dadosChamado.key}`).push({ true: 'true' });
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                  })
                } else {
                  let dadosAtendVinc = { vazio: 'vazio' };
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/${keyAtendimento}`)
                    .update({ usuariosVisualizaAtend: dadosAtendVinc })
                }
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                // console.log('------------------------_ANTES DA VERIFICAO--------------------');
                //console.log(dadosChamado.atendente);
                if (dadosChamado.atendente !== 'Não informado' && premium == 1 && permissaoParaAvaliarChamado == true && licensawebouapp >= 2 && viaChat == false) {
                  abreModalAvaliarChamadoLocal(dispatch, true)
                }
              })
          })
        } else {
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: 'mensagem',
            titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' está aguardando o cliente responder.',
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: '#39a3f4',
            circleColor: '#39a3f4',
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          }).then(() => {
            ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
            //console.log('sucesso')
          })
        }

      })
    })
  }
}
export const cadastraAvaliacaoChamado = (dadosChamado, comentarioAvaliacao, valorAvaliacao, verificaSeJaAvaliouChamado) => {
  return dispatch => {
    let dataHoraAtual = retornaDataHoraAtual();
    // console.log('AQUIIIIIIIIIIIIIIIIII')
    //console.log(dadosChamado, comentarioAvaliacao, valorAvaliacao, verificaSeJaAvaliouChamado)

    let dataHoraAvaliacaoTimeStamp = firebase.database.ServerValue.TIMESTAMP;
    dadosChamado.comentarioAvaliacao = comentarioAvaliacao;
    dadosChamado.valorAvaliacao = valorAvaliacao;
    dadosChamado.dataHoraAvaliacaoTimeStamp = dataHoraAvaliacaoTimeStamp;
    dadosChamado.dataHoraAvaliacao = dataHoraAtual;

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      // console.log(dadosChamado);
      // console.log(chaveUnica);
      abreModalAvaliarChamadoLocal(dispatch, false);
      if (verificaSeJaAvaliouChamado == 1) {
        firebase.database().ref(`${chaveUnica}/avaliacoes/`).orderByChild('key').equalTo(dadosChamado.key).once("value").then((snapshot2) => {
          let arrayKeyAvaliacao = [];
          //  console.log(snapshot2.val())
          snapshot2.forEach(childItem => {
            arrayKeyAvaliacao.push({
              key: childItem.key,
            })
          })
          // console.log(arrayKeyAvaliacao[0].key)
          firebase.database().ref(`${chaveUnica}/avaliacoes/${arrayKeyAvaliacao[0].key}`).update(dadosChamado).then(() => {
            // console.log('alterado');
          })
        })
      } else {
        abreModalAvaliarChamadoLocal(dispatch, false);
        //console.log('no else')
        firebase.database().ref(`${chaveUnica}/avaliacoes/`).push(dadosChamado).then(() => {
          // console.log('terminou');
        }).catch((err) => {
          // console.log('error')
          //console.log(err)
        })
      }
    })
  }
}
/* export const atribuiStatusAtendimento = (
  keyChamado,
  status,
  dataAbertura,
  premium,
  keyAtendente,
  dadosAtendimento
) => {
  return dispatch => {
    console.log(dadosAtendimento)
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      //  alert('antes do primeiro firebase')
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
          if (AbertoVencimento !== 0) {
            AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
          } else {
            AbertoVencimento = false;
          }
          let AbertoVencimentoAtendente = {};
          let testeObjeto = {};
          let Aberto = 'Fechado';
          AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
          testeObjeto[Aberto] = AbertoVencimentoAtendente;
          AbertoVencimento = { Fechado: AbertoVencimento }

          console.log(AbertoVencimento)
          //  alert('depois do primeiro firebase')
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          if (status == "Fechado") {
            //calculo a quantidade de dias para resolver o chamado e populo no banco
            let diferencaDeDias;
            let total = calculaDias2(dataAbertura, dataAtualPostagem);
            switch (total) {
              case 0:
                diferencaDeDias = "<1";
                break;
              case 1:
                diferencaDeDias = "1";
                break;
              case 2:
                diferencaDeDias = "2";
                break;
              case 3:
                diferencaDeDias = "3";
                break;
              case 4:
                diferencaDeDias = "4";
                break;
              case 5:
                diferencaDeDias = "5";
                break;
              case 6:
                diferencaDeDias = "6";
                break;
              case 7:
                diferencaDeDias = "7";
                break;
              default:
                diferencaDeDias = ">7";
                break;
            }

            firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
              tipo: "mensagem",
              titulo:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              mensagem:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                " finalizou o atendimento.",
              dataHoraAtual: dataHoraAtual,
              dataAtualPostagem: dataAtualPostagem,
              time: timeAtual,
              lineColor: "#00FF7F",
              circleColor: "#00FF7F",
              visivelATodos: 1,
              keyDeQuemAlterouChamado
            })
              .then(() => {
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase
                  .database()
                  .ref(`${chaveUnica}/chamados/${keyChamado}`)
                  .update({
                    status: "Fechado",
                    usuariosVisualizaAtend: dadosAtendVinc,
                    dataFechamento: dataAtualPostagem,
                    dataHoraFechamento: dataHoraAtual,
                    status_atendente: `Fechado${keyAtendente}`,
                    periodoResolucaoChamado: diferencaDeDias,
                    status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                    status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                    status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                    status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                    status_dataVencimento: AbertoVencimento, // filtro local comum
                    status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                    status_atendente_cliente: `Fechado${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                    status_atendente_setor: `Fechado${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                    status_atendente_prioridade: `Fechado${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                    status_atendente_local: `Fechado${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                    status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
                    status_atendente_numeroChamado: `Fechado_${keyAtendente}${dadosAtendimento.numeroChamado}`,
                  })
                  .then(() => {
                    firebase
                      .database()
                      .ref(`${chaveUnica}/configuracao/avaliacao`)
                      .once("value")
                      .then(snapshot => {
                        const dadosEmpresa = [];
                        snapshot.forEach(childItem => {
                          dadosEmpresa.push({
                            key: childItem.key,
                            mensagemPadrao: childItem.val().mensagemPadrao,
                            utilizaAvaliacao: childItem.val().utilizaAvaliacao
                          });
                        });
                        console.log(snapshot.val());
                        console.log(dadosEmpresa[0].mensagemPadrao);
                        console.log(dadosEmpresa[0].utilizaAvaliacao);
                        if (dadosEmpresa[0].utilizaAvaliacao == true && premium == 1) {
                          firebase
                            .database()
                            .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                            .push({
                              tipo: "mensagem",
                              titulo: "Avaliar Este Atendimento",
                              mensagem: dadosEmpresa[0].mensagemPadrao,
                              dataHoraAtual: dataHoraAtual,
                              dataAtualPostagem: dataAtualPostagem,
                              time: timeAtual,
                              lineColor: "#00FF7F",
                              circleColor: "#00FF7F",
                              visivelATodos: 1,
                              keyDeQuemAlterouChamado
                            })
                            .then(() => {
                              ativaDesativaLoadingTelaAcoesAtendimento(
                                dispatch,
                                false
                              );
                            });
                        } else {
                          ativaDesativaLoadingTelaAcoesAtendimento(
                            dispatch,
                            false
                          );
                        }
                      });
                  });
              });
          } else {
            firebase
              .database()
              .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
              .push({
                tipo: "mensagem",
                titulo:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem:
                  arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                  " está aguardando o cliente responder.",
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: "#00FF7F",
                circleColor: "#00FF7F",
                visivelATodos: 1,
                keyDeQuemAlterouChamado
              })
              .then(() => {
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              });
          }
        });
    });
  };
}; */
export const atribuirTempoEsperaAoChamado = (keyChamado, tempo2) => {
  return dispatch => {
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let tempo = tempo2;
    let tempoBancoDados;
    switch (tempo) {
      case "+5m":
        tempo = "5 minutos";
        tempoBancoDados = 5;
        break;
      case "+10m":
        tempo = "10 minutos";
        tempoBancoDados = 10;
        break;
      case "+15m":
        tempo = "15 minutos";
        tempoBancoDados = 15;
        break;
      case "+20m":
        tempo = "20 minutos";
        tempoBancoDados = 20;
        break;
      case "+25m":
        tempo = "25 minutos";
        tempoBancoDados = 25;
        break;
      case "+30m":
        tempo = "30 minutos";
        tempoBancoDados = 30;
        break;
      case "+35m":
        tempo = "35 minutos";
        tempoBancoDados = 35;
        break;
      case "+40m":
        tempo = "40 minutos";
        tempoBancoDados = 40;
        break;
      case "+45m":
        tempo = "45 minutos";
        tempoBancoDados = 45;
        break;
      case "+50m":
        tempo = "50 minutos";
        tempoBancoDados = 50;
        break;
      case "+55m":
        tempo = "55 minutos";
        tempoBancoDados = 55;
        break;
      case "+1h":
        tempo = "1 hora";
        tempoBancoDados = 60;
        break;
      case "+1h 15m":
        tempo = "1 hora e 15 minutos";
        tempoBancoDados = 75;
        break;
      case "+1h 30m":
        tempo = "1 hora e 30 minutos";
        tempoBancoDados = 90;
        break;
      case "+1h 45m":
        tempo = "1 hora e 45 minutos";
        tempoBancoDados = 105;
        break;
      case "+2h":
        tempo = "2 horas";
        tempoBancoDados = 120;
        break;
      case "+2h 30m":
        tempo = "2 horas e 30 minutos";
        tempoBancoDados = 150;
        break;
      case "+3h":
        tempo = "3 horas";
        tempoBancoDados = 180;
        break;
      case "+3h 30m":
        tempo = "3 horas e 30 minutos";
        tempoBancoDados = 210;
        break;
      case "+4h":
        tempo = "4 horas";
        tempoBancoDados = 240;
        break;
      case "+6h":
        tempo = "6 horas";
        tempoBancoDados = 360;
        break;
      case "+8h":
        tempo = "8 horas";
        tempoBancoDados = 480;
        break;
      case "+10h":
        tempo = "10 horas";
        tempoBancoDados = 600;
        break;
      case "+12h":
        tempo = "12 horas";
        tempoBancoDados = 720;
        break;
      case "+14h":
        tempo = "14 horas";
        tempoBancoDados = 840;
        break;
      case "+16h":
        tempo = "16 horas";
        tempoBancoDados = 960;
        break;
      case "+20h":
        tempo = "20 horas";
        tempoBancoDados = 1200;
        break;
      case "+24h":
        tempo = "24 horas";
        tempoBancoDados = 1440;
        break;

      default:
    }
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase
        .database()
        .ref(`${chaveUnica}/usuarios/`)
        .orderByChild("uid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();

          firebase
            .database()
            .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
            .push({
              tipo: "mensagem",
              titulo:
                arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
              mensagem:
                " trabalhei neste chamado por " +
                tempo,
              dataHoraAtual: dataHoraAtual,
              dataAtualPostagem: dataAtualPostagem,
              time: timeAtual,
              lineColor: "#00FF7F",
              circleColor: "#00FF7F",
              visivelATodos: 1,
              keyDeQuemAlterouChamado
            })
            .then(() => {
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/${keyChamado}`)
                .once("value")
                .then(snapshot => {
                  tempoBancoDados = tempoBancoDados + snapshot.val().tempoGasto;
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/${keyChamado}`)
                    .update({ tempoGasto: tempoBancoDados })
                    .then(() => {
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    });
                });
            });
        });
    });
  };
};

export const atribuiAtendimentoAh = (
  keyAtendente,
  keyChamado,
  nomeAtendenteQueIraSerAtribuido,
  setorAtendimento,
  dadosAtendimento,

) => {
  return dispatch => {
    //console.log(dadosAtendimento)
    let atendenteAtendimento = keyAtendente;
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    //console.log(setorAtendimento, atendenteAtendimento)
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let dataVencimentoUnix = '';

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          });
        });
        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
        //novos dados a ser atualizados
        let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
        if (AbertoVencimento !== 0) {
          AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
          dataVencimentoUnix = AbertoVencimento;
        } else {
          AbertoVencimento = false;
        }
        let AbertoVencimentoAtendente = {};
        let testeObjeto = {};
        let Aberto = 'Atendendo';
        AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
        testeObjeto[Aberto] = AbertoVencimentoAtendente;
        AbertoVencimento = { Atendendo: AbertoVencimento }

        // console.log(AbertoVencimento)
        //
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
          tipo: "mensagem",
          titulo:
            arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
          mensagem:
            "Atendimento atribuido ao atendente " +
            nomeAtendenteQueIraSerAtribuido,
          dataHoraAtual: dataHoraAtual,
          dataAtualPostagem: dataAtualPostagem,
          time: timeAtual,
          lineColor: "#00FF7F",
          circleColor: "#00FF7F",
          visivelATodos: 1,
          keyDeQuemAlterouChamado
        })
          .then(() => {
            let dadosAtendVinc = { vazio: 'vazio' };
            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
              atendente: keyAtendente,
              status: "Atendendo",
              status_atendente: `Atendendo${keyAtendente}`,
              //  usuariosVisualizaAtend: dadosAtendVinc,
              keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,

              status_cliente: `Atendendo${dadosAtendimento.keyCliente}`, //filtro cliente comum
              status_setor: `Atendendo${dadosAtendimento.keySetor}`, //filtro setor comum
              status_prioridade: `Atendendo${dadosAtendimento.prioridade}`, // filtro prioridade comum
              status_local: `Atendendo${dadosAtendimento.local}`, // filtro local comum
              status_dataVencimento: AbertoVencimento, // filtro local comum
              status_numeroChamado: `Atendendo_${dadosAtendimento.numeroChamado}`,

              status_atendente_cliente: `Atendendo${keyAtendente}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Atendendo${keyAtendente}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
              status_atendente_prioridade: `Atendendo${keyAtendente}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
              status_atendente_local: `Atendendo${keyAtendente}${dadosAtendimento.local}`, // filtro local vê proprio chamado
              status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
              status_atendente_numeroChamado: `Atendendo_${keyAtendente}${dadosAtendimento.numeroChamado}`,

              atendente_cliente: `${keyAtendente}${dadosAtendimento.keyCliente}`,
              atendente_setor: `${keyAtendente}${dadosAtendimento.keySetor}`,
              atendente_prioridade: `${keyAtendente}${dadosAtendimento.prioridade}`,
              atendente_local: `${keyAtendente}${dadosAtendimento.local}`,
              atendente_dataVencimento: AbertoVencimentoAtendente,
              atendente_numeroChamado: `${keyAtendente}${dadosAtendimento.numeroChamado}`,
              dataVencimentoUnix: dataVencimentoUnix

            })
              .then(() => {
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              });
          });
      });
    });
  };
};
export const salvarComentario = (nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp) => {
  const comentario = comentario2.trim();
  //console.log(nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp)
  let Fechado = "";
  return dispatch => {
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }
    AbertoVencimento = { Fechado: AbertoVencimento }
    let AbertoVencimentoAtendente = {};
    AbertoVencimentoAtendente[keyAtendente] = Fechado = AbertoVencimento;
    //console.log(AbertoVencimento)

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      modificavisibilidadeModalComentarioLocal(dispatch, false);
      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
        lastMessageChat: comentario,
        lastmessageChatStatus: "sent",
        lastmessageChatType: resArquivo.length > 0 ? 'arquivo' : "mensagem",
        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
        lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
        lastMessageLineColor: '#39a3f4'
      })

      firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
        let arrayNomeQuemAbriuChamado = [];
        snapshot.forEach(childItem => {
          arrayNomeQuemAbriuChamado.push({
            nomeDeQuemAbriuChamado: childItem.val().nome
          })
        })
        let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
        // console.log(arrayNomeQuemAbriuChamado)
        // console.log(arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado);
        let diferencaDeDias;
        let total = calculaDias2(dataAbertura, dataAtualPostagem)
        switch (total) {
          case 0:
            diferencaDeDias = '<1'
            break;
          case 1:
            diferencaDeDias = '1'
            break;
          case 2:
            diferencaDeDias = '2'
            break;
          case 3:
            diferencaDeDias = '3'
            break;
          case 4:
            diferencaDeDias = '4'
            break;
          case 5:
            diferencaDeDias = '5'
            break;
          case 6:
            diferencaDeDias = '6'
            break;
          case 7:
            diferencaDeDias = '7'
            break;
          default:
            diferencaDeDias = '>7'
            break;
        }
        //(diferencaDeDias)

        //comentario publico

        if (statusFechar) {
          // console.log('somente comentario sem arquivo , publico e é pra fechar')
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: 'mensagem',
            titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' Finalizou o atendimento',
            mensagem: comentario,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: '#39a3f4',
            circleColor: '#39a3f4',
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          }).then(() => {
            let dadosAtendVinc = { vazio: 'vazio' };
            firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
              status: 'Fechado', status_atendente: `Fechado${atendenteAtendimento}`,
              // usuariosVisualizaAtend: dadosAtendVinc,
              periodoResolucaoChamado: diferencaDeDias,
              dataFechamento: dataAtualPostagem,
              status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
              status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
              status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
              status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
              status_dataVencimento: AbertoVencimento, // filtro local comum
              status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

              status_atendente_cliente: `Fechado${atendenteAtendimento}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
              status_atendente_setor: `Fechado${atendenteAtendimento}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
              status_atendente_prioridade: `Fechado${atendenteAtendimento}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
              status_atendente_local: `Fechado${atendenteAtendimento}${dadosAtendimento.local}`, // filtro local vê proprio chamado
              status_atendente_dataVencimento: AbertoVencimentoAtendente, // filtro local vê proprio chamado
              status_atendente_numeroChamado: `Fechado_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
              dataHoraFechamento: dataHoraAtual,

              /*  lastMessageChat: comentario,
               lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
               lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
               lastmessageChatType: 'mensagem',
               lastmessageChatStatus: 'sent',
               lastMessageLineColor: '#39a3f4' */
            }).then(
              () => {
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                zeraInformacoesComentario(dispatch);
                //  console.log('------------------------_ANTES DA VERIFICAO--------------------');
                //  console.log(dadosChamado.atendente);
                if (dadosChamado.atendente !== 'Não informado' && premium == 1 && permissaoParaAvaliarChamado == true && licensawebouapp >= 2) {
                  abreModalAvaliarChamadoLocal(dispatch, true)
                }
                // console.log('sucesso');
              }).catch(error => {
                //error.code
                //error.message
                switch (error.code) {
                  case "auth/network-request-failed":
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    toastr.error('Error', 'Sem conexão com a Internet')
                    break;
                  default:
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    toastr.error('Error', error.message)
                    break;
                }
              });
          }).catch(error => {
            //error.code
            //error.message
            switch (error.code) {
              case "auth/network-request-failed":
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                toastr.error('Error', 'Sem conexão com a Internet')
                break;
              default:
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                toastr.error('Error', error.message)
                break;
            }
          });


        }
        //nao fecho
        else {
          // console.log('somente comentario sem arquivo , publico e é não pra fechar')
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
            tipo: 'mensagem',
            titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
            mensagem: comentario,
            dataHoraAtual: dataHoraAtual,
            dataAtualPostagem: dataAtualPostagem,
            time: timeAtual,
            lineColor: '#39a3f4',
            circleColor: '#39a3f4',
            visivelATodos: 1,
            keyDeQuemAlterouChamado
          }).then(() => {
            /*  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
               lastMessageChat: comentario,
               lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
               lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
               lastmessageChatType: 'mensagem',
               lastmessageChatStatus: 'sent',
               lastMessageLineColor: '#39a3f4'
             }) */
            ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
            zeraInformacoesComentario(dispatch);
            // console.log('sucesso');
          }).catch(error => {
            //error.code
            //error.message
            switch (error.code) {
              case "auth/network-request-failed":
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                toastr.error('Error', 'Sem conexão com a Internet')
                break;
              default:
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                toastr.error('Error', error.message)
                break;
            }
          });
        }





      })
    })

  }
}
export const salvarComentario2 = (
  nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp, viaChat,
  responderMensagem, idMensagemResponder, mensagemResposta, circleColorResponder, commentResponderType, atendenteResponder
) => {
  console.log(dadosAtendimento)
  return dispatch => {
    if (viaChat == true) {
      modificaUpandoARquivoChat(dispatch, true);
    }
    const comentario = comentario2.trim();

    /*  console.log(
       nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp
     ); */


    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }

    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Fechado';
    AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Fechado: AbertoVencimento }
    // console.log(AbertoVencimento)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      if (resArquivo.length === 0) {
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
          lastMessageChat: comentario,
          lastmessageChatStatus: "sent",
          lastmessageChatType: "mensagem",
          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
          lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
          lastMessageLineColor: '#39a3f4'
        })
      } else {
        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
          lastmessageChatStatus: dadosAtendimento.chamadoAbertoPorWpp === true ? 'waiting' : "sent",
        })
      }

      modificavisibilidadeModalComentarioLocal(dispatch, false);
      // console.log(chaveUnica)
      firebase
        .database()
        .ref(`${chaveUnica}/clientes/`)
        .orderByChild("useruid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let diferencaDeDias;
          if (statusFechar) {
            //calculo a quantidade de dias para resolver o chamado e populo no banco

            let total = calculaDias2(dataAbertura, dataAtualPostagem);
            switch (total) {
              case 0:
                diferencaDeDias = "<1";
                break;
              case 1:
                diferencaDeDias = "1";
                break;
              case 2:
                diferencaDeDias = "2";
                break;
              case 3:
                diferencaDeDias = "3";
                break;
              case 4:
                diferencaDeDias = "4";
                break;
              case 5:
                diferencaDeDias = "5";
                break;
              case 6:
                diferencaDeDias = "6";
                break;
              case 7:
                diferencaDeDias = "7";
                break;
              default:
                diferencaDeDias = ">7";
                break;
            }
          }
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          // console.log(keyDeQuemAlterouChamado)
          //então tenho arquivo
          let aux = 0;
          if (nomeArquivo !== "") {
            for (let i = 0; i < resArquivo.length; i++) {
              console.log(resArquivo[i])
              aux++;
              // console.log('2 new')
              //    let uri = resArquivo.uri.replace("file://", ""); //remove o file:// que tem no ios
              let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${keyChamado}/${keyChamado}/${resArquivo[i].name}`);
              let mime = resArquivo[i].type;
              //console.log('apos mime')
              /*    RNFetchBlob.fs.readFile(uri, "base64")
                   .then(data => {
                     console.log('apos read file')
                     return RNFetchBlob.polyfill.Blob.build(data, {
                       type: mime + ";BASE64"
                     });
                   }).catch((err) => { console.log('error antes do FIREBASE'); console.log(err) }) */
              /*   .then(blob => { */
              caminhoPadraoArquivo1.put(resArquivo[i]).then(url => {
                url.ref.getDownloadURL().then(function (downloadURL) {
                  // console.log('3')
                  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                    tipo: "link",
                    titulo:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem: resArquivo[i].name,
                    urlDownload: downloadURL,
                    tipoArquivo: resArquivo[i].type,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    audio: resArquivo[i].audio == undefined ? false : resArquivo[i].audio,
                    duracao: resArquivo[i].audio == true ? resArquivo[i].duracao : '',
                    lineColor: "#39a3f4",
                    circleColor: "#39a3f4",
                    visivelATodos: 1,
                    keyDeQuemAlterouChamado,
                    alterado: firebase.database.ServerValue.TIMESTAMP,
                    idMensagemResponder,
                    possuiResposta: responderMensagem,
                    mensagemResposta: mensagemResposta,
                    circleColorResponder,
                    commentResponderType,
                    atendenteResponder
                  })
                    .then(() => {
                      // console.log('aux e resarquiv length')
                      // console.log(aux);
                      // console.log(resArquivo.length)
                      if (aux == resArquivo.length) {
                        firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                          lastMessageChat: resArquivo[i].name,
                          lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                          lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
                          lastmessageChatType: resArquivo[i].type,
                          // lastmessageChatStatus: 'sent',
                          lastMessageLineColor: '#39a3f4'
                        }).then(() => {
                          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                          modificaUpandoARquivoChat(dispatch, false);
                          zeraInformacoesComentario(dispatch);
                          ativaDesativaLoadingEnviandoAudioChat(dispatch, false);
                        })
                      }
                      // console.log('4')
                    });
                })
              })
                .catch(error => {
                  ativaDesativaLoadingEnviandoAudioChat(dispatch, false);
                  // console.log(error)
                  //error.code
                  //error.message
                  switch (error.code) {
                    case "auth/network-request-failed":
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                      modificaUpandoARquivoChat(dispatch, false);
                      toastr.error('Error', 'Sem conexão com a INTERNET!');
                      break;
                    default:
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                      modificaUpandoARquivoChat(dispatch, false);
                      toastr.error('Error', error.message);
                      break;
                  }
                });
              /*  })
               .catch(error => {
                 console.log(error)
                 //error.code
                 //error.message
                 switch (error.code) {
                   case "auth/network-request-failed":
                     ativaDesativaLoadingTelaAcoesAtendimento(
                       dispatch,
                       false
                     );
                     zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                       dispatch,
                       "semConexaoInternet"
                     );
                     break;
                   default:
                     ativaDesativaLoadingTelaAcoesAtendimento(
                       dispatch,
                       false
                     );
                     zeraMensagemSemConexaoInternetSalvarComentarioLocal(
                       dispatch,
                       "semConexaoInternet"
                     );
                     break;
                 }
               }); */
            }
          }

          //somente comentario
          else {
            //comentario publico

            if (statusFechar) {
              firebase
                .database()
                .ref(`${chaveUnica}/acoesChamados/${keyChamado}/`)
                .push({
                  tipo: "mensagem",
                  titulo:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Finalizou o atendimento",
                  mensagem: comentario,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#39a3f4",
                  circleColor: "#39a3f4",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado
                })
                .then(() => {
                  let dadosAtendVinc = { vazio: 'vazio' };
                  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
                    status: 'Fechado', status_atendente: `Fechado${atendenteAtendimento}`,
                    //usuariosVisualizaAtend: dadosAtendVinc,
                    periodoResolucaoChamado: diferencaDeDias,
                    dataFechamento: dataAtualPostagem,
                    status_cliente: `Fechado${dadosAtendimento.keyCliente}`, //filtro cliente comum
                    status_setor: `Fechado${dadosAtendimento.keySetor}`, //filtro setor comum
                    status_prioridade: `Fechado${dadosAtendimento.prioridade}`, // filtro prioridade comum
                    status_local: `Fechado${dadosAtendimento.local}`, // filtro local comum
                    status_dataVencimento: AbertoVencimento, // filtro local comum
                    status_numeroChamado: `Fechado_${dadosAtendimento.numeroChamado}`,

                    status_atendente_cliente: `Fechado${atendenteAtendimento}${dadosAtendimento.keyCliente}`, //filtro cliente vê proprio chamado
                    status_atendente_setor: `Fechado${atendenteAtendimento}${dadosAtendimento.keySetor}`, //filtro setor vê proprio chamado
                    status_atendente_prioridade: `Fechado${atendenteAtendimento}${dadosAtendimento.prioridade}`, // filtro prioridade vê proprio chamado
                    status_atendente_local: `Fechado${atendenteAtendimento}${dadosAtendimento.local}`, // filtro local vê proprio chamado
                    status_atendente_dataVencimento: AbertoVencimentoAtendente, // filtro local vê proprio chamado
                    status_atendente_numeroChamado: `Fechado_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
                    dataHoraFechamento: dataHoraAtual,

                    /*  lastMessageChat: comentario,
                     lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                     lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
                     lastmessageChatType: "mensagem",
                     lastmessageChatStatus: 'sent',
                     lastMessageLineColor: '#39a3f4' */
                  }).then(
                    () => {
                      ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                      zeraInformacoesComentario(dispatch);
                      //  console.log('------------------------_ANTES DA VERIFICAO--------------------');
                      //  console.log(dadosChamado.atendente);
                      if (dadosChamado.atendente !== 'Não informado' && premium == 1 && permissaoParaAvaliarChamado == true && licensawebouapp >= 2) {
                        abreModalAvaliarChamadoLocal(dispatch, true)
                      }
                      //  console.log('sucesso');
                    }).catch(error => {
                      //error.code
                      //error.message
                      switch (error.code) {
                        case "auth/network-request-failed":
                          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                          toastr.error('Error', 'sem Conexão com a internet')
                          break;
                        default:
                          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                          toastr.error('Error', error.message)
                          break;
                      }
                    });
                })
                .catch(error => {
                  //error.code
                  //error.message
                  switch (error.code) {
                    case "auth/network-request-failed":
                      ativaDesativaLoadingTelaAcoesAtendimento(
                        dispatch,
                        false
                      );
                      toastr.error('Error', 'sem Conexão com a internet')
                      break;
                    default:
                      ativaDesativaLoadingTelaAcoesAtendimento(
                        dispatch,
                        false
                      );
                      toastr.error('Error', error.message)
                      break;
                  }
                });
            }
            //nao fecho
            else {
              // console.log('somente comentario sem arquivo , publico e é não pra fechar')
              firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                tipo: 'mensagem',
                titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: comentario,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,

                time: timeAtual,
                lineColor: '#39a3f4',
                circleColor: '#39a3f4',
                visivelATodos: 1,
                keyDeQuemAlterouChamado,
                alterado: firebase.database.ServerValue.TIMESTAMP,
                possuiResposta: responderMensagem,
                mensagemResposta: mensagemResposta,
                circleColorResponder,
                commentResponderType,
                idMensagemResponder,
                atendenteResponder
              }).then(() => {
                // firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/${dadosAcoesChamado.key}`).update({ statusMessage: 'sent' });
                ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                modificaUpandoARquivoChat(dispatch, false);
                zeraInformacoesComentario(dispatch);
                ativaDesativaLoadingEnviandoAudioChat(dispatch, false);
                /*   firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                    lastMessageChat: comentario,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
                    lastmessageChatType: "mensagem",
                    lastmessageChatStatus: 'sent',
                    lastMessageLineColor: '#39a3f4'
                  }) */
                // console.log('sucesso');
              }).catch(error => {
                ativaDesativaLoadingEnviandoAudioChat(dispatch, false);

                //error.code
                //error.message
                switch (error.code) {
                  case "auth/network-request-failed":
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    modificaUpandoARquivoChat(dispatch, false);
                    toastr.error('Error', 'sem Conexão com a internet')
                    break;
                  default:
                    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                    modificaUpandoARquivoChat(dispatch, false);
                    toastr.error('Error', error.message)
                    break;
                }
              });
            }

            //comentario privado

          }
        });
    });
  };
};
export const uploadArquivoChat = (
  nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp, viaChat,
  responderMensagem, idMensagemResponder, mensagemResposta, circleColorResponder, commentResponderType
) => {
  const comentario = comentario2.trim();
  console.log('test')
  console.log(dadosAtendimento)
  let teste = dadosAtendimento.lastmessageChatCount + 1;
  console.log(teste)
  return dispatch => {
    /*  console.log(
       nomeArquivo, statusFechar, comentario2, resArquivo, comentarioPublicoSimOuNao, keyChamado, dadosChamado, dataAbertura, premium, permissaoParaAvaliarChamado, keyAtendente, dadosAtendimento, licensawebouapp
     ); */
    if (viaChat === true && resArquivo.length !== 0) {
      // toastr.warning('Enviando arquivo', 'Aguarde um momento...');
      // modificaUpandoARquivoChat(dispatch, true);

    }

    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
    let atendenteAtendimento = keyAtendente.toString() == "" ? "Não informado" : keyAtendente.toString();
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dadosAtendimento.dataVencimento.toString() == "Não informado" ? 0 : dadosAtendimento.dataVencimento;
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
    } else {
      AbertoVencimento = false;
    }

    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = 'Fechado';
    AbertoVencimentoAtendente[keyAtendente] = AbertoVencimento;
    testeObjeto[Aberto] = AbertoVencimentoAtendente;
    AbertoVencimento = { Fechado: AbertoVencimento }
    // console.log(AbertoVencimento)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      /*  firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}/`).update({
         lastMessageChat: comentario,
         lastmessageChatStatus: "sent",
         lastmessageChatType: 'arquivo',
         lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
         lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
         lastMessageLineColor: '#39a3f4'
       }) */
      modificavisibilidadeModalComentarioLocal(dispatch, false);
      // console.log(chaveUnica)
      firebase
        .database()
        .ref(`${chaveUnica}/clientes/`)
        .orderByChild("useruid")
        .equalTo(firebase.auth().currentUser.uid)
        .once("value")
        .then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let diferencaDeDias;
          if (statusFechar) {
            //calculo a quantidade de dias para resolver o chamado e populo no banco

            let total = calculaDias2(dataAbertura, dataAtualPostagem);
            switch (total) {
              case 0:
                diferencaDeDias = "<1";
                break;
              case 1:
                diferencaDeDias = "1";
                break;
              case 2:
                diferencaDeDias = "2";
                break;
              case 3:
                diferencaDeDias = "3";
                break;
              case 4:
                diferencaDeDias = "4";
                break;
              case 5:
                diferencaDeDias = "5";
                break;
              case 6:
                diferencaDeDias = "6";
                break;
              case 7:
                diferencaDeDias = "7";
                break;
              default:
                diferencaDeDias = ">7";
                break;
            }
          }
          let keyDeQuemAlterouChamado = Object.keys(snapshot.val()).toString();
          //console.log(keyDeQuemAlterouChamado)
          //então tenho arquivo
          let aux = 0;
          let contadorArquivoText = 1;
          for (let i = 0; i < resArquivo.length; i++) {
            aux++;
            //  console.log('2 new')
            //    let uri = resArquivo.uri.replace("file://", ""); //remove o file:// que tem no ios
            let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${keyChamado}/${keyChamado}/${resArquivo[i].name}`).put(resArquivo[i]);
            let mime = resArquivo[i].type;
            // console.log('apos mime')

            caminhoPadraoArquivo1.on('state_changed', (snapshot) => {
              const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              //   console.log(progress)
              //   console.log(`enviando arquivo ${i} ${progress}%`);
              modificaTextArquivoChat(dispatch, `Enviando arquivo (${contadorArquivoText}) - ${progress}%`)
            }, (error) => {
              toastr.error('Error', error.message);
              modificaTextArquivoChat(dispatch, '');
            }, () => {
              //   console.log('sucesso upload stream');
              //  console.log(caminhoPadraoArquivo1.snapshot)
              caminhoPadraoArquivo1.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                //  console.log('File available at', downloadURL);
                contadorArquivoText++;

                // console.log('3')
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}/`).push({
                  tipo: "link",
                  titulo:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem: resArquivo[i].name,
                  urlDownload: downloadURL,
                  tipoArquivo: resArquivo[i].type,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#39a3f4",
                  circleColor: "#39a3f4",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado,
                  idMensagemResponder,
                  possuiResposta: responderMensagem,
                  mensagemResposta: mensagemResposta,
                  circleColorResponder,
                  commentResponderType
                })
                  .then(() => {
                    //   console.log(aux);
                    //  console.log(resArquivo.length)
                    if (aux == resArquivo.length) {
                      firebase.database().ref(`${chaveUnica}/chamados/${keyChamado}`).update({
                        lastMessageChat: resArquivo[i].name,
                        lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                        lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
                        lastmessageChatType: resArquivo[i].type,
                        lastmessageChatStatus: 'sent',
                        lastMessageLineColor: '#39a3f4'
                      }).then(() => {
                        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
                        zeraInformacoesEnvioArquivoChat(dispatch);
                        if (viaChat === true && resArquivo.length !== 0) {
                          // toastr.success('Arquivo enviado com sucesso!');
                          modificaTextArquivoChat(dispatch, '')
                          //   modificaUpandoARquivoChat(dispatch, false);
                        }
                      })
                    }
                  });
              });

            })




          }


        });
    });
  };
};
export const alterarChamado = (
  clienteAtendimento2,
  atendenteAtendimento2,
  setorAtendimento2,
  prioridadeAtendimento2,
  localAtendimento2,
  descricaoAtendimento2,
  dataVencimento2,
  keyAtendimento,
  arrayInformacoesAnteriores,
  nomeAuxiliarAtendente2,
  nomeAuxiliarCliente2,
  nomeAuxiliarSetor2,
  keyEmpresaVinculada2,
  statusChamado,
  dadosAtendimento
) => {
  let keyEmpresaVinculada;
  if (keyEmpresaVinculada2 != "" && keyEmpresaVinculada2 != undefined) {
    keyEmpresaVinculada = keyEmpresaVinculada2;
  } else {
    keyEmpresaVinculada = 'Não Vinculado'
  }
  //  console.log(statusChamado)
  // console.log(dadosAtendimento)
  let nomeAuxiliarAtendente = nomeAuxiliarAtendente2.toString() == "" ? "Não informado" : nomeAuxiliarAtendente2;
  let nomeAuxiliarCliente = nomeAuxiliarCliente2.toString() == "" ? "Não informado" : nomeAuxiliarCliente2;
  let nomeAuxiliarSetor = nomeAuxiliarSetor2.toString() == "" ? "Não informado" : nomeAuxiliarSetor2;

  let dataVencimento = dataVencimento2.toString() == "" ? "Não informado" : dataVencimento2;
  let clienteAtendimento = clienteAtendimento2.toString() == "" ? "Não informado" : clienteAtendimento2.toString();
  let prioridadeAtendimento = prioridadeAtendimento2.toString() == "" ? "Não informado" : prioridadeAtendimento2.toString();
  let atendenteAtendimento = atendenteAtendimento2.toString() == "" ? "Não informado" : atendenteAtendimento2.toString();
  let setorAtendimento = setorAtendimento2.toString() == "" ? "Não informado" : setorAtendimento2.toString();
  let localAtendimento = localAtendimento2.toString() == "" ? "Não informado" : localAtendimento2.toString();

  let dataVencimentoUnix = '';
  const descricaoAtendimento = descricaoAtendimento2.trim();
  return dispatch => {
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    let AbertoVencimento = dataVencimento.toString() == "Não informado" ? 0 : dataVencimento;
    let AbertoVencimentoAtendente = {};
    let testeObjeto = {};
    let Aberto = '';
    if (AbertoVencimento !== 0) {
      AbertoVencimento = moment(AbertoVencimento, 'DD/MM/YYYY').unix();
      dataVencimentoUnix = AbertoVencimento;
    } else {
      AbertoVencimento = false;
    }
    if (statusChamado == 'Aberto') {
      Aberto = 'Aberto'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Aberto: AbertoVencimento }
    }
    if (statusChamado == 'Atendendo') {
      Aberto = 'Atendendo'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Atendendo: AbertoVencimento }
    }
    if (statusChamado == 'Fechado') {
      Aberto = 'Fechado'
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Fechado: AbertoVencimento }
    }
    if (statusChamado == 'Cancelado') {
      Aberto = 'Cancelado';
      AbertoVencimentoAtendente[atendenteAtendimento] = AbertoVencimento;
      testeObjeto[Aberto] = AbertoVencimentoAtendente;
      AbertoVencimento = { Cancelado: AbertoVencimento }

    }

    // console.log(AbertoVencimento)

    if (descricaoAtendimento == "") {
      toastr.error('Error', 'informe a DESCRIÇÃO DO PROBLEMA');
    } else {
      AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true);
        dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, payload: false });
        //  dispatch(NavigationActions.back());
        const chaveUnica = chaveUnica2.replace(/"/g, "");

        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            });
          });
          let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
            atendente: atendenteAtendimento,
            cliente: clienteAtendimento,
            descricaoProblema: descricaoAtendimento,
            local: localAtendimento,
            prioridade: prioridadeAtendimento,
            setor: setorAtendimento,
            status_atendente: `${statusChamado}${atendenteAtendimento}`,
            //        status: (nomeAuxiliarAtendente.toString() == 'Não informado') ? 'Aberto' : 'Atendendo',
            dataVencimento: dataVencimento,
            clienteVinculado: clienteAtendimento == "Não informado" ? 0 : 1,
            setorVinculado: setorAtendimento == "Não informado" ? 0 : 1,
            keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,
            keyEmpresaVinculada,

            status_cliente: `${statusChamado}${clienteAtendimento}`, //filtro cliente comum
            status_setor: `${statusChamado}${setorAtendimento}`, //filtro setor comum
            status_prioridade: `${statusChamado}${prioridadeAtendimento}`, // filtro prioridade comum
            status_local: `${statusChamado}${localAtendimento}`, // filtro local comum
            status_dataVencimento: AbertoVencimento, // filtro local comum
            status_numeroChamado: `${statusChamado}_${dadosAtendimento.numeroChamado}`,

            status_atendente_cliente: `${statusChamado}${atendenteAtendimento}${clienteAtendimento}`, //filtro cliente vê proprio chamado
            status_atendente_setor: `${statusChamado}${atendenteAtendimento}${setorAtendimento}`, //filtro setor vê proprio chamado
            status_atendente_prioridade: `${statusChamado}${atendenteAtendimento}${prioridadeAtendimento}`, // filtro prioridade vê proprio chamado
            status_atendente_local: `${statusChamado}${atendenteAtendimento}${localAtendimento}`, // filtro local vê proprio chamado
            status_atendente_dataVencimento: testeObjeto, // filtro local vê proprio chamado
            status_atendente_numeroChamado: `${statusChamado}_${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,

            atendente_cliente: `${atendenteAtendimento}${clienteAtendimento}`,
            atendente_setor: `${atendenteAtendimento}${setorAtendimento}`,
            atendente_prioridade: `${atendenteAtendimento}${prioridadeAtendimento}`,
            atendente_local: `${atendenteAtendimento}${localAtendimento}`,
            atendente_dataVencimento: AbertoVencimentoAtendente,
            atendente_numeroChamado: `${atendenteAtendimento}${dadosAtendimento.numeroChamado}`,
            dataVencimentoUnix: dataVencimentoUnix

          })
            .then(sucess => {
              /* if (setorAtendimento !== "Não informado" && statusChamado == 'Aberto') {
                //  console.log('setor diferente de naão informado');
                //busco clientes vinculados ao setor, se nao tiver eu seto todos os atendentes se tiver seto apenas os atendentes vinculados
                firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                  let dadosAtendVinc = snapshotSetor.val();
                  //   console.log(snapshotSetor.val());
                  if (snapshotSetor.val()) {
                    //tem atendentes vinculados ao setor
                    const dadosAtendentesVinculadosSetor = [];
                    snapshotSetor.forEach((childItem) => {
                      dadosAtendentesVinculadosSetor.push({
                        key: childItem.key,
                      });
                    })
                    //  console.log(dadosAtendentesVinculadosSetor)

                    let verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 0;
                    if (atendenteAtendimento !== 'Não informado') {
                      //verifico se o atendente do chamado nao esta vinculado a este setor
                      for (let i = 0; i < dadosAtendentesVinculadosSetor.length; i++) {
                        //  console.log(dadosAtendentesVinculadosSetor[i].key)
                        if (dadosAtendentesVinculadosSetor[i].key == atendenteAtendimento) {
                          verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado = 1;

                        }
                      }
                      //atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado
                      if (verificoSeAtendenteDoChamadoNaoEstaNoSetorVinculado == 0) {
                        // console.log('atendente nao esta no grupo porem foi vinculado ao atendimento, coloco ele nos vinculados do chamado')
                        let objetocadastro = {};
                        firebase.database().ref(`${chaveUnica}/usuarios/${atendenteAtendimento}`).once("value").then((snapshot) => {
                          let key = snapshot.key;
                          let codigoInterno = snapshot.val().codigoInterno;
                          objetocadastro[codigoInterno] = codigoInterno;
                          objetocadastro[key] = true;
                          //console.log(objetocadastro)
                          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                        })

                      } else {
                        //creio que ja cai na situaçaão acima
                        //  console.log('creio que ja cai na situaçaão acima NAO SEI SE EH PRA CHEGAR AQUI')
                      }
                    }

                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                  } else {
                    //  console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                    firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                      //  console.log(snapshot.val())
                      const idTodosAtendentes = [];
                      let objetocadastro = {};
                      snapshot.forEach((childItem) => {
                        idTodosAtendentes.push({
                          key: childItem.key,
                          codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                        });
                      })
                      // console.log(idTodosAtendentes)
                      for (let i = 0; i < idTodosAtendentes.length; i++) {
                        objetocadastro[idTodosAtendentes[i].key] = true;
                        objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                      }
                      //   console.log(objetocadastro)
                      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                    })

                  }
                })
              } else {
                //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                //  console.log('SETOR NAO INFORMADOOOOO')
                let dadosAtendVinc = { vazio: 'vazio' };
                firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({ usuariosVisualizaAtend: dadosAtendVinc })

              } */
              if (arrayInformacoesAnteriores[0].prioridade !== prioridadeAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + " Alterou prioridade do atendimento de " +
                    arrayInformacoesAnteriores[0].prioridade + " para " + prioridadeAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                });
              }
              if (arrayInformacoesAnteriores[0].local !== localAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o local do atendimento de " +
                    arrayInformacoesAnteriores[0].local +
                    " para " +
                    localAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                });
              }
              if (arrayInformacoesAnteriores[0].dataVencimento !== dataVencimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    " Alterou a data de vencimento de " +
                    arrayInformacoesAnteriores[0].dataVencimento +
                    " para " +
                    dataVencimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                });
              }
              if (arrayInformacoesAnteriores[0].descricaoProblema !== descricaoAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou a descrição do problema de " +
                    arrayInformacoesAnteriores[0].descricaoProblema +
                    " para " +
                    descricaoAtendimento,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                }).then(() => {
                  firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                    lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou a descrição do problema de " +
                      arrayInformacoesAnteriores[0].descricaoProblema +
                      " para " +
                      descricaoAtendimento,
                    lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                    lastmessageChatCount: dadosAtendimento.lastmessageChatCount + 1,
                    lastmessageChatType: "mensagem",
                    lastmessageChatStatus: 'sent',
                    lastMessageLineColor: '#39a3f4'
                  })
                });
              }
              if (arrayInformacoesAnteriores[0].keyAtendente !== atendenteAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o atendente de " +
                    arrayInformacoesAnteriores[0].atendente +
                    " para " +
                    nomeAuxiliarAtendente,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 1,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                });
              }
              if (arrayInformacoesAnteriores[0].keyCliente !== clienteAtendimento) {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                  tipo: "mensagem",
                  titulo:

                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                  mensagem:
                    arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                    " Alterou o cliente de " +
                    arrayInformacoesAnteriores[0].cliente +
                    " para " +
                    nomeAuxiliarCliente,
                  dataHoraAtual: dataHoraAtual,
                  dataAtualPostagem: dataAtualPostagem,
                  time: timeAtual,
                  lineColor: "#00FF7F",
                  circleColor: "#00FF7F",
                  visivelATodos: 0,
                  keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                });
              }
              if (arrayInformacoesAnteriores[0].keySetor !== setorAtendimento) {
                firebase
                  .database()
                  .ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`)
                  .push({
                    tipo: "mensagem",
                    titulo:

                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                    mensagem:
                      arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                      " Alterou o setor de " +
                      arrayInformacoesAnteriores[0].setor +
                      " para " +
                      nomeAuxiliarSetor,
                    dataHoraAtual: dataHoraAtual,
                    dataAtualPostagem: dataAtualPostagem,
                    time: timeAtual,
                    lineColor: "#00FF7F",
                    circleColor: "#00FF7F",
                    visivelATodos: 0,
                    keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
                  }).then(() => {
                    firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
                      lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado +
                        " Alterou o setor de " +
                        arrayInformacoesAnteriores[0].setor +
                        " para " +
                        nomeAuxiliarSetor,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: arrayInformacoesAnteriores[0].lastmessageChatCount + 1,
                      lastmessageChatType: "mensagem",
                      lastmessageChatStatus: 'sent',
                      lastMessageLineColor: '#39a3f4'
                    });
                  })
              }
              ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);
              toastr.success('Sucesso', 'Atendimento alterado com sucesso!')
              //dispatch(NavigationActions.back());

              //dispatch({ type: VERIFICACHAMADOALTERADOSUCESSO, payload: "chamadoAlteradoComSucesso" });
            });
        });
      });
    }
  };
};

export const alterarChamado2 = (descricaoAtendimento2, arrayInformacoesAnteriores, keyAtendimento, criarChamadoProblemaEstatico,
  problemaEstaticoSelecionado2, nomeProblemaEstaticoSelecionado, utilizaProblemaEstatico) => {
  const descricaoAtendimento = descricaoAtendimento2.trim();
  return dispatch => {
    const timeAtual = retornaHoraAtual();
    const dataHoraAtual = retornaDataHoraAtual();
    const dataAtualPostagem = retornaDataAtual();
    //console.log(criarChamadoProblemaEstatico, nomeProblemaEstaticoSelecionado, utilizaProblemaEstatico, problemaEstaticoSelecionado2)
    let problemaEstaticoSelecionado = '';
    if (problemaEstaticoSelecionado2.length > 0) {
      problemaEstaticoSelecionado = problemaEstaticoSelecionado2
    }
    if (descricaoAtendimento == "") {
      toastr.error('Error', "Informe a Descrição do Problema")
    } else {
      // console.log('antes do array')
      AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true)
        dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, payload: false });

        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
          let arrayNomeQuemAbriuChamado = [];
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome
            })
          })
          let keyAtendenteQueAlterouChamado = Object.keys(snapshot.val()).toString();
          // console.log('antes do desc problema')
          let descProblema = '';
          if (criarChamadoProblemaEstatico == true && utilizaProblemaEstatico == 1) {
            descProblema = nomeProblemaEstaticoSelecionado;
          } else {
            descProblema = descricaoAtendimento;
          }
          // console.log(keyAtendenteQueAlterouChamado)
          firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({
            keyAtendenteQueAlterouChamado: keyAtendenteQueAlterouChamado,
            descricaoProblema: (criarChamadoProblemaEstatico == true && utilizaProblemaEstatico == 1) ? nomeProblemaEstaticoSelecionado : descricaoAtendimento,
            //descricaoProblemaEstatico: (criarChamadoProblemaEstatico == true) ? 1 : 0,
            keyProblemaEstatico: (criarChamadoProblemaEstatico == true && utilizaProblemaEstatico == 1) ? problemaEstaticoSelecionado : [],

            lastMessageChat: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' Alterou a descrição do problema de ' + arrayInformacoesAnteriores[0].descricaoProblema + ' para ' + descProblema,
            lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
            lastmessageChatCount: arrayInformacoesAnteriores[0].lastmessageChatCount + 1,
            lastmessageChatType: "mensagem",
            lastmessageChatStatus: 'sent',
            lastMessageLineColor: '#39a3f4'
          }).then((sucess) => {
            if (arrayInformacoesAnteriores[0].descricaoProblema !== descProblema) {
              //  console.log('dentro de descricao problema')
              firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/`).push({
                tipo: 'mensagem',
                titulo: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                mensagem: arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado + ' Alterou a descrição do problema de ' + arrayInformacoesAnteriores[0].descricaoProblema + ' para ' + descProblema,
                dataHoraAtual: dataHoraAtual,
                dataAtualPostagem: dataAtualPostagem,
                time: timeAtual,
                lineColor: '#39a3f4',
                circleColor: '#39a3f4',
                visivelATodos: 1,
                keyDeQuemAlterouChamado: keyAtendenteQueAlterouChamado
              })
            }
            ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false)
            //  console.log('chamado alterado com sucesso')
            toastr.success('Sucesso', "Atendimento alterado com sucesso.")
          })
        })
      })
    }
  }
}
export const cadastraNovoAtendimento = (
  clienteAtendimento2,
  atendenteAtendimento2,
  setorAtendimento2,
  prioridadeAtendimento2,
  localAtendimento2,
  descricaoAtendimento2,
  chamadosAnteriores2,
  nomeAuxCliente,
  nomeAuxAtendente,
  dataVencimento2,
  arquivosParaSerUpadosNaAberturaChamado,
  setorSelecionado,
  verificaAgruparAtendenteSetor,
  nomeAuxSetor,
  criarChamadoProblemaEstatico,
  problemaEstaticoSelecionado2,
  nomeProblemaSelecionado,
  aberturaViaChat
) => {
  /*  console.log(criarChamadoProblemaEstatico)
   console.log(problemaEstaticoSelecionado2)
   console.log(nomeProblemaSelecionado); */
  let problemaEstaticoSelecionado = '';
  if (problemaEstaticoSelecionado2.length > 0) {
    problemaEstaticoSelecionado = problemaEstaticoSelecionado2;
  }
  let dataVencimento = (dataVencimento2.toString() == '') ? 'Não informado' : dataVencimento2
  // let chamadosAnteriores = (chamadosAnteriores2[0].clientes == 'semChamadosAbertos') ? [] : chamadosAnteriores2;
  let clienteAtendimento = (clienteAtendimento2.toString() == '') ? 'Não informado' : clienteAtendimento2.toString();
  let prioridadeAtendimento = (prioridadeAtendimento2.toString() == '') ? 'Não informado' : prioridadeAtendimento2.toString();
  let atendenteAtendimento = (atendenteAtendimento2.toString() == '') ? 'Não informado' : atendenteAtendimento2.toString();
  let setorAtendimento = (setorAtendimento2.toString() == '') ? 'Não informado' : setorAtendimento2.toString();
  let localAtendimento = (localAtendimento2.toString() == '') ? 'Não informado' : localAtendimento2.toString();
  let chamadoAbertoPor = 'cliente';
  let dadosAtendVinc = '';
  /*  let atendenteAtendimento = atendenteAtendimento2.toString();
   let setorAtendimento = setorAtendimento2.toString();
   let prioridadeAtendimento = prioridadeAtendimento2.toString();
   let localAtendimento = localAtendimento2.toString(); */
  const dataAbertura = retornaDataAtual();
  const timeAtual = retornaHoraAtual();
  const dataHoraAtual = retornaDataHoraAtual();

  const descricaoAtendimento = descricaoAtendimento2.trim();
  /* console.log(firebase.auth().currentUser.uid)
  console.log(`${clienteAtendimento}, ${atendenteAtendimento}, ${setorAtendimento}, ${prioridadeAtendimento},
        ${localAtendimento}, ${descricaoAtendimento}, ${nomeAuxCliente},${nomeAuxSetor}`); */
  return dispatch => {


    if (descricaoAtendimento == "" && criarChamadoProblemaEstatico == false) {
      // dispatch({ type: SEMCONEXAOINTERNETCADASTRACHAMADO, payload: "semDescricaoChamado" });
      toastr.error('Error', 'Informe a Descrição do Problema');
      dispatch({ type: ATIVALOADINGBOTAOCADASTRARCHAMADO, payload: false })
    } else if (verificaAgruparAtendenteSetor == true && setorAtendimento == 'Não informado') {
      toastr.error('Error', 'Selecione o Produto/Setor');
      dispatch({ type: ATIVALOADINGBOTAOCADASTRARCHAMADO, payload: false })
      // dispatch({ type: SEMCONEXAOINTERNETCADASTRACHAMADO, payload: "semSetorInformado" });
    } else if (criarChamadoProblemaEstatico == true && problemaEstaticoSelecionado.length == 0) {
      toastr.error('Error', 'Selecione o Problema');
      dispatch({ type: ATIVALOADINGBOTAOCADASTRARCHAMADO, payload: false })
      //  dispatch({ type: SEMCONEXAOINTERNETCADASTRACHAMADO, payload: "semProblemaEstaticoInformado" });
    } else {
      ativaLoadingBotaoCadastrarChamado(dispatch);
      AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        //  console.log('antes do metodo')
        firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {

          let keyDeQuemAbriuChamado = Object.keys(snapshot.val())[0];
          let arrayNomeQuemAbriuChamado = [];
          //   console.log(snapshot.val())
          snapshot.forEach(childItem => {
            arrayNomeQuemAbriuChamado.push({
              nomeDeQuemAbriuChamado: childItem.val().nome,
              nomeEmpresaDeQuemAbriuChamado: childItem.val().empresa,
              keyEmpresaVinculada: (childItem.val().keyEmpresaVinculada == undefined) ? 'Não Vinculado' : childItem.val().keyEmpresaVinculada,
            })
          })
          /*        console.log(arrayNomeQuemAbriuChamado)
                 console.log(arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado)
                 console.log(arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada)
                 console.log(keyDeQuemAbriuChamado); */

          firebase.database().ref(`${chaveUnica}/proximaNumeracao/`).orderByChild('numeroAtual').once("value").then((snapshot) => {
            if (snapshot.val() == null) {
              firebase.database().ref(`${chaveUnica}/proximaNumeracao`).push({ numeroAtual: 1 }).then(
                () => {
                  // console.log('depois do metodo');
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/`)
                    .push({
                      cliente: keyDeQuemAbriuChamado,
                      atendente: atendenteAtendimento,
                      setor: setorAtendimento,
                      prioridade: prioridadeAtendimento,
                      local: localAtendimento,
                      descricaoProblema: (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento,
                      descricaoProblemaEstatico: (criarChamadoProblemaEstatico == true) ? 1 : 0,
                      keyProblemaEstatico: problemaEstaticoSelecionado,
                      dataAbertura: dataAbertura,
                      dataHoraAbertura: dataHoraAtual,
                      abertoPor: keyDeQuemAbriuChamado,
                      status: "Aberto",
                      alterado: 0,
                      numeroChamado: 1,
                      chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
                      dataVencimento: dataVencimento,
                      tempoGasto: 0,
                      clienteVinculado: 1,
                      status_atendente: "AbertoNão informado",
                      dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      setorVinculado: (setorAtendimento == 'Não informado') ? 0 : 1,
                      keyEmpresaVinculada: arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada,
                      status_cliente: `Aberto${keyDeQuemAbriuChamado}`,
                      status_setor: `Aberto${setorAtendimento}`, //filtro setor comum
                      status_numeroChamado: `Aberto_${1}`, // filtro local comum
                      channelChamado: aberturaViaChat === true ? 'clienteChatWeb' : 'clienteWEB',
                      abertoPorChat: aberturaViaChat === true ? `${keyDeQuemAbriuChamado}Chat` : 'false',
                      status_atendente_abertoviachat: aberturaViaChat === true ? "AbertoNão informadotrue" : '',
                      lastMessageChat: descricaoAtendimento,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: 1,
                      lastmessageChatType: "mensagem",
                      lastmessageChatStatus: 'sent',
                      lastMessageLineColor: '#39a3f4'
                    })
                    .then(dadosChamado => {
                      //  console.log(dadosChamado.key);
                      if (setorAtendimento !== "Não informado") {
                        //   console.log('setor diferente de naão informado');
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          dadosAtendVinc = snapshotSetor.val();
                          //    console.log(snapshotSetor.val());
                          console.log(dadosAtendVinc)
                          let keyAtendentesVinculados = Object.keys(snapshotSetor.val());
                          console.log(keyAtendentesVinculados)
                          for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                            if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                              dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                            }
                          }
                          console.log(dadosAtendVinc)
                          if (snapshotSetor.val()) {


                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,
                              });
                              if (aberturaViaChat == true) {
                                if (childItem.key !== undefined) {
                                  if (childItem.key.includes('codigo')) {
                                    dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`;
                                    dadosAtendVinc[`${childItem.key}Chatstatus`] = `${childItem.key}ChatAberto`;
                                  } else {
                                    dadosAtendVinc[`${childItem.key}Chat`] = true;
                                  }
                                }
                                /*  dadosAtendVinc[`${childItem.key}Chat`] = true; //retirar depois de 1 mes
                                 dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`; */
                              }
                            })
                            // console.log(dadosAtendentesVinculadosSetor)
                            firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            //   console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              //    console.log(snapshot.val())
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              // console.log(idTodosAtendentes)
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                //objetocadastro[idTodosAtendentes[i].key] = true;
                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";

                                if (aberturaViaChat == true) {
                                  objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                                  objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                                }
                              }
                              // console.log(objetocadastro)
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                              //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            })

                          }
                        })
                      } else {
                        //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                        // console.log('SETOR NAO INFORMADOOOOO,MOSTRO PRA TODOS ATENDENTES')
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                          //  console.log(snapshot.val())
                          const idTodosAtendentes = [];
                          let objetocadastro = {};
                          snapshot.forEach((childItem) => {
                            idTodosAtendentes.push({
                              key: childItem.key,
                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                            });
                          })
                          //  console.log(idTodosAtendentes)
                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                            // objetocadastro[idTodosAtendentes[i].key] = true;
                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                            if (aberturaViaChat == true) {
                              objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                              objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                            }
                          }
                          // console.log(objetocadastro)
                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                        })
                        /* let objetocadastro = {}
                        if (atendenteAtendimento != "Não informado") {
                          console.log('atendente informadoooooo')
                          console.log(atendenteAtendimento)
                          objetocadastro[atendenteAtendimento] = true;
                          console.log(objetocadastro)
                          let dadosAtendVinc = objetocadastro;
                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/`).update({dadosAtendVinc})
                        } */
                      }


                      firebase.database().ref(`${chaveUnica}/acoesChamados/${dadosChamado.key}/`).push({
                        tipo: 'mensagem',
                        titulo: 'Atendimento criado por: ' + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                        criadoPor: keyDeQuemAbriuChamado,
                        mensagem: aberturaViaChat === true ? (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento : dataHoraAtual,
                        dataHoraAtual: dataHoraAtual,
                        time: timeAtual,
                        lineColor: '#39a3f4',
                        circleColor: '#39a3f4',
                        dataAtualPostagem: dataAbertura,
                        visivelATodos: 1,
                        mensagemSistema: aberturaViaChat === true ? 'Chat Iniciado' : ''
                      }).then(() => {
                        if (aberturaViaChat === true) {
                          let verificaPrimeiraExecucaoBLoquearModificacaoTela = false;
                          listaChamadoParaTelaAcoesChamadoLocal(dispatch, dadosChamado.key);
                          modificaKeyAtendimentoChat(dispatch, dadosChamado.key);
                          //indicando posicao da fila
                          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat")
                            .equalTo("AbertoNão informadotrue")
                            .on("value", snapshot => {
                              if (snapshot.val() == null) {
                                // console.log('snapshot null posicao fila')
                                modificaPosicaoAtualFila(dispatch, 1)
                                if (verificaPrimeiraExecucaoBLoquearModificacaoTela == false) {
                                  modificaTelaAtualChat(dispatch, 'aguardandoAtendimento');
                                }

                                verificaPrimeiraExecucaoBLoquearModificacaoTela = true;
                              } else {
                                // changing to reverse chronological order (latest first)
                                let arrayOfKeys = Object.keys(snapshot.val());
                                let posicaoFila = Object.keys(snapshot.val()).indexOf(dadosChamado.key);
                                //console.log(arrayOfKeys);
                                posicaoFila = posicaoFila + 1;
                                //console.log(posicaoFila)
                                // transforming to array
                                modificaPosicaoAtualFila(dispatch, posicaoFila)
                                if (verificaPrimeiraExecucaoBLoquearModificacaoTela == false) {
                                  modificaTelaAtualChat(dispatch, 'aguardandoAtendimento');
                                }
                                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                // console.log(results);
                                verificaPrimeiraExecucaoBLoquearModificacaoTela = true;
                              }
                            })
                        }

                        if (arquivosParaSerUpadosNaAberturaChamado.length != []) {
                          // console.log(`tem arquivos`);
                          let aux = 0;
                          for (let i = 0; i < arquivosParaSerUpadosNaAberturaChamado.length; i++) {
                            //  let uri = arquivosParaSerUpadosNaAberturaChamado[i].uri.replace("file://", ""); //remove o file:// que tem no ios
                            let nomeArquivoParaSerUpado = arquivosParaSerUpadosNaAberturaChamado[i].name == undefined ? arquivosParaSerUpadosNaAberturaChamado[i].fileName : arquivosParaSerUpadosNaAberturaChamado[i].name;
                            let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${dadosChamado.key}/${nomeArquivoParaSerUpado}`);
                            let mime = arquivosParaSerUpadosNaAberturaChamado[i].type;
                            // console.log(mime);
                            /*   RNFetchBlob.fs
                                .readFile(uri, "base64")
                                .then(data => {
                                  console.log("dentro do ready file");
                                  console.log(data);
                                  return RNFetchBlob.polyfill.Blob.build(
                                    data,
                                    {
                                      type: mime + ";BASE64"
                                    }
                                  );
                                })
                                .catch(err => {
                                  console.log(err);
                                }) 
                                .then(blob => {*/
                            //  console.log("depois do ready file");
                            caminhoPadraoArquivo1.put(arquivosParaSerUpadosNaAberturaChamado[i]).then(() => {
                              //  console.log("upou");
                              aux++;
                              /*   blob.close(); */
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/imagensVinculadas/`)
                                .push({ nomeArquivo: nomeArquivoParaSerUpado });
                              //aqui eu faço a verificação para saber quando o ultimo arquivo foi upado
                              if (aux == arquivosParaSerUpadosNaAberturaChamado.length) {/* 
                                          if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {
                                            console.log('atendente ve apenas proprio chamado, verifico se o usuario logado é o mesmo do atendente')
                                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                         //    enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                            }
                                          } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {
                                            console.log('atendente ve apenas proprio chamado e de setores a ele vinculado, porem nao tenho setor vinculado mas o chamado tem um atendente, verifico se é o atendente logado, se for eu mostro')
                                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                           //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                            }
                                          } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {
                                            console.log('chamado normal envio agora!');
                                          //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                          } */
                                if (aberturaViaChat === true) {
                                  zeraCadastroChamado(dispatch);
                                } else {
                                  toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                                  zeraCadastroChamado(dispatch);
                                }

                              }
                            })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/network-request-failed":
                                    toastr.error('Error', 'Sem conexão com a INTERNET!');
                                    break;
                                  default:
                                    toastr.error('Error', error.message);
                                    break;
                                }
                              });
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               console.log(error);
                               switch (error.code) {
                                 case "auth/network-request-failed":
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                                 default:
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                               }
                             }); */
                          }
                        } else {
                          // console.log('sem arquivo')
                          //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores))
                          if (aberturaViaChat === true) {
                            zeraCadastroChamado(dispatch);
                          } else {
                            toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                            zeraCadastroChamado(dispatch);
                          }
                        }

                      })
                        .catch(error => {
                          //error.code
                          //error.message
                          switch (error.code) {
                            case "auth/network-request-failed":
                              toastr.error('Error', 'Sem conexão com a INTERNET!');
                              break;
                            default:
                              toastr.error('Error', error.message);
                              break;
                          }
                        });
                    })
                }
              )
            }
            else {
              let numeroAtual;
              let keyNumeracao;
              snapshot.forEach(childItem => {
                numeroAtual = childItem.val().numeroAtual;
                keyNumeracao = childItem.key
              });
              let proximaNumeracao = numeroAtual + 1;
              firebase.database().ref(`${chaveUnica}/proximaNumeracao/${keyNumeracao}`).update({ numeroAtual: proximaNumeracao }).then(
                () => {
                  // console.log('depois do metodo');
                  // console.log(proximaNumeracao)
                  firebase
                    .database()
                    .ref(`${chaveUnica}/chamados/`)
                    .push({
                      cliente: keyDeQuemAbriuChamado,
                      atendente: atendenteAtendimento,
                      setor: setorAtendimento,
                      prioridade: prioridadeAtendimento,
                      local: localAtendimento,
                      descricaoProblema: (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento,
                      descricaoProblemaEstatico: (criarChamadoProblemaEstatico == true) ? 1 : 0,
                      keyProblemaEstatico: problemaEstaticoSelecionado,
                      dataAbertura: dataAbertura,
                      dataHoraAbertura: dataHoraAtual,
                      status: "Aberto",
                      alterado: 0,
                      abertoPor: keyDeQuemAbriuChamado,
                      numeroChamado: proximaNumeracao,
                      chamadoAbertoPorAdminOuCliente: chamadoAbertoPor,
                      dataVencimento: dataVencimento,
                      status_atendente: "AbertoNão informado",
                      tempoGasto: 0,
                      clienteVinculado: 1,
                      dataAberturaTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      setorVinculado: (setorAtendimento == 'Não informado') ? 0 : 1,
                      keyEmpresaVinculada: arrayNomeQuemAbriuChamado[0].keyEmpresaVinculada,
                      status_cliente: `Aberto${keyDeQuemAbriuChamado}`,
                      status_setor: `Aberto${setorAtendimento}`, //filtro setor comum
                      status_numeroChamado: `Aberto_${proximaNumeracao}`, // filtro local comum
                      channelChamado: aberturaViaChat === true ? 'clienteChatWeb' : 'clienteWEB',
                      abertoPorChat: aberturaViaChat === true ? `${keyDeQuemAbriuChamado}Chat` : 'false',
                      status_atendente_abertoviachat: aberturaViaChat === true ? "AbertoNão informadotrue" : '',

                      lastMessageChat: descricaoAtendimento,
                      lastMessageChatTimeStamp: firebase.database.ServerValue.TIMESTAMP,
                      lastmessageChatCount: 1,
                      lastmessageChatType: "mensagem",
                      lastmessageChatStatus: 'sent',
                      lastMessageLineColor: '#39a3f4'
                    })
                    .then(dadosChamado => {
                      // console.log(dadosChamado.key);
                      if (setorAtendimento !== "Não informado") {
                        //  console.log('setor diferente de naão informado');
                        firebase.database().ref(`${chaveUnica}/setores/${setorAtendimento}/atendentesVinculados`).once('value').then((snapshotSetor) => {
                          dadosAtendVinc = snapshotSetor.val();
                          console.log(dadosAtendVinc)
                          let keyAtendentesVinculados = Object.keys(snapshotSetor.val());
                          console.log(keyAtendentesVinculados)
                          for (let i = 0; i < keyAtendentesVinculados.length; i++) {
                            if (keyAtendentesVinculados[i].includes('codigoInterno')) {
                              dadosAtendVinc[keyAtendentesVinculados[i] + "status"] = keyAtendentesVinculados[i] + "Aberto";
                            }
                          }
                          console.log(dadosAtendVinc)
                          //  console.log(snapshotSetor.val());
                          if (snapshotSetor.val()) {
                            //  console.log(dadosAtendVinc)

                            //  console.log(dadosAtendVinc)
                            const dadosAtendentesVinculadosSetor = [];
                            snapshotSetor.forEach((childItem) => {
                              dadosAtendentesVinculadosSetor.push({
                                key: childItem.key,

                              });
                              if (aberturaViaChat == true) {
                                if (childItem.key !== undefined) {
                                  if (childItem.key.includes('codigo')) {
                                    dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`;
                                    dadosAtendVinc[`${childItem.key}Chatstatus`] = `${childItem.key}ChatAberto`;
                                  } else {
                                    dadosAtendVinc[`${childItem.key}Chat`] = true;
                                  }
                                }
                                /*   dadosAtendVinc[`${childItem.key}Chat`] = true;
                                  dadosAtendVinc[`${childItem.key}Chat`] = `${childItem.key}Chat`; */
                              }

                            })
                            //  console.log(dadosAtendVinc)
                            firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend`).update({ dadosAtendVinc })
                          } else {
                            // console.log('setor naão tem o campo atendentevinculado criado, mostro pra todos os atendentes');
                            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                              //  console.log(snapshot.val())
                              const idTodosAtendentes = [];
                              let objetocadastro = {};
                              snapshot.forEach((childItem) => {
                                idTodosAtendentes.push({
                                  key: childItem.key,
                                  codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                                });
                              })
                              // console.log(idTodosAtendentes)
                              for (let i = 0; i < idTodosAtendentes.length; i++) {
                                // objetocadastro[idTodosAtendentes[i].key] = true;
                                objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                                objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                                if (aberturaViaChat == true) {
                                  objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                                  objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                                }

                              }
                              // console.log(objetocadastro)
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                              //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                            })

                          }
                        })
                      } else {
                        //verifica se o atendente é dierente de naão informado, porque se for, preciso criar o campo atendentes vinculados pra esse atendente visualizar este atendimento
                        // console.log('SETOR NAO INFORMADOOOOO,MOSTRO PRA TODOS ATENDENTES')
                        firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild('excluido').equalTo(null).once('value').then((snapshot) => {
                          //  console.log(snapshot.val())
                          const idTodosAtendentes = [];
                          let objetocadastro = {};
                          snapshot.forEach((childItem) => {
                            idTodosAtendentes.push({
                              key: childItem.key,
                              codigoInterno: childItem.val().codigoInterno == undefined ? 'codigoInterno1' : childItem.val().codigoInterno,
                            });
                          })
                          //  console.log(idTodosAtendentes)
                          for (let i = 0; i < idTodosAtendentes.length; i++) {
                            //objetocadastro[idTodosAtendentes[i].key] = true;
                            objetocadastro[idTodosAtendentes[i].codigoInterno] = idTodosAtendentes[i].codigoInterno;
                            objetocadastro[idTodosAtendentes[i].codigoInterno + "status"] = idTodosAtendentes[i].codigoInterno + "Aberto";
                            if (aberturaViaChat == true) {
                              objetocadastro[`${idTodosAtendentes[i].key}Chat`] = true;
                              objetocadastro[`${idTodosAtendentes[i].codigoInterno}Chat`] = `${idTodosAtendentes[i].codigoInterno}Chat`;
                            }
                          }
                          // console.log(objetocadastro)
                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/dadosAtendVinc`).update(objetocadastro)
                          //enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                        })
                        /* let objetocadastro = {}
                        if (atendenteAtendimento != "Não informado") {
                          console.log('atendente informadoooooo')
                          console.log(atendenteAtendimento)
                          objetocadastro[atendenteAtendimento] = true;
                          console.log(objetocadastro)
                          let dadosAtendVinc = objetocadastro;
                          firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/usuariosVisualizaAtend/`).update({dadosAtendVinc})
                        } */
                      }

                      firebase.database().ref(`${chaveUnica}/acoesChamados/${dadosChamado.key}/`).push({
                        tipo: 'mensagem',
                        titulo: 'Atendimento criado por: ' + arrayNomeQuemAbriuChamado[0].nomeDeQuemAbriuChamado,
                        criadoPor: keyDeQuemAbriuChamado,
                        mensagem: aberturaViaChat === true ? (criarChamadoProblemaEstatico == true) ? nomeProblemaSelecionado : descricaoAtendimento : dataHoraAtual,
                        dataHoraAtual: dataHoraAtual,
                        time: timeAtual,
                        lineColor: '#39a3f4',
                        circleColor: '#39a3f4',
                        visivelATodos: 1,
                        mensagemSistema: aberturaViaChat === true ? 'Chat Iniciado' : ''
                      }).then((keyAtendimento) => {

                        if (aberturaViaChat === true) {
                          let verificaPrimeiraExecucaoBLoquearModificacaoTela = false;
                          listaChamadoParaTelaAcoesChamadoLocal(dispatch, dadosChamado.key);
                          modificaKeyAtendimentoChat(dispatch, dadosChamado.key);
                          //indicando posicao da fila
                          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat")
                            .equalTo("AbertoNão informadotrue")
                            .on("value", snapshot => {
                              if (snapshot.val() == null) {
                                // console.log('snapshot null posicao fila')
                                modificaPosicaoAtualFila(dispatch, 1)
                                if (verificaPrimeiraExecucaoBLoquearModificacaoTela == false) {
                                  modificaTelaAtualChat(dispatch, 'aguardandoAtendimento');
                                }

                                verificaPrimeiraExecucaoBLoquearModificacaoTela = true;
                              } else {
                                // changing to reverse chronological order (latest first)
                                let arrayOfKeys = Object.keys(snapshot.val());
                                let posicaoFila = Object.keys(snapshot.val()).indexOf(dadosChamado.key);
                                //  console.log(arrayOfKeys);
                                posicaoFila = posicaoFila + 1;
                                //  console.log(posicaoFila)
                                // transforming to array
                                modificaPosicaoAtualFila(dispatch, posicaoFila)
                                if (verificaPrimeiraExecucaoBLoquearModificacaoTela == false) {
                                  modificaTelaAtualChat(dispatch, 'aguardandoAtendimento');
                                }
                                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                // console.log(results);
                                verificaPrimeiraExecucaoBLoquearModificacaoTela = true;
                              }
                            })
                        }
                        //tem arquivos, então eu upo
                        if (arquivosParaSerUpadosNaAberturaChamado.length != []) {
                          //  console.log(`tem arquivos`);
                          let aux = 0;
                          for (let i = 0; i < arquivosParaSerUpadosNaAberturaChamado.length; i++) {
                            //  let uri = arquivosParaSerUpadosNaAberturaChamado[i].uri.replace("file://", ""); //remove o file:// que tem no ios
                            let nomeArquivoParaSerUpado = arquivosParaSerUpadosNaAberturaChamado[i].name == undefined ? arquivosParaSerUpadosNaAberturaChamado[i].fileName : arquivosParaSerUpadosNaAberturaChamado[i].name;
                            let caminhoPadraoArquivo1 = firebase.storage().ref(`${chaveUnica}/${dadosChamado.key}/${nomeArquivoParaSerUpado}`);
                            let mime = arquivosParaSerUpadosNaAberturaChamado[i].type;
                            //  console.log(mime);
                            /*   RNFetchBlob.fs
                                .readFile(uri, "base64")
                                .then(data => {
                                  console.log("dentro do ready file");
                                  console.log(data);
                                  return RNFetchBlob.polyfill.Blob.build(
                                    data,
                                    {
                                      type: mime + ";BASE64"
                                    }
                                  );
                                })
                                .catch(err => {
                                  console.log(err);
                                }) 
                                .then(blob => {*/
                            //  console.log("depois do ready file");
                            caminhoPadraoArquivo1.put(arquivosParaSerUpadosNaAberturaChamado[i]).then(() => {
                              //   console.log("upou");
                              aux++;
                              /*   blob.close(); */
                              firebase.database().ref(`${chaveUnica}/chamados/${dadosChamado.key}/imagensVinculadas/`)
                                .push({ nomeArquivo: nomeArquivoParaSerUpado });
                              //aqui eu faço a verificação para saber quando o ultimo arquivo foi upado
                              if (aux == arquivosParaSerUpadosNaAberturaChamado.length) {/* 
                                          if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == false && contaPrincipal == false && setorAtendimento == "Não informado") {
                                            console.log('atendente ve apenas proprio chamado, verifico se o usuario logado é o mesmo do atendente')
                                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                         //    enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                            }
                                          } else if (atendenteVeApenasProprioChamado == true && agruparAtendenteSetor == true && contaPrincipal == false && setorAtendimento == "Não informado") {
                                            console.log('atendente ve apenas proprio chamado e de setores a ele vinculado, porem nao tenho setor vinculado mas o chamado tem um atendente, verifico se é o atendente logado, se for eu mostro')
                                            if (atendenteAtendimento == keyDeQuemAbriuChamado) {
                                           //   enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                            }
                                          } else if (atendenteVeApenasProprioChamado == false && agruparAtendenteSetor == false) {
                                            console.log('chamado normal envio agora!');
                                          //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores));
                                          } */

                                if (aberturaViaChat === true) {
                                  zeraCadastroChamado(dispatch);
                                } else {
                                  toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                                  zeraCadastroChamado(dispatch);
                                }
                              }
                            })
                              .catch(error => {
                                //error.code
                                //error.message
                                switch (error.code) {
                                  case "auth/network-request-failed":
                                    toastr.error('Error', 'Sem conexão com a INTERNET!');
                                    break;
                                  default:
                                    toastr.error('Error', error.message);
                                    break;
                                }
                              });
                            /*  })
                             .catch(error => {
                               //error.code
                               //error.message
                               console.log(error);
                               switch (error.code) {
                                 case "auth/network-request-failed":
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                                 default:
                                   dispatch({
                                     type: SEMCONEXAOINTERNETCADASTRACHAMADO,
                                     payload:
                                       "semConexaoInternetCadastraChamado"
                                   });
                                   break;
                               }
                             }); */
                          }
                        } else {
                          // console.log('sem arquivo')
                          //  enviaChamadoAberto(dispatch, chamado.concat(chamadosAnteriores))
                          if (aberturaViaChat === true) {
                            zeraCadastroChamado(dispatch);
                          } else {
                            toastr.success('Sucesso', 'Atendimento criado com sucesso!');
                            zeraCadastroChamado(dispatch);
                          }
                        }
                      })
                    })
                    .catch(error => {
                      //error.code
                      //error.message
                      switch (error.code) {
                        case "auth/network-request-failed":
                          toastr.error('Error', 'Sem conexão com a INTERNET!');
                          break;
                        default:
                          toastr.error('Error', error.message);
                          break;
                      }
                    });
                }
              )
            }
          })
        })
      });
    }

  };
};




//chamados todos novo filtro


export const listaTodosOsChamados = (
  valor,
  referenciaUltimaKey,
  destino,
  chamadosAnteriores,
  ultimakeyTodos,
  atendenteVeApenasProprioChamado,
  contaPrincipal
) => {
  return dispatch => {
    /*  console.log(`antes do check todos chamados`); */
    let referenceToOldestKey;
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      if (atendenteVeApenasProprioChamado == true && contaPrincipal == false) {
        //aqui o atendente vê somente os proprios chamados
        const { currentUser } = firebase.auth();
        firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(currentUser.uid).once("value").then((snapshot) => {
          let key = Object.keys(snapshot.val()).toString();
          const filtro = key;
          if (valor == 0) {
            // if initial fetch
            ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByChild('atendente').equalTo(filtro)
              .limitToLast(7)
              .on("value", snapshot => {

                if (snapshot.val() == null) {
                  enviaChamadoTodos(dispatch, []);
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                } else {
                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse();
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  let arrayChamado = [];
                  let promisseCliente;
                  let promisseAtendente;
                  let promisseSetor;
                  for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                  }
                  let aux = 0;
                  Promise.all(arrayChamado).then(responses => {
                    for (let i = 0; i < responses.length; i += 3) {
                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        //  cliente:responses[i + 1].val() == null? "Não informado": responses[i + 1].val(),
                        atendente:
                          responses[i].val() == null
                            ? "Não informado"
                            : responses[i].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        dataHoraCancelamento: results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                      aux++;
                    }
                    ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                    enviaUltimaReferenciaKeyTodos(
                      dispatch,
                      referenceToOldestKey
                    );
                    enviaChamadoTodos(dispatch, chamados);
                    ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, true);
                  });
                }
              })

            /*   .catch(error => {
                console.log(error);
              }); */
          } else {
            if (ultimakeyTodos != undefined && ultimakeyTodos != "") {
              /*  ativaDesativaLoadingFinalFlatListChamadoTodos(
                 dispatch,
                 true
               ); */
              firebase
                .database()
                .ref(`${chaveUnica}/chamados/`)
                .orderByChild('atendente')
                .startAt(filtro)
                .endAt(filtro, ultimakeyTodos)
                .limitToLast(8)
                .on("value", snapshot => {
                  if (snapshot.val() == null) {
                    ativaDesativaLoadingFinalFlatListChamadoTodos(
                      dispatch,
                      false
                    );
                  } else {

                    // changing to reverse chronological order (latest first)
                    let arrayOfKeys = Object.keys(snapshot.val())
                      .sort()
                      .reverse()
                      .slice(1);
                    // transforming to array
                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                    // storing reference
                    referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                    let chamados = [];
                    let arrayChamado = [];
                    let promisseCliente;
                    let promisseAtendente;
                    let promisseSetor;
                    for (let i = 0; i < results.length; i++) {
                      let cliente = results[i].cliente;
                      let atendente = results[i].atendente;
                      let setor = results[i].setor;
                      promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                      promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                      promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                      arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                    }
                    let aux = 0;
                    Promise.all(arrayChamado).then(responses => {
                      for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                          key: arrayOfKeys[aux],
                          cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                          empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                          setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                          urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                          //  cliente:responses[i + 1].val() == null? "Não informado": responses[i + 1].val(),
                          atendente:
                            responses[i].val() == null
                              ? "Não informado"
                              : responses[i].val(),
                          dataHoraAbertura: results[aux].dataHoraAbertura,
                          status: results[aux].status,
                          prioridade:
                            results[aux].prioridade === ""
                              ? "Não informado"
                              : results[aux].prioridade,
                          descricaoProblema: results[aux].descricaoProblema,
                          alterado: results[aux].alterado,
                          chaveUnica: chaveUnica,
                          keyCliente: results[aux].cliente,
                          numeroChamado: results[aux].numeroChamado,
                          dataHoraFechamento: results[aux].dataHoraFechamento,
                          dataHoraCancelamento:
                            results[aux].dataHoraCancelamento,
                          channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                          lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                          lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                        aux++;
                      }
                      ativaDesativaLoadingFinalFlatListChamadoTodos(
                        dispatch,
                        false
                      );
                      enviaUltimaReferenciaKeyTodos(
                        dispatch,
                        referenceToOldestKey
                      );
                      enviaChamadoTodos(
                        dispatch,
                        chamadosAnteriores.concat(chamados)
                      );
                    });
                  }
                })

              /*  .catch(error => {
                 console.log(error);
               }); */
            } else {
              ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
            }
          }
        })
      } else {
        if (valor == 0) {
          ativaDesativaLoadingTelaChamadoTodos(dispatch, true);
          // if initial fetch
          firebase
            .database()
            .ref(`${chaveUnica}/chamados/`)
            .orderByKey()
            .limitToLast(7)
            .on("value", snapshot => {

              if (snapshot.val() == null) {
                enviaChamadoTodos(dispatch, []);
                ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
              } else {
                // changing to reverse chronological order (latest first)
                let arrayOfKeys = Object.keys(snapshot.val())
                  .sort()
                  .reverse();
                // transforming to array
                let results = arrayOfKeys.map(key => snapshot.val()[key]);
                // storing reference
                referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                let chamados = [];
                let arrayChamado = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                for (let i = 0; i < results.length; i++) {
                  let cliente = results[i].cliente;
                  let atendente = results[i].atendente;
                  let setor = results[i].setor;
                  promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                  promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                  promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                  arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                Promise.all(arrayChamado).then(responses => {
                  for (let i = 0; i < responses.length; i += 3) {
                    chamados.push({
                      key: arrayOfKeys[aux],
                      cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                      empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                      setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                      urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                      //  cliente:responses[i + 1].val() == null? "Não informado": responses[i + 1].val(),
                      atendente:
                        responses[i].val() == null
                          ? "Não informado"
                          : responses[i].val(),
                      dataHoraAbertura: results[aux].dataHoraAbertura,
                      status: results[aux].status,
                      prioridade:
                        results[aux].prioridade === ""
                          ? "Não informado"
                          : results[aux].prioridade,
                      descricaoProblema: results[aux].descricaoProblema,
                      alterado: results[aux].alterado,
                      chaveUnica: chaveUnica,
                      keyCliente: results[aux].cliente,
                      numeroChamado: results[aux].numeroChamado,
                      dataHoraFechamento: results[aux].dataHoraFechamento,
                      dataHoraCancelamento: results[aux].dataHoraCancelamento,
                      channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                      lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                      lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                    });
                    aux++;
                  }
                  ativaDesativaLoadingTelaChamadoTodos(dispatch, false);
                  enviaUltimaReferenciaKeyTodos(
                    dispatch,
                    referenceToOldestKey
                  );
                  enviaChamadoTodos(dispatch, chamados);
                  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, true);
                });
              }
            })

          /*    .catch(error => {
               console.log(error);
             }); */
        } else {
          if (ultimakeyTodos != undefined && ultimakeyTodos != "") {
            //  ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch,true);
            firebase
              .database()
              .ref(`${chaveUnica}/chamados/`)
              .orderByKey()
              .endAt(ultimakeyTodos)
              .limitToLast(8)
              .on("value", snapshot => {
                if (snapshot.val() == null) {
                  ativaDesativaLoadingFinalFlatListChamadoTodos(
                    dispatch,
                    false
                  );
                } else {

                  // changing to reverse chronological order (latest first)
                  let arrayOfKeys = Object.keys(snapshot.val())
                    .sort()
                    .reverse()
                    .slice(1);
                  // transforming to array
                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                  // storing reference
                  referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];

                  let chamados = [];
                  let arrayChamado = [];
                  let promisseCliente;
                  let promisseAtendente;
                  let promisseSetor;
                  for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamado.push(promisseAtendente, promisseCliente, promisseSetor);
                  }
                  let aux = 0;
                  Promise.all(arrayChamado).then(responses => {
                    for (let i = 0; i < responses.length; i += 3) {
                      chamados.push({
                        key: arrayOfKeys[aux],
                        cliente: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().nome,
                        setor: responses[i + 2].val() == null ? "Não informado" : responses[i + 2].val(),
                        empresa: responses[i + 1].val() == null ? "Não informado" : responses[i + 1].val().empresa,
                        urlImagemNuvem: responses[i + 1].val() == null ? undefined : responses[i + 1].val().urlImagemNuvem,
                        //  cliente:responses[i + 1].val() == null? "Não informado": responses[i + 1].val(),
                        atendente:
                          responses[i].val() == null
                            ? "Não informado"
                            : responses[i].val(),
                        dataHoraAbertura: results[aux].dataHoraAbertura,
                        status: results[aux].status,
                        prioridade:
                          results[aux].prioridade === ""
                            ? "Não informado"
                            : results[aux].prioridade,
                        descricaoProblema: results[aux].descricaoProblema,
                        alterado: results[aux].alterado,
                        chaveUnica: chaveUnica,
                        keyCliente: results[aux].cliente,
                        numeroChamado: results[aux].numeroChamado,
                        dataHoraFechamento: results[aux].dataHoraFechamento,
                        dataHoraCancelamento:
                          results[aux].dataHoraCancelamento,
                        channelChamado: (results[aux].channelChamado === undefined) ? 'Não informado' : results[aux].channelChamado,
                        lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                        lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                      });
                      aux++;
                    }
                    /*   ativaDesativaLoadingFinalFlatListChamadoTodos(
                        dispatch,
                        false
                      ); */
                    enviaUltimaReferenciaKeyTodos(
                      dispatch,
                      referenceToOldestKey
                    );
                    enviaChamadoTodos(
                      dispatch,
                      chamadosAnteriores.concat(chamados)
                    );
                  });
                }
              })

            /*  .catch(error => {
               console.log(error);
             }); */
          } else {
            ativaDesativaLoadingFinalFlatListChamadoTodos(dispatch, false);
          }
        }
      }
    });

  };
};


export const listaSetorTelaNovoChamado = () => {
  return dispatch => {/* 
    ativaLoadingCarregarSetorTelaNovoChamado(dispatch);

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase
        .database()
        .ref(`${chaveUnica}/setores`)
        .orderByChild("nome")
        .on("value", snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaSetorParaReducer(dispatch, arrayUser);
          } else {
            const children2 = [];
            snapshot.forEach(childItem => {
              if (childItem.val().excluido == 1) {
              } else {
                children2.push({
                  value: childItem.key,
                  name: childItem.val().nome
                });
              }
            });
            const items = [{
              name: 'SETORES',
              type: 'group', items: children2
            }];
            enviaSetorParaReducer(dispatch, items);
          }
        });
    }); */
  };
};
export const listaProblemasEstaticosTelaNovoChamado = () => {
  return dispatch => {
    ativaDesativaLoadingCarregaProblemasEstaticos(dispatch, true);

    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase
        .database()
        .ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).on("value", snapshot => {
          if (snapshot.val() == null) {
            const arrayUser = [];
            enviaProblemasEstaticosParaReducer(dispatch, arrayUser);
            ativaDesativaLoadingCarregaProblemasEstaticos(dispatch, false);
          } else {
            const children2 = [];
            snapshot.forEach(childItem => {

              children2.push({
                value: childItem.key,
                name: childItem.val().descricaoProblema
              });

            });
            const items = [{
              name: 'PROBLEMAS',
              type: 'group', items: children2
            }];
            //  const items = [{ children: children2 }];
            enviaProblemasEstaticosParaReducer(dispatch, items);
            ativaDesativaLoadingCarregaProblemasEstaticos(dispatch, false);
          }
        });
    });
  };
};

export const listaChamadoParaTelaAcoesChamado = (keyAtendimento) => {
  /* console.log(keyAtendimento) */
  return dispatch => {

    ativaDesativaLoadingTelaAcoesAtendimento(dispatch, true)
    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");
      dispatch(listaAcoesChamadoConversasAcoesChamado(0, chaveUnica, keyAtendimento, '', []));
      /*  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).on("value", snapshot2 => {
         let arrayAcoesAtendimento = [];
         snapshot2.forEach(childItem => {
           if (childItem.val().visivelATodos == 1) {
             if (childItem.val().tipo !== undefined) {
               arrayAcoesAtendimento.push({
                 key: childItem.key,
                 title: childItem.val().titulo,
                 tipo: childItem.val().tipo,
                 description: childItem.val().mensagem,
                 time: childItem.val().time,
                 lineColor: childItem.val().lineColor,
                 dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                 circleColor: childItem.val().circleColor,
                 urlDownload: childItem.val().urlDownload,
                 tipoArquivo: childItem.val().tipoArquivo,
                 duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                 audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                 statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                 idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                 circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                 commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                 idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                 mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                 possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                 commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                 commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                 atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
               })
             }
           }
         })
         enviaAcoesAtendimentoParaReducer(dispatch, arrayAcoesAtendimento.reverse());
       }) */
      firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('key').equalTo(keyAtendimento).limitToLast(1).on("value", snapshot => {
        if (snapshot.val()) {
          /* console.log(snapshot.val());
          console.log('---------------AVALIACAO FEITA----------------------') */
          enviaSeFezAvaliacaoNoChamado(dispatch, 1);
        } else {
          enviaSeFezAvaliacaoNoChamado(dispatch, 0);
          /*    console.log('---------------NAO FEZ AVALIACAO----------------------') */
        }
      })
      firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once("value").then((snapshot) => {
        if (snapshot.val()) {

          /*  console.log('tem dados') */
          const dadosEmpresa = [];
          snapshot.forEach(childItem => {
            dadosEmpresa.push({
              key: childItem.key,
              mensagemPadrao: childItem.val().mensagemPadrao,
              utilizaAvaliacao: childItem.val().utilizaAvaliacao
            })
          })
          /*  console.log(snapshot.val())
           console.log(dadosEmpresa[0].mensagemPadrao);
           console.log(dadosEmpresa[0].utilizaAvaliacao); */
          //envia se tem ou nao permissao pra avaliar
          if (dadosEmpresa[0].utilizaAvaliacao) {
            enviaSetemAutorizacaoParaAvaliarChamado(dispatch, true);
          } else {
            enviaSetemAutorizacaoParaAvaliarChamado(dispatch, false);
          }

        } else {
          /*  console.log('não tem dados') */
          //envia como nao tem permissao
          enviaSetemAutorizacaoParaAvaliarChamado(dispatch, false);
        }

      })
      firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).on("value", snapshot => {

        /*    console.log('snapshot')
           console.log(snapshot.val()); */

        let keyAtendimento = Object.keys(snapshot.val())
        /*  console.log(keyAtendimento) */
        let arrayPromisses = [];
        /*   console.log(snapshot.val()[keyAtendimento].cliente)
          console.log(snapshot.val()[keyAtendimento].atendente)
          console.log(snapshot.val()[keyAtendimento].setor)
          console.log(snapshot.val()[keyAtendimento].status) */
        let promisseAbertoPor;
        if (snapshot.val()[keyAtendimento].chamadoAbertoPorAdminOuCliente == 'cliente') {

          promisseAbertoPor = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
        } else {
          promisseAbertoPor = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
        }

        let promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].cliente}/nome`).once('value')
        let promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].atendente}/nome`).once('value')
        let promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${snapshot.val()[keyAtendimento].setor}/nome`).once('value')

        arrayPromisses.push(promisseAtendente, promisseCliente, promisseSetor, promisseAbertoPor);

        const dadosAtendimento = [];
        Promise.all(arrayPromisses).then(responses => {
          snapshot.forEach(childItem => {
            /* console.log('dentro do snap')
            console.log(childItem.val().status) */
            dadosAtendimento.push({
              key: childItem.key,
              abertoPor: (responses[3].val() == null) ? 'Não informado' : responses[3].val(),
              alterado: childItem.val().alterado,
              keyAtendente: childItem.val().atendente,
              atendente: (responses[0].val() == null) ? 'Não informado' : responses[0].val(),
              chamadoAbertoPorAdminOuCliente: childItem.val().chamadoAbertoPorAdminOuCliente,
              keyCliente: childItem.val().cliente,
              cliente: (responses[1].val() == null) ? 'Não informado' : responses[1].val(),
              dataAbertura: childItem.val().dataAbertura,
              dataHoraAbertura: childItem.val().dataHoraAbertura,
              dataHoraFechamento: (childItem.val().dataHoraFechamento == undefined) ? '' : childItem.val().dataHoraFechamento,
              dataHoraCancelamento: (childItem.val().dataHoraCancelamento == undefined) ? '' : childItem.val().dataHoraCancelamento,
              dataVencimento: childItem.val().dataVencimento,
              descricaoProblema: childItem.val().descricaoProblema,
              local: childItem.val().local,
              numeroChamado: childItem.val().numeroChamado,
              prioridade: childItem.val().prioridade,
              keySetor: childItem.val().setor,
              descricaoProblemaEstatico: (childItem.val().descricaoProblemaEstatico == undefined) ? 0 : childItem.val().descricaoProblemaEstatico,
              keyProblemaEstatico: (childItem.val().keyProblemaEstatico == undefined) ? [] : [childItem.val().keyProblemaEstatico],
              setor: (responses[2].val() == null) ? 'Não informado' : responses[2].val(),
              status: childItem.val().status,
              chaveUnica: chaveUnica,
              tempoGasto: childItem.val().tempoGasto,
              periodoResolucaoChamado: childItem.val().periodoResolucaoChamado == undefined ? "" : childItem.val().periodoResolucaoChamado,
              solucaoAtendimento: childItem.val().solucaoAtendimento == undefined ? "" : childItem.val().solucaoAtendimento,
              lastmessageChatCount: childItem.val().lastmessageChatCount == undefined ? 0 : childItem.val().lastmessageChatCount,
              lastmessageChatType: childItem.val().lastmessageChatType == undefined ? 'mensagem' : childItem.val().lastmessageChatType,
            });

          });
          /* console.log(dadosAtendimento)
          console.log('antes do envio'); */
          /* f (this.props.criarChamadoProblemaEstatico == true && this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 1) { */
          dispatch({ type: MODIFICAPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].keyProblemaEstatico })
          dispatch({ type: MODIFICANOMEPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].descricaoProblema })

          /* }  */

          enviaAtendimentoParaReducer(dispatch, dadosAtendimento);
          /*  console.log('depois do envio') */
          ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false)
        })



      })


      firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}/imagensVinculadas`).once('value').then((snapshot) => {
        //tem arquivos vinculados
        let nomesArquivosAnexados = [];
        let arrayArquivosUpados = [];
        if (snapshot.val()) {
          //ativo loading
          /*      console.log('tem arquivos') */

          snapshot.forEach(childItem => {
            nomesArquivosAnexados.push({
              key: childItem.key,
              nomeArquivo: childItem.val().nomeArquivo,
            })
          })
          /*  console.log(nomesArquivosAnexados); */
          let aux = 0;
          for (let i = 0; i < nomesArquivosAnexados.length; i++) {
            firebase.storage().ref().child(`${chaveUnica}/${keyAtendimento}/${nomesArquivosAnexados[i].nomeArquivo}`).getDownloadURL().then((url) => {
              firebase.storage().ref().child(`${chaveUnica}/${keyAtendimento}/${nomesArquivosAnexados[i].nomeArquivo}`).getMetadata().then((metadata) => {

                arrayArquivosUpados.push({
                  url: url,
                  nome: metadata.name,
                  tipo: metadata.contentType
                })
                aux++;
                if (aux == nomesArquivosAnexados.length) {
                  /* console.log(arrayArquivosUpados)
                  console.log('fim') */
                  enviaArquivosUpadosAnexadoAoChamado(dispatch, arrayArquivosUpados)
                  ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado(dispatch, false)
                }

              })
            })
          }
        }

        //nao tem arquivo
        else {
          //desativo loading
          /* console.log('nao tem arquivos') */
          enviaArquivosUpadosAnexadoAoChamado(dispatch, arrayArquivosUpados)
          ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado(dispatch, false)
        }
      })
    })

  }
}
const listaChamadoParaTelaAcoesChamadoLocal = (dispatch, keyAtendimento) => {
  /*   console.log(keyAtendimento)
    console.log('lista acao local'); */
  const sound = new Howl({
    src: [soundnotification],
    volume: 1,
    // autoplay: true
  });
  AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
    const chaveUnica = chaveUnica2.replace(/"/g, "");
    let verificaSeJaFoiExecutadoPrimeirVez = false;
    dispatch(listaAcoesChamadoConversasChat(0, chaveUnica, keyAtendimento, '', []));
    /*  firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).on("value", snapshot2 => {
       let arrayAcoesAtendimento = [];
       let numChildrenAux = 0;
       snapshot2.forEach(childItem => {
         if (childItem.val().visivelATodos == 1) {
           if (childItem.val().tipo !== undefined) {
             arrayAcoesAtendimento.push({
               key: childItem.key,
               title: childItem.val().titulo,
               tipo: childItem.val().tipo,
               description: childItem.val().mensagem,
               time: childItem.val().time,
               lineColor: childItem.val().lineColor,
               dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
               circleColor: childItem.val().circleColor,
               urlDownload: childItem.val().urlDownload,
               tipoArquivo: childItem.val().tipoArquivo,
               mensagemSistema: childItem.val().mensagemSistema,
               duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
               audio: childItem.val().audio == undefined ? false : childItem.val().audio,
               statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
               idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
               circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
               commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
               idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
               mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
               possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
               commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
               commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
               atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
             })
           }
         }
         numChildrenAux++
         if (numChildrenAux == snapshot2.numChildren()) {
           if (childItem.val().circleColor == '#00FF7F') {
             let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimentocliente')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimentocliente'))
             if (verificaSeJaFoiExecutadoPrimeirVez == true && cfgAudio == true) {
               setTimeout(() => {
                 if (sound.playing() == false) {
                   sound.play();
                 }
               }, 500);
 
               dispatch({ type: MODIFICAATENDIMENTOONLINEVISIBLE, payload: true })
             }
 
           }
         }
       })
    
       enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesAtendimento.reverse());
       verificaSeJaFoiExecutadoPrimeirVez = true;
     }) */
    /*     firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('key').equalTo(keyAtendimento).on("value", snapshot => {
          if (snapshot.val()) {
            console.log(snapshot.val());
            console.log('---------------AVALIACAO FEITA----------------------')
            enviaSeFezAvaliacaoNoChamadoChat(dispatch, 1);
          } else {
            enviaSeFezAvaliacaoNoChamadoChat(dispatch, 0);
            console.log('---------------NAO FEZ AVALIACAO----------------------')
          }
        }) */
    /*  firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).on("value", snapshot => {
       if (snapshot.val()) {
 
         console.log('tem dados')
         const dadosEmpresa = [];
         snapshot.forEach(childItem => {
           dadosEmpresa.push({
             key: childItem.key,
             mensagemPadrao: childItem.val().mensagemPadrao,
             utilizaAvaliacao: childItem.val().utilizaAvaliacao
           })
         })
         console.log(snapshot.val())
         console.log(dadosEmpresa[0].mensagemPadrao);
         console.log(dadosEmpresa[0].utilizaAvaliacao);
         //envia se tem ou nao permissao pra avaliar
         if (dadosEmpresa[0].utilizaAvaliacao) {
           enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, true);
         } else {
           enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, false);
         }
 
       } else {
         console.log('não tem dados')
         //envia como nao tem permissao
         enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, false);
       }
 
     }) */
    firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).on("value", snapshot => {

      /* console.log('snapshot')
      console.log(snapshot.val()); */

      let keyAtendimento = Object.keys(snapshot.val())
      /* console.log(keyAtendimento) */
      let arrayPromisses = [];
      /*  console.log(snapshot.val()[keyAtendimento].cliente)
       console.log(snapshot.val()[keyAtendimento].atendente)
       console.log(snapshot.val()[keyAtendimento].setor)
       console.log(snapshot.val()[keyAtendimento].status) */
      let promisseAbertoPor;
      if (snapshot.val()[keyAtendimento].chamadoAbertoPorAdminOuCliente == 'cliente') {

        promisseAbertoPor = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
      } else {
        promisseAbertoPor = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
      }

      let promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].cliente}/nome`).once('value')
      let promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].atendente}/nome`).once('value')
      let promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${snapshot.val()[keyAtendimento].setor}/nome`).once('value')

      arrayPromisses.push(promisseAtendente, promisseCliente, promisseSetor, promisseAbertoPor);

      const dadosAtendimento = [];
      Promise.all(arrayPromisses).then(responses => {
        snapshot.forEach(childItem => {
          /*  console.log('dentro do snap')
           console.log(childItem.val().status) */
          dadosAtendimento.push({
            key: childItem.key,
            abertoPor: (responses[3].val() == null) ? 'Não informado' : responses[3].val(),
            alterado: childItem.val().alterado,
            keyAtendente: childItem.val().atendente,
            atendente: (responses[0].val() == null) ? 'Não informado' : responses[0].val(),
            chamadoAbertoPorAdminOuCliente: childItem.val().chamadoAbertoPorAdminOuCliente,
            keyCliente: childItem.val().cliente,
            cliente: (responses[1].val() == null) ? 'Não informado' : responses[1].val(),
            dataAbertura: childItem.val().dataAbertura,
            dataHoraAbertura: childItem.val().dataHoraAbertura,
            dataHoraFechamento: (childItem.val().dataHoraFechamento == undefined) ? '' : childItem.val().dataHoraFechamento,
            dataHoraCancelamento: (childItem.val().dataHoraCancelamento == undefined) ? '' : childItem.val().dataHoraCancelamento,
            dataVencimento: childItem.val().dataVencimento,
            descricaoProblema: childItem.val().descricaoProblema,
            local: childItem.val().local,
            numeroChamado: childItem.val().numeroChamado,
            prioridade: childItem.val().prioridade,
            keySetor: childItem.val().setor,
            descricaoProblemaEstatico: (childItem.val().descricaoProblemaEstatico == undefined) ? 0 : childItem.val().descricaoProblemaEstatico,
            keyProblemaEstatico: (childItem.val().keyProblemaEstatico == undefined) ? [] : [childItem.val().keyProblemaEstatico],
            setor: (responses[2].val() == null) ? 'Não informado' : responses[2].val(),
            status: childItem.val().status,
            chaveUnica: chaveUnica,
            tempoGasto: childItem.val().tempoGasto,
            channelChamado: childItem.val().channelChamado,
            periodoResolucaoChamado: childItem.val().periodoResolucaoChamado == undefined ? "" : childItem.val().periodoResolucaoChamado,
            solucaoAtendimento: childItem.val().solucaoAtendimento == undefined ? "" : childItem.val().solucaoAtendimento,
            lastmessageChatCount: childItem.val().lastmessageChatCount == undefined ? 0 : childItem.val().lastmessageChatCount,
            lastmessageChatType: childItem.val().lastmessageChatType == undefined ? 'mensagem' : childItem.val().lastmessageChatType,
          });

        });
        /*  console.log(dadosAtendimento)
         console.log('antes do envio'); */
        /* f (this.props.criarChamadoProblemaEstatico == true && this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 1) { */
        dispatch({ type: MODIFICAPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].keyProblemaEstatico })
        dispatch({ type: MODIFICANOMEPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].descricaoProblema })

        /* }  */

        enviaAtendimentoParaReducerChat(dispatch, dadosAtendimento);
        /*   console.log('depois do envio') */
      })



    })

  })

}
export const listaAcoesChamadoConversasAcoesChamado = (valor, chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores) => {
  return dispatch => {
    ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, true);
    console.log('no listaConversasAcoesChamado')
    console.log(chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores)
    if (valor === 0) {
      enviaConversasAntigasChatAcoesChamado(dispatch, []);
      enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, '');

      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).limitToLast(15).on("value", snapshot2 => {
        let arrayAcoesAtendimento = [];

        firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).once("value").then((snapshot) => {
          let keyAtendimento = Object.keys(snapshot.val());
          let lastmessageChatStatus = snapshot.val()[keyAtendimento].lastmessageChatStatus === undefined ? "waiting" : snapshot.val()[keyAtendimento].lastmessageChatStatus;
          if (lastmessageChatStatus === "waiting" || lastmessageChatStatus === "sent") {
            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({ lastmessageChatStatus: 'received' })
          }
        })
        /* console.log('antes for each som') */
        let aux = 0;
        snapshot2.forEach(childItem => {
          if (childItem.val().tipo !== undefined) {
            if (childItem.val().visivelATodos == 1) {

              if (childItem.val().statusMessage == 'sent') {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/${childItem.key}`).update({ statusMessage: 'received' })

              }
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                time: childItem.val().time,
                lineColor: childItem.val().lineColor,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                tipoArquivo: childItem.val().tipoArquivo,
                mensagemSistema: childItem.val().mensagemSistema,
                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,


              })
            }


          }
        })

        console.log('acoes do chamado local')
        console.log(arrayAcoesAtendimento)
        arrayAcoesAtendimento = arrayAcoesAtendimento.reverse()
        console.log(arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1]);
        // enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
        enviaAcoesAtendimentoParaReducer(dispatch, arrayAcoesAtendimento);
        ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);

      })
    } else {
      if (referenciaUltimaKey === undefined) {
        setTimeout(() => {
          ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
        }, 500);
      } else {

        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
          .endAt(referenciaUltimaKey).limitToLast(15).once('value').then((snapshot) => {
            if (snapshot.val()) {
              let arrayNovasConversas = [];
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
              let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              snapshot.forEach(childItem => {
                if (childItem.val().tipo !== undefined && childItem.val().visivelATodos == 1) {
                  arrayNovasConversas.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    time: childItem.val().time,
                    lineColor: childItem.val().lineColor,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    tipoArquivo: childItem.val().tipoArquivo,
                    mensagemSistema: childItem.val().mensagemSistema,
                    visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                    duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                    deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

                  })
                }
              })
              console.log(arrayNovasConversas)
              let arrayAcoes = arrayNovasConversas.reverse().slice(1);
              console.log(referenceToOldestKey)
              //enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoes))
              enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, referenceToOldestKey);
              enviaConversasAntigasChatAcoesChamado(dispatch, conversasAnteriores.concat(arrayAcoes))
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
              }, 500);
              //enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
              // enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesNovo);
              // ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
            } else {
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
              }, 500);
            }
          })
      }
    }
  }
}
export const listaConversasAntigasResponderAcoes = (keyChamado, idMensagem, conversasAnteriores, rota, keyUltimaMensagem, keyMensagemResponder) => {
  return dispatch => {
    console.log(keyChamado, idMensagem, conversasAnteriores, rota, keyUltimaMensagem, keyMensagemResponder)
    ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, true);
    /*   ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, true);
      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, true); */


    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}`).orderByKey().startAt(keyMensagemResponder).endAt(keyUltimaMensagem).once('value').then((snapshot2) => {
        console.log(snapshot2.val())
        /*  console.log(`dentro do firebase`); */
        let arrayAcoesAtendimento = [];
        if (snapshot2.val()) {
          ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado(dispatch, true);
          snapshot2.forEach(childItem => {
            /*    console.log(childItem.val()) */
            if (childItem.val().tipo !== undefined) {
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                time: childItem.val().time,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                lineColor: childItem.val().lineColor,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                tipoArquivo: childItem.val().tipoArquivo,

                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                read: childItem.val().read == undefined ? false : childItem.val().read,
                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

              });
            }
          });
          /*  if (verificaSeColocoInformacoesFinaisAtendimento) {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Fim',
               title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               tipo: 'mensagem',
               description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });

           } else {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Andamento',
               title: `Conversa em Andamento: #${numeroChamado}`,
               tipo: 'mensagem',
               description: `Conversa em Andamento: #${numeroChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });
           } */
          console.log('referencia ultima key');

          let arrayConversas = arrayAcoesAtendimento.reverse().slice(1);
          let referenceToOldestKey = arrayConversas[arrayConversas.length - 1].key;
          console.log(referenceToOldestKey)
          enviaReferenciaultimaKeyAcoesChamadoAcoesChamado(dispatch, referenceToOldestKey)
          enviaConversasAntigasChatAcoesChamado(dispatch, arrayConversas);
          enviaIdRespostaAntigaAcoesChamado(dispatch, idMensagem);
          ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado(dispatch, false);
          ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado(dispatch, false);
        }
      });

    })



  }
};
export const listaAcoesChamadoConversasChat = (valor, chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores) => {
  return dispatch => {
    ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
    console.log('no listaConversasChat')
    console.log(chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores)
    const sound = new Howl({
      src: [soundnotification],
      volume: 1,
      // autoplay: true
    });


    if (valor === 0) {
      let verificaSeJaFoiExecutadoPrimeirVez = false;

      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).limitToLast(15).on("value", snapshot2 => {
        let arrayAcoesAtendimento = [];
        let numChildrenAux = 0;
        /* console.log('antes for each som') */
        let aux = 0;
        firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).once("value").then((snapshot) => {
          let keyAtendimento = Object.keys(snapshot.val());
          let lastmessageChatStatus = snapshot.val()[keyAtendimento].lastmessageChatStatus === undefined ? "waiting" : snapshot.val()[keyAtendimento].lastmessageChatStatus;
          if (lastmessageChatStatus === "waiting" || lastmessageChatStatus === "sent") {
            firebase.database().ref(`${chaveUnica}/chamados/${keyAtendimento}`).update({ lastmessageChatStatus: 'received' })
          }
        })
        snapshot2.forEach(childItem => {
          if (childItem.val().tipo !== undefined) {
            if (childItem.val().visivelATodos == 1) {

              if (childItem.val().statusMessage == 'sent') {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/${childItem.key}`).update({ statusMessage: 'received' })

              }
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                time: childItem.val().time,
                lineColor: childItem.val().lineColor,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                tipoArquivo: childItem.val().tipoArquivo,
                mensagemSistema: childItem.val().mensagemSistema,
                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

              })
            }
            numChildrenAux++
            /*       console.log('numchil')
                  console.log(numChildrenAux)
                  console.log(snapshot2.numChildren()) */
          }
        })
        /* console.log('depois for each som')
        console.log(arrayAcoesAtendimento)
        console.log(arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1].circleColor) */
        if (arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1].circleColor == '#00FF7F' && arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1].statusMessage === "sent") {
          /*  console.log('ultima linha do childval')
           console.log(keyAtendimento) */
          /*  if (childItem.val().circleColor == '#00FF7F') { */
          let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimentocliente')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimentocliente'))
          if (verificaSeJaFoiExecutadoPrimeirVez == true && cfgAudio == true) {
            /* console.log('antes de executar o som') */
            setTimeout(() => {
              if (sound.playing() == false) {
                sound.play();
              }
            }, 500);
            dispatch({ type: MODIFICAATENDIMENTOONLINEVISIBLE, payload: true })
          }

          /* } */
        }
        console.log('acoes do chamado local')
        console.log(arrayAcoesAtendimento)
        arrayAcoesAtendimento = arrayAcoesAtendimento.reverse()
        console.log(arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1]);
        // enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
        enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesAtendimento);
        ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);

        verificaSeJaFoiExecutadoPrimeirVez = true;
      })
    } else {
      if (referenciaUltimaKey === undefined) {
        setTimeout(() => {
          ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
        }, 500);
      } else {

        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
          .endAt(referenciaUltimaKey).limitToLast(15).once('value').then((snapshot) => {
            if (snapshot.val()) {
              let arrayNovasConversas = [];
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
              let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              snapshot.forEach(childItem => {
                if (childItem.val().tipo !== undefined) {
                  arrayNovasConversas.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    time: childItem.val().time,
                    lineColor: childItem.val().lineColor,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    tipoArquivo: childItem.val().tipoArquivo,
                    mensagemSistema: childItem.val().mensagemSistema,
                    visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                    duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                    deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                  })
                }
              })
              console.log(arrayNovasConversas)
              let arrayAcoes = arrayNovasConversas.reverse().slice(1);
              console.log(referenceToOldestKey)
              //enviaConversasCarregadasEmEspera(dispatch, conversasAnteriores.concat(arrayAcoes))
              enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
              enviaConversasAntigasChat(dispatch, conversasAnteriores.concat(arrayAcoes))
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
              }, 500);
              //enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
              // enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesNovo);
              // ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
            } else {
              setTimeout(() => {
                ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
              }, 500);
            }
          })
      }
    }
  }
}
export const listaConversasAntigasResponder = (keyChamado, idMensagem, conversasAnteriores, rota, keyUltimaMensagem, keyMensagemResponder) => {
  return dispatch => {
    console.log(keyChamado, idMensagem, conversasAnteriores, rota, keyUltimaMensagem, keyMensagemResponder)
    ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
    /*   ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigosEmEspera(dispatch, true);
      ativaDesativaLoadingCarregaChamadosAntigos(dispatch, true);
      ativaDesativaLoadingCarregaMaisChamadosAntigos(dispatch, true); */


    AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
      const chaveUnica = chaveUnica2.replace(/"/g, "");

      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyChamado}`).orderByKey().startAt(keyMensagemResponder).endAt(keyUltimaMensagem).once('value').then((snapshot2) => {
        console.log(snapshot2.val())
        /*  console.log(`dentro do firebase`); */
        let arrayAcoesAtendimento = [];
        if (snapshot2.val()) {
          ativaDesativaLoadingCarregaRespostasAntigas(dispatch, true);
          snapshot2.forEach(childItem => {
            /*    console.log(childItem.val()) */
            if (childItem.val().tipo !== undefined) {
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                time: childItem.val().time,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                lineColor: childItem.val().lineColor,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                tipoArquivo: childItem.val().tipoArquivo,
                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                captionArquivo: childItem.val().captionArquivo == undefined ? '' : childItem.val().captionArquivo,
                read: childItem.val().read == undefined ? false : childItem.val().read,
                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,

              });
            }
          });
          /*  if (verificaSeColocoInformacoesFinaisAtendimento) {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Fim',
               title: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               tipo: 'mensagem',
               description: `Fim do Atendimento: #${numeroChamado} - ${dataFechamentoChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });

           } else {
             arrayAcoesAtendimento.push({
               key: keyAtendimento + 'Andamento',
               title: `Conversa em Andamento: #${numeroChamado}`,
               tipo: 'mensagem',
               description: `Conversa em Andamento: #${numeroChamado}`,
               statusMessage: 'sent',
               time: "09:50",
               dataHoraAtual: '01/01/2099',
               lineColor: '#00FF7F',
               circleColor: '#00FF7F',
               visivelATodos: 1,
             });
           } */
          console.log('referencia ultima key');

          let arrayConversas = arrayAcoesAtendimento.reverse().slice(1);
          let referenceToOldestKey = arrayConversas[arrayConversas.length - 1].key;
          console.log(referenceToOldestKey)
          enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey)
          enviaConversasAntigasChat(dispatch, arrayConversas);
          enviaIdRespostaAntiga(dispatch, idMensagem);
          ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
          ativaDesativaLoadingCarregaRespostasAntigas(dispatch, false);
        }
      });

    })



  }
};
/* export const listaAcoesChamadoConversasChat = (chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores) => {
  return dispatch => {
    ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, true);
    console.log('no listaConversasChat')
    console.log(chaveUnica, keyAtendimento, referenciaUltimaKey, conversasAnteriores)
    const sound = new Howl({
      src: [soundnotification],
      volume: 1,
      // autoplay: true
    });
    let arrayAcoesAtendimento = [];
    console.log(arrayAcoesAtendimento.length)
    console.log(conversasAnteriores.length)
    if (arrayAcoesAtendimento.length === 0 && conversasAnteriores.length > 0) {
      console.log('adicionando conversas anteriores no arrayacoes')
      arrayAcoesAtendimento = [...conversasAnteriores]
    }

    if (referenciaUltimaKey === '') {
      let verificaSeJaFoiExecutadoPrimeirVez = false;
      firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).limitToLast(10).on("value", snapshot2 => {

        let numChildrenAux = 0;
        snapshot2.forEach(childItem => {
          if (childItem.val().tipo !== undefined) {
            if (childItem.val().visivelATodos == 1) {

              if (childItem.val().statusMessage == 'sent') {
                firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}/${childItem.key}`).update({ statusMessage: 'received' })
              }
              arrayAcoesAtendimento.push({
                key: childItem.key,
                title: childItem.val().titulo,
                tipo: childItem.val().tipo,
                description: childItem.val().mensagem,
                time: childItem.val().time,
                lineColor: childItem.val().lineColor,
                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                circleColor: childItem.val().circleColor,
                urlDownload: childItem.val().urlDownload,
                tipoArquivo: childItem.val().tipoArquivo,
                mensagemSistema: childItem.val().mensagemSistema,
                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
              })
            }
            numChildrenAux++
          
          }

        })
        
        if (arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1].circleColor == '#00FF7F') {
        
          let cfgAudio = JSON.parse(localStorage.getItem('reproduzsomematendimentocliente')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimentocliente'))
          if (verificaSeJaFoiExecutadoPrimeirVez == true && cfgAudio == true) {
            setTimeout(() => {
              if (sound.playing() == false) {
                sound.play();
              }
            }, 500);
            dispatch({ type: MODIFICAATENDIMENTOONLINEVISIBLE, payload: true })
          }

        }
        console.log('acoes do chamado local')
        console.log(arrayAcoesAtendimento)
        arrayAcoesAtendimento = arrayAcoesAtendimento.reverse()
        let referenceToOldestKey = arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1].key;
        console.log(referenceToOldestKey)
        console.log(arrayAcoesAtendimento[arrayAcoesAtendimento.length - 1]);
        enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
        enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesAtendimento);
        ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);

        verificaSeJaFoiExecutadoPrimeirVez = true;
      })
    } else {
      if (referenciaUltimaKey === undefined || referenciaUltimaKey !== '' && conversasAnteriores.length === 0) {
        ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
      } else {
        
        firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).orderByKey()
          .endAt(referenciaUltimaKey).limitToLast(15).once('value').then((snapshot) => {
            if (snapshot.val()) {
              let arrayNovasConversas = [];
              let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
              let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
              snapshot.forEach(childItem => {
                if (childItem.val().tipo !== undefined) {
                  arrayNovasConversas.push({
                    key: childItem.key,
                    title: childItem.val().titulo,
                    tipo: childItem.val().tipo,
                    description: childItem.val().mensagem,
                    time: childItem.val().time,
                    lineColor: childItem.val().lineColor,
                    dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                    circleColor: childItem.val().circleColor,
                    urlDownload: childItem.val().urlDownload,
                    tipoArquivo: childItem.val().tipoArquivo,
                    mensagemSistema: childItem.val().mensagemSistema,
                    duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                    audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                    statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                    idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                    circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                    commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                    idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                    mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                    possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                    commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                    commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                    atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                  })
                }
              })
              console.log('arrayAcoesAtendimento')
              console.log(arrayAcoesAtendimento)

              console.log('arrayNovasConversas')
              console.log(arrayNovasConversas);

              console.log(referenceToOldestKey);
              let arrayAcoes = arrayNovasConversas.reverse().slice(1);
              console.log(arrayAcoes)
              let arrayAcoesNovo = arrayAcoesAtendimento.concat(arrayAcoes);
              console.log(arrayAcoesNovo)
              enviaReferenciaultimaKeyAcoesChamado(dispatch, referenceToOldestKey);
              enviaAcoesAtendimentoParaReducerChat(dispatch, arrayAcoesNovo);
              ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
            } else {
              ativaDesativaLoadingCarregandoMaisAcoesChamado(dispatch, false);
            }
          })
      }
    }
  }
} */
export const listaChamadoParaTelaAcoesChamadoExport = (dispatch, keyAtendimento) => {
  /*   console.log(keyAtendimento)
    console.log('lista acao export'); */

  AsyncStorage.getItem("chaveUnicaCliente").then(chaveUnica2 => {
    const chaveUnica = chaveUnica2.replace(/"/g, "");
    dispatch(listaAcoesChamadoConversasChat(0, chaveUnica, keyAtendimento, '', []));
    /*  firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('key').equalTo(keyAtendimento).on("value", snapshot => {
       if (snapshot.val()) {
         console.log(snapshot.val());
         console.log('---------------AVALIACAO FEITA----------------------')
         enviaSeFezAvaliacaoNoChamadoChat(dispatch, 1);
       } else {
         enviaSeFezAvaliacaoNoChamadoChat(dispatch, 0);
         console.log('---------------NAO FEZ AVALIACAO----------------------')
       }
     }) */
    /*    firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).on("value", snapshot => {
         if (snapshot.val()) {
   
           console.log('tem dados')
           const dadosEmpresa = [];
           snapshot.forEach(childItem => {
             dadosEmpresa.push({
               key: childItem.key,
               mensagemPadrao: childItem.val().mensagemPadrao,
               utilizaAvaliacao: childItem.val().utilizaAvaliacao
             })
           })
           console.log(snapshot.val())
           console.log(dadosEmpresa[0].mensagemPadrao);
           console.log(dadosEmpresa[0].utilizaAvaliacao);
           //envia se tem ou nao permissao pra avaliar
           if (dadosEmpresa[0].utilizaAvaliacao) {
             enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, true);
           } else {
             enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, false);
           }
   
         } else {
           console.log('não tem dados')
           //envia como nao tem permissao
           enviaSetemAutorizacaoParaAvaliarChamadoChat(dispatch, false);
         }
   
       }) */
    firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).limitToLast(1).on("value", snapshot => {

      /*  console.log('snapshot')
       console.log(snapshot.val()); */

      let keyAtendimento = Object.keys(snapshot.val())
      /*  console.log(keyAtendimento) */
      let arrayPromisses = [];
      /*  console.log(snapshot.val()[keyAtendimento].cliente)
       console.log(snapshot.val()[keyAtendimento].atendente)
       console.log(snapshot.val()[keyAtendimento].setor)
       console.log(snapshot.val()[keyAtendimento].status) */
      let promisseAbertoPor;
      if (snapshot.val()[keyAtendimento].chamadoAbertoPorAdminOuCliente == 'cliente') {

        promisseAbertoPor = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
      } else {
        promisseAbertoPor = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].abertoPor}/nome`).once('value')
      }

      let promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${snapshot.val()[keyAtendimento].cliente}/nome`).once('value')
      let promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${snapshot.val()[keyAtendimento].atendente}/nome`).once('value')
      let promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${snapshot.val()[keyAtendimento].setor}/nome`).once('value')

      arrayPromisses.push(promisseAtendente, promisseCliente, promisseSetor, promisseAbertoPor);

      const dadosAtendimento = [];
      Promise.all(arrayPromisses).then(responses => {
        snapshot.forEach(childItem => {
          /*  console.log('dentro do snap')
           console.log(childItem.val().status) */
          dadosAtendimento.push({
            key: childItem.key,
            abertoPor: (responses[3].val() == null) ? 'Não informado' : responses[3].val(),
            alterado: childItem.val().alterado,
            keyAtendente: childItem.val().atendente,
            atendente: (responses[0].val() == null) ? 'Não informado' : responses[0].val(),
            chamadoAbertoPorAdminOuCliente: childItem.val().chamadoAbertoPorAdminOuCliente,
            keyCliente: childItem.val().cliente,
            cliente: (responses[1].val() == null) ? 'Não informado' : responses[1].val(),
            dataAbertura: childItem.val().dataAbertura,
            dataHoraAbertura: childItem.val().dataHoraAbertura,
            dataHoraFechamento: (childItem.val().dataHoraFechamento == undefined) ? '' : childItem.val().dataHoraFechamento,
            dataHoraCancelamento: (childItem.val().dataHoraCancelamento == undefined) ? '' : childItem.val().dataHoraCancelamento,
            dataVencimento: childItem.val().dataVencimento,
            descricaoProblema: childItem.val().descricaoProblema,
            local: childItem.val().local,
            numeroChamado: childItem.val().numeroChamado,
            prioridade: childItem.val().prioridade,
            keySetor: childItem.val().setor,
            descricaoProblemaEstatico: (childItem.val().descricaoProblemaEstatico == undefined) ? 0 : childItem.val().descricaoProblemaEstatico,
            keyProblemaEstatico: (childItem.val().keyProblemaEstatico == undefined) ? [] : [childItem.val().keyProblemaEstatico],
            setor: (responses[2].val() == null) ? 'Não informado' : responses[2].val(),
            status: childItem.val().status,
            chaveUnica: chaveUnica,
            channelChamado: childItem.val().channelChamado,
            tempoGasto: childItem.val().tempoGasto,
            periodoResolucaoChamado: childItem.val().periodoResolucaoChamado == undefined ? "" : childItem.val().periodoResolucaoChamado,
            solucaoAtendimento: childItem.val().solucaoAtendimento == undefined ? "" : childItem.val().solucaoAtendimento,
            lastmessageChatCount: childItem.val().lastmessageChatCount == undefined ? 0 : childItem.val().lastmessageChatCount,
            lastmessageChatType: childItem.val().lastmessageChatType == undefined ? 'mensagem' : childItem.val().lastmessageChatType,
          });

        });
        /* console.log(dadosAtendimento)
        console.log('antes do envio'); */
        /* f (this.props.criarChamadoProblemaEstatico == true && this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 1) { */
        dispatch({ type: MODIFICAPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].keyProblemaEstatico })
        dispatch({ type: MODIFICANOMEPROBLEMAESTATICOSELECIONADO, payload: dadosAtendimento[0].descricaoProblema })

        /* }  */

        enviaAtendimentoParaReducerChat(dispatch, dadosAtendimento);
        /*  console.log('depois do envio') */
      })



    })

  })

}
const enviaProblemasEstaticosParaReducer = (dispatch, valor) => {
  dispatch({
    type: ENVIAPROBLEMASESTATICOSPARAREDUCER,
    payload: valor
  });
};
const modificaTelaAtualChat = (dispatch, valor) => {
  dispatch({
    type: MODIFICATELAATUALCHAT,
    payload: valor
  });
};
export const modificaTelaAtualChatExport = tela => {
  return {
    type: MODIFICATELAATUALCHAT,
    payload: tela
  };
};
const modificaKeyAtendimentoChat = (dispatch, valor) => {
  dispatch({
    type: MODIFICAKEYATENDIMENTOCHAT,
    payload: valor
  });
};


const modificaUpandoARquivoChat = (dispatch, valor) => {
  dispatch({
    type: MODIFICAUPANDOARQUIVOCHAT,
    payload: valor
  });
};
const modificaTextArquivoChat = (dispatch, valor) => {
  dispatch({
    type: MODIFICATEXTARQUIVOCHAT,
    payload: valor
  });
};
const ativaDesativaLoadingCarregaProblemasEstaticos = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGAPROBLEMASESTATICOS,
    payload: valor
  });
};
const abreModalAvaliarChamadoLocal = (dispatch, visible) => {
  dispatch({
    type: ABREMODALAVALIARCHAMADO,
    payload: visible
  })
};
//metodo de quando clica no chamado nas abas de chamado aberto, fechado, em atendimento
export const navegaTelaALterarChamado = (keyChamado, numeroAtendimento, history) => {
  return dispatch => {
    /* console.log(keyChamado, numeroAtendimento) */
    dispatch({ type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO, payload: true });
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    modificaNomeRotaNoHeader(dispatch, `Atendimento #${numeroAtendimento}`)
    history.push("/visualizaatendimento")
    // window.location = "/visualizaatendimento";
  };
};
export const navegaTelaALterarChamadoSolicitacoesEmpresa = (keyChamado, numeroAtendimento, history) => {
  return dispatch => {
    /*   console.log(keyChamado, numeroAtendimento) */
    dispatch({ type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO, payload: true });
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    modificaNomeRotaNoHeader(dispatch, `Atendimento #${numeroAtendimento}`)
    history.push("/visualizaatendimentoempresa")
    // window.location = "/visualizaatendimento";
  };
};
const enviaUltimaReferenciaKeySolicitacoesEmpresa = (dispatch, value) => {
  dispatch({
    type: ENVIAULTIMAREFERENCIAKEYSOLICITACOESEMPRESA,
    payload: value
  })
};
export const modificaVisibilidadeModalNovoAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALNOVOATENDIMENTO, payload: value });
    zeraCadastroChamado(dispatch);
  };
};
const enviaSetemAutorizacaoParaAvaliarChamado = (dispatch, value) => {
  dispatch({
    type: ENVIASETEMAUTORIZACAOPARAAVALIARCHAMADO,
    payload: value
  });
};
const enviaSetemAutorizacaoParaAvaliarChamadoChat = (dispatch, value) => {
  dispatch({
    type: ENVIASETEMAUTORIZACAOPARAAVALIARCHAMADOCHAT,
    payload: value
  });
};
export const modificaVisibilidadeModalAlterarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALALTERARATENDIMENTO, payload: value });
    /*   setTimeout(() => {
        dispatch({ type: VISIBLESELECTSEARCH, payload: value });
    }, 100); */
    //dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //window.location = "/atendimentos"
  };
};
const ativaDesativaLoadingTelaSolicitacoesEmpresa = (dispatch, value) => {
  dispatch({
    type: ATIVADEASTIVALOADINGTELASOLICITACOESEMPRESA,
    payload: value
  })
};
const ativaDesativaLoadingFimDaPAginaSolicitacoesEmpresa = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADIINGFIMDAPAGINASOLICITACOESEMPRESA,
    payload: value
  })
};
export const modificaVisibilidadeModalAtribuirAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALATRIBUIRATENDIMENTO, payload: value });
  };
};

export const modificaVisibilidadeModalAddTempoGastoAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALADDTEMPOGASTOATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalFinalizarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALFINALIZARATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalPrioridadeAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALPRIORIDADEATENDIMENTO, payload: value });
  };
};
export const modificaVisibilidadeModalCancelarAtendimento = (value) => {

  return dispatch => {
    dispatch({ type: MODIFICAVISIBILIDADEMODALCANCELARATENDIMENTO, payload: value });
  };
};
export const teste = () => {
  return dispatch => {
    toastr.error('Sucesso', 'Usuario logado')
    //dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    //dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    //window.location = "/dashboard"
  };
};

const ativaDesativaLoadingCarregaRespostasAntigasAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARESPOSTAANTIGASACOESCHAMADO,
    payload: value
  });
};
const enviaIdRespostaAntigaAcoesChamado = (dispatch, id) => {
  dispatch({
    type: ENVIAIDRESPOSTAANTIGAACOESCHAMADO,
    payload: id
  });
};
const enviaConversasAntigasChatAcoesChamado = (dispatch, valor) => {
  dispatch({
    type: ENVIACONVERSASANTIGASCHATACOESCHAMADO,
    payload: valor
  });
};
export const enviaConversasAntigasChatExportAcoesChamado = (valor) => {
  return {
    type: ENVIACONVERSASANTIGASCHATACOESCHAMADO,
    payload: valor
  };
};
const ativaDesativaLoadingCarregandoMaisAcoesChamadoAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADOACOESCHAMADO,
    payload: value
  })
};
export const enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado = status => {
  return {
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO,
    payload: status
  };
};
const enviaReferenciaultimaKeyAcoesChamadoAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADOACOESCHAMADO,
    payload: value
  })
};



//daqui pra cima

const ativaDesativaLoadingCarregaRespostasAntigas = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARESPOSTAANTIGAS,
    payload: value
  });
};
const enviaIdRespostaAntiga = (dispatch, id) => {
  dispatch({
    type: ENVIAIDRESPOSTAANTIGA,
    payload: id
  });
};
const enviaConversasAntigasChat = (dispatch, valor) => {
  dispatch({
    type: ENVIACONVERSASANTIGASCHAT,
    payload: valor
  });
};
export const enviaConversasAntigasChatExport = (valor) => {
  return {
    type: ENVIACONVERSASANTIGASCHAT,
    payload: valor
  };
};
const ativaDesativaLoadingCarregandoMaisAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGANDOMAISACOESCHAMADO,
    payload: value
  })
};
export const enviaReferenciaultimaKeyAcoesChamadoExport = status => {
  return {
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADO,
    payload: status
  };
};
const enviaReferenciaultimaKeyAcoesChamado = (dispatch, value) => {
  dispatch({
    type: ENVIAREFERENCIAULTIMAKEYACOESCHAMADO,
    payload: value
  })
};

const ativaDesativaLoadingFinalFlatListSolicitacoesEmpresa = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTSOLICITACOESEMPRESA,
    payload: value
  })
};
const modificaNomeRotaNoHeader = (dispatch, nome) => {
  return dispatch({
    type: MODIFICANOMEROTANOHEADER,
    payload: nome
  })
};
const enviaChamadosSolicitacoesEmpresa = (dispatch, value) => {
  dispatch({
    type: ENVIACHAMADOSSOLICITACOESEMPRESA,
    payload: value
  })
};
export const modificavisibilidadeModalComentario = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  };
};
export const modificaInputComentario = texto => {
  /*  console.log('no modifica input coment')
   console.log(texto) */
  return {
    type: MODIFICAINPUTCOMENTARIO,
    payload: texto
  };
};
export const modificaComentarioPublicoSimOuNao = valor => {
  return {
    type: MODIFICACOMENTARIOPUBLICOSIMOUNAO,
    payload: valor
  };
};


//metodo ao clicar em alterar chamado na tela acoes atendimento
export const navegaTelaEditarChamado = () => {
  return dispatch => {
    zeraCadastroChamado(dispatch);
    // dispatch(NavigationActions.navigate({ routeName: "AlterandoChamado" }));
  };
};
const enviaSeFezAvaliacaoNoChamado = (dispatch, value) => {
  dispatch({
    type: ENVIASEFEZAVALIACAOCHAMADO,
    payload: value
  });
};
const enviaSeFezAvaliacaoNoChamadoChat = (dispatch, value) => {
  dispatch({
    type: ENVIASEFEZAVALIACAOCHAMADOCHAT,
    payload: value
  });
};
export const navegaTelaNovoChamado = () => {
  return dispatch => {
    zeraCadastroChamado(dispatch);
    //  dispatch(NavigationActions.navigate({ routeName: "TelaAberturaChamado" }));
  };
};

export const modificaNomeClienteSelecionadoTelaCadastroCliente = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACADASTROCLIENTE,
    payload: nome
  };
};
const ativaDesativaLoadingMaisResultadosChamadosCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGMAISRESULTADOSCHAMADOSCLIENTE,
    payload: valor
  });
};
const modificaPosicaoAtualFila = (dispatch, valor) => {
  dispatch({
    type: MODIFICAPOSICAOATUALFILA,
    payload: valor
  });
};
export const modificaNomeClienteSelecionadoTelaAlterarCliente = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
    payload: nome
  };
};


export const modificaNomeClienteSelecionadoTelaAlterarClienteNovoCadastro = nome => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
    payload: nome
  };
};

//metodo de quando clica no chamado nas abas de chamado aberto, fechado, em atendimento
/* export const navegaTelaALterarChamado = (keyChamado, numeroAtendimento) => {
  return dispatch => {
    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
    dispatch({ type: ALTERAKEYCHAMADO, payload: keyChamado });
    dispatch(NavigationActions.navigate({ routeName: "AcoesAtendimento" }));
  };
}; */
//metodo de quando clica na notificacao pra entrar no chamado



export const navegaTelaAlterarCliente = (
  uriFoto,
  empresaCliente,
  nomeCliente,
  email,
  permissaoApp,
  senha,
  confirmaSenha,
  key,
  clienteSelecionadoConsultaClienteTelaCAdastraCliente,
  clienteEmpresaPrincipal,
  telefoneCliente,
  enderecoCliente
) => {
  /*  console.log(uriFoto,
     empresaCliente,
     nomeCliente,
     email,
     permissaoApp,
     senha,
     confirmaSenha,
     key,
     clienteSelecionadoConsultaClienteTelaCAdastraCliente,
     clienteEmpresaPrincipal,
     telefoneCliente,
     enderecoCliente); */
  let keyClienteVinculado;
  if (
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined ||
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == "Não Vinculado"
  ) {
    keyClienteVinculado = [];
  } else {
    keyClienteVinculado = clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  }
  // /keyClienteVinculado = (clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined) ? [] : clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  /*   console.log("key cliente vinculado");
    console.log(keyClienteVinculado);
    console.log(keyClienteVinculado.length); */
  /*   if (keyClienteVinculado.length !== 0) {
      keyClienteVinculado = keyClienteVinculado;
    } */
  return dispatch => {
    dispatch({
      type: ALTERAEMPRESACLIENTETELAALTERAR,
      payload: empresaCliente
    });
    dispatch({ type: ALTERANOMECLIENTETELAALTERAR, payload: nomeCliente });
    dispatch({ type: ALTERAREMAILCLIENTETELAALTERAR, payload: email });
    dispatch({
      type: ALTERARPERMISSAOCLIENTETELAALTERAR,
      payload: permissaoApp
    });
    dispatch({ type: ALTERASENHACLIENTETELAALTERAR, payload: senha });
    dispatch({
      type: ALTERACONFIRMASENHACLIENTETELAALTERAR,
      payload: confirmaSenha
    });
    dispatch({ type: ALTERAFOTOCLIENTETELAALTERAR, payload: uriFoto });
    dispatch({ type: ALTERAKEYCLIENTETELAALTERAR, payload: key });
    dispatch({ type: ZERAMENSAGEMDESUCESSOEERRO, payload: true });
    dispatch({ type: MODIFICAENDERECOTELACADASTROCLIENTE, payload: enderecoCliente });
    dispatch({ type: MODIFICATELEFONETELACADASTROCLIENTE, payload: telefoneCliente });

    /*  console.log("cliente principal");
     console.log(clienteEmpresaPrincipal); */
    dispatch({
      type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
      payload: keyClienteVinculado
    });
    dispatch({
      type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
      payload: empresaCliente
    });

    dispatch({
      type: ENVIAEMPRESAPRINCIPALCLIENTE,
      payload: clienteEmpresaPrincipal
    });

    //dispatch(NavigationActions.navigate({ routeName: "AlterandoCliente" }));
  };
};
const enviaDadosClienteTelaAlterar = (
  uriFoto,
  empresaCliente,
  nomeCliente,
  email,
  permissaoApp,
  senha,
  confirmaSenha,
  key,
  clienteSelecionadoConsultaClienteTelaCAdastraCliente,
  clienteEmpresaPrincipal,
  telefoneCliente,
  enderecoCliente
) => {
  /*  console.log('método 2');
   console.log(uriFoto,
     empresaCliente,
     nomeCliente,
     email,
     permissaoApp,
     senha,
     confirmaSenha,
     key,
     clienteSelecionadoConsultaClienteTelaCAdastraCliente,
     clienteEmpresaPrincipal,
     telefoneCliente,
     enderecoCliente); */
  let keyClienteVinculado;
  if (
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined ||
    clienteSelecionadoConsultaClienteTelaCAdastraCliente == "Não Vinculado"
  ) {
    keyClienteVinculado = [];
  } else {
    keyClienteVinculado = clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  }
  // /keyClienteVinculado = (clienteSelecionadoConsultaClienteTelaCAdastraCliente == undefined) ? [] : clienteSelecionadoConsultaClienteTelaCAdastraCliente;
  /*  console.log("key cliente vinculado");
   console.log(keyClienteVinculado);
   console.log(keyClienteVinculado.length); */
  if (keyClienteVinculado.length !== 0) {
    keyClienteVinculado = [keyClienteVinculado];
  }
  return dispatch => {
    dispatch({
      type: ALTERAEMPRESACLIENTETELAALTERAR,
      payload: empresaCliente
    });
    dispatch({ type: ALTERANOMECLIENTETELAALTERAR, payload: nomeCliente });
    dispatch({ type: ALTERAREMAILCLIENTETELAALTERAR, payload: email });
    dispatch({
      type: ALTERARPERMISSAOCLIENTETELAALTERAR,
      payload: permissaoApp
    });
    dispatch({ type: ALTERASENHACLIENTETELAALTERAR, payload: senha });
    dispatch({
      type: ALTERACONFIRMASENHACLIENTETELAALTERAR,
      payload: confirmaSenha
    });
    dispatch({ type: ALTERAFOTOCLIENTETELAALTERAR, payload: uriFoto });
    dispatch({ type: ALTERAKEYCLIENTETELAALTERAR, payload: key });
    dispatch({ type: ZERAMENSAGEMDESUCESSOEERRO, payload: true });
    dispatch({ type: MODIFICAENDERECOTELACADASTROCLIENTE, payload: enderecoCliente });
    dispatch({ type: MODIFICATELEFONETELACADASTROCLIENTE, payload: telefoneCliente });

    /*     console.log("cliente principal");
        console.log(clienteEmpresaPrincipal); */
    dispatch({
      type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
      payload: keyClienteVinculado
    });
    dispatch({
      type: MODIFICANOMECLIENTESELECIONADOTELAALTERARCLIENTE,
      payload: empresaCliente
    });

    dispatch({
      type: ENVIAEMPRESAPRINCIPALCLIENTE,
      payload: clienteEmpresaPrincipal
    });

    //dispatch(NavigationActions.navigate({ routeName: "AlterandoCliente" }));
  };
};
export const modificaNumeroAtendimento = numero => {
  return {
    type: MODIFICANOMEROTANOHEADER,
    payload: numero
  };
};
export const alteraClienteSelecionadoTelaCadastraCliente = key => {
  return {
    type: ALTERACLIENTESELECIONADOTELACADASTRACLIENTE,
    payload: key
  };
};
export const alteraEmpresaSelecionadoTelaAlterarCliente = key => {
  /* console.log(key) */
  return {
    type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTE,
    payload: key
  };
};
export const alteraEmpresaSelecionadoTelaAlterarClienteNovoCadastro = key => {
  /*  console.log(key) */
  return {
    type: ALTERAEMPRESASELECIONADOTELAALTERARCLIENTENOVOCADASTRO,
    payload: key
  };
};
export const mudaVisibilidadeModalCadastraClienteVersaoPremium = visible => {
  return {
    type: MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
    payload: visible
  };
};
export const modificaVisibleModalNovoCLiente = visible => {
  return {
    type: MODIFICAVISIBLEMODALNOVOCLIENTE,
    payload: visible
  };
};
export const mudaVisibilidadeModalExcluirCliente = visible => {
  return {
    type: MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
    payload: visible
  };
};
export const modificaValorArraySectionedFiltroChamado = value => {
  /* console.log(value) */
  return {
    type: MODIFICAVALORARRAYSECTIONEDFILTROCHAMADO,
    payload: value
  };
};
export const enviaArquivosParaFirebaseTelaAbrirChamado = resArquivo => {
  return dispatch => {
    enviaArquivoParaSerUpadoTelaAberturaChamado(dispatch, resArquivo);
  };
};
export const diminuiTamanhoTotalARquivosAnexados = tamanho => {
  return dispatch => {
    diminuiTamanhoTotalArquivosAnexadosLocal(dispatch, tamanho);
  };
};

const enviaEmpresaPrincipalCliente = (dispatch, valor) => {
  dispatch({
    type: ENVIAEMPRESAPRINCIPALCLIENTE,
    payload: valor
  });
};
const ativaDesativaLoadingNumeroExclusivoCadastraCliente = (
  dispatch,
  valor
) => {
  dispatch({
    type: ATIVADESATIVALOADINGNUMEROEXCLUSIVOCADASTRACLIENTE,
    payload: valor
  });
};

const ativaDesativaLoadingBotaoExcluirCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGBOTAOEXCLUIRCLIENTE,
    payload: valor
  });
};
const ativaDesativaLoadingTelaAlterarCliente = (dispatch, valor) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELAALTERARCLIENTE,
    payload: valor
  });
};
const modificaNumeroExclusivoCadastraCliente = (dispatch, numero) => {
  dispatch({
    type: MODIFICANUMEROEXCLUSIVOCADASTRACLIENTE,
    payload: numero
  });
};

const diminuiTamanhoTotalArquivosAnexadosLocal = (dispatch, tamanho) => {
  dispatch({
    type: DIMINUITAMANHOTOTALARQUIVOSANEXADOSLOCAL,
    payload: tamanho
  });
};
const mudaVisibilidadeModalExcluirClienteLocal = (dispatch, visible) => {
  dispatch({
    type: MUDAVISIBILIDADEMODALEXCLUIRCLIENTE,
    payload: visible
  });
};
export const modificaFiltroChamado = value => {
  return {
    type: MODIFICAFILTROCHAMADO,
    payload: value
  };
};
export const modificaSelectTextChamado = value => {
  return {
    type: MODIFICASELECTTEXTCHAMADO,
    payload: value
  };
};
export const modificaPesquisaTextChamado = value => {
  return {
    type: MODIFICAPESQUISATEXTCHAMADO,
    payload: value
  };
};

export const modificaFiltroSelecionadoTelaChamadoAberto = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOABERTO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoEmAtendimento = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOEMATENDIMENTO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoFechado = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOFECHADO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoCancelado = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOCANCELADO,
    payload: cliente
  };
};
export const modificaFiltroSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICAFILTROSELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};

export const modificaClienteSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};
export const modificaTabIndex = indeex => {
  localStorage.setItem('tabindexCliente', JSON.stringify(indeex));
  return {
    type: MODIFICATABINDEX,
    payload: indeex
  };
};

export const modificaClienteSelecionadoTelaConsultaCliente = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACONSULTACLIENTE,
    payload: cliente
  };
};
export const modificaEmpresaSelecionadaAoAbrirChamado = empresa => {
  return {
    type: MODIFICAEMPRESASELECIONADAAOABRIRCHAMADO,
    payload: empresa
  };
};
export const modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado = empresa => {
  return {
    type: MODIFICAKEYEMPRESAVINCULADASELECIONADAAOABRIRCHAMADO,
    payload: empresa
  };
};

export const enviaTamanhoArquivoAnexadoTelAberturaChamado = tamanho => {
  return {
    type: ENVIATAMANHOARQUIVOANEXADOTELAABERTURACHAMADO,
    payload: tamanho
  };
};
export const zeraClienteSelecionadoChamadoTodos = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADOTELACHAMADOTODOSTESTE,
    payload: cliente
  };
};
export const modificaModalFiltroChamado = value => {
  return {
    type: MODIFICAMODALFILTROCHAMADO,
    payload: value
  };
};

export const modificaNomeClienteSelecionadoTelaChamadoTodos = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACHAMADOTODOS,
    payload: cliente
  };
};
export const modificaDataVencimentoTelaChamadoAbertura = data => {
  return {
    type: MODIFICADATAVENCIMENTOTELACHAMADOABERTURA,
    payload: data
  };
};
export const modificaFiltroClientesTelaClientes = data => {
  return {
    type: MODIFICAFILTROCLIENTESTELACLIENTES,
    payload: data
  };
};
export const modificaNomeClienteSelecionadoTelaConsultaCliente = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADOTELACONSULTACLIENTE,
    payload: cliente
  };
};

export const modificaClienteSelecionado = cliente => {
  return {
    type: MODIFICACLIENTESELECIONADO,
    payload: cliente
  };
};
export const modificaNomeClienteSelecionado = cliente => {
  return {
    type: MODIFICANOMECLIENTESELECIONADO,
    payload: cliente
  };
};
export const modificaNomeAtendenteSelecionado = atendente => {
  return {
    type: MODIFICANOMEATENDENTESELECIONADO,
    payload: atendente
  };
};
export const abreModalAvaliarChamado = visible => {
  return {
    type: ABREMODALAVALIARCHAMADO,
    payload: visible
  };
};
export const modificaComentarioAvaliacao = (text) => {
  return {
    type: MODIFICACOMENTARIOAVALIACAO,
    payload: text
  };
};
export const modificaNomeSetorSelecionado = atendente => {
  return {
    type: MODIFICANOMESETORSELECIONADO,
    payload: atendente
  };
};

export const modificaNomeArquivo1 = arquivo1 => {
  return {
    type: MODIFICANOMEARQUIVO1,
    payload: arquivo1
  };
};
export const modificaNomeArquivo2 = arquivo2 => {
  return {
    type: MODIFICANOMEARQUIVO2,
    payload: arquivo2
  };
};

export const desativaLoadingTelaChamadoAberto = dispatch => {
  dispatch({
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: false
  });
};
const zeraInformacoesEnvioArquivoChat = dispatch => {
  dispatch({
    type: ZERAINFORMACOESENVIOARQUIVOCHAT,
    payload: true
  });
};

/* const verificaSeEhObrigatorioATribuirAtendente = (dispatch, valor) => {
  dispatch({
    type: VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
    payload: valor
  });
}; */

const enviaArquivosUpadosAnexadoAoChamado = (dispatch, array) => {
  dispatch({
    type: ENVIARQUIVOSUPADOSANEXADOSAOCHAMADO,
    payload: array
  });
};

const ativaDesatidaLoadingArquivosUpadosAnexadoAoChamado = (
  dispatch,
  valor
) => {
  dispatch({
    type: ATIVADESATIVALOADINGARQUIVOSUPADOSANEXADOSAOCHAMADO,
    payload: valor
  });
};
export const ativaLoadingTelaAberturaChamadoRemoveFiltro = value => {
  return {
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: value
  };
};

export const ativaLoadingTelaAberturaChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGTELACHAMADOABERTO,
    payload: true
  });
};
const ativaDesativaLoadingFinalFlatListChamadoAberto = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
    payload: value
  });
};
export const ativaDesativaLoadingFinalFlatListChamadoAbertoExport = status => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOABERTO,
    payload: status
  };
};
export const modificavisibilidadeModalCancelar = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCANCELAR,
    payload: status
  };
};

export const zeraMensagemSemConexaoInternetSalvarComentario = msg => {
  return {
    type: ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
    payload: msg
  };
};
const zeraMensagemSemConexaoInternetSalvarComentarioLocal = (dispatch, msg) => {
  dispatch({
    type: ZERAMENSAGEMSEMCONEXAOINTERNETSALVARCOMENTARIO,
    payload: msg
  });
};
const zeraInformacoesComentario = dispatch => {
  dispatch({
    type: ZERAINFORMACOESCOMENTARIO,
    payload: true
  });
};
const enviaArquivoParaSerUpadoTelaAberturaChamado = (dispatch, res) => {
  dispatch({
    type: ENVIAARQUIVOPARASERUPADOTELAABERTURACHAMADO,
    payload: res
  });
};
export const enviaArquivosParaArrayDeArquivosAnexados = array => {
  return dispatch => {
    enviaArquivoParaSerUpadoTelaAberturaChamado(dispatch, array);
  };
};

const modificavisibilidadeModalCancelarLocal = (dispatch, status) => {
  dispatch({
    type: MODIFICAVISIBILIDADEMODALCANCELAR,
    payload: status
  });
};

/* export const modificavisibilidadeModalComentario = status => {
  return {
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  };
}; */
const modificavisibilidadeModalComentarioLocal = (dispatch, status) => {
  dispatch({
    type: MODIFICAVISIBILIDADEMODALCOMENTARIo,
    payload: status
  });
};

const modificaMensagemErroMotivoCancelamento = (dispatch, error) => {
  dispatch({
    type: MODIFICAMENSAGEMERRORMOTIVOCANCELAMENTO,
    payload: error
  });
};

const enviaArquivosUpadosTelaAcoesChamados1 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS,
    payload: array
  });
};
const enviaArquivosUpadosTelaAcoesChamados2 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS2,
    payload: array
  });
};
const enviaArquivosUpadosTelaAcoesChamados3 = (dispatch, array) => {
  dispatch({
    type: ENVIAARQUIVOSUPADOSTELAACOESCHAMADOS3,
    payload: array
  });
};

const ativaDesativaLoadingRefreshControlConsultaCliente = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCONSULTACLIENTE,
    payload: value
  });
};

const ativaDesativaLoadingFinalFlatListChamadoEmAtendimento = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoFechado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoCancelado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
    payload: value
  });
};
const ativaDesativaLoadingFinalFlatListChamadoTodos = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
    payload: value
  });
};


export const ativaDesativaLoadingFinalFlatListTelaConsultaCliente = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGFINALFLATLISTTELACONSULTACLIENTE,
    payload: value
  });
};

export const ativaDesativaSwitchComentario = value => {
  console.log(value)
  return {
    type: ATIVADESATIVASWITCHCOMENTARIO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoAtendimentoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOEMATENDIMENTO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoFechadoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOFECHADO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoCanceladoExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOCANCELADO,
    payload: value
  };
};
export const ativaDesativaLoadingFinalFlatListChamadoTodosExport = value => {
  return {
    type: ATIVADESATIVALOADINGFINALFLATLISTCHAMADOTODOS,
    payload: value
  };
};

const mensagemChamadoAlteradoComSucesso = msg => {
  return {
    type: VERIFICACHAMADOALTERADOSUCESSO,
    payload: msg
  };
};

const indicaFimDalistaTodos = (dispatch, value) => {
  dispatch({
    type: INDICAFIMDALISTA,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoAtendimentoRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
    payload: value
  };
};

const ativaDesativaLoadingTelaChamadoAtendimento = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOATENDIMENTO,
    payload: value
  });
};
const ativaDesativaLoadingTelaChamadoFechado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOFECHADO,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoFechadoRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOFECHADO,
    payload: value
  };
};
const ativaDesativaLoadingTeste = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINTESTETSTE,
    payload: value
  });
};
const ativaDesativaLoadingTelaChamadoCancelado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
    payload: value
  });
};
export const ativaDesativaLoadingTelaChamadoCanceladoRemoveFIltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOCANCELADO,
    payload: value
  };
};
export const ativaDesativaLoadingTelaChamadoTodosRemoveFiltro = (value) => {
  return {
    type: ATIVADESATIVALOADINGTELACHAMADOTODOS,
    payload: value
  }
};
const ativaDesativaLoadingTelaChamadoTodos = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACHAMADOTODOS,
    payload: value
  });
};
export const ativaDesativaLoadingTelaConsultaCliente = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELACONSULTACLIENTE,
    payload: value
  });
};
const ativaDesativaLoadingTelaAcoesAtendimento = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGTELAACOESATENDIMENTO,
    payload: value
  });
};

export const modificaNomeArquivoComentarioTelaAcoesAtendimento = arquivo => {
  return {
    type: MODIFICANOMEARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
    payload: arquivo
  };
};
export const modificaResArquivoComentarioTelaAcoesAtendimento = res => {
  return {
    type: MODIFICARESARQUIVOCOMENTARIOTELAACOESATENDIMENTO,
    payload: res
  };
};

export const modificaNomeArquivo3 = arquivo3 => {
  return {
    type: MODIFICANOMEARQUIVO3,
    payload: arquivo3
  };
};

export const modificaResArquivo1 = resarquivo1 => {
  return {
    type: MODIFICARESARQUIVO1,
    payload: resarquivo1
  };
};
export const modificaResArquivo2 = resarquivo2 => {
  return {
    type: MODIFICARESARQUIVO2,
    payload: resarquivo2
  };
};
export const modificaResArquivo3 = resarquivo3 => {
  return {
    type: MODIFICARESARQUIVO3,
    payload: resarquivo3
  };
};

export const modificaLocalSelecionado = local => {
  return {
    type: MODIFICALOCALSELECIONADO,
    payload: local
  };
};
export const modificaSetorSelecionado = setor => {
  return {
    type: MODIFICASETORSELECIONADO,
    payload: setor
  };
};
export const modificaPrioridadeSelecionado = prioridade => {
  return {
    type: MODIFICAPRIORIDADESELECIONADO,
    payload: prioridade
  };
};
const modificaMotivoCancelamentoTelaAcoesAtendimentoLocal = (
  dispatch,
  mensagem
) => {
  dispatch({
    type: MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
    payload: mensagem
  });
};
export const modificaMotivoCancelamentoTelaAcoesAtendimento = mensagem => {
  return {
    type: MODIFICAMOTIVOCANCELAMENTOTELAACOESATENDIMENTO,
    payload: mensagem
  };
};

export const modificaAtendenteSelecionado = atendente => {
  return {
    type: MODIFICAATENDENTESELECIONADO,
    payload: atendente
  };
};
const zeraCadastroChamado = dispatch => {
  dispatch({
    type: ZERACADASTROCHAMADO,
    payload: ""
  });
};
const modificaArquivoCompletoParaUparLocal = (dispatch, value) => {
  dispatch({
    type: MODIFICAARQUIVOCOMOPLETOPARAUPAR,
    payload: value
  });
};
export const modificaArquivoCompletoParaUpar = (value) => {
  return {
    type: MODIFICAARQUIVOCOMOPLETOPARAUPAR,
    payload: value
  };
};
const modificaArquivoCompletoParaUparLocalNovoCadastro = (dispatch, value) => {
  dispatch({
    type: MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
    payload: value
  });
};
export const modificaArquivoCompletoParaUparNovoCadastro = (value) => {
  return {
    type: MODIFICAARQUIVOCOMOPLETOPARAUPARNOVOCADASTRO,
    payload: value
  };
};
export const zeraMensagemChamadoAlteradoSucesso = () => {
  return {
    type: VERIFICACHAMADOALTERADOSUCESSO,
    payload: ""
  };
};
export const zeraMensagemErrorAlterarChamado = () => {
  return {
    type: VERIFICAERRORTELAALTERARCHAMADO,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoEmAtendimento = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOEMATENDIMENTO,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoCancelado = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOCANCELADO,
    payload: ""
  };
};
export const zeraIndicaFimDaListaTOdos = () => {
  return {
    type: INDICAFIMDALISTA,
    payload: false
  };
};
export const zeraMensagemSemInternetChamadoTodos = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOTODOS,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoFechado = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOFECHADO,
    payload: ""
  };
};
export const zeraMensagemChamadoCadastradoSucesso = () => {
  return {
    type: CHAMADOCADASTRADOSUCESSO,
    payload: ""
  };
};
const zeraInformacoesCadastroCliente = dispatch => {
  dispatch({
    type: ZERAINFORMACOESCADASTROCLIENTE,
    payload: "zeraInformacoes"
  });
};
export const zeraInformacoesCadastroClienteExport = () => {
  return {
    type: ZERAINFORMACOESCADASTROCLIENTE,
    payload: "zeraInformacoes"
  }
};
export const zeraMensagemSemInternetAlteraCliente = () => {
  return {
    type: SEMCONEXAOINTERNETALTERACLIENTE,
    payload: ""
  };
};
export const zeraMensagemSemInternetChamadoAberto = () => {
  return {
    type: ZERAMENSAGEMSEMINTERNETCHAMADOABERTO,
    payload: ""
  };
};

export const zeraMensagemSemInternetTelaCadastroCliente = () => {
  return {
    type: SEMCONEXAOINTERNETCADASTRACLIENTE,
    payload: ""
  };
};
export const zeraMensagemSemInternetCadastraChamado = () => {
  return {
    type: SEMCONEXAOINTERNETCADASTRACHAMADO,
    payload: ""
  };
};

/* export const mensagemToastTelaConsultaCliente = (dispatch, mensagem) => {
  dispatch({
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: mensagem
  })
}; */

const mensagemToastTelaCOnsultaCliente = (dispatch, mensagem) => {
  dispatch({
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: mensagem
  });
};

export const alteraComentarioChat = (value) => {
  return {
    type: ALTERACOMENTARIOCHAT,
    payload: value
  };
};
export const zeraMensagemSemInternetConsultaCliente = () => {
  return {
    type: SEMCONEXAOINTERNETCONSULTACLIENTE,
    payload: ""
  };
};
export const zeraMensagemAlteradoSucesso = () => {
  return {
    type: MENSAGEMCADASTRACLIENTESUCESSOTELAALTERAR,
    payload: ""
  };
};

const ativaLoadingBotaoCadastrarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOCADASTRARCLIENTE,
    payload: true
  });
};
const desativaLoadingBotaoCadastrarCliente = dispatch => {
  dispatch({
    type: DESATIVALOADINGBOTAOCADASTRARCLIENTE,
    payload: false
  });
};
const mudaVisibilidadeModalCadastraClienteVersaoPremiumLocal = (
  dispatch,
  visible
) => {
  dispatch({
    type: MUDAVISIBILIDADEMODALCADASTRACLIENTEVERSAOPREMIUM,
    payload: visible
  });
};
const ativaLoadingBotaoCadastrarChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOCADASTRARCHAMADO,
    payload: true
  });
};
export const ativaLoadingBotaoCadastrarChamadoExport = value => {
  return {
    type: ATIVALOADINGBOTAOCADASTRARCHAMADO,
    payload: value
  };
};
const ativaLoadingBotaoAlterarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOALTERARCLIENTE,
    payload: true
  });
};
const desativaLoadingBotaoAlterarCliente = dispatch => {
  dispatch({
    type: ATIVALOADINGBOTAOALTERARCLIENTE,
    payload: false
  });
};

const ativaLoadingCarregarClienteTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
    payload: true
  });
};

const enviaClienteParaTelaRelatorioDetalhado = (dispatch, value) => {
  dispatch({
    type: ENVIACLIENTEPARATELARELATORIODETALHADO,
    payload: value
  });
};
const loadingClienteTelaRelatorioDetalhado = (dispatch, value) => {
  dispatch({
    type: LOADINGCLIENTETELARELATORIODETALHADO,
    payload: value
  });
};

const ativaDesativaLoadingCarregarClienteTelaChamadoTodos = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELACHAMADOTODOS,
    payload: value
  });
};
const ativaDesativaLoadingCarregarClienteTelaConsultaCliente = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELACONSULTACLIENTE,
    payload: value
  });
};
const desativaLoadingCarregarClienteTelaNovoChamado = dispatch => {
  dispatch({
    type: DESATIVALOADINGCARREGARCLIENTETELANOVOCHAMADO,
    payload: false
  });
};
const ativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
    payload: true
  });
};
const ativaLoadingCarregarSetorTelaNovoChamado = dispatch => {
  dispatch({
    type: ATIVALOADINGCARREGARSETORTELANOVOCHAMADO,
    payload: true
  });
};
const desativaLoadingCarregarAtendenteTelaNovoChamado = dispatch => {
  dispatch({
    type: DESATIVALOADINGCARREGARATENDENTETELANOVOCHAMADO,
    payload: false
  });
};
const mensagemCasdastraClienteError = (dispatch, error) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTEERROR,
    payload: error
  });
};
const mensagemCasdastraClienteErrorTelaAlterar = (dispatch, error) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTEERRORTELAALTERAR,
    payload: error
  });
};
const mensagemCasdastraClienteSucesso = (dispatch, sucesso) => {
  dispatch({
    type: MENSAGEMCADASTRACLIENTESUCESSO,
    payload: sucesso
  });
};
export const navegaTelaCadastraCliente = () => {
  return dispatch => {
    dispatch({
      type: ZERAMSGSUCESSOEERROCADASTRACLIENTE,
      payload: "zeraInformacoes"
    });
    // dispatch(NavigationActions.navigate({ routeName: "CadastroClientes" }));
  };
};
export const testeDrawer = () => {
  return dispatch => {
    // dispatch(NavigationActions.navigate({ routeName: "DrawerOpen" }));
  };
};
const modificaEnviandoComentario = (dispatch, valor) => {
  dispatch({
    type: MODIFICAUPANDOARQUIVOCHAT,
    payload: valor
  });
};
export const modificaMostrarMaisChamados = (value) => {
  return {
    type: MODIFICAMOSTRARMAISCHAMADOS,
    payload: value
  };
};
export const modificaRetornaTelaClienteParaNovoAtendimento = (value) => {
  return {
    type: MODIFICARETORNATELACLIENTESPARANOVOATENDIMENTO,
    payload: value
  };
};


const enviaFotoClienteParaReducer = (dispatch, arrayUser) => {
  dispatch({
    type: ENVIAFOTOCLIENTEPARAREDUCER,
    payload: arrayUser
  });
};

const enviaChamadoAberto = (dispatch, arrayChamado) => {
  dispatch({
    type: LISTACHAMADOABERTO,
    payload: arrayChamado
  });
};
const enviaChamadoEmAtendimento = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOEMATENDIMENTO,
    payload: arrayChamado
  });
};
const enviaChamadoFechado = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOFECHADO,
    payload: arrayChamado
  });
};
const enviaChamadoCancelado = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOCANCELADO,
    payload: arrayChamado
  });
};
const enviaChamadoTodos = (dispatch, arrayChamado) => {
  dispatch({
    type: ENVIACHAMADOTODOS,
    payload: arrayChamado
  });
};
const enviaUsuariosTelaAcoesAtendimento = (dispatch, arrayUsuario) => {
  dispatch({
    type: ENVIAUSUARIOSTELAACOESATENDIMENTO,
    payload: arrayUsuario
  });
};
export const enviaChamadoAberto2 = arrayChamado => {
  return {
    type: LISTACHAMADOABERTO,
    payload: arrayChamado
  };
};
const enviaUltimaReferenciaKey = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEY,
    payload: referencia
  });
};

const enviaUltimaReferenciaKeyTelaConsultaCliente = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYTELACONSULTACLIENTE,
    payload: referencia
  });
};

const enviaUltimaReferenciaKeyEmAtendimento = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYEMATENDIMENTO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyTodos = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYTODOS,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyFechado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYFECHADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyCancelado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYCANCELADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaKeyClienteChamadoTodos = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIAKEYCLIENTECHAMADOTODOS,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoEmAtendimento = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOEMATENDIMENTO,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoFechado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOFECHADO,
    payload: referencia
  });
};
const enviaUltimaReferenciaChamadoCancelado = (dispatch, referencia) => {
  dispatch({
    type: ENVIAULTIAMREFERENCIACHAMADOCANCELADO,
    payload: referencia
  });
};

const enviaClienteParaReducer = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCER,
    payload: arrayCliente
  });
};
const enviaAtendimentoParaReducer = (dispatch, arrayAtendimento) => {
  dispatch({
    type: ENVIAATENDIMENTOPARAREDUCER,
    payload: arrayAtendimento
  });
};
const enviaAtendimentoParaReducerChat = (dispatch, arrayAtendimento) => {
  dispatch({
    type: ENVIAATENDIMENTOPARAREDUCERCHAT,
    payload: arrayAtendimento
  });
};
const enviaAcoesAtendimentoParaReducer = (dispatch, array) => {
  dispatch({
    type: ENVIAACOESATENDIMENTOPARAREDUCER,
    payload: array
  });
};
const enviaAcoesAtendimentoParaReducerChat = (dispatch, array) => {
  dispatch({
    type: ENVIAACOESATENDIMENTOPARAREDUCERCHAT,
    payload: array
  });
};
const enviaClienteParaReducerTelaChamadoTodos = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTEPARAREDUCERTELACHAMADOTODOS,
    payload: arrayCliente
  });
};
const enviaClienteSectionedTelaCOnsultaCliente = (dispatch, arrayCliente) => {
  dispatch({
    type: ENVIACLIENTESECTIONEDTELACONSULTACLIENTE,
    payload: arrayCliente
  });
};

const enviaEmpresaSectionedTelaAlterarCliente = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ENVIAEMPRESASECTIONEDTELAALTERARCLIENTE,
    payload: arrayEmpresa
  });
};
const ativaDesativaLoadingCarregarClienteTelaAlterarCliente = (dispatch, arrayEmpresa) => {
  dispatch({
    type: ATIVADESATIVALOADINGCARREGARCLIENTETELAALTERARCLIENTE,
    payload: arrayEmpresa
  });
};

export const modificaVisibilidadeTelaChatOffline = value => {
  return {
    type: MODIFICAVISIBILIDADETELACHATOFFLINE,
    payload: value
  }
};

const enviaAtendenteParaReducer = (dispatch, arrayAtendente) => {
  dispatch({
    type: ENVIAATENDENTEPARAREDUCER,
    payload: arrayAtendente
  });
};
const enviaSetorParaReducer = (dispatch, arraySetor) => {
  dispatch({
    type: ENVIASETORPARAREDUCER,
    payload: arraySetor
  });
};
export const enviaFotoClienteParaReducer2 = arrayUser => {
  return {
    type: ENVIAFOTOCLIENTEPARAREDUCER,
    payload: arrayUser
  }
};

export const alteraValorSwitch = valor => {
  /*  console.log(valor) */
  return {
    type: ALTERAVALORSWITCHTELACLIENTE,
    payload: valor
  };
};
export const alteraValorSwitchNovoCadastro = valor => {
  /* console.log(valor) */
  return {
    type: ALTERAVALORSWITCHTELACLIENTENOVOCADASTRO,
    payload: valor
  };
};
export const alteValorMaisDetalhes = valor => {
  const valorAlterado = !valor;
  return {
    type: ALTERAMAISDETALHESTELANOVOCHAMADO,
    payload: valorAlterado
  };
};
export const alteraValorDigitoConsulta = digito => {
  return {
    type: ALTERAVALORDIGITOCONSULTA,
    payload: digito
  };
};
export const insereFotoCadastroClienteTelaFlatListConsulta = uri => {
  return {
    type: INSEREFOTOCADASTROCLIENTETELAFLATLISTCONSULTA,
    payload: uri
  };
};
export const insereFotoAlterarCliente = uri => {
  return {
    type: INSEREFOTOALTERARCLIENTE,
    payload: uri
  };
};

export const insereFotoCadastroCliente = uri => {
  return {
    type: INSEREFOTOCADASTROCLIENTE,
    payload: uri
  };
};
export const navegaTelaTeste = () => {
  return dispatch => {
    // dispatch(NavigationActions.navigate({ routeName: "TelaTeste2" }));
  };
};

export const navegaTelaConsultaCliente = () => {
  return dispatch => {
    //  dispatch(NavigationActions.navigate({ routeName: "ConsultaCliente" }));
  };
};
export const modificaNomeClienteTelaCadastroCliente = nome => {
  return {
    type: MODIFICANOMECLIENTETELACADASTROCLIENTE,
    payload: nome
  };
};
export const modificaNomeClienteTelaCadastroClienteNovoCadastro = nome => {
  return {
    type: MODIFICANOMECLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: nome
  };
};
export const modificaEmpresaClienteTelaCadastroCliente = empresa => {
  return {
    type: MODIFICAEMPRESACLIENTETELACADASTROCLIENTE,
    payload: empresa
  };
};
export const modificaEmpresaClienteTelaCadastroClienteNovoCadastro = empresa => {
  return {
    type: MODIFICAEMPRESACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: empresa
  };
};
export const modificaEmailClienteTelaCadastroCliente = email => {
  return {
    type: MODIFICAEMAILCLIENTETELACADASTROCLIENTE,
    payload: email
  };
};
export const modificaEmailClienteTelaCadastroClienteNovoCadastro = email => {
  return {
    type: MODIFICAEMAILCLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: email
  };
};


export const modificaEnderecoTelaCAdastroCliente = end => {
  return {
    type: MODIFICAENDERECOTELACADASTROCLIENTE,
    payload: end
  };
};
export const modificaProblemaEstaticoSelecionado = setor => {
  return {
    type: MODIFICAPROBLEMAESTATICOSELECIONADO,
    payload: setor
  };
};
export const modificaNomeProblemaEstaticoSelecionado = value => {
  return {
    type: MODIFICANOMEPROBLEMAESTATICOSELECIONADO,
    payload: value
  };
};
export const modificaEnderecoTelaCAdastroClienteNovoCadastro = end => {
  return {
    type: MODIFICAENDERECOTELACADASTROCLIENTENOVOCADASTRO,
    payload: end
  };
};
export const modificaTelefoneTelaCAdastroClienteNovoCadastro = tel => {
  return {
    type: MODIFICATELEFONETELACADASTROCLIENTENOVOCADASTRO,
    payload: tel
  };
};
export const modificaTelefoneTelaCAdastroCliente = tel => {
  return {
    type: MODIFICATELEFONETELACADASTROCLIENTE,
    payload: tel
  };
};

export const modificaSenhaClienteTelaCadastroCliente = senha => {
  return {
    type: MODIFICASENHACLIENTETELACADASTROCLIENTE,
    payload: senha
  };
};
export const modificaSenhaClienteTelaCadastroClienteNovoCadastro = senha => {
  return {
    type: MODIFICASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: senha
  };
};
export const modificaConfirmaClienteTelaCadastroClienteNovoCadastro = senha => {
  return {
    type: MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTENOVOCADASTRO,
    payload: senha
  };
};
export const modificaConfirmaClienteTelaCadastroCliente = senha => {
  return {
    type: MODIFICACONFIRMASENHACLIENTETELACADASTROCLIENTE,
    payload: senha
  };
};


export const alteraSetor = setor => {
  return {
    type: ALTERASETOR,
    payload: setor
  };
};
export const alteraPrioridade = prioridade => {
  return {
    type: ALTERAPRIORIDADE,
    payload: prioridade
  };
};
export const alteraLocalAtendimento = localAtendimento => {
  return {
    type: ALTERALOCALATENDIMENTO,
    payload: localAtendimento
  };
};
export const modificaResolucaoProblema = value => {
  return {
    type: MODIFICARESOLUCAOPROBLEMA,
    payload: value
  };
};
export const alteraDescricaoChamado = descricao => {
  return {
    type: ALTERADESCRICAOCHAMADO,
    payload: descricao
  };
};
export const alteraDescricaoChamadoAudio = (descricaoAudio, descricao) => {
  const descricaoFinal = descricao + " " + descricaoAudio;
  return {
    type: ALTERADESCRICAOCHAMADO,
    payload: descricaoFinal
  };
};
export const alteraDescricaoComentario = (descricaoAudio, descricao) => {
  const descricaoFinal = descricao + " " + descricaoAudio;
  return {
    type: MODIFICAINPUTCOMENTARIO,
    payload: descricaoFinal
  };
};

export const ativaDesativaLoadingRefreshControlChamadoAberto = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOABERTO,
    payload: value
  });
};

export const ativaDesativaLoadingRefreshControlChamadoEmAtendimento = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOEMATENDIMENTO,
    payload: value
  });
};

export const ativaDesativaLoadingRefreshControlChamadoFechado = (
  dispatch,
  value
) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOFECHADO,
    payload: value
  });
};
const ativaDesativaLoadingEnviandoAudioChat = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
    payload: value
  });
};
export const ativaDesativaLoadingEnviandoAudioChatExport = value => {
  return {
    type: ATIVADESATIVALOADINGENVIANDOAUDIOCHAT,
    payload: value
  };
};
const ativaDesativaLoadingRefreshControlChamadoCancelado = (dispatch, value) => {
  dispatch({
    type: ATIVADESATIVALOADINGREFRESHCONTROLCHAMADOCANCELADO,
    payload: value
  });
};

export const modificaArquivoUpado1 = status => {
  return {
    type: MODIFICAARQUIVOUPADO1,
    payload: status
  };
};
export const modificaArquivoUpado2 = status => {
  return {
    type: MODIFICAARQUIVOUPADO2,
    payload: status
  };
};
export const modificaArquivoUpado3 = status => {
  return {
    type: MODIFICAARQUIVOUPADO3,
    payload: status
  };
};

export const modificaContagemArquivo = arquivo => {
  return {
    type: MODIFICACONTAGEMARQUIVO,
    payload: arquivo
  };
};
export const modificaTamanhoArquivo1 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO1,
    payload: tamanho
  };
};
export const modificaTamanhoArquivo2 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO2,
    payload: tamanho
  };
};
export const modificaTamanhoArquivo3 = tamanho => {
  return {
    type: MODIFICATAMANHOARQUIVO3,
    payload: tamanho
  };
};
export const modificaVisibilidadeBotaoAddVencimento = value => {
  return {
    type: MODIFICAVISIBLIDADEBOTAOADDVENCIMENTO,
    payload: value
  };
};

function calculaDias2(date1, date2) {
  //formato do brasil 'pt-br'
  // moment.locale('pt-br');
  //setando data1
  let data1 = moment(date1, "DD/MM/YYYY");
  //setando data2
  let data2 = moment(date2, "DD/MM/YYYY");
  //tirando a diferenca da data2 - data1 em dias
  // let diff = data2.diff(data1, 'days');
  let diff = moment.duration(data2.diff(data1)).asDays();

  return diff;
}
function calculaDias(date1, date2) {
  //formato do brasil 'pt-br'
  // moment.locale('pt-br');
  //setando data1
  let data1 = moment(date1, "DD/MM/YYYY");
  //setando data2
  let data2 = moment(date2, "DD/MM/YYYY");
  //tirando a diferenca da data2 - data1 em dias
  let diff = data2.diff(data1, "days");

  return diff;
}

function retornaDataAtual() {
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY");
  return dataAtual;
}
function retornaDataHoraAtual() {
  let dataAtual = moment().toDate();
  dataAtual = moment(dataAtual).format("DD/MM/YYYY HH:mm");
  return dataAtual;
}
function retornaHoraAtual() {
  let dataAtual = moment().toDate();
  let horaAtual = moment(dataAtual).format("HH:mm");
  return horaAtual;
}



function isNumber(val) {
  return typeof val === "number"
}
const AsyncStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
};