
import moment from 'moment';
import axios from 'axios';
import { moment as momentz } from 'moment-timezone';
import React from 'react';
import firebase from '../servicos/FirebaseConnection';
import { jsPDF } from "jspdf";
import _ from 'lodash';
import html2pdf from "html2pdf.js";
//import html2pdf from "html2pdf.js";
import XLSX from 'xlsx';
import { Howl, Howler } from 'howler';
import soundnotification from '../imagens/notificationsound.mp3';
import { listaChamadoParaTelaAcoesChamadoExport } from './ChamadoAction'

import {
    MODIFICACORBORDALISTATAREFAS,
    MODIFICAFILTROPORDATADASHBOARD,
    LOADINGTELALOGIN,
    ENVIAATENDIMENTOSNOTOTALDASHBOARD, ATIVADESATIVALOADINGTELADASHBOARD,
    ENVIACLIENTESNOTOTALDASHBOARD, ENVIATENDIMENTOSHOJEDASHBOARD, ENVIAATENDIMENTOSDESTASEMANADASHBOARD,
    ATIVADESATIVALOADINGREFRESHCONTROL, ENVIATENDIMENTOSPORSTATUSDASHBOARD, ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD,
    ENVIATOTALATENDIMENTOPORPRIORIDADE, ENVIACLIENTEMAISABRIRAMCHAMADOS, ENVIASETORESMAISABRIRAMCHAMADOS,
    ENVIATEMPORESOLUCAOCHAMADOS, ENVIATOALTENDIMENTOSPORLOCALDASHBOARD, ENVIATENDIMENTOSPORLOCALDASHBOARD,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAO, ENVIASEMCONEXAOINTERNETDASHBOARD, ENVIASEEHVERSAOPREMIUM,
    VERIFICAINTERNETTELAVIP, LOADINGREFRESHCONTROLVIP, LOADINGTELAVIP, ENVIAPLANOMENSAL, ENVIAPLANOTRIMESTRAL,
    ENVIAPLANOANUAL, ENVIADATAVALIDADEVERSAOPREMIUM, ENVIAPERIODOPERSONALIZADO, MODIFICADATAINICIALCONSULTADASHBOARD,
    MODIFICADATAFINALCONSULTADASHBOARD, MENSAGEMPERIODOPERSONALIZADOERROR, ENVIADATACOMPRAVERSAOPREMIUM, ENVIAPERMISSOESACESSARTELASATENDENTE,
    ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS, ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO,
    ENVIAPERIODOPERSONALIZADORELATORIODETALHADI, MODIFICALOADINGCONFIGURACOESEMPRESA,
    MODIFICAMODALDUVIDASFREQUENTES, ENVIAAVISOPARATELADASHBOARD,
    ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO, ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO, ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO,
    ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO, ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO,
    ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO, MODIFICASETORRELATORIODETALHADO,
    MODIFICAATENDENTERELATORIODETALHADO, MODIFICACLIENTETELARELATORIODETALHADO, ZERADATAINICIALFILTRORELATORIODETALHADO,
    ZERADATAFINALFILTRORELATORIODETALHADO, MODIFICAFILTROTELARELATORIODETALHADO, MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO,
    MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO, MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO,
    MODIFICADATAFINALRELATORIODETALHADO, MODIFICADATAINICIALRELATORIODETALHADO, MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
    ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS, ENVIASEMCONEXAOINTERNETRELATORIOGERAL, ATIVADESATIVALOADINGRELATORIOGERAL,
    ENVIAPERIODOPERSONALIZADORELATORIOGERAL, ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
    ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL, ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL, ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL,
    ENVIANOTAMEDIAAVALIACAORELATORIOGERAL, ZERADATAFINALFILTRORELATORIOGERAL, ZERADATAINICIALFILTRORELATORIOGERAL,
    MODIFICADATAINICIALRELATORIOGERAL, MODIFICADATAFINALRELATORIOGERAL, ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL, ENVIATEXT2MODALINF, ENVIATEXT1MODALINF, ENVIATEXTCABECALHOMODALINF,
    MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO, ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO,
    ENVIATEXT1MODALINFRELATORIODETALHADO, ENVIATEXT2MODALINFRELATORIODETALHADO, ENVIACHAMADOSDETALHADOSPARADOWNLOAD,
    MODIFICAMENSAGEMGERANDORELATORIO, ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD, MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
    ENVIADATAVALIDADEATUALPREMIUM, ENVIANUMEROPEDIDOCOMPRAPREMIUM, ENVIAPLANOPREMIUM, ENVIAPLATAFORMAPREMIUM,
    ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM, ENVIASTATUSASSINATURAPREMIUM, ATIVADESATIVALOADINGVERSAOPREMIUM,
    ENVIATEXTSTATUSASSINATURA, ENVIACORTEXTSTATUSASSINATURA, MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
    MODIFICAVISIBLEMODALASSINATURASUSPENSA, MODIFICAVISIBLEMODALASSINATURAPAUSADA, MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU,
    VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE, VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO, VERIFICAAGRUPARATENDENTESETOR,
    ENVIAIDENTIFICADORUNICACOMPRA, ENVIAEMAILASSINANTE, ENVIANOMEASSINANTE, ENVIAPREMIUMVERSAOGRATIS, ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS,
    ALTERAINFORMACAOCONTAPRINCIPALATENDENTE, VERIFICACRIARCHAMADOPROBLEMAESTATICO, ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL,
    MODIFICATOASTACOESATNEDIMENTO, ENVIASEATENDENTEEHADMINISTRADOR, MODIFICAFILTROSELECIONADORELATORIODETALHADO,
    ENVIAATENDIMENTOABERTO, ENVIAATENDIMENTOEMATENDIMENTO, ENVIAATENDMENTOCANCELADO, ENVIAATENDIMENTOFECHADO,
    ENVIATAREFAPARAREDUCER, MODIFICAMODALSAIR, ENVIAKEYUSUARIOPERFIL, CARREGAEMAILPERFIL, CARREGANOMEPERFIL,
    CARREGAIMAGEMPERFIL,
    MODIFICACORFUNDOPRINCIPAL, MODIFICACORFUNDOSECUNDARIO, MODIFICACORFUNDOITENSSELECIONAVEIS, MODIFICACORFUNDOBOTAOPRINCIPAL,
    MODIFICACORFUNDOBOTAOSECUNDARIO, MODIFICACORLETRAPADRAO, MODIFICACORLETRAPRINCIPAL, MODIFICACORLETRASECUNDARIA,
    MODIFICACORLETRADESCRICAODOSCAMPOS, MODIFICACORFUNDOMENU, MODIFICACORLETRAMENU, MODIFICALOGOTIPOTELACONFIGURACAO,
    ALTERANUMEROATENDIMENTO, ALTERAKEYCHAMADO, MODIFICANOMEROTANOHEADER, ENVIANOTIFICACOESPARAREDUCER,
    ENVIATOTALNOTIFICACOESPARAREDUCER, ATIVADESATIVARENOVARPLANO, MODIFICAMOSTRAVERSAOEXPIRANDO,
    MODIFICALICENSAWEBAPP, ATIVADESATIVALOADINGTELACONSULTACLIENTE,
    ENVIAEMPRESAPARAREDUCER, ENVIASENHAPARAREDUCER, ENVIACONFIRMASENHAPARAREDUCER,
    CARREGAEMAILCLIENTEPERFIL, ENVIAPROBLEMASESTATICOSPARAREDUCER, ENVIASETORPARAREDUCER, MODIFICANOMEEMPRESACONFIGURAEMPRESA, MODIFICAATENDIMENTOONLINEVISIBLE,
    MODIFICATELAATUALCHAT, MODIFICAKEYATENDIMENTOCHAT, MODIFICAPOSICAOATUALFILA, MODIFICAVISIBILIDADEATENDIMENTOCHATONLINEOFFLINE,

    MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, ALTERAVALORCHECKSEGUNDAEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA, MODIFICAHORARIOOPERACAOQUARTAFEIRA, MODIFICAHORARIOOPERACAOQUINTAFEIRA,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA, MODIFICAHORARIOOPERACAOSABADOFEIRA, MODIFICAHORARIOOPERACAODOMINGOFEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA2, MODIFICAHORARIOOPERACAOQUARTAFEIRA2, MODIFICAHORARIOOPERACAOQUINTAFEIRA2,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA2, MODIFICAHORARIOOPERACAOSABADOFEIRA2, MODIFICAHORARIOOPERACAODOMINGOFEIRA2,
    ALTERAVALORCHECKDOMINGOEIRA, ALTERAVALORCHECKSABADOEIRA, ALTERAVALORCHECKSEXTAEIRA, ALTERAVALORCHECKQUINTAEIRA,
    ALTERAVALORCHECKQUARTAEIRA, ALTERAVALORCHECKTERCAEIRA, ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT,
    MODIFICAFUSOHORARIOSELECIONADO, MODIFICACORPRINCIPALCHAT, UPDTEISMAINTENANCE, UPDTELASTVERSIONAVAILABLE
} from './types';
import { toastr } from 'react-redux-toastr';

export const inserePlanoPremium = (plano, idSubscription, descricaoPlano) => {
    return dispatch => {
        /* console.log(plano, idSubscription) */
        loadingTelalVIP(dispatch, true);
        let dataAtual = retornaDataAtual();
        let dataValidadePlano, dataValidadeAtual;
        let licensawebouapp = 0;

        //licensawebou app == 1 (app) == 2 (web) == 3 (webapp)
        if (plano == 'mensalapp' || plano == 'mensalweb' || plano == 'mensalwebapp') {
            dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
        } else {
            dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'years').format('DD/MM/YYYY');
        }
        if (plano == 'mensalapp') {
            licensawebouapp = 1;
        } else if (plano == 'anualapp') {
            licensawebouapp = 1;
        } else if (plano == 'mensalweb') {
            licensawebouapp = 2;
        } else if (plano == 'anualweb') {
            licensawebouapp = 2;
        }
        else if (plano == 'mensalwebapp') {
            licensawebouapp = 3;
        } else {
            licensawebouapp = 3;

        }
        let quantidadeAtendenteLiberado = 999;

        /*   for (let i = 1; i <= 50; i++) {
              if (`mensal${i}atendente` == plano) {
                  quantidadeAtendenteLiberado = i;
                  break;
              }
          } */
        console.log('qtd atendente')
        console.log(quantidadeAtendenteLiberado)
        /*  if (plano == 'licenca_mensal_dropdesk' || plano == 'dropdeskADMIN123.licencamensal') {
             dataValidadePlano = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
         }
         if (plano == 'licenca_trimestral_dropdesk' || plano == 'dropdeskADMIN123.licencatrimestral') {
             dataValidadePlano = moment(dataAtual, 'DD/MM/YYYY').add(3, 'months').format('DD/MM/YYYY');
         }
         if (plano == 'licenca_anual_dropdesk' || plano == 'dropdeskADMIN123.licencaanual') {
             dataValidadePlano = moment(dataAtual, 'DD/MM/YYYY').add(12, 'months').format('DD/MM/YYYY');
         } */
        /*  if (plano == 'android.test.purchased') {
             dataValidadePlano = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
         } */


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({ dataCompra: dataAtual, premium: 1, dataValidoAte: '12/12/2099', plataforma: 'web', qtdAtendentesLiberados: quantidadeAtendenteLiberado, plano: descricaoPlano, statusAssinatura: '', dataValidadeAtual, identificadorUnicoDaCompra: idSubscription, uidComprador: uidComprador, licensawebouapp }).then(
                    () => {

                        firebase.database().ref(`comprasNoAplicativo`).push({ chaveUnica: chaveUnica, dataCompra: dataAtual, premium: 1, dataValidoAte: '12/12/2099', identificadorUnicoDaCompra: idSubscription, plano: descricaoPlano, keyEmpresa, plataforma: 'web', uidComprador: uidComprador, licensawebouapp }).then(
                            () => {
                                loadingTelalVIP(dispatch, false);
                            })

                    })


            })
        })
    }
}

export const inserePlanoPremiumBoleto = (plano, idSubscription, descricaoPlano, renovacaoBoleto2, dataValidoAte) => {
    return dispatch => {
        console.log(plano, idSubscription)
        //  loadingTelalVIP(dispatch, true);
        let dataAtual = retornaDataAtual();
        let dataValidadePlano, dataValidadeAtual;
        let licensawebouapp = 0;
        let renovacaoBoleto = false;
        if (renovacaoBoleto2 == true) {
            renovacaoBoleto = true;
        }
        console.log(renovacaoBoleto)

        //licensawebou app == 1 (app) == 2 (web) == 3 (webapp)
        if (plano == 'mensalapp' || plano == 'mensalweb' || plano == 'mensalwebapp') {
            dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'months').format('DD/MM/YYYY');
        } else {
            dataValidadeAtual = moment(dataAtual, 'DD/MM/YYYY').add(1, 'years').format('DD/MM/YYYY');
        }
        if (plano == 'mensalapp') {
            licensawebouapp = 1;
        } else if (plano == 'anualapp') {
            licensawebouapp = 1;
        } else if (plano == 'mensalweb') {
            licensawebouapp = 2;
        } else if (plano == 'anualweb') {
            licensawebouapp = 2;
        }
        else if (plano == 'mensalwebapp') {
            licensawebouapp = 3;
        } else {
            licensawebouapp = 3;

        }


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                })
                firebase.database().ref(`comprasNoAplicativo`).push({ chaveUnica: chaveUnica, dataCompra: dataAtual, premium: 0, identificadorUnicoDaCompra: idSubscription, plano: descricaoPlano, keyEmpresa, plataforma: 'web', uidComprador: uidComprador, licensawebouapp, statusAssinatura: 'boleto', renovacaoBoleto, dataValidoAte }).then(
                    () => {
                        //loadingTelalVIP(dispatch, false);
                    })
            })
        })
    }
}
export const cancelaAssinatura = () => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let uidComprador = firebase.auth().currentUser.uid;

            firebase.database().ref(`${chaveUnica}/empresa`).once("value").then((snapshot) => {
                let keyEmpresa = '';
                let dataValidade = '';
                snapshot.forEach(childItem => {
                    keyEmpresa = childItem.key;
                    dataValidade = childItem.val().dataValidadeAtual;
                })
                firebase.database().ref(`${chaveUnica}/empresa/${keyEmpresa}`).update({ dataValidoAte: dataValidade }).then(
                    () => {

                    })


            })
        })
    }
}
export const exportaAtendimentosParaExcelPdfVisaoGeral = (arrayChamados, operacao, modoExportacao) => {
    return dispatch => {
        console.log(arrayChamados, operacao, modoExportacao)
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando Relatório', 'Por favor aguarde...');
            console.log(chaveUnica)

            let results = [];
            if (operacao == 'clientes') {
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].clientes !== undefined && arrayChamados[i].clientes !== null) {
                        results.push(arrayChamados[i].clientes)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nomeCliente: responses[i].val() == null ? "" : responses[i].val().nome,
                            empresa: responses[i].val() == null ? "" : responses[i].val().empresa

                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Clientes que MAIS abriram Atendimentos!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Cliente', 'Empresa'];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Clientes', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'atendentes') {

                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].atendentes !== undefined && arrayChamados[i].atendentes !== null) {
                        results.push(arrayChamados[i].atendentes)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/usuarios/${cliente}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nome: responses[i].val() == null ? "" : responses[i].val(),
                        });
                        aux++;
                    }
                    console.log('----------------no chamado--------------')
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendentes que MAIS atenderam!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Atendente'];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Atendentes', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });
            }
            if (operacao == 'setores') {


                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].setores !== undefined && arrayChamados[i].setores !== null) {
                        results.push(arrayChamados[i].setores)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].key;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/setores/${cliente}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseCliente);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i++) {
                        chamados.push({
                            quantidadeAtendimento: results[i].value,
                            nome: responses[i].val() == null ? "" : responses[i].val()

                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Setores que MAIS abriram Atendimentos!']
                    let colunas = ['Quantidade de Atendimentos', 'Nome do Setor'];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDFVisaoGeral('Setores', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });
            }
            if (operacao == 'problemas') {

                console.log(arrayChamados)
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].problemas !== undefined && arrayChamados[i].problemas !== null) {
                        results.push(arrayChamados[i].problemas)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let chamados = [];
                /*    for (let i = 0; i < results.length; i++) {
                       let cliente = results[i].key;
                       promisseCliente = firebase.database().ref(`${chaveUnica}/setores/${cliente}/nome`).once("value");
                       arrayChamadoPromisse.push(promisseCliente);
                   } */
                let aux = 0;

                console.log(results)
                for (let i = 0; i < results.length; i++) {
                    chamados.push({
                        quantidadeAtendimento: results[i].value,
                        problema: results[i].key

                    });
                    aux++;
                }
                console.log(chamados)
                let colunaPrincipal = ['Os atendimentos ABAIXO são provenientes do relatório de Problemas Estaticos que mais abriram atendimentos!']
                let colunas = ['Quantidade de Atendimentos', 'Descrição Problema'];
                if (modoExportacao == 'EXCEL') {
                    console.log('modo EXCEL')
                    exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, chamados, dispatch);
                } else {
                    console.log('modo PDF')
                    exportRelatorioAtendimentosParaPDFVisaoGeral('Problemas', chamados, dispatch);
                }

                //enviaChamadoAberto(dispatch, chamados);

            }
        })
    }
}
export const exportaAtendimentosParaExcelPdf = (arrayChamados, operacao, modoExportacao) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando Relatório', 'Por favor aguarde...')
            console.log(chaveUnica)
            console.log(arrayChamados)
            console.log(modoExportacao)
            let results = [];
            if (operacao == 'status') {
                console.log('status')
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].status !== undefined && arrayChamados[i].status !== null) {
                        results.push(arrayChamados[i].status)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por STATUS']
                    let colunas = ['Numero do Atendimento', 'Descricão do Problema', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDF('STATUS', chamados, dispatch);
                    }

                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'prioridade') {
                console.log('prioridade')
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].prioridade !== undefined && arrayChamados[i].prioridade !== null) {
                        results.push(arrayChamados[i].prioridade)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por PRIORIDADE']
                    let colunas = ['Numero do Atendimento', 'Descricão do Problema', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDF('PRIORIDADE', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'local') {
                console.log('local')
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].local !== undefined && arrayChamados[i].local !== null) {
                        results.push(arrayChamados[i].local)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dia(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por LOCAL']
                    let colunas = ['Numero do Atendimento', 'Descricão do Problema', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDF('LOCAL', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
            if (operacao == 'solucao') {
                console.log('solucao')
                for (let i = 0; i < arrayChamados.length; i++) {
                    if (arrayChamados[i].solucao !== undefined && arrayChamados[i].solucao !== null) {
                        results.push(arrayChamados[i].solucao)
                    }
                }

                let arrayChamadoPromisse = [];
                let promisseCliente;
                let promisseAtendente;
                let promisseSetor;
                let chamados = [];
                for (let i = 0; i < results.length; i++) {
                    let cliente = results[i].cliente;
                    let atendente = results[i].atendente;
                    let setor = results[i].setor;
                    promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/`).once("value");
                    promisseAtendente = firebase.database().ref(`${chaveUnica}/usuarios/${atendente}/nome`).once("value");
                    promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once("value");
                    arrayChamadoPromisse.push(promisseAtendente, promisseCliente, promisseSetor);
                }
                let aux = 0;
                console.log('Antes do promisse all')
                Promise.all(arrayChamadoPromisse).then(responses => {
                    console.log('Dentro do promisse all')
                    for (let i = 0; i < responses.length; i += 3) {
                        chamados.push({
                            cliente: responses[i + 1].val() == null ? "" : responses[i + 1].val().nome,
                            empresa: responses[i + 1].val() == null ? "" : responses[i + 1].val().empresa,
                            setor: responses[i + 2].val() == null ? "" : responses[i + 2].val(),
                            atendente: responses[i].val() == null ? "" : responses[i].val(),
                            dataHoraCancelamento: results[aux].dataHoraCancelamento == undefined ? "" : results[aux].dataHoraCancelamento,
                            dataHoraFechamento: results[aux].dataHoraFechamento == undefined ? "" : results[aux].dataHoraFechamento,
                            dataVencimento: results[aux].dataVencimento == "Não informado" ? "" : results[aux].dataVencimento,
                            local: results[aux].local == "Não informado" ? "" : results[aux].local,
                            periodoResolucaoChamado: results[aux].periodoResolucaoChamado == undefined ? "" : results[aux].periodoResolucaoChamado + ' dias(s)',
                            dataHoraAbertura: results[aux].dataHoraAbertura,
                            status: results[aux].status,
                            prioridade: results[aux].prioridade === "Não informado" ? "" : results[aux].prioridade,
                            descricaoProblema: results[aux].descricaoProblema,
                            numeroChamado: results[aux].numeroChamado,
                            tempoAtendimentoGasto: results[aux].tempoGasto == 0 ? "" : results[aux].tempoGasto + ' min.',
                            lastmessageChatCount: results[aux].lastmessageChatCount == undefined ? 0 : results[aux].lastmessageChatCount,
                            lastmessageChatType: results[aux].lastmessageChatType == undefined ? 'mensagem' : results[aux].lastmessageChatType,
                        });
                        aux++;
                    }
                    console.log(chamados)
                    let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendimento Por PERIODO DE RESOLUÇÃO']
                    let colunas = ['Numero do Atendimento', 'Descricão do Problema', 'Status', 'Cliente'
                        , 'Empresa', 'Setor', 'Atendente(Técnico)', 'Data Abertura Atendimento', 'Prioridade'
                        , 'Local', 'Data Vencimento Atendimento', 'Data Fechamento Atendimento',
                        'Data Cancelamento Atendimento', 'Período Resolução Chamado', 'Tempo Atendimento Gasto',];
                    if (modoExportacao == 'EXCEL') {
                        console.log('modo EXCEL')
                        exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, chamados, dispatch);
                    } else {
                        console.log('modo PDF')
                        exportRelatorioAtendimentosParaPDF('PERÍODO RESOLUÇÃO CHAMADO', chamados, dispatch);
                    }
                    //enviaChamadoAberto(dispatch, chamados);
                });

            }
        })
    }
}
function exportRelatorioAtendimentosParaPDF(operacao, chamados, dispatch) {

    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        console.log(dataAtual)

        let mytable =
            '<table border="1" borderColor="#CCC">' +
            '<caption>Relatório de Atendimentos Por ' + operacao + '</caption>' +
            '<th style="background-color:#A9A9A9">Atendimento</th>' +
            '<th style="background-color:#A9A9A9">Problema</th>' +
            '<th style="background-color:#A9A9A9">Status</th>' +
            '<th style="background-color:#A9A9A9">Cliente</th>' +
            '<th style="background-color:#A9A9A9">Empresa</th>' +
            '<th style="background-color:#A9A9A9">Setor</th>' +
            '<th style="background-color:#A9A9A9">Atendente</th>' +
            '<th style="background-color:#A9A9A9">Abertura</th>' +
            '<th style="background-color:#A9A9A9">Prioridade</th>' +
            '<th style="background-color:#A9A9A9">Local</th>' +
            '<th style="background-color:#A9A9A9">Vencimento</th>' +
            '<th style="background-color:#A9A9A9">Fechamento</th>' +
            '<th style="background-color:#A9A9A9">Cancelamento</th>' +
            '<th style="background-color:#A9A9A9">Periodo Resolução</th>' +
            '<th style="background-color:#A9A9A9">Tempo Atend. Gasto</th>' +
            '<tr>';
        let aux = 1;
        for (let i = 0; i < chamados.length; i++) {
            if (aux % 2 == 0 && i !== 0) {
                mytable += "<tr> <td style=background-color:#CCC>#" + chamados[i].numeroChamado + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].descricaoProblema + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].status + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].cliente + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].empresa + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].setor + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].atendente + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraAbertura + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].prioridade + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].local + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataVencimento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraFechamento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].dataHoraCancelamento + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].periodoResolucaoChamado + "</td>" +
                    "<td style=background-color:#CCC>" + chamados[i].tempoAtendimentoGasto + "</td> </tr>";
            } else {
                mytable += "<tr> <td>#" + chamados[i].numeroChamado + "</td>" +
                    "<td>" + chamados[i].descricaoProblema + "</td>" +
                    "<td>" + chamados[i].status + "</td>" +
                    "<td>" + chamados[i].cliente + "</td>" +
                    "<td>" + chamados[i].empresa + "</td>" +
                    "<td>" + chamados[i].setor + "</td>" +
                    "<td>" + chamados[i].atendente + "</td>" +
                    "<td>" + chamados[i].dataHoraAbertura + "</td>" +
                    "<td>" + chamados[i].prioridade + "</td>" +
                    "<td>" + chamados[i].local + "</td>" +
                    "<td>" + chamados[i].dataVencimento + "</td>" +
                    "<td>" + chamados[i].dataHoraFechamento + "</td>" +
                    "<td>" + chamados[i].dataHoraCancelamento + "</td>" +
                    "<td>" + chamados[i].periodoResolucaoChamado + "</td>" +
                    "<td>" + chamados[i].tempoAtendimentoGasto + "</td> </tr>";
            }
            aux++
        }
        console.log('depois do for')
        console.log(mytable)
        mytable += " </table>";
        console.log('fim')
        console.log(mytable)
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'landscape', unit: 'pt', format: 'a4' },
            pagebreak: { mode: ['css'], avoid: 'tr' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        /*    html2pdf().from(mytable).set(opt).toPdf().output('blob').then(function (pdfBlob) {
               window.open(URL.createObjectURL(pdfBlob));
           }); */


    } catch (err) {
        alert(err);
    }


    //  XLSX.writeFile(wb, "export-demo.xlsx")
}
function exportRelatorioAtendimentosParaExcel(colunaPrincipal, colunas, linhas, dispatch, modoSalvarRelatorioLocalOuEmail) {

    //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
    console.log(dataAtual)

    //colunas do excel, recebe array com colunas
    //let users = [["First Name", "Last Name", "Age"]]
    let users = [colunaPrincipal, colunas]
    //valores em array
    linhas.forEach((atendimento) => {
        let userArray = [
            atendimento.numeroChamado, atendimento.descricaoProblema, atendimento.status, atendimento.cliente,
            atendimento.empresa, atendimento.setor, atendimento.atendente, atendimento.dataHoraAbertura,
            atendimento.prioridade, atendimento.local, atendimento.dataVencimento, atendimento.dataHoraFechamento,
            atendimento.dataHoraCancelamento, atendimento.periodoResolucaoChamado, atendimento.tempoAtendimentoGasto
        ]
        users.push(userArray)
    })
    console.log(users)
    const wb = XLSX.utils.book_new()

    const wsAll = XLSX.utils.aoa_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
    const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
    console.log(wbout)
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, `relatorio${dataAtual}.xlsx`);
}
function exportRelatorioAtendimentosParaExcelVisaoGeral(colunaPrincipal, colunas, linhas, dispatch, modoSalvarRelatorioLocalOuEmail) {
    console.log(colunaPrincipal, colunas, linhas, dispatch, modoSalvarRelatorioLocalOuEmail)

    //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
    console.log(dataAtual)

    //caminho do arquivo pasta download + nome com a data e hora

    //colunas do excel, recebe array com colunas
    //let users = [["First Name", "Last Name", "Age"]]
    let users = [colunaPrincipal, colunas]
    console.log(colunas)
    console.log(colunas.length)
    //se colunas.length for igual a 3 é porque é clientes, se nao for eh setor ou atendente
    if (colunas.length == 3) {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidadeAtendimento, atendimento.nomeCliente, atendimento.empresa
            ]
            users.push(userArray)
        })
    } else {
        linhas.forEach((atendimento) => {
            let userArray = [
                atendimento.quantidadeAtendimento, atendimento.nome
            ]
            users.push(userArray)
        })
    }

    const wb = XLSX.utils.book_new()
    console.log(users)

    const wsAll = XLSX.utils.aoa_to_sheet(users)
    XLSX.utils.book_append_sheet(wb, wsAll, "All Users")
    const wbout = XLSX.write(wb, { type: 'binary', bookType: "xlsx" });
    const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }));
    download(url, `relatorio${dataAtual}.xlsx`);

}
function exportRelatorioAtendimentosParaPDFVisaoGeral(operacao, chamados, dispatch, modoSalvarRelatorioLocalOuEmail) {
    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        console.log(dataAtual)

        let mytable;
        if (operacao == 'Clientes') {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<th style="background-color:#A9A9A9">Empresa</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].nomeCliente + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].empresa + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].nomeCliente + "</td>" +
                        "<td>" + chamados[i].empresa + "</td>" +
                        "</tr>";
                }
                aux++
            }
        } else if (operacao == 'Problemas') {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' Estáticos com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].problema + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].problema + "</td>" +
                        "</tr>";
                }
                aux++
            }
        }
        else {
            mytable =
                '<table border="1" borderColor="#CCC">' +
                '<caption>Relatório de ' + operacao + ' com mais Atendimentos</caption>' +
                '<th style="background-color:#A9A9A9">Qtd Atendimento</th>' +
                '<th style="background-color:#A9A9A9">Nome</th>' +
                '<tr>';
            let aux = 1;
            for (let i = 0; i < chamados.length; i++) {

                if (aux % 2 == 0 && i !== 0) {
                    mytable += "<tr> <td style=background-color:#CCC>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td style=background-color:#CCC>" + chamados[i].nome + "</td>" +
                        "</tr>";
                } else {
                    mytable += "<tr> <td>" + chamados[i].quantidadeAtendimento + "</td>" +
                        "<td>" + chamados[i].nome + "</td>" +
                        "</tr>";
                }
                aux++
            }
        }
        console.log('depois do for')
        console.log(mytable)
        mytable += " </table>";
        console.log('fim')
        console.log(mytable)
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'landscape', unit: 'pt', format: 'a4' },
            pagebreak: { mode: ['css'], avoid: 'tr' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        /*    html2pdf().from(mytable).set(opt).toPdf().output('blob').then(function (pdfBlob) {
               window.open(URL.createObjectURL(pdfBlob));
           }); */

    } catch (err) {
        alert(err);
    }


    //  XLSX.writeFile(wb, "export-demo.xlsx")
}
export const listaAtendimentosRelatorioGeralPersonalizado = (dataInicial2, dataFinal2, valorReferencia) => {
    return dispatch => {
        let dataInicial, dataFinal;
        if (valorReferencia == 0) {
            //valor Personalizado
            dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
            dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
        } else {
            //valor Inicial
            let dataHojeWeb = moment().toDate();
            let dataHojeEnviaDatePicker = moment().toDate();
            dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);
            dataHojeWeb = moment(dataHojeWeb).format('DD/MM/YYYY');

            dataInicial = dataHojeWeb;
            dataFinal = dataHojeWeb;
            dispatch({ type: MODIFICADATAINICIALRELATORIOGERAL, payload: dataHojeEnviaDatePicker });
            dispatch({ type: MODIFICADATAFINALRELATORIOGERAL, payload: dataHojeEnviaDatePicker });
        }
        console.log(dataInicial, dataFinal, valorReferencia)
        let arrayChamadosComClientes = [];
        let arrayChamadosComAtendentes = [];
        let arrayChamadosComSetores = [];
        let arrayChamadosComProblemasEstaticos = [];
        let tempoMedioParaSolucaoAtendimentos = 0;
        let tempoParaSolucaoAtendimentos = [];
        let atendimento1estrela = 0;
        let atendimento2estrela = 0;
        let atendimento3estrela = 0;
        let atendimento4estrela = 0;
        let atendimento5estrela = 0;
        let totalAvaliacoes = 0;
        let pessoasComMaisAtendimentosPraBaixar = [];



        let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
        let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);


        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            console.log(chaveUnica);
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            }
            else if (dataInicial == '' || dataInicial == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA INICIAL do período de pesquisa.');
            }
            else if (dataFinal == '' || dataFinal == null && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'Informe a DATA FINAL do período de pesquisa.');
            }
            else if (verificaSeDataInicialMaiorQueDataFinal == true && valorReferencia == 0) {
                enviaSemConexaoInternetRelatorioGeral(dispatch, 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            }
            else {
                ativaDesativaLoadingSemInternetRelatorioGeral(dispatch, false);
                ativaDesativaLoadingRelatorioGeral(dispatch, true);
                enviaPeriodoPersonalizadoRelatorioGeral(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                console.log('antes da query firebase')
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                console.log(dataInicioConsulta);
                console.log(dataFinalConsulta);
                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {

                        if (snapshot.val()) {

                            console.log('tem dados snapshot')
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                            let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                            for (let i = 0; i < results.length; i++) {

                                if (results[i].clienteVinculado == 1) {
                                    console.log('chamado com cliente vinculado');
                                    console.log(results[i])
                                    arrayChamadosComClientes.push(results[i].cliente)
                                }
                                if (results[i].setorVinculado == 1) {
                                    arrayChamadosComSetores.push(results[i].setor);
                                }
                                if (results[i].atendente != 'Não informado' && results[i].atendente != '' &&
                                    results[i].atendente != null && results[i].atendente != undefined) {
                                    arrayChamadosComAtendentes.push(results[i].atendente);
                                }
                                if (results[i].descricaoProblemaEstatico == 1) {
                                    arrayChamadosComProblemasEstaticos.push(results[i].descricaoProblema);
                                }
                                if (results[i].status == 'Fechado') {
                                    if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                        results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                        results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                        console.log(results[i].dataHoraAbertura)
                                        console.log(results[i].dataHoraFechamento)

                                        let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                        console.log(dataSubtraida);
                                        tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                    }
                                }
                            }
                            if (arrayChamadosComClientes.length != 0) {
                                let arrayDeKeyRepetidos = arrayChamadosComClientes.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    pessoasComMaisAtendimentosPraBaixar.push({ clientes: testeOrdenacao[i] })
                                }
                                //se for maior tenho que pegar somente as 4 primeiras casas do array
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < 4; i++) {
                                        let cliente = testeOrdenacao[i].key;

                                        promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                        arrayChamado.push(promisseCliente);

                                    }
                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < 4; i++) {
                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /*  labelClientes.push(
 
                                                 responses[i].val(),
 
                                             );
                                             valoresClientes.push(
 
                                                 testeOrdenacao[i].value,
 
                                             ); */


                                        }
                                        console.log('antes do datchartcliente')

                                        let dataLineChartCliente = [{
                                            "id": "Clientes",
                                            "data": valoresClientes
                                        }]
                                        enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);
                                    });
                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        let cliente = testeOrdenacao[i].key;

                                        promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                        arrayChamado.push(promisseCliente);

                                    }
                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < testeOrdenacao.length; i++) {
                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /* labelClientes.push(

                                                responses[i].val(),

                                            );
                                            valoresClientes.push(

                                                testeOrdenacao[i].value,

                                            ); */


                                        }
                                        console.log('antes do datchartcliente')
                                        console.log(labelClientes)
                                        console.log(valoresClientes)
                                        let dataLineChartCliente = [{
                                            "id": "Clientes",
                                            "data": valoresClientes
                                        }]
                                        console.log(dataLineChartCliente)
                                        enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);
                                    });
                                }
                            }
                            else {
                                //se não, não tem dados, então envia zerado
                                console.log('cliente mais abriu chamado zerado')
                                let clientesMaisAbriramChamadosZerado = [
                                    {
                                        "id": "Clientes",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);
                            }
                            if (arrayChamadosComSetores.length != 0) {
                                let arrayDeKeyRepetidos = arrayChamadosComSetores.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});
                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    pessoasComMaisAtendimentosPraBaixar.push({ setores: testeOrdenacao[i] })
                                }
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseSetor;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < 4; i++) {
                                        let setor = testeOrdenacao[i].key;

                                        promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once('value')
                                        arrayChamado.push(promisseSetor);

                                    }
                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < 4; i++) {

                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /*   labelClientes.push(
  
                                                  responses[i].val(),
  
                                              );
                                              valoresClientes.push(
  
                                                  testeOrdenacao[i].value,
  
                                              ); */


                                        }
                                        let dataLineChartSetor = [{
                                            "id": "Setores",
                                            "data": valoresClientes
                                        }]
                                        enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                    });
                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseSetor;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        let setor = testeOrdenacao[i].key;

                                        promisseSetor = firebase.database().ref(`${chaveUnica}/setores/${setor}/nome`).once('value')
                                        arrayChamado.push(promisseSetor);

                                    }

                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < testeOrdenacao.length; i++) {
                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /*  labelClientes.push(
 
                                                 responses[i].val(),
 
                                             );
                                             valoresClientes.push(
 
                                                 testeOrdenacao[i].value,
 
                                             ); */


                                        }
                                        let dataLineChartSetor = [{
                                            "id": "Setores",
                                            "data": valoresClientes
                                        }]
                                        enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                    });
                                }
                            } else {
                                let SetoresMaisAbriramChamadosZerado = [
                                    {
                                        "id": "Setores",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                            }
                            if (arrayChamadosComAtendentes.length != 0) {
                                let arrayDeKeyRepetidos = arrayChamadosComAtendentes.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});
                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    pessoasComMaisAtendimentosPraBaixar.push({ atendentes: testeOrdenacao[i] })
                                }
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseSetor;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < 4; i++) {
                                        let setor = testeOrdenacao[i].key;

                                        promisseSetor = firebase.database().ref(`${chaveUnica}/usuarios/${setor}/nome`).once('value')
                                        arrayChamado.push(promisseSetor);

                                    }
                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < 4; i++) {
                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /*  labelClientes.push(
 
                                                 responses[i].val(),
 
                                             );
                                             valoresClientes.push(
 
                                                 testeOrdenacao[i].value,
 
                                             ); */


                                        }
                                        let dataLineChartSetor = [{
                                            "id": "Atendentes",
                                            "data": valoresClientes
                                        }]
                                        enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                    });
                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseSetor;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        let setor = testeOrdenacao[i].key;

                                        promisseSetor = firebase.database().ref(`${chaveUnica}/usuarios/${setor}/nome`).once('value')
                                        arrayChamado.push(promisseSetor);

                                    }

                                    Promise.all(arrayChamado).then(responses => {
                                        for (let i = 0; i < testeOrdenacao.length; i++) {
                                            valoresClientes.push({
                                                x: responses[i].val(),
                                                y: testeOrdenacao[i].value
                                            })
                                            /*  labelClientes.push(
 
                                                 responses[i].val(),
 
                                             );
                                             valoresClientes.push(
 
                                                 testeOrdenacao[i].value,
 
                                             ); */


                                        }
                                        let dataLineChartSetor = [{
                                            "id": "Atendentes",
                                            "data": valoresClientes
                                        }]
                                        enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartSetor);
                                    });
                                }
                            } else {
                                let SetoresMaisAbriramChamadosZerado = [
                                    {
                                        "id": "Atendentes",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                            }
                            if (arrayChamadosComProblemasEstaticos.length != 0) {
                                let arrayDeKeyRepetidos = arrayChamadosComProblemasEstaticos.reduce(function (prev, cur) {
                                    prev[cur] = (prev[cur] || 0) + 1;
                                    return prev;
                                }, {});

                                let testeOrdenacao = ordenaObjeto(arrayDeKeyRepetidos);
                                for (let i = 0; i < testeOrdenacao.length; i++) {
                                    pessoasComMaisAtendimentosPraBaixar.push({ problemas: testeOrdenacao[i] })
                                }
                                console.log('problemas com mais chamados')
                                console.log(testeOrdenacao)
                                //se for maior tenho que pegar somente as 4 primeiras casas do array
                                if (testeOrdenacao.length >= 4) {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []
                                    /*   for (let i = 0; i < 4; i++) {
                                          let cliente = testeOrdenacao[i].key;
 
                                          promisseCliente = firebase.database().ref(`${chaveUnica}/clientes/${cliente}/nome`).once('value')
                                          arrayChamado.push(promisseCliente);
 
                                      } */

                                    for (let i = 0; i < 4; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /*     labelClientes.push(testeOrdenacao[i].key);
                                            valoresClientes.push(testeOrdenacao[i].value); */
                                    }
                                    let dataLineChartCliente = [{
                                        "id": "Problemas",
                                        "data": valoresClientes
                                    }]
                                    enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);

                                }
                                //se não eu pego todo array, já que o mesmo será menos que 4
                                else {
                                    let arrayChamado = [];
                                    let promisseCliente;
                                    let labelClientes = [];
                                    let valoresClientes = []


                                    for (let i = 0; i < testeOrdenacao.length; i++) {
                                        valoresClientes.push({
                                            x: testeOrdenacao[i].key,
                                            y: testeOrdenacao[i].value
                                        })
                                        /* labelClientes.push(testeOrdenacao[i].key);
                                        valoresClientes.push(testeOrdenacao[i].value); */
                                    }
                                    let dataLineChartCliente = [{
                                        "id": "Problemas",
                                        "data": valoresClientes
                                    }]
                                    enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, dataLineChartCliente);

                                }
                            }
                            else {
                                //se não, não tem dados, então envia zerado
                                let clientesMaisAbriramChamadosZerado = [
                                    {
                                        "id": "Clientes",
                                        "data": [
                                            {
                                                "x": "0",
                                                "y": 0
                                            },
                                        ]
                                    },
                                ];
                                enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);
                            }
                            if (tempoParaSolucaoAtendimentos.length !== 0) {
                                tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                                let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                                console.log(durationTempoMedio.asHours())
                                if (durationTempoMedio.asHours() >= 24) {
                                    let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                                    console.log('tempo medio para solucao')
                                    console.log(dataConvertidaDoTempoMedio)
                                    enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, dataConvertidaDoTempoMedio)
                                } else {
                                    let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                                    console.log('tempo medio para solucao')
                                    console.log(dataConvertidaDoTempoMedio)
                                    enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, dataConvertidaDoTempoMedio)
                                }

                            } else {
                                console.log('envia hora tempo medio solucao zerada')
                                enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, '0h 00m')
                            }
                            enviaPessoasComMaisAtendimentosParaDownload(dispatch, pessoasComMaisAtendimentosPraBaixar);
                            // ativaDesativaLoadingRelatorioGeral(dispatch, false);
                        }
                        else {

                            console.log('não tem dados snapshot');
                            enviaPessoasComMaisAtendimentosParaDownload(dispatch, []);
                            let clientesMaisAbriramChamadosZerado = [
                                {
                                    "id": "Clientes",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaClientesMaisAbriramChamadosRelatorioGeral(dispatch, clientesMaisAbriramChamadosZerado);

                            let atendentesMaisAbriramChamadosZerado = [
                                {
                                    "id": "Atendentes",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaAtendentesMaisAbriramChamadosRelatorioGeral(dispatch, atendentesMaisAbriramChamadosZerado)

                            let SetoresMaisAbriramChamadosZerado = [
                                {
                                    "id": "Setores",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaSetoresMaisAbriramChamadosRelatorioGeral(dispatch, SetoresMaisAbriramChamadosZerado)
                            let problemasMaisAbriramChamadosZerado = [
                                {
                                    "id": "Problemas",
                                    "data": [
                                        {
                                            "x": "0",
                                            "y": 0
                                        },
                                    ]
                                },
                            ];
                            enviaProblemasMaisAbriramChamadosRelatorioGeral(dispatch, problemasMaisAbriramChamadosZerado);
                            enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral(dispatch, '0h 00m');
                            // ativaDesativaLoadingRelatorioGeral(dispatch, false);
                        }
                    })
                firebase.database().ref(`${chaveUnica}/avaliacoes`).orderByChild('dataHoraAvaliacaoTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                        console.log('na queryy firebase avaliacao:')
                        console.log(snapshot.val())
                        if (snapshot.val()) {
                            //envia os valores para o grafico
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                            let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                            for (let i = 0; i < results.length; i++) {
                                console.log(results[i]);
                                totalAvaliacoes++;
                                if (results[i].valorAvaliacao == 1) {
                                    atendimento1estrela++;
                                }
                                if (results[i].valorAvaliacao == 2) {
                                    atendimento2estrela++;
                                }
                                if (results[i].valorAvaliacao == 3) {
                                    atendimento3estrela++;
                                }
                                if (results[i].valorAvaliacao == 4) {
                                    atendimento4estrela++;
                                }
                                if (results[i].valorAvaliacao == 5) {
                                    atendimento5estrela++;
                                }
                            }
                            console.log('1 estrela: ' + atendimento1estrela);
                            console.log('2 estrelas: ' + atendimento2estrela);
                            console.log('3 estrelas: ' + atendimento3estrela);
                            console.log('4 estrelas: ' + atendimento4estrela);
                            console.log('5 estrelas: ' + atendimento5estrela);
                            console.log('TOTAL: ' + totalAvaliacoes);
                            let valor1estrelaParaPorcentagem = atendimento1estrela * 1;
                            let valor2estrelaParaPorcentagem = atendimento2estrela * 2;
                            let valor3estrelaParaPorcentagem = atendimento3estrela * 3;
                            let valor4estrelaParaPorcentagem = atendimento4estrela * 4;
                            let valor5estrelaParaPorcentagem = atendimento5estrela * 5;
                            console.log(valor1estrelaParaPorcentagem)
                            console.log(valor2estrelaParaPorcentagem)
                            console.log(valor3estrelaParaPorcentagem)
                            console.log(valor4estrelaParaPorcentagem)
                            console.log(valor5estrelaParaPorcentagem);
                            let notaMedia = (valor1estrelaParaPorcentagem + valor2estrelaParaPorcentagem + valor3estrelaParaPorcentagem + valor4estrelaParaPorcentagem + valor5estrelaParaPorcentagem) / totalAvaliacoes;
                            console.log('notaMedia: ' + notaMedia.toFixed(2).replace('.', ','));
                            enviaNotaMediaAvaliacaoRelatorioGeral(dispatch, notaMedia.toFixed(2).replace('.', ','));

                            ativaDesativaLoadingRelatorioGeral(dispatch, false);
                        } else {
                            console.log('sem avaliacoes')
                            enviaNotaMediaAvaliacaoRelatorioGeral(dispatch, 'Sem Avaliações');
                            ativaDesativaLoadingRelatorioGeral(dispatch, false);
                        }
                    })

            }
        })

    }
}
export const listaAtendimentosPeriodoPersonalizado = (dataInicial2, dataFinal2, valorReferencia, filtro, keyFiltro) => {
    console.log(dataFinal2)
    let dataInicial = moment(dataInicial2).format('DD/MM/YYYY')
    let dataFinal = moment(dataFinal2).format('DD/MM/YYYY')
    console.log(dataInicial, dataFinal, valorReferencia, filtro, keyFiltro)
    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];
    let tempoMedioParaSolucaoAtendimentos = 0;
    let tempoParaSolucaoAtendimentos = [];
    let arrayComChamadosParaBaixar = [];

    let dataInicialVerificacao = moment(dataInicial).format('YYYY-MM-DD');
    let dataFinalVerificacao = moment(dataFinal).format('YYYY-MM-DD');
    console.log('data inicial verificacao e data final verificacao')
    console.log(dataInicialVerificacao)
    console.log(dataFinalVerificacao)
    let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            console.log(chaveUnica);
            if (dataInicial2 == '' && dataFinal2 == '' || dataInicial2 == null && dataFinal2 == null) {
                toastr.error('Error', 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            }
            else if (dataInicial2 == '' || dataInicial2 == null) {
                toastr.error('Error', 'Informe a DATA INICIAL do período de pesquisa.');
            }
            else if (dataFinal2 == '' || dataFinal2 == null) {
                toastr.error('Error', 'Informe a DATA FINAL do período de pesquisa.');
            }
            else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                toastr.error('Error', 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            }
            else {
                enviaSemConexaoInternetTelaRelatorioDetalhado(dispatch, false);
                ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, true);
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${dataInicial} - ${dataFinal}`);
                /*  if (valorReferencia == 1) {
                     dispatch(NavigationActions.back());
                 } */
                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                console.log('antes da query firebase')
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                console.log(dataInicioConsulta);
                console.log(dataFinalConsulta);
                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {

                        if (snapshot.val()) {
                            console.log('tem dados snapshot')
                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                            let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                            if (filtro == 'cliente') {
                                console.log('filtro igual cliente')
                                for (let i = 0; i < results.length; i++) {
                                    if (results[i].cliente == keyFiltro) {


                                        //por status
                                        if (results[i].status == 'Aberto') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAbertoNaSemana++
                                        }
                                        if (results[i].status == 'Atendendo') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAtendendoNaSemana++
                                        }
                                        if (results[i].status == 'Fechado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            // console.log(results[i]);
                                            atendimentosFechadoNaSemana++
                                            if (results[i].periodoResolucaoChamado == '<1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMenorque1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '2') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado2++
                                            }
                                            if (results[i].periodoResolucaoChamado == '3') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado3++
                                            }
                                            if (results[i].periodoResolucaoChamado == '4') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado4++
                                            }
                                            if (results[i].periodoResolucaoChamado == '5') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado5++
                                            }
                                            if (results[i].periodoResolucaoChamado == '6') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado6++
                                            }
                                            if (results[i].periodoResolucaoChamado == '7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado7++
                                            }
                                            if (results[i].periodoResolucaoChamado == '>7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMaiorque7++
                                            }
                                            if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                                results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                                results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                                // console.log(results[i].dataHoraAbertura)
                                                //console.log(results[i].dataHoraFechamento)

                                                let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                                // console.log(dataSubtraida);
                                                tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                            }
                                        }
                                        if (results[i].status == 'Cancelado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosCanceladoNaSemana++
                                        }
                                        //por prioridade
                                        if (results[i].prioridade == 'Baixa') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeBaixa++;
                                        }
                                        if (results[i].prioridade == 'Normal') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeNormal++;
                                        }
                                        if (results[i].prioridade == 'Alta') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeAlta++;
                                        }
                                        if (results[i].prioridade == 'Urgente') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeUrgente++;
                                        }

                                        if (results[i].local == 'Interno') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalInterno++
                                        }
                                        if (results[i].local == 'Externo') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalExterno++
                                        }
                                    }
                                }
                            }
                            if (filtro == 'atendente') {
                                for (let i = 0; i < results.length; i++) {
                                    if (results[i].atendente == keyFiltro) {


                                        //por status
                                        if (results[i].status == 'Aberto') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAbertoNaSemana++
                                        }
                                        if (results[i].status == 'Atendendo') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAtendendoNaSemana++
                                        }
                                        if (results[i].status == 'Fechado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            //  console.log(results[i]);
                                            atendimentosFechadoNaSemana++
                                            if (results[i].periodoResolucaoChamado == '<1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMenorque1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '2') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado2++
                                            }
                                            if (results[i].periodoResolucaoChamado == '3') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado3++
                                            }
                                            if (results[i].periodoResolucaoChamado == '4') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado4++
                                            }
                                            if (results[i].periodoResolucaoChamado == '5') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado5++
                                            }
                                            if (results[i].periodoResolucaoChamado == '6') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado6++
                                            }
                                            if (results[i].periodoResolucaoChamado == '7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado7++
                                            }
                                            if (results[i].periodoResolucaoChamado == '>7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMaiorque7++
                                            }
                                            if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                                results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                                results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                                // console.log(results[i].dataHoraAbertura)
                                                //   console.log(results[i].dataHoraFechamento)

                                                let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                                // console.log(dataSubtraida);
                                                tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                            }
                                        }
                                        if (results[i].status == 'Cancelado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosCanceladoNaSemana++
                                        }
                                        //por prioridade
                                        if (results[i].prioridade == 'Baixa') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeBaixa++;
                                        }
                                        if (results[i].prioridade == 'Normal') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeNormal++;
                                        }
                                        if (results[i].prioridade == 'Alta') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeAlta++;
                                        }
                                        if (results[i].prioridade == 'Urgente') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeUrgente++;
                                        }

                                        if (results[i].local == 'Interno') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalInterno++
                                        }
                                        if (results[i].local == 'Externo') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalExterno++
                                        }
                                    }
                                }
                            }
                            if (filtro == 'setor') {
                                for (let i = 0; i < results.length; i++) {
                                    if (results[i].setor == keyFiltro) {


                                        //por status
                                        if (results[i].status == 'Aberto') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAbertoNaSemana++
                                        }
                                        if (results[i].status == 'Atendendo') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosAtendendoNaSemana++
                                        }
                                        if (results[i].status == 'Fechado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            // console.log(results[i]);
                                            atendimentosFechadoNaSemana++
                                            if (results[i].periodoResolucaoChamado == '<1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMenorque1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '1') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado1++
                                            }
                                            if (results[i].periodoResolucaoChamado == '2') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado2++
                                            }
                                            if (results[i].periodoResolucaoChamado == '3') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado3++
                                            }
                                            if (results[i].periodoResolucaoChamado == '4') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado4++
                                            }
                                            if (results[i].periodoResolucaoChamado == '5') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado5++
                                            }
                                            if (results[i].periodoResolucaoChamado == '6') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado6++
                                            }
                                            if (results[i].periodoResolucaoChamado == '7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamado7++
                                            }
                                            if (results[i].periodoResolucaoChamado == '>7') {
                                                arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                                tempoResolucaoChamadoMaiorque7++
                                            }
                                            if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                                results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                                results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                                // console.log(results[i].dataHoraAbertura)
                                                //  console.log(results[i].dataHoraFechamento)

                                                let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                                //console.log(dataSubtraida);
                                                tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                            }
                                        }
                                        if (results[i].status == 'Cancelado') {
                                            arrayComChamadosParaBaixar.push({ status: results[i] })
                                            atendimentosCanceladoNaSemana++
                                        }
                                        //por prioridade
                                        if (results[i].prioridade == 'Baixa') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeBaixa++;
                                        }
                                        if (results[i].prioridade == 'Normal') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeNormal++;
                                        }
                                        if (results[i].prioridade == 'Alta') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeAlta++;
                                        }
                                        if (results[i].prioridade == 'Urgente') {
                                            arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                            atendimentosAbertoAtendendoPrioridadeUrgente++;
                                        }

                                        if (results[i].local == 'Interno') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalInterno++
                                        }
                                        if (results[i].local == 'Externo') {
                                            arrayComChamadosParaBaixar.push({ local: results[i] })
                                            atendimentosPorLocalExterno++
                                        }
                                    }
                                }
                            }
                            if (filtro == 'padrao') {
                                for (let i = 0; i < results.length; i++) {
                                    //por status
                                    if (results[i].status == 'Aberto') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAbertoNaSemana++
                                    }
                                    if (results[i].status == 'Atendendo') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosAtendendoNaSemana++
                                    }
                                    if (results[i].status == 'Fechado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        //console.log(results[i]);
                                        atendimentosFechadoNaSemana++
                                        if (results[i].periodoResolucaoChamado == '<1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMenorque1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '1') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado1++
                                        }
                                        if (results[i].periodoResolucaoChamado == '2') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado2++
                                        }
                                        if (results[i].periodoResolucaoChamado == '3') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado3++
                                        }
                                        if (results[i].periodoResolucaoChamado == '4') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado4++
                                        }
                                        if (results[i].periodoResolucaoChamado == '5') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado5++
                                        }
                                        if (results[i].periodoResolucaoChamado == '6') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado6++
                                        }
                                        if (results[i].periodoResolucaoChamado == '7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamado7++
                                        }
                                        if (results[i].periodoResolucaoChamado == '>7') {
                                            arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                            tempoResolucaoChamadoMaiorque7++
                                        }
                                        if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                            results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                            results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                            // console.log(results[i].dataHoraAbertura)
                                            //  console.log(results[i].dataHoraFechamento)

                                            let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                            //  console.log(dataSubtraida);
                                            tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                        }
                                    }
                                    if (results[i].status == 'Cancelado') {
                                        arrayComChamadosParaBaixar.push({ status: results[i] })
                                        atendimentosCanceladoNaSemana++
                                    }
                                    //por prioridade
                                    if (results[i].prioridade == 'Baixa') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeBaixa++;
                                    }
                                    if (results[i].prioridade == 'Normal') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeNormal++;
                                    }
                                    if (results[i].prioridade == 'Alta') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeAlta++;
                                    }
                                    if (results[i].prioridade == 'Urgente') {
                                        arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                        atendimentosAbertoAtendendoPrioridadeUrgente++;
                                    }

                                    if (results[i].local == 'Interno') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalInterno++
                                    }
                                    if (results[i].local == 'Externo') {
                                        arrayComChamadosParaBaixar.push({ local: results[i] })
                                        atendimentosPorLocalExterno++
                                    }

                                }
                            }
                            //implemento array para enviar os chamados por status
                            //implemento array para enviar os chamados por status
                            if (atendimentosAbertoNaSemana == 0) {
                                atendimentosAbertoNaSemana = 0.5;
                            }

                            if (atendimentosAtendendoNaSemana == 0) {
                                atendimentosAtendendoNaSemana = 0.5;
                            }
                            if (atendimentosFechadoNaSemana == 0) {
                                atendimentosFechadoNaSemana = 0.5;
                            }
                            if (atendimentosCanceladoNaSemana == 0) {
                                atendimentosCanceladoNaSemana = 0.5;

                            }
                            console.log('depois dos ifs')
                            let atendimentosPorStatusEnviaDashBoard = [
                                { value: atendimentosAbertoNaSemana, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: atendimentosAtendendoNaSemana, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: atendimentosFechadoNaSemana, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: atendimentosCanceladoNaSemana, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);
                            /*   let atendimentosPorStatusEnviaDashBoard = {
                                  dataSets: [{
                                      values: arrayChamadosPorStatus,
                                      label: '',
                                      config: {
                                          colors: [processColor('#00BFFF'), processColor('#F9BF3B'), processColor('#00FF7F'), processColor('red')],
                                          valueTextSize: 18,
                                          valueTextColor: processColor('#000'),
                                          sliceSpace: 5,
                                          selectionShift: 13,
                                          // xValuePosition: "OUTSIDE_SLICE",
                                          // yValuePosition: "OUTSIDE_SLICE",
                                          // valueFormatter: "#.#'%'",
                                          valueFormatter: "#",
                                          valueLineColor: processColor('#000'),
                                          valueLinePart1Length: 0.5
                                      }
                                  }],
                              }
                              enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard); */

                            /*       let TotalAtendimentosPorStatus = atendimentosAbertoNaSemana +
                                      atendimentosAtendendoNaSemana + atendimentosFechadoNaSemana +
                                      atendimentosCanceladoNaSemana;
                                  enviaTotalAtendimentosPorStatusRelatorioDetalhado(dispatch, TotalAtendimentosPorStatus) */


                            console.log('1')
                            //envio chamados por prioridades
                            /*        let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                                       atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                                       atendimentosAbertoAtendendoPrioridadeUrgente;
                                   enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                            if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                                atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                                atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                                atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                                atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                            }
                            let atendimentosPorPrioridadeEnviaDashBoard = [
                                { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard)
                            /*     let atendimentosPorPrioridadeEnviaDashBoard = {
                                    dataSets: [{
                                        values: [
                                            { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa' },
                                            { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal' },
                                            { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta' },
                                            { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente' }],
                                        label: '',
                                        config: {
                                            colors: [processColor('#00BFFF'), processColor('#B0C4DE'), processColor('#F9BF3B'), processColor('#f70e4c')],
                                            valueTextSize: 18,
                                            valueTextColor: processColor('#000'),
                                            sliceSpace: 5,
                                            selectionShift: 13,
                                            // xValuePosition: "OUTSIDE_SLICE",
                                            // yValuePosition: "OUTSIDE_SLICE",
                                            // valueFormatter: "#.#'%'",
                                            valueFormatter: "#",
                                            valueLineColor: processColor('#000'),
                                            valueLinePart1Length: 0.5
                                        }
                                    }],
                                }
                                enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard) */
                            console.log('2')
                            if (tempoResolucaoChamadoMenorque1 == 0) {
                                tempoResolucaoChamadoMenorque1 = 0.5
                            }
                            if (tempoResolucaoChamado1 == 0) {
                                tempoResolucaoChamado1 = 0.5
                            }
                            if (tempoResolucaoChamado2 == 0) {
                                tempoResolucaoChamado2 = 0.5
                            }
                            if (tempoResolucaoChamado3 == 0) {
                                tempoResolucaoChamado3 = 0.5
                            }
                            if (tempoResolucaoChamado4 == 0) {
                                tempoResolucaoChamado4 = 0.5
                            }
                            if (tempoResolucaoChamado5 == 0) {
                                tempoResolucaoChamado5 = 0.5
                            }
                            if (tempoResolucaoChamado6 == 0) {
                                tempoResolucaoChamado6 = 0.5
                            }
                            if (tempoResolucaoChamado7 == 0) {
                                tempoResolucaoChamado7 = 0.5
                            }
                            if (tempoResolucaoChamadoMaiorque7 == 0) {
                                tempoResolucaoChamadoMaiorque7 = 0.5
                            }
                            let atendimentosPorTempOResolucao = [
                                { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                                { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                            enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                            /* let atendimentosPorTempOResolucao = {
                                dataSets: [{
                                    values: [
                                        { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia' },
                                        { value: tempoResolucaoChamado1, label: '1 dia' },
                                        { value: tempoResolucaoChamado2, label: '2 dias' },
                                        { value: tempoResolucaoChamado3, label: '3 dias' },
                                        { value: tempoResolucaoChamado4, label: '4 dias' },
                                        { value: tempoResolucaoChamado5, label: '5 dias' },
                                        { value: tempoResolucaoChamado6, label: '6 dias' },
                                        { value: tempoResolucaoChamado7, label: '7 dias' },
                                        { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias' }],
                                    label: '',
                                    config: {
                                        colors: [processColor('#00FF7F'), processColor('#D3D3D3'), processColor('#F9BF3B'), processColor('#f70e4c'), processColor('#B0C4DE'),
                                        processColor('#00BFFF'), processColor('#00FFFF'), processColor('#D2691E'), processColor('#008B8B')],
                                        valueTextSize: 18,
                                        valueTextColor: processColor('#000'),
                                        sliceSpace: 5,
                                        selectionShift: 13,
                                        // xValuePosition: "OUTSIDE_SLICE",
                                        // yValuePosition: "OUTSIDE_SLICE",
                                        // valueFormatter: "#.#'%'",
                                        valueFormatter: "#",
                                        valueLineColor: processColor('#000'),
                                        valueLinePart1Length: 0.5
                                    }
                                }],
                            }
                            enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                            enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado(dispatch, atendimentosFechadoNaSemana); */
                            console.log('3')
                            if (atendimentosPorLocalInterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                                })
                            }
                            if (atendimentosPorLocalExterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                                })
                            }

                            enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocal)
                            //atendimentos interno e externo
                            /*     if (atendimentosPorLocalInterno == 0) {
                                    atendimentosPorLocal.push({
                                        value: 0.5, label: 'Interno'
                                    })

                                } else {
                                    atendimentosPorLocal.push({
                                        value: atendimentosPorLocalInterno, label: 'Interno'
                                    })
                                }
                                if (atendimentosPorLocalExterno == 0) {
                                    atendimentosPorLocal.push({
                                        value: 0.5, label: 'Externo'
                                    })

                                } else {
                                    atendimentosPorLocal.push({
                                        value: atendimentosPorLocalExterno, label: 'Externo'
                                    })
                                }

                                let atendimentosPorLocalArray = {
                                    dataSets: [{
                                        values: atendimentosPorLocal,
                                        label: '',
                                        config: {
                                            colors: [processColor('#00BFFF'), processColor('#B0C4DE')],
                                            valueTextSize: 18,
                                            valueTextColor: processColor('#000'),
                                            sliceSpace: 5,
                                            selectionShift: 13,
                                            // xValuePosition: "OUTSIDE_SLICE",
                                            // yValuePosition: "OUTSIDE_SLICE",
                                            // valueFormatter: "#.#'%'",
                                            valueFormatter: "#",
                                            valueLineColor: processColor('#000'),
                                            valueLinePart1Length: 0.5
                                        }
                                    }],
                                }
                                let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                                enviaTotalAtendimentosPorLocalRelatorioDetalhado(dispatch, totalAtendimentosPorLocal)
                                enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocalArray) */
                            console.log('4')
                            //envia tempo medio para solucao
                            console.log(tempoParaSolucaoAtendimentos);
                            if (tempoParaSolucaoAtendimentos.length !== 0) {
                                tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                                let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                                console.log(durationTempoMedio.asHours())
                                if (durationTempoMedio.asHours() >= 24) {
                                    let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                                    console.log('tempo medio para solucao')
                                    console.log(dataConvertidaDoTempoMedio)
                                    enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                                } else {
                                    let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                                    console.log('tempo medio para solucao')
                                    console.log(dataConvertidaDoTempoMedio)
                                    enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                                }

                            } else {
                                console.log('envia hora tempo medio solucao zerada')
                                enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')
                            }

                            enviaChamadosDetalhadosParaDownload(dispatch, arrayComChamadosParaBaixar);
                            ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                            // ativaDesativaLoadingRefreshControol(dispatch, false);
                        } else {
                            console.log('não tem dados snapshot')
                            //sem informacoes
                            let atendimentosPorStatusZerado = [
                                { value: 0.5, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: 0.5, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusZerado);
                            let atendimentoPorPrioridadeZerado = [
                                { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                            ]


                            enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentoPorPrioridadeZerado);
                            //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                            let atendimentoPorResolucaoZerado = [
                                { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                                { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                                { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                                { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                            enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentoPorResolucaoZerado);
                            // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                            let atendimentoPorLocalZerado = [
                                { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                            ];
                            // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                            enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentoPorLocalZerado)

                            //envia tempo medio zerado
                            enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')

                            enviaChamadosDetalhadosParaDownload(dispatch, []);
                            ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                            console.log(`final do grafico`)
                        }
                    })
            }

        })

    }
}
export const novalistaAtendimentosZeradoParaRelatorioDetalhado = (periodoEstatico, valorReferencia) => {

    console.log('nova lista atendi');
    console.log(periodoEstatico, valorReferencia)
    //nome dos meses pt br
    moment.locale('pt-br', {
        months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    })
    //HOJE
    let dataHoje = moment().toDate();
    console.log(dataHoje)
    dataHoje = moment(dataHoje).format('DD/MM/YYYY');

    //ONTEM
    let datadeOntem = moment().subtract(1, 'days').toDate(); // pego data atual
    datadeOntem = moment(datadeOntem).format('DD/MM/YYYY');

    //SEMANA ATUAL
    const comecoDaSemana = moment().startOf('week').toDate();
    const fimDaSemana = moment().endOf('week').toDate();
    let dataComecoSemana = moment(comecoDaSemana).format('DD/MM/YYYY');
    let dataFimSemana = moment(fimDaSemana).format('DD/MM/YYYY');
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    // dataFimSemana = dataFimSemana + ' 23:59:59';

    //SEMANA PASSADA
    let comecoSemanaPassada = moment().weekday(-7).toDate();
    let fimDaSemanaPassada = moment().weekday(-7).add(6, 'day').toDate()
    let dataComecoSemanaPassada = moment(comecoSemanaPassada).format('DD/MM/YYYY');
    let dataFimDaSemanaPassada = moment(fimDaSemanaPassada).format('DD/MM/YYYY');

    //MES ATUAL
    const nomeMesAtual = moment().startOf('month').format('MMMM');
    const comecoDoMesAtual = moment().startOf('month').format('DD/MM/YYYY');
    const fimDoMesAtual = moment().endOf('month').format('DD/MM/YYYY');

    //MES PASSADO
    const nomeMesPassado = moment().subtract(1, 'months').startOf('month').format('MMMM');
    const comecoDoMesPassado = moment().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
    const fimDoMesPassado = moment().subtract(1, 'months').endOf('month').format('DD/MM/YYYY');

    console.log(`HOJE ${dataHoje}`);
    console.log(`ONTEM ${datadeOntem}`);
    console.log(`SEMANA ATUAL ${dataComecoSemana} - ${dataFimSemana}`);
    console.log(`SEMANA PASSADA ${dataComecoSemanaPassada} - ${dataFimDaSemanaPassada}`);
    console.log(`MES ATUAL ${comecoDoMesAtual} - ${fimDoMesAtual} - nome Mês Atual ${nomeMesAtual}`);
    console.log(`MES PASSADO ${comecoDoMesPassado} - ${fimDoMesPassado} - nome Mês Passado ${nomeMesPassado}`);


    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];
    let tempoMedioParaSolucaoAtendimentos = 0;
    let tempoParaSolucaoAtendimentos = [];
    let arrayComChamadosParaBaixar = [];
    let arrayChamadosPorStatusDownload = [];
    // dataComecoSemana = dataComecoSemana + ' 00:00:01';
    //dataFimSemana = dataFimSemana + ' 23:59:59';
    //  let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
    // let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);

    //como converter uma data de timestamp em data normal
    // let date2 = new Date(1562016662685).toUTCString();
    // let dataAtualTimeStamp = moment(date2).format('DD/MM/YYYY');
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            console.log(chaveUnica);
            enviaSemConexaoInternetTelaRelatorioDetalhado(dispatch, false);
            ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, true);
            let dataAtual = retornaDataAtual();
            let dataInicioConsulta, dataFinalConsulta;

            //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
            if (periodoEstatico == 'Hoje') {
                dataInicioConsulta = dataHoje + ' 00:00:01';
                dataFinalConsulta = dataHoje + ' 23:59:59';
                let dataHojeWeb = moment().toDate();
                dataHojeWeb = moment(dataHojeWeb)
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataHojeWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataHojeWeb });
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            }
            else if (periodoEstatico == 'Ontem') {
                dataInicioConsulta = datadeOntem + ' 00:00:01';
                dataFinalConsulta = datadeOntem + ' 23:59:59';
                let datadeOntemWeb = moment().subtract(1, 'days').toDate(); // pego data atual
                datadeOntemWeb = moment(datadeOntemWeb);
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: datadeOntemWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: datadeOntemWeb });
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            }
            else if (periodoEstatico == 'Semana Atual') {
                console.log('dentro do semana atual')
                dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                dataFinalConsulta = dataFimSemana + ' 23:59:59';
                let comecoDaSemanaWeb = moment().startOf('week').toDate();
                let fimDaSemanaWeb = moment().endOf('week').toDate();
                comecoDaSemanaWeb = moment(comecoDaSemanaWeb);
                fimDaSemanaWeb = moment(fimDaSemanaWeb);
                dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: comecoDaSemanaWeb });
                dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: fimDaSemanaWeb });

                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            }
            else if (periodoEstatico == 'Semana Passada') {
                dataInicioConsulta = dataComecoSemanaPassada + ' 00:00:01';
                dataFinalConsulta = dataFimDaSemanaPassada + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);
            }
            else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 1) {
                dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
            }
            else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 1) {
                dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
            }
            else if (periodoEstatico.includes('Mês Atual') && valorReferencia == 0) {
                dataInicioConsulta = comecoDoMesAtual + ' 00:00:01';
                dataFinalConsulta = fimDoMesAtual + ' 23:59:59';
                //  enviaPeriodoPersonalizado(dispatch, `${periodoEstatico}, ${nomeMesAtual}.`);
            }
            else if (periodoEstatico.includes('Mês Passado') && valorReferencia == 0) {
                dataInicioConsulta = comecoDoMesPassado + ' 00:00:01';
                dataFinalConsulta = fimDoMesPassado + ' 23:59:59';
                //enviaPeriodoPersonalizado(dispatch, `${periodoEstatico}, ${nomeMesPassado}.`);
            }
            else {
                console.log('ELSE QUE NAO é pra chegar!')
                //nunca vai chegar aqui, mas pra previnir vou retornar como se fosse semana atual
                dataInicioConsulta = dataComecoSemana + ' 00:00:01';
                dataFinalConsulta = dataFimSemana + ' 23:59:59';
                enviaPeriodoPersonalizadoRelatorioDetalhado(dispatch, periodoEstatico);

            }
            console.log(dataInicioConsulta);
            console.log(dataFinalConsulta);
            console.log('antes da query firebase')
            dataInicioConsulta = retornaDataTimeStamp(dataInicioConsulta);
            dataFinalConsulta = retornaDataTimeStamp(dataFinalConsulta);
            //  zeraDataInicialFiltroRelatorioDetalhado(dispatch);
            // zeraDataFinalFiltroRelatorioDetalhado(dispatch);
            console.log(dataInicioConsulta);
            console.log(dataFinalConsulta);
            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                    if (snapshot.val()) {
                        console.log('tem dados snapshot')
                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                        for (let i = 0; i < results.length; i++) {
                            //por status
                            if (results[i].status == 'Aberto') {
                                arrayComChamadosParaBaixar.push({ status: results[i] })
                                atendimentosAbertoNaSemana++
                            }
                            if (results[i].status == 'Atendendo') {
                                arrayComChamadosParaBaixar.push({ status: results[i] })
                                atendimentosAtendendoNaSemana++
                            }
                            if (results[i].status == 'Fechado') {
                                arrayComChamadosParaBaixar.push({ status: results[i] })
                                console.log(results[i]);
                                atendimentosFechadoNaSemana++
                                if (results[i].periodoResolucaoChamado == '<1') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamadoMenorque1++
                                }
                                if (results[i].periodoResolucaoChamado == '1') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado1++
                                }
                                if (results[i].periodoResolucaoChamado == '2') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado2++
                                }
                                if (results[i].periodoResolucaoChamado == '3') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado3++
                                }
                                if (results[i].periodoResolucaoChamado == '4') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado4++
                                }
                                if (results[i].periodoResolucaoChamado == '5') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado5++
                                }
                                if (results[i].periodoResolucaoChamado == '6') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado6++
                                }
                                if (results[i].periodoResolucaoChamado == '7') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamado7++
                                }
                                if (results[i].periodoResolucaoChamado == '>7') {
                                    arrayComChamadosParaBaixar.push({ solucao: results[i] })
                                    tempoResolucaoChamadoMaiorque7++
                                }

                                if (results[i].dataHoraAbertura != undefined && results[i].dataHoraAbertura != '' &&
                                    results[i].dataHoraAbertura != null && results[i].dataHoraFechamento != undefined &&
                                    results[i].dataHoraFechamento != '' && results[i].dataHoraFechamento != null) {
                                    console.log(results[i].dataHoraAbertura)
                                    console.log(results[i].dataHoraFechamento)

                                    let dataSubtraida = moment(results[i].dataHoraFechamento, 'DD/MM/YYYY HH:mm').diff(moment(results[i].dataHoraAbertura, 'DD/MM/YYYY HH:mm'))
                                    console.log(dataSubtraida);
                                    tempoParaSolucaoAtendimentos.push(dataSubtraida);

                                }
                            }
                            if (results[i].status == 'Cancelado') {
                                arrayComChamadosParaBaixar.push({ status: results[i] })
                                atendimentosCanceladoNaSemana++
                            }
                            //por prioridade
                            if (results[i].prioridade == 'Baixa') {
                                arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                atendimentosAbertoAtendendoPrioridadeBaixa++;
                            }
                            if (results[i].prioridade == 'Normal') {
                                arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                atendimentosAbertoAtendendoPrioridadeNormal++;
                            }
                            if (results[i].prioridade == 'Alta') {
                                arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                atendimentosAbertoAtendendoPrioridadeAlta++;
                            }
                            if (results[i].prioridade == 'Urgente') {
                                arrayComChamadosParaBaixar.push({ prioridade: results[i] })
                                atendimentosAbertoAtendendoPrioridadeUrgente++;
                            }

                            if (results[i].local == 'Interno') {
                                arrayComChamadosParaBaixar.push({ local: results[i] })
                                atendimentosPorLocalInterno++
                            }
                            if (results[i].local == 'Externo') {
                                arrayComChamadosParaBaixar.push({ local: results[i] })
                                atendimentosPorLocalExterno++
                            }

                        }


                        //implemento array para enviar os chamados por status
                        if (atendimentosAbertoNaSemana == 0) {
                            atendimentosAbertoNaSemana = 0.5;
                            /*  arrayChamadosPorStatus.push({
                                 value: 0.5, label: 'Aberto'
                             }) */
                        } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosAbertoNaSemana, label: 'Aberto'
                                    })
                                } */

                        if (atendimentosAtendendoNaSemana == 0) {
                            atendimentosAtendendoNaSemana = 0.5;
                            /*  arrayChamadosPorStatus.push({
                                 value: 0.5, label: 'Atendendo'
                             }) */
                        } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosAtendendoNaSemana, label: 'Atendendo'
                                    })
                                } */
                        if (atendimentosFechadoNaSemana == 0) {
                            atendimentosFechadoNaSemana = 0.5;
                            /*  arrayChamadosPorStatus.push({
                                 value: 0.5, label: 'Fechado'
                             }) */
                        } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosFechadoNaSemana, label: 'Fechado'
                                    })
                                } */
                        if (atendimentosCanceladoNaSemana == 0) {
                            atendimentosCanceladoNaSemana = 0.5;
                            /*   arrayChamadosPorStatus.push({
                                  value: 0.5, label: 'Cancelado'
                              }) */
                        } /* else {
                                    arrayChamadosPorStatus.push({
                                        value: atendimentosCanceladoNaSemana, label: 'Cancelado'
                                    })
                                } */
                        console.log('depois dos ifs')
                        let atendimentosPorStatusEnviaDashBoard = [
                            { value: atendimentosAbertoNaSemana, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: atendimentosAtendendoNaSemana, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: atendimentosFechadoNaSemana, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: atendimentosCanceladoNaSemana, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                        enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);

                        /*        let atendimentosPorStatusEnviaDashBoard = {
                                   dataSets: [{
                                       values: arrayChamadosPorStatus,
                                       label: '',
                                       config: {
                                           colors: [processColor('#00BFFF'), processColor('#F9BF3B'), processColor('#00FF7F'), processColor('red')],
                                           valueTextSize: 18,
                                           valueTextColor: processColor('#000'),
                                           sliceSpace: 5,
                                           selectionShift: 13,
                                           // xValuePosition: "OUTSIDE_SLICE",
                                           // yValuePosition: "OUTSIDE_SLICE",
                                           // valueFormatter: "#.#'%'",
                                           valueFormatter: "#",
                                           valueLineColor: processColor('#000'),
                                           valueLinePart1Length: 0.5
                                       }
                                   }],
                               } 
                               enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusEnviaDashBoard);*/
                        /*     let TotalAtendimentosPorStatus = atendimentosAbertoNaSemana +
                                atendimentosAtendendoNaSemana + atendimentosFechadoNaSemana +
                                atendimentosCanceladoNaSemana;
                            enviaTotalAtendimentosPorStatusRelatorioDetalhado(dispatch, TotalAtendimentosPorStatus) */


                        console.log('1')
                        //envio chamados por prioridades
                        let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                            atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                            atendimentosAbertoAtendendoPrioridadeUrgente;
                        enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade)
                        if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                            atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                            atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                            atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                            atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                        }
                        let atendimentosPorPrioridadeEnviaDashBoard = [
                            { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                        enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                        /*     let atendimentosPorPrioridadeEnviaDashBoard = {
                                dataSets: [{
                                    values: [
                                        { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa' },
                                        { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal' },
                                        { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta' },
                                        { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente' }],
                                    label: '',
                                    config: {
                                        colors: [processColor('#00BFFF'), processColor('#B0C4DE'), processColor('#F9BF3B'), processColor('#f70e4c')],
                                        valueTextSize: 18,
                                        valueTextColor: processColor('#000'),
                                        sliceSpace: 5,
                                        selectionShift: 13,
                                        // xValuePosition: "OUTSIDE_SLICE",
                                        // yValuePosition: "OUTSIDE_SLICE",
                                        // valueFormatter: "#.#'%'",
                                        valueFormatter: "#",
                                        valueLineColor: processColor('#000'),
                                        valueLinePart1Length: 0.5
                                    }
                                }],
                            }
                            enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentosPorPrioridadeEnviaDashBoard) */
                        console.log('2')
                        if (tempoResolucaoChamadoMenorque1 == 0) {
                            tempoResolucaoChamadoMenorque1 = 0.5
                        }
                        if (tempoResolucaoChamado1 == 0) {
                            tempoResolucaoChamado1 = 0.5
                        }
                        if (tempoResolucaoChamado2 == 0) {
                            tempoResolucaoChamado2 = 0.5
                        }
                        if (tempoResolucaoChamado3 == 0) {
                            tempoResolucaoChamado3 = 0.5
                        }
                        if (tempoResolucaoChamado4 == 0) {
                            tempoResolucaoChamado4 = 0.5
                        }
                        if (tempoResolucaoChamado5 == 0) {
                            tempoResolucaoChamado5 = 0.5
                        }
                        if (tempoResolucaoChamado6 == 0) {
                            tempoResolucaoChamado6 = 0.5
                        }
                        if (tempoResolucaoChamado7 == 0) {
                            tempoResolucaoChamado7 = 0.5
                        }
                        if (tempoResolucaoChamadoMaiorque7 == 0) {
                            tempoResolucaoChamadoMaiorque7 = 0.5
                        }
                        let atendimentosPorTempOResolucao = [
                            { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                        enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                        /*       let atendimentosPorTempOResolucao = {
                                  dataSets: [{
                                      values: [
                                          { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia' },
                                          { value: tempoResolucaoChamado1, label: '1 dia' },
                                          { value: tempoResolucaoChamado2, label: '2 dias' },
                                          { value: tempoResolucaoChamado3, label: '3 dias' },
                                          { value: tempoResolucaoChamado4, label: '4 dias' },
                                          { value: tempoResolucaoChamado5, label: '5 dias' },
                                          { value: tempoResolucaoChamado6, label: '6 dias' },
                                          { value: tempoResolucaoChamado7, label: '7 dias' },
                                          { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias' }],
                                      label: '',
                                      config: {
                                          colors: [processColor('#00FF7F'), processColor('#D3D3D3'), processColor('#F9BF3B'), processColor('#f70e4c'), processColor('#B0C4DE'),
                                          processColor('#00BFFF'), processColor('#00FFFF'), processColor('#D2691E'), processColor('#008B8B')],
                                          valueTextSize: 18,
                                          valueTextColor: processColor('#000'),
                                          sliceSpace: 5,
                                          selectionShift: 13,
                                          // xValuePosition: "OUTSIDE_SLICE",
                                          // yValuePosition: "OUTSIDE_SLICE",
                                          // valueFormatter: "#.#'%'",
                                          valueFormatter: "#",
                                          valueLineColor: processColor('#000'),
                                          valueLinePart1Length: 0.5
                                      }
                                  }],
                              }
                              enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentosPorTempOResolucao);
                              enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado(dispatch, atendimentosFechadoNaSemana); */
                        console.log('3')
                        //atendimentos interno e externo
                        /*    if (atendimentosPorLocalInterno == 0) {
                               atendimentosPorLocal.push({
                                   value: 0.5, label: 'Interno'
                               })
   
                           } else {
                               atendimentosPorLocal.push({
                                   value: atendimentosPorLocalInterno, label: 'Interno'
                               })
                           }
                           if (atendimentosPorLocalExterno == 0) {
                               atendimentosPorLocal.push({
                                   value: 0.5, label: 'Externo'
                               })
   
                           } else {
                               atendimentosPorLocal.push({
                                   value: atendimentosPorLocalExterno, label: 'Externo'
                               })
                           }
   
                           let atendimentosPorLocalArray = {
                               dataSets: [{
                                   values: atendimentosPorLocal,
                                   label: '',
                                   config: {
                                       colors: [processColor('#00BFFF'), processColor('#B0C4DE')],
                                       valueTextSize: 18,
                                       valueTextColor: processColor('#000'),
                                       sliceSpace: 5,
                                       selectionShift: 13,
                                       // xValuePosition: "OUTSIDE_SLICE",
                                       // yValuePosition: "OUTSIDE_SLICE",
                                       // valueFormatter: "#.#'%'",
                                       valueFormatter: "#",
                                       valueLineColor: processColor('#000'),
                                       valueLinePart1Length: 0.5
                                   }
                               }],
                           }
                           let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                           enviaTotalAtendimentosPorLocalRelatorioDetalhado(dispatch, totalAtendimentosPorLocal)
                           enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocalArray) */
                        if (atendimentosPorLocalInterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })
                        }
                        if (atendimentosPorLocalExterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })
                        }

                        enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentosPorLocal)
                        console.log('4')

                        //tempo médio para solucao de atendimentos
                        console.log(tempoParaSolucaoAtendimentos);
                        if (tempoParaSolucaoAtendimentos.length !== 0) {
                            tempoMedioParaSolucaoAtendimentos = retornaMedia(tempoParaSolucaoAtendimentos);
                            let durationTempoMedio = moment.duration(tempoMedioParaSolucaoAtendimentos);
                            console.log(durationTempoMedio.asHours())
                            if (durationTempoMedio.asHours() >= 24) {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asDays()) + "d" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" hh") + "h";
                                console.log('tempo medio para solucao')
                                console.log(dataConvertidaDoTempoMedio)
                                enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                            } else {
                                let dataConvertidaDoTempoMedio = Math.floor(durationTempoMedio.asHours()) + "h" + moment.utc(tempoMedioParaSolucaoAtendimentos).format(" mm") + "m";
                                console.log('tempo medio para solucao')
                                console.log(dataConvertidaDoTempoMedio)
                                enviaTempoMedioDeSolucaoAtendimentos(dispatch, dataConvertidaDoTempoMedio)
                            }

                        } else {
                            console.log('envia hora tempo medio solucao zerada')
                            enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')
                        }


                        console.log(arrayComChamadosParaBaixar)
                        enviaChamadosDetalhadosParaDownload(dispatch, arrayComChamadosParaBaixar);
                        ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                        // ativaDesativaLoadingRefreshControol(dispatch, false);
                    } else {
                        console.log('não tem dados snapshot')
                        //sem informacoes

                        let atendimentosPorStatusZerado = [
                            { value: 0.5, label: 'Aberto', id: "Aberto", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Atendendo', id: "Atendendo", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Fechado', id: "Fechado", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Cancelado', id: "Cancelado", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                        enviaAtendimentosPorStatusRelatorioDetalhado(dispatch, atendimentosPorStatusZerado);
                        let atendimentoPorPrioridadeZerado = [
                            { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                        ]


                        enviaAtendimentosPorPrioridadeRelatorioDetalhado(dispatch, atendimentoPorPrioridadeZerado);
                        //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                        let atendimentoPorResolucaoZerado = [
                            { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                        enviaTempoResolucaoChamadosRelatorioDetalhado(dispatch, atendimentoPorResolucaoZerado);
                        // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                        let atendimentoPorLocalZerado = [
                            { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                        ];
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                        enviaAtendimentosPorLocalRelatorioDetalhado(dispatch, atendimentoPorLocalZerado)

                        //envia tempo medio zerado
                        enviaTempoMedioDeSolucaoAtendimentos(dispatch, '0h 00m')

                        enviaChamadosDetalhadosParaDownload(dispatch, []);
                        ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados(dispatch, false);
                        console.log(`final do grafico`)
                    }
                })

        })

    }
}

export const exportaAtendimentoParaPDF = (arrayChamados) => {
    return dispatch => {
        console.log(arrayChamados)
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            toastr.warning('Gerando PDF', 'Por favor aguarde...')
            console.log(chaveUnica)

            let chamado = [];
            chamado.push({
                cliente: arrayChamados.cliente == "Não informado" ? "" : arrayChamados.cliente,
                empresa: arrayChamados.empresa == "Não informado" ? "" : arrayChamados.empresa,
                setor: arrayChamados.setor == "Não informado" ? "" : arrayChamados.setor,
                atendente: arrayChamados.atendente == "Não informado" ? "" : arrayChamados.atendente,
                dataHoraCancelamento: arrayChamados.dataHoraCancelamento == undefined ? "" : arrayChamados.dataHoraCancelamento,
                dataHoraFechamento: arrayChamados.dataHoraFechamento == undefined ? "" : arrayChamados.dataHoraFechamento,
                dataVencimento: arrayChamados.dataVencimento == "Não informado" ? "" : arrayChamados.dataVencimento,
                local: arrayChamados.local == "Não informado" ? "" : arrayChamados.local,
                periodoResolucaoChamado: arrayChamados.periodoResolucaoChamado == "" ? "" : arrayChamados.periodoResolucaoChamado + ' dia(s)',
                dataHoraAbertura: arrayChamados.dataHoraAbertura,
                status: arrayChamados.status,
                prioridade: arrayChamados.prioridade === "Não informado" ? "" : arrayChamados.prioridade,
                descricaoProblema: arrayChamados.descricaoProblema,
                numeroChamado: arrayChamados.numeroChamado,
                tempoAtendimentoGasto: arrayChamados.tempoGasto == 0 ? "" : arrayChamados.tempoGasto + ' min.',
            })
            firebase.database().ref(`${chaveUnica}/acoesChamados/${arrayChamados.key}`).once("value").then(snapshot => {
                let arrayAcoesAtendimento = [];
                snapshot.forEach(childItem => {
                    if (childItem.val().visivelATodos == 1) {
                        if (childItem.val().tipo !== undefined) {
                            arrayAcoesAtendimento.push({
                                key: childItem.key,
                                title: childItem.val().titulo,
                                tipo: childItem.val().tipo,
                                description: childItem.val().mensagem,
                                time: childItem.val().time,
                                lineColor: childItem.val().lineColor,
                                circleColor: childItem.val().circleColor,
                                urlDownload: childItem.val().urlDownload,
                                tipoArquivo: childItem.val().tipoArquivo,
                                duracao: childItem.val().duracao == undefined ? '' : childItem.val().duracao,
                                audio: childItem.val().audio == undefined ? false : childItem.val().audio,
                                dataHoraAtual: childItem.val().dataHoraAtual == undefined ? childItem.val().mensagem : childItem.val().dataHoraAtual,
                                visivelATodos: childItem.val().visivelATodos == undefined ? 1 : childItem.val().visivelATodos,
                                statusMessage: childItem.val().statusMessage == undefined ? 'sent' : childItem.val().statusMessage,
                                idMensagem: childItem.val().idMensagem == undefined ? childItem.key : childItem.val().idMensagem === '' ? childItem.key : childItem.val().idMensagem,
                                circleColorResponder: childItem.val().circleColorResponder == undefined ? '#00FF7F' : childItem.val().circleColorResponder,
                                commentResponderType: childItem.val().commentResponderType == undefined ? '' : childItem.val().commentResponderType,
                                idMensagemResponder: childItem.val().idMensagemResponder == undefined ? '' : childItem.val().idMensagemResponder,
                                mensagemResposta: childItem.val().mensagemResposta == undefined ? '' : childItem.val().mensagemResposta,
                                possuiResposta: childItem.val().possuiResposta == undefined ? false : childItem.val().possuiResposta,
                                commentResponderTypeArquivo: childItem.val().commentResponderTypeArquivo == undefined ? '' : childItem.val().commentResponderTypeArquivo,
                                commentResponderIsAudio: childItem.val().commentResponderIsAudio == undefined ? '' : childItem.val().commentResponderIsAudio,
                                atendenteResponder: childItem.val().atendenteResponder == undefined ? '' : childItem.val().atendenteResponder,
                                deleteMessage: childItem.val().deleteMessage == undefined ? false : childItem.val().deleteMessage,
                            });
                        }
                    }
                });
                //ativaDesativaLoadingTelaAcoesAtendimento(dispatch, false);


                exportRelatorioAtendimentoParaPDF(chamado, dispatch, arrayAcoesAtendimento.reverse());
            });

            console.log('----------------no chamado--------------')
            console.log(chamado)
            let colunaPrincipal = ['Os Atendimentos ABAIXO são Provenientes do Relatório de Atendentes que MAIS atenderam!']
            let colunas = ['Quantidade de Atendimentos', 'Nome do Atendente'];


        })
    }
}
function exportRelatorioAtendimentoParaPDF(chamados, dispatch, conversas) {

    console.log(chamados)
    console.log(conversas);
    let timeline = '';
    for (let i = 0; i < conversas.length; i++) {
        if (conversas[i].tipo == 'mensagem') {
            timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
        } else {
            if (conversas[i].tipoArquivo != undefined && conversas[i].tipoArquivo != null && conversas[i].tipoArquivo != '') {
                if (conversas[i].tipoArquivo.includes('image')) {
                    if (conversas[i].urlDownload == undefined || conversas[i].urlDownload == null || conversas[i].urlDownload == '') {
                        timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
                    } else {
                        /*  imageToBase64(conversas[i].urlDownload) // Path to the image
                             .then(
                                 (response) => {
                                     console.log('imagem base64')
                                     console.log(response); // "cGF0aC90by9maWxlLmpwZw=="
                                 }
                             )
                             .catch(
                                 (error) => {
                                     console.log('imagem catch base64')
                                     console.log(error); // Logs an error if there was one
                                 }
                             ) */
                        timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': </p></div>';
                        timeline += '<div><img width="350" height="350" src=' + conversas[i].urlDownload + ' alt="Image"/></div>';


                    }
                } else {
                    timeline += '<div style="margin-top:10px"><p style="font-size: 15px">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
                }
            } else {
                timeline += '<div style="margin-top:10px"><p style="font-size: 13p5">(' + conversas[i].dataHoraAtual + ') ' + conversas[i].title + ': ' + conversas[i].description + '</p></div>';
            }
        }
    }
    try {
        //pegando data e hora para colocar no nome do arquivo para que nao haja repeticao de nome
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format("DD-MM-YYYY HH-mm-ss");
        console.log(timeline)







        const doc = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'a4'
        });
        //  relatorio${dataAtual}.pdf`
        let myTableTeste = '<div >' +
            '<h3 style="text-align: center">Relatório do atendimento #' + chamados[0].numeroChamado + '</h3>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:11px,font-weight:bold">Cliente: <label style="font-size:12px">' + chamados[0].cliente + ' </label> </label>' +
            '</div>'
            + '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Atendente:  <label style="font-size:12px">' + chamados[0].atendente + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Setor:  <label style="font-size:12px">' + chamados[0].setor + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Vencimento:  <label style="font-size:12px">' + chamados[0].dataVencimento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Problema:  <label style="font-size:12px">' + chamados[0].descricaoProblema + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Status:  <label style="font-size:12px">' + chamados[0].status + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Abertura:  <label style="font-size:12px">' + chamados[0].dataHoraAbertura + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Fechamento:  <label style="font-size:12px">' + chamados[0].dataHoraFechamento + '</label> </label>' +
            '</div>' +
            '<hr>' +
            '<div style="margin-bottom:10px">' +
            '<label style="font-size:12px,font-weight:bold">Cancelamento:  <label style="font-size:12px">' + chamados[0].dataHoraCancelamento + '</label> </label>' +
            '</div>' +
            '<hr>' +

            '<div style="margin-top:15px">' +
            '<h3 style="text-align: center">Comentarios do atendimento #' + chamados[0].numeroChamado + ' iniciado em ' + chamados[0].dataHoraAbertura + '</h3>' +
            timeline +
            '</div>' +
            '</div>';
        let opt = {
            filename: `relatorio${dataAtual}.pdf`,
            jsPDF: { orientation: 'p', unit: 'pt', format: 'a4' },
            html2canvas: {
                dpi: 300,
                letterRendering: true,
                useCORS: true
            }
        };


        html2pdf().from(myTableTeste).set(opt).toPdf().output('blob').then(function (pdfBlob) {
            window.open(URL.createObjectURL(pdfBlob));
        });



    } catch (err) {
        alert(err);
    }


}
export const novalistaInformacoesDashBoard = () => {
    return dispatch => {
        let dataHojeEnviaDatePicker = moment().toDate();
        dataHojeEnviaDatePicker = moment(dataHojeEnviaDatePicker);
        dispatch({ type: MODIFICADATAINICIALCONSULTADASHBOARD, payload: dataHojeEnviaDatePicker });
        dispatch({ type: MODIFICADATAFINALCONSULTADASHBOARD, payload: dataHojeEnviaDatePicker });
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        const comecoDaSemana = moment().startOf('week').toDate();
        const fimDaSemana = moment().endOf('week').toDate();
        let dataComecoSemana = moment(comecoDaSemana).format('DD/MM/YYYY');
        let dataFimSemana = moment(fimDaSemana).format('DD/MM/YYYY');
        let atendimentosAbertoHoje = 0;
        let atendimentosAbertoNaSemana = 0;
        let atendimentosAtendendoNaSemana = 0;
        let atendimentosFechadoNaSemana = 0;
        let atendimentosCanceladoNaSemana = 0;
        let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
        let atendimentosAbertoAtendendoPrioridadeNormal = 0;
        let atendimentosAbertoAtendendoPrioridadeAlta = 0;
        let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
        let tempoResolucaoChamadoMenorque1 = 0;
        let tempoResolucaoChamado1 = 0;
        let tempoResolucaoChamado2 = 0;
        let tempoResolucaoChamado3 = 0;
        let tempoResolucaoChamado4 = 0;
        let tempoResolucaoChamado5 = 0;
        let tempoResolucaoChamado6 = 0;
        let tempoResolucaoChamado7 = 0;
        let tempoResolucaoChamadoMaiorque7 = 0;
        let atendimentosPorLocalInterno = 0;
        let atendimentosPorLocalExterno = 0;
        let atendimentosPorLocal = [];
        let atendimentoAbertoStatus = 0;
        let atendimentoEmAtendimentoStatus = 0;
        let atendimentoFechadoStatus = 0;
        let atendimentoCanceladoStatus = 0;
        let arrayChamadosPorStatus = [];
        dataComecoSemana = dataAtual + ' 00:00:01';
        dataFimSemana = dataAtual + ' 23:59:59';
        let dataComecoSemanaTS = retornaDataTimeStamp(dataComecoSemana);
        let dataFimSemanaTS = retornaDataTimeStamp(dataFimSemana);
        //como converter uma data de timestamp em data normal
        let date2 = new Date(1562016662685).toUTCString();
        let dataAtualTimeStamp = retornaDataTimeStamp(dataAtual);
        console.log(dataAtual)
        console.log(dataAtualTimeStamp)

        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, true);
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            console.log(chaveUnica)
            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                console.log('key Usuario')
                console.log(keyUsuario)
                firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas`).on("value", snapshot => {
                    let arrayTarefas = [];
                    if (snapshot.val()) {
                        console.log('no lista tarefas')
                        console.log(snapshot.val())
                        snapshot.forEach(childItem => {
                            arrayTarefas.push({
                                key: childItem.key,
                                tarefa: childItem.val().tarefa,
                                feito: childItem.val().feito,
                            });
                        });
                        enviaTarefaParaReducer(dispatch, arrayTarefas);
                        console.log(arrayTarefas)
                    } else {
                        console.log('no else tarefas')
                        enviaTarefaParaReducer(dispatch, arrayTarefas);
                    }

                })
            })
            //envio periodo personalizado semana atual porque o padrao do relatório é da semana atual
            enviaPeriodoPersonalizado(dispatch, 'Hoje');
            enviaSemConexaoInternetDashBoard(dispatch, false);
            ativaDesativaLoadingTelaDashBoard(dispatch, true);
            // ativaDesativaLoadingRenderVersaoPremium(dispatch, true);
            let dataAtual = retornaDataAtual();
            const { currentUser } = firebase.auth();


            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                .startAt(dataComecoSemanaTS).endAt(dataFimSemanaTS).once("value").then((snapshot) => {
                    if (snapshot.val()) {
                        enviaAtendimentosDestaSemanaDashBoard(dispatch, snapshot.numChildren());

                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                        let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                        for (let i = 0; i < results.length; i++) {
                            if (results[i].dataAbertura == dataAtual) {
                                atendimentosAbertoHoje++
                            }
                            //por status
                            if (results[i].status == 'Aberto') {
                                atendimentosAbertoNaSemana++
                                //por prioridade
                                if (results[i].prioridade == 'Baixa') {
                                    atendimentosAbertoAtendendoPrioridadeBaixa++;
                                }
                                if (results[i].prioridade == 'Normal') {
                                    atendimentosAbertoAtendendoPrioridadeNormal++;
                                }
                                if (results[i].prioridade == 'Alta') {
                                    atendimentosAbertoAtendendoPrioridadeAlta++;
                                }
                                if (results[i].prioridade == 'Urgente') {
                                    atendimentosAbertoAtendendoPrioridadeUrgente++;
                                }
                            }
                            if (results[i].status == 'Atendendo') {
                                atendimentosAtendendoNaSemana++
                            }
                            if (results[i].status == 'Fechado') {
                                atendimentosFechadoNaSemana++
                                if (results[i].periodoResolucaoChamado == '<1') {
                                    tempoResolucaoChamadoMenorque1++
                                }
                                if (results[i].periodoResolucaoChamado == '1') {
                                    tempoResolucaoChamado1++
                                }
                                if (results[i].periodoResolucaoChamado == '2') {
                                    tempoResolucaoChamado2++
                                }
                                if (results[i].periodoResolucaoChamado == '3') {
                                    tempoResolucaoChamado3++
                                }
                                if (results[i].periodoResolucaoChamado == '4') {
                                    tempoResolucaoChamado4++
                                }
                                if (results[i].periodoResolucaoChamado == '5') {
                                    tempoResolucaoChamado5++
                                }
                                if (results[i].periodoResolucaoChamado == '6') {
                                    tempoResolucaoChamado6++
                                }
                                if (results[i].periodoResolucaoChamado == '7') {
                                    tempoResolucaoChamado7++
                                }
                                if (results[i].periodoResolucaoChamado == '>7') {
                                    tempoResolucaoChamadoMaiorque7++
                                }
                            }
                            if (results[i].status == 'Cancelado') {
                                atendimentosCanceladoNaSemana++
                            }
                            if (results[i].local == 'Interno') {
                                atendimentosPorLocalInterno++
                            }
                            if (results[i].local == 'Externo') {
                                atendimentosPorLocalExterno++
                            }

                        }
                        //envio chamados abertos hoje
                        enviaAtendimentosHojeDashBoard(dispatch, atendimentosAbertoHoje);


                        //implemento array para enviar os chamados por status
                        if (atendimentosAbertoNaSemana == 0) {
                            atendimentoAbertoStatus = 0;
                        } else {
                            atendimentoAbertoStatus = atendimentosAbertoNaSemana;

                        }

                        if (atendimentosAtendendoNaSemana == 0) {
                            atendimentoEmAtendimentoStatus = 0;
                        } else {
                            atendimentoEmAtendimentoStatus = atendimentosAtendendoNaSemana;

                        }
                        if (atendimentosFechadoNaSemana == 0) {
                            atendimentoFechadoStatus = 0;
                        } else {
                            atendimentoFechadoStatus = atendimentosFechadoNaSemana;
                        }
                        if (atendimentosCanceladoNaSemana == 0) {
                            atendimentoCanceladoStatus = 0;
                        } else {
                            atendimentoCanceladoStatus = atendimentosCanceladoNaSemana;

                        }

                        enviaAtendimentoAberto(dispatch, atendimentoAbertoStatus);
                        enviaAtendimentoCancelado(dispatch, atendimentoCanceladoStatus);
                        enviaAtendimentoFechado(dispatch, atendimentoFechadoStatus);
                        enviaAtendimentoEmAtendimento(dispatch, atendimentoEmAtendimentoStatus);


                        //envio chamados por prioridades
                        /*    let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                               atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                               atendimentosAbertoAtendendoPrioridadeUrgente;
                           enviaTotalAtendimentosPorPRioridade(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                        if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                            atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                            atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                            atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                        }
                        if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                            atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                        }

                        let atendimentosPorPrioridadeEnviaDashBoard = [
                            { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                        enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                        if (tempoResolucaoChamadoMenorque1 == 0) {
                            tempoResolucaoChamadoMenorque1 = 0.5
                        }
                        if (tempoResolucaoChamado1 == 0) {
                            tempoResolucaoChamado1 = 0.5
                        }
                        if (tempoResolucaoChamado2 == 0) {
                            tempoResolucaoChamado2 = 0.5
                        }
                        if (tempoResolucaoChamado3 == 0) {
                            tempoResolucaoChamado3 = 0.5
                        }
                        if (tempoResolucaoChamado4 == 0) {
                            tempoResolucaoChamado4 = 0.5
                        }
                        if (tempoResolucaoChamado5 == 0) {
                            tempoResolucaoChamado5 = 0.5
                        }
                        if (tempoResolucaoChamado6 == 0) {
                            tempoResolucaoChamado6 = 0.5
                        }
                        if (tempoResolucaoChamado7 == 0) {
                            tempoResolucaoChamado7 = 0.5
                        }
                        if (tempoResolucaoChamadoMaiorque7 == 0) {
                            tempoResolucaoChamadoMaiorque7 = 0.5
                        }
                        let atendimentosPorTempOResolucao = [
                            { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                        enviaTempoResolucaoChamados(dispatch, atendimentosPorTempOResolucao);
                        //   enviaTotalAtendimentosPeriodoResolucao(dispatch, atendimentosFechadoNaSemana); 
                        //atendimentos interno e externo
                        if (atendimentosPorLocalInterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                            })
                        }
                        if (atendimentosPorLocalExterno == 0) {
                            atendimentosPorLocal.push({
                                value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })

                        } else {
                            atendimentosPorLocal.push({
                                value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                            })
                        }

                        // let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, totalAtendimentosPorLocal)
                        enviaAtendimentosPorLocalDashboard(dispatch, atendimentosPorLocal)
                        //envio total de clientes

                        //envio total de chamados

                        console.log('ONDE FICA O LOADING')
                        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                        ativaDesativaLoadingTelaDashBoard(dispatch, false);
                        ativaDesativaLoadingRefreshControol(dispatch, false);
                    } else {
                        //sem informacoes
                        enviaAtendimentosDestaSemanaDashBoard(dispatch, 0);
                        enviaAtendimentosHojeDashBoard(dispatch, 0);
                        enviaAtendimentoAberto(dispatch, 0);
                        enviaAtendimentoCancelado(dispatch, 0);
                        enviaAtendimentoFechado(dispatch, 0);
                        enviaAtendimentoEmAtendimento(dispatch, 0);

                        let atendimentoPorPrioridadeZerado = [
                            { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                        ]


                        enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentoPorPrioridadeZerado);
                        //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                        let atendimentoPorResolucaoZerado = [
                            { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                            { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                            { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                            { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                            { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                            { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                        enviaTempoResolucaoChamados(dispatch, atendimentoPorResolucaoZerado);
                        // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                        let atendimentoPorLocalZerado = [
                            { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                            { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                        ];
                        // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                        enviaAtendimentosPorLocalDashboard(dispatch, atendimentoPorLocalZerado)

                        ativaDesativaLoadingTelaDashBoard(dispatch, false);

                        ativaDesativaLoadingRefreshControol(dispatch, false);
                        console.log('ONDE FICA O LOADING')
                        ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                    }
                })

        })


    }
}
export const insereLogoECorAtravesDaReferencia = (codigoReferencia3) => {
    let codigoReferencia2 = codigoReferencia3.trim();
    return dispatch => {
        let codigoReferencia = parseInt(codigoReferencia2);
        modificaLoadingLoginLocal(dispatch, true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
            },

            data: { codigoReferencia: codigoReferencia },
            url: 'https://us-central1-dark-4b307.cloudfunctions.net/getUniqueKeyFromIdCompany',
        };
        axios(options).then((snapshot) => {
            snapshot = snapshot.data;
            if (snapshot !== null) {
                const arrayOfKeys = Object.keys(snapshot);
                const result = snapshot[arrayOfKeys[0]];
                const numeroExclusivoCadastraCliente = result.numeroExclusivoCadastraCliente;
                const chaveUnica = result.chaveUnica;
                const optionsTheme = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8',
                        'Accept': 'application/json',
                    },

                    data: { chaveUnica: chaveUnica },
                    url: 'https://us-central1-dark-4b307.cloudfunctions.net/getTheme',
                };
                axios(optionsTheme).then((snapshot) => {
                    const { snapshotColor, snapshotLogo } = snapshot.data;
                    let backgroundPrimeiro;
                    let backgroundSegundo;
                    let backgroundMaisEscuro;
                    let coresLetraPadrao;
                    let coresLetraSecundario;
                    let corBotaoPadrao;
                    let letraBotaoPadrao;
                    let corSegundoBotaoPadrao;
                    let corLetraDescricaoDosCampos;
                    let corFundoMenu;
                    let corLetraMenu;
                    if (snapshotColor !== null) {
                        const arrayOfKeys = Object.keys(snapshotColor);
                        const colors = snapshotColor[arrayOfKeys[0]];
                        backgroundPrimeiro = colors.backgroundPrimeiro;
                        backgroundSegundo = colors.backgroundSegundo;
                        backgroundMaisEscuro = colors.backgroundMaisEscuro;
                        coresLetraPadrao = colors.coresLetraPadrao;
                        coresLetraSecundario = colors.coresLetraSecundario;
                        corBotaoPadrao = colors.corBotaoPadrao;
                        letraBotaoPadrao = colors.letraBotaoPadrao;
                        corSegundoBotaoPadrao = colors.corSegundoBotaoPadrao;
                        corLetraDescricaoDosCampos = colors.corLetraDescricaoDosCampos;
                        corFundoMenu = colors.corFundoMenu;
                        corLetraMenu = colors.corLetraMenu;
                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        localStorage.setItem('backgroundPrimeiro', JSON.stringify(backgroundPrimeiro));
                        localStorage.setItem('corBotaoPadrao', JSON.stringify(corBotaoPadrao));
                        localStorage.setItem('backgroundSegundo', JSON.stringify(backgroundSegundo))
                        localStorage.setItem('coresLetraPadrao', JSON.stringify(coresLetraPadrao))
                        localStorage.setItem('letraBotaoPadrao', JSON.stringify(letraBotaoPadrao))
                        modificaLoadingLoginLocal(dispatch, false)

                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        modificaLoadingLoginLocal(dispatch, false)
                    }
                    if (snapshotLogo !== null) {
                        const arrayOfKeys = Object.keys(snapshotLogo);
                        const logo = snapshotLogo[arrayOfKeys[0]];
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: logo.uri
                        })
                        localStorage.setItem('logoLogin', JSON.stringify(logo.uri))
                        // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: 'semURI'
                        })
                        //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }

                })
            } else {
                console.log('nao achou referencia');
                dispatch({
                    type: MODIFICALOGOTIPOTELACONFIGURACAO,
                    payload: 'semURI'
                })
                modificaLoadingLoginLocal(dispatch, false)
            }
        }).catch((err) => {
            console.log(err);
            modificaLoadingLoginLocal(dispatch, false)
        })
    }
}
export const listaConfiguracoesDaEmpresa = (history) => {
    return dispatch => {
        let nome = '';
        let keyUsuario = '';
        let dataAtual = moment().toDate();
        dataAtual = moment(dataAtual).format('DD/MM/YYYY');
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            modificaLoadingConfiguracoesEmpresa(dispatch, true);
            if (chaveUnica2 == null) {

                firebase.database().ref(`usuariosALL/${firebase.auth().currentUser.uid}`).once('value').then((snapshot) => {
                    console.log(snapshot.val())
                    if (snapshot.val()) {
                        /*  console.log(`chave unica nula`) */
                        const dadosUsuario = _.first(_.values(snapshot.val()));
                        let chaveUnica = dadosUsuario.chaveUnica;
                        //se quem tiver logando for admin, pode logar normalmente, caso seja cliente vai para o else
                        if (dadosUsuario.admin == 0) {
                            console.log(chaveUnica)
                            localStorage.setItem(`chaveUnicaCliente`, JSON.stringify(chaveUnica));
                            ///verifica o ultimo chamado averto via chat
                            firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                                if (snapshot.val()) {
                                    // let key = Object.keys(snapshot.val()).toString();
                                    let dadosUsuarioBanco = [];
                                    snapshot.forEach((childItem) => {
                                        dadosUsuarioBanco.push({
                                            key: childItem.key,
                                        })
                                    })
                                    firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("abertoPorChat").equalTo(`${dadosUsuarioBanco[0].key}Chat`).limitToLast(1).once("value").then((snapshot) => {
                                        /*   console.log('ultimo chamado do cliente via chat'); */
                                        let keyUltimoChamadoAbertoViaChat = '';
                                        let statusAtualChamado = '';
                                        snapshot.forEach((childItem) => {
                                            keyUltimoChamadoAbertoViaChat = childItem.key;
                                            /*  console.log(keyUltimoChamadoAbertoViaChat) */
                                            statusAtualChamado = childItem.val().status;
                                            let atendenteChamado = childItem.val().atendente;
                                            let status_atendente_abertoviachat = childItem.val().status_atendente_abertoviachat;
                                            let valueStatusAbertoViaChat = `Atendendo${atendenteChamado}true`;
                                            console.log(valueStatusAbertoViaChat)
                                            /*  console.log(statusAtualChamado) */
                                            if (statusAtualChamado == 'Aberto' || statusAtualChamado == 'Atendendo') {
                                                if (status_atendente_abertoviachat.length == 0) {
                                                    console.log('entrou onde nao era pra entrar')
                                                } else {
                                                    if (statusAtualChamado == 'Aberto') {
                                                        //indicando posicao da fila
                                                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat")
                                                            .equalTo("AbertoNão informadotrue")
                                                            .on("value", snapshot => {
                                                                if (snapshot.val() == null) {
                                                                    /*   console.log('snapshot null posicao fila') */
                                                                    dispatch({ type: MODIFICAPOSICAOATUALFILA, payload: 1 });
                                                                } else {
                                                                    // changing to reverse chronological order (latest first)
                                                                    let arrayOfKeys = Object.keys(snapshot.val());
                                                                    let posicaoFila = Object.keys(snapshot.val()).indexOf(keyUltimoChamadoAbertoViaChat);
                                                                    /*     console.log(arrayOfKeys); */
                                                                    posicaoFila = posicaoFila + 1;
                                                                    /*  console.log(posicaoFila) */
                                                                    // transforming to array
                                                                    dispatch({ type: MODIFICAPOSICAOATUALFILA, payload: posicaoFila });
                                                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                                                    /* console.log(results); */
                                                                }
                                                            })
                                                    }
                                                    /*  console.log('envia os dados para o chat'); */
                                                    dispatch({
                                                        type: MODIFICAKEYATENDIMENTOCHAT,
                                                        payload: keyUltimoChamadoAbertoViaChat
                                                    });
                                                    dispatch({ type: MODIFICATELAATUALCHAT, payload: 'aguardandoAtendimento' })
                                                    listaChamadoParaTelaAcoesChamadoExport(dispatch, keyUltimoChamadoAbertoViaChat);
                                                    dispatch({ type: MODIFICAATENDIMENTOONLINEVISIBLE, payload: true })
                                                }
                                            } else {
                                                /*  console.log('nao envia nada para o chat') */
                                            }

                                        })

                                    })
                                }
                            })
                            firebase.database().ref(`status_server`).on("value", snapshot => {
                                if (snapshot.val() !== null) {
                                    snapshot.forEach(childItem => {
                                        let isMaintenance = childItem.val().isMaintenance;
                                        let lastVersionAvailableCLI = childItem.val().lastVersionAvailableCLI;
                                        dispatch({ type: UPDTEISMAINTENANCE, payload: isMaintenance })
                                        dispatch({ type: UPDTELASTVERSIONAVAILABLE, payload: lastVersionAvailableCLI })
                                        if (childItem.val().isLogout) {
                                            let keysToRemove = ["loginCliente", "chaveUnicaCliente", "tabindexCliente", "menuOpenCliente", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "logoLogin", "backgroundSegundo", "coresLetraPadrao", "letraBotaoPadrao"];
                                            for (const key of keysToRemove) {
                                                localStorage.removeItem(key);
                                            }
                                            firebase.auth().signOut();
                                        }
                                    })
                                }
                            })

                            firebase.database().ref(`${chaveUnica}/empresa/`).on('value', snapshotPremium => {
                                /*  console.log('-------------------------------------------') */
                                let arrayEmpresa = [];
                                snapshotPremium.forEach(childItem => {
                                    arrayEmpresa.push({
                                        premium: childItem.val().premium,
                                        nomeEmpresa: childItem.val().empresa,
                                        licensawebouapp: childItem.val().licensawebouapp == undefined ? 1 : childItem.val().licensawebouapp,
                                    })
                                })
                                /*   console.log(snapshotPremium.key)
                                  console.log('verifica se é premium')
                                  console.log(arrayEmpresa[0].premium) */
                                enviaSeEhVersaoPremium(dispatch, arrayEmpresa[0].premium);
                                modificaLicensawebouapp(dispatch, arrayEmpresa[0].licensawebouapp);
                                dispatch({ type: MODIFICANOMEEMPRESACONFIGURAEMPRESA, payload: arrayEmpresa[0].nomeEmpresa })
                                firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on('value', snapshot => {
                                    const dadosConfiguracaoAtendimento = [];
                                    snapshot.forEach(childItem => {
                                        dadosConfiguracaoAtendimento.push({
                                            key: childItem.key,
                                            agruparAtendenteSetor: childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor,
                                            criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico,
                                        })
                                        verificaAgruparAtendenteSetor(dispatch, dadosConfiguracaoAtendimento[0].agruparAtendenteSetor);
                                        verificaCriarChamadoProblemaEstatico(dispatch, dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico);

                                    })
                                })
                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
                                let backgroundPrimeiro;
                                let backgroundSegundo;
                                let backgroundMaisEscuro;
                                let coresLetraPadrao;
                                let coresLetraSecundario;
                                let corBotaoPadrao;
                                let letraBotaoPadrao;
                                let corSegundoBotaoPadrao;
                                let corLetraDescricaoDosCampos;
                                let corFundoMenu;
                                let corLetraMenu;
                                let corPrincipalChat;
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                                        backgroundSegundo = childItem.val().backgroundSegundo;
                                        backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                                        coresLetraPadrao = childItem.val().coresLetraPadrao;
                                        coresLetraSecundario = childItem.val().coresLetraSecundario;
                                        corBotaoPadrao = childItem.val().corBotaoPadrao;
                                        letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                                        corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                                        corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                                        corFundoMenu = childItem.val().corFundoMenu;
                                        corLetraMenu = childItem.val().corLetraMenu;
                                        corPrincipalChat = childItem.val().corPrincipalChat;
                                    })
                                    /*  console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                         corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                                    dispatch({
                                        type: MODIFICACORFUNDOPRINCIPAL,
                                        payload: backgroundPrimeiro
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOSECUNDARIO,
                                        payload: backgroundSegundo
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                                        payload: backgroundMaisEscuro
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                                        payload: corBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                                        payload: corSegundoBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAPADRAO,
                                        payload: letraBotaoPadrao
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAPRINCIPAL,
                                        payload: coresLetraPadrao
                                    })

                                    dispatch({
                                        type: MODIFICACORLETRASECUNDARIA,
                                        payload: coresLetraSecundario
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                                        payload: corLetraDescricaoDosCampos
                                    })
                                    dispatch({
                                        type: MODIFICACORFUNDOMENU,
                                        payload: corFundoMenu
                                    })
                                    dispatch({
                                        type: MODIFICACORLETRAMENU,
                                        payload: corLetraMenu
                                    })
                                    dispatch({
                                        type: MODIFICACORPRINCIPALCHAT,
                                        payload: corPrincipalChat
                                    })
                                    localStorage.setItem('backgroundPrimeiro', JSON.stringify(backgroundPrimeiro))
                                    localStorage.setItem('corBotaoPadrao', JSON.stringify(corBotaoPadrao))
                                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                                } else {
                                }

                            })
                            let primeiraVerificacaoExecucao = false;
                            let children2 = [];
                            firebase.database().ref(`${chaveUnica}/setores`).orderByKey().limitToLast(1).on("value", snapshot => {
                                if (snapshot.val() == null) {
                                    const arrayUser = [];

                                    enviaSetorParaReducer(dispatch, arrayUser);

                                } else {
                                    let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                    let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                    let aux = 0;
                                    results.forEach(childItem => {
                                        let permiteClienteSelecionarSetor = childItem.permiteClienteSelecionarSetor === undefined ? true : childItem.permiteClienteSelecionarSetor;
                                        if (childItem.excluido == 1 || childItem.nome == undefined || childItem.value === referenceToOldestKey || permiteClienteSelecionarSetor === false) {
                                            //console.log('usuario excluido')
                                        } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {
                                        } else {
                                            children2.push({
                                                value: arrayOfKeys[aux],
                                                name: childItem.nome
                                            });
                                        }
                                        aux++
                                    });
                                    let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';
                                    if (primeiraVerificacaoExecucao === true) {
                                        // const items = [{ children: children2 }];
                                        const items = [{
                                            name: 'SETORES',
                                            type: 'group', items: children2
                                        }];
                                        enviaSetorParaReducer(dispatch, items);


                                    } else {
                                        firebase.database().ref(`${chaveUnica}/setores`).orderByKey().endAt(referenceToOldestKey).once("value")
                                            .then(snapshot => {
                                                if (snapshot.val() == null) {
                                                    primeiraVerificacaoExecucao = true;
                                                    let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                                    const items = [{
                                                        name: 'SETORES',
                                                        type: 'group', items: arrayOrdernado
                                                    }];
                                                    enviaSetorParaReducer(dispatch, items);


                                                } else {
                                                    primeiraVerificacaoExecucao = true;
                                                    let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                                                    let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                                    let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                                    let aux = 0;

                                                    results.forEach(childItem => {
                                                        let permiteClienteSelecionarSetor = childItem.permiteClienteSelecionarSetor === undefined ? true : childItem.permiteClienteSelecionarSetor;
                                                        if (childItem.excluido == 1 || childItem.nome == undefined || permiteClienteSelecionarSetor === false) {
                                                            //console.log('usuario excluido')
                                                        } else {
                                                            children2.push({
                                                                value: arrayOfKeys[aux],
                                                                name: childItem.nome
                                                            });
                                                        }
                                                        aux++
                                                    });
                                                    let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                                    const items = [{
                                                        name: 'SETORES',
                                                        type: 'group', items: arrayOrdernado
                                                    }];
                                                    enviaSetorParaReducer(dispatch, items);


                                                }
                                            })
                                    }
                                }
                            });
                            firebase
                                .database()
                                .ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).on("value", snapshot => {
                                    if (snapshot.val() == null) {
                                        const arrayUser = [];
                                        enviaProblemasEstaticosParaReducer(dispatch, arrayUser);
                                    } else {
                                        const children2 = [];
                                        snapshot.forEach(childItem => {

                                            children2.push({
                                                value: childItem.key,
                                                name: childItem.val().descricaoProblema
                                            });

                                        });
                                        const items = [{
                                            name: 'PROBLEMAS',
                                            type: 'group', items: children2
                                        }];
                                        //  const items = [{ children: children2 }];
                                        enviaProblemasEstaticosParaReducer(dispatch, items);
                                    }
                                });
                            let uidUsuarioLogado = firebase.auth().currentUser.uid;
                            //pego key de quem Criou o aviso
                            firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(uidUsuarioLogado).once("value").then((snapshot) => {
                                if (snapshot.val()) {
                                    let arrayKeyQuemCriouAviso = [];
                                    snapshot.forEach(childItem => {
                                        arrayKeyQuemCriouAviso.push({
                                            keyQuemAbriuAviso: childItem.key
                                        })
                                    })
                                    /*    console.log('dentro do lista avisos tela dash board'); */
                                    let dataAtual = moment().toDate();
                                    dataAtual = moment(dataAtual).format('YYYY-MM-DD HH:mm');
                                    /*   console.log('----------------comeco do lista avisos------------------------') */
                                    let keyUsuarioLogado = arrayKeyQuemCriouAviso[0].keyQuemAbriuAviso;
                                    /*   console.log(keyUsuarioLogado); */
                                    firebase.database().ref(`${chaveUnica}/avisos/`).orderByChild('ativo').equalTo(true).on("value", snapshot => {
                                        /*   console.log(snapshot.val()); */
                                        let arrayAvisos = [];
                                        snapshot.forEach(childItem => {
                                            if (childItem.val().destinatario == 2) {
                                                if (childItem.val().cliente == 'TodosPadrao' || childItem.val().cliente == keyUsuarioLogado) {
                                                    if (childItem.val().porFaixaHorario == true) {
                                                        let dataHoraInicial = childItem.val().dataInicial;
                                                        let dataHoraFinal = childItem.val().dataFinal;
                                                        let dataHoraInicialVerificacao = moment(dataHoraInicial, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                                                        let dataHoraFinalVerificacao = moment(dataHoraFinal, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                                                        /* console.log(dataHoraInicial);
                                                        console.log(dataHoraFinal);
                                                        console.log(dataAtual);
                                                        console.log(dataHoraInicialVerificacao);
                                                        console.log(dataHoraFinalVerificacao);
                                                        console.log(childItem.key) */
                                                        let vigorData = moment(dataAtual).isBetween(dataHoraInicialVerificacao, dataHoraFinalVerificacao);
                                                        let dataInicialIgualDataAtual = moment(dataHoraInicialVerificacao).isSame(dataAtual);
                                                        let VerificaSeDataInicialEhDepoisDataAtual = moment(dataHoraInicialVerificacao).isAfter(dataAtual);
                                                        /*   console.log('vigor da data: ' + vigorData); */
                                                        let alterado = childItem.val().alterado + 1;
                                                        if (vigorData || dataInicialIgualDataAtual) {
                                                            //então o aviso ainda está no prazo correto
                                                            arrayAvisos.push({
                                                                key: childItem.key,
                                                                destinatario: childItem.val().destinatario,
                                                                cliente: childItem.val().cliente,
                                                                atendente: childItem.val().atendente,
                                                                aviso: childItem.val().aviso,
                                                                keyDeQuemAbriuAviso: childItem.val().keyDeQuemAbriuAviso,
                                                                ativo: childItem.val().ativo,
                                                                porFaixaHorario: childItem.val().porFaixaHorario,
                                                                dataInicial: childItem.val().dataInicial,
                                                                dataFinal: childItem.val().dataFinal,
                                                                alterado: childItem.val().alterado
                                                            })
                                                        } else if (VerificaSeDataInicialEhDepoisDataAtual) {
                                                            /*   console.log(`a data inicial ainda nao entrou em vigor ou seja a data inicial é depois da data atual`) */
                                                        }
                                                        else {
                                                            firebase.database().ref(`${chaveUnica}/avisos/${childItem.key}`).update({
                                                                ativo: false,
                                                                alterado
                                                            })
                                                        }
                                                    } else {
                                                        arrayAvisos.push({
                                                            key: childItem.key,
                                                            destinatario: childItem.val().destinatario,
                                                            cliente: childItem.val().cliente,
                                                            atendente: childItem.val().atendente,
                                                            aviso: childItem.val().aviso,
                                                            keyDeQuemAbriuAviso: childItem.val().keyDeQuemAbriuAviso,
                                                            ativo: childItem.val().ativo,
                                                            porFaixaHorario: childItem.val().porFaixaHorario,
                                                            dataInicial: childItem.val().dataInicial,
                                                            dataFinal: childItem.val().dataFinal,
                                                            alterado: childItem.val().alterado
                                                        })
                                                    }
                                                }
                                            }

                                        })
                                        /*   console.log(arrayAvisos); */
                                        enviaAvisoParaTelaDashBoard(dispatch, arrayAvisos);
                                        /*  console.log('-----------------fim do lista avisos-----------------------') */
                                    })
                                } else {
                                    /*   console.log(`sem dados de aviso`) */
                                }
                            })

                            firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        keyUsuario = childItem.key;
                                        nome = childItem.val().nome;
                                        enviaNomeParaReducer(dispatch, childItem.val().nome);
                                        enviaEmailParaReducer(dispatch, childItem.val().email);
                                        enviaEmpresaParaReducer(dispatch, childItem.val().empresa);
                                        enviaSenhaParaReducer(dispatch, childItem.val().senha);
                                        enviaConfirmaSenhaParaReducer(dispatch, childItem.val().senha);
                                        modificaEmailClienteTelaCadastroClienteLOCAL(dispatch, childItem.val().email);
                                        if (childItem.val().urlImagemNuvem == undefined || childItem.val().urlImagemNuvem == null || childItem.val().urlImagemNuvem == '') {
                                            if (childItem.val().nome.length > 0) {
                                                const primeiraLetra = childItem.val().nome.substring(0, 1).trim().toLowerCase();
                                                /*  console.log('antes do verifica se eh alphabeto') */
                                                verificaSeEhAlphabeto(primeiraLetra, dispatch);
                                                dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });

                                            } else {
                                                const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                                enviaImagemPerfil(dispatch, fotoURI)
                                                dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                            }
                                        } else {
                                            enviaImagemPerfil(dispatch, childItem.val().urlImagemNuvem)
                                            dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                        }
                                    })


                                    /*    firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                                           enviaImagemPerfil(dispatch, url);
                                           dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                       }).catch(() => {
                                           const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                                           firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                               enviaImagemPerfil(dispatch, url);
                                               dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                           }).catch(() => {
                                               const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af';
                                               enviaImagemPerfil(dispatch, fotoURI);
                                               dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                           })
                                       }) */


                                } else {
                                }
                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot2 => {
                                let dadosConfigChat = [];
                                let diaDaSemana = '';
                                if (snapshot2.val()) {
                                    snapshot2.forEach(childItem => {
                                        dadosConfigChat.push({
                                            vigienciaSegundaFeira: childItem.val().vigienciaSegundaFeira,
                                            vigienciaTercaFeira: childItem.val().vigienciaTercaFeira,
                                            vigienciaQuartaFeira: childItem.val().vigienciaQuartaFeira,
                                            vigienciaQuintaFeira: childItem.val().vigienciaQuintaFeira,
                                            vigienciaSextaFeira: childItem.val().vigienciaSextaFeira,
                                            vigienciaSabadoFeira: childItem.val().vigienciaSabadoFeira,
                                            vigienciaDomingoFeira: childItem.val().vigienciaDomingoFeira,
                                            horarioOperacaoSegundaFeira: childItem.val().horarioOperacaoSegundaFeira,
                                            horarioOperacaoSegundaFeira2: childItem.val().horarioOperacaoSegundaFeira2,
                                            horarioOperacaoTercaFeira: childItem.val().horarioOperacaoTercaFeira,
                                            horarioOperacaoTercaFeira2: childItem.val().horarioOperacaoTercaFeira2,
                                            horarioOperacaoQuartaFeira: childItem.val().horarioOperacaoQuartaFeira,
                                            horarioOperacaoQuartaFeira2: childItem.val().horarioOperacaoQuartaFeira2,
                                            horarioOperacaoQuintaFeira: childItem.val().horarioOperacaoQuintaFeira,
                                            horarioOperacaoQuintaFeira2: childItem.val().horarioOperacaoQuintaFeira2,
                                            horarioOperacaoSextaFeira: childItem.val().horarioOperacaoSextaFeira,
                                            horarioOperacaoSextaFeira2: childItem.val().horarioOperacaoSextaFeira2,
                                            horarioOperacaoSabadoFeira: childItem.val().horarioOperacaoSabadoFeira,
                                            horarioOperacaoSabadoFeira2: childItem.val().horarioOperacaoSabadoFeira2,
                                            horarioOperacaoDomingoFeira: childItem.val().horarioOperacaoDomingoFeira,
                                            horarioOperacaoDomingoFeira2: childItem.val().horarioOperacaoDomingoFeira2,
                                            utilizarChat: childItem.val().utilizarChat,
                                            utilizar2Horarios: childItem.val().utilizar2Horarios,
                                            fusoHorario: childItem.val().fusoHorario === undefined ? "America/Sao_Paulo" : childItem.val().fusoHorario,
                                        })
                                    })
                                    /*  console.log('---- dentro do config chat -----------')
                                     console.log(dadosConfigChat[0]) */
                                    moment.locale('pt-br', {
                                        weekdays: 'Domingo_Segunda_Terca_Quarta_Quinta_Sexta_Sabado'.split('_')
                                    })
                                    //HOJE
                                    // let dataHoje = momentz().tz(moment(), 'America/Manaus').format('DD/MM/YYYY HH:mm')
                                    let dataHoje = moment().toDate();
                                    let horarioAtualBaseadoFusoHorarioConfigurado = toTimeZone(dataHoje, dadosConfigChat[0].fusoHorario);
                                    /*    console.log(horarioAtualBaseadoFusoHorarioConfigurado)
                                       console.log(dataHoje) */
                                    dataHoje = moment(dataHoje).format('dddd');
                                    /*  console.log('nome do dia');
                                     console.log(dataHoje) */

                                    let utilizarChat = dadosConfigChat[0].utilizarChat;
                                    let utiliza2Horarios = dadosConfigChat[0].utilizar2Horarios;

                                    if (utilizarChat == true) {
                                        //aqui pode esta online ou offline
                                        //tenho que verificar o periodo de exibicao, pra verificar se coloco como online ou offline
                                        if (dataHoje == 'Domingo') {
                                            /*   console.log('dia de Hoje Domingo') */
                                            if (dadosConfigChat[0].vigienciaDomingoFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira2.end, "HH:mm");
                                                    /*   console.log(startTime)
                                                      console.log(endTime)
                                                      console.log(startTime2)
                                                      console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.end, "HH:mm");
                                                    /*  console.log(startTime)
                                                     console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /* console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else if (dataHoje == 'Segunda') {
                                            /*  console.log('dia de Hoje Segunda') */
                                            if (dadosConfigChat[0].vigienciaSegundaFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira2.end, "HH:mm");
                                                    /*     console.log(startTime)
                                                        console.log(endTime)
                                                        console.log(startTime2)
                                                        console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*   console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.end, "HH:mm");
                                                    /*   console.log(startTime)
                                                      console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /*      console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else if (dataHoje == 'Terca') {
                                            /*  console.log('dia de Hoje Terca') */
                                            if (dadosConfigChat[0].vigienciaTercaFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoTercaFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoTercaFeira2.end, "HH:mm");
                                                    /*  console.log(startTime)
                                                     console.log(endTime)
                                                     console.log(startTime2)
                                                     console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.end, "HH:mm");
                                                    /*      console.log(startTime)
                                                         console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /*    console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else if (dataHoje == 'Quarta') {
                                            /*   console.log('dia de Hoje Quarta') */
                                            if (dadosConfigChat[0].vigienciaQuartaFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira2.end, "HH:mm");
                                                    /*    console.log(startTime)
                                                       console.log(endTime)
                                                       console.log(startTime2)
                                                       console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.end, "HH:mm");
                                                    /*    console.log(startTime)
                                                       console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /*      console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else if (dataHoje == 'Quinta') {
                                            /*   console.log('dia de Hoje Quinta') */
                                            if (dadosConfigChat[0].vigienciaQuintaFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    /*     console.log('dentro da verificacao 2 horarios quinta feira') */
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira2.end, "HH:mm");
                                                    /*  console.log(startTime)
                                                     console.log(endTime)
                                                     console.log(startTime2)
                                                     console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    /*   console.log('dentro da verificacao quinta feira') */
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.end, "HH:mm");
                                                    /*    console.log(startTime)
                                                       console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /*   console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }

                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else if (dataHoje == 'Sexta') {
                                            /*    console.log('dia de Hoje Sexta') */
                                            if (dadosConfigChat[0].vigienciaSextaFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    /*     console.log('dentro da verificacao 2 horarios sexta feira') */
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSextaFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSextaFeira2.end, "HH:mm");
                                                    /*    console.log(startTime)
                                                       console.log(endTime)
                                                       console.log(startTime2)
                                                       console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    /*  console.log('dentro da verificacao sexta feira') */
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.end, "HH:mm");
                                                    /*    console.log(startTime)
                                                       console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /*  console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        } else {
                                            /*  console.log('dia de Hoje Sabado') */
                                            if (dadosConfigChat[0].vigienciaSabadoFeira == true) {
                                                if (utiliza2Horarios == true) {
                                                    //verifico se a hora de agora está entre os dois horarios
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.end, "HH:mm");
                                                    let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira2.start, "HH:mm");
                                                    let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira2.end, "HH:mm");
                                                    /*   console.log(startTime)
                                                      console.log(endTime)
                                                      console.log(startTime2)
                                                      console.log(endTime2) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                                    /* console.log(chatDisponivel) */
                                                    if (chatDisponivel === true || chatDisponivel2 === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                } else {
                                                    //verifico se a hora de agora está entro o primeiro horario
                                                    let startTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.start, "HH:mm");
                                                    let endTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.end, "HH:mm");
                                                    /* console.log(startTime)
                                                    console.log(endTime) */
                                                    let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                                    /* console.log(chatDisponivel) */
                                                    if (chatDisponivel === true) {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                                    } else {
                                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                                    }
                                                }
                                            } else {
                                                modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                            }
                                        }
                                        // modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                    } else {
                                        //aqui ele desabilitou o chat
                                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'naocfg');
                                    }
                                    dispatch({ type: ALTERAVALORCHECKSEGUNDAEIRA, payload: dadosConfigChat[0].vigienciaSegundaFeira })
                                    dispatch({ type: ALTERAVALORCHECKTERCAEIRA, payload: dadosConfigChat[0].vigienciaTercaFeira })
                                    dispatch({ type: ALTERAVALORCHECKQUARTAEIRA, payload: dadosConfigChat[0].vigienciaQuartaFeira })
                                    dispatch({ type: ALTERAVALORCHECKQUINTAEIRA, payload: dadosConfigChat[0].vigienciaQuintaFeira })
                                    dispatch({ type: ALTERAVALORCHECKSEXTAEIRA, payload: dadosConfigChat[0].vigienciaSextaFeira })
                                    dispatch({ type: ALTERAVALORCHECKSABADOEIRA, payload: dadosConfigChat[0].vigienciaSabadoFeira })
                                    dispatch({ type: ALTERAVALORCHECKDOMINGOEIRA, payload: dadosConfigChat[0].vigienciaDomingoFeira })
                                    dispatch({ type: ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT, payload: dadosConfigChat[0].utilizar2Horarios })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA, payload: dadosConfigChat[0].horarioOperacaoTercaFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoTercaFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSextaFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSextaFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira2 })

                                    dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira })
                                    dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira2 })
                                    dispatch({ type: MODIFICAFUSOHORARIOSELECIONADO, payload: dadosConfigChat[0].fusoHorario === undefined ? 'America/Sao_Paulo' : dadosConfigChat[0].fusoHorario })


                                } else {
                                    /* console.log('sem dados chat online, envia nao cfg') */
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'naocfg');
                                }

                            })
                            firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                if (snapshot.val()) {
                                    let qtdNotificacoes = 0;
                                    snapshot.forEach((childItem) => {
                                        keyUsuario = childItem.key;
                                    })
                                    /*  firebase
                                         .database()
                                         .ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes`).limitToLast(1)
                                         .on('child_added', function (childSnapshot, prevChildName) {
                                             if (snapshot.val()) {
                                                 if (qtdNotificacoes == 0) {
 
                                                 } else {
                                                     const sound = new Howl({
                                                         src: [soundnotification],
                                                         volume: 0.5,
                                                         //autoplay: true
                                                     });
 
                                                     sound.play();
 
                                                 }
                                                 qtdNotificacoes = 1;
                                             }
                                         })
                                     firebase
                                         .database()
                                         .ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes`)
                                         .orderByKey()
                                         .limitToLast(30)
                                         .on("value", snapshot => {
                                             if (snapshot.val()) {
                                                 let notificacoes = [];
                                                 let notificacoesNaoLidas = 0;
                                                
                                                 snapshot.forEach(childItem => {
                                                     notificacoes.push({
                                                         key: childItem.key,
                                                         title: childItem.val().title,
                                                         body: childItem.val().body,
                                                         keyChamado: childItem.val().keyChamado,
                                                         numeroChamado: childItem.val().numeroChamado,
                                                         lido: childItem.val().lido,
                                                         clicked: childItem.val().clicked,
                                                         dataAtual: childItem.val().dataAtual,
                                                         chaveUnica: chaveUnica,
                                                         keyUsuario
                                                     });
 
 
                                                     if (childItem.val().lido == "false") {
                                                         notificacoesNaoLidas++
                                                     }
                                                 })
                                                
                                                 enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                                 enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                                 modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                             } else {
                                                 enviaNotificacoesParaReducer(dispatch, []);
                                                 enviaTotalNotificacoesParaReducer(dispatch, 0);
                                                 modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                             }
                                         }) */
                                    firebase
                                        .database()
                                        .ref(`${chaveUnica}/notificacoes/`)
                                        .orderByChild("keyCliente")
                                        .equalTo(keyUsuario)
                                        .limitToLast(1)
                                        .on('child_added', function (childSnapshot, prevChildName) {
                                            if (snapshot.val()) {
                                                if (qtdNotificacoes == 0) {

                                                } else {
                                                    const dadosNotif = childSnapshot.val();
                                                    console.log(dadosNotif)
                                                    if (dadosNotif.viaChat === "true") {
                                                        console.log('nao reproduz barulho')
                                                    } else {

                                                        /*  const sound = new Howl({
                                                             src: [soundnotification],
                                                             volume: 1,
                                                             //  autoplay: true
                                                         });
 
                                                         setTimeout(() => {
                                                             if (sound.playing() == false) {
                                                                 sound.play();
                                                             }
                                                         }, 500); */
                                                    }
                                                }
                                                qtdNotificacoes = 1;
                                            }
                                        })
                                    firebase
                                        .database()
                                        .ref(`${chaveUnica}/notificacoes/`)
                                        .orderByChild("keyCliente")
                                        .equalTo(keyUsuario)
                                        .limitToLast(15)
                                        .on("value", snapshot => {
                                            if (snapshot.val() == null) {
                                                enviaNotificacoesParaReducer(dispatch, []);
                                                enviaTotalNotificacoesParaReducer(dispatch, 0);
                                                modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                            } else {
                                                console.log('ON 1')
                                                // changing to reverse chronological order (latest first)
                                                /*   let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
            
                                                  // transforming to array
                                                  let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                                  // storing reference
                                                  let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
            */
                                                let aux = 0;
                                                let notificacoes = [];
                                                let notificacoesNaoLidas = 0;
                                                console.log('---------DENTRO DO NOTIF-------------- 0 ');
                                                snapshot.forEach(childItem => {
                                                    notificacoes.push({
                                                        key: childItem.key,
                                                        title: childItem.val().title,
                                                        body: childItem.val().body,
                                                        keyChamado: childItem.val().keyChamado,
                                                        numeroChamado: childItem.val().numeroChamado,
                                                        lido: childItem.val().lido,
                                                        clicked: childItem.val().clicked,
                                                        dataAtual: childItem.val().dataAtual,
                                                        chaveUnica: chaveUnica,
                                                        keyUsuario,
                                                        viaChat: childItem.val().viaChat,
                                                    });


                                                    if (childItem.val().lido == "false") {
                                                        notificacoesNaoLidas++
                                                    }
                                                })

                                                enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                                enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                                modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                            }
                                        })


                                } else {
                                    modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                }
                            })
                            firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                                let uriLogoTipo = [];
                                if (snapshot2.val()) {
                                    snapshot2.forEach(childItem => {
                                        uriLogoTipo.push({
                                            uri: childItem.val().uri,
                                        })
                                    })
                                    dispatch({
                                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                                        payload: uriLogoTipo[0].uri
                                    })
                                    // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                                } else {
                                    dispatch({
                                        type: MODIFICALOGOTIPOTELACONFIGURACAO,
                                        payload: 'semURI'
                                    })
                                    //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                                }

                            })

                            const messaging = firebase.messaging();
                            Notification.requestPermission().then((permission) => {
                                if (permission === 'granted') {
                                    return messaging.getToken()
                                }
                                return '';
                            }).then(token => {
                                firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                                    if (snapshot.val()) {
                                        // let key = Object.keys(snapshot.val()).toString();
                                        let dadosUsuarioBanco = [];
                                        snapshot.forEach((childItem) => {
                                            dadosUsuarioBanco.push({
                                                key: childItem.key,
                                                fcmTokenWEB: childItem.val().fcmTokenWEB,
                                            })
                                        })
                                        /*   console.log(dadosUsuarioBanco[0].fcmToken)
                                          console.log(token) */
                                        if (dadosUsuarioBanco[0].fcmTokenWEB == token) {
                                            /*    console.log('nao faz nada porque ja tem o fcmtoken guardado') */
                                        } else {
                                            firebase.database().ref(`${chaveUnica}/clientes/${dadosUsuarioBanco[0].key}`).update({ fcmTokenWEB: token })

                                        }
                                    }

                                })
                            })
                            messaging.onMessage((payload) => {
                                /*   console.log('antes do audio') */
                                /*      const sound = new Howl({
                                         src: [soundnotification],
                                         autoplay: true
                                     });
                 
                                     sound.play(); */
                                /*  const audio = new Audio('../imagens/notificationsound.mp3');
                                 audio.play(); */
                                if (payload.data.title.includes('Resposta no Chat')) {
                                    console.log('NAO MOSTRA NOTIFICACAOOOOOOOOOOOOOOO')
                                } else {
                                    const toastrOptions = {
                                        timeOut: 5000, // by setting to 0 it will prevent the auto close
                                        icon: (<i className="icon-sino" style={{ color: '#000', fontSize: 22 }} />), // You can add any component you want but note that the width and height are 70px ;)
                                        onShowComplete: () => { },
                                        onHideComplete: () => { },
                                        onCloseButtonClick: () => { },
                                        onToastrClick: () => {
                                            /* firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                                let keyUsuario = Object.keys(snapshot.val()).toString();
                                                firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes/`).orderByChild("title").equalTo(payload.data.title).once("value").then(snapshot2 => {
                                                    let keyNotif = Object.keys(snapshot2.val()).toString();
                                                    
                                                    firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                                })
                                            }) */
                                            firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                                let keyUsuario = Object.keys(snapshot.val()).toString();
                                                let filtro = `${keyUsuario}${payload.data.title}`
                                                console.log(filtro)
                                                firebase.database().ref(`${chaveUnica}/notificacoes/`).orderByChild("keyClienteTitle").equalTo(filtro).once("value").then(snapshot2 => {
                                                    console.log(payload)
                                                    console.log(snapshot2.val())
                                                    let keyNotif = Object.keys(snapshot2.val()).toString();
                                                    console.log('---------- no key notif---------')
                                                    console.log(keyNotif)
                                                    console.log(keyUsuario)
                                                    firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                                })
                                            })
                                            if (payload.data.viaChat == 'true') {
                                                //nao vai pra nenhuma tela, porque o App ja fica aberto na tela principal
                                            } else {
                                                let numeroAtendimento = parseInt(payload.data.numeroChamado);
                                                dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
                                                dispatch({ type: ALTERAKEYCHAMADO, payload: payload.data.keyChamado });
                                                dispatch({ type: MODIFICANOMEROTANOHEADER, payload: `Atendimento #${numeroAtendimento}` })
                                                history.push('/visualizaatendimento')
                                            }
                                        },
                                        showCloseButton: true, // false by default
                                        closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
                                        /*   component: ( // this option will give you a func 'remove' as props
                                            <MyCustomComponent myProp="myValue">
                                              <span>Hello, World!</span>
                                            </MyCustomComponent>
                                          ) */
                                    }
                                    /*    console.log(payload); */
                                    toastr.light(payload.data.title, payload.data.body, toastrOptions)
                                }
                            })
                            modificaLoadingConfiguracoesEmpresa(dispatch, false);
                        } else {
                            modificaLoadingConfiguracoesEmpresa(dispatch, false);
                            // toastr.error('Error', 'Essa conta é uma conta cliente, por favor baixe o aplicativo dropdesk cliente.');
                            //firebase.auth().signOut();
                            //desativaLoadingBotaoLogarTelaLogin(dispatch);
                        }
                    } else {
                        modificaLoadingConfiguracoesEmpresa(dispatch, false);
                    }
                })
            } else {
                /*  console.log('chave unica nao é nula') */
                const chaveUnica = chaveUnica2.replace(/"/g, "");

                ///verifica o ultimo chamado averto via chat
                firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                    if (snapshot.val()) {
                        // let key = Object.keys(snapshot.val()).toString();
                        let dadosUsuarioBanco = [];
                        snapshot.forEach((childItem) => {
                            dadosUsuarioBanco.push({
                                key: childItem.key,
                            })
                        })
                        firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("abertoPorChat").equalTo(`${dadosUsuarioBanco[0].key}Chat`).limitToLast(1).once("value").then((snapshot) => {
                            /*    console.log('ultimo chamado do cliente via chat'); */
                            let keyUltimoChamadoAbertoViaChat = '';
                            let statusAtualChamado = '';
                            snapshot.forEach((childItem) => {
                                keyUltimoChamadoAbertoViaChat = childItem.key;
                                /*    console.log(keyUltimoChamadoAbertoViaChat) */
                                statusAtualChamado = childItem.val().status;

                                statusAtualChamado = childItem.val().status;
                                let atendenteChamado = childItem.val().atendente;
                                let status_atendente_abertoviachat = childItem.val().status_atendente_abertoviachat;
                                let valueStatusAbertoViaChat = `Atendendo${atendenteChamado}true`;
                                console.log(status_atendente_abertoviachat.length)
                                console.log(valueStatusAbertoViaChat)
                                /*    console.log(statusAtualChamado) */
                                if (statusAtualChamado == 'Aberto' || statusAtualChamado == 'Atendendo') {
                                    if (status_atendente_abertoviachat.length == 0) {
                                        console.log('entrou onde nao era pra entrar')
                                    } else {

                                        if (statusAtualChamado == 'Aberto') {
                                            //indicando posicao da fila
                                            firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat")
                                                .equalTo("AbertoNão informadotrue")
                                                .on("value", snapshot => {
                                                    if (snapshot.val() == null) {
                                                        /*  console.log('snapshot null posicao fila') */
                                                        dispatch({ type: MODIFICAPOSICAOATUALFILA, payload: 1 });
                                                    } else {
                                                        // changing to reverse chronological order (latest first)
                                                        let arrayOfKeys = Object.keys(snapshot.val());
                                                        let posicaoFila = Object.keys(snapshot.val()).indexOf(keyUltimoChamadoAbertoViaChat);
                                                        /*   console.log(arrayOfKeys); */
                                                        posicaoFila = posicaoFila + 1;
                                                        /*  console.log(posicaoFila) */
                                                        // transforming to array
                                                        dispatch({ type: MODIFICAPOSICAOATUALFILA, payload: posicaoFila });
                                                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                                        /*  console.log(results); */
                                                    }
                                                })
                                        }
                                        /* console.log('envia os dados para o chat'); */
                                        dispatch({
                                            type: MODIFICAKEYATENDIMENTOCHAT,
                                            payload: keyUltimoChamadoAbertoViaChat
                                        });
                                        dispatch({ type: MODIFICATELAATUALCHAT, payload: 'aguardandoAtendimento' })
                                        listaChamadoParaTelaAcoesChamadoExport(dispatch, keyUltimoChamadoAbertoViaChat);
                                        dispatch({ type: MODIFICAATENDIMENTOONLINEVISIBLE, payload: true })
                                    }

                                } else {
                                    console.log('nao envia nada para o chat')
                                }

                            })

                        })
                    }
                }).catch((e) => {
                    console.log(e)
                })
                firebase.database().ref(`${chaveUnica}/empresa/`).on('value', snapshotPremium => {

                    let arrayEmpresa = [];
                    snapshotPremium.forEach(childItem => {
                        arrayEmpresa.push({
                            premium: childItem.val().premium,
                            nomeEmpresa: childItem.val().empresa,
                            licensawebouapp: childItem.val().licensawebouapp == undefined ? 1 : childItem.val().licensawebouapp,
                        })
                    })
                    /*      console.log(snapshotPremium.key)
                         console.log('verifica se é premium')
                         console.log(arrayEmpresa[0].premium) */
                    enviaSeEhVersaoPremium(dispatch, arrayEmpresa[0].premium);
                    modificaLicensawebouapp(dispatch, arrayEmpresa[0].licensawebouapp);
                    dispatch({ type: MODIFICANOMEEMPRESACONFIGURAEMPRESA, payload: arrayEmpresa[0].nomeEmpresa })

                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on('value', snapshot => {
                        const dadosConfiguracaoAtendimento = [];
                        snapshot.forEach(childItem => {
                            dadosConfiguracaoAtendimento.push({
                                key: childItem.key,
                                agruparAtendenteSetor: childItem.val().agruparAtendenteSetor == undefined ? false : childItem.val().agruparAtendenteSetor,
                                criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico,
                            })
                            verificaAgruparAtendenteSetor(dispatch, dadosConfiguracaoAtendimento[0].agruparAtendenteSetor);
                            verificaCriarChamadoProblemaEstatico(dispatch, dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico);

                        })
                    })
                })
                firebase.database().ref(`status_server`).on("value", snapshot => {
                    if (snapshot.val() !== null) {
                        snapshot.forEach(childItem => {
                            let isMaintenance = childItem.val().isMaintenance;
                            let lastVersionAvailableCLI = childItem.val().lastVersionAvailableCLI;
                            dispatch({ type: UPDTEISMAINTENANCE, payload: isMaintenance })
                            dispatch({ type: UPDTELASTVERSIONAVAILABLE, payload: lastVersionAvailableCLI })
                            if (childItem.val().isLogout) {
                                let keysToRemove = ["loginCliente", "chaveUnicaCliente", "tabindexCliente", "menuOpenCliente", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "logoLogin", "backgroundSegundo", "coresLetraPadrao", "letraBotaoPadrao"];
                                for (const key of keysToRemove) {
                                    localStorage.removeItem(key);
                                }
                                firebase.auth().signOut();
                            }
                        })
                    }
                })
                firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
                    let backgroundPrimeiro;
                    let backgroundSegundo;
                    let backgroundMaisEscuro;
                    let coresLetraPadrao;
                    let coresLetraSecundario;
                    let corBotaoPadrao;
                    let letraBotaoPadrao;
                    let corSegundoBotaoPadrao;
                    let corLetraDescricaoDosCampos;
                    let corFundoMenu;
                    let corLetraMenu;
                    let corPrincipalChat;
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                            backgroundSegundo = childItem.val().backgroundSegundo;
                            backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                            coresLetraPadrao = childItem.val().coresLetraPadrao;
                            coresLetraSecundario = childItem.val().coresLetraSecundario;
                            corBotaoPadrao = childItem.val().corBotaoPadrao;
                            letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                            corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                            corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                            corFundoMenu = childItem.val().corFundoMenu;
                            corLetraMenu = childItem.val().corLetraMenu;
                            corPrincipalChat = childItem.val().corPrincipalChat;
                        })
                        /*  console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                             corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        dispatch({
                            type: MODIFICACORPRINCIPALCHAT,
                            payload: corPrincipalChat
                        })
                        localStorage.setItem('backgroundPrimeiro', JSON.stringify(backgroundPrimeiro))
                        localStorage.setItem('corBotaoPadrao', JSON.stringify(corBotaoPadrao))
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                    }

                })
                let primeiraVerificacaoExecucao = false;
                let children2 = [];
                firebase.database().ref(`${chaveUnica}/setores`).orderByKey().limitToLast(1).on("value", snapshot => {
                    if (snapshot.val() == null) {
                        const arrayUser = [];

                        enviaSetorParaReducer(dispatch, arrayUser);

                    } else {
                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                        let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                        let aux = 0;

                        results.forEach(childItem => {
                            let permiteClienteSelecionarSetor = childItem.permiteClienteSelecionarSetor === undefined ? true : childItem.permiteClienteSelecionarSetor;
                            if (childItem.excluido == 1 || childItem.nome == undefined || permiteClienteSelecionarSetor === false) {
                                //console.log('usuario excluido')
                            } else if (children2.filter(e => e.value === referenceToOldestKey).length > 0) {
                                console.log('prevent duplicate set')
                            } else {
                                children2.push({
                                    value: arrayOfKeys[aux],
                                    name: childItem.nome
                                });
                            }
                            aux++
                        });
                        let ultimoUser = children2.length > 0 ? children2[children2.length - 1].value : '';
                        if (primeiraVerificacaoExecucao === true) {
                            // const items = [{ children: children2 }];
                            const items = [{
                                name: 'SETORES',
                                type: 'group', items: children2
                            }];
                            enviaSetorParaReducer(dispatch, items);


                        } else {
                            firebase.database().ref(`${chaveUnica}/setores`).orderByKey().endAt(referenceToOldestKey).once("value")
                                .then(snapshot => {
                                    if (snapshot.val() == null) {
                                        primeiraVerificacaoExecucao = true;
                                        let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                        const items = [{
                                            name: 'SETORES',
                                            type: 'group', items: arrayOrdernado
                                        }];
                                        enviaSetorParaReducer(dispatch, items);


                                    } else {
                                        primeiraVerificacaoExecucao = true;
                                        let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse().slice(1);
                                        let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                        let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
                                        let aux = 0;

                                        results.forEach(childItem => {
                                            let permiteClienteSelecionarSetor = childItem.permiteClienteSelecionarSetor === undefined ? true : childItem.permiteClienteSelecionarSetor;
                                            if (childItem.excluido == 1 || childItem.nome == undefined || permiteClienteSelecionarSetor === false) {
                                                //console.log('usuario excluido')
                                            } else {
                                                children2.push({
                                                    value: arrayOfKeys[aux],
                                                    name: childItem.nome
                                                });
                                            }
                                            aux++
                                        });
                                        let arrayOrdernado = children2.sort((a, b) => a.name.localeCompare(b.name))
                                        const items = [{
                                            name: 'SETORES',
                                            type: 'group', items: arrayOrdernado
                                        }];
                                        enviaSetorParaReducer(dispatch, items);


                                    }
                                })
                        }
                    }
                });
                /* 
                if (snapshot.val() == null) {
                    const arrayUser = [];
                    enviaSetorParaReducer(dispatch, arrayUser);
                } else {
                    const children2 = [];
                    snapshot.forEach(childItem => {
                        if (childItem.val().excluido == 1 || childItem.val().permiteClienteSelecionarSetor === false) {
                        } else {
                            children2.push({
                                value: childItem.key,
                                name: childItem.val().nome
                            });
                        }
                    });
                    const items = [{
                        name: 'SETORES',
                        type: 'group', items: children2
                    }];
                    enviaSetorParaReducer(dispatch, items);
                } */
                firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByChild("selecionado").equalTo(1).on("value", snapshot => {
                    if (snapshot.val() == null) {
                        const arrayUser = [];
                        enviaProblemasEstaticosParaReducer(dispatch, arrayUser);
                    } else {
                        const children2 = [];
                        snapshot.forEach(childItem => {

                            children2.push({
                                value: childItem.key,
                                name: childItem.val().descricaoProblema
                            });

                        });
                        const items = [{
                            name: 'PROBLEMAS',
                            type: 'group', items: children2
                        }];
                        //  const items = [{ children: children2 }];
                        enviaProblemasEstaticosParaReducer(dispatch, items);
                    }
                });
                let uidUsuarioLogado = firebase.auth().currentUser.uid;
                //pego key de quem Criou o aviso
                firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(uidUsuarioLogado).once("value").then((snapshot) => {
                    if (snapshot.val()) {
                        let arrayKeyQuemCriouAviso = [];
                        snapshot.forEach(childItem => {
                            arrayKeyQuemCriouAviso.push({
                                keyQuemAbriuAviso: childItem.key
                            })
                        })
                        /*  console.log('dentro do lista avisos tela dash board'); */
                        let dataAtual = moment().toDate();
                        dataAtual = moment(dataAtual).format('YYYY-MM-DD HH:mm');
                        /*   console.log('----------------comeco do lista avisos------------------------') */
                        let keyUsuarioLogado = arrayKeyQuemCriouAviso[0].keyQuemAbriuAviso;
                        /*    console.log(keyUsuarioLogado); */
                        firebase.database().ref(`${chaveUnica}/avisos/`).orderByChild('ativo').equalTo(true).on("value", snapshot => {
                            /* console.log(snapshot.val()); */
                            let arrayAvisos = [];
                            snapshot.forEach(childItem => {
                                if (childItem.val().destinatario == 2) {
                                    if (childItem.val().cliente == 'TodosPadrao' || childItem.val().cliente == keyUsuarioLogado) {
                                        if (childItem.val().porFaixaHorario == true) {
                                            let dataHoraInicial = childItem.val().dataInicial;
                                            let dataHoraFinal = childItem.val().dataFinal;
                                            let dataHoraInicialVerificacao = moment(dataHoraInicial, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                                            let dataHoraFinalVerificacao = moment(dataHoraFinal, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
                                            /*   console.log(dataHoraInicial);
                                              console.log(dataHoraFinal);
                                              console.log(dataAtual);
                                              console.log(dataHoraInicialVerificacao);
                                              console.log(dataHoraFinalVerificacao);
                                              console.log(childItem.key) */
                                            let vigorData = moment(dataAtual).isBetween(dataHoraInicialVerificacao, dataHoraFinalVerificacao);
                                            let dataInicialIgualDataAtual = moment(dataHoraInicialVerificacao).isSame(dataAtual);
                                            let VerificaSeDataInicialEhDepoisDataAtual = moment(dataHoraInicialVerificacao).isAfter(dataAtual);
                                            /*   console.log('vigor da data: ' + vigorData); */
                                            let alterado = childItem.val().alterado + 1;
                                            if (vigorData || dataInicialIgualDataAtual) {
                                                //então o aviso ainda está no prazo correto
                                                arrayAvisos.push({
                                                    key: childItem.key,
                                                    destinatario: childItem.val().destinatario,
                                                    cliente: childItem.val().cliente,
                                                    atendente: childItem.val().atendente,
                                                    aviso: childItem.val().aviso,
                                                    keyDeQuemAbriuAviso: childItem.val().keyDeQuemAbriuAviso,
                                                    ativo: childItem.val().ativo,
                                                    porFaixaHorario: childItem.val().porFaixaHorario,
                                                    dataInicial: childItem.val().dataInicial,
                                                    dataFinal: childItem.val().dataFinal,
                                                    alterado: childItem.val().alterado
                                                })
                                            } else if (VerificaSeDataInicialEhDepoisDataAtual) {
                                                /*    console.log(`a data inicial ainda nao entrou em vigor ou seja a data inicial é depois da data atual`) */
                                            }
                                            else {
                                                firebase.database().ref(`${chaveUnica}/avisos/${childItem.key}`).update({
                                                    ativo: false,
                                                    alterado
                                                })
                                            }
                                        } else {
                                            arrayAvisos.push({
                                                key: childItem.key,
                                                destinatario: childItem.val().destinatario,
                                                cliente: childItem.val().cliente,
                                                atendente: childItem.val().atendente,
                                                aviso: childItem.val().aviso,
                                                keyDeQuemAbriuAviso: childItem.val().keyDeQuemAbriuAviso,
                                                ativo: childItem.val().ativo,
                                                porFaixaHorario: childItem.val().porFaixaHorario,
                                                dataInicial: childItem.val().dataInicial,
                                                dataFinal: childItem.val().dataFinal,
                                                alterado: childItem.val().alterado
                                            })
                                        }
                                    }
                                }

                            })
                            /*  console.log(arrayAvisos); */
                            enviaAvisoParaTelaDashBoard(dispatch, arrayAvisos);
                            /*   console.log('-----------------fim do lista avisos-----------------------') */
                        })
                    } else {
                        /*  console.log(`sem dados de aviso`) */
                    }
                })

                firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            keyUsuario = childItem.key;
                            nome = childItem.val().nome;
                            enviaNomeParaReducer(dispatch, childItem.val().nome);
                            enviaEmailParaReducer(dispatch, childItem.val().email);
                            enviaEmpresaParaReducer(dispatch, childItem.val().empresa);
                            enviaSenhaParaReducer(dispatch, childItem.val().senha);
                            enviaConfirmaSenhaParaReducer(dispatch, childItem.val().senha);
                            modificaEmailClienteTelaCadastroClienteLOCAL(dispatch, childItem.val().email);
                            if (childItem.val().urlImagemNuvem == undefined || childItem.val().urlImagemNuvem == null || childItem.val().urlImagemNuvem == '') {
                                if (childItem.val().nome.length > 0) {
                                    const primeiraLetra = childItem.val().nome.substring(0, 1).trim().toLowerCase();
                                    /*    console.log('antes do verifica se eh alphabeto') */
                                    verificaSeEhAlphabeto(primeiraLetra, dispatch);
                                    dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });

                                } else {
                                    const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                    enviaImagemPerfil(dispatch, fotoURI)
                                    dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                                }
                            } else {
                                enviaImagemPerfil(dispatch, childItem.val().urlImagemNuvem)
                                dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                            }
                        })


                        /* firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                            enviaImagemPerfil(dispatch, url);
                            dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                        }).catch(() => {
                            const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                            firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                enviaImagemPerfil(dispatch, url);
                                dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                            }).catch(() => {
                                const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af';
                                enviaImagemPerfil(dispatch, fotoURI);
                                dispatch({ type: ATIVADESATIVALOADINGTELACONSULTACLIENTE, payload: false });
                            })
                        }) */


                    } else {
                    }
                })
                firebase.database().ref(`${chaveUnica}/configuracao/chatOnline`).on("value", snapshot2 => {
                    let dadosConfigChat = [];
                    let diaDaSemana = '';
                    if (snapshot2.val()) {
                        snapshot2.forEach(childItem => {
                            dadosConfigChat.push({
                                vigienciaSegundaFeira: childItem.val().vigienciaSegundaFeira,
                                vigienciaTercaFeira: childItem.val().vigienciaTercaFeira,
                                vigienciaQuartaFeira: childItem.val().vigienciaQuartaFeira,
                                vigienciaQuintaFeira: childItem.val().vigienciaQuintaFeira,
                                vigienciaSextaFeira: childItem.val().vigienciaSextaFeira,
                                vigienciaSabadoFeira: childItem.val().vigienciaSabadoFeira,
                                vigienciaDomingoFeira: childItem.val().vigienciaDomingoFeira,
                                horarioOperacaoSegundaFeira: childItem.val().horarioOperacaoSegundaFeira,
                                horarioOperacaoSegundaFeira2: childItem.val().horarioOperacaoSegundaFeira2,
                                horarioOperacaoTercaFeira: childItem.val().horarioOperacaoTercaFeira,
                                horarioOperacaoTercaFeira2: childItem.val().horarioOperacaoTercaFeira2,
                                horarioOperacaoQuartaFeira: childItem.val().horarioOperacaoQuartaFeira,
                                horarioOperacaoQuartaFeira2: childItem.val().horarioOperacaoQuartaFeira2,
                                horarioOperacaoQuintaFeira: childItem.val().horarioOperacaoQuintaFeira,
                                horarioOperacaoQuintaFeira2: childItem.val().horarioOperacaoQuintaFeira2,
                                horarioOperacaoSextaFeira: childItem.val().horarioOperacaoSextaFeira,
                                horarioOperacaoSextaFeira2: childItem.val().horarioOperacaoSextaFeira2,
                                horarioOperacaoSabadoFeira: childItem.val().horarioOperacaoSabadoFeira,
                                horarioOperacaoSabadoFeira2: childItem.val().horarioOperacaoSabadoFeira2,
                                horarioOperacaoDomingoFeira: childItem.val().horarioOperacaoDomingoFeira,
                                horarioOperacaoDomingoFeira2: childItem.val().horarioOperacaoDomingoFeira2,
                                utilizarChat: childItem.val().utilizarChat,
                                utilizar2Horarios: childItem.val().utilizar2Horarios,
                                fusoHorario: childItem.val().fusoHorario === undefined ? "America/Sao_Paulo" : childItem.val().fusoHorario,
                            })
                        })
                        /*  console.log('---- dentro do config chat -----------')
                         console.log(dadosConfigChat[0]) */
                        moment.locale('pt-br', {
                            weekdays: 'Domingo_Segunda_Terca_Quarta_Quinta_Sexta_Sabado'.split('_')
                        })
                        //HOJE

                        let dataHoje = moment().toDate();
                        /*  let dataTeste = moment(dataHoje).tz('America/Sao_Paulo');
                         console.log('data teste')
                         console.log(dataTeste);
                         dataTeste = moment(dataTeste).format('dddd'); 
                         console.log(dataTeste)*/
                        let horarioAtualBaseadoFusoHorarioConfigurado = toTimeZone(dataHoje, dadosConfigChat[0].fusoHorario);
                        /*  console.log(horarioAtualBaseadoFusoHorarioConfigurado)
                         console.log(dataHoje) */
                        dataHoje = moment(dataHoje).format('dddd');
                        /*  console.log('nome do dia');
                         console.log(dataHoje) */

                        let utilizarChat = dadosConfigChat[0].utilizarChat;
                        let utiliza2Horarios = dadosConfigChat[0].utilizar2Horarios;

                        if (utilizarChat == true) {
                            //aqui pode esta online ou offline
                            //tenho que verificar o periodo de exibicao, pra verificar se coloco como online ou offline
                            if (dataHoje == 'Domingo') {
                                /*  console.log('dia de Hoje Domingo') */
                                if (dadosConfigChat[0].vigienciaDomingoFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira2.end, "HH:mm");
                                        /*   console.log(startTime)
                                          console.log(endTime)
                                          console.log(startTime2)
                                          console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        /*    console.log(chatDisponivel) */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoDomingoFeira.end, "HH:mm");
                                        /*   console.log(startTime)
                                          console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else if (dataHoje == 'Segunda') {
                                /*  console.log('dia de Hoje Segunda') */
                                if (dadosConfigChat[0].vigienciaSegundaFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira2.end, "HH:mm");
                                        /*  console.log(startTime)
                                         console.log(endTime)
                                         console.log(startTime2)
                                         console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSegundaFeira.end, "HH:mm");
                                        /*   console.log(startTime)
                                          console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else if (dataHoje == 'Terca') {
                                /*  console.log('dia de Hoje Terca') */
                                if (dadosConfigChat[0].vigienciaTercaFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoTercaFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoTercaFeira2.end, "HH:mm");
                                        /*  console.log(startTime)
                                         console.log(endTime)
                                         console.log(startTime2)
                                         console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoTercaFeira.end, "HH:mm");
                                        /*   console.log(startTime) */
                                        /*  console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else if (dataHoje == 'Quarta') {
                                /*   console.log('dia de Hoje Quarta') */
                                if (dadosConfigChat[0].vigienciaQuartaFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira2.end, "HH:mm");
                                        /*  console.log(startTime)
                                         console.log(endTime)
                                         console.log(startTime2)
                                         console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        /*   console.log(chatDisponivel) */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoQuartaFeira.end, "HH:mm");
                                        /* console.log(startTime)
                                        console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*  console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else if (dataHoje == 'Quinta') {
                                /*    console.log('dia de Hoje Quinta') */
                                if (dadosConfigChat[0].vigienciaQuintaFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        console.log('dentro da verificacao 2 horarios quinta feira')
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira2.end, "HH:mm");
                                        console.log(startTime)
                                        console.log(endTime)
                                        console.log(startTime2)
                                        console.log(endTime2)
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        console.log(chatDisponivel)
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        /*  console.log('dentro da verificacao quinta feira') */
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoQuintaFeira.end, "HH:mm");
                                        /*    console.log(startTime)
                                           console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /* console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }

                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else if (dataHoje == 'Sexta') {
                                /*   console.log('dia de Hoje Sexta') */
                                if (dadosConfigChat[0].vigienciaSextaFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        /*     console.log('dentro da verificacao 2 horarios sexta feira') */
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSextaFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSextaFeira2.end, "HH:mm");
                                        /*     console.log(startTime)
                                            console.log(endTime)
                                            console.log(startTime2)
                                            console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        /*    console.log(chatDisponivel) */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        /*  console.log('dentro da verificacao sexta feira') */
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSextaFeira.end, "HH:mm");
                                        /*  console.log(startTime)
                                         console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*  console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            } else {
                                /*    console.log('dia de Hoje Sabado') */
                                if (dadosConfigChat[0].vigienciaSabadoFeira == true) {
                                    if (utiliza2Horarios == true) {
                                        //verifico se a hora de agora está entre os dois horarios
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.end, "HH:mm");
                                        let startTime2 = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira2.start, "HH:mm");
                                        let endTime2 = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira2.end, "HH:mm");
                                        /*    console.log(startTime)
                                           console.log(endTime)
                                           console.log(startTime2)
                                           console.log(endTime2) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
                                        console.log(chatDisponivel)/*  */
                                        if (chatDisponivel === true || chatDisponivel2 === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    } else {
                                        //verifico se a hora de agora está entro o primeiro horario
                                        let startTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.start, "HH:mm");
                                        let endTime = moment(dadosConfigChat[0].horarioOperacaoSabadoFeira.end, "HH:mm");
                                        /*   console.log(startTime)
                                          console.log(endTime) */
                                        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
                                        /*    console.log(chatDisponivel) */
                                        if (chatDisponivel === true) {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'online');
                                        } else {
                                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                        }
                                    }
                                } else {
                                    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                                }
                            }
                            // modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline');
                        } else {
                            //aqui ele desabilitou o chat
                            modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'naocfg');
                        }
                        dispatch({ type: ALTERAVALORCHECKSEGUNDAEIRA, payload: dadosConfigChat[0].vigienciaSegundaFeira })
                        dispatch({ type: ALTERAVALORCHECKTERCAEIRA, payload: dadosConfigChat[0].vigienciaTercaFeira })
                        dispatch({ type: ALTERAVALORCHECKQUARTAEIRA, payload: dadosConfigChat[0].vigienciaQuartaFeira })
                        dispatch({ type: ALTERAVALORCHECKQUINTAEIRA, payload: dadosConfigChat[0].vigienciaQuintaFeira })
                        dispatch({ type: ALTERAVALORCHECKSEXTAEIRA, payload: dadosConfigChat[0].vigienciaSextaFeira })
                        dispatch({ type: ALTERAVALORCHECKSABADOEIRA, payload: dadosConfigChat[0].vigienciaSabadoFeira })
                        dispatch({ type: ALTERAVALORCHECKDOMINGOEIRA, payload: dadosConfigChat[0].vigienciaDomingoFeira })
                        dispatch({ type: ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT, payload: dadosConfigChat[0].utilizar2Horarios })

                        dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSegundaFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA, payload: dadosConfigChat[0].horarioOperacaoTercaFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOTERCAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoTercaFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOQUARTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuartaFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOQUINTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoQuintaFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA, payload: dadosConfigChat[0].horarioOperacaoSextaFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOSEXTAFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSextaFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAOSABADOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoSabadoFeira2 })

                        dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira })
                        dispatch({ type: MODIFICAHORARIOOPERACAODOMINGOFEIRA2, payload: dadosConfigChat[0].horarioOperacaoDomingoFeira2 })
                        dispatch({ type: MODIFICAFUSOHORARIOSELECIONADO, payload: dadosConfigChat[0].fusoHorario === undefined ? 'America/Sao_Paulo' : dadosConfigChat[0].fusoHorario })


                    } else {
                        /* console.log('sem dados chat online, envia nao cfg') */
                        modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'naocfg');
                    }

                })

                firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                    if (snapshot.val()) {
                        let qtdNotificacoes = 0;
                        snapshot.forEach((childItem) => {
                            keyUsuario = childItem.key;
                        })
                        /*    firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes`).limitToLast(1)
                               .on('child_added', function (childSnapshot, prevChildName) {
                                   if (snapshot.val()) {
                                       if (qtdNotificacoes == 0) {
   
                                       } else {
                                           const sound = new Howl({
                                               src: [soundnotification],
                                               volume: 0.5,
                                               // autoplay: true
                                           });
   
                                           sound.play();
   
                                       }
                                       qtdNotificacoes = 1;
                                   }
                               })
                           firebase
                               .database()
                               .ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes`)
                               .orderByKey()
                               .limitToLast(30)
                               .on("value", snapshot => {
                                   if (snapshot.val()) {
                                       let notificacoes = [];
                                       let notificacoesNaoLidas = 0;
                                      
                                       snapshot.forEach(childItem => {
                                           notificacoes.push({
                                               key: childItem.key,
                                               title: childItem.val().title,
                                               body: childItem.val().body,
                                               keyChamado: childItem.val().keyChamado,
                                               numeroChamado: childItem.val().numeroChamado,
                                               lido: childItem.val().lido,
                                               clicked: childItem.val().clicked,
                                               dataAtual: childItem.val().dataAtual,
                                               chaveUnica: chaveUnica,
                                               keyUsuario
                                           });
   
   
                                           if (childItem.val().lido == "false") {
                                               notificacoesNaoLidas++
                                           }
                                       })
                                       
                                       enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                       enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                       modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                   } else {
                                       enviaNotificacoesParaReducer(dispatch, []);
                                       enviaTotalNotificacoesParaReducer(dispatch, 0);
                                       modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                   }
                               }) */
                        firebase
                            .database()
                            .ref(`${chaveUnica}/notificacoes/`)
                            .orderByChild("keyCliente")
                            .equalTo(keyUsuario)
                            .limitToLast(1)
                            .on('child_added', function (childSnapshot, prevChildName) {
                                if (snapshot.val()) {
                                    if (qtdNotificacoes == 0) {

                                    } else {
                                        const dadosNotif = childSnapshot.val();
                                        console.log(dadosNotif)

                                        if (dadosNotif.viaChat === "true") {
                                            console.log('nao reproduz barulho')
                                        } else {
                                            /*   const sound = new Howl({
                                                  src: [soundnotification],
                                                  volume: 1,
                                                  //  autoplay: true
                                              });
  
                                              setTimeout(() => {
                                                  if (sound.playing() == false) {
                                                      sound.play();
                                                  }
                                              }, 500); */
                                        }
                                    }
                                    qtdNotificacoes = 1;
                                }
                            })
                        firebase
                            .database()
                            .ref(`${chaveUnica}/notificacoes/`)
                            .orderByChild("keyCliente")
                            .equalTo(keyUsuario)
                            .limitToLast(15)
                            .on("value", snapshot => {
                                if (snapshot.val() == null) {
                                    enviaNotificacoesParaReducer(dispatch, []);
                                    enviaTotalNotificacoesParaReducer(dispatch, 0);
                                    modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                } else {
                                    console.log('ON 1')
                                    // changing to reverse chronological order (latest first)
                                    /*   let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();

                                      // transforming to array
                                      let results = arrayOfKeys.map(key => snapshot.val()[key]);
                                      // storing reference
                                      let referenceToOldestKey = arrayOfKeys[arrayOfKeys.length - 1];
*/
                                    let aux = 0;
                                    let notificacoes = [];
                                    let notificacoesNaoLidas = 0;
                                    console.log('---------DENTRO DO NOTIF-------------- 0 ');
                                    snapshot.forEach(childItem => {
                                        notificacoes.push({
                                            key: childItem.key,
                                            title: childItem.val().title,
                                            body: childItem.val().body,
                                            keyChamado: childItem.val().keyChamado,
                                            numeroChamado: childItem.val().numeroChamado,
                                            lido: childItem.val().lido,
                                            clicked: childItem.val().clicked,
                                            dataAtual: childItem.val().dataAtual,
                                            chaveUnica: chaveUnica,
                                            keyUsuario,
                                            viaChat: childItem.val().viaChat,
                                        });


                                        if (childItem.val().lido == "false") {
                                            notificacoesNaoLidas++
                                        }
                                    })

                                    enviaNotificacoesParaReducer(dispatch, notificacoes.reverse());
                                    enviaTotalNotificacoesParaReducer(dispatch, notificacoesNaoLidas);
                                    modificaLoadingConfiguracoesEmpresa(dispatch, false);
                                }
                            })

                    } else {
                        modificaLoadingConfiguracoesEmpresa(dispatch, false);
                    }
                })
                firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                    let uriLogoTipo = [];
                    if (snapshot2.val()) {
                        snapshot2.forEach(childItem => {
                            uriLogoTipo.push({
                                uri: childItem.val().uri,
                            })
                        })
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: uriLogoTipo[0].uri
                        })
                        // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: 'semURI'
                        })
                        //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }

                })

                const messaging = firebase.messaging();
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        return messaging.getToken()
                    }
                    return '';
                }).then(token => {
                    firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                        if (snapshot.val()) {
                            // let key = Object.keys(snapshot.val()).toString();
                            let dadosUsuarioBanco = [];
                            snapshot.forEach((childItem) => {
                                dadosUsuarioBanco.push({
                                    key: childItem.key,
                                    fcmTokenWEB: childItem.val().fcmTokenWEB,
                                })
                            })
                            /*    console.log(dadosUsuarioBanco[0].fcmToken)
                               console.log(token) */
                            if (dadosUsuarioBanco[0].fcmTokenWEB == token) {
                                /*   console.log('nao faz nada porque ja tem o fcmtoken guardado') */
                            } else {
                                firebase.database().ref(`${chaveUnica}/clientes/${dadosUsuarioBanco[0].key}`).update({ fcmTokenWEB: token })

                            }
                        }

                    })
                }).catch((err) => {
                    /*   console.log('Error Token : ', err); */

                })
                messaging.onMessage((payload) => {
                    if (payload.data.title.includes('Resposta no Chat')) {
                        console.log('NAO MOSTRA NOTIFICACAOOOOOOOOOOOOOOO')
                    } else {
                        /*   console.log('antes do audio') */
                        /*      const sound = new Howl({
                                 src: [soundnotification],
                                 autoplay: true
                             });
         
                             sound.play(); */
                        /*  const audio = new Audio('../imagens/notificationsound.mp3');
                         audio.play(); */
                        const toastrOptions = {
                            timeOut: 5000, // by setting to 0 it will prevent the auto close
                            icon: (<i className="icon-sino" style={{ color: '#000', fontSize: 22 }} />), // You can add any component you want but note that the width and height are 70px ;)
                            onShowComplete: () => { },
                            onHideComplete: () => { },
                            onCloseButtonClick: () => { },
                            onToastrClick: () => {
                                /*    firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                       let keyUsuario = Object.keys(snapshot.val()).toString();
                                       firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes/`).orderByChild("title").equalTo(payload.data.title).once("value").then(snapshot2 => {
                                           let keyNotif = Object.keys(snapshot2.val()).toString();
                                         
                                           firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                       })
                                   }) */
                                firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                                    let keyUsuario = Object.keys(snapshot.val()).toString();
                                    let filtro = `${keyUsuario}${payload.data.title}`
                                    console.log(filtro)
                                    firebase.database().ref(`${chaveUnica}/notificacoes/`).orderByChild("keyClienteTitle").equalTo(filtro).once("value").then(snapshot2 => {
                                        console.log(payload)
                                        console.log(snapshot2.val())
                                        let keyNotif = Object.keys(snapshot2.val()).toString();
                                        console.log('---------- no key notif---------')
                                        console.log(keyNotif)
                                        console.log(keyUsuario)
                                        firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotif}`).update({ lido: "true" });
                                    })
                                })
                                if (payload.data.viaChat == 'true') {
                                    //nao vai pra nenhuma tela, porque o App ja fica aberto na tela principal
                                } else {
                                    let numeroAtendimento = parseInt(payload.data.numeroChamado);
                                    dispatch({ type: ALTERANUMEROATENDIMENTO, payload: numeroAtendimento });
                                    dispatch({ type: ALTERAKEYCHAMADO, payload: payload.data.keyChamado });
                                    dispatch({ type: MODIFICANOMEROTANOHEADER, payload: `Atendimento #${numeroAtendimento}` })
                                    history.push('/visualizaatendimento')
                                }
                            },
                            showCloseButton: true, // false by default
                            closeOnToastrClick: true, // false by default, this will close the toastr when user clicks on it
                            /*   component: ( // this option will give you a func 'remove' as props
                                <MyCustomComponent myProp="myValue">
                                  <span>Hello, World!</span>
                                </MyCustomComponent>
                              ) */
                        }
                        /* console.log(payload); */
                        toastr.light(payload.data.title, payload.data.body, toastrOptions)
                    }
                })



            }
        })

    }
}

export const novalistaInformacoesDashBoarPeriodoPersonalizado = (dataInicial, dataFinal) => {
    console.log(dataInicial, dataFinal)
    dataInicial = moment(dataInicial).format('DD/MM/YYYY');
    dataFinal = moment(dataFinal).format('DD/MM/YYYY');
    console.log(dataInicial, dataFinal)
    let atendimentosAbertoHoje = 0;
    let atendimentosAbertoNaSemana = 0;
    let atendimentosAtendendoNaSemana = 0;
    let atendimentosFechadoNaSemana = 0;
    let atendimentosCanceladoNaSemana = 0;
    let atendimentosAbertoAtendendoPrioridadeBaixa = 0;
    let atendimentosAbertoAtendendoPrioridadeNormal = 0;
    let atendimentosAbertoAtendendoPrioridadeAlta = 0;
    let atendimentosAbertoAtendendoPrioridadeUrgente = 0;
    let tempoResolucaoChamadoMenorque1 = 0;
    let tempoResolucaoChamado1 = 0;
    let tempoResolucaoChamado2 = 0;
    let tempoResolucaoChamado3 = 0;
    let tempoResolucaoChamado4 = 0;
    let tempoResolucaoChamado5 = 0;
    let tempoResolucaoChamado6 = 0;
    let tempoResolucaoChamado7 = 0;
    let tempoResolucaoChamadoMaiorque7 = 0;
    let atendimentosPorLocalInterno = 0;
    let atendimentosPorLocalExterno = 0;
    let atendimentosPorLocal = [];
    let atendimentoAbertoStatus = 0;
    let atendimentoEmAtendimentoStatus = 0;
    let atendimentoFechadoStatus = 0;
    let atendimentoCanceladoStatus = 0;
    let arrayChamadosComClientes = [];
    let arrayChamadosComSetores = [];
    let arrayChamadosPorStatus = [];

    let dataInicialVerificacao = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let dataFinalVerificacao = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let verificaSeDataInicialMaiorQueDataFinal = moment(dataFinalVerificacao).isBefore(dataInicialVerificacao);
    return dispatch => {

        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            console.log(chaveUnica);
            if (dataInicial == '' && dataFinal == '' || dataInicial == null && dataFinal == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA INICIAL e DATA FINAL do período de pesquisa.');
            }
            else if (dataInicial == '' || dataInicial == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA INICIAL do período de pesquisa.');
            }
            else if (dataFinal == '' || dataFinal == null) {
                mensagemPeriodoPersonalizadoError(dispatch, 'Informe a DATA FINAL do período de pesquisa.');
            }
            else if (verificaSeDataInicialMaiorQueDataFinal == true) {
                mensagemPeriodoPersonalizadoError(dispatch, 'DATA INICIAL maior que DATA FINAL, por favor verifique.');
            }
            else {
                enviaSemConexaoInternetDashBoard(dispatch, false);
                ativaDesativaLoadingTelaDashBoard(dispatch, true);
                enviaPeriodoPersonalizado(dispatch, `${dataInicial} - ${dataFinal}`);

                dataInicial = dataInicial + ' 00:00:01';
                dataFinal = dataFinal + ' 23:59:59';
                let dataAtual = retornaDataAtual();
                let dataInicioConsulta, dataFinalConsulta;

                //verificacao para saber qual periodo é e passar o periodo correto para a query do firebase
                console.log('antes da query firebase')
                dataInicioConsulta = retornaDataTimeStamp(dataInicial);
                dataFinalConsulta = retornaDataTimeStamp(dataFinal);
                console.log(dataInicioConsulta);
                console.log(dataFinalConsulta);
                firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild('dataAberturaTimeStamp')
                    .startAt(dataInicioConsulta).endAt(dataFinalConsulta).once("value").then((snapshot) => {
                        if (snapshot.val()) {
                            enviaAtendimentosDestaSemanaDashBoard(dispatch, snapshot.numChildren());

                            let arrayOfKeys = Object.keys(snapshot.val()).sort().reverse();
                            let results = arrayOfKeys.map((key) => snapshot.val()[key]);
                            for (let i = 0; i < results.length; i++) {
                                if (results[i].dataAbertura == dataAtual) {
                                    atendimentosAbertoHoje++
                                }
                                //por status
                                if (results[i].status == 'Aberto') {
                                    atendimentosAbertoNaSemana++
                                    //por prioridade
                                    if (results[i].prioridade == 'Baixa') {
                                        atendimentosAbertoAtendendoPrioridadeBaixa++;
                                    }
                                    if (results[i].prioridade == 'Normal') {
                                        atendimentosAbertoAtendendoPrioridadeNormal++;
                                    }
                                    if (results[i].prioridade == 'Alta') {
                                        atendimentosAbertoAtendendoPrioridadeAlta++;
                                    }
                                    if (results[i].prioridade == 'Urgente') {
                                        atendimentosAbertoAtendendoPrioridadeUrgente++;
                                    }
                                }
                                if (results[i].status == 'Atendendo') {
                                    atendimentosAtendendoNaSemana++
                                }
                                if (results[i].status == 'Fechado') {
                                    atendimentosFechadoNaSemana++
                                    if (results[i].periodoResolucaoChamado == '<1') {
                                        tempoResolucaoChamadoMenorque1++
                                    }
                                    if (results[i].periodoResolucaoChamado == '1') {
                                        tempoResolucaoChamado1++
                                    }
                                    if (results[i].periodoResolucaoChamado == '2') {
                                        tempoResolucaoChamado2++
                                    }
                                    if (results[i].periodoResolucaoChamado == '3') {
                                        tempoResolucaoChamado3++
                                    }
                                    if (results[i].periodoResolucaoChamado == '4') {
                                        tempoResolucaoChamado4++
                                    }
                                    if (results[i].periodoResolucaoChamado == '5') {
                                        tempoResolucaoChamado5++
                                    }
                                    if (results[i].periodoResolucaoChamado == '6') {
                                        tempoResolucaoChamado6++
                                    }
                                    if (results[i].periodoResolucaoChamado == '7') {
                                        tempoResolucaoChamado7++
                                    }
                                    if (results[i].periodoResolucaoChamado == '>7') {
                                        tempoResolucaoChamadoMaiorque7++
                                    }
                                }
                                if (results[i].status == 'Cancelado') {
                                    atendimentosCanceladoNaSemana++
                                }
                                if (results[i].local == 'Interno') {
                                    atendimentosPorLocalInterno++
                                }
                                if (results[i].local == 'Externo') {
                                    atendimentosPorLocalExterno++
                                }

                            }
                            //envio chamados abertos hoje
                            enviaAtendimentosHojeDashBoard(dispatch, atendimentosAbertoHoje);


                            //implemento array para enviar os chamados por status
                            if (atendimentosAbertoNaSemana == 0) {
                                atendimentoAbertoStatus = 0;
                            } else {
                                atendimentoAbertoStatus = atendimentosAbertoNaSemana;

                            }

                            if (atendimentosAtendendoNaSemana == 0) {
                                atendimentoEmAtendimentoStatus = 0;
                            } else {
                                atendimentoEmAtendimentoStatus = atendimentosAtendendoNaSemana;

                            }
                            if (atendimentosFechadoNaSemana == 0) {
                                atendimentoFechadoStatus = 0;
                            } else {
                                atendimentoFechadoStatus = atendimentosFechadoNaSemana;
                            }
                            if (atendimentosCanceladoNaSemana == 0) {
                                atendimentoCanceladoStatus = 0;
                            } else {
                                atendimentoCanceladoStatus = atendimentosCanceladoNaSemana;

                            }

                            enviaAtendimentoAberto(dispatch, atendimentoAbertoStatus);
                            enviaAtendimentoCancelado(dispatch, atendimentoCanceladoStatus);
                            enviaAtendimentoFechado(dispatch, atendimentoFechadoStatus);
                            enviaAtendimentoEmAtendimento(dispatch, atendimentoEmAtendimentoStatus);


                            //envio chamados por prioridades
                            /*    let totalAtendimentosAbertoAtendendoPorPrioridade = atendimentosAbertoAtendendoPrioridadeBaixa +
                                   atendimentosAbertoAtendendoPrioridadeNormal + atendimentosAbertoAtendendoPrioridadeAlta +
                                   atendimentosAbertoAtendendoPrioridadeUrgente;
                               enviaTotalAtendimentosPorPRioridade(dispatch, totalAtendimentosAbertoAtendendoPorPrioridade) */
                            if (atendimentosAbertoAtendendoPrioridadeBaixa == 0) {
                                atendimentosAbertoAtendendoPrioridadeBaixa = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeNormal == 0) {
                                atendimentosAbertoAtendendoPrioridadeNormal = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeAlta == 0) {
                                atendimentosAbertoAtendendoPrioridadeAlta = 0.5
                            }
                            if (atendimentosAbertoAtendendoPrioridadeUrgente == 0) {
                                atendimentosAbertoAtendendoPrioridadeUrgente = 0.5
                            }

                            let atendimentosPorPrioridadeEnviaDashBoard = [
                                { value: atendimentosAbertoAtendendoPrioridadeBaixa, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeNormal, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeAlta, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: atendimentosAbertoAtendendoPrioridadeUrgente, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }];
                            enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentosPorPrioridadeEnviaDashBoard)

                            if (tempoResolucaoChamadoMenorque1 == 0) {
                                tempoResolucaoChamadoMenorque1 = 0.5
                            }
                            if (tempoResolucaoChamado1 == 0) {
                                tempoResolucaoChamado1 = 0.5
                            }
                            if (tempoResolucaoChamado2 == 0) {
                                tempoResolucaoChamado2 = 0.5
                            }
                            if (tempoResolucaoChamado3 == 0) {
                                tempoResolucaoChamado3 = 0.5
                            }
                            if (tempoResolucaoChamado4 == 0) {
                                tempoResolucaoChamado4 = 0.5
                            }
                            if (tempoResolucaoChamado5 == 0) {
                                tempoResolucaoChamado5 = 0.5
                            }
                            if (tempoResolucaoChamado6 == 0) {
                                tempoResolucaoChamado6 = 0.5
                            }
                            if (tempoResolucaoChamado7 == 0) {
                                tempoResolucaoChamado7 = 0.5
                            }
                            if (tempoResolucaoChamadoMaiorque7 == 0) {
                                tempoResolucaoChamadoMaiorque7 = 0.5
                            }
                            let atendimentosPorTempOResolucao = [
                                { value: tempoResolucaoChamadoMenorque1, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado1, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado2, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado3, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado4, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado6, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                                { value: tempoResolucaoChamado7, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                                { value: tempoResolucaoChamadoMaiorque7, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }];
                            enviaTempoResolucaoChamados(dispatch, atendimentosPorTempOResolucao);
                            //   enviaTotalAtendimentosPeriodoResolucao(dispatch, atendimentosFechadoNaSemana); 
                            //atendimentos interno e externo
                            if (atendimentosPorLocalInterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalInterno, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0"
                                })
                            }
                            if (atendimentosPorLocalExterno == 0) {
                                atendimentosPorLocal.push({
                                    value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                                })

                            } else {
                                atendimentosPorLocal.push({
                                    value: atendimentosPorLocalExterno, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0"
                                })
                            }

                            // let totalAtendimentosPorLocal = atendimentosPorLocalInterno + atendimentosPorLocalExterno;
                            // enviaTotalAtendimentosPorLocalDashboard(dispatch, totalAtendimentosPorLocal)
                            enviaAtendimentosPorLocalDashboard(dispatch, atendimentosPorLocal)
                            //envio total de clientes

                            //envio total de chamados

                            console.log('ONDE FICA O LOADING')
                            ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                            ativaDesativaLoadingTelaDashBoard(dispatch, false);
                            ativaDesativaLoadingRefreshControol(dispatch, false);
                        } else {
                            //sem informacoes
                            enviaAtendimentosDestaSemanaDashBoard(dispatch, 0);
                            enviaAtendimentosHojeDashBoard(dispatch, 0);
                            enviaAtendimentoAberto(dispatch, 0);
                            enviaAtendimentoCancelado(dispatch, 0);
                            enviaAtendimentoFechado(dispatch, 0);
                            enviaAtendimentoEmAtendimento(dispatch, 0);

                            let atendimentoPorPrioridadeZerado = [
                                { value: 0.5, label: 'Baixa', id: "Baixa", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Normal', id: "Normal", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: 0.5, label: 'Alta', id: "Alta", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: 0.5, label: 'Urgente', id: "Urgente", color: "hsl(344, 94%, 51%)", valorZerado: "0" }
                            ]


                            enviaAtendimentosPorPrioridadeDashboard(dispatch, atendimentoPorPrioridadeZerado);
                            //  enviaTotalAtendimentosPorPRioridade(dispatch, 0)
                            let atendimentoPorResolucaoZerado = [
                                { value: 0.5, label: '< 1 dia', id: "< 1 dia", color: "hsl(150, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '1 dia', id: "1 dia", color: "hsl(0, 0%, 83%)", valorZerado: "0" },
                                { value: 0.5, label: '2 dias', id: "2 dias", color: "hsl(42, 94%, 60%)", valorZerado: "0" },
                                { value: 0.5, label: '3 dias', id: "3 dias", color: "hsl(344, 94%, 51%)", valorZerado: "0" },
                                { value: 0.5, label: '4 dias', id: "4 dias", color: "hsl(214, 41%, 78%)", valorZerado: "0" },
                                { value: 0.5, label: '5 dias', id: "5 dias", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '6 dias', id: "6 dias", color: "hsl(180, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: '7 dias', id: "7 dias", color: "hsl(25, 75%, 47%)", valorZerado: "0" },
                                { value: 0.5, label: '> 7 dias', id: "> 7 dias", color: "hsl(180, 100%, 27%)", valorZerado: "0" }]
                            enviaTempoResolucaoChamados(dispatch, atendimentoPorResolucaoZerado);
                            // enviaTotalAtendimentosPeriodoResolucao(dispatch, 0);

                            let atendimentoPorLocalZerado = [
                                { value: 0.5, label: 'Interno', id: "Interno", color: "hsl(195, 100%, 50%)", valorZerado: "0" },
                                { value: 0.5, label: 'Externo', id: "Externo", color: "hsl(214, 41%, 78%)", valorZerado: "0" }
                            ];
                            // enviaTotalAtendimentosPorLocalDashboard(dispatch, 0)
                            enviaAtendimentosPorLocalDashboard(dispatch, atendimentoPorLocalZerado)

                            ativaDesativaLoadingTelaDashBoard(dispatch, false);

                            ativaDesativaLoadingRefreshControol(dispatch, false);
                            console.log('ONDE FICA O LOADING')
                            ativaDesativaLoadingCarregandoCOnfiguracoesGerais(dispatch, false);
                        }
                    })
            }

        })

    }
}
export const verificaSeExisteConfiguracaoCriada = () => {
    return dispatch => {
        //se tiver dados na configuracoes inseridsas é porqeu já tem todas as configuracoes inseridas no banco do firebase
        //entao nao precisa ficar consultando
        AsyncStorage.getItem('configuracoesInseridas3').then(
            (dadosConfig) => {
                if (dadosConfig !== null) {
                    console.log(dadosConfig)
                    console.log('CONFIGURACOES JA INSERIDAS NA NUVEM')
                } else {

                    AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
                        const chaveUnica = chaveUnica2.replace(/"/g, "");
                        let verificaSeJaExisteConfigAvaliacao = false;
                        let verificaSeJaExisteConfigAtendimento = false;
                        let verificaSeJaExisteConfigUserPrincipal = false;
                        let verificaSeJaExisteConfigProblemaEstatico = false;
                        //verifica configuracao de avaliacao
                        firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once('value').then((snapshot) => {
                            console.log(snapshot.val());
                            if (snapshot.val()) {
                                console.log('TEM CONFIGURACAO------');
                                verificaSeJaExisteConfigAvaliacao = true;
                            } else {
                                console.log('NAO TEM CONFIGURACAO------');
                                firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).push({
                                    utilizaAvaliacao: true,
                                    mensagemPadrao: 'Qualquer dúvida, estamos à disposição. Por gentileza, avalie meu atendimento. Tenha um ótimo dia!'
                                }).then(() => {
                                    console.log('CONFIGURACAO AVALIACAO CRIADA------');
                                    verificaSeJaExisteConfigAvaliacao = true;
                                })
                            }
                            //verifica configuracoes de atendimento em geral
                            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {
                                console.log(snapshot.val());
                                if (snapshot.val()) {
                                    console.log('TEM CONFIGURACAO ATENDIMENTO------');
                                    const dadosConfiguracaoAtendimento = [];
                                    snapshot.forEach(childItem => {
                                        dadosConfiguracaoAtendimento.push({
                                            key: childItem.key,
                                            atribuirAtendenteObrigatorio: childItem.val().atribuirAtendenteObrigatorio,
                                            atendenteVeApenasProprioChamado: childItem.val().atendenteVeApenasProprioChamado,
                                            agruparAtendenteSetor: childItem.val().agruparAtendenteSetor,
                                            criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico,
                                        })
                                    })
                                    console.log(dadosConfiguracaoAtendimento[0].agruparAtendenteSetor)
                                    console.log(dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioChamado)
                                    console.log(dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico)
                                    if (dadosConfiguracaoAtendimento[0].agruparAtendenteSetor == undefined || dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioChamado == undefined) {
                                        firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                                            atendenteVeApenasProprioChamado: false,
                                            agruparAtendenteSetor: false,
                                        })
                                    }
                                    //nova cfg
                                    if (dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico == undefined) {
                                        firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                                            criarChamadoProblemaEstatico: false,
                                        })
                                    }
                                    console.log('TODA CONF ATENDIMENTO CRIADA, NAO FAZ NADA')

                                    verificaSeJaExisteConfigAtendimento = true;
                                } else {
                                    console.log('NAO TEM CONFIGURACAO ATENDIMENTO------');
                                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).push({
                                        atribuirAtendenteObrigatorio: false,
                                        atendenteVeApenasProprioChamado: false,
                                        agruparAtendenteSetor: false,
                                        criarChamadoProblemaEstatico: false
                                    }).then(() => {
                                        console.log('CONFIGURACAO ATENDIMENTO CRIADA------');
                                        verificaSeJaExisteConfigAtendimento = true;
                                    })
                                }
                                firebase.database().ref(`${chaveUnica}/usuarios/`).limitToFirst(1).once('value').then((snapshot) => {

                                    if (snapshot.val()) {
                                        console.log('PRIMEIRO USUARIOS------');
                                        console.log(snapshot.val());
                                        snapshot.forEach(childItem => {
                                            console.log(childItem.val().contaPrincipal);
                                            if (childItem.val().contaPrincipal == undefined || childItem.val().contaPrincipal == null || childItem.val().contaPrincipal == false) {
                                                console.log('COLOCANDO USUARIO COMO CONTA PRINCIPAL');
                                                firebase.database().ref(`${chaveUnica}/usuarios/${childItem.key}`).update({
                                                    contaPrincipal: true,
                                                    permissaoAcessoRelatorios: true,
                                                    permissaoAdministrador: true,
                                                    permissaoAlterarCadastrarAtendente: true,
                                                    permissaoAlterarCadastrarAvisos: true,
                                                    permissaoAlterarCadastrarCliente: true,
                                                    permissaoAlterarCadastrarSetores: true,
                                                    StringAdmin: 'Admin'
                                                });
                                                verificaSeJaExisteConfigUserPrincipal = true;
                                            } else {
                                                console.log('USUARIO JA É CONTA PRINCIPAL')
                                                verificaSeJaExisteConfigUserPrincipal = true;
                                            }
                                        })
                                    } else {
                                        console.log('NAO TEM USUARIO CADASTRADO O QUE NAO FAZ SENTIDO------');
                                    }
                                    console.log('apos as consultas')
                                    console.log(verificaSeJaExisteConfigAvaliacao)
                                    console.log(verificaSeJaExisteConfigAtendimento)
                                    console.log(verificaSeJaExisteConfigUserPrincipal)
                                    if (verificaSeJaExisteConfigAvaliacao == true && verificaSeJaExisteConfigAtendimento == true &&
                                        verificaSeJaExisteConfigUserPrincipal == true) {
                                        console.log(' CONFIG já inseridas na nuvem')
                                        localStorage.setItem(`configuracoesInseridas3`, JSON.stringify('OK'));
                                    } else {
                                        console.log('nao tem as configuracoes inseridas na nuvem')
                                    }
                                })

                            })
                        })



                    })
                }
            })


    }
}
export const cadastraTarefas = (nomeTarefa) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                console.log('key Usuario')
                console.log(keyUsuario)
                firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas`).push({
                    tarefa: nomeTarefa,
                    feito: false
                }).then(
                    () => {
                        console.log('tarefa cadastrada')
                    })
            })

        })



    }
}
export const marcarTodasNotifComoLidoold = () => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/clientes/`).orderByChild('useruid').equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = Object.keys(snapshot.val()).toString();
                firebase
                    .database()
                    .ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes`)
                    .orderByKey()
                    .limitToLast(30)
                    .once("value").then(snapshot => {
                        if (snapshot.val()) {
                            let aux = 0;
                            /*  console.log(snapshot.numChildren()) */
                            snapshot.forEach((childItem) => {
                                aux++;
                                firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}/notificacoes/${childItem.key}`).update({ lido: "true" }).then(() => {
                                    if (snapshot.numChildren() == aux) {
                                        toastr.success('Sucesso', 'Notificações marcadas como lidas');
                                    }
                                });
                            })
                        }

                    })
            })

        })



    }
}
export const marcarTodasNotifComoLido = (notificacoesarray) => {
    return dispatch => {
        console.log('marcando como lida')
        console.log(notificacoesarray)
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            let keyNotificacoesMarcarComoLido = []
            for (let i = 0; i < notificacoesarray.length; i++) {
                console.log(notificacoesarray[i].lido)
                if (notificacoesarray[i].lido == "false") {
                    console.log('no false')
                    console.log(notificacoesarray[i].key)
                    keyNotificacoesMarcarComoLido.push(notificacoesarray[i].key)
                }
            }
            console.log(keyNotificacoesMarcarComoLido)
            if (keyNotificacoesMarcarComoLido.length > 0) {
                let aux = 0;
                for (let i = 0; i < keyNotificacoesMarcarComoLido.length; i++) {
                    aux++;
                    firebase.database().ref(`${chaveUnica}/notificacoes/${keyNotificacoesMarcarComoLido[i]}`).update({ lido: "true" }).then(() => {
                        if (keyNotificacoesMarcarComoLido.length == aux) {
                            console.log('1')
                            toastr.success('Sucesso', 'Notificações marcadas como lidas!');
                        }
                    });
                }
            } else {
                console.log('2')
                toastr.success('Sucesso', 'Notificações marcadas como lidas!');
            }
        })



    }
}
export const excluirTarefa = (keyTarefa) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                /*  console.log('key Usuario')
                 console.log(keyUsuario) */
                firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas/${keyTarefa}`).remove().then(
                    () => {
                        /*    console.log('tarefa excluida') */
                    })
            })

        })



    }
}
export const marcarTarefaComoFeito = (keyTarefa, statusFeito) => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");

            firebase.database().ref(`${chaveUnica}/usuarios/`).orderByChild("uid").equalTo(firebase.auth().currentUser.uid).once("value").then(snapshot => {
                let keyUsuario = '';
                snapshot.forEach(childItem => {
                    keyUsuario = childItem.key;

                });
                console.log('key Usuario')
                console.log(keyUsuario)
                firebase.database().ref(`${chaveUnica}/usuarios/${keyUsuario}/tarefas/${keyTarefa}`).update({ feito: statusFeito }).then(
                    () => {
                        console.log('tarefa excluida')
                    })
            })

        })



    }
}

const modificaEmailClienteTelaCadastroClienteLOCAL = (dispatch, email) => {
    dispatch({
        type: CARREGAEMAILCLIENTEPERFIL,
        payload: email
    });
};
export const modificaModalSair = (value) => {
    return {
        type: MODIFICAMODALSAIR,
        payload: value
    }
};
const modificaLoadingLoginLocal = (dispatch, value) => {
    dispatch({
        type: LOADINGTELALOGIN,
        payload: value
    });
};
export const modificaLoadingLogin = (value) => {
    return {
        type: LOADINGTELALOGIN,
        payload: value
    }
};
export const modificaCorBordaListaTarefas = (value) => {
    return {
        type: MODIFICACORBORDALISTATAREFAS,
        payload: value
    }
};
const modificaLicensawebouapp = (dispatch, value) => {
    dispatch({
        type: MODIFICALICENSAWEBAPP,
        payload: value
    });
};
export const insereDataFiltroDashboard = (dataInicial, dataFinal) => {

    return dispatch => {
        /* console.log(dataInicial)
        console.log(dataFinal) */
        dispatch({ type: MODIFICADATAINICIALCONSULTADASHBOARD, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALCONSULTADASHBOARD, payload: dataFinal });
    }
};
export const modificaFiltroPorDataDashboard = (value) => {
    return {
        type: MODIFICAFILTROPORDATADASHBOARD,
        payload: value
    }
};
const enviaSetorParaReducer = (dispatch, arraySetor) => {
    dispatch({
        type: ENVIASETORPARAREDUCER,
        payload: arraySetor
    });
};
const enviaProblemasEstaticosParaReducer = (dispatch, valor) => {
    dispatch({
        type: ENVIAPROBLEMASESTATICOSPARAREDUCER,
        payload: valor
    });
};
export const modificaFiltroSelecionadoRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROSELECIONADORELATORIODETALHADO,
        payload: value
    }
};

const enviaAvisoParaTelaDashBoard = (dispatch, valor) => {
    dispatch({
        type: ENVIAAVISOPARATELADASHBOARD,
        payload: valor
    });
};
export const modificaDataInicialFinalRelatorioAtendimentoDetalhado = (dataInicial, dataFinal) => {
    return dispatch => {
        console.log(dataInicial)
        console.log(dataFinal)
        dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataFinal });
    }
};
const modificaDataInicialFinalRelatorioAtendimentoDetalhadoLocal = (dataInicial, dataFinal) => {
    return dispatch => {
        console.log(dataInicial)
        console.log(dataFinal)
        dispatch({ type: MODIFICADATAINICIALRELATORIODETALHADO, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIODETALHADO, payload: dataFinal });
    }
};
export const modificaDataInicialFinalRelatorioAtendimentoGeral = (dataInicial, dataFinal) => {
    return dispatch => {
        console.log(dataInicial)
        console.log(dataFinal)
        dispatch({ type: MODIFICADATAINICIALRELATORIOGERAL, payload: dataInicial });
        dispatch({ type: MODIFICADATAFINALRELATORIOGERAL, payload: dataFinal });
    }
};


export const insereTextosInformacionaisModal = (textCabecalho, text1, text2) => {
    return dispatch => {
        enviaTextCabecalhoModalInf(dispatch, textCabecalho);
        enviaText1ModalInf(dispatch, text1);
        enviaText2ModalInf(dispatch, text2);
        modificaVisibilidadeModalInformacaoRelatorioGeralLocal(dispatch, true);
    }
}
export const insereTextosInformacionaisModalRelatorioDetalhado = (textCabecalho, text1, text2) => {
    return dispatch => {
        enviaTextCabecalhoModalInfRelatorioDetalhado(dispatch, textCabecalho);
        enviaText1ModalInfRelatorioDetalhado(dispatch, text1);
        enviaText2ModalInfRelatorioDetalhado(dispatch, text2);
        modificaVisibilidadeModalInformacaoRelatorioDetalhadoLocal(dispatch, true);
    }
}
export const modificaVisibleModalVersaoPremiumEsgotou = (value) => {
    return {
        type: MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU,
        payload: value
    }
}
const modificaVisibilidadeAtendimentoChatOnlineOuOffline = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBILIDADEATENDIMENTOCHATONLINEOFFLINE,
        payload: value
    });
};
export const modificaVisibilidadeAtendimentoChatOnlineOuOfflineExport = (value) => {
    return {
        type: MODIFICAVISIBILIDADEATENDIMENTOCHATONLINEOFFLINE,
        payload: value
    };
};
const enviaIdentificadorUnicoDaCompra = (dispatch, value) => {
    dispatch({
        type: ENVIAIDENTIFICADORUNICACOMPRA,
        payload: value
    });
};
const enviaNotificacoesParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIANOTIFICACOESPARAREDUCER,
        payload: value
    });
};

const enviaTotalNotificacoesParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALNOTIFICACOESPARAREDUCER,
        payload: value
    });
};
export const enviaTotalNotificacoesParaReducerExport = (value) => {
    return {
        type: ENVIATOTALNOTIFICACOESPARAREDUCER,
        payload: value
    }
}
export const modificaVisibleModalAssinaturaPausadaExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALASSINATURAPAUSADA,
        payload: value
    }
}
const enviaTarefaParaReducer = (dispatch, value) => {
    dispatch({
        type: ENVIATAREFAPARAREDUCER,
        payload: value
    });
}
const ativaDesativaLoadingCarregandoCOnfiguracoesGerais = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOCONFIGURACOESGERAIS,
        payload: value
    });
}
const modificaVisibleModalAssinaturaPausada = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALASSINATURAPAUSADA,
        payload: value
    });
};

const enviaAtendimentoAberto = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOABERTO,
        payload: value
    });
};
const enviaAtendimentoEmAtendimento = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOEMATENDIMENTO,
        payload: value
    });
};
const enviaAtendimentoCancelado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDMENTOCANCELADO,
        payload: value
    });
};
const enviaAtendimentoFechado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOFECHADO,
        payload: value
    });
};




export const modificaVisibleModalAdiquiraVersaoPremiumExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
        payload: value
    }
}
export const modificaVisibleModalAssinaturaSuspensaExterno = (value) => {
    return {
        type: MODIFICAVISIBLEMODALASSINATURASUSPENSA,
        payload: value
    }
}
const modificaVisibleModalAssinaturaSuspensa = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALASSINATURASUSPENSA,
        payload: value
    });
};

const modificaVisibleModalAdiquiraVersaoPremium = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
        payload: value
    });
};

const enviaTextCabecalhoModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXTCABECALHOMODALINFRELATORIODETALHADO,
        payload: value
    });
};
const enviaText1ModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT1MODALINFRELATORIODETALHADO,
        payload: value
    });
};
const enviaText2ModalInfRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT2MODALINFRELATORIODETALHADO,
        payload: value
    });
};

const enviaTextCabecalhoModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXTCABECALHOMODALINF,
        payload: value
    });
};
const enviaText1ModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT1MODALINF,
        payload: value
    });
};
const enviaText2ModalInf = (dispatch, value) => {
    dispatch({
        type: ENVIATEXT2MODALINF,
        payload: value
    });
};



const enviaNotaMediaAvaliacaoRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIANOTAMEDIAAVALIACAORELATORIOGERAL,
        payload: value
    });
};

const enviaPlanoMensal = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOMENSAL,
        payload: value
    });
};
const enviaPeriodoPersonalizado = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADO,
        payload: value
    });
};
const enviaPlanoTrimestral = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOTRIMESTRAL,
        payload: value
    });
};
const enviaPlanoAnual = (dispatch, value) => {
    dispatch({
        type: ENVIAPLANOANUAL,
        payload: value
    });
};
const verificaAgruparAtendenteSetor = (dispatch, valor) => {
    dispatch({
        type: VERIFICAAGRUPARATENDENTESETOR,
        payload: valor
    });
};
const verificaAtendenteVeApenasProprioChamado = (dispatch, valor) => {
    dispatch({
        type: VERIFICAATENDENTEVERAPENASPROPRIOCHAMADO,
        payload: valor
    });
};
const verificaCriarChamadoProblemaEstatico = (dispatch, valor) => {
    dispatch({
        type: VERIFICACRIARCHAMADOPROBLEMAESTATICO,
        payload: valor
    });
};

const verificaSeEhObrigatorioATribuirAtendente = (dispatch, valor) => {
    dispatch({
        type: VERIFICARSEEHOBRIGATORIOATRIBUIRATENDENTE,
        payload: valor
    });
};
const enviaClientesMaisAbriramChamadosRelatorioGeral = (dispatch, clientes) => {
    dispatch({
        type: ENVIACLIENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: clientes
    });
};
const enviaAtendentesMaisAbriramChamadosRelatorioGeral = (dispatch, atendentes) => {
    dispatch({
        type: ENVIAATENDENTEMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: atendentes
    });
};
const enviaSetoresMaisAbriramChamadosRelatorioGeral = (dispatch, setores) => {
    dispatch({
        type: ENVIASETORESMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: setores
    });
};
const enviaProblemasMaisAbriramChamadosRelatorioGeral = (dispatch, problemas) => {
    dispatch({
        type: ENVIASPROBLEMASMAISABRIRAMCHAMADOSRELATORIOGERAL,
        payload: problemas
    });
};

const enviaSemConexaoInternetRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETRELATORIOGERAL,
        payload: value
    });
};
const modificaLoadingConfiguracoesEmpresa = (dispatch, value) => {
    dispatch({
        type: MODIFICALOADINGCONFIGURACOESEMPRESA,
        payload: value
    });
};
export const modificaLoadingConfiguracoesEmpresaExport = (value) => {
    return {
        type: MODIFICALOADINGCONFIGURACOESEMPRESA,
        payload: value
    }
}
export const zeraEnviaSemConexaoInternetRelatorioGeral = () => {
    return {
        type: ENVIASEMCONEXAOINTERNETRELATORIOGERAL,
        payload: ''
    }
}
export const modificaVisibilidadeModalInformacaoRelatorioGeral = (value) => {
    return {
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL,
        payload: value
    }
}
const modificaMensagemGerandoRelatorio = (dispatch, value) => {
    dispatch({
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: value
    });
}
function modificaMensagemGerandoRelatorioLocal(value) {
    console.log(value)
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: value
    }
}
export const zeraMensagemGerandoRelatorio = () => {
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIO,
        payload: ''
    }
}
const modificaVisibilidadeModalInformacaoRelatorioGeralLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIOGERAL,
        payload: value
    });
}



export const modificaVisibilidadeModalInformacaoRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO,
        payload: value
    }
}
const modificaVisibilidadeModalInformacaoRelatorioDetalhadoLocal = (dispatch, value) => {
    dispatch({
        type: MODIFICAVISIBILIDADEMODALINFORMACAORELATORIODETALHADO,
        payload: value
    });
}



const ativaDesativaLoadingRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGRELATORIOGERAL,
        payload: value
    });
};
const enviaPeriodoPersonalizadoRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADORELATORIOGERAL,
        payload: value
    });
};



const verificaInternetTelaVIP = (dispatch, value) => {
    dispatch({
        type: VERIFICAINTERNETTELAVIP,
        payload: value
    });
};
const loadingTelalVIP = (dispatch, value) => {
    dispatch({
        type: LOADINGTELAVIP,
        payload: value
    });
};
const loadingRefreshControlVIP = (dispatch, value) => {
    dispatch({
        type: LOADINGREFRESHCONTROLVIP,
        payload: value
    });
};

const mensagemPeriodoPersonalizadoError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMPERIODOPERSONALIZADOERROR,
        payload: error
    });
};
const mensagemPeriodoPersonalizadoRelatorioDetalhadoError = (dispatch, error) => {
    dispatch({
        type: MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
        payload: error
    });
};

const enviaChamadosDetalhadosParaDownload = (dispatch, value) => {
    dispatch({
        type: ENVIACHAMADOSDETALHADOSPARADOWNLOAD,
        payload: value
    });
};
export const zeraMensagemPeriodoPersonalizadoRelatorioDetalhadoError = () => {
    return {
        type: MENSAGEMPERIODOPERSONALIZADORELATORIODETALHADOERROR,
        payload: ''
    }
}

export const zeraMensagemErrorMensagemConsultaPersonalizada = () => {
    return {
        type: MENSAGEMPERIODOPERSONALIZADOERROR,
        payload: ''
    }
}

export const modificaFiltroTelaRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICAFILTROTELARELATORIODETALHADO,
        payload: filtro
    }
}

export const modificaDataInicialRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICADATAINICIALRELATORIODETALHADO,
        payload: filtro
    }
}
export const modificaModalDuvidasFrequentes = (value) => {
    return {
        type: MODIFICAMODALDUVIDASFREQUENTES,
        payload: value
    }
}

export const modificaDataFinalRelatorioDetalhado = (filtro) => {
    return {
        type: MODIFICADATAFINALRELATORIODETALHADO,
        payload: filtro
    }
}


export const modificaFiltroClienteSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROCLIENTESECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaFiltroAtendenteSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROATENDENTESECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaFiltroSetorSectionedTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAFILTROSETORSECTIONEDTELARELATORIODETALHADO,
        payload: value
    }
}





export const modificaClienteTelaRelatorioDetalhado = (value) => {
    return {
        type: MODIFICACLIENTETELARELATORIODETALHADO,
        payload: value
    }
}
export const modificaAtendenteRelatorioDetalhado = (value) => {
    return {
        type: MODIFICAATENDENTERELATORIODETALHADO,
        payload: value
    }
}
export const modificaSetorRelatorioDetalhado = (value) => {
    return {
        type: MODIFICASETORRELATORIODETALHADO,
        payload: value
    }
}

const ativaDesativaLoadingSemInternetRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGSEMINTERNETRELATORIOGERAL,
        payload: value
    });
};
const enviaAtendimentosNoTotalDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIAATENDIMENTOSNOTOTALDASHBOARD,
        payload: qtd
    });
};
const enviaSemConexaoInternetDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETDASHBOARD,
        payload: qtd
    });
};

const ativaDesativaLoadingTelaRelatorioAtendimentoDetalhados = (dispatch, value) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELARELATORIOATENDIMENTODETALHADOS,
        payload: value
    });
};
const enviaSemConexaoInternetTelaRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIASEMCONEXAOINTERNETTELARELATORIODETALHADO,
        payload: value
    });
};
const enviaPeriodoPersonalizadoRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAPERIODOPERSONALIZADORELATORIODETALHADI,
        payload: value
    });
};

const enviaTempoMedioDeSolucaoAtendimentos = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPOMEDIODESOLUCAOATENDIMENTOS,
        payload: value
    });
};
const enviaTempoMedioDeSolucaoAtendimentosRelatorioGeral = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPOMEDIODESOLUCAOATENDIMENTOSRELATORIOGERAL,
        payload: value
    });
};

const enviaAtendimentosPorStatusRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORSTATUSRELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorStatusRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORSTATUSRELATORIODETALHADO,
        payload: value
    });
};
const enviaAtendimentosPorPrioridadeRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorPRioridadeRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORPRIORIDADERELATORIODETALHADO,
        payload: value
    });
};
const enviaTempoResolucaoChamadosRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATEMPORESOLUCAOCHAMADOSRELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPeriodoResolucaoRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPERIODORESOLUCAORELATORIODETALHADO,
        payload: value
    });
};
const enviaTotalAtendimentosPorLocalRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPORLOCALRELATORIODETALHADO,
        payload: value
    });
};
const enviaAtendimentosPorLocalRelatorioDetalhado = (dispatch, value) => {
    dispatch({
        type: ENVIAATENDIMENTOSPORLOCALRELATORIODETALHADO,
        payload: value
    });
};


const enviaClientesNoTOtalDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIACLIENTESNOTOTALDASHBOARD,
        payload: qtd
    });
};
const enviaAtendimentosHojeDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIATENDIMENTOSHOJEDASHBOARD,
        payload: qtd
    });
};
const enviaAtendimentosDestaSemanaDashBoard = (dispatch, qtd) => {
    dispatch({
        type: ENVIAATENDIMENTOSDESTASEMANADASHBOARD,
        payload: qtd
    });
};
const enviaTotalAtendimentosPorPRioridade = (dispatch, qtd) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOPORPRIORIDADE,
        payload: qtd
    });
};
const enviaAtendimentosPorStatusDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORSTATUSDASHBOARD,
        payload: array
    });
};

const enviaAtendimentosPorLocalDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORLOCALDASHBOARD,
        payload: array
    });
};
const enviaTotalAtendimentosPorLocalDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATOALTENDIMENTOSPORLOCALDASHBOARD,
        payload: array
    });
};

const enviaAtendimentosPorPrioridadeDashboard = (dispatch, array) => {
    dispatch({
        type: ENVIATENDIMENTOSPORPRIORIDADEDASHBOARD,
        payload: array
    });
};
const enviaClientesMaisAbriramChamados = (dispatch, clientes) => {
    dispatch({
        type: ENVIACLIENTEMAISABRIRAMCHAMADOS,
        payload: clientes
    });
};
const enviaPermissoesAcessarTelasAtendente = (dispatch, permissoes) => {
    dispatch({
        type: ENVIAPERMISSOESACESSARTELASATENDENTE,
        payload: permissoes
    });
};
const enviaSetoresMaisAbriramChamados = (dispatch, setores) => {
    dispatch({
        type: ENVIASETORESMAISABRIRAMCHAMADOS,
        payload: setores
    });
};
const enviaSeAtendenteEhAdministrador = (dispatch, value) => {
    dispatch({
        type: ENVIASEATENDENTEEHADMINISTRADOR,
        payload: value
    });
};
const enviaTempoResolucaoChamados = (dispatch, array) => {
    dispatch({
        type: ENVIATEMPORESOLUCAOCHAMADOS,
        payload: array
    });
};
const enviaTotalAtendimentosPeriodoResolucao = (dispatch, array) => {
    dispatch({
        type: ENVIATOTALATENDIMENTOSPERIODORESOLUCAO,
        payload: array
    });
};

const ativaDesativaLoadingTelaDashBoard = (dispatch, status) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELADASHBOARD,
        payload: status
    });
};
const ativaDesativaLoadingRenderVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGVERSAOPREMIUM,
        payload: valor
    });
};

const enviaNomeAssinante = (dispatch, valor) => {
    dispatch({
        type: ENVIANOMEASSINANTE,
        payload: valor
    });
};
const enviaEmailAssinante = (dispatch, valor) => {
    dispatch({
        type: ENVIAEMAILASSINANTE,
        payload: valor
    });
};

const enviaPremiumVersaoGartis = (dispatch, valor) => {
    dispatch({
        type: ENVIAPREMIUMVERSAOGRATIS,
        payload: valor
    });
};

const enviaTextStatusAssinatura = (dispatch, valor) => {
    dispatch({
        type: ENVIATEXTSTATUSASSINATURA,
        payload: valor
    });
};
const enviaCorTextStatusAssinatura = (dispatch, valor) => {
    dispatch({
        type: ENVIACORTEXTSTATUSASSINATURA,
        payload: valor
    });
};
const ativaDesativaLoadingRefreshControol = (dispatch, status) => {
    dispatch({
        type: ATIVADESATIVALOADINGREFRESHCONTROL,
        payload: status
    });
};
const enviaSeEhVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIASEEHVERSAOPREMIUM,
        payload: valor
    });
};
const enviaDataValidadeVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATAVALIDADEVERSAOPREMIUM,
        payload: valor
    });
};
const enviaDataCompraVersaoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATACOMPRAVERSAOPREMIUM,
        payload: valor
    });
};

///////////////

const enviaDataValidadeAtualPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIADATAVALIDADEATUALPREMIUM,
        payload: valor
    });
};
const enviaNumeroPedidoCompraPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIANUMEROPEDIDOCOMPRAPREMIUM,
        payload: valor
    });
};
const enviaPlanoPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAPLANOPREMIUM,
        payload: valor
    });
};
const enviaPlataformaPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAPLATAFORMAPREMIUM,
        payload: valor
    });
};
const enviaQtdAtendentesLiberadosPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM,
        payload: valor
    });
};
const enviaStatusAssinaturaPremium = (dispatch, valor) => {
    dispatch({
        type: ENVIASTATUSASSINATURAPREMIUM,
        payload: valor
    });
};
////////////////

const zeraDataInicial = (dispatch) => {
    dispatch({
        type: MODIFICADATAINICIALCONSULTADASHBOARD,
        payload: null
    });
};
const zeraDataFinal = (dispatch) => {
    dispatch({
        type: MODIFICADATAFINALCONSULTADASHBOARD,
        payload: null
    });
};

const zeraDataInicialFiltroRelatorioDetalhado = (dispatch) => {
    dispatch({
        type: ZERADATAINICIALFILTRORELATORIODETALHADO,
        payload: null
    });
};

const zeraDataInicialFiltroRelatorioGeral = (dispatch) => {
    dispatch({
        type: ZERADATAINICIALFILTRORELATORIOGERAL,
        payload: null
    });
};
const zeraDataFinalFiltroRelatorioGeral = (dispatch) => {
    dispatch({
        type: ZERADATAFINALFILTRORELATORIOGERAL,
        payload: null
    });
};
const enviaPessoasComMaisAtendimentosParaDownload = (dispatch, array) => {
    dispatch({
        type: ENVIAPESSOASCOMMAISATENDIMENTOSPARADOWNLOAD,
        payload: array
    });
};

const zeraDataFinalFiltroRelatorioDetalhado = (dispatch) => {
    dispatch({
        type: ZERADATAFINALFILTRORELATORIODETALHADO,
        payload: null
    });
}

const modificaMensagemGerandoRelatorioVisaoGeral = (dispatch, value) => {
    dispatch({
        type: MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
        payload: value
    });
}
const modificaToastAcoesAtendimento = (dispatch, value) => {
    dispatch({
        type: MODIFICATOASTACOESATNEDIMENTO,
        payload: value
    });
}
export const zeraToastAcoesAtendimento = value => {
    return {
        type: MODIFICATOASTACOESATNEDIMENTO,
        payload: value
    };
};
export const zeraModificaMensagemGerandoRelatorioVisaoGeral = () => {
    return {
        type: MODIFICAMENSAGEMGERANDORELATORIOVISAOGERAL,
        payload: ''
    };
};


export const modificaDataInicialConsultaDashBoard = data => {
    return {
        type: MODIFICADATAINICIALCONSULTADASHBOARD,
        payload: data
    };
};
export const modificaDataFinalConsultaDashBoard = data => {
    return {
        type: MODIFICADATAFINALCONSULTADASHBOARD,
        payload: data
    };
};

const enviaNomeParaReducer = (dispatch, nome) => {
    dispatch({
        type: CARREGANOMEPERFIL,
        payload: nome
    });
}
const enviaEmailParaReducer = (dispatch, email) => {
    dispatch({
        type: CARREGAEMAILPERFIL,
        payload: email
    });

}
const enviaEmpresaParaReducer = (dispatch, empresa) => {
    dispatch({
        type: ENVIAEMPRESAPARAREDUCER,
        payload: empresa
    });

}
const enviaSenhaParaReducer = (dispatch, empresa) => {
    dispatch({
        type: ENVIASENHAPARAREDUCER,
        payload: empresa
    });

}
const enviaConfirmaSenhaParaReducer = (dispatch, empresa) => {
    dispatch({
        type: ENVIACONFIRMASENHAPARAREDUCER,
        payload: empresa
    });

}
const enviaKeyUsuarioPerfil = (dispatch, key) => {
    dispatch({
        type: ENVIAKEYUSUARIOPERFIL,
        payload: key
    });
}
const enviaImagemPerfil = (dispatch, img) => {
    dispatch({
        type: CARREGAIMAGEMPERFIL,
        payload: img
    });
}

export const ativaDesativaRenovarPLano = value => {
    return {
        type: ATIVADESATIVARENOVARPLANO,
        payload: value
    };
};

export const modificaMostraVersaoExpirando = value => {
    return {
        type: MODIFICAMOSTRAVERSAOEXPIRANDO,
        payload: value
    };
};

export const modificaDataInicialRelatorioGeral = data => {
    return {
        type: MODIFICADATAINICIALRELATORIOGERAL,
        payload: data
    };
};
export const modificaDataFinalRelatorioGeral = data => {
    return {
        type: MODIFICADATAFINALRELATORIOGERAL,
        payload: data
    };
};
function ordenaObjeto(obj) {
    var arr = [];
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({
                'key': prop,
                'value': obj[prop]
            });
        }
    }
    arr.sort(function (a, b) { return a.value - b.value; });
    //arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
    return arr.reverse(); // returns array
}

function retornaDataTimeStamp(data) {
    var myDate = data;
    myDate = myDate.split("/");
    var newDate = myDate[1] + "/" + myDate[0] + "/" + myDate[2];
    return new Date(newDate).getTime();
}
function retornaDataAtual() {
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
}
function retornaMedia(arrayComValores) {
    const total = arrayComValores.reduce((acc, c) => acc + c, 0);
    return total / arrayComValores.length;
}
function calculaDias2(date1, date2) {
    //formato do brasil 'pt-br'
    // moment.locale('pt-br');
    //setando data1
    let data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    let data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    // let diff = data2.diff(data1, 'days');
    let diff = moment.duration(data2.diff(data1)).asDays();

    return diff;
}
function encurtaTexto(text, length) {
    console.log('no encurta texto')
    console.log(text)
    console.log(length)
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    let last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    console.log(text)
    return text + "...";
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};
function toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
        console.log('no to date')
        console.log(xhr.response)
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

function _renderImages(url) {
    toDataUrl(url, (myBase64) => {

        console.log('no render image')
        console.log(myBase64)
        return myBase64
    });


}

function download(url, name) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
};
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
}
function toTimeZone(time, zone) {
    let format = 'HH:mm';
    return moment(time, format).tz(zone).format(format);

}
function verificaSeEhAlphabeto(ch, dispatch) {
    // console.log(ch)
    let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
        && (ch >= "a" && ch <= "z");
    //console.log('no metodo')
    // console.log(verificaSeEhAlphabeto)
    if (verificaSeEhAlphabeto) {
        //console.log('é do alfabeto')
        switch (ch) {
            case "a":
                let a = require('../imagens/a.png')
                enviaImagemPerfil(dispatch, a);
                break;
            case "b":
                let b = require('../imagens/b.png');
                enviaImagemPerfil(dispatch, b);
                break;
            case "c":
                let c = require('../imagens/c.png');
                enviaImagemPerfil(dispatch, c);
                break;
            case "d":
                let d = require('../imagens/d.png');
                enviaImagemPerfil(dispatch, d);
                break;
            case "e":
                let e = require('../imagens/e.png');
                enviaImagemPerfil(dispatch, e);
                break;
            case "f":
                let f = require('../imagens/f.png');
                enviaImagemPerfil(dispatch, f);
                break;
            case "g":
                let g = require('../imagens/g.png');
                enviaImagemPerfil(dispatch, g)
                break;
            case "h":
                let h = require('../imagens/h.png');
                enviaImagemPerfil(dispatch, h);
                break;
            case "i":
                let i = require('../imagens/i.png');
                enviaImagemPerfil(dispatch, i);
                break;
            case "j":
                let j = require('../imagens/j.png');
                enviaImagemPerfil(dispatch, j);
                break;
            case "k":
                let k = require('../imagens/k.png');
                enviaImagemPerfil(dispatch, k);
                break;
            case "l":
                let l = require('../imagens/l.png');
                enviaImagemPerfil(dispatch, l);
                break;
            case "m":
                let m = require('../imagens/m.png');
                enviaImagemPerfil(dispatch, m);
                break;
            case "n":
                let n = require('../imagens/n.png');
                enviaImagemPerfil(dispatch, n);
                break;
            case "o":
                let o = require('../imagens/o.png');
                enviaImagemPerfil(dispatch, o);
                break;
            case "p":
                let p = require('../imagens/p.png');
                enviaImagemPerfil(dispatch, p);
                break;
            case "q":
                let q = require('../imagens/q.png');
                enviaImagemPerfil(dispatch, q);
                break;
            case "r":
                let r = require('../imagens/r.png');
                enviaImagemPerfil(dispatch, r);
                break;
            case "s":
                let s = require('../imagens/s.png');
                enviaImagemPerfil(dispatch, s);
                break;
            case "t":
                let t = require('../imagens/t.png');
                enviaImagemPerfil(dispatch, t);
                break;
            case "u":
                let u = require('../imagens/u.png');
                enviaImagemPerfil(dispatch, u);
                break;
            case "v":
                let v = require('../imagens/v.png');
                enviaImagemPerfil(dispatch, v);
                break;
            case "x":
                let x = require('../imagens/x.png');
                enviaImagemPerfil(dispatch, x);
                break;
            case "z":
                let z = require('../imagens/z.png');
                enviaImagemPerfil(dispatch, z);
                break;
            case "w":
                let w = require('../imagens/w.png');
                enviaImagemPerfil(dispatch, w);
                break;
            case "y":
                let y = require('../imagens/y.png');
                enviaImagemPerfil(dispatch, y);
                break;
            default:
                let defaultimage = require('../imagens/userPadrao.png');
                enviaImagemPerfil(dispatch, defaultimage);
                console.log('dentro do default')
                break;

        }
    } else {
        //   console.log('não é do alfabeto')
        let urlIMG = require('../imagens/userPadrao.png')
        enviaImagemPerfil(dispatch, urlIMG);
    }

}