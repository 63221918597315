import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import api from '../http_client/http.client';
import {
    CARREGANOMEPERFIL, CARREGAEMAILPERFIL, CARREGAIMAGEMPERFIL, ENVIATOUCHBACKGROUNDMENUGAVETA,
    ENVIAKEYUSUARIOPERFIL, ACESSOUMEUPERFIL, MODIFICANOMECLIENTETELAPERFIL, INSEREFOTOALTERARCLIENTE, ATIVALOADINGBOTAOALTERARCLIENTE,
    CARREGAEMAILCLIENTEPERFIL, ENVIASENHAPARAREDUCER, ENVIACONFIRMASENHAPARAREDUCER
} from './types';

export const alteraPerfil = (empresa2, nome2, foto2, email, senha2, confirmaSenha2, arquivoCompletoParaUpar) => {
    /*    console.log(empresa2, nome2, foto2, email, senha2, confirmaSenha2, arquivoCompletoParaUpar); */

    const empresa = empresa2.trim();
    const nome = nome2.trim();
    const emailCliente = email.trim().toLowerCase();
    const senha = senha2.trim();
    const confirmarSenha = confirmaSenha2.trim();
    let uriHTTP = 0;
    let foto;
    let verificaSeUriImagemEhImagemUpadaOuNao = false;
    if (foto2.includes('/static/media/userPadrao') || foto2.includes('data:image/png;base64')) {
        /*  console.log('no tipo uri') */
        //é uma imagem local nao precisa upar
        uriHTTP = 1;
    } else if (foto2.includes("http") && !foto2.includes("blob")) {
        verificaSeUriImagemEhImagemUpadaOuNao = true;
        foto = foto2;
        /*     console.log('segundo if') */
        uriHTTP = 1;
    } else if (arquivoCompletoParaUpar === '') {
        /*  console.log('arquivos completo para upar vazio') */
        uriHTTP = 1;
    } else {
        /*  console.log('no else') */
        //é uma imagem que precisa upar
        foto = foto2;
    }

    return dispatch => {
        if (nome.trim() === '') {
            toastr.error('Error', 'Digite seu Nome.');
        }
        else if (nome.trim().length < 2) {
            toastr.error('Error', 'Seu nome deve conter mais do que 1 letra.');
        }
        else if (emailCliente.trim() === "") {
            toastr.error('Error', "Digite um email.");
        } else if (senha.trim() === "") {
            toastr.error('Error', "Digite a senha de acesso.");
        } else if (senha.trim().length < 6) {
            toastr.error('Error', "A senha de acesso deve conter pelo menos 6 digitos.");
        } else if (confirmarSenha.trim() === "") {
            toastr.error('Error', "Confirme a senha de acesso.");
        } else if (senha !== confirmarSenha) {
            toastr.error('Error', "Senhas diferentes, por favor digite novamente.");
        }
        /*   else if (empresa.trim() === '') {
              toastr.error('Error', 'Digite o Nome da Empresa.');
          }
          else if (empresa.trim().length < 2) {
              toastr.error('Error', 'O Nome da Empresa conter mais do que 1 letra.');
          } */
        else {
            ativaDesativaLoadingBotaoAlterarCliente(dispatch, true);
            AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                /*  if (foto2 !== null) {
                     foto = foto2.uri;
                     if (foto.includes("http")) {
                         uriHTTP = 1;
                     }
                 } */
                const { currentUser } = firebase.auth();
                let useruid = currentUser.uid;
                firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                    if (snapshot.val()) {
                        let keyUsuario = Object.keys(snapshot.val()).toString();
                        let alterado;
                        let senhaBanco;
                        let emailBanco;
                        snapshot.forEach(childItem => {
                            alterado = childItem.val().alterado + 1;
                            senhaBanco = childItem.val().senha;
                            emailBanco = childItem.val().email;

                        });

                        /*  console.log(alterado, senhaBanco, emailBanco); */

                        if (uriHTTP == 1) {
                            //altero email cliente
                            if (emailBanco !== emailCliente) {
                                //troco senha
                                if (senhaBanco !== senha) {
                                    /*  secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                    
                                         userCredential.updateEmail(emailCliente).then(() => {
                                       
                                             secondaryApp.auth().signInWithEmailAndPassword(emailCliente, senhaBanco)
                                                 .then(user2 => {
                                                     user2.updatePassword(senha).then(() => { */
                                    const options = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json; charset=utf-8',
                                            'Accept': 'application/json',
                                        },

                                        data: { email: emailCliente, senha: senha, rota: 'alteraremailesenha', uid: useruid },
                                        url: 'crudUserReactNative',
                                    };
                                    api(options).then((response) => {
                                        console.log('sucesso update user')
                                        console.log(response.data)
                                        /*   console.log('3'); */
                                        firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                            nome: nome,
                                            email: emailCliente,
                                            senha: senha,
                                            alterado: alterado,
                                            //empresa: empresa,
                                            urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? foto : "",
                                        }).then(() => {
                                            /*  console.log('só cliente alterado 1'); */
                                            firebase.auth().signInWithEmailAndPassword(emailCliente, senha);
                                            const arrayEmailSenha = [{
                                                key: emailCliente,
                                                email: emailCliente,
                                                senha: senha,
                                            }];
                                            /* console.log(arrayEmailSenha) */
                                            AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                            toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                            ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        })
                                    }).catch(error => {
                                        ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        switch (error.code) {
                                            case "auth/invalid-email":
                                                toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                break;
                                            case "auth/email-already-in-use":
                                                toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                break;
                                            case "auth/network-request-failed":
                                                toastr.error('Error', "Sem conexão com a internet");
                                                break;
                                            default:
                                                toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                break;
                                        }
                                    });

                                }
                                //troco somente email
                                else {
                                    /*    secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                         
                                           userCredential.updateEmail(emailCliente).then(() => { */
                                    const options = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json; charset=utf-8',
                                            'Accept': 'application/json',
                                        },

                                        data: { email: emailCliente, senha: senhaBanco, rota: 'alteraremail', uid: useruid },
                                        url: 'crudUserReactNative',
                                    };
                                    api(options).then((response) => {
                                        console.log('sucesso update user')
                                        console.log(response.data)

                                        firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                            nome: nome,
                                            email: emailCliente,
                                            alterado: alterado,
                                            //  senha: senha,
                                            //empresa: empresa,
                                            urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? foto : "",
                                        }).then(() => {
                                            /*   console.log('só cliente alterado 2.'); */
                                            firebase.auth().signInWithEmailAndPassword(emailCliente, senhaBanco);
                                            const arrayEmailSenha = [{
                                                key: emailCliente,
                                                email: emailCliente,
                                                senha: senhaBanco
                                            }];
                                            /*    console.log(arrayEmailSenha) */
                                            AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                            toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                            ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        })

                                    }).catch(error => {
                                        ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        switch (error.code) {
                                            case "auth/invalid-email":
                                                toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                break;
                                            case "auth/email-already-in-use":
                                                toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                break;
                                            case "auth/network-request-failed":
                                                toastr.error('Error', "Sem conexão com a internet");
                                                break;
                                            default:
                                                toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                break;
                                        }
                                    });


                                }
                            } else {
                                //troco senha
                                if (senhaBanco !== senha) {
                                    /*  secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                     
                                         userCredential.updatePassword(senha).then(() => { */

                                    const options = {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json; charset=utf-8',
                                            'Accept': 'application/json',
                                        },

                                        data: { email: emailBanco, senha: senha, rota: 'alterarsenha', uid: useruid },
                                        url: 'crudUserReactNative',
                                    };
                                    api(options).then((response) => {
                                        console.log('sucesso create user')
                                        console.log(response.data)

                                        firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                            nome: nome,
                                            //    email: emailCliente,
                                            senha: senha,
                                            alterado: alterado,
                                            //empresa: empresa,
                                            urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? foto : "",
                                        }).then(() => {
                                            /*   console.log('só cliente alterado 3.'); */
                                            firebase.auth().signInWithEmailAndPassword(emailBanco, senha);
                                            const arrayEmailSenha = [{
                                                key: emailCliente,
                                                email: emailBanco,
                                                senha: senha,
                                            }];
                                            /*  console.log(arrayEmailSenha) */
                                            AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                            toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                            ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        })

                                    }).catch(error => {
                                        ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                        switch (error.code) {
                                            case "auth/invalid-email":
                                                toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                break;
                                            case "auth/email-already-in-use":
                                                toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                break;
                                            case "auth/network-request-failed":
                                                toastr.error('Error', "Sem conexão com a internet");
                                                break;
                                            default:
                                                toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                break;
                                        }
                                    });


                                }
                                //update somente no nome do cliente
                                else {
                                    firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                        nome: nome,
                                        alterado: alterado,
                                        //empresa: empresa,
                                        urlImagemNuvem: verificaSeUriImagemEhImagemUpadaOuNao == true ? foto : "",
                                    }).then(() => {
                                        /*  console.log('só cliente alterado.'); */
                                        toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                        ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                    })
                                }
                            }

                        } else {
                            let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyUsuario}/imagem.jpg`);
                            caminhoPadraoImagem.put(arquivoCompletoParaUpar).then((url) => {
                                url.ref.getDownloadURL().then(function (downloadURL) {
                                    if (emailBanco !== emailCliente) {
                                        //troco senha
                                        if (senhaBanco !== senha) {
                                            /*       secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                                      userCredential.updateEmail(emailCliente).then(() => {
                                                          secondaryApp.auth().signInWithEmailAndPassword(emailCliente, senhaBanco)
                                                              .then(user2 => {
                                                                  user2.updatePassword(senha).then(() => { */
                                            /*  console.log('3'); */
                                            const options = {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json; charset=utf-8',
                                                    'Accept': 'application/json',
                                                },

                                                data: { email: emailCliente, senha: senha, rota: 'alteraremailesenha', uid: useruid },
                                                url: 'crudUserReactNative',
                                            };
                                            api(options).then((response) => {
                                                console.log('sucesso update user')
                                                console.log(response.data)
                                                firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                                    nome: nome,
                                                    email: emailCliente,
                                                    senha: senha,
                                                    alterado: alterado,
                                                    //empresa: empresa,
                                                    urlImagemNuvem: downloadURL
                                                }).then(() => {
                                                    /*    console.log('cliente e imagem alterado 4.'); */
                                                    firebase.auth().signInWithEmailAndPassword(emailCliente, senha);
                                                    const arrayEmailSenha = [{
                                                        key: emailCliente,
                                                        email: emailCliente,
                                                        senha: senha,
                                                    }];
                                                    /*  console.log(arrayEmailSenha) */
                                                    AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                                    toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                                    ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                })
                                            }).catch(error => {
                                                /* console.log(error) */
                                                ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                switch (error.code) {
                                                    case "auth/invalid-email":
                                                        toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                        break;
                                                    case "auth/email-already-in-use":
                                                        toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                        break;
                                                    case "auth/network-request-failed":
                                                        toastr.error('Error', "Sem conexão com a internet");
                                                        break;
                                                    default:
                                                        toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                        break;
                                                }
                                            });

                                        }
                                        //troco somente email
                                        else {
                                            /*    secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                                 
                                                   userCredential.updateEmail(emailCliente).then(() => { */
                                            const options = {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json; charset=utf-8',
                                                    'Accept': 'application/json',
                                                },

                                                data: { email: emailCliente, senha: senhaBanco, rota: 'alteraremail', uid: useruid },
                                                url: 'crudUserReactNative',
                                            };
                                            api(options).then((response) => {
                                                console.log('sucesso update user')
                                                console.log(response.data)

                                                firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                                    nome: nome,
                                                    email: emailCliente,
                                                    //  senha: senha,
                                                    //empresa: empresa,
                                                    urlImagemNuvem: downloadURL,
                                                    alterado: alterado,
                                                }).then(() => {
                                                    /*  console.log('só cliente alterado 5.'); */
                                                    firebase.auth().signInWithEmailAndPassword(emailCliente, senhaBanco);
                                                    const arrayEmailSenha = [{
                                                        key: emailCliente,
                                                        email: emailCliente,
                                                        senha: senhaBanco
                                                    }];
                                                    /*  console.log(arrayEmailSenha); */
                                                    AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                                    toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                                    ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                })

                                            }).catch(error => {
                                                ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                switch (error.code) {
                                                    case "auth/invalid-email":
                                                        toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                        break;
                                                    case "auth/email-already-in-use":
                                                        toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                        break;
                                                    case "auth/network-request-failed":
                                                        toastr.error('Error', "Sem conexão com a internet");
                                                        break;
                                                    default:
                                                        toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                        break;
                                                }
                                            });


                                        }
                                    } else {
                                        //troco senha
                                        if (senhaBanco !== senha) {
                                            /*  secondaryApp.auth().signInWithEmailAndPassword(emailBanco, senhaBanco).then(userCredential => {
                                                 userCredential.updatePassword(senha).then(() => { */
                                            const options = {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json; charset=utf-8',
                                                    'Accept': 'application/json',
                                                },

                                                data: { email: emailBanco, senha: senha, rota: 'alterarsenha', uid: useruid },
                                                url: 'crudUserReactNative',
                                            };
                                            api(options).then((response) => {
                                                console.log('sucesso create user')
                                                console.log(response.data)
                                                firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({
                                                    nome: nome,
                                                    //    email: emailCliente,
                                                    senha: senha,
                                                    //empresa: empresa,
                                                    urlImagemNuvem: downloadURL,
                                                    alterado: alterado,
                                                }).then(() => {
                                                    /*  console.log('só cliente alterado 6.'); */
                                                    firebase.auth().signInWithEmailAndPassword(emailBanco, senha);
                                                    const arrayEmailSenha = [{
                                                        key: emailCliente,
                                                        email: emailCliente,
                                                        senha: senha,
                                                    }];
                                                    /*  console.log(arrayEmailSenha) */
                                                    AsyncStorage.setItem(`loginCliente`, JSON.stringify(arrayEmailSenha));
                                                    toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                                    ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                })

                                            }).catch(error => {
                                                ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                switch (error.code) {
                                                    case "auth/invalid-email":
                                                        toastr.error('Error', "Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br");
                                                        break;
                                                    case "auth/email-already-in-use":
                                                        toastr.error('Error', "Este email já está sendo utilizado, informe outro email.");
                                                        break;
                                                    case "auth/network-request-failed":
                                                        toastr.error('Error', "Sem conexão com a internet");
                                                        break;
                                                    default:
                                                        toastr.error('Error', "Erro ao Alterar, tente novamente mais tarde");
                                                        break;
                                                }
                                            });


                                        }
                                        //update somente no nome e imagem do cliente
                                        else {
                                            firebase.database().ref(`${chaveUnica}/clientes/${keyUsuario}`).update({ nome: nome, urlImagemNuvem: downloadURL, alterado: alterado, }).then(() => {
                                                /*  console.log('foto e cliente alterado.') */
                                                toastr.success('Sucesso', 'Perfil alterado com sucesso!');
                                                ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                            }).catch((error) => {
                                                //error.code
                                                //error.message
                                                ativaDesativaLoadingBotaoAlterarCliente(dispatch, false);
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                        }
                                    }

                                })
                            })
                        }

                    } else {

                    }
                })
            })
        }
    }
}

export const preenchePerfilUsuario = () => {
    let nome = '';
    let keyUsuario = ''
    return dispatch => {
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            if (chaveUnica2 == null) {
                firebase.database().ref(`usuariosALL/${firebase.auth().currentUser.uid}`).once('value').then((snapshot) => {
                    if (snapshot.val()) {
                        /* console.log(`tem dados`) */
                        const dadosUsuario = _.first(_.values(snapshot.val()));
                        let chaveUnica = dadosUsuario.chaveUnica;
                        //se quem tiver logando for admin, pode logar normalmente, caso seja cliente vai para o else
                        if (dadosUsuario.admin == 1) {
                            localStorage.setItem(`chaveUnica`, JSON.stringify(chaveUnica));
                            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                                if (snapshot.val()) {
                                    snapshot.forEach((childItem) => {
                                        keyUsuario = childItem.key;
                                        nome = childItem.val().nome;
                                        enviaNomeParaReducer(dispatch, childItem.val().nome);
                                        enviaEmailParaReducer(dispatch, childItem.val().email);
                                        enviaKeyUsuarioPerfil(dispatch, childItem.key);
                                    })
                                    firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                                        enviaImagemPerfil(dispatch, url)
                                    }).catch(() => {
                                        const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                                        firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                            enviaImagemPerfil(dispatch, url)
                                        }).catch(() => {
                                            const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                            enviaImagemPerfil(dispatch, fotoURI)
                                        })
                                    })

                                } else {
                                }
                            })
                        } else {
                            // toastr.error('Error', 'Essa conta é uma conta cliente, por favor baixe o aplicativo dropdesk cliente.');
                            //firebase.auth().signOut();
                            //desativaLoadingBotaoLogarTelaLogin(dispatch);
                        }
                    } else {
                    }
                })
            } else {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                // const { currentUser } = firebase.auth();
                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            keyUsuario = childItem.key;
                            nome = childItem.val().nome;
                            enviaNomeParaReducer(dispatch, childItem.val().nome);
                            enviaEmailParaReducer(dispatch, childItem.val().email);
                            enviaKeyUsuarioPerfil(dispatch, childItem.key);
                        })
                        firebase.storage().ref().child(`${chaveUnica}/${keyUsuario}/imagem.jpg`).getDownloadURL().then((url) => {
                            enviaImagemPerfil(dispatch, url)
                        }).catch(() => {
                            const primeiraLetra = nome.substring(0, 1).trim().toLowerCase();
                            firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
                                enviaImagemPerfil(dispatch, url)
                            }).catch(() => {
                                const fotoURI = 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af'
                                enviaImagemPerfil(dispatch, fotoURI)
                            })
                        })

                    } else {
                    }
                })
            }
        });
    }
}
export const sairDoApp = () => {
    return dispatch => {
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                if (snapshot.val()) {
                    let key = Object.keys(snapshot.val()).toString()
                    firebase.database().ref(`${chaveUnica}/clientes/${key}`).update({ fcmTokenWEB: '' }).then(() => {
                        let keysToRemove = ["loginCliente", "chaveUnicaCliente", "tabindexCliente", "menuOpenCliente", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "logoLogin", "backgroundSegundo", "coresLetraPadrao", "letraBotaoPadrao","token"];
                        for (const key of keysToRemove) {
                            localStorage.removeItem(key);
                        }
                        firebase.auth().signOut();
                        /*  localStorage.removeItem('user');
                         localStorage.removeItem('login');
                         localStorage.removeItem('chaveUnica');
                         localStorage.removeItem('configuracoesInseridas3');
                         localStorage.removeItem('tabindex');
                         localStorage.removeItem('menuOpen');
                         localStorage.removeItem('keyAtendimento');
                         localStorage.removeItem('numeroAtendimento');
                         localStorage.removeItem('backgroundPrimeiro');
                         localStorage.removeItem('corBotaoPadrao'); */

                    })
                }
            }).catch(() => {
                let keysToRemove = ["loginCliente", "chaveUnicaCliente", "tabindexCliente", "menuOpenCliente", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "logoLogin", "backgroundSegundo", "coresLetraPadrao", "letraBotaoPadrao","token"];
                for (const key of keysToRemove) {
                    localStorage.removeItem(key);
                }
                firebase.auth().signOut();
            })
        })


    }
}
export const insereFotoAlterarUsuario = uri => {
    return {
        type: INSEREFOTOALTERARCLIENTE,
        payload: uri
    };
};
export const modificaNomeClienteTelaPerfil = uri => {
    return {
        type: MODIFICANOMECLIENTETELAPERFIL,
        payload: uri
    };
};
export const mudaToachBackGround = (botao) => {
    let arrayTouchBackGround = [];
    return dispatch => {
        switch (botao) {
            case 'Inicio':

                arrayTouchBackGround.push({
                    selecionadoInicio: true,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Vip':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: true,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Clientes':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: true,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Atendentes':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: true,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Setores':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: true,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Configuracao':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: true,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Contato':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: true,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Sobre':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: true,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avaliar':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: true,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'RedesSocias':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: true,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Compartilhar':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: true,
                    selecionadoAvisos: false,
                    selecionadoSair: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Sair':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoAvisos: false,
                    selecionadoSair: true,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avisos':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: true,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'ConfiguracaoEmpresa':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: true,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Avaliacao':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: true,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: false,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
            case 'Relatorios':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: true,
                    selecionadoCentralDeAjuda: false,

                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;

            case 'CentralDeAjuda':
                arrayTouchBackGround.push({
                    selecionadoInicio: false,
                    selecionadoVip: false,
                    selecionadoClientes: false,
                    selecionadoAtendentes: false,
                    selecionadoSetores: false,
                    selecionadoConfiguracao: false,
                    selecionadoContato: false,
                    selecionadoSobre: false,
                    selecionadoAvaliar: false,
                    selecionadoRedesSocias: false,
                    selecionadoCompartilhar: false,
                    selecionadoSair: false,
                    selecionadoAvisos: false,
                    selecionadoConfiguracaoEmpresa: false,
                    selecionadoAvaliacaoAtendimento: false,
                    selecionadoRelatorio: false,
                    selecionadoCentralDeAjuda: true,
                });
                enviaTochBackGroundMenuGaveta(dispatch, arrayTouchBackGround);
                break;
        }
    }
}

export const modificaEmailClienteTelaCadastroCliente = email => {
    return {
        type: CARREGAEMAILCLIENTEPERFIL,
        payload: email
    };
};
export const modificaSenhaClienteTelaCadastroCliente = senha => {
    return {
        type: ENVIASENHAPARAREDUCER,
        payload: senha
    };
};
export const modificaConfirmaClienteTelaCadastroCliente = senha => {
    return {
        type: ENVIACONFIRMASENHAPARAREDUCER,
        payload: senha
    };
};
const enviaNomeParaReducer = (dispatch, nome) => {
    dispatch({
        type: CARREGANOMEPERFIL,
        payload: nome
    });
}
const ativaDesativaLoadingBotaoAlterarCliente = (dispatch, value) => {
    dispatch({
        type: ATIVALOADINGBOTAOALTERARCLIENTE,
        payload: value
    });
};
const desativaLoadingBotaoAlterarCliente = dispatch => {
    dispatch({
        type: ATIVALOADINGBOTAOALTERARCLIENTE,
        payload: false
    });
};
const enviaEmailParaReducer = (dispatch, email) => {
    dispatch({
        type: CARREGAEMAILPERFIL,
        payload: email
    });

}
const enviaKeyUsuarioPerfil = (dispatch, key) => {
    dispatch({
        type: ENVIAKEYUSUARIOPERFIL,
        payload: key
    });
}
const enviaTochBackGroundMenuGaveta = (dispatch, array) => {
    dispatch({
        type: ENVIATOUCHBACKGROUNDMENUGAVETA,
        payload: array
    });
}
const enviaImagemPerfil = (dispatch, img) => {
    dispatch({
        type: CARREGAIMAGEMPERFIL,
        payload: img
    });
}
export const acessouMeuPErfil = (value) => {
    return {
        type: ACESSOUMEUPERFIL,
        payload: value
    }
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};