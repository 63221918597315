import axios from "axios";
import { getToken } from "./token";
import { getIdCompanyByLocalStorage } from "./common";
import jwt_decode from "jwt-decode";
const api = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
    },
    baseURL: 'https://us-central1-dark-4b307.cloudfunctions.net/'
});


api.interceptors.request.use(async (request) => {
    const token = await getToken();
    const idCompany = getIdCompanyByLocalStorage();
    //console.log('interceptor');
   // console.log('interceptor decoded',jwt_decode(token))
    //console.log('idCompany', idCompany)
    request.headers['Authorization'] = `Bearer ${token}`;
    request.headers['idCompany'] = idCompany;

    return request;
});

export default api;
