import {
    ALTERASWITCHNOTIFATENDABERTOPORMIM, ALTERASWITCHNOTIFATENDESTOUATENDENDO, ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
    ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO, ATIVADESATIVALOADINGTELANOTIFICACAO,
    ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO, VERIFICACONEXAOINTERNETNOTIFICAO,
    ZERAVERIFICASEALTEROUCOMSUCESSONOTIFICACAO, VERIFICASEALTEROUCOMSUCESSONOTIFICACAO
} from '../actions/types';


const INITIAL_STATE = {
    notifAtendAberto: false,
    notifAtendEstouAtendendo: false,
    notifRespAtendAbertoPorMim: false,
    notifRespAtendQueEstouAtendendo: false,
    loadingBotaoAlterarNotificacao: false,
    loadingNotificacao: false,
    semInternetNotificacao: false,
    loadingRefreshControlNotif: false,
    verificaSeAlterouNotifica: ''
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALTERASWITCHNOTIFATENDABERTOPORMIM:
            return { ...state, notifAtendAberto: action.payload };
        case ALTERASWITCHNOTIFATENDESTOUATENDENDO:
            return { ...state, notifAtendEstouAtendendo: action.payload };
        case ALTERASWITCHNOTIFRESPATENDABERTOPORMIM:
            return { ...state, notifRespAtendAbertoPorMim: action.payload };
        case ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO:
            return { ...state, notifRespAtendQueEstouAtendendo: action.payload };
        case ATIVADESATIVALOADINGTELANOTIFICACAO:
            return { ...state, loadingNotificacao: action.payload };
        case ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO:
            return { ...state, loadingBotaoAlterarNotificacao: action.payload };
        case VERIFICACONEXAOINTERNETNOTIFICAO:
            return { ...state, semInternetNotificacao: action.payload };
        case VERIFICASEALTEROUCOMSUCESSONOTIFICACAO:
            return { ...state, verificaSeAlterouNotifica: action.payload };
        case ZERAVERIFICASEALTEROUCOMSUCESSONOTIFICACAO:
            return { ...state, verificaSeAlterouNotifica: action.payload };

        default:
            return state;
    }
};