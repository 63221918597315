import styled from 'styled-components';
import { shade, opacify } from 'polished';

export const Container = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   flex-direction:row;
`;
export const SubContainer = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   flex-direction:column;
`;


export const ButtonMenu = styled.div`
   display:flex;
   flex:1;
   height:100%;
   justify-content:center;
   align-items:center;
`;

export const ButtonExpandeMenu = styled.button`
justify-content:center;
align-items:center;
display:flex;
margin-right:4px;
background:rgba(173, 173, 173, 0.2);
border-radius:4px;
border:0;
height:35px;
width: 35px;
min-width: 35px;
`;
export const BorderBottomMenu = styled.div`
display:flex;
border-bottom: 1px solid rgba(173, 173, 173, 0.2);
`;
export const HeaderPadrao = styled.div`
height:60px;
flex-direction:row;
display:flex;
justify-content:space-between;
padding-right:20px;
padding-left:20px;
align-items:center;
-webkit-box-shadow: 0px 0px 6px 1px #000;
-moz-box-shadow: 0px 0px 6px 1px #000;
box-shadow: 0px 0px 6px 1px #000;
position: relative;
background:${props => props.backgroundDinamico};
`;
export const TelaInteira = styled.div`
   display:flex;
   flex:1;
   justify-content:center;
align-items:center;
background:${props => props.backgroundDinamico};
`;
