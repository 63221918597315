import styled, { keyframes } from 'styled-components';
import { shade, opacify } from 'polished';
//background:radial-gradient(at 0% 30%, #223033 10px, #223033 30%, #29454D 50%);
//background: linear-gradient(90deg, #223033 0%, #29454D 35%, #223033 100%);
export const Container = styled.div`
   flex-direction:column;
   background-Color:${props => props.backgroundDinamico};
`;
export const SubContainer = styled.div`
 
`;
export const ContainerHeader = styled.div`
   display:flex;
   flex:1;
   height:100vh;
   flex-direction:column;
   justify-content:center;
   align-items:center;
`;


export const TextLogin = styled.p`
font-size:25px;
font-weight:bold;
margin-left:10px;
margin-top:12px;
color:${props => props.corLetraDinamico};
`;
export const TextEntre = styled.p`
font-size:15px;
margin-left:10px;
color:${props => props.corLetraDinamico};
`;


export const ContainerHeaderLogo = styled.div`
height:70px;
flex-direction:row;
display:flex;
justify-content:space-between;
padding-right:20px;
padding-left:20px;
align-items:center;
-webkit-box-shadow: 0px 0px 6px 1px #000000;
  -moz-box-shadow: 0px 0px 6px 1px #000000;
  box-shadow: 0px 0px 6px 1px #000000;
`;
export const DivCelular = styled.div`
flex-direction:row;
display:flex;
justify-content:center;
align-items:center;
`;
export const DivLogoSetaVoltar = styled.div`
flex-direction:row;
display:flex;
justify-content:center;
align-items:center;
`;
const animacaoLogin = keyframes`
from{
opacity:0;
transform:translateX(-50px);
}to{
   opacity:1;
transform:translateX(0);
}
`;
export const ContainerLogin = styled.form`
animation:${animacaoLogin} 1.2s;
width:350px;
flex-direction:column;
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:10px;
margin-top:30px;
-webkit-box-shadow: 0px 0px 6px 1px #000000;
  -moz-box-shadow: 0px 0px 6px 1px #000000;
  box-shadow: 0px 0px 6px 1px #000000;
`;
export const ContainerTextLogin = styled.div`
height:60px;
display:flex;
flex-direction:column;
justify-content:center;
`;

export const SubContainerInputEmail = styled.div`
height:40px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputEmail};
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:5px;
align-items:center;
`;

export const SubContainerInputSenha = styled.div`
height:40px;
margin:0 10px;
margin-top:18px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputSenha};
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:5px;
align-items:center;
`;
export const InputEmail = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:38px;
border:0;
color:${props => props.corLetraDinamico};
&::placeholder{
   color:#a8a8B3
}
`;
export const InputSenha = styled.input`
display:flex;
flex:1;
height:39px;
border:0;
color:${props => props.corLetraDinamico};
background-Color:transparent;
&::placeholder{
   color:#a8a8B3
}
`;
export const CheckManterConectado = styled.input`
border: 0;
margin: 0 0 0 10px;
height:20px;

`;

export const ContainerConectarAutomaticamente = styled.div`
display:flex;
margin: 10px 0 0 0;
height:20px;
flex-direction:row;
align-items:center;
`;

export const ContainerInputs = styled.div`
display:flex;
flex:1;
flex-direction:column;
`;

export const ContainerButton = styled.div`
display:flex;
flex:1;
justify-content:center;
align-items:center;
flex-direction:row;
`;

export const ButtonEsqueciSenha = styled.button`
display:flex;
height:45px;
flex:1;
justify-content:center;
align-items:center;
margin:10px;
background-color:#3F535A;
border:1px solid;
border-color:#00FF7F;
color:#00FF7F;
font-size:14px;
font-weight:bold;
border-radius: 5px;
transition: background-color 0.2s;
&:hover{
   background: ${shade(0.2, ('#3F535A'))};
}
`;
export const ButtonEntrar = styled.button`
display:flex;
width:70%;
justify-content:center;
align-items:center;
background-Color:${props => props.corLetraDinamico};
color:#000;
font-size:17px;
font-weight:bold;
border:0;
transition: background-color 0.2s;
height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background:${props => shade(0.2, props.corLetraDinamico)};
}

`;
export const MarcaDagua = styled.div`
   position: absolute;
   z-index: -100;
   display:flex;
   flex:1;
   height:100vh;
`;

export const ButtonCriarConta = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-color:#20B2AA;
color:#FFF;
font-size:15px;
font-weight:bold;
border:0;
transition: background-color 0.2s;
height:35px;
border-radius:  0 0 10px 10px;
&:hover{
   background: ${shade(0.2, '#20B2AA')};
}
`;

export const SubContainerInputEmpresa = styled.div`
height:40px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputEmail};
display:flex;
background-Color:${props => props.backgroundDinamico};;
border-radius:5px;
align-items:center;
`;
export const SubContainerInputNome = styled.div`
height:40px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputEmail};
display:flex;
background-Color:${props => props.backgroundDinamico};;
border-radius:5px;
align-items:center;
`;

export const SubContainerInputConfirma = styled.div`
height:40px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputSenha};
display:flex;
background-Color:${props => props.backgroundDinamico};;
border-radius:5px;
align-items:center;
`;






