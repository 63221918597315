import styled, { keyframes } from 'styled-components';
import { shade, opacify } from 'polished';
import logoMarcaDagua from '../../imagens/logo3.png'
export const Container = styled.div`
   flex-direction:column;
   display:flex;
   flex:1;
   height:100vh;
   background-Color:${props => props.backgroundDinamico};
 
`;
export const MarcaDagua = styled.div`
   position: absolute;
   z-index: -100;
   display:flex;
   flex:1;
   height:100vh;

`;

export const SubContainer = styled.div`
 flex:1;
 height:100vh;

`;
export const ContainerHeader = styled.div`
   display:flex;
   flex:1;
   flex-direction:column;
   height:100vh;
   align-items:center;
  
`;


export const TextLogin = styled.p`
font-size:25px;
font-weight:bold;
margin-left:10px;
margin-top:12px;
color:${props => props.corLetraDinamico};
`;
export const TextEntre = styled.p`
font-size:15px;
margin-left:10px;
color:${props => props.corLetraDinamico};
`;


export const ContainerHeaderLogo = styled.div`
height:70px;
flex-direction:row;
display:flex;
justify-content:space-between;
padding-right:20px;
padding-left:20px;
align-items:center;
-webkit-box-shadow: 0px 0px 6px 1px #000000;
  -moz-box-shadow: 0px 0px 6px 1px #000000;
  box-shadow: 0px 0px 6px 1px #000000;
`;
export const DivCelular = styled.div`
flex-direction:row;
display:flex;
justify-content:center;
align-items:center;
`;

const animacaoLogin = keyframes`
from{
opacity:0;
transform:translateX(-50px);
}to{
   opacity:1;
transform:translateX(0);
}
`;
export const ContainerLogin = styled.form`
animation:${animacaoLogin} 1.2s;
height:350px;
width:350px;
flex-direction:column;
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:10px;
-webkit-box-shadow: 0px 0px 6px 1px #000000;
  -moz-box-shadow: 0px 0px 6px 1px #000000;
  box-shadow: 0px 0px 6px 1px #000000;
`;
export const ContainerTextLogin = styled.div`
height:70px;
display:flex;
flex-direction:column;
justify-content:center;
`;

export const SubContainerInputEmail = styled.div`
height:45px;
margin: 0px 10px;
margin-top:20px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputEmail};
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:5px;
align-items:center;
`;

export const SubContainerInputSenha = styled.div`
height:45px;
margin:0 10px;
margin-top:18px;
padding: 0 10px;
border: 1px solid #fff;
border-color: ${props => props.corBordaInputSenha};
display:flex;
background-Color:${props => props.backgroundDinamico};
border-radius:5px;
align-items:center;
`;
export const InputEmail = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
border:0;
color:${props => props.corLetraDinamico};;
&::placeholder{
   color:#a8a8B3
}
`;
export const InputSenha = styled.input`
display:flex;
flex:1;
height:40px;
color:${props => props.corLetraDinamico};;
border:0;
background-Color:transparent;
&::placeholder{
   color:#a8a8B3
}
`;
export const CheckManterConectado = styled.input`
border: 0;
margin: 0 0 0 10px;
height:20px;

`;

export const ContainerConectarAutomaticamente = styled.div`
display:flex;
margin: 10px 0 0 0;
height:20px;
flex-direction:row;
align-items:center;
justify-content:'space-between';
`;

export const ContainerInputs = styled.div`
display:flex;
flex:1;
flex-direction:column;
`;

export const ContainerButton = styled.div`
display:flex;
flex:1;
justify-content:center;
align-items:center;
flex-direction:row;
`;

export const ButtonEsqueciSenha = styled.button`
display:flex;
flex:1;
justify-content:flex-end;
margin-right:10px;
color:#00BFFF;
font-size:12px;
font-weight:bold;
border:0;
text-decoration:underline;
transition: background-color 0.2s;
background:transparent;
&:hover{
   color: ${shade(0.2, '#00BFFF')};
}
`;
export const ButtonEntrar = styled.button`
display:flex;
justify-content:center;
align-items:center;
background-Color:${props => props.corLetraDinamico};
color:#000;
font-size:16px;
font-weight:bold;
width:70%;
border:0;
transition: background-color 0.2s;
height:40px;
margin:10px;
border-radius: 5px;
&:hover{
   background:${props => shade(0.2, props.corLetraDinamico)};
}

`;

export const ButtonCriarConta = styled.button`
display:flex;
width:100%;
justify-content:center;
align-items:center;
background-color:transparent;
color:${props => props.corLetraDinamico};
font-size:13px;
font-weight:bold;
flex-direction:row;
border:0;
transition: background-color 0.2s;
height:35px;
border-radius:  0 0 10px 10px;
&:hover{
   color: ${props => shade(0.2, props.corLetraDinamico)};
}
`;

export const TextCadastrar = styled.strong`
color:#00BFFF;
font-weight:bold;
margin-left:3px;
transition: background-color 0.2s;
&:hover{
   color: ${shade(0.2, '#00BFFF')};
}
`;


export const ContainerModalPrioridadeAtendimento = styled.div`
 display:flex;
   width:550px;
  height:280px;
  overflow:auto;
   flex-direction:column;
   border-radius:7px;
   background:${props => props.backgroundDinamico};
`;
export const ContainerIconeFecharModal = styled.div`
padding-top:5px;
padding-bottom:5px;
justify-content:space-between;
align-items:center;
display:flex;
border-radius: 7px 7px 0 0;
width:100%;
background:${props => props.backgroundDinamico};
`;
export const TextNovoAtendimento = styled.strong`
font-size:17px;
color:${props => props.corLetraDinamico};
margin-left:12px;
letter-spacing: 1.5px;
`;
export const ContainerAtendentes = styled.div`
display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
  flex-direction:column;
`;
export const DivInputMotivoCancelamento = styled.div`
 display:flex;
 flex:1;
 flex-direction:column;
justify-content:flex-start;
align-items:center;
`;
export const InputCancelamento = styled.input`
display:flex;
background-Color:transparent;
flex:1;
height:40px;
border:0;
width:350px;
color:#FFF;
&::placeholder{
   color:#a8a8B3
}
`;
export const ButtonCancelarAtendimento = styled.button`
display:flex;
flex-direction:row;
border:0;
margin:10px;
justify-content:space-around;
height:50px;
width:150px;
align-items:center;
border-radius:5px;
transition: background-color 0.2s;
background:${props => props.backgroundBotao};
&:hover{
    background:${props => shade(0.2, props.backgroundBotao)};
}
`;
export const TextBotaoCancelarAtendimento = styled.span`
  color:${props => props.colorDinamico};
  font-size:16px;
 font-weight:bold;
 flex:1;
 position: relative;
 margin-right:7px;
 text-align:center;
 align-items:center;
 justify-content:center;
`;

export const IconeFechar = styled.i`
font-size:15px;
margin-right:5px;
color:${props => props.backgroundDinamico};
cursor: pointer;
`;


