import React from 'react';
import firebase from './servicos/FirebaseConnection';
import { BrowserRouter, Switch, Route, Link, useLocation, Router, withRouter, Redirect } from 'react-router-dom';
import { ProSidebar, Menu as MenuSide, MenuItem as MenuItemSide, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import { MessageBox, MeetingMessage, SystemMessage, Popup, MessageList, LocationMessage, Input } from 'react-chat-elements'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import vCard from 'vcard-parser';
import { Offline, Online, Detector } from "react-detect-offline";
import FlatList from 'flatlist-react';
import Notifications from "react-notifications-menu";
import Dropdown from 'rc-dropdown';
import { toastr } from 'react-redux-toastr';
import ClipLoader from "react-spinners/ClipLoader";
import { AuthProvider } from "./servicos/AuthProtected";
import PrivateRoute from "./servicos/PrivateRoute";
import { Modal } from 'react-responsive-modal';
import Dropzone from 'react-dropzone';
import 'rc-dropdown/assets/index.css';
import { saveAs } from 'file-saver';
import './menu.css';
import SelectSearch from 'react-select-search';
import MicRecorder from 'mic-recorder-to-mp3';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import Linkify from 'react-linkify';
import { Picker } from 'emoji-mart';
import 'react-h5-audio-player/lib/styles.css';
import 'emoji-mart/css/emoji-mart.css';
import './chatelements.css';
//import 'react-pro-sidebar/dist/css/styles.css';

import Login from './components/Login/index';
import NotFound from './components/NotFound/index';
import Cadastro from './components/Cadastro/index';
import Atendimentos from './components/Atendimentos/index';
import ChamadoAberto from './components/ChamadoAberto/index';
import ChamadoAtendendo from './components/ChamadoAtendendo/index';
import ChamadoFechado from './components/ChamadoFechado/index';
import ChamadoCancelado from './components/ChamadoCancelado/index';
import ChamadoTodos from './components/ChamadoTodos/index';
import AcoesChamado from './components/AcoesChamado/index';
import AcoesChamadoSolicitacoesEmpresa from './components/AcoesChamadoSolicitacoesEmpresa/index';
import Clientes from './components/Clientes/index';
import Notificacoes from './components/Notificacoes/index';
import imgCheck from './imagens/imgCheck.png';
import moment from 'moment';


import Routes from './Routes';
import './App.css';
import history from './history';
import {
  Container, ButtonMenu, ButtonExpandeMenu, BorderBottomMenu, HeaderPadrao,
  TelaInteira, SubContainer, NomeRota, ContainerPerfil, DivNomeEmail, TextNome, TextEmail,
  ButtonImagemPerfil, ButtonNotificacao, IconeNotificacao, ButtonVariosNovos, IconeVariosNovos,
  TextBotaoNovo, IconeVariosNovosSeta, PageBody, DivMenuNovo, TextNovoMenu, TextFiltrarPor,
  DivMenuFiltro, DivIconeTextFiltro, TextMenuFiltro, DivMenuPerfil, TextPerfil,
  DivMenuFiltroClientes, DivMenuFiltroAvisos, TextMenuFiltroAvisos, TextFiltrarPorAvisos,
  DivMenuFiltroAtendimento, TextMenuFiltroAtendimento, DivMenuFiltroAtendimentoDetalhado,
  TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento, ButtonCancelarAtendimento,
  SubContainerNotif, TextMarcaLido, TextCabecalhoModalAcoes, TextNovoAtendimento,
  TextNotification, DivCountNotif, TextCountNotif,
  HeaderNotif, ProSidebarTeste, TextNovoMenuLink, ContainerNotif, TextLinkPerfil,
  BackgroundModal, ContainerMensagem, ContainerImagem, ContainerMensagemTexto,
  ContainerTitle, ContainerDataNotif, TitleNotif, SubTitleNotif, TextData, TextNew,
  ImageNotif,
  ContainerModalPrioridadeAtendimento, ContainerIconeFecharModal,
  IconeFechar, ContainerAtendentes, DivTextoReabrirAtendimento, DivInputMotivoCancelamento,
  ContainerAtendimentoOnlineExpandido, ContainerAtendimentoOnlineRetraido, CabecalhoChat, ContainerChat,
  InputDescricaoChamado, ContainerInputDescricaoChamado, IconeSetaPraBaixo, DivInputSearch, ContainerSelectSearch, SubContainerNovoAtendimento,
  InputSearch, ButtonEntrarChat, InputDescricaoMensagemChat, LinkDropDesk, IconeChat,
  TextTitulo, TextAvaliar, TextMensagem, ContainerClipBaixarArquivo, TextBaixarArquivo, ImagemComentario, ContainerChatAbertura,
  TextLoadingEnviandoARquivo, ButtonEncerrarChat, ButtonCancelarEncerrarChat, ContainerChatFinalizado,
  DivInputComentarChamado, InputDescricaoComentarioChat, DivAnimadaPosicaoFila, ButtonEntrarChatLink,
  ButtonAddNew, ContainerSemInternet,
  BackgroundSemInternet, ContainerSemInternetLaranja, ContainerModalGravarAudio, TextGravandoAudio,
  DivContainerMensagemChat, IconeMostrarSenha, DivTruncarTexto, TextResponder, ContainerMensagemChat,
  ContainerModalCompraSucesso
} from './AppStyled';

import userPadrao from './imagens/userPadrao.png';
import logoPadrao from './imagens/logodropdesk2.png';
import backGroundStopVersaoPremium from './imagens/backGroundStopVersaoPremium.jpg';

import imgNotifComent from './imagens/commentnotif.svg';
import imgNotifNovoChamado from './imagens/notifNovoChamado.svg';
import imgNotifAtribuido from './imagens/telemarketing.svg';
import { modificaNomeRotaNoHeader, modificaPesquisaTeste, modificaAberturaMenu } from '../src/actions/HeaderAction';

import {
  modificaVisibilidadeModalNovoAtendimento,
  modificaModalFiltroChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
  modificaValorArraySectionedFiltroChamado, modificaFiltroClientesTelaClientes, modificaVisibleModalNovoCLiente, listaSetorTelaNovoChamado, zeraInformacoesCadastroClienteExport,
  navegaTelaALterarChamadoNotificacao, listaProblemasEstaticosTelaNovoChamado,
  modificaNomeProblemaEstaticoSelecionado,
  modificaNomeSetorSelecionado, modificaSetorSelecionado, modificaProblemaEstaticoSelecionado,
  cadastraNovoAtendimento, alteraDescricaoChamado, alteraComentarioChat, salvarComentario2,
  uploadArquivoChat, atribuiStatusAtendimento, modificaComentarioAvaliacao, cadastraAvaliacaoChamado, modificaTelaAtualChatExport,
  modificaAtendimentoOnlineVisible, modificaVisibilidadeTelaChatOffline, ativaDesativaLoadingEnviandoAudioChatExport,
  ativaLoadingBotaoCadastrarChamadoExport, listaAcoesChamadoConversasChat, listaConversasAntigasResponder,
  enviaConversasAntigasChatExport, enviaReferenciaultimaKeyAcoesChamadoExport, deletaMensagemDropDesk
} from "../src/actions/ChamadoAction";
import {
  modificaFiltroPorDataDashboard, insereDataFiltroDashboard, modificaFiltroSelecionadoRelatorioDetalhado,
  modificaModalSair, enviaTotalNotificacoesParaReducerExport, marcarTodasNotifComoLido, modificaModalDuvidasFrequentes,
  ativaDesativaRenovarPLano, modificaMostraVersaoExpirando, modificaVisibilidadeAtendimentoChatOnlineOuOfflineExport
} from '../src/actions/DashBoardAction';
import { acessouMeuPErfil, sairDoApp } from '../src/actions/PerfilAction';

const cssToolTip = 'sobreporIndexToolTip';

const polling = {
  enabled: true,
  interval: 15000,
  timeout: 5000,
  //url: "https://ipv4.icanhazip.com/",
  // url: "https://api.ipify.org/?format=json",
};
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
const AsyncStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
};
class App extends React.Component {
  state = {
    menuOpen: JSON.parse(localStorage.getItem('menuOpenCliente')) == null ? false : JSON.parse(localStorage.getItem('menuOpenCliente')),
    cfgAudio: JSON.parse(localStorage.getItem('reproduzsomematendimentocliente')) == null ? true : JSON.parse(localStorage.getItem('reproduzsomematendimentocliente')),
    sairDoDropDesk: false,
    deslogado: false,
    modalNotif: false,
    conexaoInternetOffline: JSON.parse(localStorage.getItem('internetConnectionDropDeskCliente')) == null ? false : JSON.parse(localStorage.getItem('internetConnectionDropDeskCliente')),
    divEncerrarChat: false,
    isLoadingLogged: true,
    // atendimentoOnlineVisible: true,
    mostraVersaoExpirando: false,
    isSelectedRadioButton5estrelas: false,
    isSelectedRadioButton4estrelas: false,
    isSelectedRadioButton3estrelas: false,
    isSelectedRadioButton2estrelas: false,
    isSelectedRadioButton1estrelas: false,
    modalSairVisible: false,
    valorAvaliacao: 0,
    divEnviarAudioChat: false,
    viewEmoji: false,
    viewMicrophone: false,
    recordSecs: 0,
    bufferAudio: '',
    recordTime: '00:00:00',
    currentPositionSec: 0,
    currentDurationSec: 0,
    playTime: '0:00',
    duration: '00:00:00',
    statusAudio: 'gravar',
    currentDurationSecSlider: 0,
    nomeAudio: '',
    uriAudioFile: '',
    timer: null,
    colorTextComent: this.props.letraBotaoPadrao,
    colorTextComent2: this.props.coresLetraPadrao,
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    },
    blobAudioPuro: '',
    isRecording: false,
    blobURL: '',
    isBlocked: false,
    setandoMensagensLidas: false,
    scrollTop: 0,
    viewResponder: false,
    commentResponder: '',
    idResponder: '',
    circleColorResponder: '',
    commentResponderType: null,
    focusMessageBox: 'vazio',
    atendenteResponder: '',

  }
  componentWillMount() {
    /*   let keysToRemove = ["loginCliente", "chaveUnicaCliente", "tabindexCliente", "menuOpenCliente", "keyAtendimento", "numeroAtendimento", "backgroundPrimeiro", "corBotaoPadrao", "logoLogin", "backgroundSegundo", "coresLetraPadrao", "letraBotaoPadrao"];
     for (let i = 0; i < keysToRemove.length; i++) {
       localStorage.removeItem(keysToRemove[i]);
     } 
     firebase.auth().signOut(); */
    localStorage.setItem('internetConnectionDropDeskCliente', JSON.stringify(false));
  }

  componentDidMount() {
    //firebase.auth().signOut();
    // localStorage.clear()
    document.addEventListener("click", this.handleDocumentClick, false);
  }

  componentWillReceiveProps(nextProps) {
    ReactTooltip.rebuild();
    /*   console.log('NEXT PROPS APP CHAT') */

    if (nextProps.dadosAtendimentosChat.length > 0) {
      /*  console.log('DENTRO DO PROPS CHAT')
       console.log(nextProps.dadosAtendimentosChat[0]) */
      if (nextProps.dadosAtendimentosChat[0].status == 'Fechado' || nextProps.dadosAtendimentosChat[0].status == 'Cancelado') {
        /*     console.log('----------------TENHO QUE DESATIVAR OS OUVINTES-----------------------------')
            console.log(nextProps.dadosAtendimentosChat[0]); */
        let chaveUnica = nextProps.dadosAtendimentosChat[0].chaveUnica;
        let keyAtendimento = nextProps.dadosAtendimentosChat[0].key;
        /* console.log(chaveUnica)
        console.log(keyAtendimento) */
        setTimeout(() => {
          firebase.database().ref(`${chaveUnica}/acoesChamados/${keyAtendimento}`).off("value");
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByKey().equalTo(keyAtendimento).off("value")
          firebase.database().ref(`${chaveUnica}/chamados/`).orderByChild("status_atendente_abertoviachat").equalTo("AbertoNão informadotrue").off("value")
          /*  console.log('OUVINTES DESATIVADOS')
           console.log(nextProps.dadosAtendimentosChat[0].chaveUnica)
           console.log(nextProps.dadosAtendimentosChat[0].key) */
        }, 500);
      }
      if (this.props.dadosAtendimentosChat.length > 0 && nextProps.dadosAtendimentosChat.length > 0) {
        if (this.props.dadosAtendimentosChat[0].status == 'Aberto' && nextProps.dadosAtendimentosChat[0].status == 'Atendendo') {
          this.props.modificaTelaAtualChatExport('aguardandoAtendimento')
        }

      }
      /* if (nextProps.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat.length == 0) {
       if (nextProps.dadosAtendimentosChat[0].status == 'Aberto' && nextProps.telaAtualChat !== 'aguardandoAtendimento') {
         console.log('nexpropsss 2')
         this.props.modificaTelaAtualChatExport('aguardandoAtendimento')
       }
     } */

    }
    if (this.props.acoesDoAtendimentoChat !== nextProps.acoesDoAtendimentoChat) {
      if (this.state.setandoMensagensLidas === false && this.state.scrollTop === 0) {
        setTimeout(() => {
          this.setaMensagensLidas(nextProps.acoesDoAtendimentoChat);
        }, 1500);

      }

    }
    if (this.props.loadingCarregandoRespostasAntigas === true && nextProps.loadingCarregandoRespostasAntigas === false) {
      if (nextProps.idRespostaAntiga !== '') {
        console.log('gerando foco')
        console.log(nextProps.idRespostaAntiga)
        this.setState({ focusMessageBox: nextProps.idRespostaAntiga })
      }
    }
    /* 
    console.log(this.props.dataValidoAte)
    if (this.props.dataValidoAte !== nextProps.dataValidoAte) {
      let dataAtual = this.retornaDataAtual();
      console.log('antes do dia restante')
      console.log(nextProps.dataValidoAte)
      console.log(dataAtual)
      let diasRestantesBoleto = this.calculaDiasRestantesVersaoGratis(dataAtual, nextProps.dataValidoAte);
      console.log(diasRestantesBoleto);
      console.log(nextProps.statusAssinatura)
      if (diasRestantesBoleto <= 3 && nextProps.statusAssinatura == 'boleto') {
        this.props.modificaMostraVersaoExpirando(true)
        //this.setState({ mostraVersaoExpirando: true })
      } else {
        this.props.modificaMostraVersaoExpirando(false)
        //    this.setState({ mostraVersaoExpirando: false });
        this.props.ativaDesativaRenovarPLano(false);
      }
    } */
  }

  setaMensagensLidas(arrayMensagens) {
    if (arrayMensagens && arrayMensagens !== undefined && arrayMensagens.length > 0) {
      AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
        const chaveUnica = chaveUnica2.replace(/"/g, "");
        console.log(chaveUnica)
        let arrayKeysSetarLido = []
        console.log('setando mensagens lidas');
        console.log(arrayMensagens);
        for (let i = 0; i < arrayMensagens.length; i++) {
          if (arrayMensagens[i].circleColor === "#00FF7F" && arrayMensagens[i].statusMessage !== 'read') {
            arrayKeysSetarLido.push({ key: arrayMensagens[i].key })
          }
        }
        console.log(this.props.keyAtendimentoChat)
        if (arrayKeysSetarLido.length > 0) {
          firebase.database().ref(`${chaveUnica}/chamados/${this.props.keyAtendimentoChat}`).update({ lastmessageChatStatus: 'read' })
          const promises = arrayKeysSetarLido.map(async (usuario) => {
            this.setState({ setandoMensagensLidas: true })
            console.log(usuario)
            return firebase.database().ref(`${chaveUnica}/acoesChamados/${this.props.keyAtendimentoChat}/${usuario.key}/`).update({
              statusMessage: 'read'
            })
          })
          Promise.all(promises).then(responses => {
            console.log('mensagens lidas!!!')
            this.setState({ setandoMensagensLidas: false })
          })
        }
      })
    }
  }
  _toggleViewEmoji() {
    this.setState({ viewEmoji: !this.state.viewEmoji })
  }
  transformaMinutosEmSegundos(s) {

    function duas_casas(numero) {
      if (numero <= 9) {
        numero = "0" + numero;
      }
      return numero;
    }

    let hora = duas_casas(Math.trunc(s / 3600));
    let minuto = duas_casas(Math.trunc((s % 3600) / 60));
    let segundo = duas_casas((s % 3600) % 60);

    let formatado = hora + ":" + minuto + ":" + segundo;

    return formatado;
  }
  verificaSeChatEstaOnlineAntesDeCriarNovoChat() {
    console.log(this.props.segundaFeiraChat)
    console.log(this.props.tercaFeiraChat)
    console.log(this.props.quartaFeiraChat)
    console.log(this.props.quintaFeiraChat)
    console.log(this.props.sextaFeiraChat)
    console.log(this.props.sabadoChat)
    console.log(this.props.domingoChat)
    if (this.props.segundaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoSegundaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSegundaFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoSegundaFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoSegundaFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoSegundaFeira.start)
        console.log(this.props.horarioOperacaoSegundaFeira.end)
        let startTime = moment(this.props.horarioOperacaoSegundaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSegundaFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else if (this.props.tercaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoTercaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoTercaFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoTercaFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoTercaFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoTercaFeira.start)
        console.log(this.props.horarioOperacaoTercaFeira.end)
        let startTime = moment(this.props.horarioOperacaoTercaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoTercaFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else if (this.props.quartaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoQuartaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoQuartaFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoQuartaFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoQuartaFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoQuartaFeira.start)
        console.log(this.props.horarioOperacaoQuartaFeira.end)
        let startTime = moment(this.props.horarioOperacaoQuartaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoQuartaFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else if (this.props.quintaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoQuintaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoQuintaFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoQuintaFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoQuintaFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoQuintaFeira.start)
        console.log(this.props.horarioOperacaoQuintaFeira.end)
        let startTime = moment(this.props.horarioOperacaoQuintaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoQuintaFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else if (this.props.sextaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoSextaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSextaFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoSextaFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoSextaFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoSextaFeira.start)
        console.log(this.props.horarioOperacaoSextaFeira.end)
        let startTime = moment(this.props.horarioOperacaoSextaFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSextaFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else if (this.props.sabadoChat == true) {
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoSabadoFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSabadoFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoSabadoFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoSabadoFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoSabadoFeira.start)
        console.log(this.props.horarioOperacaoSabadoFeira.end)
        let startTime = moment(this.props.horarioOperacaoSabadoFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoSabadoFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    } else {
      //domingo chat
      if (this.props.switchUtilizar2HorariosChat == true) {
        //verifico se a hora de agora está entre os dois horarios
        console.log('dentro da verificacao 2 horarios App')
        let startTime = moment(this.props.horarioOperacaoDomingoFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoDomingoFeira.end, "HH:mm");
        let startTime2 = moment(this.props.horarioOperacaoDomingoFeira2.start, "HH:mm");
        let endTime2 = moment(this.props.horarioOperacaoDomingoFeira2.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)
        console.log(startTime2)
        console.log(endTime2)
        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        let chatDisponivel2 = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime2, endTime2);
        console.log(chatDisponivel)
        console.log(chatDisponivel2)
        if (chatDisponivel === true || chatDisponivel2 === true) {
          return true;
        } else {
          return false;
          /*    modificaVisibilidadeAtendimentoChatOnlineOuOffline(dispatch, 'offline'); */
        }
      } else {
        //verifico se a hora de agora está entro o primeiro horario
        /*  console.log('dentro da verificacao quinta feira') */
        console.log(this.props.horarioOperacaoDomingoFeira.start)
        console.log(this.props.horarioOperacaoDomingoFeira.end)
        let startTime = moment(this.props.horarioOperacaoDomingoFeira.start, "HH:mm");
        let endTime = moment(this.props.horarioOperacaoDomingoFeira.end, "HH:mm");
        console.log(startTime)
        console.log(endTime)

        let dataHoje = moment().toDate();
        let horarioAtualBaseadoFusoHorarioConfigurado = this.toTimeZone(dataHoje, this.props.fusoHorarioSelelcionado);
        console.log(this.props.fusoHorarioSelelcionado)
        console.log(horarioAtualBaseadoFusoHorarioConfigurado)
        let chatDisponivel = moment(horarioAtualBaseadoFusoHorarioConfigurado, 'HH:mm').isBetween(startTime, endTime);
        console.log(chatDisponivel)
        if (chatDisponivel === true) {
          return true
        } else {
          return false
        }
      }
    }

  }
  toTimeZone(time, zone) {
    let format = 'HH:mm';
    return moment(time, format).tz(zone).format(format);
  }
  handleDocumentClick = event => {
    let isEmojiClassFound = false;
    let isAudioClassFound = false;

    event &&
      event.path &&
      event.path.forEach(elem => {
        if (elem && elem.classList) {
          const data = elem.classList.value;
          if (data.includes("emoji")) {
            isEmojiClassFound = true;
          }
          if (data == 'divGravarAudio2') {
            isAudioClassFound = true;
          }
        }
      }); // end
    if (isEmojiClassFound === false && event.target.id !== "emojis-btn")
      this.setState({ viewEmoji: false })
    if (isAudioClassFound === false && event.target.id !== "divGravarAudioChat") {
      /*  this._toggleModalGravarAudio() */
      this.handleRest()
      this.setState({ divEnviarAudioChat: false });
    }
  };
  renderTextGravacaoAudio() {
    if (this.state.statusAudio == 'gravar') {
      return (
        <p style={{
          color: this.props.coresLetraPadrao,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 18,
          fontFamily: 'Source Sans Pro'
        }}>Pressione no Microphone para Gravar</p>
      )
    } else if (this.state.statusAudio == 'gravando') {
      return (
        <TextGravandoAudio corDinamico={this.props.coresLetraPadrao} >GRAVANDO</TextGravandoAudio>
      )
    } else {
      //play audio
      return (
        <p style={{
          color: this.props.coresLetraPadrao,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: 18,
          fontFamily: 'Source Sans Pro'
        }}>Gravação</p>
      )
    }

  }
  _toggleModalGravarAudio() {
    this.handleRest()
    this.setState({ divEnviarAudioChat: !this.state.divEnviarAudioChat });
  }
  millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: null,
        m: null,
        s: null,
      }
    }
    this.setState({ audioDetails: reset });
  }

  renderBotaoGravarAudio() {

    if (this.state.statusAudio == 'gravar') {
      return (
        <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
          <div style={{
            display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
          }} onClick={() => this.onStartRecord()}>
            <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
          </div>
        </div>
      )
    } else if (this.state.statusAudio == 'gravando') {

      return (
        <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

          <div style={{
            display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
            cursor: 'pointer'
          }} onClick={() => {
            /*     setTimeout(() => {
                    this.setState({
                        recordSecs: 0,
                        statusAudio: 'gravacao'
                    });
                }, 100);
                AudioManager.stopRecord(); */


            this.onStopRecord(false);

          }}>
            <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
          </div>
        </div>
      )
    } else {
      //play audio

      return (
        <div style={{ height: 280, display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <audio src={this.state.blobURL} controls />
          </div>
          <div style={{ display: 'flex', height: 55, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

              this.onStopRecord(true)
            }}>
              {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                        /> */}
              {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
              <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebase() }}>
              {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
              <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
            </div>
          </div>
          {/* <TouchableOpacity
                    style={styles.viewBarWrapper}
                    onPress={(e) => { this.onStatusPress(e) }}
                >
                    <View style={styles.viewBar}>
                        <View style={[styles.viewBarPlay, { width: playWidth }]} />
                    </View>
                </TouchableOpacity>*/}


        </div >
      )
    }

  }
  onStartRecord = () => {
    /*  navigator.getUserMedia({ audio: true },
       () => {
         console.log('Permissão concedida');
         this.setState({ isBlocked: false });
         if (this.state.isBlocked) {
           console.log('Permission Denied');
         } else { */
    Mp3Recorder
      .start()
      .then((e) => {
        console.log(e)
        this.setState({ isRecording: true, statusAudio: 'gravando' });
        let timer = setInterval(this.tick, 1000);
        this.setState({ timer });
      }).catch((e) => console.error(e));
    /*  }
   },
   () => {
     console.log('Permissão negada ');
     this.setState({ isBlocked: true })
   },
 ); */
  }
  onStopRecord = (apenasLimpar) => {
    if (apenasLimpar == true) {
      if (this.state.isRecording) {
        Mp3Recorder.stop()
          .getMp3()
          .then(([buffer, blob]) => {
            clearInterval(this.state.timer);
            this.setState({
              blobURL: '',
              isRecording: false,
              recordSecs: 0,
              blobAudioPuro: '',
              statusAudio: 'gravar',
              timer: null,
              bufferAudio: ''
            });

          }).catch((e) => console.log(e));
      } else {
        clearInterval(this.state.timer);
        this.setState({
          blobURL: '',
          isRecording: false,
          recordSecs: 0,
          blobAudioPuro: '',
          statusAudio: 'gravar',
          timer: null,
          bufferAudio: ''
        });
      }

    } else {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {

          console.log(buffer)
          console.log(blob)
          const blobURL = URL.createObjectURL(blob)
          console.log(blobURL)
          clearInterval(this.state.timer);
          this.setState({
            blobURL,
            isRecording: false,
            //recordSecs: 0,
            blobAudioPuro: blob,
            statusAudio: 'gravacao',
            timer: null,
            bufferAudio: buffer
          });

          /* const file = new File(buffer, `audioweb${new Date().getTime()}.mp3`, {
              type: blob.type,
              lastModified: Date.now()
          });

          const player = new Audio(URL.createObjectURL(file));
          player.controls = true; */
          //  player.play();

        }).catch((e) => console.log(e));
    }
  }
  tick = () => {
    this.setState({
      recordSecs: this.state.recordSecs + 1
    });
  }
  milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
  getFileBlob = (url, cb) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function () {
      cb(xhr.response);
    });
    xhr.send();
  };
  handleAudioStop(data) {
    console.log(data)
    this.setState({ audioDetails: data });
  }
  enviarAudioFirebase() {
    this._toggleModalGravarAudio();

    console.log(this.state.bufferAudio)
    console.log(this.state.blobAudioPuro)
    let duracao = (this.state.recordSecs * 1000);
    let duracaoNan = Number.isNaN(duracao)
    if (duracaoNan == true) {
      duracao = 1000
    }
    const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.mp3`, {
      type: this.state.blobAudioPuro.type,
      lastModified: Date.now()
    });
    file.audio = true;
    file.duracao = duracao;
    console.log(file)
    let res = [file]
    console.log(res)

    if (res.length > 0) {

      if (this.props.premium == 0) {
        toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
      } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
        toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
      }
      else {
        this.props.ativaDesativaLoadingEnviandoAudioChatExport(true)
        this.props.salvarComentario2('tem arquivo sim', false, '', res, 1, this.props.keyAtendimentoChat, this.props.dadosAtendimentosChat[0], this.props.dadosAtendimentosChat[0].dataAbertura,
          this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamadoChat, this.props.dadosAtendimentosChat[0].keyAtendente,
          this.props.dadosAtendimentosChat[0], this.props.licensawebouapp, false, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
          this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
        this.zeraMensagemResponder();
        this.InputChat.focus()
        this.onStopRecord(true)
      }

    } else {
      toastr.error('Error ao Enviar Audio', 'Tente novamente');
    }
    //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
    //  let duracao = duracaoMilisegundos;

  }
  addEmoji = (event, emojiObject) => {

    this.props.alteraComentarioChat(this.props.comentarioChat + event.native)
  };

  calculaDiasRestantesVersaoGratis(date1, date2) {
    //formato do brasil 'pt-br'
    // moment.locale('pt-br');
    //setando data1
    let data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    let data2 = moment(date2, 'DD/MM/YYYY');

    //tirando a diferenca da data2 - data1 em dias
    // let diff = data2.diff(data1, 'days');
    let diff = Math.abs(data2.diff(data1, 'days'));

    return diff;
  }
  _toggleDrawerMenu() {
    localStorage.setItem('menuOpenCliente', JSON.stringify(!this.state.menuOpen));
    let state = this.state;
    state.menuOpen = !this.state.menuOpen;
    this.setState({ state })

  }

  _toggleSairDropDesk() {
    let state = this.state;
    state.sairDoDropDesk = !this.state.sairDoDropDesk;
    this.setState({ state });

  }
  renderImagemNotif(item) {
    /* console.log('no render imagem notif')
    console.log(item) */
    if (item.title.includes('Novo atendimento')) {
      return (
        <img src={imgNotifNovoChamado} alt="notif" style={{ height: 30, width: 30, borderRadius: '50%', background: '#CCC' }} />
      )
    } else if (item.title.includes('Atendimento atribuido à você')) {
      return (
        <img src={imgNotifAtribuido} alt="notif" style={{ height: 30, width: 30, borderRadius: '50%', background: '#CCC' }} />
      )
    } else {
      return (
        <ImageNotif src={imgNotifComent} alt="notif" />
      )
    }
  }
  renderTextTotalNotif() {
    if (this.props.totalNotificacoes <= 0) {
      return null
    } else if (this.props.totalNotificacoes > 9) {
      return (
        <DivCountNotif>
          <TextCountNotif>
            9
          </TextCountNotif>
          +
        </DivCountNotif>

      )
    } else {
      return (
        <DivCountNotif>
          <TextCountNotif>
            {this.props.totalNotificacoes}
          </TextCountNotif>
        </DivCountNotif>

      )
    }
  }
  _toggleModalNotif() {
    /* console.log('no toggle modal notif') */
    let state = this.state;
    state.modalNotif = !this.state.modalNotif;
    this.setState({ state });

  }

  insereAvaliacao(valor) {
    /*  console.log(valor); */
    if (valor == 5) {
      let state = this.state;
      state.isSelectedRadioButton5estrelas = true;
      state.isSelectedRadioButton4estrelas = false;
      state.isSelectedRadioButton3estrelas = false;
      state.isSelectedRadioButton2estrelas = false;
      state.isSelectedRadioButton1estrelas = false;
      state.obrigatorioComentarioAvaliacao = false;


      state.valorAvaliacao = 5;
      this.setState(state);
      /*  console.log(this.state.valorAvaliacao)
       console.log(this.state.isSelectedRadioButton5estrelas);
       console.log(this.state.isSelectedRadioButton4estrelas)
       console.log(this.state.isSelectedRadioButton3estrelas)
       console.log(this.state.isSelectedRadioButton2estrelas)
       console.log(this.state.isSelectedRadioButton1estrelas) */

    } else if (valor == 4) {
      let state = this.state;
      state.isSelectedRadioButton5estrelas = false;
      state.isSelectedRadioButton4estrelas = true;
      state.isSelectedRadioButton3estrelas = false;
      state.isSelectedRadioButton2estrelas = false;
      state.isSelectedRadioButton1estrelas = false;
      state.obrigatorioComentarioAvaliacao = false;
      state.valorAvaliacao = 4;
      this.setState(state);
      /*       console.log(this.state.valorAvaliacao)
            console.log(this.state.isSelectedRadioButton5estrelas);
            console.log(this.state.isSelectedRadioButton4estrelas)
            console.log(this.state.isSelectedRadioButton3estrelas)
            console.log(this.state.isSelectedRadioButton2estrelas)
            console.log(this.state.isSelectedRadioButton1estrelas) */
    }
    else if (valor == 3) {
      let state = this.state;
      state.isSelectedRadioButton5estrelas = false;
      state.isSelectedRadioButton4estrelas = false;
      state.isSelectedRadioButton3estrelas = true;
      state.isSelectedRadioButton2estrelas = false;
      state.isSelectedRadioButton1estrelas = false;
      state.obrigatorioComentarioAvaliacao = false;
      state.valorAvaliacao = 3;
      this.setState(state);
      /*   console.log(this.state.valorAvaliacao)
        console.log(this.state.isSelectedRadioButton5estrelas);
        console.log(this.state.isSelectedRadioButton4estrelas)
        console.log(this.state.isSelectedRadioButton3estrelas)
        console.log(this.state.isSelectedRadioButton2estrelas)
        console.log(this.state.isSelectedRadioButton1estrelas) */
    }

    else if (valor == 2) {
      let state = this.state;
      state.isSelectedRadioButton5estrelas = false;
      state.isSelectedRadioButton4estrelas = false;
      state.isSelectedRadioButton3estrelas = false;
      state.isSelectedRadioButton2estrelas = true;
      state.isSelectedRadioButton1estrelas = false;
      state.obrigatorioComentarioAvaliacao = true;
      state.valorAvaliacao = 2;
      this.setState(state);
      /*      console.log(this.state.valorAvaliacao)
           console.log(this.state.isSelectedRadioButton5estrelas);
           console.log(this.state.isSelectedRadioButton4estrelas)
           console.log(this.state.isSelectedRadioButton3estrelas)
           console.log(this.state.isSelectedRadioButton2estrelas)
           console.log(this.state.isSelectedRadioButton1estrelas) */
    } else {
      //valor 1
      let state = this.state;
      state.isSelectedRadioButton5estrelas = false;
      state.isSelectedRadioButton4estrelas = false;
      state.isSelectedRadioButton3estrelas = false;
      state.isSelectedRadioButton2estrelas = false;
      state.isSelectedRadioButton1estrelas = true;
      state.obrigatorioComentarioAvaliacao = true;
      state.valorAvaliacao = 1;
      this.setState(state);
      /*  console.log(this.state.valorAvaliacao)
       console.log(this.state.isSelectedRadioButton5estrelas);
       console.log(this.state.isSelectedRadioButton4estrelas)
       console.log(this.state.isSelectedRadioButton3estrelas)
       console.log(this.state.isSelectedRadioButton2estrelas)
       console.log(this.state.isSelectedRadioButton1estrelas) */
    }
  }
  onSelect({ key }) {
  }

  onVisibleChange(visible) {
  }
  retornaDataAtual() {
    moment.locale('pt-br')
    let dataAtual = moment().toDate();
    dataAtual = moment(dataAtual).format('DD/MM/YYYY');
    return dataAtual;
  }
  renderMenuCliente() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarCliente === true) {
      return (
        <MenuItemSide active={this.props.nomeRota == 'Clientes' ? true : false} title='Clientes' onClick={() => { this.props.modificaNomeRotaNoHeader('Clientes') }} icon={<i className="icon-man-user" style={{ color: '#FFF', fontSize: 18 }} />}>Clientes <Link to="/clientes" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Clientes' ? true : false} title='Clientes' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-man-user" style={{ color: '#FFF', fontSize: 18 }} />}>Clientes </MenuItemSide>
      )
    }
  }
  renderMenuAtendente() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAtendente === true) {
      return (
        <MenuItemSide active={this.props.nomeRota == 'Atendentes' ? true : false} title='Atendentes' onClick={() => { this.props.modificaNomeRotaNoHeader('Atendentes') }} icon={<i className="icon-atendimentos" style={{ color: '#FFF', fontSize: 18 }} />}>Atendentes <Link to="/atendentes" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Atendentes' ? true : false} title='Atendentes' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-atendimentos" style={{ color: '#FFF', fontSize: 18 }} />}>Atendentes</MenuItemSide>
      )
    }
  }
  renderMenuSetores() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarSetores === true) {
      return (
        <MenuItemSide active={this.props.nomeRota == 'Setores' ? true : false} title='Setores' onClick={() => { this.props.modificaNomeRotaNoHeader('Setores') }} icon={<i className="icon-icon" style={{ color: '#FFF', fontSize: 18 }} />}>Setores <Link to="/setores" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Setores' ? true : false} title='Setores' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-icon" style={{ color: '#FFF', fontSize: 18 }} />}>Setores</MenuItemSide>
      )
    }
  }
  scrollCheck = event => {
    /* console.log(event)
    console.log(event.target.scrollHeight)
    console.log(event.target.scrollTop)
    console.log(event.target.clientHeight);

    console.log(this.props.loadingCarregandoMaisConversasChat)
    console.log(this.props.referenciaUltimaKeyChatApp)
    console.log(this.props.dadosAtendimentosChat.length) */
    let arrayMensagens = this.props.acoesDoAtendimentoChat;
    let arrayKeysSetarLido = [];
    this.setState({ scrollTop: event.target.scrollTop })
    let scrollInTop = event.target.scrollHeight + event.target.scrollTop;
    //  console.log(scrollInTop)
    if (event.target.scrollTop === 0) {
      this.props.enviaConversasAntigasChatExport([])
      this.props.enviaReferenciaultimaKeyAcoesChamadoExport('');
      console.log("At The Bottom"); //Add in what you want here
      for (let i = 0; i < arrayMensagens.length; i++) {
        if (arrayMensagens[i].circleColor === "#00FF7F" && arrayMensagens[i].statusMessage !== 'read') {
          arrayKeysSetarLido.push(arrayMensagens[i])
        }
      }
      if (arrayKeysSetarLido.length > 0) {
        console.log('seta mensagens como lidas');
        console.log(arrayKeysSetarLido)
        this.setaMensagensLidas(arrayKeysSetarLido);
        this.setState({ scrollTop: 0 })
      } else {
        console.log('não há mensagem para marcar como lida');
        this.setState({ scrollTop: 0 })
      }

    }
    scrollInTop = (scrollInTop - 20)
    if (scrollInTop <= event.target.clientHeight && this.props.loadingCarregandoMaisConversasChat === false && this.props.referenciaUltimaKeyChatApp !== undefined && this.props.dadosAtendimentosChat.length > 0) {
      console.log('scroll no top')
      console.log('carregar mais conversas');
      let arrayConversas = [...this.props.acoesDoAtendimentoChat]
      // arrayConversas = arrayConversas.reverse();
      console.log(arrayConversas);
      //  console.log(arrayConversas[arrayConversas.length - 1]);
      let ultimaKey = this.props.referenciaUltimaKeyChatApp === '' ? arrayConversas[arrayConversas.length - 1].key : this.props.referenciaUltimaKeyChatApp;
      console.log(this.props.dadosAtendimentosChat[0].key);
      console.log(this.props.dadosAtendimentosChat[0].chaveUnica);
      console.log(this.props.dadosAtendimentosChat[0])
      this.props.listaAcoesChamadoConversasChat(1, this.props.dadosAtendimentosChat[0].chaveUnica, this.props.dadosAtendimentosChat[0].key, ultimaKey, this.props.arrayConversasAntigasChat);
    }

  };
  renderMenuAvisos() {
    if (this.props.premium == 0) {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
      )

    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
      )

    } else {
      if (this.props.permissoesAcessarTelasAtendente.permissaoAlterarCadastrarAvisos === true) {
        return (
          <MenuItemSide active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { this.props.modificaNomeRotaNoHeader('Mural de Avisos') }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos <Link to="/avisos" /></MenuItemSide>
        )
      } else {
        return (
          <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Mural de Avisos' ? true : false} title='Mural de Avisos' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-megaphone" style={{ color: '#FFF', fontSize: 18 }} />}>Mural de Avisos </MenuItemSide>
        )
      }
    }
  }
  renderMenuRelatorios() {
    if (this.props.premium == 0) {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
      )

    } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
      )

    } else {
      if (this.props.permissoesAcessarTelasAtendente.permissaoAcessoRelatorios === true) {
        return (
          <SubMenu onClick={() => { }} title='Relatórios' icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>
            <SubMenu title='Atendimento' icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>
              <MenuItemSide active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Atendimentos') }}>Atendimentos<Link to="/relatorioatendimentodetalhado" /></MenuItemSide>
              <MenuItemSide active={this.props.nomeRota == 'Relatório Atendimentos Geral' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Atendimentos Geral') }}>Visão Geral<Link to="/relatorioatendimentogeral" /></MenuItemSide>
            </SubMenu>
            <SubMenu title='Avaliação' icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>
              <MenuItemSide active={this.props.nomeRota == 'Relatório Registros Avaliações' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Registros Avaliações') }}>Avaliações<Link to="/relatorioregistroavaliacao" /></MenuItemSide>
              <MenuItemSide active={this.props.nomeRota == 'Relatório Avaliação Geral' ? true : false} onClick={() => { this.props.modificaNomeRotaNoHeader('Relatório Avaliação Geral') }}>Visão Geral<Link to="/relatorioavaliacaogeral" /></MenuItemSide>
            </SubMenu>

          </SubMenu>
        )
      } else {
        return (
          <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Relatório Atendimentos' ? true : false} title='Relatórios' onClick={() => { toastr.error('Acesso Negado', 'Você não tem permissão para acessar esta tela'); }} icon={<i className="icon-bar-chart2" style={{ color: '#FFF', fontSize: 18 }} />}>Relatórios </MenuItemSide>
        )
      }
    }
  }
  renderMenuConfiguracoes() {
    if (this.props.permissoesAcessarTelasAtendente.permissaoAdministrador === true) {
      return (
        <MenuItemSide active={this.props.nomeRota == 'Configurações' ? true : false} title='Configurações' onClick={() => { this.props.modificaNomeRotaNoHeader('Configurações') }} icon={<i className="icon-settings-gears" style={{ color: '#FFF', fontSize: 18 }} />}>Configurações <Link to="/configuracao" /></MenuItemSide>
      )
    } else {
      return (
        <MenuItemSide suffix={<i className="icon-senha" style={{ color: 'red', fontSize: 15 }} />} active={this.props.nomeRota == 'Configurações' ? true : false} title='Configurações' onClick={() => { toastr.error('Acesso Negado', 'Somente ADMINISTRADOR tem permissão para acessar esta tela'); }} icon={<i className="icon-settings-gears" style={{ color: '#FFF', fontSize: 18 }} />}>Configurações</MenuItemSide>
      )
    }
  }
  renderImagemPerfil() {
    if (this.props.foto == null) {
      return (
        <div style={{ height: 45, width: 45, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
      )
    } else {
      return (
        <ButtonImagemPerfil type="button">
          <img src={this.props.foto} alt="Imagem Perfil" style={{ height: 45, width: 45, borderRadius: '50%' }} />
        </ButtonImagemPerfil>
      )
    }
  }

  renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
    if (isDragAccept == true) {
      return '#00FF7F';
    } else {
      return 'red';
    }
  }
  escolheArquivoChamado(res) {
    /*    console.log(res) */
    if (this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
      toastr.error('Error ao Anexar', 'Chat Cancelado');
    } else if (this.props.dadosAtendimentosChat[0].status == 'Fechado') {
      toastr.error('Error ao Anexar', 'Chat Fechado');
    } else if (this.props.textArquivoChat !== '') {
      toastr.error('Error ao Anexar', 'Tem arquivo sendo upado neste momento, por favor aguarde.');
    } else {
      if (res.length > 0) {
        /*  console.log(res) */
        /*  for (let i = 0; i < res.length; i++) { */

        /*  console.log(this.props.licensawebouapp) */
        if (this.props.premium == 0) {
          toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
        } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
          toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
        }
        else {

          /* console.log('tudo ok 3') */
          this.props.uploadArquivoChat('tem arquivo sim', false,
            '', res, true, this.props.keyAtendimentoChat, this.props.dadosAtendimentosChat[0], this.props.dadosAtendimentosChat[0].dataAbertura, this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamadoChat, this.props.dadosAtendimentosChat[0].keyAtendente, this.props.dadosAtendimentosChat[0], this.props.licensawebouapp, true,
            this.state.viewResponder, this.state.idResponder, this.state.commentResponder, this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
          this.zeraMensagemResponder()
        }
        /*  } */
      } else {
        toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
      }
    }
  }
  baixarArquivo(nomeArquivo, arquivo) {
    console.log(arquivo)
    console.log(nomeArquivo)
    /*  AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
         const chaveUnica = chaveUnica2.replace(/"/g, ""); */
    this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.2, downloadFile: true, clickFileDownload: true })
    /* firebase.storage().ref().child(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/${this.props.atendimentosPorKeyReducerChat.key}/${this.props.atendimentosPorKeyReducerChat.key}/${nomeArquivo}`).getDownloadURL().then((url) => {
       firebase.storage().ref().child(`${this.props.atendimentosPorKeyReducerChat.chaveUnica}/${this.props.atendimentosPorKeyReducerChat.key}/${this.props.atendimentosPorKeyReducerChat.key}/${nomeArquivo}`).getMetadata().then((metadata) => {
           // console.log(metadata);
           this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true }) */
    this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.5, downloadFile: true, clickFileDownload: true })
    this.getFileBlob(arquivo.urlDownload, (blob) => {

      this.setState({ nomeFileDownload: nomeArquivo, loadingFileDownload: 0.9, downloadFile: true, clickFileDownload: true })
      console.log(blob)
      let file = new File([blob], nomeArquivo, { type: blob.type });
      saveAs(file)
      this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
    })
    /*   saveAs(url, metadata.contentType); */
    /*  var link = document.createElement("a");
    if (link.download !== undefined) {
        //usar quando for abrir imagem
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } */

    /* }).catch(function (error) {
        console.log(error)
        this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
        // console.log(error);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {

            case 'storage/object-not-found':
                // File doesn't exist
                break;

            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
        }
    });
}).catch(function (error) {
    console.log(error)
    this.setState({ nomeFileDownload: '', loadingFileDownload: 0, downloadFile: false, clickFileDownload: false })
    // console.log(error);
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {

        case 'storage/object-not-found':
            // File doesn't exist
            break;

        case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

        case 'storage/canceled':
            // User canceled the upload
            break;

        case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
    }
}); */
    /*  }) */
  }
  renderRemoveButton(item) {
    if (this.props.dadosAtendimentos[0].channelChamado.includes('Chat') || this.props.dadosAtendimentos[0].status == 'Fechado' || this.props.dadosAtendimentos[0].status == 'Cancelado' || item.deleteMessage === true || item.circleColor === "#39a3f4") {
      return false
    } else {
      return true
    }
  }
  /* baixarArquivo(nomeArquivo) {
   
    firebase.storage().ref().child(`${this.props.dadosAtendimentosChat[0].chaveUnica}/${this.props.keyAtendimentoChat}/${this.props.keyAtendimentoChat}/${nomeArquivo}`).getDownloadURL().then((url) => {
      firebase.storage().ref().child(`${this.props.dadosAtendimentosChat[0].chaveUnica}/${this.props.keyAtendimentoChat}/${this.props.keyAtendimentoChat}/${nomeArquivo}`).getMetadata().then((metadata) => {
       
        this.getFileBlob(url, (blob) => {
          console.log(blob)
          let file = new File([blob], metadata.name, { type: blob.type });
          saveAs(file)
        })
       

      }).catch(function (error) {
      
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {

          case 'storage/object-not-found':
            // File doesn't exist
            break;

          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;

          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
    }).catch(function (error) {
  
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {

        case 'storage/object-not-found':
          // File doesn't exist
          break;

        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;

        case 'storage/canceled':
          // User canceled the upload
          break;

        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          break;
      }
    });
   
  } */
  renderTitleSemAtendimentoCriadoPor(title, item) {
    if (title.includes('Atendimento criado por')) {
      let titleSemString = title.replace('Atendimento criado por: ', '');
      return (
        <TextTitulo corLetraDinamica={item.circleColor}>{titleSemString}</TextTitulo>
      )
    } else {
      return (
        <TextTitulo corLetraDinamica={item.circleColor}>{title}</TextTitulo>
      )

    }
  }
  renderDescriptionTimeline(item) {
    console.log('dentro do description avaliar atend')
    console.log(item)

    if (item.description.includes('Conversa Iniciada por ')) {
      return null
    } else {
      return (
        <>
          {/*    {this.renderTitleSemAtendimentoCriadoPor(item.title, item)} */}
          <MessageBox

            {...item}

            /*   ref={scrollTo} */
            id={item.key}
            reply={this.renderMensagemReply(item)}
            onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
            onReplyClick={() => { this.renderResponderMensagem(item) }}
            //onClick={() => { console.log('clicou onClick') }}
            //onOpen={() => { console.log('clicou onOpen') }}
            position={item.circleColor === '#39a3f4' ? "right" : "left"}
            type={'text'}
            title={item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
            onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
            text={
              (
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  {item.description}
                </Linkify>
              )
            }
            copiableDate
            dateString={item.time}
            replyButton={item.statusMessage === 'waiting' ? false : true}
            status={item.circleColor === '#39a3f4' ? null : item.statusMessage}
            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
            removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
          />
        </>
      )

    }
  }
  renderTimeLineItem(item) {
    if (item.tipo == 'mensagem') {
      if (item.title.includes('Atendimento criado por')) {
        if (item.circleColor.includes('39a3f4')) {
          return (
            <div key={item.key}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} >
                < TextMensagem corLetraDinamica='#808080' >
                  Chat Iniciado
              </TextMensagem>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} >
                <div
                  style={{
                    background: '#d9edf7',
                    padding: 10,
                    // borderRadius: 8,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 1,
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 5,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  {this.renderDescriptionTimeline(item)}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>

            </div>
          )
        } else {
          return (
            <div key={item.key}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                < TextMensagem corLetraDinamica='#808080' >
                  Chat Iniciado
              </TextMensagem>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }}>
                <div style={{
                  background: '#E6E9F2',
                  padding: 10,
                  //borderRadius: 8,
                  borderTopLeftRadius: 1,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 20,
                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  {this.renderDescriptionTimeline(item)}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>

            </div>
          )
        }
      } else if (item.description.includes(`${item.title} entrou no Chat`)) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
            < TextMensagem corLetraDinamica='#808080' >
              {item.description}
            </TextMensagem>
          </div>
        )
      }
      else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
        let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
        return (
          <div key={item.key}>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }}>
              < TextMensagem corLetraDinamica='#808080' >
                {item.description}
              </TextMensagem>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
              < TextMensagem corLetraDinamica='#808080' >
                {atendendoAtribuido} entrou no Chat
              </TextMensagem>
            </div>
          </div>
        )
      } else if (item.description.includes(`${item.title} encerrou o chat.`)) {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
            < TextMensagem corLetraDinamica='#808080' >
              {item.description}
            </TextMensagem>
          </div>
        )
      } else if (item.description.includes(`Inicio do Atendimento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#39a3f4', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem style={{ fontSize: 14 }} corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div>
        )
      }
      else if (item.description.includes(`Fim do Atendimento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem style={{ fontSize: 14 }} corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div >
        )
      } else if (item.description.includes(`Conversa em Andamento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem style={{ fontSize: 14 }} corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div >
        )
      }

      else {
        if (item.circleColor.includes('39a3f4')) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
              <div
                style={{
                  background: '#d9edf7',
                  padding: 10,
                  // borderRadius: 8,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 1,
                  borderBottomRightRadius: 20,
                  borderBottomLeftRadius: 5,
                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {this.renderDescriptionTimeline(item)}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
              <div style={{
                background: '#E6E9F2',
                padding: 10,
                //borderRadius: 8,
                borderTopLeftRadius: 1,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 5,
                borderBottomLeftRadius: 20,
                boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                margin: 10,
                display: 'block',
                flexDirection: 'column'
              }}>
                {this.renderDescriptionTimeline(item)}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                </div>
              </div>
            </div>
          )
        }
      }

    } else {
      /* console.log(item) */
      //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
      if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
        if (item.tipoArquivo.includes('image')) {
          if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
            //tenho que buscar a url de download no firebase
            /* console.log('é uma imagem SEM URL');
            console.log(item.urlDownload); */
            if (item.circleColor.includes('39a3f4')) {

              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
                  <div
                    style={{
                      background: '#d9edf7',
                      padding: 10,
                      // borderRadius: 8,
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 1,
                      borderBottomRightRadius: 20,
                      borderBottomLeftRadius: 5,
                      boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                      margin: 10,
                      display: 'flex',
                      flexDirection: 'column'
                    }} >
                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                      <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                      <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                    </ContainerClipBaixarArquivo>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                      <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
                  <div style={{
                    background: '#E6E9F2',
                    padding: 10,
                    //borderRadius: 8,
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 20,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                      <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                      <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                    </ContainerClipBaixarArquivo>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                      <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                    </div>
                  </div>
                </div>
              )
            }
          } else {
            //tenho a URL salva, somente renderizar
            /*   console.log('é uma imagem COM URL');
              console.log(item.urlDownload); */
            if (item.circleColor.includes('39a3f4')) {
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
                  <div
                    style={{
                      background: '#d9edf7',
                      padding: 10,
                      //borderRadius: 8,
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 1,
                      borderBottomRightRadius: 20,
                      borderBottomLeftRadius: 5,
                      boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                      margin: 10,
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    key={item.key}
                  >
                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                    <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                      <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                      <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                    </ContainerClipBaixarArquivo>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                      <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
                  <div
                    style={{
                      background: '#E6E9F2',
                      padding: 10,
                      //borderRadius: 8,
                      borderTopLeftRadius: 1,
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 5,
                      borderBottomLeftRadius: 20,
                      boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                      margin: 10,
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    key={item.key}
                  >
                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                    <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                      <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                      <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                    </ContainerClipBaixarArquivo>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                      <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                    </div>
                  </div>
                </div>
              )
            }

          }

        } else if (item.audio == true) {
          if (item.circleColor.includes('39a3f4')) {

            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
                <div
                  style={{
                    background: '#d9edf7',
                    padding: 10,
                    // borderRadius: 8,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 1,
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 5,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>

                    <AudioPlayer
                      src={item.urlDownload}
                      preload='auto'
                      showSkipControls={false}
                      showJumpControls={false}
                      layout={'horizontal-reverse'}
                      defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                      customControlsSection={
                        [
                          RHAP_UI.MAIN_CONTROLS,
                        ]
                      }
                      style={{
                        width: '280px',
                        borderRadius: 10,
                        background: '#F0f0f7'
                      }}
                    // other props here
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
                <div
                  style={{
                    background: '#E6E9F2',
                    padding: 10,
                    //borderRadius: 8,
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 20,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>

                    <AudioPlayer
                      src={item.urlDownload}
                      preload='auto'
                      showSkipControls={false}
                      showJumpControls={false}
                      layout={'horizontal-reverse'}
                      defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                      customControlsSection={
                        [
                          RHAP_UI.MAIN_CONTROLS,
                        ]
                      }
                      style={{
                        width: '280px',
                        borderRadius: 10,
                        background: '#F0f0f7'
                      }}
                    // other props here
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>
            )
          }
        } else {
          /*   console.log('NÃO é uma imagem'); */
          if (item.circleColor.includes('39a3f4')) {

            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
                <div
                  style={{
                    background: '#d9edf7',
                    padding: 10,
                    // borderRadius: 8,
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 1,
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 5,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                  <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                  </ContainerClipBaixarArquivo>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
                <div
                  style={{
                    background: '#E6E9F2',
                    padding: 10,
                    //borderRadius: 8,
                    borderTopLeftRadius: 1,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 5,
                    borderBottomLeftRadius: 20,
                    boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                    margin: 10,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                  <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                  </ContainerClipBaixarArquivo>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                    <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                  </div>
                </div>
              </div>
            )
          }
        }
      } else {
        if (item.circleColor.includes('39a3f4')) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key}>
              <div
                style={{
                  background: '#d9edf7',
                  padding: 10,
                  //borderRadius: 8,
                  borderTopLeftRadius: 20,
                  borderTopRightRadius: 1,
                  borderBottomRightRadius: 20,
                  borderBottomLeftRadius: 5,
                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                  <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                  <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                </ContainerClipBaixarArquivo>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key}>
              <div
                style={{
                  background: '#E6E9F2',
                  padding: 10,
                  //  borderRadius: 8,
                  borderTopLeftRadius: 1,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 20,
                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                  margin: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                  <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                  <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                </ContainerClipBaixarArquivo>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                </div>
              </div>
            </div>
          )
        }
      }
    }
  }
  millisToMinutesAndSeconds(millis) {
    let minutes = Math.floor(millis / 60000);
    let seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  renderTextArquivosMensagemRespondidas(item) {
    if (item.commentResponderType === 'imagem') {
      return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</p>
    } else if (item.commentResponderType === 'video') {
      return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Vídeo</p>
    } else if (item.commentResponderType === 'location') {
      return <p><i className="icon-localization" style={{ fontSize: 13, color: '#000' }} /> Localização</p>
    } else if (item.commentResponderType === 'contato') {
      return <p><i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} /> Contato</p>
    } else if (item.commentResponderType === 'audio') {
      return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</p>
    } else if (item.commentResponderType === 'arquivo') {
      return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</p>
    }
    else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image')) {
      return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</p>
    } else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true) {
      return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Vídeo</p>
    }
    else if (item.commentResponderType === 'link' && item.commentResponderIsAudio === true) {
      return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</p>
    }
    else if (item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio === false) {
      return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</p>
    }
    else {
      return <p>{item.mensagemResposta}</p>
    }
  }
  renderMensagemReply(item) {
    if (item.possuiResposta && this.props.dadosAtendimentosChat.length > 0) {
      /*     { this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} /> }
          { this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} /> }

          { this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} /> }
          { this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} /> }
          { this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} /> }
          { this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} /> }
          { this.state.commentResponder } */
      return {
        title: item.circleColorResponder === '#00FF7F' ? this.props.dadosAtendimentosChat[0].atendente : this.props.nome,
        titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
        message:
          this.renderTextArquivosMensagemRespondidas(item)

         /*    (<p>
                {item.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} />}
                {item.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} />}

                {item.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 13, color: '#000' }} />}
                {item.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} />}
                {item.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} />}
                {item.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} />}
                {item.mensagemResposta}

                 {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && <><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</>}
                {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true && <> < i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Video</>}
                {item.commentResponderType === 'link' && item.commentResponderIsAudio === true && <> <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</>}
                {item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && !item.commentResponderIsAudio === false && !item.commentResponderIsAudio === true && <><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</>}

                {item.commentResponderType !== 'link' && <>{item.mensagemResposta}</>} 
            </p>) */,


      }
    } else {
      return null
    }


  }
  renderResponderMensagem(item) {
    //onReplyClick={() => { this.renderResponderMensagem(item) }}
    this.InputChat.focus();
    if (item.tipo === 'mensagem') {
      this.setState({ commentResponderType: 'texto', commentResponder: item.description, idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
    } else if (item.tipo === 'location') {
      this.setState({ commentResponderType: 'location', commentResponder: 'Localização', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
    } else if (item.tipo === 'vcard') {
      this.setState({ commentResponderType: 'contato', commentResponder: 'Contato', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
    } else {
      if (item.tipoArquivo.includes('image')) {
        this.setState({ commentResponderType: 'imagem', commentResponder: 'Imagem', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
      } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
        this.setState({ commentResponderType: 'video', commentResponder: 'Vídeo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
      } else if (item.audio == true) {
        this.setState({ commentResponderType: 'audio', commentResponder: 'Mensagem de Voz', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
      } else {
        this.setState({ commentResponderType: 'arquivo', commentResponder: 'Arquivo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
      }
    }
  }
  renderClickButtonReply(item, ref) {
    console.log(item);

    let msgMesmaConversa = this.props.acoesDoAtendimentoChat.filter(e => e.idMensagem === item.idMensagemResponder).length > 0;
    console.log(msgMesmaConversa)
    if (msgMesmaConversa === true) {
      console.log('mensagem na mesma conversa');
      console.log(item.idMensagemResponder)
      this.setState({ focusMessageBox: item.idMensagemResponder })
    } else {
      let mensagemJaCarregada = false
      for (let i = 0; i < this.props.arrayConversasAntigasChat.length; i++) {
        console.log(this.props.arrayConversasAntigasChat[i])
        if (this.props.arrayConversasAntigasChat[i].idMensagem === item.idMensagemResponder) {
          mensagemJaCarregada = true;
          break;
        }
      }
      console.log(mensagemJaCarregada)
      if (mensagemJaCarregada === true) {
        this.setState({ focusMessageBox: item.idMensagemResponder })
      } else {
        console.log('carregar mensagens do chamado antigo');
        let arrayConversas = [...this.props.acoesDoAtendimentoChat]
        let ultimaKey = arrayConversas[arrayConversas.length - 1].key;
        this.props.listaConversasAntigasResponder(this.props.dadosAtendimentosChat[0].key, item.idMensagemResponder, [], 'emAtendimento', ultimaKey, item.idMensagemResponder)
      }
    }
    // this.setState({ focusMessageBox: item.idMensagemResponder })
  }
  downloadImage(url, name) {
    let link = document.createElement("a");
    //usar quando for abrir imagem
    link.setAttribute("href", url);
    link.setAttribute("target", "_blank");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    /*  const a = document.createElement('a');
     a.href = url;
     a.download = name;
     a.click();
     a.target = "_blank";
     
     window.URL.revokeObjectURL(url); */
    /*  const a = document.createElement('a');
     a.href = url;
     a.download = name;
     a.click();
     a.target = "_blank";
     window.open(url); */
  };
  messageBox(item) {

    if (item.deleteMessage === true) {
      return (
        <div key={item.key + item.alterado}>


          <MessageBox
            {...item}
            id={item.key}
            reply={this.renderMensagemReply(item)}
            /*   ref={ref => { this.refsArray[item.key] = ref }} */
            onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
            onReplyClick={() => { this.renderResponderMensagem(item) }}
            //onClick={() => { console.log('clicou onClick') }}
            //onOpen={() => { console.log('clicou onOpen') }}
            position={item.circleColor === '#39a3f4' ? "right" : "left"}
            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
            onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
            type={'text'}
            title={item.title}
            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
            text={
              (
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <i className="icon-block" style={{ fontSize: 14, color: '#808080' }} />
                  <span style={{ color: '#808080', fontSize: 14, fontStyle: 'italic' }}>Mensagem Apagada</span>
                </Linkify>
              )
            }
            copiableDate
            dateString={item.time}
            status={null} //(waiting, sent, received, read)
            forwarded={false}
            replyButton={false}
            removeButton={false}

          />

        </div>
      )
    } else if (item.tipo == 'mensagem') {
      if (item.title.includes('Atendimento criado por')) {
        return (
          <div key={item.key}>
            <SystemMessage
              text={`Conversa Iniciada`} />

            {this.renderDescriptionTimeline(item)}
          </div>
        )

      } else if (item.title == 'Avaliar Este Atendimento') {
        return (
          <div
            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column', marginTop: 10, marginBottom: 10, marginRight: 10 }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', background: '#D5F0FB', borderRadius: 10, padding: 7 }} >
              <TextMensagem corLetraDinamica='#000'>
                {item.description}
              </TextMensagem>
              <TextAvaliar corPrincipalChat={this.props.corPrincipalChat} onClick={() => {
                this.props.modificaTelaAtualChatExport('chatFinalizado')
              }} corLetraDinamica={this.props.coresLetraPadrao}>{item.title}</TextAvaliar>

            </div>
          </div >
        )


      }

      else if (item.description.includes(`${item.title} entrou no Chat`)) {
        return (
          <div key={item.key}>
            <SystemMessage
              text={`${item.title} entrou na Conversa`} />
            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                      < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                          {item.description}
                      </TextMensagem>
                  </div> */}
          </div>
        )
      }
      else if (item.description.includes(`${item.title} Cancelou este atendimento`)) {
        return (
          <div key={item.key}>
            <SystemMessage
              text={`${item.description}`} />
            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                      < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                          {item.description}
                      </TextMensagem>
                  </div> */}
          </div>
        )
      }
      else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
        let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
        return (
          <div key={item.key}>
            <SystemMessage
              text={`${atendendoAtribuido} entrou na Conversa`} />
            <SystemMessage
              text={`${item.description}`} />

            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                        < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                            {atendendoAtribuido} entrou no Chat
                  </TextMensagem>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }}>
                        < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                            {item.description}
                        </TextMensagem>
                    </div> */}

          </div>
        )
      } else if (item.description.includes(`${item.title} encerrou o chat.`)) {
        return (
          <div key={item.key}>
            <SystemMessage
              text={`${item.title} encerrou a Conversa.`} />
            {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                      < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                          {item.description}
                      </TextMensagem>
            </div> */}
          </div>
        )
      } else if (item.description.includes(`Inicio do Atendimento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#39a3f4', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div>
        )
      }
      else if (item.description.includes(`Fim do Atendimento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div >
        )
      } else if (item.description.includes(`Conversa em Andamento: #`)) {
        return (
          <div key={item.key}>
            <div style={{
              display: 'flex', height: 20, flex: 1, backgroundColor: '#FFC04D', borderRadius: 10, alignItems: 'center', justifyContent: 'center', textAlign: 'center',
              margin: 15
            }}>
              {/*  <SystemMessage
                    text={item.description} /> */}
              {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}> */}
              < TextMensagem corLetraDinamica={'#000'} >
                {item.description}
              </TextMensagem>
            </div>
            {/*  </div> */}
          </div >
        )
      }
      else {

        return (
          <div key={item.key + item.alterado}>

            <MessageBox
              {...item}
              id={item.key}
              reply={this.renderMensagemReply(item)}
              /*   ref={ref => { this.refsArray[item.key] = ref }} */
              onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
              onReplyClick={() => { this.renderResponderMensagem(item) }}
              //onClick={() => { console.log('clicou onClick') }}
              //onOpen={() => { console.log('clicou onOpen') }}
              position={item.circleColor === '#39a3f4' ? "right" : "left"}
              focus={item.idMensagem === this.state.focusMessageBox ? true : false}
              onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
              type={'text'}
              title={item.title}
              titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
              text={
                (
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {item.description}
                  </Linkify>
                )
              }
              copiableDate
              dateString={item.time}
              replyButton={item.statusMessage === 'waiting' ? false : true}
              removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
              onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
              status={null} //(waiting, sent, received, read)
            // focus
            //  data={{
            //   uri: 'https://facebook.github.io/react/img/logo.svg',
            //    status: {
            //        click: false,
            //        loading: 0,
            //    }
            // }} 
            />
            {/*  <MessageList
                        className='message-list'
                        lockable={true}
                        toBottomHeight={'100%'}
                        dataSource={[
                            {
                                position: 'right',
                                type: 'text',
                                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
                                date: new Date(),
                            },
                        ]} /> */}
            {/*  <MeetingMessage
                        subject={'New Release'}
                        title={'in ullamco'}
                        date={new Date()}
                        collapseTitle={'Commodo aliquip'}
                        participants={[
                            {
                                id: '1',
                                title: 'Facebook',
                            },

                        ]}
                        dataSource={[
                            {
                                id: '1',
                                avatar: 'https://facebook.github.io/react/img/logo.svg',
                                message: 'Lorem ipsum dolor sit amet.',
                                title: 'Elit magna',
                                avatarFlexible: true,
                                date: new Date(),
                                event: [{
                                    title: 'Toplantı sona erdi!',
                                    avatars:[{
                                        src: 'https://facebook.github.io/react/img/logo.svg'
                                    }]
                                }],
                                record: [{
                                    avatar: 'https://facebook.github.io/react/img/logo.svg',
                                    title: 'Arama',
                                    savedBy: 'Kaydeden: Elit magna',
                                    time: new Date(),
                                }]
                            },

                        ]} /> */}
            {/* <Popup
                        show={this.state.show}
                        header='Lorem ipsum dolor sit amet.'
                        headerButtons={[{
                            type: 'transparent',
                            color: 'black',
                            text: 'close',
                            onClick: () => {
                                this.setState({ show: false })
                            }
                        }]}
                        text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem animi veniam voluptas eius!'
                        footerButtons={[{
                            color: 'white',
                            backgroundColor: '#ff5e3e',
                            text: "Vazgeç",
                        }, {
                            color: 'white',
                            backgroundColor: 'lightgreen',
                            text: "Tamam",
                        }]} /> */}


          </div>
          /*  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
               
               <div
                   style={{
                       background: '#d9edf7',
                       padding: 10,
                       // borderRadius: 8,
                       borderTopLeftRadius: 20,
                       borderTopRightRadius: 1,
                       borderBottomRightRadius: 20,
                       borderBottomLeftRadius: 5,
                       boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                       margin: 10,
                       display: 'flex',
                       flexDirection: 'column'
                   }}
               >
                   {this.renderDescriptionTimeline(item)}
                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                       <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                   </div>
               </div>
           </div > */
        )

      }
    } else {
      //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
      if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
        if (item.tipoArquivo.includes('image')) {
          if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
            //tenho que buscar a url de download no firebase
            //  console.log('é uma imagem SEM URL');
            //  console.log(item.urlDownload);
            return (
              <div key={item.key + item.alterado}>
                <MessageBox
                  {...item}
                  id={item.key}
                  reply={this.renderMensagemReply(item)}
                  data={{
                    uri: item.urlDownload,
                    status: {
                      click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                      loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                      download: this.state.downloadFile
                    },
                    //size: "100MB",
                  }}
                  focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                  onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                  onDownload={event => this.baixarArquivo(item.description, item)}
                  onReplyClick={() => { this.renderResponderMensagem(item) }}
                  onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                  //onClick={() => { console.log('clicou onClick') }}
                  //onOpen={() => { console.log('clicou onOpen') }}
                  position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                  type={'file'}
                  title={item.title}
                  titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                  text={item.description}
                  copiableDate
                  dateString={item.time}

                  replyButton={item.statusMessage === 'waiting' ? false : true}
                  removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                  onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                  status={null} //(waiting, sent, received, read)

                />
              </div>
            )
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                <div
                                    style={{
                                        background: '#d9edf7',
                                        padding: 10,
                                        // borderRadius: 8,
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 1,
                                        borderBottomRightRadius: 20,
                                        borderBottomLeftRadius: 5,
                                        boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                        margin: 10,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }} >
                                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                        <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                        <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                    </ContainerClipBaixarArquivo>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                        <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                    </div>
                                </div>
                            </div> */}


          } else {
            //tenho a URL salva, somente renderizar
            // console.log('é uma imagem COM URL');
            // console.log(item.urlDownload);

            return (
              <div key={item.key + item.alterado}>
                <MessageBox
                  {...item}
                  id={item.key}
                  reply={this.renderMensagemReply(item)}

                  data={{
                    uri: item.urlDownload,
                    status: {
                      click: true,
                      // loading: 1,
                      download: true
                    },
                    width: 300,
                    height: 300,
                  }}
                  //onDownload={event => this.baixarArquivo(item.description, item)}
                  onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                  onReplyClick={() => { this.renderResponderMensagem(item) }}

                  /*     onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);   }} */
                  onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                  position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                  type={'photo'}
                  title={item.title}
                  titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                  text={''
                    /* 
                    (
                        <div>
                            <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                            <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo>
                        </div>
                    ) */
                  }
                  focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                  onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                  copiableDate
                  dateString={item.time}

                  replyButton={item.statusMessage === 'waiting' ? false : true}
                  removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                  onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                  status={null} //(waiting, sent, received, read)

                />
              </div>
            )
            /*    if (item.circleColor.includes('00FF7F')) {
                   return (
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                           <div
                               style={{
                                   background: '#d9edf7',
                                   padding: 10,
                                   //  borderRadius: 8,
                                   borderTopLeftRadius: 20,
                                   borderTopRightRadius: 1,
                                   borderBottomRightRadius: 20,
                                   borderBottomLeftRadius: 5,
                                   boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                   margin: 10,
                                   display: 'flex',
                                   flexDirection: 'column'
                               }}
                               key={item.key + item.alterado}
                           >
                               <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                               <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                               <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                   <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                   <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                               </ContainerClipBaixarArquivo>
                               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                   <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                               </div>
                           </div>
                       </div>
                   )
               } else {
                   return (
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                           <div
                               style={{
                                   background: '#E6E9F2',
                                   padding: 10,
                                   //borderRadius: 8,
                                   borderTopLeftRadius: 1,
                                   borderTopRightRadius: 20,
                                   borderBottomRightRadius: 5,
                                   borderBottomLeftRadius: 20,
                                   boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                   margin: 10,
                                   display: 'flex',
                                   flexDirection: 'column'
                               }}
                               key={item.key + item.alterado}
                           >
                               <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                               <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                               <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                   <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                   <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                               </ContainerClipBaixarArquivo>
                               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                   <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                               </div>
                           </div>
                       </div>
                   )
               } */

          }

        } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
          if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
            //tenho que buscar a url de download no firebase
            //  console.log('é uma imagem SEM URL');
            //  console.log(item.urlDownload);
            return (
              <div key={item.key + item.alterado}>
                <MessageBox
                  {...item}
                  id={item.key}
                  reply={this.renderMensagemReply(item)}
                  data={{
                    uri: item.urlDownload,
                    status: {
                      click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                      loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                      download: this.state.downloadFile
                    },
                    //size: "100MB",
                  }}
                  focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                  onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                  onDownload={event => this.baixarArquivo(item.description, item)}
                  onReplyClick={() => { this.renderResponderMensagem(item) }}
                  onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                  //onClick={() => { console.log('clicou onClick') }}
                  //onOpen={() => { console.log('clicou onOpen') }}
                  position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                  type={'file'}
                  title={item.title}
                  titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                  text={item.description}
                  copiableDate
                  dateString={item.time}

                  replyButton={item.statusMessage === 'waiting' ? false : true}
                  removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                  onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                  status={null}//(waiting, sent, received, read)

                />
              </div>
            )
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                                <div
                                    style={{
                                        background: '#d9edf7',
                                        padding: 10,
                                        // borderRadius: 8,
                                        borderTopLeftRadius: 20,
                                        borderTopRightRadius: 1,
                                        borderBottomRightRadius: 20,
                                        borderBottomLeftRadius: 5,
                                        boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                        margin: 10,
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }} >
                                    <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                                    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                        <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                        <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                    </ContainerClipBaixarArquivo>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                        <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                                    </div>
                                </div>
                            </div> */}


          } else {
            //tenho a URL salva, somente renderizar
            // console.log('é uma imagem COM URL');
            // console.log(item.urlDownload);

            return (
              <div key={item.key + item.alterado}>
                <MessageBox
                  {...item}
                  id={item.key}
                  reply={this.renderMensagemReply(item)}

                  data={{
                    videoURL: item.urlDownload,
                    status: {
                      click: true,
                      // loading: 1,
                      download: true
                    },
                    width: 300,
                    height: 300,
                  }}
                  focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                  onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                  //onDownload={event => this.baixarArquivo(item.description, item)}
                  onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                  onReplyClick={() => { this.renderResponderMensagem(item) }}

                  /* onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);  }} */
                  onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                  position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                  type={'video'}
                  title={item.title}
                  titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                  text={''
                    /* 
                    (
                        <div>
                            <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                            <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo>
                        </div>
                    ) */
                  }
                  copiableDate
                  dateString={item.time}

                  replyButton={item.statusMessage === 'waiting' ? false : true}
                  removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                  onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                  status={null}//(waiting, sent, received, read)

                />
              </div>
            )
            /*    if (item.circleColor.includes('00FF7F')) {
                   return (
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                           <div
                               style={{
                                   background: '#d9edf7',
                                   padding: 10,
                                   //  borderRadius: 8,
                                   borderTopLeftRadius: 20,
                                   borderTopRightRadius: 1,
                                   borderBottomRightRadius: 20,
                                   borderBottomLeftRadius: 5,
                                   boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                   margin: 10,
                                   display: 'flex',
                                   flexDirection: 'column'
                               }}
                               key={item.key + item.alterado}
                           >
                               <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                               <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                               <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                   <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                   <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                               </ContainerClipBaixarArquivo>
                               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                   <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                               </div>
                           </div>
                       </div>
                   )
               } else {
                   return (
                       <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                           <div
                               style={{
                                   background: '#E6E9F2',
                                   padding: 10,
                                   //borderRadius: 8,
                                   borderTopLeftRadius: 1,
                                   borderTopRightRadius: 20,
                                   borderBottomRightRadius: 5,
                                   borderBottomLeftRadius: 20,
                                   boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                   margin: 10,
                                   display: 'flex',
                                   flexDirection: 'column'
                               }}
                               key={item.key + item.alterado}
                           >
                               <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                               <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                               <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                   <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                   <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                               </ContainerClipBaixarArquivo>
                               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                   <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                               </div>
                           </div>
                       </div>
                   )
               } */

          }

        }
        else if (item.audio == true) {
          return (
            <div key={item.key + item.alterado}>
              <MessageBox
                {...item}
                id={item.key}
                reply={this.renderMensagemReply(item)}
                /*  data={{
                     uri: item.urlDownload,
                     status: {
                         click: false,
                         loading: 0,
                     }
                 }} */
                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                onReplyClick={() => { this.renderResponderMensagem(item) }}

                //onClick={() => { console.log('clicou onClick') }}
                //onOpen={() => { console.log('clicou onOpen') }}
                position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                type={'text'}
                title={item.title}
                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                text={
                  (
                    <AudioPlayer
                      src={item.urlDownload}
                      preload='auto'
                      showSkipControls={false}
                      showJumpControls={false}
                      layout={'horizontal-reverse'}
                      defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                      customControlsSection={
                        [
                          RHAP_UI.MAIN_CONTROLS,
                        ]
                      }
                      style={{
                        width: '300px',
                        borderRadius: 10,
                        background: '#E9E9E9'
                      }}
                    // other props here
                    />
                  )
                }
                copiableDate
                dateString={item.time}

                replyButton={item.statusMessage === 'waiting' ? false : true}
                removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                status={null} //(waiting, sent, received, read)

              />
            </div>
          )
          /*     if (item.circleColor.includes('00FF7F')) {

                  return (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                          <div
                              style={{
                                  background: '#d9edf7',
                                  padding: 10,
                                  // borderRadius: 8,
                                  borderTopLeftRadius: 20,
                                  borderTopRightRadius: 1,
                                  borderBottomRightRadius: 20,
                                  borderBottomLeftRadius: 5,
                                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                  margin: 10,
                                  display: 'flex',
                                  flexDirection: 'column'
                              }}
                          >
                              <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>

                                  <AudioPlayer
                                      src={item.urlDownload}
                                      preload='auto'
                                      showSkipControls={false}
                                      showJumpControls={false}
                                      layout={'horizontal-reverse'}
                                      defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                      customControlsSection={
                                          [
                                              RHAP_UI.MAIN_CONTROLS,
                                          ]
                                      }
                                      style={{
                                          width: '300px',
                                          borderRadius: 10,
                                          background: '#F0f0f7'
                                      }}
                                  // other props here
                                  />
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                              </div>
                          </div>
                      </div>
                  )
              } else {
                  return (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                          <div
                              style={{
                                  background: '#E6E9F2',
                                  padding: 10,
                                  // borderRadius: 8,
                                  borderTopLeftRadius: 1,
                                  borderTopRightRadius: 20,
                                  borderBottomRightRadius: 5,
                                  borderBottomLeftRadius: 20,
                                  boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                                  margin: 10,
                                  display: 'flex',
                                  flexDirection: 'column'
                              }}
                          >
                              <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>

                                  <AudioPlayer
                                      src={item.urlDownload}
                                      preload='auto'
                                      showSkipControls={false}
                                      showJumpControls={false}
                                      layout={'horizontal-reverse'}
                                      defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                      customControlsSection={
                                          [
                                              RHAP_UI.MAIN_CONTROLS,
                                          ]
                                      }
                                      style={{
                                          width: '300px',
                                          borderRadius: 10,
                                          background: '#F0f0f7'
                                      }}
                                  // other props here
                                  />
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                                  <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                              </div>
                          </div>
                      </div>
                  )
              } */
        } else {
          // console.log('NÃO é uma imagem');
          return (
            <div key={item.key + item.alterado}>
              <MessageBox
                {...item}
                id={item.key}
                reply={this.renderMensagemReply(item)}
                data={{
                  uri: item.urlDownload,
                  status: {
                    click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                    loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                    download: this.state.downloadFile
                  },
                  //size: "100MB",
                }}
                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                onDownload={event => this.baixarArquivo(item.description, item)}
                onReplyClick={() => { this.renderResponderMensagem(item) }}
                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                //onClick={() => { console.log('clicou onClick') }}
                //onOpen={() => { console.log('clicou onOpen') }}
                position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                type={'file'}
                title={item.title}
                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                text={item.description}
                copiableDate
                dateString={item.time}

                replyButton={item.statusMessage === 'waiting' ? false : true}
                removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                status={null} //(waiting, sent, received, read)

              />
            </div>
          )
          /*  if (item.circleColor.includes('00FF7F')) {

               return (
                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                       <div
                           style={{
                               background: '#d9edf7',
                               padding: 10,
                               // borderRadius: 8,
                               borderTopLeftRadius: 20,
                               borderTopRightRadius: 1,
                               borderBottomRightRadius: 20,
                               borderBottomLeftRadius: 5,
                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                               margin: 10,
                               display: 'flex',
                               flexDirection: 'column'
                           }}
                       >
                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                           </ContainerClipBaixarArquivo>
                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                           </div>
                       </div>
                   </div>
               )
           } else {
               return (
                   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                       <div
                           style={{
                               background: '#E6E9F2',
                               padding: 10,
                               // borderRadius: 8,
                               borderTopLeftRadius: 1,
                               borderTopRightRadius: 20,
                               borderBottomRightRadius: 5,
                               borderBottomLeftRadius: 20,
                               boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                               margin: 10,
                               display: 'flex',
                               flexDirection: 'column'
                           }}
                       >
                           <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                           <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                               <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                               <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                           </ContainerClipBaixarArquivo>
                           <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                               <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                           </div>
                       </div>
                   </div>
               )
           } */
        }
      } else {
        if (item.tipo === 'location') {
          let dados = item.description.split(" - ");
          /*  console.log(dados)
           console.log(dados[0])
           console.log(dados[1]) */
          return (
            <div key={item.key + item.alterado}>
              <MessageBox

                {...item}
                id={item.key}
                // apiKey="AIzaSyDz1f3-p7gxOvHjVYnmt23VHuLCbZ0TS6s"
                reply={this.renderMensagemReply(item)}
                data={{
                  // uri: item.urlDownload,
                  /* status: {
                      click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                      loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                      download: this.state.downloadFile
                  }, */
                  // size: "100MB",
                  latitude: dados[0],
                  longitude: dados[1],

                }}
                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                onReplyClick={() => { this.renderResponderMensagem(item) }}
                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                //onClick={() => { console.log('clicou onClick') }}
                //onOpen={() => { console.log('clicou onOpen') }}
                position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                type={'location'}
                /*  onDownload={event => this.baixarArquivo(item.description, item)} */

                title={item.title}
                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                text={''}
                copiableDate
                dateString={item.time}

                replyButton={item.statusMessage === 'waiting' ? false : true}
                removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                status={null}//(waiting, sent, received, read) 

              />
              {/* < LocationMessage
                            key={item.key}
                            target="_blank"
                            data={{
                                 // latitude: dados[0],
                                // longitude: dados[1], 
                                latitude: '37.773972',
                                longitude: '-122.431297',
                                // staticURL: '<optional>',
                                // mapURL: '<optional>'
                            }
                            } /> */}
            </div>
          )
        } if (item.tipo === 'vcard') {
          //let vcardMEssage2 = vCard.parse(item.description);
          let card = vCard.parse(item.description);
          return (
            <div key={item.key + item.alterado}>

              <MessageBox
                /* avatar={imgTeste} */
                {...item}
                id={item.key}
                reply={this.renderMensagemReply(item)}
                onReplyClick={() => { this.renderResponderMensagem(item) }}
                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                /*  data={{
                     uri: item.urlDownload,
                     status: {
                         click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                         loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                         download: this.state.downloadFile
                     },
                     size: "100MB",
                 }} */
                /*       onReplyMessageClick={() => console.log('reply clicked!')} */
                //onClick={() => { console.log('clicou onClick') }}
                //onOpen={() => { console.log('clicou onOpen') }}
                position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                type={'text'}
                /* onDownload={event => this.baixarArquivo(item.description, item)} */

                title={item.title}
                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                /* text={(
                    <p>{card.fn[0].value}</p>
                )} */
                text={(
                  <div>
                    {card.fn.map(function (item, i) {
                      return (
                        <p key={i}>{item.value}</p>
                      )
                    })}
                    {card.tel.map(function (item, i) {
                      return <p key={i}>{item.value}</p>
                    })}
                  </div>
                )}
                copiableDate
                dateString={item.time}

                replyButton={item.statusMessage === 'waiting' ? false : true}
                removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                status={null} //(waiting, sent, received, read)

              />
            </div>
          )
        } else {
          return (
            <div key={item.key + item.alterado}>
              <MessageBox
                {...item}
                id={item.key}
                reply={this.renderMensagemReply(item)}
                data={{
                  uri: item.urlDownload,
                  status: {
                    click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                    loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                    download: this.state.downloadFile
                  },
                  // size: "100MB",
                }}

                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                onReplyClick={() => { this.renderResponderMensagem(item) }}
                //onClick={() => { console.log('clicou onClick') }}
                //onOpen={() => { console.log('clicou onOpen') }}
                position={item.circleColor === '#39a3f4' ? 'right' : "left"}
                type={'file'}
                onDownload={event => this.baixarArquivo(item.description, item)}

                title={item.title}
                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                text={item.description}
                copiableDate
                dateString={item.time}

                replyButton={item.statusMessage === 'waiting' ? false : true}
                removeButton={item.circleColor === '#00FF7F' || item.deleteMessage === true ? false : true}
                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                status={null} //(waiting, sent, received, read)

              />
            </div>
          )
        }

        /*  if (item.circleColor.includes('00FF7F')) {
             return (
                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right' }} key={item.key + item.alterado}>
                     <div
                         style={{
                             background: '#d9edf7',
                             padding: 10,
                             //  borderRadius: 8,
                             borderTopLeftRadius: 20,
                             borderTopRightRadius: 1,
                             borderBottomRightRadius: 20,
                             borderBottomLeftRadius: 5,
                             boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                             margin: 10,
                             display: 'flex',
                             flexDirection: 'column'
                         }}
                     >
                         <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                         <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                             <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                             <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                         </ContainerClipBaixarArquivo>
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'left', marginBottom: -8, marginTop: 5 }}>
                             <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                         </div>
                     </div>
                 </div>
             )
         } else {
             return (
                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', textAlign: 'left' }} key={item.key + item.alterado}>
                     <div
                         style={{
                             background: '#E6E9F2',
                             padding: 10,
                             // borderRadius: 8,
                             borderTopLeftRadius: 1,
                             borderTopRightRadius: 20,
                             borderBottomRightRadius: 5,
                             borderBottomLeftRadius: 20,
                             boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',
                             margin: 10,
                             display: 'flex',
                             flexDirection: 'column'
                         }}
                     >
                         <TextTitulo corLetraDinamica={item.circleColor}>{item.title}</TextTitulo>
                         <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                             <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                             <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                         </ContainerClipBaixarArquivo>
                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right', marginBottom: -8, marginTop: 5 }}>
                             <p style={{ fontSize: 11, color: '#808080', fontStyle: 'italic' }}>{item.time}</p>
                         </div>
                     </div>
                 </div>
             )
         } */
      }
    }

    /* if (item.circleColor.includes('00FF7F')) {
        return (
            <MessageBox
                position={'right'}
                type={'photo'}
                text={'react.svg'}
                data={{
                    uri: 'https://facebook.github.io/react/img/logo.svg',
                    status: {
                        click: false,
                        loading: 0,
                    }
                }} />
        )
    } else {
        return (
            <MessageBox
                position={'left'}
                type={'photo'}
                text={'react.svg'}
                data={{
                    uri: 'https://facebook.github.io/react/img/logo.svg',
                    status: {
                        click: false,
                        loading: 0,
                    }
                }} />
        )

    } */
  }
  renderFiltroAtendente() {
    if (this.props.verificaAtendenteVeSomenteProprioAtendimento == false && this.props.nomeRota == 'Atendimentos') {
      return (
        <DivIconeTextFiltro onClick={() => {
          /* console.log('no on click') */
          if (this.props.premium == 0) {
            /*  console.log('no premium 0') */
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Atendente');
            this.props.modificaSelectTextChamado('Selecione o Atendente');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.atendenteNovoChamado)
            /*  console.log('atendentes novo chamado')
             console.log(this.props.atendenteNovoChamado) */
          }
        }}>
          <i className="icon-atendimentos" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltroAtendimento >Atendente</TextMenuFiltroAtendimento>
        </DivIconeTextFiltro>
      )
    } else if (this.props.contaPrincipal == true && this.props.nomeRota == 'Atendimentos') {
      return (
        <DivIconeTextFiltro onClick={() => {
          if (this.props.premium == 0) {
            toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para filtrar os chamados, Assine a versão PREMIUM e libere esse e mais recursos!')
          } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
            toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
          } else {
            this.props.modificaFiltroChamado('Atendente');
            this.props.modificaSelectTextChamado('Selecione o Atendente');
            this.props.modificaValorArraySectionedFiltroChamado(this.props.atendenteNovoChamado)
          }
        }}>
          <i className="icon-atendimentos" style={{ color: '#000', fontSize: 12, marginLeft: 3 }} />
          <TextMenuFiltro >Atendente</TextMenuFiltro>
        </DivIconeTextFiltro>
      )
    } else {
      return null;
    }
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth', top: 0 });
  }

  renderRefreshConversasAnteriores() {

    if (this.props.loadingCarregandoMaisConversasChat === true) {
      console.log('mostrando loading')
      return (
        <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 5/* position: 'absolute', top: 15, center: 0 */ }}>
          <div style={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', marginTop: 5, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center' }}>
            <ClipLoader
              css={{}}
              size={25}
              color={this.props.corBotaoPadrao}
              loading={true}
            />
          </div>
        </div>
      )
    } else {
      return null;
    }

  }
  zeraMensagemResponder() {
    this.setState({
      viewResponder: false,
      commentResponder: '',
      idResponder: '',
      circleColorResponder: '',
      commentResponderType: null,
      atendenteResponder: ''
    })
  }
  _handleKeyDown = (e) => {
    if (this.props.upandoArquivoChat == false) {
      if (e.key === 'Enter' && e.shiftKey) {
        /*   $('#app').append("<br/> Detected Shift+Enter") */
      }
      else if (e.key === 'Enter') {
        /*    if (e.key === 'Enter') { */
        if (this.props.comentarioChat.trim() == '') {
          toastr.error('Error', 'Digite uma mensagem');
          e.preventDefault();
        } else {
          this.setState({ scrollTop: 0 });
          this.scrollToBottom()
          this.props.salvarComentario2('', false,
            this.props.comentarioChat, [], 1,
            this.props.keyAtendimentoChat, this.props.dadosAtendimentosChat[0], this.props.dadosAtendimentosChat[0].dataAbertura,
            this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamadoChat, this.props.dadosAtendimentosChat[0].keyAtendente,
            this.props.dadosAtendimentosChat[0], this.props.licensawebouapp, true, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
            this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
          this.zeraMensagemResponder();
          this.InputChat.focus();
          e.preventDefault();

        }
      }
    }
  }
  hideTooltips() {
    ReactTooltip.hide();
    setTimeout(
      () => {
        ReactTooltip.rebuild();
        ReactTooltip.show();

      },
      100
    )
  }
  renderImagemLogoMenu() {
    if (this.props.logotipo == null) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', height: this.state.menuOpen == false ? '100%' : '100%', width: this.state.menuOpen == false ? '100%' : '100%', borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
          {/* <div style={{ height: 50, flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
          </div> */}
        </div>
      )
    } else {
      return (
        /*  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> */
        <img src={this.props.logotipo == 'semURI' ? logoPadrao : this.props.logotipo} alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight: this.state.menuOpen == false ? '100%' : '100%', maxWidth: this.state.menuOpen == false ? '100%' : '100%' }} />

        /* </div> */
      )
    }
  }
  renderInputComentChat() {
    if (this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Fechado' || this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
      return (
        <DivContainerMensagemChat
          backgroundDinamico={this.props.corSegundoBotaoPadrao}
          corLetraDinamico={this.props.coresLetraPadrao}
          corLetraSecundaria={this.props.coresLetraSecundario}
          corBordaDinamica={this.props.corBotaoPadrao}
        >
          <div style={{ display: 'flex', height: 60, width: 40, alignItems: 'center', justifyContent: 'center' }}>
            {this.props.loadingEnviandoAudioChat == true && <ClipLoader
              css={{}}
              size={18}
              color={this.props.letraBotaoPadrao}
              loading={true}
            />}
            {this.props.loadingEnviandoAudioChat == false && < IconeMostrarSenha style={{ cursor: 'no-drop' }} data-tip={"Chat Encerrado"} data-for="tip-top" disabled onClick={() => { }} colorDinamico={this.props.letraBotaoPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-microphone" />}
          </div>
          <InputDescricaoMensagemChat
            disabled={true}
            type="textarea"
            onChange={(value) => { this.props.alteraComentarioChat(value.target.value) }}
            corLetraDinamico='#000'
            corLetraSecundaria='#a8a8B3'
            corBordaDinamica={this.props.corPrincipalChat}
            placeholder='Chat Encerrado'
            value={this.props.comentarioChat}
            onKeyDown={this._handleKeyDown}
          />
          <div style={{ display: 'flex', height: 60, width: 40, alignItems: 'center', justifyContent: 'center' }}>
            < IconeMostrarSenha style={{ cursor: 'no-drop' }} id="emojis-btn" data-tip={"Chat Encerrado"} data-for="tip-top" onClick={() => { }} colorDinamico={this.props.letraBotaoPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-smiley" />
          </div>
        </DivContainerMensagemChat>
      )
    } else {
      return (
        <>
          {this.state.viewResponder === true &&

            <div style={{ display: 'flex', borderTopLeftRadius: 5, borderTopRightRadius: 5, background: '#d9edf7', width: '100%', padding: 5, flexDirection: 'row', position: 'absolute', right: 0, bottom: 110 }}>

              <div style={{ display: 'flex', background: '#F0f0f7', width: '100%', borderRadius: 3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <div style={{ width: 5, height: '100%', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, backgroundColor: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}></div>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                    <TextResponder style={{ color: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}>{this.state.circleColorResponder === '#00FF7F' ? this.state.atendenteResponder : 'Você'}</TextResponder>
                    {/*  </div>
                              <div style={{ display: 'flex', flexDirection: 'row' }}> */}

                    <DivTruncarTexto /* style={{ display: 'flex', height: 52, flex: 1, alignItems: 'center', justifyContent: 'flex-start' }} */>


                      <TextResponder style={{ color: '#000' }}>
                        {this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} />}
                        {this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} />}

                        {this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} />}
                        {this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} />}
                        {this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} />}
                        {this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} />}
                        {this.state.commentResponder}</TextResponder>

                    </DivTruncarTexto>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', width: 40, justifyContent: 'center', alignItems: 'center' }}>
                <i onClick={() => { this.zeraMensagemResponder() }} className="icon-close" style={{ fontSize: 13, color: '#000', cursor: 'pointer' }} />
              </div>
            </div>}
          <DivContainerMensagemChat
            backgroundDinamico={this.props.corSegundoBotaoPadrao}
            corLetraDinamico={this.props.coresLetraPadrao}
            corLetraSecundaria={this.props.coresLetraSecundario}
            corBordaDinamica={this.props.corBotaoPadrao}
          >
            <div style={{ display: 'flex', height: 60, width: 40, alignItems: 'center', justifyContent: 'center' }}>
              {this.props.loadingEnviandoAudioChat == true && <ClipLoader
                css={{}}
                size={18}
                color={this.props.letraBotaoPadrao}
                loading={true}
              />}
              {this.props.loadingEnviandoAudioChat == false && < IconeMostrarSenha style={{ cursor: 'pointer' }} data-tip={"Enviar mensagem de áudio"} data-for="tip-top" onClick={() => { this._toggleModalGravarAudio() }} colorDinamico={this.props.letraBotaoPadrao} bordaDinamica={this.props.corBotaoPadrao} id="divGravarAudioChat" className="icon-microphone" />}
            </div>
            <InputDescricaoMensagemChat
              type="textarea"
              onChange={(value) => { this.props.alteraComentarioChat(value.target.value) }}
              ref={(ref) => { this.InputChat = ref }}

              corLetraDinamico='#000'
              corLetraSecundaria='#a8a8B3'
              corBordaDinamica={this.props.corPrincipalChat}
              placeholder="Escreva aqui uma mensagem"
              value={this.props.comentarioChat}
              onKeyDown={this._handleKeyDown}
            />
            <div style={{ display: 'flex', height: 60, width: 40, alignItems: 'center', justifyContent: 'center' }}>
              < IconeMostrarSenha style={{ cursor: 'pointer' }} id="emojis-btn" data-tip={"Enviar Emoji na Conversa"} data-for="tip-top" onClick={() => { this._toggleViewEmoji() }} colorDinamico={this.state.viewEmoji == true ? '#16cb9c' : this.props.letraBotaoPadrao} bordaDinamica={this.props.corBotaoPadrao} className="icon-smiley" />
            </div>
            {this.state.viewEmoji == true && <Picker
              emojiSize={32}
              native
              onSelect={this.addEmoji}
              // onClick={this.addEmoji}
              title='Selecione seu Emoji'
              emoji='point_up'
              showPreview={false}
              autoFocus={false}
              useButton
              tooltip={false}
              style={{ position: 'absolute', bottom: '115px', right: '20px', zIndex: 999 }}
              i18n={{
                search: 'Pesquisar emoji',
                clear: 'Limpar',
                notfound: 'Nenhum Emoji Encontrado',
                skintext: 'Escolha seu tom de pele padrão',
                categories: {
                  search: 'Resultados da pesquisa',
                  recent: 'Recentes',
                  smileys: 'Emojis & Pessoas',
                  people: 'Emojis & Pessoas',
                  nature: 'Animais & Natureza',
                  foods: 'Comidas & Bebidas',
                  activity: 'Atividades',
                  places: 'Viagens & Locais',
                  objects: 'Objetos',
                  symbols: 'Símbolos',
                  flags: 'Bandeiras',
                  custom: 'Custom',
                }
              }}
            />}
          </DivContainerMensagemChat>
        </>
      )
    }
  }
  renderIconeEncerrarCHat() {
    if (this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Fechado' || this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
      return (
        <div>
          <IconeChat onClick={() => {
            this.hideTooltips();
            this.props.modificaTelaAtualChatExport('avaliacaoFeitaSucesso');
            //
          }} data-tip="Encerrar Chat" data-for="tip-top" className="icon-logout" />
        </div>
      )
    } else {
      return (
        <div>
          <IconeChat onClick={() => {
            this.setState({ divEncerrarChat: true })
            //
          }} data-tip="Encerrar Chat" data-for="tip-top" className="icon-logout" />
        </div>
      )
    }

  }
  renderIconeAudio() {
    if (this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Fechado' || this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
      return null
    } else {
      if (this.state.cfgAudio == false) {
        return (
          <div>
            <IconeChat onClick={() => {
              localStorage.setItem(`reproduzsomematendimentocliente`, JSON.stringify(true));
              this.setState({ cfgAudio: true })
            }} data-tip="Ativar som de novas mensagens" data-for="tip-top" className="icon-audio-speaker-off" />
          </div>
        )
      } else {
        return (
          <div>
            <IconeChat onClick={() => {
              localStorage.setItem(`reproduzsomematendimentocliente`, JSON.stringify(false));
              this.setState({ cfgAudio: false })
            }} data-tip="Desativar som de novas mensagens" data-for="tip-top" className="icon-audio-speaker-on" />
          </div>
        )
      }

    }


  }
  renderIconeUploadArquivoChat() {
    if (this.props.textArquivoChat !== '') {
      return (
        <TextLoadingEnviandoARquivo >{this.props.textArquivoChat}</TextLoadingEnviandoARquivo>
      )
    } else if (this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Fechado' || this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
      return null
    } else {
      return (
        <Dropzone
          multiple={true}
          noKeyboard
          noDrag
          maxSize={2e+7}
          /*  onDropRejected={() => {
               toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
           }} */
          onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            /*  console.log(isDragActive); */

            return (
              /*     <section> */
              <div   {...getRootProps()}>
                <input  {...getInputProps()} />
                <IconeChat data-tip="Enviar Arquivos" data-for="tip-top" className="icon-clip" />

              </div>
              /*   </section> */
            )
          }}
        </Dropzone>
      )
    }
  }
  renderAtendimentoOnlineOffline() {
    if (this.props.chatOnlineOuOffline == 'online' || this.props.chatOnlineOuOffline == 'offline' && this.props.telaAtualChat === 'aguardandoAtendimento') {
      return (
        <>
          <BrowserView >
            <ContainerAtendimentoOnlineRetraido
              onClick={() => { this.props.modificaAtendimentoOnlineVisible(true); }}
              corBackgroundDinamico={this.props.corPrincipalChat}>
              <i className="icon-chat1" style={{ fontSize: 17, color: this.props.coresLetraPadrao, marginLeft: 5, marginRight: 5 }} />
              <p style={{ color: this.props.coresLetraPadrao, fontSize: 17, fontWeight: 'bold' }}>Atendimento Online</p>
            </ContainerAtendimentoOnlineRetraido>
          </BrowserView>
          <MobileView>
            <div
              style={{
                backgroundColor: this.props.corPrincipalChat,
                alignSelf: "flex-end",
                position: "absolute",
                display: 'flex',
                bottom: 15,
                right: 15,
                zIndex: 9988,
                height: 50,
                width: 50,
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={() => {
                this.props.modificaAtendimentoOnlineVisible(true);
              }}
            >
              <i className="icon-chat1" style={{ fontSize: 25, color: this.props.coresLetraPadrao, marginLeft: 5, marginRight: 5 }} />
            </div>
          </MobileView>
        </>
      )
    } else if (this.props.chatOnlineOuOffline == 'offline' && this.props.telaAtualChat !== 'aguardandoAtendimento') {
      return (
        <>
          <BrowserView>
            <ContainerAtendimentoOnlineRetraido
              onClick={() => { this.props.modificaVisibilidadeTelaChatOffline(true); }}
              corBackgroundDinamico={this.props.corPrincipalChat}>
              <i className="icon-no-chatting" style={{ fontSize: 17, color: this.props.coresLetraPadrao, marginLeft: 5, marginRight: 5 }} />
              <p style={{ color: this.props.coresLetraPadrao, fontSize: 17, fontWeight: 'bold' }}>Atendimento Offline</p>
            </ContainerAtendimentoOnlineRetraido>
          </BrowserView>
          <MobileView>
            <div
              style={{
                backgroundColor: this.props.corPrincipalChat,
                alignSelf: "flex-end",
                position: "absolute",
                display: 'flex',
                bottom: 15,
                right: 15,
                zIndex: 9988,
                height: 50,
                width: 50,
                borderRadius: 50,
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={() => {
                this.props.modificaVisibilidadeTelaChatOffline(true);
              }}
            >
              <i className="icon-no-chatting" style={{ fontSize: 25, color: this.props.coresLetraPadrao, marginLeft: 5, marginRight: 5 }} />
            </div>
          </MobileView>
        </>
      )
    } else {
      //chatOnlineOuOffline == 'naocfg'
      return null
    }
  }
  renderTelasChat() {
    if (this.props.telaAtualChat == '') {
      return (
        <ContainerChatAbertura>
          <div style={{
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            flex: 1, flexDirection: 'column'
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              {this.props.verificaAgrupaAtendenteSetor == true &&

                <SubContainerNovoAtendimento>
                  <p style={{ marginLeft: 20, color: '#666', fontSize: 14, marginTop: 30 }}>Produto / Setor: *</p>
                  <ContainerSelectSearch>
                    <SelectSearch
                      renderValue={(valueProps) =>
                        <DivInputSearch>
                          <InputSearch
                            corLetraDinamico='#000'
                            backgroundDinamico='#f6f8f9'
                            placeholderColor='#a8a8B3'
                            corBordaDinamica={this.props.corPrincipalChat}
                            {...valueProps} ></InputSearch>
                          <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico='#000' />
                        </DivInputSearch>
                      }
                      value={this.props.setorSelecionado} options={this.props.setorNovoChamado} onChange={(value, option) => { this.props.modificaSetorSelecionado(value); this.props.modificaNomeSetorSelecionado(option.name); }} placeholder="Selecione" search />

                  </ContainerSelectSearch>
                </SubContainerNovoAtendimento>}
              {this.props.criarChamadoProblemaEstatico == true &&
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: this.props.verificaAgrupaAtendenteSetor == true ? 0 : 1 }}>
                      <SubContainerNovoAtendimento style={{ marginTop: this.props.verificaAgrupaAtendenteSetor == true ? 10 : 30 }}>
                        <p style={{ marginLeft: 20, color: '#666', fontSize: 14 }}>Problema / Dúvida: *</p>
                        <ContainerSelectSearch>
                          <SelectSearch
                            renderValue={(valueProps) =>
                              <DivInputSearch>
                                <InputSearch
                                  corLetraDinamico='#000'
                                  backgroundDinamico='#f6f8f9'
                                  placeholderColor='#a8a8B3'
                                  corBordaDinamica={this.props.corPrincipalChat}
                                  {...valueProps} ></InputSearch>
                                <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico='#000' />
                              </DivInputSearch>
                            }
                            value={this.props.problemaEstaticoSelecionado} options={this.props.problemasEstatico} onChange={(value, option) => { this.props.modificaProblemaEstaticoSelecionado(value); this.props.modificaNomeProblemaEstaticoSelecionado(option.name); }} placeholder="Selecione" search />

                        </ContainerSelectSearch>
                      </SubContainerNovoAtendimento>

                    </div>

                  </div>

                </div>
              }

              {this.props.criarChamadoProblemaEstatico == false && <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}> <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <ContainerInputDescricaoChamado>
                  <strong style={{ color: '#666', fontSize: 15, marginBottom: 5, marginLeft: 5, marginTop: this.props.verificaAgrupaAtendenteSetor == true ? 10 : 30 }}>Qual assunto deseja tratar neste Chat? *</strong>
                  <InputDescricaoChamado
                    type="textarea"
                    onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                    corLetraDinamico='#000'
                    corLetraSecundaria='#a8a8B3'
                    corBordaDinamica={this.props.corPrincipalChat}
                    placeholder="Digite aqui sua dúvida"
                    value={this.props.descricaoAtendimento}
                  />
                </ContainerInputDescricaoChamado>
              </div>
              </div>
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
              {this.props.loadingBotaoCadastrarChamado == false && <ButtonEntrarChat onClick={() => {
                this.props.ativaLoadingBotaoCadastrarChamadoExport(true)
                /*  let verificaChatOnline = this.verificaSeChatEstaOnlineAntesDeCriarNovoChat()
                 if (this.props.chatOnlineOuOffline == 'offline') {
                   toastr.error('Error', 'Atendimento via chat se encontra Offline neste momento, verifique nosso horário de funcionamento');
                   this.props.modificaVisibilidadeTelaChatOffline(true);
                   this.props.modificaAtendimentoOnlineVisible(false);
                   this.props.ativaLoadingBotaoCadastrarChamadoExport(false)
                 } else if (verificaChatOnline === false) {
                   console.log('coloco chat como offline')
                   this.props.modificaVisibilidadeTelaChatOffline(true);
                   this.props.modificaVisibilidadeAtendimentoChatOnlineOuOfflineExport('offline')
                   this.props.modificaAtendimentoOnlineVisible(false);
                   toastr.warning('Aviso', 'Atendimento via chat se encontra Offline neste momento, verifique nosso horário de funcionamento.')
                   this.props.ativaLoadingBotaoCadastrarChamadoExport(false)
                 }
                 else { */
                this.props.cadastraNovoAtendimento(
                  '',
                  '',
                  this.props.setorSelecionado,
                  '',
                  '',
                  this.props.descricaoAtendimento,
                  [],
                  '',
                  '',
                  '',
                  [],
                  this.props.setorSelecionado,
                  this.props.verificaAgrupaAtendenteSetor,
                  this.props.nomeAuxSetor,
                  this.props.criarChamadoProblemaEstatico,
                  this.props.problemaEstaticoSelecionado,
                  this.props.nomeProblemaEstaticoSelecionado,
                  true
                )
                /*  } */
              }} backgroundDinamico={this.props.corPrincipalChat}><p style={{ fontSize: 16, color: this.props.coresLetraPadrao }}>Entrar no Chat</p></ButtonEntrarChat>}
              {this.props.loadingBotaoCadastrarChamado == true &&
                <ClipLoader
                  /* css={{ margin: 10 }} */
                  size={25}
                  color={this.props.corPrincipalChat}
                  loading={this.props.loadingBotaoCadastrarChamado}
                />
              }

            </div>
            <div style={{ height: 20, margin: 10, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
            </div>
          </div>
        </ContainerChatAbertura>
      )
    } else if (this.props.telaAtualChat == 'aguardandoAtendimento') {
      return (
        <Dropzone
          multiple={true}
          noKeyboard
          maxSize={2e+7}
          noClick
          onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            /*          console.log(isDragActive, isDragAccept, isDragReject); */
            if (isDragActive) {
              if (this.props.dadosAtendimentosChat[0].status == 'Fechado') {
                return (
                  <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: 'red',
                    borderStyle: 'dashed',
                    color: '#bdbdbd',
                    background: '#f6f8f9',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                    justifyContent: 'center',
                    /*  margin: 5, */
                  }}  {...getRootProps()}>
                    {/*   <input {...getInputProps()} /> */}
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22 }}>Chat Finalizado</p>
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22, marginTop: 20 }}>Abra um novo chat para fazer upload de arquivos</p>
                  </div>
                )
              } else if (this.props.dadosAtendimentosChat[0].status == 'Cancelado') {
                return (
                  <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: 'red',
                    borderStyle: 'dashed',
                    color: '#bdbdbd',
                    background: '#f6f8f9',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                    justifyContent: 'center',
                    /*  margin: 5, */
                  }}  {...getRootProps()}>
                    {/*   <input {...getInputProps()} /> */}
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22 }}>Chat Cancelado</p>
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22, marginTop: 20 }}>Abra um novo chat para fazer upload de arquivos</p>
                  </div>
                )
              } else {
                return (
                  <div style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: 2,
                    borderRadius: 2,
                    borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                    borderStyle: 'dashed',
                    color: '#bdbdbd',
                    background: '#f6f8f9',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                    justifyContent: 'center',
                    /*  margin: 5, */
                  }}  {...getRootProps()}>
                    {/*   <input {...getInputProps()} /> */}
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22 }}>Solte Arquivos para Anexar <i className="icon-clip" style={{ color: '#666', fontSize: 21 }} /></p>
                    <p style={{ color: '#666', textAlign: 'center', fontSize: 22, marginTop: 20 }}>Tamanho Máximo de 20MB</p>
                  </div>
                )
              }
            } else {
              return (

                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden', background: '#f6f8f9' }}  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div onClick={() => {

                  }} style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <div style={{ height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d9edf7', width: '100%' }}>
                      <p style={{ color: '#000', fontSize: 14 }}>{this.props.nomeEmpresa.toUpperCase()}</p>
                    </div>
                    {this.props.dadosAtendimentosChat.length > 0 && this.props.dadosAtendimentosChat[0].status == 'Aberto' && <div style={{ height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d9edf7', width: '100%' }}>
                      <DivAnimadaPosicaoFila backgroundinamico={this.props.corPrincipalChat}>
                        <p style={{ fontSize: 14, fontWeight: 'bold', color: this.props.coresLetraPadrao }}>Posição na Fila: {this.props.posicaoFilaChat}</p>
                      </DivAnimadaPosicaoFila>
                    </div>}
                    <ContainerChat ref={el => { this.el = el; }} onScroll={this.scrollCheck} style={{ borderRadius: 0 }} >
                      <FlatList
                        list={this.props.acoesDoAtendimentoChat}
                        renderItem={(item) => this.messageBox(item)}
                        renderWhenEmpty={() => null}
                      // onContentSizeChange={() => { alert("1") } }
                      />
                      <FlatList
                        list={this.props.arrayConversasAntigasChat}
                        renderItem={(item) => this.messageBox(item)}
                        renderWhenEmpty={() => null}
                      />

                      {/*  <FlatList
                        list={this.props.acoesDoAtendimentoChat}
                        renderItem={(item) => this.renderTimeLineItem(item)}
                        renderWhenEmpty={() => null}
                      /> */}
                      {this.renderRefreshConversasAnteriores()}
                    </ContainerChat>
                    {/*    <form onSubmit={(e) => alert('teste')}> */}
                    {this.renderInputComentChat()}
                    {/*  </form> */}
                    <div style={{
                      height: 40, padding: 10, justifyContent: 'space-between', alignItems: 'center', display: 'flex', backgroundColor: '#f6f8f9',
                      flexDirection: 'row', paddingLeft: 20, paddingRight: 20, borderBottomLeftRadius: 5, borderBottomRightRadius: 5
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {this.renderIconeEncerrarCHat()}


                        {this.renderIconeUploadArquivoChat()}
                        {this.renderIconeAudio()}
                      </div>
                      {this.state.divEncerrarChat == true && <div

                        style={{
                          display: 'flex', flexDirection: 'column', zIndex: 9988, height: 100, width: 395, position: 'absolute', bottom: 12,
                          right: 15, background: '#d9edf7', borderRadius: 5, boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',

                        }}>
                        <p style={{ fontSize: 17, color: '#666', textAlign: 'center', marginTop: 7, marginBottom: 7 }}>Deseja Realmente Finalizar o Chat?</p>
                        <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'space-around' }}>
                          <ButtonCancelarEncerrarChat onClick={() => { this.setState({ divEncerrarChat: false }) }} corBordaDinamica={this.props.corPrincipalChat} backgroundDinamico='#f6f8f9'>Cancelar</ButtonCancelarEncerrarChat>
                          <ButtonEncerrarChat onClick={() => {
                            this.props.enviaConversasAntigasChatExport([])
                            this.props.enviaReferenciaultimaKeyAcoesChamadoExport('');
                            this.props.atribuiStatusAtendimento(this.props.keyAtendimentoChat, 'Fechado', this.props.dadosAtendimentosChat[0].dataAbertura, this.props.dadosAtendimentosChat[0], this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamadoChat, this.props.dadosAtendimentosChat[0].keyAtendente, this.props.dadosAtendimentosChat[0], this.props.licensawebouapp, true);
                            this.setState({ divEncerrarChat: false })
                          }} corBordaDinamica='#f6f8f9' backgroundDinamico={this.props.corPrincipalChat}>Encerrar</ButtonEncerrarChat>
                        </div>
                      </div>}

                      <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
                    </div>
                  </div >
                </div>

              )
            }
          }}
        </Dropzone>
      )
    } else if (this.props.telaAtualChat == 'chatFinalizado') {
      return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d9edf7', width: '100%' }}>
            <p style={{ color: '#000', fontSize: 14 }}>Chat Finalizado</p>
          </div>
          <ContainerChatFinalizado style={{ borderRadius: 0 }}>
            <div style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', marginTop: 7 }}>
              <i className="icon-review" style={{ fontSize: 20, color: '#666', padding: 7 }} />
              <p style={{ fontSize: 17, fontWeight: 'bold', color: '#666' }}>Como você avalia este atendimento?</p>
            </div>
            <div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row'
                }}>
                  <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(5) }}>
                    <div style={{
                      display: 'flex',
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                      borderWidth: 2,
                      border: `1px solid ${this.props.corPrincipalChat}`,
                      //  border:`1px solid ${this.props.corBotaoPadrao}`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {
                        this.state.isSelectedRadioButton5estrelas ?
                          <div style={{
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            backgroundColor: this.props.corPrincipalChat,
                          }} />
                          : null
                      }
                    </div>
                  </div>
                  <p style={{
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro',
                    color: '#666',
                    textAlign: 'left'
                  }}>Excelente</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />

                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  //alignItems: 'center',
                  // justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(4) }}>
                    <div style={{
                      display: 'flex',
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                      borderWidth: 2,
                      border: `1px solid ${this.props.corPrincipalChat}`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {
                        this.state.isSelectedRadioButton4estrelas ?
                          <div style={{
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            backgroundColor: this.props.corPrincipalChat,
                          }} />
                          : null
                      }
                    </div>
                  </div>
                  <p style={{
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro',
                    color: '#666',
                    textAlign: 'left'
                  }}>Ótimo</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />

                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  //alignItems: 'center',
                  // justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(3) }}>
                    <div style={{
                      display: 'flex',
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                      borderWidth: 2,
                      border: `1px solid ${this.props.corPrincipalChat}`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {
                        this.state.isSelectedRadioButton3estrelas ?
                          <div style={{
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            backgroundColor: this.props.corPrincipalChat,
                          }} />
                          : null
                      }
                    </div>
                  </div>
                  <p style={{
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro',
                    color: '#666',
                    textAlign: 'left'
                  }}>Bom</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  //alignItems: 'center',
                  // justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(2) }}>
                    <div style={{
                      display: 'flex',
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                      borderWidth: 2,
                      border: `1px solid ${this.props.corPrincipalChat}`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {
                        this.state.isSelectedRadioButton2estrelas ?
                          <div style={{
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            backgroundColor: this.props.corPrincipalChat,
                          }} />
                          : null
                      }
                    </div>
                  </div>
                  <p style={{
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro',
                    color: '#666',
                    textAlign: 'left'
                  }}>Regular</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                </div>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  //alignItems: 'center',
                  // justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(1) }}>
                    <div style={{
                      display: 'flex',
                      height: 24,
                      width: 24,
                      borderRadius: 12,
                      borderWidth: 2,
                      border: `1px solid ${this.props.corPrincipalChat}`,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      {
                        this.state.isSelectedRadioButton1estrelas ?
                          <div style={{
                            height: 12,
                            width: 12,
                            borderRadius: 6,
                            backgroundColor: this.props.corPrincipalChat,
                          }} />
                          : null
                      }
                    </div>
                  </div>
                  <p style={{
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro',
                    color: '#666',
                    textAlign: 'left'
                  }}>Ruim</p>
                </div>
                <div style={{
                  display: 'flex',
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row'
                }}>
                  <i className="icon-star" style={{ fontSize: 20, color: "#FFDB58", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                  <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                </div>
              </div>

            </div>
            <DivInputComentarChamado >
              <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', paddingLeft: 40 }}>
                <p style={{ fontSize: 16, color: '#666' }}>Comentário:</p>
              </div>
              <InputDescricaoComentarioChat
                type="textarea"
                onChange={(value) => { this.props.modificaComentarioAvaliacao(value.target.value) }}
                corLetraDinamico='#000'
                corLetraSecundaria='#a8a8B3'
                corBordaDinamica={this.props.corPrincipalChat}
                value={this.props.comentarioAvaliacao}
                placeholder='(Opcional)'
              />
            </DivInputComentarChamado>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
              {this.props.loadingBotaoCadastrarChamado == false && <ButtonEntrarChat onClick={() => {
                if (this.state.valorAvaliacao === 0) {
                  toastr.error('Error', 'Selecione uma nota de Avaliação');
                } else {
                  this.props.modificaTelaAtualChatExport('avaliacaoFeitaSucesso')
                  this.props.cadastraAvaliacaoChamado(this.props.dadosAtendimentosChat[0], this.props.comentarioAvaliacao, this.state.valorAvaliacao, this.props.verificaSeJaFezAvaliacaoChamadoChat);
                  let state = this.state;
                  state.isSelectedRadioButton5estrelas = false;
                  state.isSelectedRadioButton4estrelas = false;
                  state.isSelectedRadioButton3estrelas = false;
                  state.isSelectedRadioButton2estrelas = false;
                  state.isSelectedRadioButton1estrelas = false;
                  state.obrigatorioComentarioAvaliacao = false;
                  state.valorAvaliacao = 0;
                  this.setState(state);

                }
              }} backgroundDinamico={this.props.corPrincipalChat}><p style={{ fontSize: 16, color: this.props.coresLetraPadrao }}>Enviar Avaliação</p></ButtonEntrarChat>}
              {this.props.loadingBotaoCadastrarChamado == true &&
                <ClipLoader
                  /* css={{ margin: 10 }} */
                  size={25}
                  color={this.props.corPrincipalChat}
                  loading={this.props.loadingBotaoCadastrarChamado}
                />
              }

            </div>
          </ContainerChatFinalizado>

          <div style={{
            height: 40, padding: 10, justifyContent: 'space-between', alignItems: 'center', display: 'flex', backgroundColor: '#f6f8f9',
            flexDirection: 'row', paddingLeft: 20, paddingRight: 20, borderBottomLeftRadius: 5, borderBottomRightRadius: 5
          }}>

            <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
          </div>
        </div >
      )
    } else if (this.props.telaAtualChat == 'avaliacaoFeitaSucesso') {
      return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ContainerChatFinalizado style={{ borderRadius: 0 }}>
            <div style={{
              padding: 10, justifyContent: 'center', alignItems: 'center', display: 'flex', flex: 1,
              flexDirection: 'column',
            }}>
              <img src={imgCheck} alt="foto check" style={{ height: 70, width: 70 }} />
              <p style={{ fontSize: 17, color: '#666', textAlign: 'center', marginTop: 20, marginBottom: 20 }}>Obrigado por usar nosso chat!</p>
              <ButtonEntrarChat onClick={() => {

                this.props.modificaSetorSelecionado('');
                this.props.modificaNomeSetorSelecionado('');
                this.props.modificaProblemaEstaticoSelecionado('');
                this.props.modificaNomeProblemaEstaticoSelecionado('');
                setTimeout(() => {
                  this.props.modificaTelaAtualChatExport('');
                }, 100);
              }} backgroundDinamico={this.props.corPrincipalChat}><p style={{ fontSize: 16, color: this.props.coresLetraPadrao }}>Novo Atendimento</p></ButtonEntrarChat>
            </div>
          </ContainerChatFinalizado>
          <div style={{
            height: 40, padding: 10, justifyContent: 'space-between', alignItems: 'center', display: 'flex', backgroundColor: '#f6f8f9',
            flexDirection: 'row', paddingLeft: 20, paddingRight: 20, borderBottomLeftRadius: 5, borderBottomRightRadius: 5
          }}>

            <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
          </div>
        </div >
      )
    }
  }
  renderConteudoChat() {
    return (
      <ContainerAtendimentoOnlineExpandido >
        <CabecalhoChat corBackgroundDinamico={this.props.corPrincipalChat}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <i className="icon-chat1" style={{ color: this.props.coresLetraPadrao, fontSize: 16 }} />
            <p style={{ color: this.props.coresLetraPadrao, fontSize: 19, fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Atendimento Online</p>
          </div>
          <i className="icon-close" style={{ color: this.props.coresLetraPadrao, fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.props.modificaAtendimentoOnlineVisible(false)} />
        </CabecalhoChat>

        {this.renderTelasChat()}




      </ContainerAtendimentoOnlineExpandido>
    )
  }
  renderHorariosOperacaoSegunda() {
    if (this.props.segundaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Segunda - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSegundaFeira.start} - {this.props.horarioOperacaoSegundaFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Segunda - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSegundaFeira.start} - {this.props.horarioOperacaoSegundaFeira.end} <span> às </span> {this.props.horarioOperacaoSegundaFeira2.start} - {this.props.horarioOperacaoSegundaFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Segunda - Feira</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }


  }
  renderHorariosOperacaoTerca() {
    if (this.props.tercaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Terça - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoTercaFeira.start} - {this.props.horarioOperacaoTercaFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Terça - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoTercaFeira.start} - {this.props.horarioOperacaoTercaFeira.end} <span> às </span> {this.props.horarioOperacaoTercaFeira2.start} - {this.props.horarioOperacaoTercaFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Terça - Feira</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderHorariosOperacaoQuarta() {
    if (this.props.quartaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quarta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoQuartaFeira.start} - {this.props.horarioOperacaoQuartaFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quarta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoQuartaFeira.start} - {this.props.horarioOperacaoQuartaFeira.end} <span> às </span> {this.props.horarioOperacaoQuartaFeira2.start} - {this.props.horarioOperacaoQuartaFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quarta - Feira</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderHorariosOperacaoQuinta() {
    if (this.props.quintaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quinta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoQuintaFeira.start} - {this.props.horarioOperacaoQuintaFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quinta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoQuintaFeira.start} - {this.props.horarioOperacaoQuintaFeira.end} <span> às </span> {this.props.horarioOperacaoQuintaFeira2.start} - {this.props.horarioOperacaoQuintaFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Quinta - Feira</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderHorariosOperacaoSexta() {
    if (this.props.sextaFeiraChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sexta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSextaFeira.start} - {this.props.horarioOperacaoSextaFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sexta - Feira</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSextaFeira.start} - {this.props.horarioOperacaoSextaFeira.end} <span> às </span> {this.props.horarioOperacaoSextaFeira2.start} - {this.props.horarioOperacaoSextaFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sexta - Feira</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderHorariosOperacaoDomingo() {
    if (this.props.domingoChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Domingo</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoDomingoFeira.start} - {this.props.horarioOperacaoDomingoFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Domingo</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoDomingoFeira.start} - {this.props.horarioOperacaoDomingoFeira.end} <span> às </span> {this.props.horarioOperacaoDomingoFeira2.start} - {this.props.horarioOperacaoDomingoFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Domingo</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderHorariosOperacaoSabado() {
    if (this.props.sabadoChat == true) {
      if (this.props.switchUtilizar2HorariosChat == false) {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sábado</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSabadoFeira.start} - {this.props.horarioOperacaoSabadoFeira.end}</p>
          </>
        )
      } else {
        return (
          <>
            <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sábado</p>
            <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>{this.props.horarioOperacaoSabadoFeira.start} - {this.props.horarioOperacaoSabadoFeira.end} <span> às </span> {this.props.horarioOperacaoSabadoFeira2.start} - {this.props.horarioOperacaoSabadoFeira2.end}</p>
          </>
        )
      }

    } else {
      return (
        <>
          <p style={{ fontSize: 14, fontWeight: 'bold', color: '#000' }}>Sábado</p>
          <p style={{ fontSize: 14, color: '#666', marginLeft: 5 }}>Fechado</p>
        </>
      )
    }
  }
  renderConteudoChatOffline() {
    return (
      <ContainerAtendimentoOnlineExpandido style={{ overflow: 'hidden' }}>
        <CabecalhoChat corBackgroundDinamico={this.props.corPrincipalChat}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <i className="icon-no-chatting" style={{ color: this.props.coresLetraPadrao, fontSize: 16 }} />
            <p style={{ color: this.props.coresLetraPadrao, fontSize: 19, fontWeight: 'bold', marginLeft: 10, marginRight: 10 }}>Atendimento Offline</p>
          </div>
          <i className="icon-close" style={{ color: this.props.coresLetraPadrao, fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.props.modificaVisibilidadeTelaChatOffline(false)} />
        </CabecalhoChat>

        <ContainerChatAbertura>
          <div style={{
            paddingLeft: 10,
            paddingRight: 10,
            display: 'flex',
            flex: 1, flexDirection: 'column',
            overflow: 'hidden'
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
              <div style={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <p style={{ color: '#666', fontSize: 15, marginBottom: 3 }}>Infelizmente, não estamos online no momento.</p>
                <p style={{ color: '#666', fontSize: 15 }}>Abra um atendimento que entraremos em contato o mais rápido possível.</p>
              </div>
              <div style={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                <p style={{ color: this.props.corPrincipalChat, fontSize: 15, marginBottom: 3, fontWeight: 'bold' }}>Nosso horário de operação:</p>
                {this.renderFusoHorario()}
                <div style={{ alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                  {this.renderHorariosOperacaoSegunda()}
                  {this.renderHorariosOperacaoTerca()}
                  {this.renderHorariosOperacaoQuarta()}
                  {this.renderHorariosOperacaoQuinta()}
                  {this.renderHorariosOperacaoSexta()}
                  {this.renderHorariosOperacaoSabado()}
                  {this.renderHorariosOperacaoDomingo()}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
              <ButtonEntrarChatLink to="/atendimentos" onClick={() => {
                this.props.modificaVisibilidadeModalNovoAtendimento(true);
                this.props.modificaVisibilidadeTelaChatOffline(false);
              }} backgroundDinamico={this.props.corPrincipalChat}><p style={{ fontSize: 16, color: this.props.coresLetraPadrao }}>Novo Atendimento</p></ButtonEntrarChatLink>


            </div>
            <div style={{ height: 20, margin: 10, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <LinkDropDesk href="https://www.dropdesk.com.br" target="_blank">dropdesk</LinkDropDesk>
            </div>
          </div>
        </ContainerChatAbertura>




      </ContainerAtendimentoOnlineExpandido>
    )
  }
  renderFusoHorario() {
    if (this.props.fusoHorarioSelelcionado == 'America/Rio_Branco') {
      return (
        <span style={{ color: '#666', fontSize: 13, marginBottom: 3 }}>(Fuso Horário do Acre -05:00)</span>
      )
    } else if (this.props.fusoHorarioSelelcionado == 'Brazil/DeNoronha') {
      return (
        <span style={{ color: '#666', fontSize: 13, marginBottom: 3 }}>(Fuso Horário de Fernando de Noronha -02:00)</span>
      )
    }
    else if (this.props.fusoHorarioSelelcionado == 'America/Manaus') {
      return (
        <span style={{ color: '#666', fontSize: 13, marginBottom: 3 }}>(Fuso Horário de Amazonas -04:00)</span>
      )
    } else {
      return (
        <span style={{ color: '#666', fontSize: 13, marginBottom: 3 }}>(Fuso Horário de Brasilia -03:00)</span>
      )
    }

  }
  render() {
    const menu = (
      <DivMenuNovo>
        <TextNovoMenuLink to="/atendimentos" onClick={() => {

          this.props.modificaVisibilidadeModalNovoAtendimento(true)

        }}>Novo Atendimento</TextNovoMenuLink>
      </DivMenuNovo>
    );
    const menuPerfil = (
      <DivMenuPerfil>
        <TextLinkPerfil onClick={() => {
          // this.props.acessouMeuPErfil(true);
          //history.push('/atendentes')

        }}
          to="/perfil"
        >Meu Perfil</TextLinkPerfil>
        <TextPerfil onClick={() => {

          this.props.sairDoApp()
          // window.location = "/login";
        }}>Sair</TextPerfil>
      </DivMenuPerfil>
    );


    return (
      <BrowserRouter history={history}>
        <AuthProvider>

          <Switch>
            <Route path="/" exact>
              <Login />
            </Route>
            {/*     <Route exact path="/login">
              <Login />
            </Route> */}
            {/*   <PrivateRoute exact path='/' component={Login} /> */}
            <Route exact path="/cadastro">
              <Cadastro />
            </Route>



            <Container>
              <Modal focusTrapped={false} showCloseIcon={false} open={this.props.isMaintenance} onClose={() => { }} center>
                <ContainerModalCompraSucesso backgroundDinamico='#29454D'>
                  <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                    <TextNovoAtendimento corLetraDinamico='#FFF'>MANUNTENÇÃO</TextNovoAtendimento>
                    {/*  <div>
                      <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                        this._toggleModalVersaoPremiumSucesso()
                      }} className="icon-close" />
                    </div> */}
                  </ContainerIconeFecharModal>

                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{}}>
                      <img src={backGroundStopVersaoPremium} style={{ width: '100%', height: 300 }} />
                    </div>
                    <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 30, margin: 20, display: 'flex' }}>
                      <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}></p>
                    </div>
                    <div stle={{ justifyContent: 'center', alignItems: 'center', margin: 20, display: 'flex', alignItems: 'center' }}>
                      <p style={{ color: '#FFF', fontSize: 23, textAlign: 'center' }}>Estamos passando por uma MANUNTENÇÃO para melhorar ainda mais a ferramenta, retornaremos EM BREVE!</p>
                      {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                    </div>

                  </div>
                </ContainerModalCompraSucesso>
              </Modal>
              <Modal focusTrapped={false} showCloseIcon={false} open={this.props.isNewVersionAvailable} onClose={() => { }} center>
                <ContainerModalCompraSucesso backgroundDinamico='#29454D'>
                  <ContainerIconeFecharModal backgroundDinamico='#3F535A'>
                    <TextNovoAtendimento corLetraDinamico='#FFF'>Nova Atualização</TextNovoAtendimento>
                    {/*  <div>
                      <IconeFechar backgroundDinamico='#FFF' onClick={() => {
                        this._toggleModalVersaoPremiumSucesso()
                      }} className="icon-close" />
                    </div> */}
                  </ContainerIconeFecharModal>

                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{}}>
                      <img src={backGroundStopVersaoPremium} style={{ width: '100%', height: 300 }} />
                    </div>
                    <div style={{ height: 60, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 30, margin: 20, display: 'flex' }}>
                      <p style={{ fontSize: 19, fontWeight: 'bold', color: '#FFF', textAlign: 'center' }}></p>
                    </div>
                    <div stle={{ justifyContent: 'center', alignItems: 'center', margin: 20, display: 'flex', alignItems: 'center' }}>
                      <p style={{ color: '#FFF', fontSize: 23, textAlign: 'center' }}>Há uma nova atualização do DropDesk, por favor atualize a página para continuar utilizando.</p>
                      {/*  <Text style={{ color: '#FFF', fontSize: 13, textAlign: 'center' }}>Qualquer dúvid.</Text> */}
                    </div>

                  </div>
                </ContainerModalCompraSucesso>
              </Modal>
              <ProSidebarTeste
                backgroundinamico={this.props.corFundoMenu}
                colordinamico={this.props.corLetraMenu}
                highlightdinamico={this.props.corBotaoPadrao}

                /*  className="pro-sidebar"
                 style={{ "--bg": String("red")}} */
                collapsed={this.state.menuOpen}
              // image={logoPadrao}
              >
                <SidebarHeader >
                  <ButtonMenu>

                    <ButtonExpandeMenu type="button" onClick={() => this._toggleDrawerMenu()}>
                      <i className="icon-open-menu1" style={{
                        color: '#FFF', fontSize: 17,
                        //backgroundColor:'rgba(173, 173, 173, 0.2)',padding:5,borderRadius:5
                      }} />
                    </ButtonExpandeMenu>

                  </ButtonMenu>
                  <BorderBottomMenu ></BorderBottomMenu>
                </SidebarHeader>
                <SidebarContent >
                  <MenuSide iconShape="round">

                    <MenuItemSide active={this.props.nomeRota == 'Atendimentos' || this.props.nomeRota.includes('Atendimento #') ? true : false} title='Atendimentos' onClick={() => { this.props.modificaNomeRotaNoHeader('Atendimentos') }} icon={<i className="icon-headset" style={{ color: '#FFF', fontSize: 18 }} />}>Atendimentos <Link to="/atendimentos" /></MenuItemSide>
                    <MenuItemSide active={this.props.nomeRota == 'Perfil' ? true : false} title='Perfil' onClick={() => { this.props.modificaNomeRotaNoHeader('Perfil') }} icon={<i className="icon-user" style={{ color: '#FFF', fontSize: 18 }} />}>Perfil <Link to="/perfil" /></MenuItemSide>
                    <MenuItemSide active={this.props.nomeRota == 'Notificações' ? true : false} title='Notificações' onClick={() => { this.props.modificaNomeRotaNoHeader('Notificações') }} icon={<i className="icon-notification" style={{ color: '#FFF', fontSize: 18 }} />}>Notificações <Link to="/notificacoes" /></MenuItemSide>
                    <MenuItemSide active={this.props.nomeRota == 'Sair' ? true : false} title='Sair' onClick={() => { this.props.modificaModalSair(true) }} icon={<i className="icon-logout" style={{ color: '#FFF', fontSize: 18 }} />}>Sair</MenuItemSide>

                  </MenuSide>
                </SidebarContent >
                <SidebarFooter>

                  {this.renderImagemLogoMenu()}


                </SidebarFooter>
              </ProSidebarTeste>
              <SubContainer>
                <HeaderPadrao backgroundDinamico={this.props.backgroundSegundo}>
                  <NomeRota colorDinamico={this.props.coresLetraPadrao}>{this.props.nomeRota}   {this.props.nomeRota.includes('Atendimento #') &&
                    <Link to="/atendimentos" style={{ fontSize: 13, textDecoration: 'none', color: this.props.coresLetraPadrao, fontWeight: 'bold', backgroundColor: 'transparent', border: 0 }} ><i className="icon-setavoltar" style={{ color: this.props.coresLetraPadrao, marginRight: 5, fontSize: 14, marginLeft: 45, fontWeight: 'bold' }} />Voltar</Link>
                  }</NomeRota>
                  <ContainerPerfil>


                    {/*  <Dropdown
                      trigger={['click']}
                      overlay={menu}
                      animation="slide-up"
                      onVisibleChange={this.onVisibleChange}
                    > */}
                    {/*   <ButtonVariosNovos backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                     
                      <TextNovoMenuLink colorDinamico={this.props.letraBotaoPadrao}to="/atendimentos" onClick={() => {

                        this.props.modificaVisibilidadeModalNovoAtendimento(true)

                      }}>Novo Atendimento</TextNovoMenuLink>

                     
                    </ButtonVariosNovos> */}
                    <ButtonAddNew style={{ width: 150 }} onClick={() => {
                      this.props.modificaVisibilidadeModalNovoAtendimento(true)
                    }} to="/atendimentos" backgroundbotao={this.props.corSegundoBotaoPadrao} hoverbotao={this.props.coresLetraPadrao}>
                      <IconeVariosNovos className="icon-add2-1" colorPrimario={this.props.letraBotaoPadrao} />
                      <p style={{
                        color: this.props.letraBotaoPadrao,
                        fontSize: 13,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        textDecoration: 'none'
                      }} >Novo Atendimento</p>
                      <div style={{ width: 15 }}></div>
                    </ButtonAddNew>
                    {/* </Dropdown> */}


                    <ButtonNotificacao type="button" onClick={() => { this._toggleModalNotif(); this.props.enviaTotalNotificacoesParaReducerExport(0) }}>
                      {this.renderTextTotalNotif()}


                      <IconeNotificacao className="icon-sino" colorSecundario={this.props.coresLetraPadrao} colorPrimario={this.props.corSegundoBotaoPadrao} />
                    </ButtonNotificacao>
                    {this.state.modalNotif == true && <BackgroundModal onClick={() => { this._toggleModalNotif(); }}>

                    </BackgroundModal>}
                    {this.state.modalNotif == true &&

                      <ContainerNotif>
                        <HeaderNotif>
                          <TextNotification>Notificações</TextNotification>
                          {this.props.notificacoes.length > 0 && <TextMarcaLido onClick={() => { this.props.marcarTodasNotifComoLido(this.props.notificacoes) }}>Marcar como lido</TextMarcaLido>}
                        </HeaderNotif>
                        <SubContainerNotif>
                          <FlatList
                            list={this.props.notificacoes}
                            renderItem={(item) => {
                              console.log('no notificacoes app')
                              console.log(item)
                              if (item.viaChat === 'true') {
                                return (
                                  <ContainerMensagemChat onClick={() => {
                                    firebase.database().ref(`${item.chaveUnica}/notificacoes/${item.key}`).update({ lido: "true" });
                                    /*   firebase.database().ref(`${item.chaveUnica}/usuarios/${item.keyUsuario}/notificacoes/${item.key}`).update({ lido: "true" }); */
                                    this.props.navegaTelaALterarChamadoNotificacao(item.keyChamado, item.numeroChamado);
                                    this.props.modificaNomeRotaNoHeader(`Atendimento #${item.numeroChamado}`);
                                    this._toggleModalNotif();
                                    // window.open(`/visualizaatendimento?key=${item.keyChamado}&num=${item.numeroChamado}`, '_blank');
                                  }} key={item.key}>

                                    <ContainerImagem>
                                      {this.renderImagemNotif(item)}
                                    </ContainerImagem>
                                    <ContainerMensagemTexto>
                                      <ContainerTitle>
                                        <TitleNotif>
                                          {item.title}
                                        </TitleNotif>
                                        <SubTitleNotif>
                                          {item.body}
                                          {/*  */}
                                        </SubTitleNotif>
                                      </ContainerTitle>
                                      <ContainerDataNotif>
                                        <TextData>{item.dataAtual}</TextData>
                                        {
                                          item.lido == "false" &&
                                          <div style={{
                                            display: 'flex', flexDirection: 'row', height: 15, width: 35, backgroundColor: '#96CAE8', borderRadius: 15, marginBottom: 2,
                                            justifyContent: 'center', alignItems: 'center'
                                          }}>
                                            <i className="icon-star" style={{ color: '#0076B3', fontSize: 6 }} />
                                            <TextNew>Novo</TextNew>
                                          </div>}
                                      </ContainerDataNotif>
                                    </ContainerMensagemTexto>
                                  </ContainerMensagemChat>


                                )
                              } else {
                                return (

                                  <ContainerMensagem to="visualizaatendimento" onClick={() => {
                                    firebase.database().ref(`${item.chaveUnica}/notificacoes/${item.key}`).update({ lido: "true" });
                                    /*   firebase.database().ref(`${item.chaveUnica}/usuarios/${item.keyUsuario}/notificacoes/${item.key}`).update({ lido: "true" }); */
                                    this.props.navegaTelaALterarChamadoNotificacao(item.keyChamado, item.numeroChamado);
                                    this.props.modificaNomeRotaNoHeader(`Atendimento #${item.numeroChamado}`);
                                    this._toggleModalNotif();
                                    // window.open(`/visualizaatendimento?key=${item.keyChamado}&num=${item.numeroChamado}`, '_blank');
                                  }} key={item.key}>

                                    <ContainerImagem>
                                      {this.renderImagemNotif(item)}
                                    </ContainerImagem>
                                    <ContainerMensagemTexto>
                                      <ContainerTitle>
                                        <TitleNotif>
                                          {item.title}
                                        </TitleNotif>
                                        <SubTitleNotif>
                                          {item.body}
                                          {/*  */}
                                        </SubTitleNotif>
                                      </ContainerTitle>
                                      <ContainerDataNotif>
                                        <TextData>{item.dataAtual}</TextData>
                                        {
                                          item.lido == "false" &&
                                          <div style={{
                                            display: 'flex', flexDirection: 'row', height: 15, width: 35, backgroundColor: '#96CAE8', borderRadius: 15, marginBottom: 2,
                                            justifyContent: 'center', alignItems: 'center'
                                          }}>
                                            <i className="icon-star" style={{ color: '#0076B3', fontSize: 6 }} />
                                            <TextNew>Novo</TextNew>
                                          </div>}
                                      </ContainerDataNotif>
                                    </ContainerMensagemTexto>
                                  </ContainerMensagem>

                                )
                              }
                            }
                            }
                            renderWhenEmpty={() => {
                              return (
                                <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                  <SubTitleNotif>Você não tem notificações</SubTitleNotif>
                                </div>
                              )
                            }}
                          // hasMoreItems={this.props.loadingFimDaPagina}
                          //loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                          /* paginationLoadingIndicator={<MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={this.props.loadingFimDaPagina}
                          />} */
                          />
                        </SubContainerNotif>
                      </ContainerNotif>}
                    <DivNomeEmail>
                      <TextNome colorDinamico={this.props.coresLetraPadrao}>{this.props.nome}</TextNome>
                      <TextEmail colorDinamico={this.props.coresLetraSecundario}>{this.props.email}</TextEmail>
                    </DivNomeEmail>
                    <Dropdown
                      trigger={['click']}
                      overlay={menuPerfil}
                      animation="slide-up"
                      onVisibleChange={this.onVisibleChange}
                    >
                      {this.renderImagemPerfil()}
                    </Dropdown>

                  </ContainerPerfil>
                </HeaderPadrao>
                {this.props.mostraVersaoExpirando == true && <div onClick={() => this.props.ativaDesativaRenovarPLano(true)} style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', paddingVertical: 7, backgroundColor: '#FFFFDD' }}>
                  <span style={{ color: '#000', fontSize: 14, fontWeight: 'bold' }}>Sua Versão Premium EXPIRARÁ em menos de 3 dias, <Link style={{ color: '#39A3F4' }} to="/versaopremium">clique aqui</Link> para escolher um novo plano e gerar um novo boleto para renovar.</span>
                </div>}
                <PageBody corBackgroundDinamico={this.props.backgroundPrimeiro}>
                  <Switch>

                    <PrivateRoute exact path='/atendimentos' component={Atendimentos} />
                    <PrivateRoute exact path='/visualizaatendimento' component={AcoesChamado} />
                    <PrivateRoute exact path='/visualizaatendimentoempresa' component={AcoesChamadoSolicitacoesEmpresa} />

                    <PrivateRoute exact path='/perfil' component={Clientes} />
                    <PrivateRoute exact path='/notificacoes' component={Notificacoes} />
                    <PrivateRoute exact path='/chamadoaberto' component={ChamadoAberto} />
                    <PrivateRoute exact path='/chamadoatendendo' component={ChamadoAtendendo} />
                    <PrivateRoute exact path='/chamadofechado' component={ChamadoFechado} />
                    <PrivateRoute exact path='/chamadocancelado' component={ChamadoCancelado} />
                    <PrivateRoute exact path='/chamadotodos' component={ChamadoTodos} />
                    <Route path='*' component={NotFound} />
                  </Switch>
                </PageBody>

              </SubContainer>
              {/* <Detector
                  polling={polling} 
                render={({ online }) => {
                  // console.log('no verificar online' + online) 
                  if (online) {
                    let conexaoInternetOffline = JSON.parse(localStorage.getItem('internetConnectionDropDeskCliente')) == null ? false : JSON.parse(localStorage.getItem('internetConnectionDropDeskCliente'));
                    // console.log(conexaoInternetOffline) 
                    if (conexaoInternetOffline == true) {
                      return (
                        <BackgroundSemInternet>
                          <ContainerSemInternetLaranja>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Você foi desconectado do DropDesk.</p>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Sem conexão com a INTERNET.</p>
                            <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Recarregue a página para voltar a se conectar.</p>
                          </ContainerSemInternetLaranja>
                          <ContainerSemInternet>
                            <p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Não foi possível conectar ao DropDesk.</p>
                            <div onClick={() => { window.location.reload(); }} style={{ height: 30, width: 160, backgroundColor: '#CD4A42', alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 5, cursor: 'pointer' }}><p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Recarregar Página</p></div>
                          </ContainerSemInternet>
                        </BackgroundSemInternet>
                      );
                    } else {

                      return null;
                    }

                  } else {
                    localStorage.setItem('internetConnectionDropDeskCliente', JSON.stringify(true));
                    return (
                      <BackgroundSemInternet>
                        <ContainerSemInternetLaranja>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Você foi desconectado do DropDesk.</p>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Sem conexão com a INTERNET.</p>
                          <p style={{ fontSize: 18, color: '#000', fontWeight: 'bold', textAlign: 'left' }}>Recarregue a página para voltar a se conectar.</p>
                        </ContainerSemInternetLaranja>
                        <ContainerSemInternet>
                          <p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Não foi possível conectar ao DropDesk.</p>
                          <div onClick={() => { window.location.reload(); }} style={{ height: 30, width: 160, backgroundColor: '#CD4A42', alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: 5, cursor: 'pointer' }}><p style={{ fontSize: 18, color: '#FFF', fontWeight: 'bold', textAlign: 'left' }}>Recarregar Página</p></div>
                        </ContainerSemInternet>
                      </BackgroundSemInternet>
                    );
                  }
                }}

              /> */}
              <ReactTooltip className={cssToolTip} id="tip-top" place="top" effect="solid" multiline />
              <ReactTooltip className={cssToolTip} id="tip-right" place="right" effect="solid" multiline />
              <Modal focusTrapped={false} showCloseIcon={false} open={this.props.sairDoDropDesk} onClose={() => { }} center>
                <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                  <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Sair do Sistema</TextNovoAtendimento>
                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaModalSair(false) }} className="icon-close" />
                  </ContainerIconeFecharModal>

                  <ContainerAtendentes>
                    <DivTextoReabrirAtendimento >
                      <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente sair do sistema?</TextNovoAtendimento>
                    </DivTextoReabrirAtendimento>
                    <DivInputMotivoCancelamento>

                      <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaModalSair(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundbotao={this.props.backgroundPrimeiro}>
                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                      </ButtonNaoCancelarAtendimento>
                      <ButtonCancelarAtendimento onClick={() => {
                        this.props.modificaModalSair(false);
                        this.props.sairDoApp();
                      }} backgroundbotao={this.props.corBotaoPadrao}>
                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                      </ButtonCancelarAtendimento>
                    </DivInputMotivoCancelamento>
                  </ContainerAtendentes>
                </ContainerModalPrioridadeAtendimento>
              </Modal>
              <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalMensagemExcluida} onClose={() => { }} center>
                <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                  <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Apagar Mensagem</TextNovoAtendimento>
                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                      this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                    }} className="icon-close" />
                  </ContainerIconeFecharModal>

                  {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                  <ContainerAtendentes>
                    <DivTextoReabrirAtendimento >
                      <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Apagar a mensagem?</TextNovoAtendimento>
                    </DivTextoReabrirAtendimento>
                    <DivInputMotivoCancelamento>

                      <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundbotao={this.props.backgroundPrimeiro}>
                        <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                      </ButtonNaoCancelarAtendimento>
                      <ButtonCancelarAtendimento backgroundbotao={this.props.corBotaoPadrao} onClick={() => {
                        console.log(this.state.dadosMensagemExcluida.key)
                        console.log(this.props.acoesDoAtendimentoChat[0].key)
                        let isLastMessage = this.state.dadosMensagemExcluida.key === this.props.acoesDoAtendimentoChat[0].key ? true : false;
                        this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.dadosAtendimentosChat[0].key, true, isLastMessage)
                        this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })
                        /*   this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.dadosAtendimentosChat[0].key, false, [])
                          this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) */

                      }} backgroundBotao={this.props.corBotaoPadrao}>
                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                      </ButtonCancelarAtendimento>
                    </DivInputMotivoCancelamento>
                  </ContainerAtendentes>


                </ContainerModalGravarAudio>
              </Modal>
              {this.state.divEnviarAudioChat == true && < div className="divGravarAudio2"

                style={{
                  display: 'flex', flexDirection: 'column', zIndex: 9988, height: 365, width: 330, position: 'absolute', bottom: 80,
                  right: 70, borderRadius: 5, boxShadow: '0.5rem 0.5rem 0.5rem 0 rgba(0, 0, 0, 0.2)',

                }}>
                <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                  <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                    <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Gravar Áudio</TextNovoAtendimento>
                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                      this.onStopRecord(true)
                      this._toggleModalGravarAudio()

                    }} className="icon-close" />
                  </ContainerIconeFecharModal>


                  <div style={{ backgroundColor: this.state.statusAudio == 'gravando' ? '#EC6A69' : this.props.backgroundPrimeiro }} >


                    {this.state.statusAudio !== 'gravacao' && this.state.statusAudio !== 'executandoAudio' && <div style={{ display: 'flex', height: 180, alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{
                        color: this.props.coresLetraPadrao,
                        fontSize: 45,
                        fontWeight: '200',
                        letterSpacing: 3,
                      }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>

                    </div>}
                    <div style={{
                      display: 'flex',
                      height: 50,
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      marginLeft: 5,
                    }}>
                      <div></div>
                      {this.renderTextGravacaoAudio()}
                      <div ></div>
                    </div>
                    {this.renderBotaoGravarAudio()}
                  </div>


                </ContainerModalGravarAudio>
              </div>}
              {this.props.atendimentoOnlineVisible == false && window.location.pathname !== '/' && window.location.pathname !== '/cadastro' && this.renderAtendimentoOnlineOffline()}
              {this.props.atendimentoOnlineVisible == true &&
                this.renderConteudoChat()}
              {this.props.telaChatOffline == true &&
                this.renderConteudoChatOffline()}
            </Container>
          </Switch>

        </AuthProvider>
      </BrowserRouter >

    );
  }
}
const mapStateToProps = state => {
  return (
    {
      backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
      backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
      backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
      coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
      coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
      corFundoMenu: state.CoresPadraoSistema.corFundoMenu,
      corLetraMenu: state.CoresPadraoSistema.corLetraMenu,

      corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
      corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
      letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
      nomeRota: state.HeaderReducer.nomeRota,
      premium: state.PremiumReducer.premium,
      verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
      contaPrincipal: state.AtendenteReducer.contaPrincipal,
      clientesNovoChamado: state.ChamadoReducer.clientesNovoChamado,
      atendenteNovoChamado: state.ChamadoReducer.atendenteNovoChamado,
      setorNovoChamado: state.ChamadoReducer.setorNovoChamado,
      abrirMenu: state.HeaderReducer.abrirMenu,
      logotipo: state.CoresPadraoSistema.logotipo,
      valorAvaliacaoTodasAvaliacoes: state.AvaliacaoReducer.valorAvaliacaoTodasAvaliacoes,
      clientesTodasAvaliacoes: state.AvaliacaoReducer.clientesTodasAvaliacoes,
      atendenteTodasAvaliacoes: state.AvaliacaoReducer.atendenteTodasAvaliacoes,
      notificacoes: state.DashBoardReducer.notificacoes,
      totalNotificacoes: state.DashBoardReducer.totalNotificacoes,
      usuarioAdministrador: state.AtendenteReducer.usuarioAdministrador,
      email: state.PerfilReducer.email,
      nome: state.PerfilReducer.nome,
      foto: state.PerfilReducer.foto,

      dataValidoAte: state.PremiumReducer.dataValidoAte,
      statusAssinatura: state.PremiumReducer.statusAssinatura,
      mostraVersaoExpirando: state.PremiumReducer.mostraVersaoExpirando,
      nomeAuxSetor: state.ChamadoReducer.nomeAuxSetor,
      //  permissaoAdministrador: state.AtendenteReducer.permissaoAdministrador,
      // permissaoAlterarCadastrarCliente: state.AtendenteReducer.permissaoAlterarCadastrarCliente,
      // permissaoAlterarCadastrarAtendente: state.AtendenteReducer.permissaoAlterarCadastrarAtendente,
      //  permissaoAlterarCadastrarSetores: state.AtendenteReducer.permissaoAlterarCadastrarSetores,
      //  permissaoAlterarCadastrarAvisos: state.AtendenteReducer.permissaoAlterarCadastrarAvisos,
      //  permissaoAcessoRelatorios: state.AtendenteReducer.permissaoAcessoRelatorios,
      permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
      usuarioAdministrador: state.AtendenteReducer.usuarioAdministrador,
      textStatusAssinaturaDrawer: state.PremiumReducer.textStatusAssinaturaDrawer,
      corTextStatusAssinaturaDrawer: state.PremiumReducer.corTextStatusAssinaturaDrawer,
      licensawebouapp: state.PremiumReducer.licensawebouapp,
      sairDoDropDesk: state.DashBoardReducer.sairDoDropDesk,
      descricaoAtendimento: state.ChamadoReducer.descricaoAtendimento,
      verificaAgrupaAtendenteSetor: state.PremiumReducer.verificaAgrupaAtendenteSetor,
      criarChamadoProblemaEstatico: state.PremiumReducer.criarChamadoProblemaEstatico,
      problemasEstatico: state.ChamadoReducer.problemasEstatico,
      problemaEstaticoSelecionado: state.ChamadoReducer.problemaEstaticoSelecionado,
      nomeProblemaEstaticoSelecionado: state.ChamadoReducer.nomeProblemaEstaticoSelecionado,
      setorSelecionado: state.ChamadoReducer.setorSelecionado,
      loadingBotaoCadastrarChamado: state.ChamadoReducer.loadingBotaoCadastrarChamado,
      telaAtualChat: state.ChamadoReducer.telaAtualChat,
      nomeEmpresa: state.ConfiguracaoReducer.nomeEmpresa,
      acoesDoAtendimentoChat: state.ChamadoReducer.acoesDoAtendimentoChat,
      corPrincipalChat: state.CoresPadraoSistema.corPrincipalChat,
      verificaSeJaFezAvaliacaoChamadoChat: state.ChamadoReducer.verificaSeJaFezAvaliacaoChamadoChat,
      keyAtendimentoChat: state.ChamadoReducer.keyAtendimentoChat,
      comentarioChat: state.ChamadoReducer.comentarioChat,
      dadosAtendimentosChat: state.ChamadoReducer.dadosAtendimentosChat,
      verificaSeTemPermissaoParaAvaliarChamadoChat: state.ChamadoReducer.verificaSeTemPermissaoParaAvaliarChamadoChat,

      upandoArquivoChat: state.ChamadoReducer.upandoArquivoChat,
      textArquivoChat: state.ChamadoReducer.textArquivoChat,
      comentarioAvaliacao: state.ChamadoReducer.comentarioAvaliacao,
      atendimentoOnlineVisible: state.ChamadoReducer.atendimentoOnlineVisible,
      posicaoFilaChat: state.ChamadoReducer.posicaoFilaChat,
      chatOnlineOuOffline: state.ChamadoReducer.chatOnlineOuOffline,
      telaChatOffline: state.ChamadoReducer.telaChatOffline,

      horarioOperacaoSegundaFeira: state.ConfiguracaoReducer.horarioOperacaoSegundaFeira,
      horarioOperacaoSegundaFeira2: state.ConfiguracaoReducer.horarioOperacaoSegundaFeira2,
      horarioOperacaoTercaFeira: state.ConfiguracaoReducer.horarioOperacaoTercaFeira,
      horarioOperacaoQuartaFeira: state.ConfiguracaoReducer.horarioOperacaoQuartaFeira,
      horarioOperacaoQuintaFeira: state.ConfiguracaoReducer.horarioOperacaoQuintaFeira,
      horarioOperacaoSextaFeira: state.ConfiguracaoReducer.horarioOperacaoSextaFeira,
      horarioOperacaoSabadoFeira: state.ConfiguracaoReducer.horarioOperacaoSabadoFeira,
      horarioOperacaoDomingoFeira: state.ConfiguracaoReducer.horarioOperacaoDomingoFeira,
      horarioOperacaoTercaFeira2: state.ConfiguracaoReducer.horarioOperacaoTercaFeira2,
      horarioOperacaoQuartaFeira2: state.ConfiguracaoReducer.horarioOperacaoQuartaFeira2,
      horarioOperacaoQuintaFeira2: state.ConfiguracaoReducer.horarioOperacaoQuintaFeira2,
      horarioOperacaoSextaFeira2: state.ConfiguracaoReducer.horarioOperacaoSextaFeira2,
      horarioOperacaoSabadoFeira2: state.ConfiguracaoReducer.horarioOperacaoSabadoFeira2,
      horarioOperacaoDomingoFeira2: state.ConfiguracaoReducer.horarioOperacaoDomingoFeira2,
      segundaFeiraChat: state.ConfiguracaoReducer.segundaFeiraChat,
      tercaFeiraChat: state.ConfiguracaoReducer.tercaFeiraChat,
      quartaFeiraChat: state.ConfiguracaoReducer.quartaFeiraChat,
      quintaFeiraChat: state.ConfiguracaoReducer.quintaFeiraChat,
      sextaFeiraChat: state.ConfiguracaoReducer.sextaFeiraChat,
      sabadoChat: state.ConfiguracaoReducer.sabadoChat,
      domingoChat: state.ConfiguracaoReducer.domingoChat,
      switchUtilizar2HorariosChat: state.ConfiguracaoReducer.switchUtilizar2HorariosChat,
      fusoHorarioSelelcionado: state.ConfiguracaoReducer.fusoHorarioSelelcionado,
      loadingEnviandoAudioChat: state.ChamadoReducer.loadingEnviandoAudioChat,

      loadingCarregandoMaisConversasChat: state.ChamadoReducer.loadingCarregandoMaisConversasChat,
      referenciaUltimaKeyChatApp: state.ChamadoReducer.referenciaUltimaKeyChatApp,
      arrayConversasAntigasChat: state.ChamadoReducer.arrayConversasAntigasChat,
      loadingCarregandoRespostasAntigas: state.ChamadoReducer.loadingCarregandoRespostasAntigas,
      idRespostaAntiga: state.ChamadoReducer.idRespostaAntiga,

      isMaintenance: state.LoginReducer.isMaintenance,
      isNewVersionAvailable: state.LoginReducer.isNewVersionAvailable,
      lastVersionAvailableCLI: state.LoginReducer.lastVersionAvailableCLI,

    }
  );
};
export default connect(mapStateToProps, {
  modificaNomeRotaNoHeader, modificaPesquisaTeste,
  modificaVisibilidadeModalNovoAtendimento,

  modificaModalFiltroChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
  modificaValorArraySectionedFiltroChamado, modificaFiltroPorDataDashboard, insereDataFiltroDashboard,
  modificaFiltroClientesTelaClientes, modificaVisibleModalNovoCLiente,
  modificaModalSair, listaSetorTelaNovoChamado, modificaAberturaMenu,
  zeraInformacoesCadastroClienteExport,
  acessouMeuPErfil, sairDoApp, enviaTotalNotificacoesParaReducerExport, marcarTodasNotifComoLido,
  navegaTelaALterarChamadoNotificacao, modificaModalDuvidasFrequentes, ativaDesativaRenovarPLano, modificaMostraVersaoExpirando,
  listaSetorTelaNovoChamado, listaProblemasEstaticosTelaNovoChamado,
  modificaProblemaEstaticoSelecionado, modificaNomeProblemaEstaticoSelecionado,
  modificaNomeSetorSelecionado, modificaSetorSelecionado, cadastraNovoAtendimento, alteraDescricaoChamado,
  alteraComentarioChat, salvarComentario2, uploadArquivoChat, atribuiStatusAtendimento, modificaComentarioAvaliacao,
  cadastraAvaliacaoChamado, modificaTelaAtualChatExport, modificaAtendimentoOnlineVisible, modificaVisibilidadeTelaChatOffline,
  ativaDesativaLoadingEnviandoAudioChatExport, ativaLoadingBotaoCadastrarChamadoExport,
  modificaVisibilidadeAtendimentoChatOnlineOuOfflineExport, listaAcoesChamadoConversasChat,
  listaConversasAntigasResponder, enviaConversasAntigasChatExport, enviaReferenciaultimaKeyAcoesChamadoExport,
  deletaMensagemDropDesk
})(App);