import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';
import {
    MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA, ATIVADESATIVALOADINGTELACONFIGEMPRESA,
    ENVIANOMEEMPRESAPARAREDUCER, MODIFICANOMEEMPRESACONFIGURAEMPRESA, ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
    MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO, ATIVADESATIVALOADINGTELACONFIGAVALIACAO, ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
    ENVIAMENSAGEMAVALIACAOPARAREDUCER, ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO, ALTERAVALORSWITCHCONFIGATENDIMENTO,
    ENVIADADOSCONFIGURACAOATENDIMENTO, ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO, MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
    ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO, ALTERAAGRUPAATENDENTESETOR, ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
    ALTERAVALORSWITCHCONFIGCHAMADOESTATICO, MODIFICADESCRICAOPROBLEMAESTATICO, ATIVADESATIVALOADINGBOTAONOVOPROBLEMA,
    ENVIAPROBLEMAPARAPROBLEMASELECIONADO, ENVIAPROBLEMAPARAREDUCER, ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,

    MODIFICACORFUNDOPRINCIPAL, MODIFICACORFUNDOSECUNDARIO, MODIFICACORFUNDOITENSSELECIONAVEIS, MODIFICACORFUNDOBOTAOPRINCIPAL,
    MODIFICACORFUNDOBOTAOSECUNDARIO, MODIFICACORLETRAPADRAO, MODIFICACORLETRAPRINCIPAL, MODIFICACORLETRASECUNDARIA,
    MODIFICACORLETRADESCRICAODOSCAMPOS, MODIFICACORFUNDOMENU, MODIFICACORLETRAMENU,
    ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO, ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
    ALTERASWITCHNOTIFATENDESTOUATENDENDO, ALTERASWITCHNOTIFATENDABERTOPORMIM, VERIFICACRIARCHAMADOPROBLEMAESTATICO,
    MODIFICALOGOTIPOTELACONFIGURACAO, MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO

} from './types';


export const listaNotificacoesMarcadas = () => {
    let notifAtendAberto;
    let notifAtendEstouAtendendo;
    let notifRespAtendAbertoPorMim;
    let notifRespAtendQueEstouAtendendo;


    return dispatch => {
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingConfiguracaoAtendimento(dispatch, true);
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).limitToFirst(1).on("value", snapshot => {
                let key = Object.keys(snapshot.val()).toString()
                if (snapshot.val()) {
                    /* console.log('antes do foreach');
                    console.log(notifRespAtendQueEstouAtendendo); */
                    snapshot.forEach((childItem) => {
                        notifAtendAberto = (childItem.val().notifNovoAtendimento == 1) ? true : false;
                        notifRespAtendAbertoPorMim = (childItem.val().notifRespNosAtendimentos == 1) ? true : false;
                    })
                  /*   console.log('depois do foreach');
                    console.log(notifRespAtendQueEstouAtendendo); */
                    alteraSwitchNotifAtendABertoPorMimLocal(dispatch, notifAtendAberto);
                    alteraSwitchNotifRespAtendAbertoPorMimLocal(dispatch, notifRespAtendAbertoPorMim);
                    ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                } else {

                }
            })
        })
    }
}
export const alteraNomeEmpresa = (nome, backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu, logoTipo, arquivoCompletoLogoTipo) => {
  /*   console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
        corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu, logoTipo, arquivoCompletoLogoTipo) */
    return dispatch => {
        let uriHTTP = 0;
        if (logoTipo === null) {
           // console.log('no tipo uri')
            //é uma imagem local nao precisa upar
            uriHTTP = 1;
        } else if(logoTipo === 'semURI'){
           // console.log('no tipo semURI')
            uriHTTP = 1;
        }else if (logoTipo.includes('/static/media/userPadrao') || logoTipo.includes('data:image/png;base64')) {
            uriHTTP = 1;
        } else if (logoTipo.includes("http") && !logoTipo.includes("blob")) {

            //console.log('segundo if')
            uriHTTP = 1;
        } else if (arquivoCompletoLogoTipo === '') {
           // console.log('arquivos completo para upar vazio')
            uriHTTP = 1;
        } else {
           // console.log('no else')

        }
        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (nome.trim() === '') {
                toastr.error('Error', 'Digite o nome da sua Empresa.');
                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
            }
            else if (nome.trim().length < 2) {
                toastr.error('Error', 'O Nome da empresa deve possuir mais de 1 letra.');
                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
            } else {
                firebase.database().ref(`${chaveUnica}/empresa/`).once('value').then((snapshot) => {
                    let keyEmpresa = [];
                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                            empresa: childItem.val().empresa
                        })
                        keyEmpresa.push({
                            key: childItem.key,
                        })
                    })
                /*     console.log(dadosEmpresa[0].key)
                    console.log(snapshot.val()) */
                    firebase.database().ref(`${chaveUnica}/empresa/${dadosEmpresa[0].key}`).update({
                        empresa: nome
                    }).then(() => {
                        firebase.database().ref(`${chaveUnica}/configuracao/cores`).once('value').then((snapshot) => {
                            if (snapshot.val()) {
                                const dadosEmpresa = [];
                                snapshot.forEach(childItem => {
                                    dadosEmpresa.push({
                                        key: childItem.key,
                                    })
                                })
                              /*   console.log(dadosEmpresa[0].key)
                                console.log(snapshot.val()) */
                                firebase.database().ref(`${chaveUnica}/configuracao/cores/${dadosEmpresa[0].key}`).update({
                                    backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu
                                }).then(() => {

                                    //logotipo metodo 1
                                    if (uriHTTP == 1) {
                                        toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                    } else {
                                       /*  console.log('COM LOGOTIPO') */
                                        // let uri = uriImagemAtendente.replace('file://', '');
                                    /*     console.log(chaveUnica)
                                        console.log(dadosEmpresa[0].key) */
                                        let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyEmpresa[0].key}/logotipo.jpg`);
                                        let mime = 'image/jpeg';

                                        caminhoPadraoImagem.put(arquivoCompletoLogoTipo).then(
                                            (url) => {
                                                url.ref.getDownloadURL().then(function (downloadURL) {
                                                firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).once('value').then((snapshot) => {
                                                    if (snapshot.val()) {
                                                      /*   console.log('com caminho logotipo') */
                                                        const dadosConfiguracaoAtendimento = [];
                                                        snapshot.forEach(childItem => {
                                                            dadosConfiguracaoAtendimento.push({
                                                                key: childItem.key,
                                                            })
                                                        })
                                                        firebase.database().ref(`${chaveUnica}/configuracao/logotipo/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                            uri: downloadURL
                                                        }).then(() => {
                                                            toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                            dispatch({
                                                                type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                payload: ''
                                                            })
                                                            ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                        })

                                                    } else {
                                                     /*    console.log('sem caminho logotipo')
                                                        console.log(urImagemDownload.downloadURL) */
                                                        firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).push({
                                                            uri: downloadURL
                                                        }).then(() => {
                                                            dispatch({
                                                                type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                payload: ''
                                                            })
                                                            toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                            ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                        })
                                                    }
                                                })
                                            })
                                            }).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                /* console.log(error) */
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                    }

                                })
                            } else {
                                firebase.database().ref(`${chaveUnica}/configuracao/cores`).push({
                                    backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                                    corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu
                                }).then(() => {
                                    //logotipo metodo 2

                                    if (uriHTTP == 1) {
                                        toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                    } else {
                                    /*     console.log('COM LOGOTIPO') */
                                        // let uri = uriImagemAtendente.replace('file://', '');
                                        let caminhoPadraoImagem = firebase.storage().ref(`${chaveUnica}/${keyEmpresa[0].key}/logotipo.jpg`);
                                        let mime = 'image/jpeg';

                                        caminhoPadraoImagem.put(arquivoCompletoLogoTipo).then(
                                            (url) => {
                                                url.ref.getDownloadURL().then(function (downloadURL) {
                                                firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).once('value').then((snapshot) => {
                                                    if (snapshot.val()) {

                                                        const dadosConfiguracaoAtendimento = [];
                                                        snapshot.forEach(childItem => {
                                                            dadosConfiguracaoAtendimento.push({
                                                                key: childItem.key,
                                                            })
                                                        })
                                                        firebase.database().ref(`${chaveUnica}/configuracao/logotipo/${dadosConfiguracaoAtendimento[0].key}`).update({
                                                            uri: downloadURL
                                                        }).then(() => {
                                                            toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                            dispatch({
                                                                type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                payload: ''
                                                            })
                                                            ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);

                                                        })

                                                    } else {
                                                        firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).push({
                                                            uri: downloadURL
                                                        }).then(() => {
                                                            toastr.success('Sucesso', 'Configuração da empresa alterado com SUCESSO!');
                                                            dispatch({
                                                                type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
                                                                payload: ''
                                                            })
                                                            ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                        })
                                                    }
                                                })
                                            })
                                            }).catch((error) => {
                                                ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                                                /* console.log(error) */
                                                //error.code
                                                //error.message
                                                switch (error.code) {
                                                    case 'auth/invalid-email':
                                                        toastr.error('Error', 'Email inválido, digite um email válido, por exemplo: exemplo@exemplo.com.br');
                                                        break;
                                                    case 'auth/email-already-in-use':
                                                        toastr.error('Error', 'Este email já está sendo utilizado, informe outro email.');
                                                        break;
                                                    case 'auth/network-request-failed':
                                                        toastr.error('Error', 'Sem conexão com a internet');
                                                        break;
                                                    default:
                                                        toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                                                        break;
                                                }
                                            })
                                    }

                                })
                            }
                        })
                    })
                })
            }
        })

    }
}
export const alteraConfiguracaoAtendimento = (switchObrigatorioAtribuirAtendente, agruparAtendenteSetor, atendenteVeApenasProprioChamado, verificaUtilizaProblemaEstatico, problemasSelecionados, problemasEstaticos) => {
    return dispatch => {
/*         console.log(switchObrigatorioAtribuirAtendente, agruparAtendenteSetor, atendenteVeApenasProprioChamado, verificaUtilizaProblemaEstatico, problemasSelecionados, problemasEstaticos) */
        ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/setores`).once("value").then((snapshotEmp) => {
                let existeSetorCadastro;
                if (snapshotEmp.val()) {
                    existeSetorCadastro = true;
                } else {
                    existeSetorCadastro = false;
                }
                if (agruparAtendenteSetor == true && existeSetorCadastro == false) {
                    toastr.error('Error', 'Para utilizar a opção "Agrupar atendente á setores" é preciso ter no minimo 1 setor cadastrado, cadastre um setor e tente novamente.');
                    ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                } else if (verificaUtilizaProblemaEstatico == true && problemasEstaticos.length <= 0) {
                    toastr.error('Error', 'Para utilizar a opção "Utilizar Problema Pré Definido" é preciso ter no minimo 1 problema cadastrado, clique em "Novo Problema" e cadastre um problema.');
                    ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                }
                else if (verificaUtilizaProblemaEstatico == true && problemasSelecionados.length <= 0) {
                    toastr.error('Error', 'Para utilizar a opção "Utilizar Problema Pré Definido" é preciso ter no minimo 1 problema selecionado, selecione um problema.');
                    ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                }
                else {
                    firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).once('value').then((snapshot) => {

                        const dadosConfiguracaoAtendimento = [];
                        snapshot.forEach(childItem => {
                            dadosConfiguracaoAtendimento.push({
                                key: childItem.key,
                            })
                        })
                        /* console.log(dadosConfiguracaoAtendimento[0].key)
                        console.log(snapshot.val()) */
                        firebase.database().ref(`${chaveUnica}/configuracao/atendimento/${dadosConfiguracaoAtendimento[0].key}`).update({
                            atribuirAtendenteObrigatorio: switchObrigatorioAtribuirAtendente,
                            agruparAtendenteSetor: agruparAtendenteSetor,
                            atendenteVeApenasProprioChamado: atendenteVeApenasProprioChamado,
                            criarChamadoProblemaEstatico: verificaUtilizaProblemaEstatico,
                        }).then(() => {
                            if (verificaUtilizaProblemaEstatico == true) {
                                // let result = problemasEstaticos.filter(o1 => problemasSelecionados.some(o2 => o1.id !== o2.id));
                               /*  console.log('no result'); */

                                firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().once('value').then((snapshot) => {
                                   /*  console.log('no lista problemas estaticos')
                                    console.log(snapshot.val()) */
                                    if (snapshot.val() == null) {
                                       /*  console.log('NAO EH PRA CHEGAR AQUI PROBLEMA') */
                                    } else {
                                        let arrayProblemas = [];
                                        let problemasSelecionadosAlterado = [];
                                        let arrayDeProblemasQueNaoEstaoSelecionado = [];

                                        snapshot.forEach(childItem => {

                                            arrayProblemas.push({
                                                value: childItem.key,
                                            });

                                        });

                                        for (let i = 0; i < problemasSelecionados.length; i++) {
                                            problemasSelecionadosAlterado.push({
                                                value: problemasSelecionados[i]
                                            })
                                        }
                                      /*   console.log(arrayProblemas)
                                        console.log(problemasSelecionadosAlterado) */
                                        // let result = arrayProblemas.filter(item => !problemasSelecionados.includes(item.id));
                                        //   let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id != o2.id));
                                        //  let result = arrayProblemas.filter(o1 => problemasSelecionadosAlterado.some(o2 => o1.id !== o2.id));   
                                        //    console.log(result)
                                        toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                        ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                        let aux = 0;
                                        for (let i = 0; i < arrayProblemas.length; i++) {
                                            aux++;
                                            let valorExistente = 0;
                                            for (let o = 0; o < problemasSelecionadosAlterado.length; o++) {
                                                if (arrayProblemas[i].value == problemasSelecionadosAlterado[o].value) {
                                                    valorExistente = 1;
                                                    break;
                                                }
                                            }
                                            if (valorExistente == 1) {
                                           /*      console.log(arrayProblemas[i].value) */
                                                firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                    selecionado: 1
                                                }).then(() => {
                                                    if (aux == arrayProblemas.length) {
                                                       /*  console.log('Terminou'); */
                                                        toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                        ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                    }
                                                })
                                            } else {
                                                /* console.log(arrayProblemas[i].value) */
                                                firebase.database().ref(`${chaveUnica}/problemaEstatico/${arrayProblemas[i].value}`).update({
                                                    selecionado: 0
                                                }).then(() => {
                                                    if (aux == arrayProblemas.length) {
                                                        /* console.log('Terminou'); */
                                                        toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                                        ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                    }
                                                })
                                            }
                                        }

                                        /*  if (result.length == arrayProblemas.length) {
                                             //tudo ok, nada foi alterado
                                             console.log('tudo ok, nada foi alterado')
                                             mensagemSemConexaoInternetConfiguracaoAtendimento(dispatch, 'Configuração de Atendimento alterado com SUCESSO!!');
                                             ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                         } else {
                                             console.log('alterado, tenho que alterar no banco');
                                             let aux = 0;
                                             for (let i = 0; i < result.length; i++) {
                                                 firebase.database().ref(`${chaveUnica}/problemaEstatico/${result[i].id}`).update({
                                                     selecionado: 0
                                                 }).then(() => {
                                                     aux++;
                                                     if (aux == result.length) {
                                                         console.log('Terminou');
                                                         mensagemSemConexaoInternetConfiguracaoAtendimento(dispatch, 'Configuração de Atendimento alterado com SUCESSO!!');
                                                         ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                                                     }
                                                 })
                                             }
                                         } */


                                    }
                                });

                            } else {
                                toastr.success('Sucesso', 'Configuração de Atendimento alterado com SUCESSO!!');
                                ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento(dispatch, false);
                            }

                        })
                    })
                }
            })



        }
        )
    }
}
export const cadastraProblemaEstatico = (descricaoProblema2, problemasAnteriores) => {
    const descricaoProblema = descricaoProblema2.trim();
    /* console.log(descricaoProblema) */
    return dispatch => {
        // 
        if (descricaoProblema.trim() === '') {
            toastr.error('Error', 'Digite a Descrição Do Problema Estático.');
        }
        else {
            ativaDesativaLoadingBotaoNovoProblema(dispatch, true);
            AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
                const chaveUnica = chaveUnica2.replace(/"/g, "");
                firebase.database().ref(`${chaveUnica}/problemaEstatico`).push({ descricaoProblema: descricaoProblema, selecionado: 1 }).then(
                    (dadosProblema) => {
                     /*    console.log(dadosProblema); */
                        const children2 = [];
                        children2.push({
                            value: dadosProblema.key,
                            name: descricaoProblema
                        });
                      /*   console.log(children2)
                        console.log(problemasAnteriores) */
                        const items = [{ children: children2 }];
                        // enviaProblemasParaReducer(dispatch,items)
                        //    enviaProblemaParaProblemaSelecionado(dispatch,items)
                      /*   console.log('Problema cadastrado com sucesso'); */
                        toastr.success('Sucesso', 'Problema Cadastrado com SUCESSO!');
                        zeraDescricaoProblemaCadastroSucesso(dispatch, '')
                        ativaDesativaLoadingBotaoNovoProblema(dispatch, false);
                    }
                ).catch((error) => {
                    //error.code
                    //error.message
                    ativaDesativaLoadingBotaoNovoProblema(dispatch, false);
                    switch (error.code) {
                        case 'auth/network-request-failed':
                            toastr.error('Error', 'Sem conexão com a internet');
                            break;
                        default:
                            toastr.error('Error', 'Erro ao cadastrar, tente novamente mais tarde');
                            break;
                    }
                })
            })
        }


    }
}
export const resetarCores = () => {
    return dispatch => {
        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
            
                let backgroundPrimeiro = '#29454D';
                let backgroundSegundo = '#3F535A';
                let backgroundMaisEscuro = '#223033';
                let coresLetraPadrao = '#FFF';
                let coresLetraSecundario = '#9fadb7';
                let corBotaoPadrao = '#00FF7F';
                let letraBotaoPadrao = '#000';
                let corSegundoBotaoPadrao = '#F0f0f7';
                let corLetraDescricaoDosCampos = '#F0FFF0';
                let corFundoMenu = '#1d1d1d';
                let corLetraMenu = '#FFF';
                if (snapshot.val()) {
                    let key = Object.keys(snapshot.val()).toString()
                  /*   console.log(key)
                    console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                        corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */
                    firebase.database().ref(`${chaveUnica}/configuracao/cores/${key}`).update({
                        backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                        corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu
                    }).then(() => {

                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        toastr.success('Sucesso', 'Cores resetadas com SUCESSO!');
                        ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa(dispatch, false);
                    })
                    //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                } else {
                    toastr.success('Sucesso', 'Cores resetadas com SUCESSO!');
                    ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                }
            })
        })
    }
}
export const listaConfiguracoes = () => {
    return dispatch => {
        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/configuracao/atendimento`).on("value", snapshot => {
                /* console.log(snapshot.val()) */
                const dadosConfiguracaoAtendimento = [];
                snapshot.forEach(childItem => {
                    dadosConfiguracaoAtendimento.push({
                        key: childItem.key,
                        atribuirAtendenteObrigatorio: childItem.val().atribuirAtendenteObrigatorio,
                        atendenteVeApenasProprioChamado: childItem.val().atendenteVeApenasProprioChamado,
                        agruparAtendenteSetor: childItem.val().agruparAtendenteSetor,
                        criarChamadoProblemaEstatico: childItem.val().criarChamadoProblemaEstatico == undefined ? false : childItem.val().criarChamadoProblemaEstatico
                    })
                })


                /* console.log(dadosConfiguracaoAtendimento[0].atribuirAtendenteObrigatorio); */
                enviaDadosConfiguracaoAtendimento(dispatch, dadosConfiguracaoAtendimento[0].atribuirAtendenteObrigatorio);
                alteraAgrupaAtendenteSetorLOCAL(dispatch, dadosConfiguracaoAtendimento[0].agruparAtendenteSetor);
                alteraAtendenteVeApenasProprioChamadoLOCAL(dispatch, dadosConfiguracaoAtendimento[0].atendenteVeApenasProprioChamado);
                verificaCriarChamadoProblemaEstatico(dispatch, dadosConfiguracaoAtendimento[0].criarChamadoProblemaEstatico);
                firebase.database().ref(`${chaveUnica}/problemaEstatico`).orderByKey().on("value", snapshot => {
                    /* console.log('no lista problemas estaticos')
                    console.log(snapshot.val()) */
                    if (snapshot.val() == null) {
                        const arrayUser = [];
                        enviaProblemasParaReducer(dispatch, arrayUser)
                        // ativaDesativaLoadingCarregandoProblemas(dispatch, false);
                        // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    } else {
                        let children2 = [];
                        let childrenSelecionado = [];
                        let arrayProblemasSelecionado = [];
                        snapshot.forEach(childItem => {
                            if (childItem.val().selecionado == 1) {
                                /* console.log('problema Selecionado'); */
                                childrenSelecionado.push({
                                    value: childItem.key,
                                });
                                children2.push({
                                    value: childItem.key,
                                    name: childItem.val().descricaoProblema
                                });
                            } else {
                                children2.push({
                                    value: childItem.key,
                                    name: childItem.val().descricaoProblema
                                });
                            }
                        });
                      /*   console.log(children2)
                        console.log(childrenSelecionado) */
                        let result = children2.filter(o1 => childrenSelecionado.some(o2 => o1.value === o2.value));
                       /*  console.log(result) */
                        for (let i = 0; i < result.length; i++) {
                            arrayProblemasSelecionado.push(result[i].value)
                        }
                       /*  console.log(arrayProblemasSelecionado) */
                        const items = [{
                            name: 'PROBLEMAS',
                            type: 'group', items: children2
                        }];
                        //const itemsSelecionado = [{ children: arrayProblemasSelecionado }];
                        enviaProblemasParaReducer(dispatch, items);
                        enviaProblemaParaProblemaSelecionado(dispatch, arrayProblemasSelecionado)

                        ativaDesativaLoadingCarregandoProblemas(dispatch, false);
                        //  ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }
                });
                //aqui
                firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).on("value", snapshot => {

                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                            mensagemPadrao: childItem.val().mensagemPadrao,
                            utilizaAvaliacao: childItem.val().utilizaAvaliacao
                        })
                    })
                   /*  console.log(snapshot.val()) */

                   /*  console.log(dadosEmpresa[0].mensagemPadrao); */
                    //  enviaNomeEmpresReducer(dispatch, dadosEmpresa[0].empresa);
                    enviaMensagemAvaliacaoParaReducer(dispatch, dadosEmpresa[0].mensagemPadrao);
                    enviaSeDesejaMandarMensagemAvaliacaoAposFInalizarAtendimento(dispatch, dadosEmpresa[0].utilizaAvaliacao);
                    // ativaDesativaLoadingTelaConfigAvaliacao(dispatch, false);
                })
                //aqui
                firebase.database().ref(`${chaveUnica}/empresa/`).on("value", snapshot => {
                    let keyEmpresa = [];
                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                            empresa: childItem.val().empresa
                        })
                        keyEmpresa.push({
                            key: childItem.key,
                        })
                    })
                   /*  console.log(snapshot.val())

                    console.log(dadosEmpresa[0].empresa) */
                    enviaNomeEmpresReducer(dispatch, dadosEmpresa[0].empresa);
                    //ativaDesativaLoadingTelaConfigEmpresa(dispatch, false);
                })
                firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).limitToFirst(1).on("value", snapshot => {
                    let key = Object.keys(snapshot.val()).toString()
                    let notifAtendAberto;
                    let notifAtendEstouAtendendo;
                    let notifRespAtendAbertoPorMim;
                    let notifRespAtendQueEstouAtendendo;
                    if (snapshot.val()) {
                        snapshot.forEach((childItem) => {
                            notifAtendAberto = (childItem.val().notifNovoAtend == 1) ? true : false;
                            notifAtendEstouAtendendo = (childItem.val().notifAtendimentoAtribuidoAmim == 1) ? true : false;
                            notifRespAtendAbertoPorMim = (childItem.val().notifRespAtendAbertosPorMim == 1) ? true : false;
                            notifRespAtendQueEstouAtendendo = (childItem.val().notifRespAtendEmQueEstouAtend == 1) ? true : false;
                        })
                        alteraSwitchNotifAtendABertoPorMimLocal(dispatch, notifAtendAberto);
                        alteraSwitchNotifAtendEstouAtendendoLocal(dispatch, notifAtendEstouAtendendo);
                        alteraSwitchNotifRespAtendAbertoPorMimLocal(dispatch, notifRespAtendAbertoPorMim);
                        alteraSwitchNotifRespAtendQueEstouAtendendoLocal(dispatch, notifRespAtendQueEstouAtendendo);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {

                    }
                })
                firebase.database().ref(`${chaveUnica}/configuracao/logotipo`).on("value", snapshot2 => {
                    let uriLogoTipo = [];
                    if (snapshot2.val()) {
                        snapshot2.forEach(childItem => {
                            uriLogoTipo.push({
                                uri: childItem.val().uri,
                            })
                        })
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: uriLogoTipo[0].uri
                        })
                        // ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        dispatch({
                            type: MODIFICALOGOTIPOTELACONFIGURACAO,
                            payload: 'semURI'
                        })
                        //ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }

                })
                firebase.database().ref(`${chaveUnica}/configuracao/cores`).on("value", snapshot => {
                  
                    let backgroundPrimeiro;
                    let backgroundSegundo;
                    let backgroundMaisEscuro;
                    let coresLetraPadrao;
                    let coresLetraSecundario;
                    let corBotaoPadrao;
                    let letraBotaoPadrao;
                    let corSegundoBotaoPadrao;
                    let corLetraDescricaoDosCampos;
                    let corFundoMenu;
                    let corLetraMenu;
                    if (snapshot.val()) {
                        let key = Object.keys(snapshot.val()).toString()
                        snapshot.forEach((childItem) => {
                            backgroundPrimeiro = childItem.val().backgroundPrimeiro;
                            backgroundSegundo = childItem.val().backgroundSegundo;
                            backgroundMaisEscuro = childItem.val().backgroundMaisEscuro;
                            coresLetraPadrao = childItem.val().coresLetraPadrao;
                            coresLetraSecundario = childItem.val().coresLetraSecundario;
                            corBotaoPadrao = childItem.val().corBotaoPadrao;
                            letraBotaoPadrao = childItem.val().letraBotaoPadrao;
                            corSegundoBotaoPadrao = childItem.val().corSegundoBotaoPadrao;
                            corLetraDescricaoDosCampos = childItem.val().corLetraDescricaoDosCampos;
                            corFundoMenu = childItem.val().corFundoMenu;
                            corLetraMenu = childItem.val().corLetraMenu;
                        })
                  /*       console.log(backgroundPrimeiro, backgroundSegundo, backgroundMaisEscuro, coresLetraPadrao, coresLetraSecundario,
                            corBotaoPadrao, letraBotaoPadrao, corSegundoBotaoPadrao, corLetraDescricaoDosCampos, corFundoMenu, corLetraMenu); */


                        dispatch({
                            type: MODIFICACORFUNDOPRINCIPAL,
                            payload: backgroundPrimeiro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOSECUNDARIO,
                            payload: backgroundSegundo
                        })
                        dispatch({
                            type: MODIFICACORFUNDOITENSSELECIONAVEIS,
                            payload: backgroundMaisEscuro
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOPRINCIPAL,
                            payload: corBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORFUNDOBOTAOSECUNDARIO,
                            payload: corSegundoBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPADRAO,
                            payload: letraBotaoPadrao
                        })
                        dispatch({
                            type: MODIFICACORLETRAPRINCIPAL,
                            payload: coresLetraPadrao
                        })

                        dispatch({
                            type: MODIFICACORLETRASECUNDARIA,
                            payload: coresLetraSecundario
                        })
                        dispatch({
                            type: MODIFICACORLETRADESCRICAODOSCAMPOS,
                            payload: corLetraDescricaoDosCampos
                        })
                        dispatch({
                            type: MODIFICACORFUNDOMENU,
                            payload: corFundoMenu
                        })
                        dispatch({
                            type: MODIFICACORLETRAMENU,
                            payload: corLetraMenu
                        })
                        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                        //ativaDesativaLoadingTelaNotificacao(dispatch, false);
                    } else {
                        ativaDesativaLoadingConfiguracaoAtendimento(dispatch, false);
                    }

                })

            })
        })
    }

}
export const alteraConfiguracaoAvaliacao = (mensagem, enviaMensagemAposFinalizarChamado) => {
    return dispatch => {
        ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, true);
        AsyncStorage.getItem("chaveUnica").then(chaveUnica2 => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            if (mensagem.trim() === '') {
                toastr.error('Error', 'Digite a mensagem de avaliação padrão, para ser enviada após finalizar o ATENDIMENTO .');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            }
            else if (mensagem.trim().length < 2) {
                toastr.error('Error', 'A Mensagem de Avaliação Padrão deve possuir mais de 1 letra.');
                ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
            } else {
                firebase.database().ref(`${chaveUnica}/configuracao/avaliacao`).once('value').then((snapshot) => {

                    const dadosEmpresa = [];
                    snapshot.forEach(childItem => {
                        dadosEmpresa.push({
                            key: childItem.key,
                        })
                    })
                 /*    console.log(dadosEmpresa[0].key)
                    console.log(snapshot.val()) */
                    firebase.database().ref(`${chaveUnica}/configuracao/avaliacao/${dadosEmpresa[0].key}`).update({
                        mensagemPadrao: mensagem,
                        utilizaAvaliacao: enviaMensagemAposFinalizarChamado
                    }).then(() => {
                        toastr.success('Sucesso', 'Dados de avaliação alterados com SUCESSO!!');
                        ativaDesativaLoadingBotaoAlterarConfigAvaliacao(dispatch, false);
                    })
                })
            }
        })

    }
}
const enviaProblemasParaReducer = (dispatch, valor) => {
    dispatch({
        type: ENVIAPROBLEMAPARAREDUCER,
        payload: valor
    });
};
const enviaProblemaParaProblemaSelecionado = (dispatch, valor) => {
    dispatch({
        type: ENVIAPROBLEMAPARAPROBLEMASELECIONADO,
        payload: valor
    });
};
const ativaDesativaLoadingCarregandoProblemas = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoAlterarConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};
const mensagemSemConexaoInternetConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};


const enviaDadosConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ENVIADADOSCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};
const ativaDesativaLoadingConfiguracaoAtendimento = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO,
        payload: valor
    });
};


const ativaDesativaLoadingBotaoAlterarConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoAlterarNomeEmpresaConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
        payload: valor
    });
};
const ativaDesativaLoadingBotaoNovoProblema = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGBOTAONOVOPROBLEMA,
        payload: valor
    });
};
const ativaDesativaLoadingTelaConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONFIGEMPRESA,
        payload: valor
    });
};
const ativaDesativaLoadingTelaConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELACONFIGAVALIACAO,
        payload: valor
    });
};
const enviaNomeEmpresReducer = (dispatch, nome) => {
    dispatch({
        type: ENVIANOMEEMPRESAPARAREDUCER,
        payload: nome
    });
};

const enviaMensagemAvaliacaoParaReducer = (dispatch, nome) => {
    dispatch({
        type: ENVIAMENSAGEMAVALIACAOPARAREDUCER,
        payload: nome
    });
};
const enviaSeDesejaMandarMensagemAvaliacaoAposFInalizarAtendimento = (dispatch, value) => {
    dispatch({
        type: ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
        payload: value
    });
};

const mensagemSemConexaoInternetConfigEmpresa = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA,
        payload: valor
    });
};
const mensagemSemConexaoInternetConfigAvaliacao = (dispatch, valor) => {
    dispatch({
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO,
        payload: valor
    });
};


export const modificaProblemaSelecionadoSectioned = (value) => {
    return {
        type: ENVIAPROBLEMAPARAPROBLEMASELECIONADO,
        payload: value
    }
};
export const alteraValorSwitchConfigChamadoEstatico = (value) => {
    return {
        type: ALTERAVALORSWITCHCONFIGCHAMADOESTATICO,
        payload: value
    }
};

export const alteraValorSwitchConfigAtendimento = (value) => {
    return {
        type: ALTERAVALORSWITCHCONFIGATENDIMENTO,
        payload: value
    }
};
export const modificaDescricaoProblemaEstatico = (value) => {
    return {
        type: MODIFICADESCRICAOPROBLEMAESTATICO,
        payload: value
    }
};

const zeraDescricaoProblemaCadastroSucesso = (dispatch, value) => {
    dispatch({
        type: MODIFICADESCRICAOPROBLEMAESTATICO,
        payload: value
    })
};
const alteraAtendenteVeApenasProprioChamadoLOCAL = (dispatch, value) => {
    dispatch({
        type: ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
        payload: value
    })
};

const alteraAgrupaAtendenteSetorLOCAL = (dispatch, value) => {
    dispatch({
        type: ALTERAAGRUPAATENDENTESETOR,
        payload: value
    })
};
export const modificaLogoTipoTelaConfiguracao = (value) => {
    return {
        type: MODIFICALOGOTIPOTELACONFIGURACAO,
        payload: value
    }
};

export const modificaImagemCompletaLogoTipoConfiguracao = (value) => {
    return {
        type: MODIFICAIMAGEMCOMPLETALOGOTIPOCONFIGURACAO,
        payload: value
    }
};

export const alteraAtendenteVeApenasProprioChamado = (value) => {
    return {
        type: ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
        payload: value
    }
};

export const alteraAgrupaAtendenteSetor = (value) => {
    return {
        type: ALTERAAGRUPAATENDENTESETOR,
        payload: value
    }
};
export const alteraValorSwitchConfigAvaliacao = (value) => {
    return {
        type: ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
        payload: value
    }
};
export const alteraMensagemAvaliacao = (value) => {
    return {
        type: ENVIAMENSAGEMAVALIACAOPARAREDUCER,
        payload: value
    }
};
export const modificaNomeEmpresa = (value) => {
    return {
        type: MODIFICANOMEEMPRESACONFIGURAEMPRESA,
        payload: value
    }
};
export const zeraMensagemSemInternetConfigEmpresa = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA,
        payload: ''
    }
};
export const zeraMensagemSemInternetConfigAtendimento = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
        payload: ''
    }
};

export const zeraMensagemSemInternetConfigAvaliacao = () => {
    return {
        type: MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO,
        payload: ''
    }
};



export const modificaCorFundoPrincipal = (cor) => {
    return {
        type: MODIFICACORFUNDOPRINCIPAL,
        payload: cor
    }
};
export const modificaCorFundoSecundario = (cor) => {
    return {
        type: MODIFICACORFUNDOSECUNDARIO,
        payload: cor
    }
};
export const modificaCorFundoItensSelecionaveis = (cor) => {
    return {
        type: MODIFICACORFUNDOITENSSELECIONAVEIS,
        payload: cor
    }
};
export const modificaCorFundoBotaoPrincipal = (cor) => {
    return {
        type: MODIFICACORFUNDOBOTAOPRINCIPAL,
        payload: cor
    }
};
export const modificaCorFundoBotaoSecundario = (cor) => {
    return {
        type: MODIFICACORFUNDOBOTAOSECUNDARIO,
        payload: cor
    }
};
export const modificaCorLetraBotao = (cor) => {
    return {
        type: MODIFICACORLETRAPADRAO,
        payload: cor
    }
};
export const modificaCorLetraPrincipal = (cor) => {
    return {
        type: MODIFICACORLETRAPRINCIPAL,
        payload: cor
    }
};
const verificaCriarChamadoProblemaEstatico = (dispatch, valor) => {
    dispatch({
        type: VERIFICACRIARCHAMADOPROBLEMAESTATICO,
        payload: valor
    });
};
export const modificaCorLetraSecundaria = (cor) => {
    return {
        type: MODIFICACORLETRASECUNDARIA,
        payload: cor
    }
};
export const modificaCorDescricaoDosCampos = (cor) => {
    return {
        type: MODIFICACORLETRADESCRICAODOSCAMPOS,
        payload: cor
    }
};
export const modificaCorFundoMenu = (cor) => {
    return {
        type: MODIFICACORFUNDOMENU,
        payload: cor
    }
};
export const modificaCorLetraMenu = (cor) => {
    return {
        type: MODIFICACORLETRAMENU,
        payload: cor
    }
};

export const alteraSwitchNotifAtendABertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifAtendEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendAbertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendQueEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    })
}
const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};