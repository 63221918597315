import moment from 'moment';
import firebase from '../servicos/FirebaseConnection';
import { toastr } from 'react-redux-toastr';


import {
    ALTERASWITCHNOTIFATENDABERTOPORMIM, ALTERASWITCHNOTIFATENDESTOUATENDENDO, ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
    ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO, ATIVADESATIVALOADINGTELANOTIFICACAO,
    ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO, VERIFICACONEXAOINTERNETNOTIFICAO, ZERAVERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
    VERIFICASEALTEROUCOMSUCESSONOTIFICACAO,ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO
} from './types';


export const alterarConfiguracaoNotificao2 = (notifAtendAberto2, notifAtendEstouAtendendo2, notifRespAtendAbertoPorMim2, notifRespAtendQueEstouAtendendo2) => {
    return dispatch => {
      /*   console.log(notifAtendAberto2, notifAtendEstouAtendendo2, notifRespAtendAbertoPorMim2, notifRespAtendQueEstouAtendendo2) */
        let notifAtendAberto = (notifAtendAberto2 == true) ? 1 : 0;
        let notifAtendEstouAtendendo = (notifAtendEstouAtendendo2 == true) ? 1 : 0;
        let notifRespAtendAbertoPorMim = (notifRespAtendAbertoPorMim2 == true) ? 1 : 0;
        let notifRespAtendQueEstouAtendendo = (notifRespAtendQueEstouAtendendo2 == true) ? 1 : 0;
        AsyncStorage.getItem('chaveUnica').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, true)
            firebase.database().ref(`${chaveUnica}/usuarios`).orderByChild('uid').equalTo(firebase.auth().currentUser.uid).once("value").then((snapshot) => {
                let keyAtendente = Object.keys(snapshot.val()).toString()
                firebase.database().ref(`${chaveUnica}/usuarios/${keyAtendente}`).update({
                    notifNovoAtend: notifAtendAberto,
                    notifAtendimentoAtribuidoAmim: notifAtendEstouAtendendo,
                    notifRespAtendAbertosPorMim: notifRespAtendAbertoPorMim,
                    notifRespAtendEmQueEstouAtend: notifRespAtendQueEstouAtendendo
                }).then(() => {
                    ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, false);
                    toastr.success('Sucesso', 'Notificação Alterada com sucesso!');
                })
            })
        })
    }
}
export const alterarConfiguracaoNotificao = (notifAtendAberto2, notifRespAtendAbertoPorMim2, ) => {
    return dispatch => {
        let notifAtendAberto = (notifAtendAberto2 == true) ? 1 : 0;
        let notifRespAtendAbertoPorMim = (notifRespAtendAbertoPorMim2 == true) ? 1 : 0;
        /* console.log(notifAtendAberto, notifRespAtendAbertoPorMim) */
        AsyncStorage.getItem('chaveUnicaCliente').then((chaveUnica2) => {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            dispatch({
                type: ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO,
                payload: true
            });
            //ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, true)
            const { currentUser } = firebase.auth();
            firebase.database().ref(`${chaveUnica}/clientes`).orderByChild('useruid').equalTo(currentUser.uid).once("value").then((snapshot) => {
                let keyAtendente = Object.keys(snapshot.val()).toString()
              /*   console.log(keyAtendente); */
                firebase.database().ref(`${chaveUnica}/clientes/${keyAtendente}`).update({
                    notifNovoAtendimento: notifAtendAberto,
                    notifRespNosAtendimentos: notifRespAtendAbertoPorMim,
                }).then(() => {
                    dispatch({
                        type: ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO,
                        payload: false
                    });
                    // ativaDesativaLoadingBOtaoAlterarNotificacao(dispatch, false);
                    toastr.success('Sucesso', 'Notificação alterada com sucesso!')
                })
            })
        })
    }
}
export const ativaDesativaLoadingBOtaoAlterarNotificacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINBOTAOALTERARNOTIFICACAO,
        payload: valor
    })
}
export const ativaDesativaLoadingTelaNotificacao = (dispatch, valor) => {
    dispatch({
        type: ATIVADESATIVALOADINGTELANOTIFICACAO,
        payload: valor
    })
}



export const verificaConexaoInternetNotificacao = (valor) => {
    return {
        type: VERIFICACONEXAOINTERNETNOTIFICAO,
        payload: valor
    }
}

export const alteraSwitchNotifAtendABertoPorMim = (valor) => {
    console.log(valor)
    return {
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    }
}
export const alteraSwitchNotifAtendEstouAtendendo = (valor) => {
    return {
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    }
}
export const alteraSwitchNotifRespAtendAbertoPorMim = (valor) => {
    return {
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    }
}
export const alteraSwitchNotifRespAtendQueEstouAtendendo = (valor) => {
    return {
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    }
}




export const alteraSwitchNotifAtendABertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifAtendEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFATENDESTOUATENDENDO,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendAbertoPorMimLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDABERTOPORMIM,
        payload: valor
    })
}
export const alteraSwitchNotifRespAtendQueEstouAtendendoLocal = (dispatch, valor) => {
    dispatch({
        type: ALTERASWITCHNOTIFRESPATENDQUEESTOUATENDENDO,
        payload: valor
    })
}
const verificaSeAlterouComSucessoNotificacao = (dispatch, texto) => {
    dispatch({
        type: VERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
        payload: texto
    });
}
export const zeraverificaSeAlterouComSucessoNotificacao = () => {
    return {
        type: VERIFICASEALTEROUCOMSUCESSONOTIFICACAO,
        payload: ''
    }
}

const AsyncStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
};