import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import ReduxToastr from 'react-redux-toastr';
import Reducers from './reducers/index';
import App from './App';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const store = createStore(Reducers, applyMiddleware(ReduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ReduxToastr
        timeOut={5000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="bounceIn"
        transitionOut="bounceOut"
        progressBar
        closeOnToastrClick />
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);