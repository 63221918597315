import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import Dropzone from 'react-dropzone';
import { withRouter } from "react-router-dom";
import SelectSearch from 'react-select-search';
import ClipLoader from "react-spinners/ClipLoader";
import styled from 'styled-components';
import { SketchPicker, ChromePicker } from 'react-color';
import { toastr } from 'react-redux-toastr';
import { shade, opacify, lighten, darken } from 'polished';
import 'react-responsive-modal/styles.css';
import {
    Title, Container, ContainerCfg, DivCabecalhoCfg, TextCabecalho, DivConfiguracoes, ContainerPermitir,
    TextPermitir, DivInputComentarChamado, InputDescricaoChamado, TextMensagem, InputDescricaoEmpresa,
    IconeInterrogacao, DivCSelectColor, DivColorPiciker, DivCor, DivColor, DivLetraeCor, TextDescCor,
    ContainerCores, ContainerSelectSearch, DivInputSearch, InputSearch, IconeSetaPraBaixo,
    TextBotaoSalvarComentar, ButtonSalvarComentario, DivBotaoSalvarComentario, ContainerPermitirProblemaPReDefinido,
    ButtonSalvarComentarioSemComent, ContainerModalPrioridadeAtendimento, ContainerIconeFecharModal,
    TextNovoAtendimento, IconeFechar, ContainerAtendentes, ContainerInputEBotaoComent, ContainerBotaoAlterar,
    ButtonEntrar, InputDescricaoChamado2,

    ButtonEscolherLogoTipo, TextOpcaoLoading, TextInputLoading, TextOpcaoCorLoading, TextNomeEmpresaLoading,
    ButtonResetCores
} from './styled';

import ReactTooltip from "react-tooltip";

import {
    zeraMensagemSemInternetConfigAvaliacao, modificaNomeEmpresa,
    alteraMensagemAvaliacao, alteraValorSwitchConfigAvaliacao, zeraMensagemSemInternetConfigEmpresa,

    alteraValorSwitchConfigAtendimento, zeraMensagemSemInternetConfigAtendimento,
    alteraAgrupaAtendenteSetor, alteraAtendenteVeApenasProprioChamado, alteraValorSwitchConfigChamadoEstatico,
    modificaDescricaoProblemaEstatico,
    modificaProblemaSelecionadoSectioned,

    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao, modificaCorFundoMenu, modificaCorLetraMenu,
    listaConfiguracoes, cadastraProblemaEstatico, alteraConfiguracaoAtendimento,
    alteraConfiguracaoAvaliacao, alteraNomeEmpresa, resetarCores, modificaImagemCompletaLogoTipoConfiguracao, modificaLogoTipoTelaConfiguracao,
    listaNotificacoesMarcadas
} from '../../actions/ConfiguracaoAction';

import {
    alteraSwitchNotifAtendABertoPorMim, alteraSwitchNotifAtendEstouAtendendo, alteraSwitchNotifRespAtendAbertoPorMim,
    alteraSwitchNotifRespAtendQueEstouAtendendo,
    verificaConexaoInternetNotificacao, zeraverificaSeAlterouComSucessoNotificacao,
    alterarConfiguracaoNotificao
} from '../../actions/NotificacaoAction';
import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import logotipo from '../../imagens/logodropdesk2.png'

class Notificacoes extends React.Component {
    state = {
        color: '#000',
        modalResetarCores: false,
        modalVisibleDescricaoProblema: false,
        value: 0,
        displayColorPicker: false,
        displayColorPicker1: false,
        displayColorPicker2: false,
        displayColorPicker3: false,
        displayColorPicker4: false,
        displayColorPicker5: false,
        displayColorPicker6: false,
        displayColorPicker7: false,
        displayColorPicker8: false,
        displayColorPicker9: false,
        displayColorPicker10: false,
        displayColorPicker11: false,
        search: false,
        mostrarMaisDetalhesChamado: false,
        
    }

    
    componentDidMount() {
        this.props.listaNotificacoesMarcadas()
        if (this.props.nomeRota !== 'Notificações') {
            this.props.modificaNomeRotaNoHeader('Notificações')
        }

    }
    componentWillReceiveProps() {
        ReactTooltip.rebuild();
    }
    escolheImagemLogoTipo(res) {
        if (res.length > 0) {
            console.log(res)
            let uri;
            res.map(file => Object.assign(file, {
                uri: URL.createObjectURL(file)
            }));
            console.log(res)
            this.props.modificaImagemCompletaLogoTipoConfiguracao(res[0])
            this.props.modificaLogoTipoTelaConfiguracao(res[0].uri);
        } else {
            toastr.error('Error ao Selecionar', 'Selecione uma logotipo com no máximo 5mb de tamanho');

        }
    }
    renderImagemLogoTipo() {
        if (this.props.logotipo == null) {
            return (
                <div style={{ height: 256, width: 256, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />

            )
        } else {
            return (
                <img src={this.props.logotipo === 'semURI' ? logotipo : this.props.logotipo} alt="Logotipo" style={{ height: 256, width: 256 }} />
            )
        }

    }
    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
    handleClick1 = () => {
        this.setState({ displayColorPicker1: !this.state.displayColorPicker1 })
    };
    handleClick2 = () => {
        this.setState({ displayColorPicker2: !this.state.displayColorPicker2 })
    };
    handleClick3 = () => {
        this.setState({ displayColorPicker3: !this.state.displayColorPicker3 })
    };
    handleClick4 = () => {
        this.setState({ displayColorPicker4: !this.state.displayColorPicker4 })
    };
    handleClick5 = () => {
        this.setState({ displayColorPicker5: !this.state.displayColorPicker5 })
    };
    handleClick6 = () => {
        this.setState({ displayColorPicker6: !this.state.displayColorPicker6 })
    };
    handleClick7 = () => {
        this.setState({ displayColorPicker7: !this.state.displayColorPicker7 })
    };
    handleClick8 = () => {
        this.setState({ displayColorPicker8: !this.state.displayColorPicker8 })
    };
    handleClick9 = () => {
        this.setState({ displayColorPicker9: !this.state.displayColorPicker9 })
    };
    handleClick10 = () => {
        this.setState({ displayColorPicker10: !this.state.displayColorPicker10 })
    };
    handleClick11 = () => {
        this.setState({ displayColorPicker11: !this.state.displayColorPicker11 })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };
    _toggleModalVisibleDescricaoProblema() {
        this.setState({ modalVisibleDescricaoProblema: !this.state.modalVisibleDescricaoProblema })
    }
    _toggleModalResetarcores() {
        this.setState({ modalResetarCores: !this.state.modalResetarCores })
    }

    handleClose1 = () => {
        this.setState({ displayColorPicker1: false })
    };
    handleClose2 = () => {
        this.setState({ displayColorPicker2: false })
    };
    handleClose3 = () => {
        this.setState({ displayColorPicker3: false })
    };
    handleClose4 = () => {
        this.setState({ displayColorPicker4: false })
    };
    handleClose5 = () => {
        this.setState({ displayColorPicker5: false })
    };
    handleClose6 = () => {
        this.setState({ displayColorPicker6: false })
    };
    handleClose7 = () => {
        this.setState({ displayColorPicker7: false })
    };
    handleClose8 = () => {
        this.setState({ displayColorPicker8: false })
    };
    handleClose9 = () => {
        this.setState({ displayColorPicker9: false })
    };
    handleClose10 = () => {
        this.setState({ displayColorPicker10: false })
    };
    handleClose11 = () => {
        this.setState({ displayColorPicker11: false })
    };

    handleChange = (color) => {
        console.log(color)
        this.setState({ color: color.hex })
    };
    renderBotaoSalvarProblema() {
        if (this.props.descricaoProblemaEstatico !== '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this._toggleModalVisibleDescricaoProblema();
                    this.props.cadastraProblemaEstatico(this.props.descricaoProblemaEstatico, this.props.problemasSelecionado);
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Cadastrar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    renderLoadingConfiguracao() {


    }
    render() {
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        z-index:999;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index:999;
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;

        if (this.props.loadingConfigAtendimentos) {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>

                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Notificações</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                            <TextOpcaoLoading backgroundinamico={this.props.backgroundSegundo}></TextOpcaoLoading>
                        </DivConfiguracoes>

                    </ContainerCfg>
                </Container>
            )
        } else {
            return (
                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <ContainerCfg backgroundDinamico={this.props.backgroundSegundo}>
                        <DivCabecalhoCfg bordaDinamica={this.props.coresLetraSecundario}>
                            <TextCabecalho corLetraDinamica={this.props.coresLetraPadrao}>Notificações</TextCabecalho>
                        </DivCabecalhoCfg>
                        <DivConfiguracoes>
                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Novo atendimento:</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox2" type="checkbox" checked={this.props.notifAtendAberto} onChange={(value) => { console.log("no onchamnge"); this.props.alteraSwitchNotifAtendABertoPorMim(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox2" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>

                            <ContainerPermitir>
                                <TextPermitir corLetraDinamica={this.props.corLetraDescricaoDosCampos}>Respostas nos atendimentos</TextPermitir>
                                <CheckBoxWrapper>
                                    <CheckBox id="checkbox81" type="checkbox" checked={this.props.notifRespAtendAbertoPorMim} onChange={(value) => { this.props.alteraSwitchNotifRespAtendAbertoPorMim(value.target.checked) }} />
                                    <CheckBoxLabel htmlFor="checkbox81" />
                                </CheckBoxWrapper>

                            </ContainerPermitir>

                        </DivConfiguracoes>
                        <ContainerBotaoAlterar>
                            {this.props.loadingConfigAtendimentos == false && <ButtonEntrar backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao} type="button" onClick={() => {
                                this.props.alterarConfiguracaoNotificao(this.props.notifAtendAberto, this.props.notifRespAtendAbertoPorMim)
                            }}>
                                Alterar
               </ButtonEntrar>}
                            {this.props.loadingConfigAtendimentos == true &&
                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={this.props.corBotaoPadrao}
                                    loading
                                />
                            }

                        </ContainerBotaoAlterar>
                        <div style={{ display: 'flex', height: 20, justifyContent: 'flex-end', alignItems: 'center' }}>
                            <p style={{ color: this.props.coresLetraPadrao, fontStyle: 'italic', marginRight: 4 }}>Versão 2.7.1</p>
                        </div>
                    </ContainerCfg>
                </Container>
            )
        }
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            corFundoMenu: state.CoresPadraoSistema.corFundoMenu,
            corLetraMenu: state.CoresPadraoSistema.corLetraMenu,
            logotipo: state.CoresPadraoSistema.logotipo,
            arquivoLogoTipoCompleto: state.CoresPadraoSistema.arquivoLogoTipoCompleto,
            nomeRota: state.HeaderReducer.nomeRota,
            modalNovoAtendimento: state.ChamadoReducer.modalNovoAtendimento,
            visibleSelectSearch: state.ChamadoReducer.visibleSelectSearch,

            verificaConexaoInternetConfigAvaliacao: state.ConfiguracaoReducer.verificaConexaoInternetConfigAvaliacao,
            premium: state.PremiumReducer.premium,
            loadingConfiAvaliacao: state.ConfiguracaoReducer.loadingConfiAvaliacao,
            loadingBotaoAlterarConfigAvaliacao: state.ConfiguracaoReducer.loadingBotaoAlterarConfigAvaliacao,
            switchMEnsagemAvaliacao: state.ConfiguracaoReducer.switchMEnsagemAvaliacao,
            mensagemAvaliacao: state.ConfiguracaoReducer.mensagemAvaliacao,
            loadingConfigEmpresa: state.ConfiguracaoReducer.loadingConfigEmpresa,
            nomeEmpresa: state.ConfiguracaoReducer.nomeEmpresa,
            loadingBotaoAlterarNomeConfigEmpresa: state.ConfiguracaoReducer.loadingBotaoAlterarNomeConfigEmpresa,
            licensawebouapp: state.PremiumReducer.licensawebouapp,

            loadingConfigAtendimentos: state.ConfiguracaoReducer.loadingConfigAtendimentos,
            switchObrigatorioAtribuirAtendenteAtendimento: state.ConfiguracaoReducer.switchObrigatorioAtribuirAtendenteAtendimento,
            loadingBotaoAlterarConfigAtendimento: state.ConfiguracaoReducer.loadingBotaoAlterarConfigAtendimento,
            verificaConexaoInternetConfigAtendimento: state.ConfiguracaoReducer.verificaConexaoInternetConfigAtendimento,
            atendenteVeApenasProprioChamado: state.ConfiguracaoReducer.atendenteVeApenasProprioChamado,
            agruparAtendenteSetor: state.ConfiguracaoReducer.agruparAtendenteSetor,
            verificaCriarChamadoProblemaEstatico: state.ConfiguracaoReducer.verificaCriarChamadoProblemaEstatico,
            problemasEstaticos: state.ConfiguracaoReducer.problemasEstaticos,
            problemasSelecionado: state.ConfiguracaoReducer.problemasSelecionado,
            loadingBotaoNovoPRoblema: state.ConfiguracaoReducer.loadingBotaoNovoPRoblema,
            descricaoProblemaEstatico: state.ConfiguracaoReducer.descricaoProblemaEstatico,
            loadingCarregandoProblemas: state.ConfiguracaoReducer.loadingCarregandoProblemas,
            notifAtendAberto: state.NotificacaoReducer.notifAtendAberto,
            notifAtendEstouAtendendo: state.NotificacaoReducer.notifAtendEstouAtendendo,
            notifRespAtendAbertoPorMim: state.NotificacaoReducer.notifRespAtendAbertoPorMim,
            notifRespAtendQueEstouAtendendo: state.NotificacaoReducer.notifRespAtendQueEstouAtendendo,
            loadingBotaoAlterarNotificacao: state.NotificacaoReducer.loadingBotaoAlterarNotificacao,
            permissoesAcessarTelasAtendente: state.AtendenteReducer.permissoesAcessarTelasAtendente,
        }
    );
};
export default connect(mapStateToProps, {
    zeraMensagemSemInternetConfigAvaliacao, modificaNomeEmpresa,
    alteraMensagemAvaliacao, alteraValorSwitchConfigAvaliacao,
    zeraMensagemSemInternetConfigEmpresa,
    alteraSwitchNotifAtendABertoPorMim, alteraSwitchNotifAtendEstouAtendendo, alteraSwitchNotifRespAtendAbertoPorMim,
    alteraSwitchNotifRespAtendQueEstouAtendendo,
    verificaConexaoInternetNotificacao, zeraverificaSeAlterouComSucessoNotificacao,

    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao,

    alteraValorSwitchConfigAtendimento, zeraMensagemSemInternetConfigAtendimento,
    alteraAgrupaAtendenteSetor, alteraAtendenteVeApenasProprioChamado, alteraValorSwitchConfigChamadoEstatico,
    modificaDescricaoProblemaEstatico,
    modificaProblemaSelecionadoSectioned, modificaCorFundoMenu, modificaCorLetraMenu, modificaNomeRotaNoHeader,
    listaConfiguracoes, cadastraProblemaEstatico, alteraConfiguracaoAtendimento,
    alteraConfiguracaoAvaliacao, alterarConfiguracaoNotificao, alteraNomeEmpresa, resetarCores,
    modificaImagemCompletaLogoTipoConfiguracao, modificaLogoTipoTelaConfiguracao, listaNotificacoesMarcadas
})(withRouter(Notificacoes));
