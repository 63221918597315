import {
    ENVIASEEHVERSAOPREMIUM, VERIFICAINTERNETTELAVIP, LOADINGREFRESHCONTROLVIP,
    LOADINGTELAVIP, ENVIAPLANOMENSAL, ENVIAPLANOTRIMESTRAL, ENVIAPLANOANUAL, ENVIADATAVALIDADEVERSAOPREMIUM, ENVIADATACOMPRAVERSAOPREMIUM,
    ENVIADATAVALIDADEATUALPREMIUM, ENVIANUMEROPEDIDOCOMPRAPREMIUM, ENVIAPLANOPREMIUM, ENVIAPLATAFORMAPREMIUM,
    ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM, ENVIASTATUSASSINATURAPREMIUM, ATIVADESATIVALOADINGVERSAOPREMIUM,
    ENVIATEXTSTATUSASSINATURA, ENVIACORTEXTSTATUSASSINATURA, MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM,
    MODIFICAVISIBLEMODALASSINATURASUSPENSA, MODIFICAVISIBLEMODALASSINATURAPAUSADA, MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU,
    ENVIAIDENTIFICADORUNICACOMPRA, ENVIAEMAILASSINANTE, ENVIANOMEASSINANTE, ENVIAPREMIUMVERSAOGRATIS,
    MODIFICAMODALDUVIDASFREQUENTES, ATIVADESATIVARENOVARPLANO, MODIFICAMOSTRAVERSAOEXPIRANDO, MODIFICALICENSAWEBAPP,
    VERIFICAAGRUPARATENDENTESETOR,VERIFICACRIARCHAMADOPROBLEMAESTATICO
} from '../actions/types';


const INITIAL_STATE = {
    premium: 0,
    dataCompra: '',
    dataValidoAte: '',
    loadingTelaVIP: false,
    planoMensal: '',
    planoTrimestral: '',
    planoAnual: '',
    semInternetVIP: false,
    loadingRefreshControlVIP: false,
    dataValidadeAtual: '',
    numeroPedidoCompra: '',
    plano: '',
    plataforma: '',
    qtdAtendentesLiberados: '',
    statusAssinatura: '',
    loadingRenderStatusAssinaturaDrawer: true,
    textStatusAssinaturaDrawer: '',
    corTextStatusAssinaturaDrawer: '',
    modalVisibleAdiquiraAssinatura: false,
    modalVisibleAssinaturaSuspensa: false,
    modalVisibleAssinaturaPausada: false,
    modalVisibleVersaoPremiumEsgotou: false,
    identificadorUnicoCompra: '',
    nomeAssinante: '',
    emailAssinante: '',
    premiumVersaoGratis: false,
    modalDuvidasFrequentes: false,
    renovarPlano: false,
    mostraVersaoExpirando: false,
    licensawebouapp: 1,
    verificaAgrupaAtendenteSetor: false,
    criarChamadoProblemaEstatico: false,
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ENVIASEEHVERSAOPREMIUM:
            return { ...state, premium: action.payload };
        case VERIFICAINTERNETTELAVIP:
            return { ...state, semInternetVIP: action.payload };
        case LOADINGREFRESHCONTROLVIP:
            return { ...state, loadingRefreshControlVIP: action.payload };
        case LOADINGTELAVIP:
            return { ...state, loadingTelaVIP: action.payload };
        case ENVIAPLANOMENSAL:
            return { ...state, planoMensal: action.payload };
        case ENVIAPLANOTRIMESTRAL:
            return { ...state, planoTrimestral: action.payload };
        case ENVIAPLANOANUAL:
            return { ...state, planoAnual: action.payload };
        case ENVIADATAVALIDADEVERSAOPREMIUM:
            return { ...state, dataValidoAte: action.payload };
        case ENVIADATACOMPRAVERSAOPREMIUM:
            return { ...state, dataCompra: action.payload };

        case ENVIADATAVALIDADEATUALPREMIUM:
            return { ...state, dataValidadeAtual: action.payload };
        case ENVIANUMEROPEDIDOCOMPRAPREMIUM:
            return { ...state, numeroPedidoCompra: action.payload };
        case ENVIAPLANOPREMIUM:
            return { ...state, plano: action.payload };
        case ENVIAPLATAFORMAPREMIUM:
            return { ...state, plataforma: action.payload };
        case ENVIAQUANTIDADEATENDENTELIBERADOPREMIUM:
            return { ...state, qtdAtendentesLiberados: action.payload };
        case ENVIASTATUSASSINATURAPREMIUM:
            return { ...state, statusAssinatura: action.payload };
        case ATIVADESATIVALOADINGVERSAOPREMIUM:
            return { ...state, loadingRenderStatusAssinaturaDrawer: action.payload };
        case ENVIATEXTSTATUSASSINATURA:
            return { ...state, textStatusAssinaturaDrawer: action.payload };
        case ENVIACORTEXTSTATUSASSINATURA:
            return { ...state, corTextStatusAssinaturaDrawer: action.payload };
        case MODIFICAVISIBLEMODALADIQUIRAVERSAOPREMIUM:
            return { ...state, modalVisibleAdiquiraAssinatura: action.payload };
        case MODIFICAVISIBLEMODALASSINATURASUSPENSA:
            return { ...state, modalVisibleAssinaturaSuspensa: action.payload };
        case MODIFICAVISIBLEMODALASSINATURAPAUSADA:
            return { ...state, modalVisibleAssinaturaPausada: action.payload };
        case MODIFICAVISIBLEMODALVERSAOPREMIUMESGOTOU:
            return { ...state, modalVisibleVersaoPremiumEsgotou: action.payload };
        case ENVIAIDENTIFICADORUNICACOMPRA:
            return { ...state, identificadorUnicoCompra: action.payload };
        case ENVIAEMAILASSINANTE:
            return { ...state, emailAssinante: action.payload };
        case ENVIANOMEASSINANTE:
            return { ...state, nomeAssinante: action.payload };
        case ENVIAPREMIUMVERSAOGRATIS:
            return { ...state, premiumVersaoGratis: action.payload };
        case MODIFICAMODALDUVIDASFREQUENTES:
            return { ...state, modalDuvidasFrequentes: action.payload };
        case ATIVADESATIVARENOVARPLANO:
            return { ...state, renovarPlano: action.payload };
        case MODIFICAMOSTRAVERSAOEXPIRANDO:
            return { ...state, mostraVersaoExpirando: action.payload };
        case MODIFICALICENSAWEBAPP:
            return { ...state, licensawebouapp: action.payload };
        case VERIFICAAGRUPARATENDENTESETOR:
            return { ...state, verificaAgrupaAtendenteSetor: action.payload }
        case VERIFICACRIARCHAMADOPROBLEMAESTATICO:
            return { ...state, criarChamadoProblemaEstatico: action.payload }
        default:
            return state;
    }
};