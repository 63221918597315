import React from 'react';
import { connect } from 'react-redux';
import firebase from '../../servicos/FirebaseConnection';
import MoonLoader from "react-spinners/MoonLoader";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FlatList from 'flatlist-react';
import FlatListChamadoAberto from "./FlatListChamadoAberto";
import ReactTooltip from "react-tooltip";
import SelectSearch from 'react-select-search';
import '../Avisos/stylesSearch.css';


import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,

    ativaDesativaLoadingFinalFlatListChamadoAbertoExport,listaOsChamados
} from "../../actions/ChamadoAction";

import {
    Title, Container, IconeSair, ContainerInputSearch, InputFiltroPorNumero, BotaoPesquisarChamadoPorNumero,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, ContainerChamadoLoading,

    TextProblemaLoading, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase,
    ContainerImagemLoading, ImageLoading, TextClienteLoading, ContainerListaVazia

} from './styled';


class ChamadoAberto extends React.Component {
    state = {
        testeFimPagina: true,
        filtroTeste: false,
        hasMoreItems: false,
        offset: 0,
        search: '',
        maisDados: [],
        testeEmpresas: [
            { value: 'Hera', name: 'Hera' },
            { value: 'Mr Automac', name: 'Mr Automac' },
            { value: 'Autocom', name: 'Autocom' },
            { value: '1', name: 'Hera' },
            { value: 'Mr', name: 'Mr Automac' },
            { value: '3', name: 'Autocom' },
        ],
        pesquisa: [
            {
                name: 'Hera',
                type: 'group',
                items: [{
                    value: 'Heverton',
                    name: 'Heverton',
                }, {
                    value: 'Joao',
                    name: 'Joao',
                }]
            }],
        loading: true,
        chamados:
            [
                {
                    key: '1', cliente: 'HevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHeverton', numeroChamado: 1, empresa: 'Hera Engenharia de Software LTDAHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTD',
                    setor: 'ERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERP', descricaoProblema: 'Computador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão Victor', prioridade: 'Alta'
                },
                {
                    key: '2', cliente: 'Thalita Amorim', numeroChamado: 2, empresa: 'Descanse em paz',
                    setor: 'Funeraria', descricaoProblema: 'Sistema não está imprimindo na impressora A4', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'Genilson Candido', prioridade: 'Normal'
                },
                {
                    key: '3', cliente: 'Sérgio Andrade', numeroChamado: 3, empresa: 'DropDesk',
                    setor: 'App', descricaoProblema: 'Aplicativo não transmite dados', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'Ronaldo', prioridade: 'Urgente'
                },
                {
                    key: '4', cliente: 'João Victor', numeroChamado: 4, empresa: 'Jiu Jtsu',
                    setor: 'Faixa', descricaoProblema: 'Faixa ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'Carlos Magno', prioridade: 'Baixa'
                },
                {
                    key: '5', cliente: 'Não informado', numeroChamado: 5, empresa: 'Não informado',
                    setor: 'Não informado', descricaoProblema: 'No Break Ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'Não informado', prioridade: 'Não informado'
                },
                /* {key:'6',cliente:'Aldair Fernandes',numeroChamado:6,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'7',cliente:'Aldair Fernandes',numeroChamado:7,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'8',cliente:'Aldair Fernandes',numeroChamado:8,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'9',cliente:'Aldair Fernandes',numeroChamado:9,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'10',cliente:'Aldair Fernandes',numeroChamado:10,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},  */
            ]
    }
    componentWillMount() {
        // this.props.listaOsUltimosChamadosAbertos(0, '', [], 'willmount', this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.verificaAgrupaAtendenteSetor, this.props.contaPrincipal);
    }
    componentWillUnmount() {/* 
        const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnicaCliente'));
        if (chaveUnica2) {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/chamados`).off("value");
        } else {

        } */

    }
    componentDidMount() {
        ReactTooltip.rebuild();

    }
    /*  shouldComponentUpdate(nextProps) {
         if (this.props.chamados !== nextProps.chamados){
             return true;
         }
     }  */
   
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
    hideTooltips() {
        ReactTooltip.hide()
        setTimeout(
            () => {
                ReactTooltip.rebuild();
                ReactTooltip.show();

            },
            100
        )
    }
  
 
 
    renderCarregaMaisResultados() {
        if (this.props.loadingMaisResultadosChamados) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                    <MoonLoader
                        css={{ margin: 10 }}
                        size={40}
                        color={this.props.corBotaoPadrao}
                        loading={true}
                    />
                </div>
            )
        } else {
            if (this.props.referenciaUltimaKey != '' && this.props.referenciaUltimaKey != undefined) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',cursor:'pointer',height: 30, margin: 20, backgroundColor: this.props.backgroundSegundo, border: `1px solid ${this.props.coresLetraPadrao}`, width: 200, justifyContent: 'center', alignItems: 'center'
                        }} onClick={() => {
                            this.props.listaOsChamados(1, this.props.referenciaUltimaKey, this.props.chamados, this.props.chamadosEmAtendimentos, this.props.chamadosFechado, this.props.chamadosCancelados, this.props.chamadoTodos);
                        }}><p style={{ fontSize: 12, fontWeight: "bold", color: this.props.coresLetraPadrao, fontFamily: 'Source Sans Pro' }}>CARREGAR MAIS CHAMADOS</p></div>
                    </div>
                )

            } else {
                return (
                    null
                )
            }
        }
    }
    renderLoadingChamadoAberto() {
        if (this.props.loadingTelaChamadoAberto) {
            return (
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                </div>
            )
        } else {
            return (
                <Container>
                    
                    
                    {/*  <div style={{flex:1,alignItems:'flex-start',justifyContent:'center'}}> */}
                    <FlatList
                        list={this.props.chamados}
                        renderItem={(item) => <FlatListChamadoAberto data={item} key={item.key + item.alterado} />}
                        renderWhenEmpty={() => {
                            if (this.props.filtroSelecionado !== '' || this.state.search !== '') {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum registro encontrado</p>
                                    </ContainerListaVazia>
                                )
                            } else {
                                return (
                                    <ContainerListaVazia >
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Chamado Aberto</p>
                                    </ContainerListaVazia>
                                )
                            }
                        }}
                    // hasMoreItems={this.props.loadingFimDaPagina}
                    //  loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                    /*  paginationLoadingIndicator={<MoonLoader
                         css={{ margin: 10 }}
                         size={40}
                         color={this.props.corBotaoPadrao}
                         loading={this.props.loadingFimDaPagina}
                     />} */
                    //sortBy={["firstName", { key: "lastName", descending: true }]}
                    // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                    />
                    {this.renderCarregaMaisResultados()}
                    {/*  </div> */}
                </Container>
            )
        }
    }

    render() {

        return (
            <>
                {this.renderLoadingChamadoAberto()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
            email: state.LoginReducer.email,

            chamadosCancelados: state.ChamadoReducer.chamadosCancelados,
            chamadosEmAtendimentos: state.ChamadoReducer.chamadosEmAtendimentos,
            chamadosFechado: state.ChamadoReducer.chamadosFechado,
            chamadoTodos: state.ChamadoReducer.chamadoTodos,
            chamados: state.ChamadoReducer.chamados,
            loadingMaisResultadosChamados: state.ChamadoReducer.loadingMaisResultadosChamados,
            
            verificaConexaoInternetListaChamadoAberto: state.ChamadoReducer.verificaConexaoInternetListaChamadoAberto,
            loadingTelaChamadoAberto: state.ChamadoReducer.loadingTelaChamadoAberto,
            refreshControlFlatListChamadoAberto: state.ChamadoReducer.refreshControlFlatListChamadoAberto,
            referenciaUltimaKey: state.ChamadoReducer.referenciaUltimaKey,
            loadingFimDaPagina: state.ChamadoReducer.loadingFimDaPagina,
            // premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionado: state.ChamadoReducer.filtroSelecionado,
            selectTextChamado: state.ChamadoReducer.selectTextChamado,
            pesquisaTextChamado: state.ChamadoReducer.pesquisaTextChamado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
           
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    
    ativaDesativaLoadingFinalFlatListChamadoAbertoExport,listaOsChamados
})(ChamadoAberto);
