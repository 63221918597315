import {
    MODIFICADESTINATARIOSELECIONADO, ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO,
    ENVIAATENDENTEPARAREDUCERNOVOAVISO, MODIFICAATENDENTESELECIONADONOVOAVISO, ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO,
    ENVIACLIENTEPARAREDUCERNOVOAVISO, MODIFICAClIENTESELECIONADONOVOAVISO, ALTERADESCRICAOMENSAGEMNOVOAVISO,
    MENSAGEMENVIAAVISOERROR, ATIVADESATIVALOADINGCADASTRARNOVOAVISO, ZERACADASTROAVISO, ALTERARVALORSWITCHNOVOAVISO,
    MODIFICADATAINICIALNOVOAVISO, MODIFICADATAFINALNOVOAVISO, ATIVADESATIVALOADINGTELANOVOAVISO,
    ENVIAAVISOPARAREDUCER, ATIVADESATIVALOADINGREFRESHCONTROLNOVOAVISO, ENVIAREFERENCIAULTIMAKEYAVISO,
    ATIVADESATIVALOADINGFIMDAPAGINACONSULTAAVISO, ENVIASEMCONEXAOINTERNETCONSULTAAVISO,
    ALTERAFILTROSELECIONADOTELANOVOAVISO, ALTERAKEYAVISOPARAALTERACAO, ALTERASTATUSAVISOPARAALTERACAO,
    ZERACADASTROAVISOPARAALTERACAO, ENVIAAVISOPARATELADASHBOARD, ATIVADESATIVALOADINGALTERARAVISO,
    MUDAVISIBILIDADEMODALAVISOVERSAOPREMIUM, MODIFICAVISIBLEMODALNOVOAVISO,
    MODIFICADESTINATARIOSELECIONADONOVOCADASTRO, MODIFICAATENDENTESELECIONADONOVOAVISONOVOCADASTRO,
    MODIFICAClIENTESELECIONADONOVOAVISONOVOCADASTRO, ALTERARVALORSWITCHNOVOAVISONOVOCADASTRO,
    MODIFICADATAINICIALNOVOAVISONOVOCADASTRO,
    MODIFICADATAFINALNOVOAVISONOVOCADASTRO, ALTERADESCRICAOMENSAGEMNOVOAVISONOVOCADASTRO
} from '../actions/types';


const INITIAL_STATE = {
    destinatario:
        [
            {
                name: 'Selecione o Destinatário',
                type: 'group',
                items: [{
                    value: "Atendentes", name: "Atendentes"
                }, {
                    value: "Clientes", name: "Clientes",
                }]
            },

        ],

    filtros: [
        { value: "TODOS", name: "Todos" },
        { value: "ATENDENTES", name: "Atendentes" },
        { value: "CLIENTES", name: "Clientes" },
        { value: "ATIVOS", name: "Ativos" },
        { value: "INATIVOS", name: "Inativos" },
    ],
    filtrosSelecionado: '',
    destinatarioSelecionado: [],
    clientes: [],
    atendentes: [],
    clienteSelecionado: [],
    atendenteSelecionado: ["TodosPadrao"],
    mensagem: '',
    destinatarioSelecionadoValor: 0,
    loadingAtendenteNovoAviso: false,
    loadingClienteNovoAviso: false,
    loadginBotaoCadastrarAviso: false,
    verificaAvisoError: '',
    porFaixaHoraria: false,
    dataInicial: '',
    dataFinal: '',
    avisos: [],
    loadingConsultaAviso: true,
    refreshControlConsultaAvisos: false,
    referenciaUltimaKeyAviso: false,
    loadingFimDaPaginaAviso: false,
    verificaInternetConsultaAviso: '',
    statusAvisoParaAlteracao: false,
    keyAvisoParaAlteracao: '',
    avisosTelaDashBoard: [],
    loadingBotaoAlterarAviso: false,
    modalNovoAvisoVersaoPremium: false,
    modalNovoAviso: false,

    atendenteSelecionadoNovoCadastro: '',
    clienteSelecionadoNovoCadastro: '',
    porFaixaHorariaNovoCadastro: false,
    dataInicialNovoCadastro: '',
    dataFinalNovoCadastro: '',
    mensagemNovoCadastro: '',
    destinatarioSelecionadoValorNovoCadastro: 0,
    destinatarioSelecionadoNovoCadastro:''
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICADESTINATARIOSELECIONADO:
           /*  console.log(action.payload) */
            if (action.payload == "Atendentes") {
                return { ...state, destinatarioSelecionado: action.payload, destinatarioSelecionadoValor: 1 }
            } else {
                return { ...state, destinatarioSelecionado: action.payload, destinatarioSelecionadoValor: 2 }
            }

        case ATIVADESATIVALOADINGCARREGARATENDENTETELANOVOAVISO:
            return { ...state, loadingAtendenteNovoAviso: action.payload }

        case ENVIAATENDENTEPARAREDUCERNOVOAVISO:
            return { ...state, atendentes: action.payload };

        case MODIFICAATENDENTESELECIONADONOVOAVISO:
            return { ...state, atendenteSelecionado: action.payload };

        case ATIVADESATIVALOADINGCARREGARCLIENTETELANOVOAVISO:
            return { ...state, loadingClienteNovoAviso: action.payload };

        case ENVIACLIENTEPARAREDUCERNOVOAVISO:
            return { ...state, clientes: action.payload };
        case MODIFICAClIENTESELECIONADONOVOAVISO:
            return { ...state, clienteSelecionado: action.payload };
        case ALTERADESCRICAOMENSAGEMNOVOAVISO:
            return { ...state, mensagem: action.payload };
        case MENSAGEMENVIAAVISOERROR:
            return { ...state, verificaAvisoError: action.payload };
        case ATIVADESATIVALOADINGCADASTRARNOVOAVISO:
            return { ...state, loadginBotaoCadastrarAviso: action.payload };
        case ZERACADASTROAVISO:
            return {
                ...state, mensagemNovoCadastro: '', destinatarioSelecionadoValorNovoCadastro: 0, destinatarioSelecionadoNovoCadastro:'',
                dataInicialNovoCadastro: '', dataFinalNovoCadastro: '', porFaixaHorariaNovoCadastro: false
            };
        case ZERACADASTROAVISOPARAALTERACAO:
            return {
                ...state, mensagem: '', destinatarioSelecionadoValor: 0, destinatarioSelecionado: [],
                dataInicial: '', dataFinal: '', porFaixaHoraria: false, clienteSelecionado: [],
                atendenteSelecionado: ["TodosPadrao"]
            };
        case ALTERARVALORSWITCHNOVOAVISO:
            return { ...state, porFaixaHoraria: action.payload };
        case MODIFICADATAINICIALNOVOAVISO:
            return { ...state, dataInicial: action.payload };
        case MODIFICADATAFINALNOVOAVISO:
            return { ...state, dataFinal: action.payload };
        case ATIVADESATIVALOADINGTELANOVOAVISO:
            return { ...state, loadingConsultaAviso: action.payload };
        case ENVIAAVISOPARAREDUCER:
            return { ...state, avisos: action.payload };
        case ATIVADESATIVALOADINGREFRESHCONTROLNOVOAVISO:
            return { ...state, refreshControlConsultaAvisos: action.payload };
        case ENVIAREFERENCIAULTIMAKEYAVISO:
            return { ...state, referenciaUltimaKeyAviso: action.payload };
        case ATIVADESATIVALOADINGFIMDAPAGINACONSULTAAVISO:
            return { ...state, loadingFimDaPaginaAviso: action.payload };
        case ENVIASEMCONEXAOINTERNETCONSULTAAVISO:
            return { ...state, verificaInternetConsultaAviso: action.payload };
        case ALTERAFILTROSELECIONADOTELANOVOAVISO:
            return { ...state, filtrosSelecionado: action.payload };
        case ALTERAKEYAVISOPARAALTERACAO:
            return { ...state, keyAvisoParaAlteracao: action.payload };
        case ALTERASTATUSAVISOPARAALTERACAO:
            return { ...state, statusAvisoParaAlteracao: action.payload };
        case ENVIAAVISOPARATELADASHBOARD:
            return { ...state, avisosTelaDashBoard: action.payload };
        case ATIVADESATIVALOADINGALTERARAVISO:
            return { ...state, loadingBotaoAlterarAviso: action.payload };
        case MUDAVISIBILIDADEMODALAVISOVERSAOPREMIUM:
            return { ...state, modalNovoAvisoVersaoPremium: action.payload };
            case MODIFICADESTINATARIOSELECIONADONOVOCADASTRO:
                /* console.log(action.payload) */
                if (action.payload == "Atendentes") {
                    return { ...state, destinatarioSelecionadoNovoCadastro: action.payload, destinatarioSelecionadoValorNovoCadastro: 1 }
                } else {
                    return { ...state, destinatarioSelecionadoNovoCadastro: action.payload, destinatarioSelecionadoValorNovoCadastro: 2 }
                }
        
        case MODIFICAATENDENTESELECIONADONOVOAVISONOVOCADASTRO:
            return { ...state, atendenteSelecionadoNovoCadastro: action.payload };
        case MODIFICAClIENTESELECIONADONOVOAVISONOVOCADASTRO:
            return { ...state, clienteSelecionadoNovoCadastro: action.payload };
        case ALTERARVALORSWITCHNOVOAVISONOVOCADASTRO:
            return { ...state, porFaixaHorariaNovoCadastro: action.payload };
        case MODIFICADATAINICIALNOVOAVISONOVOCADASTRO:
            return { ...state, dataInicialNovoCadastro: action.payload };
        case MODIFICADATAFINALNOVOAVISONOVOCADASTRO:
            return { ...state, dataFinalNovoCadastro: action.payload };
        case ALTERADESCRICAOMENSAGEMNOVOAVISONOVOCADASTRO:
            return { ...state, mensagemNovoCadastro: action.payload };
        case MODIFICAVISIBLEMODALNOVOAVISO:
            return { ...state, modalNovoAviso: action.payload };
        default:
            return state;
    }
};