import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {

} from '../../actions/LoginAction';

import {
    Container404, ButtonEntrar
} from './styled'
class NotFound extends React.Component {
    render() {
        return (
            <div style={{
                position:'fixed',top:0,right:0,left:0,bottom:0,zIndex:'9999',
                flex: 1, height: '100vh', width: '100%', background: this.props.backgroundPrimeiro, justifyContent: 'center',
                alignItems: 'center', display: 'flex', flexDirection: 'column'
            }}>
                <div style={{
                    flex: 1, justifyContent: 'center',
                    alignItems: 'center', display: 'flex', flexDirection: 'column'
                }}>
                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 230, fontWeight: 'bold' }}>404</p>
                    <p style={{ color: this.props.coresLetraPadrao, fontSize: 50 }}>Pagina não encontrada</p>
                </div>
                <div style={{
                    flex: 1, justifyContent: 'center',
                    alignItems: 'center', display: 'flex', flexDirection: 'column'
                }}>
                    <Container404 backGroundDinamico={this.props.backgroundPrimeiro}>
                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 25, marginLeft: 20 }}>Desculpe, mas a página que você está tentando visualizar não existe.</p>
                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20, marginLeft: 25 }}> - parece que isso foi o resultado de:</p>
                        <li style={{ color: this.props.coresLetraPadrao, fontSize: 18, marginLeft: 30 }}>um endereço digitado incorretamente</li>
                        <li style={{ color: this.props.coresLetraPadrao, fontSize: 18, marginLeft: 30 }}>um link desatualizado</li>
                    </Container404>
                    <ButtonEntrar to="/" backGroundDinamico={this.props.corBotaoPadrao} corLetraBotao={this.props.letraBotaoPadrao}>
                        <p style={{ fontSize: 18, color: this.props.letraBotaoPadrao }}>Pagina Inicial</p>
                    </ButtonEntrar>

                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
        }
    );
};
export default connect(mapStateToProps, {

})(withRouter(NotFound));