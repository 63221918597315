import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';


import LoginReducer from './LoginReducer';
import CoresPadraoSistema from './CoresPadraoSistema';
import HeaderReducer from './HeaderReducer';
import DashBoardReducer from './DashBoardReducer';
import ChamadoReducer from './ChamadoReducer';
import PremiumReducer from './PremiumReducer';
import AtendenteReducer from './AtendenteReducer';
import SetorReducer from './SetorReducer';
import AvisosReducer from './AvisosReducer';
import AvaliacaoReducer from './AvaliacaoReducer';
import ConfiguracaoReducer from './ConfiguracaoReducer';
import NotificacaoReducer from './NotificacaoReducer';
import PerfilReducer from './PerfilReducer';
;
 
export default combineReducers({
  toastr: toastrReducer,
  LoginReducer,
  CoresPadraoSistema,
  HeaderReducer,
  ChamadoReducer,
  DashBoardReducer,
  PremiumReducer,
  AtendenteReducer,
  SetorReducer,
  AvisosReducer,
  AvaliacaoReducer,
  ConfiguracaoReducer,
  NotificacaoReducer,
  PerfilReducer
})

