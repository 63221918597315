import {
    MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA, ATIVADESATIVALOADINGTELACONFIGEMPRESA, ENVIANOMEEMPRESAPARAREDUCER,
    MODIFICANOMEEMPRESACONFIGURAEMPRESA, ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA,
    MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO, ATIVADESATIVALOADINGTELACONFIGAVALIACAO,
    ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO,
    ENVIAMENSAGEMAVALIACAOPARAREDUCER, ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO, ALTERAVALORSWITCHCONFIGATENDIMENTO,
    ENVIADADOSCONFIGURACAOATENDIMENTO, ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO, MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO,
    ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO, ALTERAAGRUPAATENDENTESETOR, ALTERAATENDENTEVEAPENASPROPRIOCHAMADO,
    VERIFICACRIARCHAMADOPROBLEMAESTATICO, ALTERAVALORSWITCHCONFIGCHAMADOESTATICO, MODIFICADESCRICAOPROBLEMAESTATICO,
    ATIVADESATIVALOADINGBOTAONOVOPROBLEMA, ENVIAPROBLEMAPARAPROBLEMASELECIONADO, ENVIAPROBLEMAPARAREDUCER, ATIVADESATIVALOADINGCARREGANDOPROBLEMAS,

    MODIFICAHORARIOOPERACAOSEGUNDAFEIRA, MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2, ALTERAVALORCHECKSEGUNDAEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA, MODIFICAHORARIOOPERACAOQUARTAFEIRA, MODIFICAHORARIOOPERACAOQUINTAFEIRA,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA, MODIFICAHORARIOOPERACAOSABADOFEIRA, MODIFICAHORARIOOPERACAODOMINGOFEIRA,
    MODIFICAHORARIOOPERACAOTERCAFEIRA2, MODIFICAHORARIOOPERACAOQUARTAFEIRA2, MODIFICAHORARIOOPERACAOQUINTAFEIRA2,
    MODIFICAHORARIOOPERACAOSEXTAFEIRA2, MODIFICAHORARIOOPERACAOSABADOFEIRA2, MODIFICAHORARIOOPERACAODOMINGOFEIRA2,
    ALTERAVALORCHECKDOMINGOEIRA, ALTERAVALORCHECKSABADOEIRA, ALTERAVALORCHECKSEXTAEIRA, ALTERAVALORCHECKQUINTAEIRA,
    ALTERAVALORCHECKQUARTAEIRA, ALTERAVALORCHECKTERCAEIRA, ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT,MODIFICAFUSOHORARIOSELECIONADO
} from '../actions/types';


const INITIAL_STATE = {
    nomeEmpresa: '',
    verificaConexaoInternetConfigEmpresa: '',
    loadingConfigEmpresa: false,
    loadingBotaoAlterarNomeConfigEmpresa: false,
    mensagemAvaliacao: '',
    switchMEnsagemAvaliacao: false,
    verificaConexaoInternetConfigAvaliacao: '',
    loadingConfiAvaliacao: false,
    loadingBotaoAlterarConfigAvaliacao: false,
    switchObrigatorioAtribuirAtendenteAtendimento: false,
    loadingConfigAtendimentos: true,
    loadingBotaoAlterarConfigAtendimento: false,
    verificaConexaoInternetConfigAtendimento: '',
    atendenteVeApenasProprioChamado: false,
    agruparAtendenteSetor: false,
    verificaCriarChamadoProblemaEstatico: false,
    problemasSelecionado: [],
    problemasEstaticos: [],
    loadingBotaoNovoPRoblema: false,
    descricaoProblemaEstatico: '',
    loadingCarregandoProblemas: false,


    segundaFeiraChat: true,
    tercaFeiraChat: true,
    quartaFeiraChat: true,
    quintaFeiraChat: true,
    sextaFeiraChat: true,
    sabadoChat: false,
    domingoChat: false,




    horarioOperacaoSegundaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSegundaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoTercaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoTercaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuartaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuartaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuintaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoQuintaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSextaFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSextaFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSabadoFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoSabadoFeira2: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoDomingoFeira: {
        start: "09:00",
        end: "18:00"
    },
    horarioOperacaoDomingoFeira2: {
        start: "09:00",
        end: "18:00"
    },
    switchUtilizar2HorariosChat: false,
    fusoHorarioSelelcionado: ''
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MENSAGEMSEMCONEXAOINTERNETCONFIGEMPRESA:
            return { ...state, verificaConexaoInternetConfigEmpresa: action.payload };
        case ATIVADESATIVALOADINGTELACONFIGEMPRESA:
            return { ...state, loadingConfigEmpresa: action.payload };
        case ENVIANOMEEMPRESAPARAREDUCER:
            return { ...state, nomeEmpresa: action.payload };
        case MODIFICANOMEEMPRESACONFIGURAEMPRESA:
            return { ...state, nomeEmpresa: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARNOMEEMPRESACONFIGEMPRESA:
            return { ...state, loadingBotaoAlterarNomeConfigEmpresa: action.payload };
        case MENSAGEMSEMCONEXAOINTERNETCONFIGAVALIACAO:
            return { ...state, verificaConexaoInternetConfigAvaliacao: action.payload };
        case ATIVADESATIVALOADINGTELACONFIGAVALIACAO:
            return { ...state, loadingConfiAvaliacao: action.payload };
        case ENVIASEDESEJAMANDARMENSAGEMAVALIACAOAPOSFINALIZARATENDIMENTO:
            return { ...state, switchMEnsagemAvaliacao: action.payload };
        case ENVIAMENSAGEMAVALIACAOPARAREDUCER:
            return { ...state, mensagemAvaliacao: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARCONFIGAVALIACAO:
            return { ...state, loadingBotaoAlterarConfigAvaliacao: action.payload };
        case ALTERAVALORSWITCHCONFIGATENDIMENTO:
            return { ...state, switchObrigatorioAtribuirAtendenteAtendimento: action.payload };
        case ENVIADADOSCONFIGURACAOATENDIMENTO:
            return { ...state, switchObrigatorioAtribuirAtendenteAtendimento: action.payload };
        case ATIVADESATIVALOADINGCONFIGURACAOATENDIMENTO:
            return { ...state, loadingConfigAtendimentos: action.payload };
        case MENSAGEMSEMCONEXAOINTERNETCONFIGURACAOATENDIMENTO:
            return { ...state, verificaConexaoInternetConfigAtendimento: action.payload };
        case ATIVADESATIVALOADINGBOTAOALTERARCONFIGURACAOATENDIMENTO:
            return { ...state, loadingBotaoAlterarConfigAtendimento: action.payload };
        case ALTERAAGRUPAATENDENTESETOR:
            return { ...state, agruparAtendenteSetor: action.payload };
        case ALTERAATENDENTEVEAPENASPROPRIOCHAMADO:
            return { ...state, atendenteVeApenasProprioChamado: action.payload };
        case VERIFICACRIARCHAMADOPROBLEMAESTATICO:
            return { ...state, verificaCriarChamadoProblemaEstatico: action.payload };
        case ALTERAVALORSWITCHCONFIGCHAMADOESTATICO:
            return { ...state, verificaCriarChamadoProblemaEstatico: action.payload };
        case MODIFICADESCRICAOPROBLEMAESTATICO:
            return { ...state, descricaoProblemaEstatico: action.payload };
        case ATIVADESATIVALOADINGBOTAONOVOPROBLEMA:
            return { ...state, loadingBotaoNovoPRoblema: action.payload };
        case ENVIAPROBLEMAPARAPROBLEMASELECIONADO:
            return { ...state, problemasSelecionado: action.payload };
        case ENVIAPROBLEMAPARAREDUCER:

            return { ...state, problemasEstaticos: action.payload };

        case ATIVADESATIVALOADINGCARREGANDOPROBLEMAS:
            return { ...state, loadingCarregandoProblemas: action.payload };


        case ALTERAVALORCHECKSEGUNDAEIRA:
            return { ...state, segundaFeiraChat: action.payload };
        case ALTERAVALORCHECKTERCAEIRA:
            return { ...state, tercaFeiraChat: action.payload };
        case ALTERAVALORCHECKQUARTAEIRA:
            return { ...state, quartaFeiraChat: action.payload };
        case ALTERAVALORCHECKQUINTAEIRA:
            return { ...state, quintaFeiraChat: action.payload };
        case ALTERAVALORCHECKSEXTAEIRA:
            return { ...state, sextaFeiraChat: action.payload };
        case ALTERAVALORCHECKSABADOEIRA:
            return { ...state, sabadoChat: action.payload };
        case ALTERAVALORCHECKDOMINGOEIRA:
            return { ...state, domingoChat: action.payload };

        case MODIFICAHORARIOOPERACAOSEGUNDAFEIRA:
            return { ...state, horarioOperacaoSegundaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOSEGUNDAFEIRA2:
            return { ...state, horarioOperacaoSegundaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOTERCAFEIRA:
            return { ...state, horarioOperacaoTercaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOTERCAFEIRA2:
            return { ...state, horarioOperacaoTercaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOQUARTAFEIRA:
            return { ...state, horarioOperacaoQuartaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOQUARTAFEIRA2:
            return { ...state, horarioOperacaoQuartaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOQUINTAFEIRA:
            return { ...state, horarioOperacaoQuintaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOQUINTAFEIRA2:
            return { ...state, horarioOperacaoQuintaFeira2: action.payload };


        case MODIFICAHORARIOOPERACAOSEXTAFEIRA:
            return { ...state, horarioOperacaoSextaFeira: action.payload };
        case MODIFICAHORARIOOPERACAOSEXTAFEIRA2:
            return { ...state, horarioOperacaoSextaFeira2: action.payload };

        case MODIFICAHORARIOOPERACAOSABADOFEIRA:
            return { ...state, horarioOperacaoSabadoFeira: action.payload };
        case MODIFICAHORARIOOPERACAOSABADOFEIRA2:
            return { ...state, horarioOperacaoSabadoFeira2: action.payload };

        case MODIFICAHORARIOOPERACAODOMINGOFEIRA:
            return { ...state, horarioOperacaoDomingoFeira: action.payload };
        case MODIFICAHORARIOOPERACAODOMINGOFEIRA2:
            return { ...state, horarioOperacaoDomingoFeira2: action.payload };
        case ALTERAVALORSWITCHUTILIZAR2HORARIOSCHAT:
            return { ...state, switchUtilizar2HorariosChat: action.payload };
        case MODIFICAFUSOHORARIOSELECIONADO:
            return { ...state, fusoHorarioSelelcionado: action.payload };
        default:
            return state;
    }
};