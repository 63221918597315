import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import firebase from '../../servicos/FirebaseConnection';
import ClipLoader from "react-spinners/ClipLoader";
import logoDropDesk from '../../imagens/logodropdesk2.png';
import { Modal } from 'react-responsive-modal';
import '../../fontello/css/fontello.css';
import logo1 from '../../imagens/background1.jpg';
import logo2 from '../../imagens/background2.jpg';
import logo3 from '../../imagens/background3.jpg';

import {
    modificaEmailLogin, modificaSenhaLogin, logarUsuario, modificaCorBordaInputEmailLogin, modificaCorBordaInputSenhaLogin,
    modificaErrorLoginTelaLogin, modificaErrorSenhaLogin, modificaCorIconeInputEmailLogin, modificaCorIconeInputSenhaLogin,
    modificaCheckBoxContinuarConectado, verificaSeUsuarioEstaLogadoSeNaoEstiverLoga, modificaModalEsqueciSenha,
    esqueciSenha, modificaCodigoReferencia, ativaDesativaLoadingBotaoLogarTelaLogin,modificaisCodeReference
} from '../../actions/LoginAction';
import {
    modificaLogoTipoTelaConfiguracao,
    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao,
} from '../../actions/ConfiguracaoAction';
import {
    insereLogoECorAtravesDaReferencia, modificaLoadingLogin
} from '../../actions/DashBoardAction';

import {
    Container, TextLogin, ContainerLogin, TextEntre, InputEmail, ContainerTextLogin, ContainerInputs,
    InputSenha, SubContainer, ContainerButton, ButtonEsqueciSenha, ButtonEntrar, ButtonCriarConta,
    CheckManterConectado, ContainerConectarAutomaticamente, SubContainerInputSenha, SubContainerInputEmail,
    ContainerHeader, ContainerHeaderLogo, DivCelular, TextCadastrar, MarcaDagua,
    ContainerModalPrioridadeAtendimento, ContainerIconeFecharModal, TextNovoAtendimento,
    IconeFechar, ContainerAtendentes, DivInputMotivoCancelamento, InputCancelamento,
    ButtonCancelarAtendimento, TextBotaoCancelarAtendimento,
} from './styled'

class Login extends React.Component {
    state = {
        checkBoxManterConectado: false,
        iconeEmailPressionadoCor: '#FFF',
        iconeSenhaPressionadoCor: '#FFF',
        email: '',
        senha: '',
        tipoInputSenha: 'password',
        visaoInputSenha: false,
        visaoInputConfirmaSenha: false,
        isLoggedIn: false
    }
    componentWillMount() {
    }

    componentDidMount() {

       /*  let listener = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    isLoggedIn: true
                })
                if (this.props.isadmin == false) {
                    this.props.history.push(("/atendimentos"));
                }

                this.props.ativaDesativaLoadingBotaoLogarTelaLogin(false)
                listener();
            } else {
                this.setState({
                    isLoggedIn: false
                })
                this.props.ativaDesativaLoadingBotaoLogarTelaLogin(false)
                listener();
            }
        }) */
        //localStorage.removeItem('login');
        //this.props.verificaSeUsuarioEstaLogadoSeNaoEstiverLoga(this.props.history);
        let paramskey = new URLSearchParams(this.props.history.location.search)
        let referencia = paramskey.get('ref');
    /*     console.log(referencia); */
        if (referencia !== null) {
            this.props.modificaCodigoReferencia(referencia);
            this.props.insereLogoECorAtravesDaReferencia(referencia);
            this.props.modificaisCodeReference(true);
        } else {
            let verificaSeTemCorSalva = JSON.parse(localStorage.getItem('backgroundSegundo'));
            let verificaSeLogoSalva = JSON.parse(localStorage.getItem('logoLogin'));
            if (verificaSeTemCorSalva !== null && verificaSeLogoSalva !== null) {
             /*    console.log('tem logo e cor') */

                this.props.modificaCorFundoPrincipal(JSON.parse(localStorage.getItem('backgroundPrimeiro')));
                this.props.modificaCorFundoBotaoPrincipal(JSON.parse(localStorage.getItem('corBotaoPadrao')));
                this.props.modificaCorFundoSecundario(JSON.parse(localStorage.getItem('backgroundSegundo')));
                this.props.modificaCorLetraPrincipal(JSON.parse(localStorage.getItem('coresLetraPadrao')));
                this.props.modificaCorLetraBotao(JSON.parse(localStorage.getItem('letraBotaoPadrao')));
                this.props.modificaLogoTipoTelaConfiguracao(JSON.parse(localStorage.getItem('logoLogin')));
            } else if (verificaSeTemCorSalva == null && verificaSeLogoSalva !== null) {
              /*   console.log('tem somente logo'); */
                let logo = JSON.parse(localStorage.getItem('logoLogin'));
                this.props.modificaLogoTipoTelaConfiguracao(logo)
            }
            else if (verificaSeTemCorSalva !== null && verificaSeLogoSalva == null) {
               /*  console.log('tem somente cor'); */
                this.props.modificaCorFundoPrincipal(JSON.parse(localStorage.getItem('backgroundPrimeiro')));
                this.props.modificaCorFundoBotaoPrincipal(JSON.parse(localStorage.getItem('corBotaoPadrao')));
                this.props.modificaCorFundoSecundario(JSON.parse(localStorage.getItem('backgroundSegundo')));
                this.props.modificaCorLetraPrincipal(JSON.parse(localStorage.getItem('coresLetraPadrao')));
                this.props.modificaCorLetraBotao(JSON.parse(localStorage.getItem('letraBotaoPadrao')));
            }
            else {
                this.props.modificaLogoTipoTelaConfiguracao('semURI')
                this.props.modificaLoadingLogin(false);
            }
        }

    }
    handleVisualizaSenha(value) {
        let state = this.state;
        state.tipoInputSenha = value;
        this.setState({ state });
    }
    handleInputEmailBlur() {


        let emailValido = this.validarEmail(this.props.email);
        if (emailValido) {
            this.props.modificaErrorLoginTelaLogin('');
            this.props.modificaCorIconeInputEmailLogin(this.props.corBotaoPadrao);
            this.props.modificaCorBordaInputEmailLogin('#FFF');
        } else {
            this.props.modificaErrorLoginTelaLogin('Digite um e-mail válido');
            this.props.modificaCorIconeInputEmailLogin('red');
            this.props.modificaCorBordaInputEmailLogin('red');
        }

    }
    handleInputEmailFocus() {
        if (this.props.mensagemDeErrorTelaLogin !== '') {


        } else {
            this.props.modificaCorBordaInputEmailLogin(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputEmailLogin(this.props.corBotaoPadrao);
        }
    }

    handleInputSenhaBlur() {
        if (this.props.senha.length >= 6) {
            this.props.modificaCorBordaInputSenhaLogin('#FFF');
            this.props.modificaErrorSenhaLogin('')
            this.props.modificaCorIconeInputSenhaLogin(this.props.corBotaoPadrao)
        } else if (this.props.senha.trim() === '') {
            this.props.modificaErrorSenhaLogin('Digite sua senha')
            this.props.modificaCorIconeInputSenhaLogin('red');
            this.props.modificaCorBordaInputSenhaLogin('red');
        }
        else {
            this.props.modificaErrorSenhaLogin('Digite uma senha com mais de 6 digitos')
            this.props.modificaCorIconeInputSenhaLogin('red');
            this.props.modificaCorBordaInputSenhaLogin('red');

        }
    }
    handleInputSenhaFocus() {
        if (this.props.mensagemDeErrorSenhaTelaLogin !== '') {

        } else {
            this.props.modificaCorBordaInputSenhaLogin(this.props.corBotaoPadrao);
            this.props.modificaCorIconeInputSenhaLogin(this.props.corBotaoPadrao);
        }

    }
    validarEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };
    handleSubmit(e) {
        e.preventDefault();
        this.props.logarUsuario(this.props.email, this.props.senha, this.props.history)
    }
    renderImagemLogo() {
        if (this.props.logotipo == null) {
            return (
                <div style={{ height: 190, width: 190, backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none', borderRadius: '50%' }} />
            )
        } else {
            return (
                <img src={this.props.logotipo === 'semURI' ? logoDropDesk : this.props.logotipo} alt="logo marca" style={{ height: 190, width: 190 }} />
            )
        }
    }
    renderLoadingLogin() {
        if (this.props.loadingLogin == true) {
            return (
                <div style={{ flex: 1, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFF' }}>
                    <ClipLoader
                        css={{ margin: 10 }}
                        size={40}
                        color={"#000"}
                        loading={true}
                    />

                </div>
            )
        } else {
            return (

                <Container backgroundDinamico={this.props.backgroundPrimeiro}>
                    <MarcaDagua style={{
                        opacity: 0.05,
                        /*      backgroundImage: "url(" + logo3 + ")",
                             backgroundPosition: 'center',
                             backgroundSize: 'cover',
                             backgroundRepeat: 'no-repeat', */

                    }}>
                        <img src={logo3} alt="Background DropDesk" height="100%" width="100%" />
                    </MarcaDagua>

                    <SubContainer>

                        <ContainerHeader>

                            <div style={{ display: 'flex', height: 190, width: 190, marginBottom: 30, marginTop: 30 }}>
                                {this.renderImagemLogo()}
                            </div>

                            <ContainerLogin backgroundDinamico={this.props.backgroundSegundo} onSubmit={(e) => this.handleSubmit(e)}>

                                <ContainerTextLogin>
                                    <TextLogin corLetraDinamico={this.props.coresLetraPadrao}>Login</TextLogin>
                                    <TextEntre corLetraDinamico={this.props.coresLetraPadrao}>Entre com a sua conta.</TextEntre>
                                </ContainerTextLogin>

                                <ContainerInputs>
                                    <SubContainerInputEmail backgroundDinamico={this.props.backgroundPrimeiro} corBordaInputEmail={this.props.corBordaInputEmailLogin}>
                                        <i className="icon-email" style={{ color: this.props.corIconeInputEmailLogin, marginRight: 5 }} />
                                        <InputEmail
                                            corLetraDinamico={this.props.coresLetraPadrao}
                                            disabled={this.props.loadingBotaoLogarTelaLogin}
                                            //  autoFocus
                                            type="text"
                                            placeholder="E-mail"
                                            value={this.props.email}
                                            onChange={(e) => { this.props.modificaEmailLogin(e.target.value) }}
                                            onFocus={() => { this.handleInputEmailFocus() }}
                                            onBlur={() => { this.handleInputEmailBlur() }}
                                        />
                                    </SubContainerInputEmail>
                                    {this.props.mensagemDeErrorTelaLogin !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.mensagemDeErrorTelaLogin}</p>}
                                    <SubContainerInputSenha backgroundDinamico={this.props.backgroundPrimeiro} corBordaInputSenha={this.props.corBordaInputSenhaLogin}>
                                        <i className="icon-senha" style={{ color: this.props.corIconeInputSenhaLogin, marginRight: 5 }} />
                                        <InputSenha
                                            corLetraDinamico={this.props.coresLetraPadrao}
                                            disabled={this.props.loadingBotaoLogarTelaLogin}
                                            type={this.state.tipoInputSenha}
                                            placeholder="Senha"
                                            value={this.props.senha}
                                            onChange={(e) => { this.props.modificaSenhaLogin(e.target.value) }}
                                            onFocus={() => { this.handleInputSenhaFocus() }}
                                            onBlur={() => { this.handleInputSenhaBlur() }}
                                        />
                                        {this.state.tipoInputSenha == 'password' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaSenha('text') }}>
                                            <i className="icon-semvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                        </button>}
                                        {this.state.tipoInputSenha == 'text' && <button type="button" style={{ backgroundColor: 'transparent', border: 0, height: 30 }} onClick={() => { this.handleVisualizaSenha('password') }}>
                                            <i className="icon-comvisao" style={{ color: '#FFF', fontSize: 17 }} />
                                        </button>}
                                    </SubContainerInputSenha>
                                    {this.props.mensagemDeErrorSenhaTelaLogin !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.mensagemDeErrorSenhaTelaLogin}</p>}
                                    <ContainerConectarAutomaticamente>
                                        {/*   <CheckManterConectado
                                    type="checkbox"
                                    //value={this.props.checkBoxConectarAutomaticamente}
                                    checked={this.props.checkBoxConectarAutomaticamente}
                                    onChange={(value) => { this.props.modificaCheckBoxContinuarConectado(value.target.checked) }}
                                // onClick={() => { alert('manter conectado') }}
                                />
                                <span style={{ fontSize: 12, marginLeft: 4, color: '#FFF' }}>Conectar automaticamente</span> */}
                                        <ButtonEsqueciSenha type="button"
                                            onClick={() => {
                                                this.props.modificaModalEsqueciSenha(true);
                                            }}>
                                            Esqueci minha senha
                            </ButtonEsqueciSenha>
                                    </ContainerConectarAutomaticamente>
                                    <ContainerButton>

                                        <ClipLoader
                                            css={{ margin: 10 }}
                                            size={40}
                                            color={this.props.corBotaoPadrao}
                                            loading={this.props.loadingBotaoLogarTelaLogin}
                                        />

                                        {this.props.loadingBotaoLogarTelaLogin == false && <ButtonEntrar corLetraDinamico={this.props.corBotaoPadrao} type="submit" onClick={(e) => this.handleSubmit(e)}>
                                            ENTRAR
                            </ButtonEntrar>}

                                    </ContainerButton>
                                    <Link to="/cadastro" style={{ textDecoration: 'none', color: '#FFF', height: 35 }}><ButtonCriarConta corLetraDinamico={this.props.coresLetraPadrao} type="button" onClick={() => { }}>
                                        Ainda não criou sua conta? <TextCadastrar>Cadastre-se</TextCadastrar>
                                    </ButtonCriarConta></Link>

                                </ContainerInputs>

                            </ContainerLogin>
                            <div></div>
                        </ContainerHeader>
                        {/*  <input
                style={{ height: 40, width: 150 }}
                value={this.props.email}
                onChange={(e) => { this.props.modificaEmailLogin(e.target.value) }}
            ></input>
            <p>{this.props.email}</p>
            <Link to="/dashboard">Dashboard</Link>
            <button>teste</button> */}
                    </SubContainer>
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalEsqueciSenha} onClose={() => { }} center>
                        <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Esqueceu a Senha?</TextNovoAtendimento>
                                <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaModalEsqueciSenha(false) }} className="icon-close" />
                            </ContainerIconeFecharModal>

                            <ContainerAtendentes>

                                <DivInputMotivoCancelamento >
                                    <p style={{ color: '#FFF', fontSize: 20, textAlign: 'center', marginTop: 10 }}>Informe seu e-mail que enviaremos instruções para criar uma nova senha:</p>
                                    <SubContainerInputEmail corBordaInputEmail={this.props.corBordaInputEmailLogin}>
                                        <i className="icon-email" style={{ color: this.props.corIconeInputEmailLogin, marginRight: 5 }} />
                                        <InputCancelamento
                                            disabled={this.props.loadingBotaoLogarTelaLogin}
                                            autoFocus
                                            type="text"
                                            placeholder="E-mail"
                                            value={this.props.email}
                                            onChange={(e) => { this.props.modificaEmailLogin(e.target.value) }}
                                            onFocus={() => { this.handleInputEmailFocus() }}
                                            onBlur={() => { this.handleInputEmailBlur() }}
                                        />

                                    </SubContainerInputEmail>
                                    {this.props.mensagemDeErrorTelaLogin !== '' && <p style={{ color: 'red', fontSize: 13, marginLeft: 10, fontStyle: 'italic' }}>{this.props.mensagemDeErrorTelaLogin}</p>}
                                </DivInputMotivoCancelamento>

                                <ClipLoader
                                    css={{ margin: 10 }}
                                    size={40}
                                    color={"#00FF7F"}
                                    loading={this.props.loadingBotaoEsqueciSenha}
                                />

                                {this.props.loadingBotaoEsqueciSenha == false &&

                                    <ButtonCancelarAtendimento onClick={() => { this.props.esqueciSenha(this.props.email) }} backgroundBotao={this.props.corBotaoPadrao}>
                                        <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>ENVIAR</TextBotaoCancelarAtendimento>
                                    </ButtonCancelarAtendimento>}

                            </ContainerAtendentes>
                        </ContainerModalPrioridadeAtendimento>
                    </Modal>
                </Container>

            );
        }
    }

    render() {
        return (
            <>
                {this.renderLoadingLogin()}
            </>
        )
    }
}


const mapStateToProps = state => {
    return (
        {
            email: state.LoginReducer.email,
            senha: state.LoginReducer.senha,
            mensagemDeErrorTelaLogin: state.LoginReducer.mensagemDeErrorTelaLogin,
            loadingBotaoLogarTelaLogin: state.LoginReducer.loadingBotaoLogarTelaLogin,
            statusConexao: state.LoginReducer.statusConexao,
            mensagemDeSucessoTelaCadastro: state.LoginReducer.mensagemDeSucessoTelaCadastro,
            verificaInternetLogin: state.LoginReducer.verificaInternetLogin,
            corBordaInputEmailLogin: state.LoginReducer.corBordaInputEmailLogin,
            corBordaInputSenhaLogin: state.LoginReducer.corBordaInputSenhaLogin,
            mensagemDeErrorSenhaTelaLogin: state.LoginReducer.mensagemDeErrorSenhaTelaLogin,
            corIconeInputEmailLogin: state.LoginReducer.corIconeInputEmailLogin,
            corIconeInputSenhaLogin: state.LoginReducer.corIconeInputSenhaLogin,
            loadingLogin: state.LoginReducer.loadingLogin,
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,

            checkBoxConectarAutomaticamente: state.LoginReducer.checkBoxConectarAutomaticamente,
            loadingBotaoEsqueciSenha: state.LoginReducer.loadingBotaoEsqueciSenha,
            modalEsqueciSenha: state.LoginReducer.modalEsqueciSenha,
            logotipo: state.CoresPadraoSistema.logotipo,
            isadmin: state.LoginReducer.isadmin,
        }
    );
};
export default connect(mapStateToProps, {
    modificaEmailLogin, modificaSenhaLogin, logarUsuario, modificaCorBordaInputEmailLogin, modificaCorBordaInputSenhaLogin,
    modificaErrorLoginTelaLogin, modificaErrorSenhaLogin, modificaCorIconeInputEmailLogin, modificaCorIconeInputSenhaLogin,
    modificaCheckBoxContinuarConectado, verificaSeUsuarioEstaLogadoSeNaoEstiverLoga, modificaModalEsqueciSenha,
    esqueciSenha, modificaCodigoReferencia, insereLogoECorAtravesDaReferencia, modificaLoadingLogin, modificaLogoTipoTelaConfiguracao,
    modificaCorFundoPrincipal, modificaCorFundoSecundario, modificaCorFundoItensSelecionaveis, modificaCorFundoBotaoPrincipal,
    modificaCorFundoBotaoSecundario, modificaCorLetraPrincipal, modificaCorLetraSecundaria,
    modificaCorDescricaoDosCampos, modificaCorLetraBotao, ativaDesativaLoadingBotaoLogarTelaLogin,modificaisCodeReference
})(withRouter(Login));
