import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import firebase from '../../servicos/FirebaseConnection';
import moment from 'moment';
import FlatList from 'flatlist-react';
import { saveAs } from 'file-saver';
import Linkify from 'react-linkify';
import MoonLoader from "react-spinners/MoonLoader";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { Modal } from 'react-responsive-modal';
import SelectSearch from 'react-select-search';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isSameDay } from 'react-dates';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone';
import MicRecorder from 'mic-recorder-to-mp3';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { Picker } from 'emoji-mart';
import 'react-h5-audio-player/lib/styles.css';
import 'emoji-mart/css/emoji-mart.css'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

//daqui pra baixo
import { MessageBox, MeetingMessage, SystemMessage, Popup, MessageList, Dropdown, LocationMessage, Input } from 'react-chat-elements';
//import './chatelements.css';
import vCard from 'vcard-parser';


import {
    Container, ContainerAcoes, ButtonVariosNovos, IconeVariosNovos, TextBotaoNovo,
    TextBotaoNovoTempoGasto, TextProblema, ContainerDataAbertura, TextAbertura, TextDataHoraAbertura,
    ContainerDadosDoChamado, ContainerDadosAbertura, ContainerDadosChamadoPrimeiro, ContainerDadosChamadoSegundo,
    ContainerDadosProblema, ContainerDadosAberturaFechamento, ContainerIconeMaisInformacoes,
    ContainerBotoesAcoes, ContainerIconesLaterais, IconesLateras, IconeAlterar, ContainerIconeLateral,
    ContainerComentario, DivAdicionarComentario, TextAddComent, ContainerMaisInformacoes,
    SubContainerMaisInformacoes, DivLAbelCliente, DivCliente, LabelCliente, DivSeparadoraMaisInformacoes,
    DivTruncarTexto, ContainerTimeLine, TextTitulo, TextMensagem, ImagemComentario, TextBaixarArquivo,
    ContainerClipBaixarArquivo, TextAvaliar,

    ContainerModal, ContainerIconeFecharModal, IconeFechar, TextNovoAtendimento,
    DivAdicionarMaisDetalhes, InputDataVencimento, SubContainerNovoAtendimento, DivBotaoNovoAtendimento,
    BotaoCadastrarAtendimento, TextCadastrar, ContainerInputDescricaoChamado, InputDescricaoChamado,
    ContainerSelectSearch, ContainerDetalhesModalNovoAtendimento, BotaoAdd, IconeClip,

    DivAtendentes, TextAtendentes, ContainerAtendentes, TextCabecalhoModalAcoes, ContainerTextCabecalhoModalAcoes,
    ContainerModalStatusAtendimento, ContainerModalPrioridadeAtendimento, DivInputMotivoCancelamento, InputCancelamento,
    ButtonCancelarAtendimento, TextBotaoCancelarAtendimento, ButtonNaoCancelarAtendimento, DivBotaoSalvarComentario,
    ButtonSalvarComentario, TextBotaoSalvarComentar, ButtonSalvarComentarioSemComent, DivBotaoComentarioInternoPublico,
    TextComentario, BotaoSelecionarCOment, DivInputComentarChamado, ContainerInputEBotaoComent,
    InputSearch, DivInputSearch, IconeSetaPraBaixo, BotaoDesativado, ContainerIconeLateralDesativado,
    DivAdicionarComentarioDesativado, TextProblemaLoading, TextDataHoraAberturaLoading,
    ContainerIconeMaisInformacoesLoading, ButtonVariosNovosLoading, ContainerIconeLateralLoading,
    DivAdicionarComentarioLoading, ContainerTimeLineLoading, IconeSetaPraBaixoDataVencimento,
    ContainerPermitir, TextPermitir, DivTextoReabrirAtendimento, ContainerModalAlterar,
    ContainerModalAvaliacoes, ContainerModalGravarAudio, TextGravandoAudio, ContainerIconeResposta,
    ContainerIconeRespostaChamadoFechado, TextResponder, InputDescricaoChamadoComentaChamado,
    TextTituloAvaliar
} from './styled';

import {
    modificaVisibilidadeModalAlterarAtendimento, modificaVisibilidadeModalAtribuirAtendimento,
    modificaVisibilidadeModalAddTempoGastoAtendimento, modificaVisibilidadeModalFinalizarAtendimento,
    modificaVisibilidadeModalPrioridadeAtendimento, modificaVisibilidadeModalCancelarAtendimento,
    modificavisibilidadeModalComentario, modificaInputComentario, modificaComentarioPublicoSimOuNao,
    modificaVisibilidadeBotaoAddVencimento, listaChamadoParaTelaAcoesChamado,
    modificaClienteSelecionado, modificaAtendenteSelecionado, modificaLocalSelecionado,
    modificaPrioridadeSelecionado, modificaDataVencimentoTelaChamadoAbertura, modificaSetorSelecionado,
    alteraDescricaoChamado, modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado,
    alterarChamado, salvarComentario2, atribuiAtendimentoAh, atribuirTempoEsperaAoChamado,
    atribuiStatusAtendimento, alterarPrioridadeAtendimento, alterarDataVencimentoAtendimento, modificaMotivoCancelamentoTelaAcoesAtendimento,
    cancelarChamado, ativaDesativaSwitchComentario, reabrirChamado,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaRetornaTelaClienteParaNovoAtendimento,
    modificaNumeroAtendimento, listaSetorTelaNovoChamado, abreModalAvaliarChamado, modificaComentarioAvaliacao,
    cadastraAvaliacaoChamado, modificaProblemaEstaticoSelecionado, modificaNomeProblemaEstaticoSelecionado,
    listaProblemasEstaticosTelaNovoChamado, alterarChamado2,
    alteraNumeroAtendimento, alteraKeyAtendimento, modificaResolucaoProblema,
    listaAcoesChamadoConversasAcoesChamado, listaConversasAntigasResponderAcoes,
    enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado, enviaConversasAntigasChatExportAcoesChamado,
    deletaMensagemDropDesk
} from '../../actions/ChamadoAction';

import { modificaNomeRotaNoHeader } from '../../actions/HeaderAction';

import { exportaAtendimentoParaPDF } from '../../actions/DashBoardAction';
moment.locale('pt-br', {
    months: 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_')
    //  weekdays:'Seg_Ter_Qua_Qui_Sex_Sab_Dom'.split('_'),
})

const Mp3Recorder = new MicRecorder({ bitRate: 128 });
class AcoesChamado extends React.Component {
    state = {
        date: null,
        focused: true,
        modalReabrirChamado: false,
        maisInformacoes: false,
        testeTimeLine: true,
        mostrarMaisDetalhesChamado: false,
        backgroundColor: this.props.corBotaoPadrao,
        backgroundColor2: 'transparent',
        placeholderInputComent: 'Comentário visível para clientes e atendentes',
        nomeAuxiliarCliente: '',
        nomeAuxiliarAtendente: '',
        nomeAuxiliarSetor: '',
        colorTextComent: this.props.letraBotaoPadrao,
        colorTextComent2: this.props.coresLetraPadrao,
        isSelectedRadioButton5estrelas: false,
        isSelectedRadioButton4estrelas: false,
        isSelectedRadioButton3estrelas: false,
        isSelectedRadioButton2estrelas: false,
        isSelectedRadioButton1estrelas: false,
        modalSairVisible: false,
        valorAvaliacao: 0,

        viewEmoji: false,
        viewMicrophone: false,
        recordSecs: 0,
        bufferAudio: '',
        recordTime: '00:00:00',
        currentPositionSec: 0,
        currentDurationSec: 0,
        playTime: '0:00',
        duration: '00:00:00',
        statusAudio: 'gravar',
        currentDurationSecSlider: 0,
        nomeAudio: '',
        uriAudioFile: '',
        timer: null,
        colorTextComent: this.props.letraBotaoPadrao,
        colorTextComent2: this.props.coresLetraPadrao,
        audioDetails: {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        },
        blobAudioPuro: '',
        isRecording: false,
        blobURL: '',
        isBlocked: false,
        viewResponder: false,
        commentResponder: '',
        idResponder: '',
        circleColorResponder: '',
        commentResponderType: null,
        focusMessageBox: 'vazio',
        atendenteResponder: '',
        dadosMensagemExcluida: {},
        modalMensagemExcluida: false
    }

    componentDidMount() {

        /*      console.log('no did mount acoes') */
        let paramskey = new URLSearchParams(this.props.history.location.search)
        let keyAtendimento = paramskey.get('key')
        let numeroChamado = paramskey.get('num')
        /*   console.log(keyAtendimento, numeroChamado)
          console.log(this.props.keyAtendimento) */
        let keyAtendimentoStorage = localStorage.getItem("keyAtendimentoCliente");
        /*  console.log(keyAtendimentoStorage) */
        if (keyAtendimento !== null && numeroChamado !== null) {
            /* console.log('1')
            console.log(keyAtendimento) */
            this.props.alteraKeyAtendimento(keyAtendimento)
            //  this.props.alteraNumeroAtendimento(numeroChamado)
            //this.props.listaChamadoParaTelaAcoesChamado(keyAtendimento);
            this.props.modificaNomeRotaNoHeader(`Atendimento #${numeroChamado}`);
            //   this.props.listaSetorTelaNovoChamado();
            //   this.props.listaProblemasEstaticosTelaNovoChamado();
            localStorage.setItem(`keyAtendimentoCliente`, JSON.stringify(keyAtendimento));
            localStorage.setItem(`numeroAtendimentoCliente`, JSON.stringify(numeroChamado));
        }
        else if (this.props.keyAtendimento.length == 0 && keyAtendimentoStorage !== null) {
            /*  console.log('2') */

            /*  console.log('key buscado do local storage') */
            let keyAtendimento = JSON.parse(localStorage.getItem("keyAtendimentoCliente"));
            /*  console.log(keyAtendimento) */
            this.props.alteraKeyAtendimento(keyAtendimento)
            //  this.props.listaSetorTelaNovoChamado();
            //  this.props.listaProblemasEstaticosTelaNovoChamado();

            // this.props.alteraNumeroAtendimento(numeroChamado)
            // this.props.listaChamadoParaTelaAcoesChamado(keyAtendimento);
            let numeroAtendimento = JSON.parse(localStorage.getItem("numeroAtendimentoCliente"));
            this.props.modificaNomeRotaNoHeader(`Atendimento #${numeroAtendimento}`)
        } else if (this.props.keyAtendimento.length > 0) {
            //  this.props.alteraKeyAtendimento(this.props.keyAtendimento)
            console.log('3')
            console.log(this.props.keyAtendimento)
            this.props.listaChamadoParaTelaAcoesChamado(this.props.keyAtendimento);
            localStorage.setItem(`keyAtendimentoCliente`, JSON.stringify(this.props.keyAtendimento));
            localStorage.setItem(`numeroAtendimentoCliente`, JSON.stringify(this.props.numeroAtendimento));

        } else {
            this.props.history.push(("/atendimentos"));
            toastr.warning('Houve um problema', 'Entre no atendimento novamente')
            /*   console.log('sem atendimento via URL, sem atendimento no this.props.keyatendimento e sem atendimento no localStorage, retorna para tela de atendimentos') */
        }
        /* if (this.props.criarChamadoProblemaEstatico == true && this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 1) {
            this.props.modificaProblemaEstaticoSelecionado(this.props.dadosAtendimentos[0].keyProblemaEstatico);
            this.props.modificaNomeProblemaEstaticoSelecionado(this.props.dadosAtendimentos[0].descricaoProblema);

        } */

        /*  if (!this.props.nomeRota.includes('Atendimento #')) {
             this.props.modificaNomeRotaNoHeader('Atendimento #')
         } */
        /*  document.addEventListener("click", this.handleDocumentClick, false); */
        ReactTooltip.rebuild();
    }
    componentWillReceiveProps(nextProps) {

        if (this.props.keyAtendimento !== nextProps.keyAtendimento) {
            /*           console.log('key atendimento diferente') */
            if (this.props.dadosAtendimentos.length > 0) {
                /*  console.log('dentro do next props key atendimento')
                 console.log(nextProps.keyAtendimento);
                 console.log(this.props.keyAtendimento); */
                localStorage.setItem(`keyAtendimentoCliente`, JSON.stringify(nextProps.keyAtendimento));
                localStorage.setItem(`numeroAtendimentoCliente`, JSON.stringify(this.props.numeroAtendimento));
                this.props.listaChamadoParaTelaAcoesChamado(nextProps.keyAtendimento);
                firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).off("value");
                firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/chamados/`).orderByKey().equalTo(this.props.keyAtendimento).off("value")
            } else {
                /*  console.log('dentro do next props key atendimento chave unica undefined')
                 console.log(nextProps.keyAtendimento);
                 console.log(this.props.keyAtendimento); */
                localStorage.setItem(`keyAtendimentoCliente`, JSON.stringify(nextProps.keyAtendimento));
                this.props.listaChamadoParaTelaAcoesChamado(nextProps.keyAtendimento);
            }

        }
        if (this.props.loadingCarregandoRespostasAntigasAcoesChamado === true && nextProps.loadingCarregandoRespostasAntigasAcoesChamado === false) {
            if (nextProps.idRespostaAntigaAcoesChamado !== '') {
                console.log('gerando foco')
                console.log(nextProps.idRespostaAntigaAcoesChamado)
                this.setState({ focusMessageBox: nextProps.idRespostaAntigaAcoesChamado })
            }
        }
        ReactTooltip.rebuild();
    }
    componentWillUnmount() {
        console.log('unmount acoes atendimento chamado');
        console.log(this.props.dadosAtendimentos.length)
        if (this.props.dadosAtendimentos.length > 0) {
            firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/acoesChamados/${this.props.keyAtendimento}`).off("value");
            firebase.database().ref(`${this.props.dadosAtendimentos[0].chaveUnica}/chamados/`).orderByKey().equalTo(this.props.keyAtendimento).off("value")
        }
    }
    transformaMinutosEmSegundos(s) {

        function duas_casas(numero) {
            if (numero <= 9) {
                numero = "0" + numero;
            }
            return numero;
        }

        let hora = duas_casas(Math.trunc(s / 3600));
        let minuto = duas_casas(Math.trunc((s % 3600) / 60));
        let segundo = duas_casas((s % 3600) % 60);

        let formatado = hora + ":" + minuto + ":" + segundo;

        return formatado;
    }
    handleDocumentClick = event => {
        let isEmojiClassFound = false;

        event &&
            event.path &&
            event.path.forEach(elem => {
                if (elem && elem.classList) {
                    const data = elem.classList.value;
                    if (data.includes("emoji")) {
                        isEmojiClassFound = true;
                    }
                }
            }); // end
        if (isEmojiClassFound === false && event.target.id !== "emojis-btn")
            this.setState({ viewEmoji: false })
    };
    renderTextGravacaoAudio() {
        if (this.state.statusAudio == 'gravar') {
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Pressione no Microphone para Gravar</p>
            )
        } else if (this.state.statusAudio == 'gravando') {
            return (
                <TextGravandoAudio corDinamico={this.props.coresLetraPadrao} >GRAVANDO</TextGravandoAudio>
            )
        } else {
            //play audio
            return (
                <p style={{
                    color: this.props.coresLetraPadrao,
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 18,
                    fontFamily: 'Source Sans Pro'
                }}>Gravação</p>
            )
        }

    }
    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }
    millisToMinutesAndSeconds(millis) {
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
    handleRest() {
        const reset = {
            url: null,
            blob: null,
            chunks: null,
            duration: {
                h: null,
                m: null,
                s: null,
            }
        }
        this.setState({ audioDetails: reset });
    }
    renderBotaoGravarAudio() {

        if (this.state.statusAudio == 'gravar') {
            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: '#F50257', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'
                    }} onClick={() => this.onStartRecord()}>
                        <i className="icon-microphone" style={{ fontSize: 28, color: '#FFF' }} />
                    </div>
                </div>
            )
        } else if (this.state.statusAudio == 'gravando') {

            return (
                <div style={{ display: 'flex', height: 100, justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>

                    <div style={{
                        display: 'flex', height: 60, width: 60, borderRadius: 50, backgroundColor: this.props.corSegundoBotaoPadrao, justifyContent: 'center', alignItems: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        /*     setTimeout(() => {
                                this.setState({
                                    recordSecs: 0,
                                    statusAudio: 'gravacao'
                                });
                            }, 100);
                            AudioManager.stopRecord(); */


                        this.onStopRecord(false);

                    }}>
                        <i className="icon-stop-button-black-rounded-square" style={{ fontSize: 19, color: this.props.letraBotaoPadrao }} />
                    </div>
                </div>
            )
        } else {
            //play audio

            return (
                <div style={{ height: 300, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <audio src={this.state.blobURL} controls />
                    </div>
                    <div style={{ display: 'flex', height: 65, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corSegundoBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => {

                            this.onStopRecord(true)
                        }}>
                            {/*   <Icon name="rubbish-bin" size={15} color={'#EC6A69'} style={{ marginRight: 3 }}
                            /> */}
                            {/*  <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Limpar</p>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: 45, width: 90, background: this.props.corBotaoPadrao, borderRadius: 10, cursor: 'pointer' }} onClick={() => { this.enviarAudioFirebase() }}>
                            {/*      <i className="icon-download" style={{ fontSize: 15, color: this.props.corBotaoPadrao }} /> */}
                            <p style={{ color: this.props.letraBotaoPadrao, fontSize: 17, fontWeight: 'bold' }}>Enviar</p>
                        </div>
                    </div>
                    {/* <TouchableOpacity
                        style={styles.viewBarWrapper}
                        onPress={(e) => { this.onStatusPress(e) }}
                    >
                        <View style={styles.viewBar}>
                            <View style={[styles.viewBarPlay, { width: playWidth }]} />
                        </View>
                    </TouchableOpacity>*/}


                </div >
            )
        }

    }
    onStartRecord = () => {
        /* navigator.getUserMedia({ audio: true },
            () => {
                console.log('Permissão concedida');
                this.setState({ isBlocked: false });
                if (this.state.isBlocked) {
                    console.log('Permission Denied');
                } else { */
                    Mp3Recorder
                        .start()
                        .then((e) => {
                            console.log(e)
                            this.setState({ isRecording: true, statusAudio: 'gravando' });
                            let timer = setInterval(this.tick, 1000);
                            this.setState({ timer });
                        }).catch((e) => console.error(e));
               /*  }
            },
            () => {
                console.log('Permissão negada ');
                this.setState({ isBlocked: true })
            },
        ); */
    }
    onStopRecord = (apenasLimpar) => {
        if (apenasLimpar == true) {
            if (this.state.isRecording) {
                Mp3Recorder.stop()
                    .getMp3()
                    .then(([buffer, blob]) => {
                        clearInterval(this.state.timer);
                        this.setState({
                            blobURL: '',
                            isRecording: false,
                            recordSecs: 0,
                            blobAudioPuro: '',
                            statusAudio: 'gravar',
                            timer: null,
                            bufferAudio: ''
                        });

                    }).catch((e) => console.log(e));
            } else {
                clearInterval(this.state.timer);
                this.setState({
                    blobURL: '',
                    isRecording: false,
                    recordSecs: 0,
                    blobAudioPuro: '',
                    statusAudio: 'gravar',
                    timer: null,
                    bufferAudio: ''
                });
            }

        } else {
            Mp3Recorder.stop()
                .getMp3()
                .then(([buffer, blob]) => {

                    console.log(buffer)
                    console.log(blob)
                    const blobURL = URL.createObjectURL(blob)
                    console.log(blobURL)
                    clearInterval(this.state.timer);
                    this.setState({
                        blobURL,
                        isRecording: false,
                        //recordSecs: 0,
                        blobAudioPuro: blob,
                        statusAudio: 'gravacao',
                        timer: null,
                        bufferAudio: buffer
                    });

                    /* const file = new File(buffer, `audioweb${new Date().getTime()}.mp3`, {
                        type: blob.type,
                        lastModified: Date.now()
                    });
    
                    const player = new Audio(URL.createObjectURL(file));
                    player.controls = true; */
                    //  player.play();

                }).catch((e) => console.log(e));
        }
    }
    tick = () => {
        this.setState({
            recordSecs: this.state.recordSecs + 1
        });
    }
    milliseconds = (h, m, s) => ((h * 60 * 60 + m * 60 + s) * 1000);
    getFileBlob = (url, cb) => {
        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function () {
            cb(xhr.response);
        });
        xhr.send();
    };
    handleAudioStop(data) {
        console.log(data)
        this.setState({ audioDetails: data });
    }
    enviarAudioFirebase() {
        this._toggleModalGravarAudio();
        console.log(this.state.bufferAudio)
        console.log(this.state.blobAudioPuro)
        let duracao = (this.state.recordSecs * 1000);
        let duracaoNan = Number.isNaN(duracao)
        if (duracaoNan == true) {
            duracao = 1000
        }
        const file = new File(this.state.bufferAudio, `audioweb-${new Date().getTime()}.mp3`, {
            type: this.state.blobAudioPuro.type,
            lastModified: Date.now()
        });
        file.audio = true;
        file.duracao = duracao;
        console.log(file)
        let res = [file]
        console.log(res)

        if (res.length > 0) {

            if (this.props.premium == 0) {
                toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
            } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
            }
            else {
                //  console.log('tudo ok 3')
                /*   this.props.salvarComentario('tem arquivo sim', false, '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0]) */
                this.props.salvarComentario2('tem arquivo sim', false, '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0], this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamado, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.licensawebouapp, false, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                    this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder);
                this.zeraMensagemResponder()
                this.onStopRecord(true)
            }

        } else {
            toastr.error('Error ao Enviar Audio', 'Tente novamente');
        }
        //   let duracaoMilisegundos = this.milliseconds(this.state.audioDetails.duration.h, this.state.audioDetails.duration.m, this.state.audioDetails.duration.s)
        //  let duracao = duracaoMilisegundos;

    }
    addEmoji = (event, emojiObject) => {

        this.props.alteraComentarioChat(this.props.comentarioChat + event.native)
    };
    _toggleModalGravarAudio() {
        this.handleRest()
        this.setState({ viewMicrophone: !this.state.viewMicrophone });
    }
    _toggleModal() {
        /*  let state = this.state;
         state.modalSelecionarOutroPeriodo = !this.state.modalSelecionarOutroPeriodo; */
        this.setState({ modalSairVisible: !this.state.modalSairVisible });
    }
    handleMaisInformacoes() {
        let state = this.state;
        state.maisInformacoes = !this.state.maisInformacoes;
        this.setState({ state });
    }
    handleWeekDays = (day) => {
        //Change week day with custom day array
        day._locale._weekdaysMin = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
        // return the actual dates value(like 1,2,3 ...) from the moment object.
        return (day.format('D'));
    }
    renderArquivosAnexadosAoChamado() {
        if (this.props.loadingArquivosUpadosVinculadoAoChamado) {
            return (
                <p
                    style={{
                        paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao, textAlign: 'center'
                    }}>
                    Carregando Arquivos...</p>
            )
        } else {
            return (

                <FlatList
                    list={this.props.arquivosUpadosVinculadoAoChamado}
                    renderItem={(item) => this.renderItemArquivosAnexadosAoChamado(item)}
                    renderWhenEmpty={() => null}
                //   keyExtractor={(item, index) => item.url}

                />

            )
        }
    }
    abrirTelaAvaliacao() {

        if (this.props.dadosAtendimentos[0].atendente == 'Não informado') {
            toastr.warning(
                'Opss',
                'Este atendimento não possui um ATENDENTE vinculado, sendo impossível efetuar a avaliação.',
            );
        } else {
            /*  console.log('Abre a tela de avaliacao'); */
            this.props.abreModalAvaliarChamado(true);
        }
    }
    renderItemArquivosAnexadosAoChamado(item) {
        return (
            <div key={item.url}
                style={{ display: 'flex', flexDirection: 'row', margin: 10, alignItems: 'center', cursor: 'pointer' }}
            // onClick={() => { this.permissaoBaixarARquivoAnexadoChamado(item) }}
            >
                {/* <p
                    style={{
                        paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao,
                        textDecorationLine: 'underline'
                    }}>
                    {item.nome}</p> */}
                <a style={{
                    paddingLeft: 10, fontFamily: 'Source Sans Pro', color: this.props.corBotaoPadrao,
                    textDecorationLine: 'underline'
                }} href={item.url} target="_blank">{item.nome}</a>
                <i className="icon-download" style={{ fontSize: 13, marginLeft: 10, color: this.props.corBotaoPadrao }} />

            </div>

        )
    }
    permissaoBaixarARquivoAnexadoChamado(item) {
        try {
            /*      console.log(item);
                 console.log(item.tipo)
                 console.log(item.url) */
            let date = new Date()
            this.download(item.url, item.name)
        } catch (err) {
            /*   console.log(err); */
        }

    }
    handleDatePicked(date) {

        let dataVencimentoALterada = moment(date).format('DD/MM/YYYY');
        this.props.alterarDataVencimentoAtendimento(this.props.keyAtendimento, dataVencimentoALterada, this.props.dadosAtendimentos[0].dataVencimento, this.props.dadosAtendimentos[0])
        //    this.props.modificaDataVencimentoTelaChamadoAbertura(moment(date).format('DD/MM/YYYY'));


    };
    renderDescriptionTimeline(item) {
        /*   console.log('dentro do description')
          console.log(item) */
        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width:"100%"
                    }}
                >

                    <TextTitulo corLetraDinamica={this.props.coresLetraPadrao}>
                        {item.description}
                    </TextTitulo>
                    <TextAvaliar onClick={() => {
                        if (this.props.verificaSeJaFezAvaliacaoChamado == 1) {
                            this.abrirTelaAvaliacaoJaFeita();
                        } else {
                            this.abrirTelaAvaliacao();
                        }
                    }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextAvaliar>
                    <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                        <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                    </div>
                </TimelineItem>
            )


        } else {
            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width:"100%"
                    }}
                >
                    <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a style={{ fontSize: 13, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    >
                        <TextMensagem corLetraDinamica={this.props.coresLetraPadrao}>
                            {item.description}
                        </TextMensagem>
                    </Linkify>
                    <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                        <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                    </div>
                </TimelineItem>
            )
        }
    }
    renderTimeLineItem(item) {
        if (item.tipo == 'mensagem') {
            return (
                <div key={item.key}>
                    {this.renderDescriptionTimeline(item)}
                </div>
            )
        } else {
            /*  console.log(item) */
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        /*    console.log('é uma imagem SEM URL');
                           console.log(item.urlDownload); */
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description,item) }} style={{}}>
                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                </ContainerClipBaixarArquivo>
                                <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                    <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                                </div>
                            </TimelineItem>
                        )
                    } else {
                        //tenho a URL salva, somente renderizar
                        /*    console.log('é uma imagem COM URL');
                           console.log(item.urlDownload); */
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                                <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description,item) }} style={{}}>
                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                </ContainerClipBaixarArquivo>
                                <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                    <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                                </div>
                            </TimelineItem>
                        )

                    }

                } else if (item.audio == true) {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                            <div style={{ marginTop: 10, marginBottom: 10 }}>

                                <AudioPlayer
                                    src={item.urlDownload}
                                    preload='auto'
                                    showSkipControls={false}
                                    showJumpControls={false}
                                    layout={'horizontal-reverse'}
                                    defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                    customControlsSection={
                                        [
                                            RHAP_UI.MAIN_CONTROLS,
                                        ]
                                    }
                                    style={{
                                        width: '300px',
                                        borderRadius: 10,
                                        background: '#F0f0f7'
                                    }}
                                // other props here
                                />
                            </div>
                            {/*    <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo> */}
                            <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                            </div>
                        </TimelineItem>

                    )
                } else {
                    /*  console.log('NÃO é uma imagem') */
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                            <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description,item) }} style={{}}>
                                <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                            </ContainerClipBaixarArquivo>
                            <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                            </div>
                        </TimelineItem>
                        /*  <View style={{ flex: 1 }}>
                             <Text style={styles.titleStyle2}>{rowData.title}</Text>
                             <View style={{ borderWidth: .5, borderColor: '#CCC', borderRadius: 5 }}>
                                 <TouchableOpacity
                                     style={{ flexDirection: 'row' }}
                                     onClick={() => { this.permissaoBaixarARquivo(rowData.description) }}
                                 >
                                     <Icon name="clip2" size={17} color="#CCC" style={{ padding: 5 }} />
                                     <Text numberOfLines={2} style={styles.descriptionStyle3}>{rowData.description}</Text>
                                 </TouchableOpacity>
                             </View>
                             <View style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                 <Text style={styles.descriptionStyleDataHoraAtual}>{rowData.dataHoraAtual}</Text>
                             </View>
                         </View > */

                    )
                }
            } else {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >
                        <TextTitulo corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextTitulo>
                        <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description,item) }} style={{}}>
                            <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                            <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                        </ContainerClipBaixarArquivo>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                        </div>
                    </TimelineItem>
                )
            }
        }
    }
    renderAtendentes(item) {
        /*   console.log('no render atendentes')
          console.log(item) */
        return (
            <DivAtendentes key={item.key}>
                <TextAtendentes onClick={() => {
                    this.props.modificaVisibilidadeModalAtribuirAtendimento(false);
                    this.props.atribuiAtendimentoAh(item.key, this.props.keyAtendimento, item.nomeAtendente, this.props.dadosAtendimentos[0].keySetor, this.props.dadosAtendimentos[0]);
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.nomeAtendente}</TextAtendentes>
            </DivAtendentes>
        )
    }
    renderBotaoFecharAtendimento() {
        if (this.props.solucaoAtendimento != '') {
            return (
                <ButtonSalvarComentario onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false); this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.dadosAtendimentos[0], this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamado, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.licensawebouapp, false, this.props.solucaoAtendimento) }}
                    backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Fechar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent data-tip="Informe a solução para fechar o atendimento" data-for="tip-top" onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Fechar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    abreModalAlterarChamado() {
        /*    console.log(this.props.dadosAtendimentos[0]) */

        /*   this.props.listaClientesTelaNovoChamado();
          this.props.listaAtendentesTelaNovoChamado();
          this.props.listaSetorTelaNovoChamado(); */
        if (this.props.dadosAtendimentos[0].prioridade != "Não informado") {
            this.props.modificaPrioridadeSelecionado(this.props.dadosAtendimentos[0].prioridade)
            //  this.onSelectedItemsChangePrioridade([this.props.dadosAtendimentos[0].prioridade])
        } else {
            this.props.modificaPrioridadeSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keyAtendente != "Não informado") {
            this.props.modificaAtendenteSelecionado(this.props.dadosAtendimentos[0].keyAtendente)
            // this.onSelectedItemsChangeAtendente([this.props.dadosAtendimentos[0].keyAtendente])
        } else {
            this.props.modificaAtendenteSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keyCliente != "Não informado") {
            this.props.modificaClienteSelecionado(this.props.dadosAtendimentos[0].keyCliente)
            // this.onSelectedItemsChange([this.props.dadosAtendimentos[0].keyCliente])
        } else {
            this.props.modificaClienteSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].keySetor != "Não informado") {
            this.props.modificaSetorSelecionado(this.props.dadosAtendimentos[0].keySetor)
            // this.onSelectedItemsChangeSetor([this.props.dadosAtendimentos[0].keySetor])
        } else {
            this.props.modificaSetorSelecionado('')
        }
        if (this.props.dadosAtendimentos[0].local != "Não informado") {
            this.props.modificaLocalSelecionado(this.props.dadosAtendimentos[0].local)
            //  this.onSelectedItemsChangeLocal([this.props.dadosAtendimentos[0].local])
        } else {
            this.props.modificaLocalSelecionado('')
        }

        if (this.props.dadosAtendimentos[0].dataVencimento != "Não informado") {
            this.props.modificaDataVencimentoTelaChamadoAbertura(this.props.dadosAtendimentos[0].dataVencimento)
        } else {
            this.props.modificaDataVencimentoTelaChamadoAbertura('')
        }
        /*  console.log('AQUIiIiiiii')
         console.log(this.props.dadosAtendimentos[0].status) */
        this.props.alteraDescricaoChamado(this.props.dadosAtendimentos[0].descricaoProblema)

        this.props.modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado(this.props.dadosAtendimentos[0].keyEmpresaVinculada)
        this.props.modificaVisibilidadeModalAlterarAtendimento(true)

    }
    renderAlteraDescricaoChamadoDigitado() {
        if (this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 0) {
            return (<ContainerInputDescricaoChamado style={{

            }}>
                <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></strong>
                <InputDescricaoChamado
                    autoFocus
                    type="textarea"
                    onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                    corLetraDinamico={this.props.coresLetraPadrao}
                    corLetraSecundaria={this.props.coresLetraSecundario}
                    corBordaDinamica={this.props.corBotaoPadrao}
                    placeholder=" Descreva o problema..."
                    value={this.props.descricaoAtendimento}
                />
            </ContainerInputDescricaoChamado>
            )
        } else if (this.props.criarChamadoProblemaEstatico == false) {
            return (
                <ContainerInputDescricaoChamado style={{

                }}>
                    <strong style={{ color: this.props.corLetraDescricaoDosCampos, fontSize: 14, marginBottom: 2, marginLeft: 5 }}>Descrição do problema:<span style={{ color: 'red', fontSize: 14, marginLeft: 3 }}>*</span></strong>
                    <InputDescricaoChamado
                        autoFocus
                        type="textarea"
                        onChange={(value) => { this.props.alteraDescricaoChamado(value.target.value) }}
                        corLetraDinamico={this.props.coresLetraPadrao}
                        corLetraSecundaria={this.props.coresLetraSecundario}
                        corBordaDinamica={this.props.corBotaoPadrao}
                        placeholder=" Descreva o problema..."
                        value={this.props.descricaoAtendimento}
                    />
                </ContainerInputDescricaoChamado>
            )
        } else {
            return null;
        }

    }

    renderTempoGasto(item) {
        return (
            <DivAtendentes key={item.id}>
                <TextAtendentes onClick={() => {
                    this.props.modificaVisibilidadeModalAddTempoGastoAtendimento(false);
                    this.props.atribuirTempoEsperaAoChamado(this.props.keyAtendimento, item.hora);
                }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>{item.hora}</TextAtendentes>
            </DivAtendentes>
        )
    }
    renderBotaoSalvarComentario() {
        if (this.props.inputComentario != '') {
            return (
                <ButtonSalvarComentario onClick={() => {
                    this.props.salvarComentario2(this.props.nomeArquivoAnexadoComentario, this.props.switchComentario,
                        this.props.inputComentario, [], this.props.comentarioPublicoSimOuNao, this.props.keyAtendimento, this.props.dadosAtendimentos[0], this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamado, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.licensawebouapp, false, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                        this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
                    this.zeraMensagemResponder()
                }} backgroundBotao={this.props.corBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Salvar</TextBotaoSalvarComentar>
                </ButtonSalvarComentario>
            )
        } else {
            return (
                <ButtonSalvarComentarioSemComent onClick={() => { }} backgroundBotao={this.props.corSegundoBotaoPadrao}>
                    <TextBotaoSalvarComentar backgroundBotao={this.props.letraBotaoPadrao}>Salvar</TextBotaoSalvarComentar>
                </ButtonSalvarComentarioSemComent>
            )
        }
    }
    mudaComentarioPublico() {
        let state = this.state;
        state.backgroundColor = this.props.corBotaoPadrao;
        state.colorTextComent = this.props.letraBotaoPadrao;
        state.colorTextComent2 = this.props.coresLetraPadrao;
        state.backgroundColor2 = 'transparent';
        state.placeholderInputComent = 'Comentário visível para clientes e atendentes';
        this.setState({ state });
        this.props.modificaComentarioPublicoSimOuNao(1)
    }
    mudaComentarioInterno() {
        let state = this.state;
        state.colorTextComent = this.props.coresLetraPadrao;
        state.backgroundColor = 'transparent';
        state.backgroundColor2 = this.props.corBotaoPadrao;
        state.colorTextComent2 = this.props.letraBotaoPadrao;
        state.placeholderInputComent = 'Comentário visível apenas para atendentes';
        this.setState({ state });
        this.props.modificaComentarioPublicoSimOuNao(0)
    }
    escolheArquivoChamado(res) {
        /*  console.log(res) */
        if (this.props.dadosAtendimentos[0].status == 'Cancelado') {
            toastr.error('Error ao Anexar', 'Chamado Cancelado, reabra para fazer upload');
        } else if (this.props.dadosAtendimentos[0].status == 'Fechado') {
            toastr.error('Error ao Anexar', 'Chamado Fechado, reabra para fazer upload');
        } else {
            if (res.length > 0) {
                /*   console.log(res) */
                /*  for (let i = 0; i < res.length; i++) { */

                /* console.log(this.props.licensawebouapp) */
                if (this.props.premium == 0) {
                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                }
                else {

                    /*  console.log('tudo ok 3') */
                    this.props.salvarComentario2('tem arquivo sim', false,
                        '', res, true, this.props.keyAtendimento, this.props.dadosAtendimentos[0], this.props.dadosAtendimentos[0].dataAbertura, this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamado, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.licensawebouapp, false, this.state.viewResponder, this.state.idResponder, this.state.commentResponder,
                        this.state.circleColorResponder, this.state.commentResponderType, this.state.atendenteResponder)
                    this.zeraMensagemResponder()
                }
                /*  } */
            } else {
                toastr.error('Error ao Anexar', 'Selecione arquivo com no máximo 20MB');
            }
        }
    }
    download(url, name) {
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.target = "_blank";
        window.open(url);
    };
    baixarArquivo(nomeArquivo,item) {
    
        toastr.warning('Download Iniciado', 'Baixando Arquivo...');
        this.getFileBlob(item.urlDownload, (blob) => {

            let file = new File([blob], nomeArquivo, { type: blob.type });
            saveAs(file)
        })
    }
    renderBordaDrag(isDragActive, isDragAccept, isDragReject) {
        if (isDragAccept == true) {
            return '#00FF7F';
        } else {
            return 'red';
        }
    }
    renderBotesAcoesChamado() {


        //nao é obrigatorio
        if (this.props.dadosAtendimentos[0].status == 'Aberto' || this.props.dadosAtendimentos[0].status == 'Atendendo') {
            return (
                <>
                    <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerBotoesAcoes>


                            {/*   <ButtonVariosNovos onClick={() => { this.props.modificaResolucaoProblema(''); this.props.modificaVisibilidadeModalFinalizarAtendimento(true) }} data-tip="Finalizar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                            </ButtonVariosNovos> */}


                            <ButtonVariosNovos onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(true) }} data-tip="Cancelar o atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                            </ButtonVariosNovos>

                        </ContainerBotoesAcoes>
                        <ContainerIconesLaterais>
                            <ContainerIconeLateral onClick={() => { this.abreModalAlterarChamado() }} data-tip="Alterar informações do atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateral>
                            <ContainerIconeLateral backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <Dropzone
                                    multiple={true}
                                    noKeyboard
                                    noDrag
                                    maxSize={2e+7}
                                    /*  onDropRejected={() => {
                                         toastr.error('Error', 'Adicione arquivos de no máximo 20MB')
                                     }} */
                                    onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                                    {({ getRootProps, getInputProps, isDragActive }) => {
                                        /*  console.log(isDragActive); */

                                        return (
                                            /*     <section> */
                                            <div  {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {/*  {
                                                    isDragActive ?
                                                        <p>Drop the files here ...</p> :
                                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                                } */}
                                                <i className="icon-clip" data-tip="Fazer upload de arquivos e imagens" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                            </div>
                                            /*   </section> */
                                        )
                                    }}
                                </Dropzone>
                            </ContainerIconeLateral>
                            <ContainerIconeLateral onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                }
                                else {
                                    this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                }
                            }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateral>
                        </ContainerIconesLaterais>
                    </ContainerAcoes>
                    <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivAdicionarComentario onClick={() => { this.props.modificavisibilidadeModalComentario(true); }} borderColorDinamico={this.props.corBotaoPadrao} data-tip="Adicionar um comentário nas conversas do atendimento" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                            <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                        </DivAdicionarComentario>
                        <ContainerIconeResposta onClick={() => { this.setState({ viewMicrophone: true }) }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                            <i className="icon-microphone" data-tip="Enviar mensagem de áudio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                        </ContainerIconeResposta>
                    </ContainerComentario>
                </>
            )
        } else if (this.props.dadosAtendimentos[0].status == 'Fechado') {
            return (
                <>
                    <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerBotoesAcoes>

                            <ButtonVariosNovos data-tip="Reabrir atendimento" onClick={() => { this._toggleModalReabrirChamado() }} data-for="tip-top" backgroundBotao="#00FF7F" hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechado</TextBotaoNovo>
                            </ButtonVariosNovos>

                            {this.props.verificaSeJaFezAvaliacaoChamado == 1 && this.props.verificaSeTemPermissaoParaAvaliarChamado == true && this.props.premium == 1 && this.props.licensawebouapp >= 2 && <ButtonVariosNovos onClick={() => { this.abrirTelaAvaliacaoJaFeita(); }} data-tip="Atendimento Avaliado" data-for="tip-top" backgroundBotao='yellow' hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-star" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Avaliado</TextBotaoNovo>
                            </ButtonVariosNovos>}
                            {this.props.verificaSeJaFezAvaliacaoChamado == 0 && this.props.verificaSeTemPermissaoParaAvaliarChamado == true && this.props.premium == 1 && this.props.licensawebouapp >= 2 && <ButtonVariosNovos onClick={() => { this.abrirTelaAvaliacao() }} data-tip="Avaliar Atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-starnotavalied" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Avaliar</TextBotaoNovo>
                            </ButtonVariosNovos>}
                            <BotaoDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelar</TextBotaoNovo>
                            </BotaoDesativado>
                        </ContainerBotoesAcoes>
                        <ContainerIconesLaterais>
                            <ContainerIconeLateralDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateralDesativado>
                            <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-clip" data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateralDesativado>
                            <ContainerIconeLateral onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                }
                                else {
                                    this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                }
                            }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateral>
                        </ContainerIconesLaterais>
                    </ContainerAcoes>
                    <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivAdicionarComentarioDesativado data-tip="Atendimento finalizado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                            <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                        </DivAdicionarComentarioDesativado>
                        <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                            <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                        </ContainerIconeRespostaChamadoFechado>
                    </ContainerComentario>
                </>
            )
        } else {
            //cancelado
            return (
                <>
                    <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerBotoesAcoes>

                            {/*   <BotaoDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-feitoweb" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Fechar</TextBotaoNovo>
                            </BotaoDesativado> */}
                            <ButtonVariosNovos data-tip="Reabrir atendimento" data-for="tip-top" onClick={() => { this._toggleModalReabrirChamado() }} backgroundBotao="#f70e4c" hoverBotao={this.props.coresLetraPadrao}>
                                <IconeVariosNovos className="icon-sair" colorPrimario={this.props.letraBotaoPadrao} />
                                <TextBotaoNovo colorDinamico={this.props.letraBotaoPadrao}>Cancelado</TextBotaoNovo>
                            </ButtonVariosNovos>
                        </ContainerBotoesAcoes>
                        <ContainerIconesLaterais>
                            <ContainerIconeLateralDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-editar" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateralDesativado>
                            <ContainerIconeLateralDesativado backgroundBotao="#9fadb7" hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-clip" data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateralDesativado>
                            <ContainerIconeLateral onClick={() => {
                                if (this.props.premium == 0) {
                                    toastr.error('Recurso Premium', 'Este é um recurso PREMIUM, para fazer upload de arquivos e imagens, Assine a versão PREMIUM e libere esse e mais recursos!');
                                } else if (this.props.premium == 1 && this.props.licensawebouapp < 2) {
                                    toastr.error('Plano incompatível', 'Seu plano assinado libera os recursos premium apenas para os APP`s DropDesk e não para Web!');
                                }
                                else {
                                    this.props.exportaAtendimentoParaPDF(this.props.dadosAtendimentos[0])
                                }
                            }} backgroundBotao={this.props.corSegundoBotaoPadrao} hoverBotao={this.props.coresLetraPadrao}>
                                <i className="icon-pdf2" data-tip="Relatório em PDF do atendimento" data-for="tip-top" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                            </ContainerIconeLateral>
                        </ContainerIconesLaterais>
                    </ContainerAcoes>
                    <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivAdicionarComentarioDesativado data-tip="Atendimento Cancelado, Reabra caso deseja executar alguma ação" data-for="tip-top" backGroundDinamico={this.props.backgroundPrimeiro}>
                            <TextAddComent corLetraDinamica={this.props.coresLetraSecundario}>Adicionar Comentario</TextAddComent>
                        </DivAdicionarComentarioDesativado>
                        <ContainerIconeRespostaChamadoFechado backgroundBotao='#9fadb7' hoverBotao={this.props.coresLetraPadrao}>
                            <i className="icon-microphone" data-tip="Reabra caso deseja enviar mensagem de audio" data-for="tip-top" style={{ fontSize: 15, color: this.props.letraBotaoPadrao, }} />
                        </ContainerIconeRespostaChamadoFechado>
                    </ContainerComentario>
                </>
            )
        }
    }


    renderDataHoraAbertura(status) {
        /* console.log(status) */
        if (status == 'Cancelado') {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                    <TextAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraPadrao}>Cancelamento: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.dadosAtendimentos[0].dataHoraCancelamento}</TextDataHoraAbertura>
                </>
            )
        } else if (status == 'Fechado') {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                    <TextAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraPadrao}>Fechamento: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.dadosAtendimentos[0].dataHoraFechamento}</TextDataHoraAbertura>
                </>

            )
        } else {
            return (
                <>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}> {this.props.dadosAtendimentos[0].dataHoraAbertura}</TextDataHoraAbertura>
                </>
            )
        }
    }
    _toggleModalReabrirChamado() {
        this.setState({ modalReabrirChamado: !this.state.modalReabrirChamado });
    }
    renderRefreshConversasAnteriores() {

        if (this.props.loadingCarregandoMaisConversasChatAcoesChamado === true) {
            console.log('mostrando loading')
            return (
                <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 5/* position: 'absolute', top: 15, center: 0 */ }}>
                    <div style={{ display: 'flex', height: 55, width: 55, borderRadius: '50%', marginTop: 5, backgroundColor: 'transparent', alignItems: 'center', justifyContent: 'center' }}>
                        <MoonLoader
                            // css={{}}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                            css={{ marginBottom: 10 }}
                        />
                    </div>
                </div>
            )
        } else {
            return null;
        }

    }
    renderLoadingAtendimento() {
        if (this.props.loadingTelaAcoesAtendimento) {
            return (
                <>
                    <ContainerDadosDoChamado style={{ marginBottom: 2 }} backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerDadosChamadoPrimeiro>
                            <ContainerDadosProblema corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                <DivTruncarTexto corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </DivTruncarTexto>
                            </ContainerDadosProblema>
                            <ContainerDadosAbertura>
                                <TextDataHoraAberturaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextDataHoraAberturaLoading>
                            </ContainerDadosAbertura>

                        </ContainerDadosChamadoPrimeiro>
                        <ContainerDadosChamadoSegundo >
                            <ContainerIconeMaisInformacoesLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeMaisInformacoesLoading>
                        </ContainerDadosChamadoSegundo>

                    </ContainerDadosDoChamado>
                    <ContainerAcoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerBotoesAcoes>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                            <ButtonVariosNovosLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ButtonVariosNovosLoading>
                        </ContainerBotoesAcoes>
                        <ContainerIconesLaterais>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                            <ContainerIconeLateralLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                            </ContainerIconeLateralLoading>
                        </ContainerIconesLaterais>

                    </ContainerAcoes>
                    <ContainerComentario backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <DivAdicionarComentarioLoading backgroundinamico={this.props.backgroundMaisEscuro}>
                        </DivAdicionarComentarioLoading>
                    </ContainerComentario>
                    <ContainerTimeLineLoading backGroundDinamico={this.props.backgroundPrimeiro}>
                        <MoonLoader
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={true}
                        />
                    </ContainerTimeLineLoading>
                </>
            )
        } else {
            return (
                <div style={{ display: 'flex', flex: 1, overflow: 'auto', flexDirection: 'column' }} onScroll={this.scrollCheck}>
                    <ContainerDadosDoChamado style={{ marginBottom: 2 }} backGroundDinamico={this.props.backgroundMaisEscuro}>
                        <ContainerDadosChamadoPrimeiro>
                            <ContainerDadosProblema corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                <DivTruncarTexto truncarTexto={this.state.maisInformacoes} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>
                                    <TextProblema data-tip="Descrição do Problema" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{this.props.dadosAtendimentos[0].descricaoProblema}</TextProblema>
                                </DivTruncarTexto>
                            </ContainerDadosProblema>
                            <ContainerDadosAbertura>
                                <ContainerDadosAberturaFechamento>
                                    {this.renderDataHoraAbertura(this.props.dadosAtendimentos[0].status)}

                                    <TextDataHoraAbertura style={{ marginLeft: 10 }} corLetraDinamica={this.props.coresLetraSecundario}>Atendimento criado por {this.props.dadosAtendimentos[0].abertoPor}</TextDataHoraAbertura>
                                </ContainerDadosAberturaFechamento>


                            </ContainerDadosAbertura>

                        </ContainerDadosChamadoPrimeiro>
                        <ContainerDadosChamadoSegundo >
                            {this.state.maisInformacoes == false &&
                                <ContainerIconeMaisInformacoes onClick={() => { this.handleMaisInformacoes(); }} data-tip="Exibir informações do Atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                    <i className="icon-down-arrow" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeMaisInformacoes>}
                            {this.state.maisInformacoes == true &&
                                <ContainerIconeMaisInformacoes onClick={() => { this.handleMaisInformacoes() }} data-tip="Ocultar informações do Atendimento" data-for="tip-top" backgroundBotao={this.props.corSegundoBotaoPadrao}>
                                    <i className="icon-up-arrow" style={{ fontSize: 18, color: this.props.letraBotaoPadrao }} />
                                </ContainerIconeMaisInformacoes>}
                        </ContainerDadosChamadoSegundo>
                    </ContainerDadosDoChamado>
                    {this.state.maisInformacoes == true && <ContainerMaisInformacoes backGroundDinamico={this.props.backgroundSegundo}>
                        <SubContainerMaisInformacoes backGroundDinamico={this.props.backgroundMaisEscuro}>
                            <DivSeparadoraMaisInformacoes style={{ borderTopWidth: 1, borderTopColor: this.props.coresLetraPadrao }} corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Solicitante:</LabelCliente>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].cliente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>

                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Atendente:</LabelCliente>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].atendente}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Setor/Produto:</LabelCliente>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].setor}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.coresLetraPadrao}>
                                <DivLAbelCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Vencimento:</LabelCliente>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].dataVencimento}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>

                            <DivSeparadoraMaisInformacoes corBordaDinamica={this.props.backgroundMaisEscuro}>
                                <DivLAbelCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>Status:</LabelCliente>
                                </DivLAbelCliente>
                                <DivCliente>
                                    <LabelCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.dadosAtendimentos[0].status}</LabelCliente>
                                </DivCliente>
                            </DivSeparadoraMaisInformacoes>
                        </SubContainerMaisInformacoes>
                        {this.renderArquivosAnexadosAoChamado()}
                    </ContainerMaisInformacoes>}

                    {this.renderBotesAcoesChamado()}
                    <ContainerTimeLine backGroundDinamico={this.props.backgroundPrimeiro}>
                        <Dropzone
                            multiple={true}
                            noKeyboard
                            maxSize={2e+7}
                            noClick
                            onDrop={acceptedFiles => this.escolheArquivoChamado(acceptedFiles)}>
                            {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
                                /*  console.log(isDragActive, isDragAccept, isDragReject); */
                                if (isDragActive) {
                                    if (this.props.dadosAtendimentos[0].status == 'Fechado') {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: 'red',
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Chamado Fechado</p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Reabra para fazer upload de arquivos</p>
                                            </div>
                                        )
                                    } else if (this.props.dadosAtendimentos[0].status == 'Cancelado') {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: 'red',
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Chamado Cancelado</p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Reabra para fazer upload de arquivos</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div style={{
                                                display: 'flex',
                                                flex: 1,
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '20px',
                                                borderWidth: 2,
                                                borderRadius: 2,
                                                borderColor: this.renderBordaDrag(isDragActive, isDragAccept, isDragReject),
                                                borderStyle: 'dashed',
                                                color: '#bdbdbd',
                                                outline: 'none',
                                                transition: 'border .24s ease-in-out',
                                                justifyContent: 'center',
                                                /*  margin: 5, */
                                            }}  {...getRootProps()}>
                                                {/*   <input {...getInputProps()} /> */}
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22 }}>Solte Arquivos para Anexar <i className="icon-clip" style={{ color: this.props.coresLetraPadrao, fontSize: 21 }} /></p>
                                                <p style={{ color: this.props.coresLetraSecundario, textAlign: 'center', fontSize: 22, marginTop: 20 }}>Tamanho Máximo de 20MB</p>
                                            </div>
                                        )
                                    }
                                } else {
                                    return (

                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}  {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Timeline lineColor={this.props.coresLetraSecundario}>
                                                <FlatList
                                                    list={this.props.acoesDoAtendimento}
                                                    renderItem={(item) => this.messageBox(item)}
                                                    renderWhenEmpty={() => null}
                                                />
                                                <FlatList
                                                    list={this.props.arrayConversasAntigasChatAcoesChamado}
                                                    renderItem={(item) => this.messageBox(item)}
                                                    renderWhenEmpty={() => null}
                                                />

                                                {this.renderRefreshConversasAnteriores()}
                                            </Timeline>
                                        </div>

                                    )
                                }
                            }}
                        </Dropzone>
                    </ContainerTimeLine>
                    <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAlterarAtendimento} onClose={() => { }} center>
                        <ContainerModalAlterar backgroundDinamico={this.props.backgroundPrimeiro}>
                            <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Alterar Atendimento</TextNovoAtendimento>
                                <div>
                                    <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAlterarAtendimento(false) }} className="icon-close" />
                                </div>
                            </ContainerIconeFecharModal>

                            <div style={{ display: 'flex', flex: 1, marginTop: 15, flexDirection: 'column' }}>
                                {this.props.criarChamadoProblemaEstatico == true && this.props.dadosAtendimentos[0].descricaoProblemaEstatico == 1 &&
                                    <SubContainerNovoAtendimento style={{ marginTop: 15 }}>
                                        <p style={{ marginLeft: 20, color: this.props.corLetraDescricaoDosCampos, fontSize: 14 }}>Problema:</p>
                                        <ContainerSelectSearch>
                                            <SelectSearch
                                                renderValue={(valueProps) =>
                                                    <DivInputSearch>
                                                        <InputSearch
                                                            corLetraDinamico={this.props.coresLetraPadrao}
                                                            backgroundDinamico={this.props.backgroundMaisEscuro}
                                                            placeholderColor={this.props.corLetraSecundaria}
                                                            corBordaDinamica={this.props.corBotaoPadrao}
                                                            {...valueProps} ></InputSearch>
                                                        <IconeSetaPraBaixo className="icon-angle-arrow-down" colorDinamico={this.props.coresLetraPadrao} />
                                                    </DivInputSearch>
                                                }
                                                value={this.props.problemaEstaticoSelecionado} options={this.props.problemasEstatico} onChange={(value, option) => { this.props.modificaProblemaEstaticoSelecionado(value); this.props.modificaNomeProblemaEstaticoSelecionado(option.name); }} placeholder="Selecione o Problema" search />

                                        </ContainerSelectSearch>
                                    </SubContainerNovoAtendimento>
                                }
                                {this.renderAlteraDescricaoChamadoDigitado()}

                            </div>

                            <DivBotaoNovoAtendimento>
                                <BotaoCadastrarAtendimento type="submit" onClick={() => {
                                    /*   console.log('no botao alterar') */

                                    this.props.alterarChamado2(
                                        this.props.descricaoAtendimento,
                                        this.props.dadosAtendimentos,
                                        this.props.keyAtendimento,
                                        this.props.criarChamadoProblemaEstatico,
                                        this.props.problemaEstaticoSelecionado,
                                        this.props.nomeProblemaEstaticoSelecionado,
                                        this.props.dadosAtendimentos[0].descricaoProblemaEstatico
                                    )

                                }} backgroundDinamico={this.props.corBotaoPadrao}>
                                    <TextCadastrar corLetraDinamico={this.props.letraBotaoPadrao}>Alterar</TextCadastrar>
                                </BotaoCadastrarAtendimento>
                            </DivBotaoNovoAtendimento>
                            {/*  </div> */}
                        </ContainerModalAlterar>
                    </Modal>
                </div>
            )
        }
    }
    cancelarChamadoLocal() {
        if (this.props.motivoCancelamento.trim() == '') {
            toastr.error('Error ao Cancelar', 'Informe um motivo de cancelamento');
        } else {
            this.props.modificaVisibilidadeModalCancelarAtendimento(false);
            this.props.cancelarChamado(this.props.keyAtendimento, this.props.motivoCancelamento, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0])
        }
    }
    insereAvaliacao(valor) {
        /* console.log(valor); */
        if (valor == 5) {
            let state = this.state;
            state.isSelectedRadioButton5estrelas = true;
            state.isSelectedRadioButton4estrelas = false;
            state.isSelectedRadioButton3estrelas = false;
            state.isSelectedRadioButton2estrelas = false;
            state.isSelectedRadioButton1estrelas = false;
            state.obrigatorioComentarioAvaliacao = false;


            state.valorAvaliacao = 5;
            this.setState(state);
            /*     console.log(this.state.valorAvaliacao)
                console.log(this.state.isSelectedRadioButton5estrelas);
                console.log(this.state.isSelectedRadioButton4estrelas)
                console.log(this.state.isSelectedRadioButton3estrelas)
                console.log(this.state.isSelectedRadioButton2estrelas)
                console.log(this.state.isSelectedRadioButton1estrelas) */

        } else if (valor == 4) {
            let state = this.state;
            state.isSelectedRadioButton5estrelas = false;
            state.isSelectedRadioButton4estrelas = true;
            state.isSelectedRadioButton3estrelas = false;
            state.isSelectedRadioButton2estrelas = false;
            state.isSelectedRadioButton1estrelas = false;
            state.obrigatorioComentarioAvaliacao = false;
            state.valorAvaliacao = 4;
            this.setState(state);
            /*     console.log(this.state.valorAvaliacao)
                console.log(this.state.isSelectedRadioButton5estrelas);
                console.log(this.state.isSelectedRadioButton4estrelas)
                console.log(this.state.isSelectedRadioButton3estrelas)
                console.log(this.state.isSelectedRadioButton2estrelas)
                console.log(this.state.isSelectedRadioButton1estrelas) */
        }
        else if (valor == 3) {
            let state = this.state;
            state.isSelectedRadioButton5estrelas = false;
            state.isSelectedRadioButton4estrelas = false;
            state.isSelectedRadioButton3estrelas = true;
            state.isSelectedRadioButton2estrelas = false;
            state.isSelectedRadioButton1estrelas = false;
            state.obrigatorioComentarioAvaliacao = false;
            state.valorAvaliacao = 3;
            this.setState(state);
            /*     console.log(this.state.valorAvaliacao)
                console.log(this.state.isSelectedRadioButton5estrelas);
                console.log(this.state.isSelectedRadioButton4estrelas)
                console.log(this.state.isSelectedRadioButton3estrelas)
                console.log(this.state.isSelectedRadioButton2estrelas)
                console.log(this.state.isSelectedRadioButton1estrelas) */
        }

        else if (valor == 2) {
            let state = this.state;
            state.isSelectedRadioButton5estrelas = false;
            state.isSelectedRadioButton4estrelas = false;
            state.isSelectedRadioButton3estrelas = false;
            state.isSelectedRadioButton2estrelas = true;
            state.isSelectedRadioButton1estrelas = false;
            state.obrigatorioComentarioAvaliacao = true;
            state.valorAvaliacao = 2;
            this.setState(state);
            /*   console.log(this.state.valorAvaliacao)
              console.log(this.state.isSelectedRadioButton5estrelas);
              console.log(this.state.isSelectedRadioButton4estrelas)
              console.log(this.state.isSelectedRadioButton3estrelas)
              console.log(this.state.isSelectedRadioButton2estrelas)
              console.log(this.state.isSelectedRadioButton1estrelas) */
        } else {
            //valor 1
            let state = this.state;
            state.isSelectedRadioButton5estrelas = false;
            state.isSelectedRadioButton4estrelas = false;
            state.isSelectedRadioButton3estrelas = false;
            state.isSelectedRadioButton2estrelas = false;
            state.isSelectedRadioButton1estrelas = true;
            state.obrigatorioComentarioAvaliacao = true;
            state.valorAvaliacao = 1;
            this.setState(state);
            /*   console.log(this.state.valorAvaliacao)
              console.log(this.state.isSelectedRadioButton5estrelas);
              console.log(this.state.isSelectedRadioButton4estrelas)
              console.log(this.state.isSelectedRadioButton3estrelas)
              console.log(this.state.isSelectedRadioButton2estrelas)
              console.log(this.state.isSelectedRadioButton1estrelas) */
        }
    }
    abrirTelaAvaliacaoJaFeita() {
        if (this.props.dadosAtendimentos[0].atendente == 'Não informado') {
            toastr.warning(
                'Opss',
                'Este atendimento não possui um ATENDENTE vinculado, sendo impossível efetuar a avaliação.',
            );
        } else {
            this._toggleModal();
        }
    }
    //daqui pra baixo eh o novo message box cliente
    renderResponderMensagem(item) {
        //onReplyClick={() => { this.renderResponderMensagem(item) }}
        // let input = this[this.props.data.key];
        /*  input.focus(); */
        this.props.modificavisibilidadeModalComentario(true);
        if (item.tipo === 'mensagem') {
            this.setState({ commentResponderType: 'texto', commentResponder: item.description, idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'location') {
            this.setState({ commentResponderType: 'location', commentResponder: 'Localização', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else if (item.tipo === 'vcard') {
            this.setState({ commentResponderType: 'contato', commentResponder: 'Contato', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
        } else {
            if (item.tipoArquivo.includes('image')) {
                this.setState({ commentResponderType: 'imagem', commentResponder: 'Imagem', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                this.setState({ commentResponderType: 'video', commentResponder: 'Vídeo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else if (item.audio == true) {
                this.setState({ commentResponderType: 'audio', commentResponder: 'Mensagem de Voz', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            } else {
                this.setState({ commentResponderType: 'arquivo', commentResponder: 'Arquivo', idResponder: item.idMensagem, circleColorResponder: item.circleColor, viewResponder: true, atendenteResponder: item.title })
            }
        }
    }
    downloadImage(url, name) {
        let link = document.createElement("a");
        //usar quando for abrir imagem
        link.setAttribute("href", url);
        link.setAttribute("target", "_blank");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";
         
         window.URL.revokeObjectURL(url); */
        /*  const a = document.createElement('a');
         a.href = url;
         a.download = name;
         a.click();
         a.target = "_blank";
         window.open(url); */
    };

    renderReplyButton(item) {
        if (this.props.dadosAtendimentos[0].status == 'Fechado' || this.props.dadosAtendimentos[0].status == 'Cancelado' || item.deleteMessage === true) {
            return false
        } else {
            return true
        }
    }
    renderMensagemReply(item) {
        if (item.possuiResposta) {
            /*     { this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} /> }
    
                { this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} /> }
                { this.state.commentResponder } */
            return {
                title: item.circleColorResponder === '#39a3f4' ? this.props.dadosAtendimentos[0].cliente : item.atendenteResponder,
                titleColor: item.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4",
                message:
                    this.renderTextArquivosMensagemRespondidas(item)

             /*    (<p>
                    {item.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} />}

                    {item.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} />}
                    {item.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} />}
                    {item.mensagemResposta}

                     {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image') && <><i className="icon-imagewpp" style={{ fontSize: 13, color: '#000' }} /> Imagem</>}
                    {item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true && <> < i className="icon-video-camera" style={{ fontSize: 13, color: '#000' }} /> Video</>}
                    {item.commentResponderType === 'link' && item.commentResponderIsAudio === true && <> <i className="icon-microphone" style={{ fontSize: 13, color: '#000' }} /> Mensagem de Voz</>}
                    {item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && !item.commentResponderIsAudio === false && !item.commentResponderIsAudio === true && <><i className="icon-documentwpp" style={{ fontSize: 13, color: '#000' }} /> Arquivo</>}

                    {item.commentResponderType !== 'link' && <>{item.mensagemResposta}</>} 
                </p>) */,


            }
        } else {
            return null
        }


    }
    renderClickButtonReply(item, ref) {
        /*  if (this.props.dadosAtendimentos[0].chamadoAbertoPorWpp === true) {
             firebase.database().ref(`relacionamentoMensagensStatusWpp`).orderByChild('idMensagem').equalTo(item.idMensagemResponder).once('value').then((snapshot) => {
                 if (snapshot.val()) {
                     let keyChamado = '';
                     console.log(snapshot.val())
                     snapshot.forEach(childItem => {
                         keyChamado = childItem.val().keyChamado;
                     })
                     console.log(keyChamado)
                     console.log(this.props.dadosAtendimentos[0])
                     if (keyChamado === this.props.dadosAtendimentos[0].key) {
                         console.log('mensagem na mesma conversa');
                         console.log(item.idMensagemResponder)
                         this.setState({ focusMessageBox: item.idMensagemResponder })
                     } else {
                        
                     }
                 } else {
                     console.log('sem dados')
                 }
             })
         } else { */

        console.log(item);

        let msgMesmaConversa = this.props.acoesDoAtendimento.filter(e => e.idMensagem === item.idMensagemResponder).length > 0;
        console.log(msgMesmaConversa)
        if (msgMesmaConversa === true) {
            console.log('mensagem na mesma conversa');
            console.log(item.idMensagemResponder)
            this.setState({ focusMessageBox: item.idMensagemResponder })
        } else {
            let mensagemJaCarregada = false
            for (let i = 0; i < this.props.arrayConversasAntigasChatAcoesChamado.length; i++) {
                console.log(this.props.arrayConversasAntigasChatAcoesChamado[i])
                if (this.props.arrayConversasAntigasChatAcoesChamado[i].idMensagem === item.idMensagemResponder) {
                    mensagemJaCarregada = true;
                    break;
                }
            }
            console.log(mensagemJaCarregada)
            if (mensagemJaCarregada === true) {
                this.setState({ focusMessageBox: item.idMensagemResponder })
            } else {
                console.log('carregar mensagens do chamado antigo');
                let arrayConversas = [...this.props.acoesDoAtendimento]
                let ultimaKey = arrayConversas[arrayConversas.length - 1].key;
                this.props.listaConversasAntigasResponderAcoes(this.props.dadosAtendimentos[0].key, item.idMensagemResponder, [], 'emAtendimento', ultimaKey, item.idMensagemResponder)
            }
        }
        //  this.setState({ focusMessageBox: item.idMensagemResponder })
        /*  } */
    }
    renderStatusMessageBox(item) {
        return null
        /*  console.log(item)
         console.log(this.props.dadosAtendimentos[0].cliente)
         if (item.circleColor === '#39a3f4' || item.visivelATodos === 0 || this.props.dadosAtendimentos[0].cliente === 'Não informado') {
             return null
         } else {
             return item.statusMessage
         } */
    }
    messageBox(item) {
        if (item.deleteMessage === true) {
            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width:"100%"
                    }}
                >

                    <MessageBox
                        {...item}
                        id={item.key}
                        reply={this.renderMensagemReply(item)}
                        /*   ref={ref => { this.refsArray[item.key] = ref }} */
                        onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                        onReplyClick={() => { this.renderResponderMensagem(item) }}


                        //onClick={() => { console.log('clicou onClick') }}
                        //onOpen={() => { console.log('clicou onOpen') }}
                        position={item.circleColor === '#39a3f4' ? "right" : "left"}
                        focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                        onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                        type={'text'}
                        title={item.title}
                        titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                        text={
                            (
                                <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >
                                    <i className="icon-block" style={{ fontSize: 14, color: '#808080' }} />
                                    <span style={{ color: '#808080', fontSize: 14, fontStyle: 'italic' }}>Mensagem Apagada</span>
                                </Linkify>
                            )
                        }
                        copiableDate
                        dateString={item.time}
                        forwarded={false}
                        replyButton={false}
                        removeButton={false}
                        status={null} //(waiting, sent, received, read)
                    // focus
                    //  data={{
                    //   uri: 'https://facebook.github.io/react/img/logo.svg',
                    //    status: {
                    //        click: false,
                    //        loading: 0,
                    //    }
                    // }} 
                    />



                </TimelineItem>

            )
        }
        else if (item.tipo == 'mensagem') {
            if (item.title.includes('Atendimento criado por')) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >

                        <SystemMessage
                            text={`${item.title}`} />

                        {this.renderDescriptionMessageBox(item)}
                    </TimelineItem>
                )

            } else if (item.title == 'Avaliar Este Atendimento') {

                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >

                        <TextTituloAvaliar corLetraDinamica={this.props.coresLetraPadrao}>
                            {item.description}
                        </TextTituloAvaliar>
                        <TextAvaliar onClick={() => {
                            if (this.props.verificaSeJaFezAvaliacaoChamado == 1) {
                                this.abrirTelaAvaliacaoJaFeita();
                            } else {
                                this.abrirTelaAvaliacao();
                            }
                        }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextAvaliar>
                        <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                        </div>
                    </TimelineItem>
                )


            }

            else if (item.description.includes(`Atendimento atribuido ao atendente`)) {
                let atendendoAtribuido = item.description.replace('Atendimento atribuido ao atendente', '')
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >

                        <SystemMessage
                            text={`${item.description}`} />
                        {/* <SystemMessage
                            text={`${atendendoAtribuido} entrou na Conversa`} /> */}

                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} entrou no Chat`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.title} entrou na Conversa`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} finalizou o atendimento.`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.title} Finalizou o Atendimento.`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            } else if (item.description.includes(`${item.title} Cancelou este atendimento`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            } else if (item.description.includes(`${item.title} encerrou o chat.`)) {
                return (

                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }else if (item.description.includes(`Atendimento reaberto por ${item.title}`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >

                        <SystemMessage
                            text={`${item.description}`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/* <SystemMessage
                            text={`${item.description} entrou na Conversa`} /> */}

                    </TimelineItem>
                )
            }else if (item.description.includes(`Alterou a data de vencimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            } else if (item.description.includes(`${item.title} Alterou o atendente de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }
            else if (item.description.includes(`${item.title} Alterou a descrição do problema de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }else if (item.description.includes(`${item.title} Alterou o local do atendimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }else if (item.description.includes(`${item.title} Alterou o setor de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                      </div> */}
                    </TimelineItem>
                )
            }else if (item.description.includes(`Alterou prioridade do atendimento de`)) {
                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width: 675
                        }}
                    >
                        <SystemMessage
                            text={`${item.description}.`} />
                            {item.visivelATodos == 0 && <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            <p style={{ color: this.props.coresLetraSecundario, fontSize: 9, fontStyle: 'italic', marginBottom: -10 }}>Comentário Interno</p>
                        </div>}
                        {/*   <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: 20, marginTop: 10 }} key={item.key}>
                          < TextMensagem corLetraDinamica={this.props.coresLetraPadrao} >
                              {item.description}
                          </TextMensagem>
                </div> */}
                    </TimelineItem>
                )
            }
            else {

                return (
                    <TimelineItem
                        key={item.key}
                        dateText={item.dataHoraAtual}
                        dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                        dateStyle={{ textAlign: 'center' }}
                        style={{ color: item.circleColor }}
                        bodyContainerStyle={{
                            background: this.props.backgroundSegundo,
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                            width:"100%"
                        }}
                    >

                        <MessageBox
                            {...item}
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            /*   ref={ref => { this.refsArray[item.key] = ref }} */
                            onReplyMessageClick={(e) => { this.renderClickButtonReply(item, e) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                            //onClick={() => { console.log('clicou onClick') }}
                            //onOpen={() => { console.log('clicou onOpen') }}
                            position={item.circleColor === '#39a3f4' ? "right" : "left"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                            type={'text'}
                            title={item.title}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.time}
                            forwarded={false}
                            replyButton={this.renderReplyButton(item)}
                            removeButton={this.renderRemoveButton(item)}
                            status={null} //(waiting, sent, received, read)
                        // focus
                        //  data={{
                        //   uri: 'https://facebook.github.io/react/img/logo.svg',
                        //    status: {
                        //        click: false,
                        //        loading: 0,
                        //    }
                        // }} 
                        />



                    </TimelineItem>

                )

            }
        } else {
            //let tipoArquivo = rowData.description.split('.').pop().toLowerCase();
            if (item.tipoArquivo != undefined && item.tipoArquivo != null && item.tipoArquivo != '') {
                if (item.tipoArquivo.includes('image')) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //  console.log('é uma imagem SEM URL');
                        //  console.log(item.urlDownload);
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    //onClick={() => { console.log('clicou onClick') }}
                                    //onOpen={() => { console.log('clicou onOpen') }}
                                    position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                    type={'file'}
                                    title={item.title}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={null} //(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )



                    } else {
                        //tenho a URL salva, somente renderizar
                        // console.log('é uma imagem COM URL');
                        // console.log(item.urlDownload);

                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    /*     onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);   }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                    type={'photo'}
                                    title={item.title}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={''
                                        /* 
                                        (
                                            <div>
                                                <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                                </ContainerClipBaixarArquivo>
                                            </div>
                                        ) */
                                    }
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={null} //(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )


                    }

                } else if (item.tipoArquivo.includes('video') && item.audio !== true || item.tipoArquivo.includes('mp4') && item.audio !== true) {
                    if (item.urlDownload == undefined || item.urlDownload == null || item.urlDownload == '') {
                        //tenho que buscar a url de download no firebase
                        //  console.log('é uma imagem SEM URL');
                        //  console.log(item.urlDownload);
                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}
                                    data={{
                                        uri: item.urlDownload,
                                        status: {
                                            click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                            loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                            download: this.state.downloadFile
                                        },
                                        //size: "100MB",
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                    onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    //onClick={() => { console.log('clicou onClick') }}
                                    //onOpen={() => { console.log('clicou onOpen') }}
                                    position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                    type={'file'}
                                    title={item.title}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={item.description}
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={null}//(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )



                    } else {
                        //tenho a URL salva, somente renderizar
                        // console.log('é uma imagem COM URL');
                        // console.log(item.urlDownload);

                        return (
                            <TimelineItem
                                key={item.key}
                                dateText={item.dataHoraAtual}
                                dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                                dateStyle={{ textAlign: 'center' }}
                                style={{ color: item.circleColor }}
                                bodyContainerStyle={{
                                    background: this.props.backgroundSegundo,
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                    width:"100%"
                                }}
                            >
                                <MessageBox
                                    {...item}
                                    id={item.key}
                                    reply={this.renderMensagemReply(item)}

                                    data={{
                                        videoURL: item.urlDownload,
                                        status: {
                                            click: true,
                                            // loading: 1,
                                            download: true
                                        },
                                        width: 300,
                                        height: 300,
                                    }}
                                    focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                    onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                    //onDownload={event => this.baixarArquivo(item.description, item)}
                                    onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                    onReplyClick={() => { this.renderResponderMensagem(item) }}
                                    onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                    /* onClick={(event) => {  this.downloadImage(item.urlDownload, item.description);  }} */
                                    onOpen={() => { this.downloadImage(item.urlDownload, item.description); }}
                                    position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                    type={'video'}
                                    title={item.title}
                                    titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                    text={''
                                        /* 
                                        (
                                            <div>
                                                <ImagemComentario onClick={() => { this.baixarArquivo(item.description) }} src={item.urlDownload} alt={item.description} />
                                                <ContainerClipBaixarArquivo backgroundDinamico={this.props.backgroundSegundo} corBordaDinamica={this.props.coresLetraSecundario} onClick={() => { this.baixarArquivo(item.description) }} style={{}}>
                                                    <i className="icon-clip2" style={{ fontSize: 15, color: this.props.coresLetraSecundario }} />
                                                    <TextBaixarArquivo >{item.description}</TextBaixarArquivo>
                                                </ContainerClipBaixarArquivo>
                                            </div>
                                        ) */
                                    }
                                    copiableDate
                                    dateString={item.time}
                                    forwarded={false}
                                    replyButton={this.renderReplyButton(item)}
                                    removeButton={this.renderRemoveButton(item)}
                                    status={null}//(waiting, sent, received, read)

                                />
                            </TimelineItem>
                        )


                    }

                }
                else if (item.audio == true) {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: false,
                                         loading: 0,
                                     }
                                 }} */
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => { console.log('clicou onClick') }}
                                //onOpen={() => { console.log('clicou onOpen') }}
                                position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                type={'text'}
                                title={item.title}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={
                                    (
                                        <AudioPlayer
                                            src={item.urlDownload}
                                            preload='auto'
                                            showSkipControls={false}
                                            showJumpControls={false}
                                            layout={'horizontal-reverse'}
                                            defaultDuration={this.millisToMinutesAndSeconds(item.duracao)}
                                            customControlsSection={
                                                [
                                                    RHAP_UI.MAIN_CONTROLS,
                                                ]
                                            }
                                            style={{
                                                width: '300px',
                                                borderRadius: 10,
                                                background: '#E9E9E9'
                                            }}
                                        // other props here
                                        />
                                    )
                                }
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={null} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )

                } else {
                    // console.log('NÃO é uma imagem');
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    },
                                    //size: "100MB",
                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => { console.log('clicou onClick') }}
                                //onOpen={() => { console.log('clicou onOpen') }}
                                position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                type={'file'}
                                title={item.title}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={null} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )

                }
            } else {
                if (item.tipo === 'location') {
                    let dados = item.description.split(" - ");
                    /*  console.log(dados)
                     console.log(dados[0])
                     console.log(dados[1]) */
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <MessageBox

                                {...item}
                                id={item.key}
                                apiKey="AIzaSyDz1f3-p7gxOvHjVYnmt23VHuLCbZ0TS6s"
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    // uri: item.urlDownload,
                                    /* status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    }, */
                                    // size: "100MB",
                                    latitude: dados[0],
                                    longitude: dados[1],

                                }}
                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                //onClick={() => { console.log('clicou onClick') }}
                                //onOpen={() => { console.log('clicou onOpen') }}
                                position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                type={'location'}
                                /*  onDownload={event => this.baixarArquivo(item.description, item)} */

                                title={item.title}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={''}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={null}//(waiting, sent, received, read) 

                            />
                            {/* < LocationMessage
                                key={item.key}
                                target="_blank"
                                data={{
                                     // latitude: dados[0],
                                    // longitude: dados[1], 
                                    latitude: '37.773972',
                                    longitude: '-122.431297',
                                    // staticURL: '<optional>',
                                    // mapURL: '<optional>'
                                }
                                } /> */}
                        </TimelineItem>
                    )
                } if (item.tipo === 'vcard') {
                    //let vcardMEssage2 = vCard.parse(item.description);
                    let card = vCard.parse(item.description);
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >

                            <MessageBox
                                /* avatar={imgTeste} */
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}

                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                /*  data={{
                                     uri: item.urlDownload,
                                     status: {
                                         click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                         loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                         download: this.state.downloadFile
                                     },
                                     size: "100MB",
                                 }} */
                                /*       onReplyMessageClick={() => console.log('reply clicked!')} */
                                //onClick={() => { console.log('clicou onClick') }}
                                //onOpen={() => { console.log('clicou onOpen') }}
                                position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                type={'text'}
                                /* onDownload={event => this.baixarArquivo(item.description, item)} */
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                title={item.title}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                /* text={(
                                    <p>{card.fn[0].value}</p>
                                )} */
                                text={(
                                    <div>
                                        {card.fn.map(function (item, i) {
                                            return (
                                                <p key={i}>{item.value}</p>
                                            )
                                        })}
                                        {card.tel.map(function (item, i) {
                                            return <p key={i}>{item.value}</p>
                                        })}
                                    </div>
                                )}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={null} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )
                } else {
                    return (
                        <TimelineItem
                            key={item.key}
                            dateText={item.dataHoraAtual}
                            dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                            dateStyle={{ textAlign: 'center' }}
                            style={{ color: item.circleColor }}
                            bodyContainerStyle={{
                                background: this.props.backgroundSegundo,
                                padding: '10px',
                                borderRadius: '8px',
                                boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                                width:"100%"
                            }}
                        >
                            <MessageBox
                                {...item}
                                id={item.key}
                                reply={this.renderMensagemReply(item)}
                                data={{
                                    uri: item.urlDownload,
                                    status: {
                                        click: this.state.nomeFileDownload == item.description ? this.state.clickFileDownload : false,
                                        loading: this.state.nomeFileDownload == item.description ? this.state.loadingFileDownload : 0,
                                        download: this.state.downloadFile
                                    },
                                    // size: "100MB",
                                }}

                                focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                                onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                                onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                                onReplyClick={() => { this.renderResponderMensagem(item) }}
                                //onClick={() => { console.log('clicou onClick') }}
                                //onOpen={() => { console.log('clicou onOpen') }}
                                position={item.circleColor === '#39a3f4' ? "right" : "left"}
                                type={'file'}
                                onDownload={event => this.baixarArquivo(item.description, item)}
                                onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                                title={item.title}
                                titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                                text={item.description}
                                copiableDate
                                dateString={item.time}
                                forwarded={false}
                                replyButton={this.renderReplyButton(item)}
                                removeButton={this.renderRemoveButton(item)}
                                status={null} //(waiting, sent, received, read)

                            />
                        </TimelineItem>
                    )
                }

            }
        }


    }
    zeraMensagemResponder() {
        this.setState({
            viewResponder: false,
            commentResponder: '',
            idResponder: '',
            circleColorResponder: '',
            commentResponderType: null,
            atendenteResponder: ''
        })
    }
    renderTextArquivosMensagemRespondidas(item) {
        if (item.commentResponderType === 'imagem') {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'video') {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        } else if (item.commentResponderType === 'location') {
            return <p><i className="icon-localization" style={{ fontSize: 13, color: '#5a5a5a' }} /> Localização</p>
        } else if (item.commentResponderType === 'contato') {
            return <p><i className="icon-man-user" style={{ fontSize: 13, color: '#5a5a5a' }} /> Contato</p>
        } else if (item.commentResponderType === 'audio') {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        } else if (item.commentResponderType === 'arquivo') {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('image')) {
            return <p><i className="icon-imagewpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Imagem</p>
        } else if (item.commentResponderType === 'link' && item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio !== true || item.commentResponderTypeArquivo.includes('mp4') && item.commentResponderIsAudio !== true) {
            return <p><i className="icon-video-camera" style={{ fontSize: 13, color: '#5a5a5a' }} /> Vídeo</p>
        }
        else if (item.commentResponderType === 'link' && item.commentResponderIsAudio === true) {
            return <p><i className="icon-microphone" style={{ fontSize: 13, color: '#5a5a5a' }} /> Mensagem de Voz</p>
        }
        else if (item.commentResponderType === 'link' && !item.commentResponderTypeArquivo.includes('image') && !item.commentResponderTypeArquivo.includes('video') && item.commentResponderIsAudio === false) {
            return <p><i className="icon-documentwpp" style={{ fontSize: 13, color: '#5a5a5a' }} /> Arquivo</p>
        }
        else {
            return <p>{item.mensagemResposta}</p>
        }
    }
    renderRemoveButton(item) {
        if (this.props.dadosAtendimentos[0].status == 'Fechado' || this.props.dadosAtendimentos[0].status == 'Cancelado' || item.deleteMessage === true || item.circleColor === "#00FF7F") {
            return false
        } else {
            return true
        }
    }
    renderDescriptionMessageBox(item) {
        if (item.title == 'Avaliar Este Atendimento') {

            return (
                <TimelineItem
                    key={item.key}
                    dateText={item.dataHoraAtual}
                    dateInnerStyle={{ background: item.circleColor, color: '#000' }}
                    dateStyle={{ textAlign: 'center' }}
                    style={{ color: item.circleColor }}
                    bodyContainerStyle={{
                        background: this.props.backgroundSegundo,
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
                        width:"100%"
                    }}
                >

                    <TextTitulo corLetraDinamica={this.props.coresLetraPadrao}>
                        {item.description}
                    </TextTitulo>
                    <TextAvaliar onClick={() => {
                        if (this.props.verificaSeJaFezAvaliacaoChamado == 1) {
                            this.abrirTelaAvaliacaoJaFeita();
                        } else {
                            this.abrirTelaAvaliacao();
                        }
                    }} corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{item.title}</TextAvaliar>
                    <div style={{ alignItems: 'flex-end', justifyContent: 'flex-end', display: 'flex' }}>
                        <p style={{ color: this.props.coresLetraSecundario, fontSize: 9 }}>{item.dataHoraAtual}</p>
                    </div>
                </TimelineItem>
            )


        } else {
            if (item.title.includes('Atendimento criado por') || item.title.includes('Conversa Iniciada por ')) {
                return null
            } else {
                //onReplyClick={() => { this.renderResponderMensagem(item) }}
                return (
                    <div key={item.key}>
                        <MessageBox

                            {...item}

                            /*   ref={scrollTo} */
                            id={item.key}
                            reply={this.renderMensagemReply(item)}
                            onReplyMessageClick={() => { this.renderClickButtonReply(item) }}
                            onReplyClick={() => { this.renderResponderMensagem(item) }}

                            //onClick={() => { console.log('clicou onClick') }}
                            //onOpen={() => { console.log('clicou onOpen') }}
                            position={item.circleColor === '#39a3f4' ? "right" : "left"}
                            type={'text'}
                            title={item.title.includes('Atendimento criado por') ? item.title.replace('Atendimento criado por: ', '') : item.title}
                            titleColor={item.circleColor === '#00FF7F' ? '#16cb9c' : "#39a3f4"}
                            focus={item.idMensagem === this.state.focusMessageBox ? true : false}
                            onMessageFocused={() => { console.log('zerando focus'); this.setState({ focusMessageBox: 'vazio' }); }}
                            onRemoveMessageClick={() => { this.setState({ modalMensagemExcluida: true, dadosMensagemExcluida: item }) }}
                            text={
                                (
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a style={{ fontSize: 15, color: '#39a3f4' }} target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {item.description}
                                    </Linkify>
                                )
                            }
                            copiableDate
                            dateString={item.time}
                            forwarded={false}
                            replyButton={this.renderReplyButton(item)}
                            removeButton={this.renderRemoveButton(item)}
                            status={null} //(waiting, sent, received, read)
                        />
                    </div>
                )
            }
        }
    }
    scrollCheck = event => {
        /*  console.log(event.target.scrollHeight)
         console.log(event.target.scrollTop)
         console.log(event.target.clientHeight);
 
         console.log(this.props.loadingCarregandoMaisConversasChatAcoesChamado)
         console.log(this.props.referenciaUltimaKeyChatAppAcoesChamado)
         console.log(this.props.dadosAtendimentos.length) */
        if (event.target.scrollTop <= 0) {
            console.log('no top, limpando conversas antigas')
            this.props.enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado('')
            this.props.enviaConversasAntigasChatExportAcoesChamado([])
        }
        let scrollInTop = event.target.scrollHeight - event.target.scrollTop;
        // console.log(scrollInTop)
        scrollInTop = (scrollInTop - 20)
        if (scrollInTop <= event.target.clientHeight && this.props.loadingCarregandoMaisConversasChatAcoesChamado === false && this.props.referenciaUltimaKeyChatAppAcoesChamado !== undefined && this.props.dadosAtendimentos.length > 0) {
            console.log("At The Bottom"); //Add in what you want here

            console.log('carregar mais conversas');
            let arrayConversas = [...this.props.acoesDoAtendimento]
            // arrayConversas = arrayConversas.reverse();
            console.log(arrayConversas);
            //  console.log(arrayConversas[arrayConversas.length - 1]);
            let ultimaKey = this.props.referenciaUltimaKeyChatAppAcoesChamado === '' ? arrayConversas[arrayConversas.length - 1].key : this.props.referenciaUltimaKeyChatAppAcoesChamado;
            console.log(this.props.dadosAtendimentos[0].key);
            console.log(this.props.dadosAtendimentos[0].chaveUnica);
            console.log(this.props.dadosAtendimentos[0])
            this.props.listaAcoesChamadoConversasAcoesChamado(1, this.props.dadosAtendimentos[0].chaveUnica, this.props.dadosAtendimentos[0].key, ultimaKey, this.props.arrayConversasAntigasChatAcoesChamado);
        }

    };
    render() {
        registerLocale('pt-BR', pt);
        setDefaultLocale("pt-BR");
        const CheckBoxWrapper = styled.div`
        position: relative;
        margin-left:10px;
        height: 24px;
        min-height: 24px;
      `;
        const CheckBoxLabel = styled.label`
        position: absolute;
        top: 0;
        left: 0;
        width: 42px;
        height: 23px;
        border-radius: 15px;
        background: ${this.props.coresLetraSecundario};
        
        cursor: pointer;
        &::after {
          content: "";
          display: block;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          margin: 3px;
          background: #ffffff;
          box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
          transition: 0.2s;
        }
      `;
        const CheckBox = styled.input`
        opacity: 0;
        z-index: 1;
        
        border-radius: 15px;
        width: 42px;
        height: 26px;
        &:checked + ${CheckBoxLabel} {
          background: #00FF7F;
          &::after {
            content: "";
            display: block;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            margin-left: 21px;
            transition: 0.2s;
          }
        }
      `;

        return (
            <Container backGroundDinamico={this.props.backgroundPrimeiro}>
                {this.renderLoadingAtendimento()}
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalMensagemExcluida} onClose={() => { }} center>
                    <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Apagar Mensagem</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })

                            }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente Apagar a mensagem?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {

                                    console.log(this.state.dadosMensagemExcluida.key)
                                    console.log(this.props.acoesDoAtendimento[0].key)
                                    let isLastMessage = this.state.dadosMensagemExcluida.key === this.props.acoesDoAtendimento[0].key ? true : false;
                                    this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.dadosAtendimentos[0].key, false, isLastMessage)
                                    this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} })
                                    /*  this.props.deletaMensagemDropDesk(this.state.dadosMensagemExcluida, this.props.dadosAtendimentos[0].key, false)
                                     this.setState({ modalMensagemExcluida: false, dadosMensagemExcluida: {} }) */

                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>


                    </ContainerModalGravarAudio>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAtribuirAtendimento} onClose={() => { }} center>
                    <ContainerModal backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Atribuir Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalAtribuirAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerTextCabecalhoModalAcoes>
                            <TextCabecalhoModalAcoes corLetraDinamico={this.props.corBotaoPadrao}>Atribuir atendimento para o Atendente:</TextCabecalhoModalAcoes>
                        </ContainerTextCabecalhoModalAcoes>
                        <ContainerAtendentes>

                            <FlatList
                                list={this.props.atendentesAcoesChamado}
                                renderItem={(item) => this.renderAtendentes(item)}
                                renderWhenEmpty={() => <div>Lista está vazia!</div>}
                            />
                        </ContainerAtendentes>
                    </ContainerModal>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalAvaliarChamado} onClose={() => { }} center>
                    <ContainerModalAvaliacoes backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Avaliar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.abreModalAvaliarChamado(false); }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <div style={{ width: '100%' }}>

                            <div style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row', display: 'flex', marginTop: 15 }}>
                                <i className="icon-review" style={{ fontSize: 24, color: this.props.coresLetraPadrao, padding: 7 }} />
                                <p style={{ fontSize: 22, fontWeight: 'bold', color: this.props.corLetraDescricaoDosCampos, marginVertical: 20 }}>Avalie este Atendimento</p>
                            </div>
                            <div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        flexDirection: 'row'
                                    }}>
                                        <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(5) }}>
                                            <div style={{
                                                display: 'flex',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 12,
                                                borderWidth: 2,
                                                border: `1px solid ${this.props.corBotaoPadrao}`,
                                                //  border:`1px solid ${this.props.corBotaoPadrao}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {
                                                    this.state.isSelectedRadioButton5estrelas ?
                                                        <div style={{
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: 6,
                                                            backgroundColor: this.props.corBotaoPadrao,
                                                        }} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: 18,
                                            fontFamily: 'Source Sans Pro',
                                            color: this.props.coresLetraPadrao,
                                            textAlign: 'left'
                                        }}>Excelente</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />

                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        //alignItems: 'center',
                                        // justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(4) }}>
                                            <div style={{
                                                display: 'flex',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 12,
                                                borderWidth: 2,
                                                border: `1px solid ${this.props.corBotaoPadrao}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {
                                                    this.state.isSelectedRadioButton4estrelas ?
                                                        <div style={{
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: 6,
                                                            backgroundColor: this.props.corBotaoPadrao,
                                                        }} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: 18,
                                            fontFamily: 'Source Sans Pro',
                                            color: this.props.coresLetraPadrao,
                                            textAlign: 'left'
                                        }}>Ótimo</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />

                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        //alignItems: 'center',
                                        // justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(3) }}>
                                            <div style={{
                                                display: 'flex',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 12,
                                                borderWidth: 2,
                                                border: `1px solid ${this.props.corBotaoPadrao}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {
                                                    this.state.isSelectedRadioButton3estrelas ?
                                                        <div style={{
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: 6,
                                                            backgroundColor: this.props.corBotaoPadrao,
                                                        }} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: 18,
                                            fontFamily: 'Source Sans Pro',
                                            color: this.props.coresLetraPadrao,
                                            textAlign: 'left'
                                        }}>Bom</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        //alignItems: 'center',
                                        // justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(2) }}>
                                            <div style={{
                                                display: 'flex',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 12,
                                                borderWidth: 2,
                                                border: `1px solid ${this.props.corBotaoPadrao}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {
                                                    this.state.isSelectedRadioButton2estrelas ?
                                                        <div style={{
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: 6,
                                                            backgroundColor: this.props.corBotaoPadrao,
                                                        }} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: 18,
                                            fontFamily: 'Source Sans Pro',
                                            color: this.props.coresLetraPadrao,
                                            textAlign: 'left'
                                        }}>Regular</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        //alignItems: 'center',
                                        // justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <div style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { this.insereAvaliacao(1) }}>
                                            <div style={{
                                                display: 'flex',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 12,
                                                borderWidth: 2,
                                                border: `1px solid ${this.props.corBotaoPadrao}`,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {
                                                    this.state.isSelectedRadioButton1estrelas ?
                                                        <div style={{
                                                            height: 12,
                                                            width: 12,
                                                            borderRadius: 6,
                                                            backgroundColor: this.props.corBotaoPadrao,
                                                        }} />
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <p style={{
                                            fontSize: 18,
                                            fontFamily: 'Source Sans Pro',
                                            color: this.props.coresLetraPadrao,
                                            textAlign: 'left'
                                        }}>Ruim</p>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row'
                                    }}>
                                        <i className="icon-star" style={{ fontSize: 20, color: "yellow", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                        <i className="icon-starnotavalied" style={{ fontSize: 20, color: "#9fadb7", padding: 5 }} />
                                    </div>
                                </div>
                            </div>
                            <DivInputComentarChamado >
                                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', paddingLeft: 50 }}>
                                    <p style={{ fontSize: 16, color: this.props.coresLetraPadrao }}>Comentário:</p>
                                </div>
                                <InputDescricaoChamado
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaComentarioAvaliacao(value.target.value) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    value={this.props.comentarioAvaliacao}
                                    placeholder='(Opcional)'
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                />
                            </DivInputComentarChamado>
                            {/*  <div style={styles.containerTextInputFormulario}>
                                <Text style={styles.textoInputForm}>Comentário:</Text>
                                <div style={[styles.ViewInputDescricao, this.state.style3]}>
                                    <TextInput
                                        onBlur={() => this.onBlur3()}
                                        onFocus={() => this.onFocus3()}
                                        placeholder='(Opcional)'
                                        placeholderTextColor="#778899"
                                        autoCapitalize="sentences"
                                        autoCorrect={false}
                                        // multiline
                                        style={styles.inputDesc}
                                        value={this.props.comentarioAvaliacao}
                                        onChangeText={value => this.props.modificaComentarioAvaliacao(value)}
                                        selectionColor="#FFF"
                                    />
                                </div>
                            </div> */}
                            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'row', height: 70, flex: 1 }}>
                                <div
                                    style={{ padding: 5, margin: 5, cursor: 'pointer' }}
                                    onClick={() => { this.props.abreModalAvaliarChamado(false); }}
                                >
                                    <p style={{

                                        fontSize: 18,
                                        color: this.props.corBotaoPadrao,
                                        fontFamily: 'Source Sans Pro',
                                        fontWeight: 'bold'
                                    }}>DEPOIS</p>
                                </div>
                                {this.state.valorAvaliacao !== 0 &&
                                    <div
                                        style={{ padding: 5, margin: 5, cursor: 'pointer' }}
                                        onClick={() => {

                                            this.props.cadastraAvaliacaoChamado(this.props.dadosAtendimentos[0], this.props.comentarioAvaliacao, this.state.valorAvaliacao, this.props.verificaSeJaFezAvaliacaoChamado);
                                            toastr.success("Avaliação feita com SUCESSO!!!")

                                        }}
                                    >
                                        <p style={{

                                            fontSize: 18,
                                            color: this.props.corBotaoPadrao,
                                            fontFamily: 'Source Sans Pro',
                                            fontWeight: 'bold'
                                        }}>ENVIAR</p>
                                    </div>}
                                {this.state.valorAvaliacao === 0 &&
                                    <div data-tip="Selecione uma nota de avaliação" data-for="tip-top" style={{ padding: 5, margin: 5 }}>
                                        <p style={{
                                            fontSize: 18,
                                            color: '#9fadb7',
                                            fontFamily: 'Source Sans Pro',
                                            fontWeight: 'bold'
                                        }}>ENVIAR</p>
                                    </div>}
                            </div>
                        </div>
                    </ContainerModalAvaliacoes>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalStatusAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Fechar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalFinalizarAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerInputEBotaoComent>
                            <DivInputComentarChamado >
                                <p style={{ color: this.props.coresLetraPadrao }}>Solução:</p>
                                <InputDescricaoChamado
                                    autoFocus
                                    type="textarea"
                                    onChange={(value) => { this.props.modificaResolucaoProblema(value.target.value) }}
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    value={this.props.solucaoAtendimento}
                                    corLetraSecundaria={this.props.coresLetraSecundario}
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    placeholder="Descreva a SOLUÇÃO do atendimento"
                                />
                            </DivInputComentarChamado>
                        </ContainerInputEBotaoComent>
                        <DivBotaoSalvarComentario>

                            {this.renderBotaoFecharAtendimento()}

                        </DivBotaoSalvarComentario>
                        {/*  <ContainerAtendentes>
                            
                            <TextAtendentes onClick={() => {
                                this.props.modificaVisibilidadeModalFinalizarAtendimento(false);
                                this.props.atribuiStatusAtendimento(this.props.keyAtendimento, 'Fechado', this.props.dadosAtendimentos[0].dataAbertura, this.props.dadosAtendimentos[0], this.props.premium, this.props.verificaSeTemPermissaoParaAvaliarChamado, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0], this.props.licensawebouapp)
                            }} corLetraBotaoPadrao={this.props.corBotaoPadrao} corLetraDinamico={this.props.coresLetraPadrao}>Finalizar</TextAtendentes>
                      

                        </ContainerAtendentes> */}
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalSairVisible} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Reavaliar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModal(); }} className="icon-close" />
                        </ContainerIconeFecharModal>
                        <ContainerAtendentes>
                            <DivInputMotivoCancelamento >
                                <p style={{ fontSize: 25, color: this.props.coresLetraPadrao }}>Este atendimento já foi avaliado, deseja Reavaliar?</p>
                            </DivInputMotivoCancelamento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this._toggleModal(); }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => { this._toggleModal(); this.abrirTelaAvaliacao(); }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalCancelarAtendimento} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Cancelar Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(false) }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <DivInputMotivoCancelamento >
                                <InputCancelamento
                                    corLetraDinamico={this.props.coresLetraPadrao}
                                    autoFocus
                                    type="text"
                                    placeholder="Motivo do Cancelamento"
                                    corBordaDinamica={this.props.corBotaoPadrao}
                                    value={this.props.motivoCancelamento}
                                    onChange={(e) => { this.props.modificaMotivoCancelamentoTelaAcoesAtendimento(e.target.value) }}
                                // onFocus={() => { this.handleInputEmailFocus() }}
                                // onBlur={() => { this.handleInputEmailBlur() }}
                                />
                            </DivInputMotivoCancelamento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this.props.modificaVisibilidadeModalCancelarAtendimento(false) }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => { this.cancelarChamadoLocal(); }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.props.modalComentarioVisible} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Adicionar Comentário</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this.props.modificavisibilidadeModalComentario(false); this.zeraMensagemResponder(); }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <ContainerInputEBotaoComent>
                                {this.state.viewResponder === true &&

                                    <div style={{ display: 'flex', borderTopLeftRadius: 5, borderTopRightRadius: 5, background: '#FFF', width: '100%', padding: 5, flexDirection: 'row' }}>

                                        <div style={{ display: 'flex', background: '#F0f0f7', width: '100%', borderRadius: 3, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                <div style={{ width: 5, height: '100%', borderTopLeftRadius: 3, borderBottomLeftRadius: 3, backgroundColor: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'flex-start', justifyContent: 'flex-start', padding: 5 }}>
                                                    <TextResponder style={{ color: this.state.circleColorResponder === '#00FF7F' ? '#16cb9c' : "#39a3f4" }}>{this.state.circleColorResponder === '#00FF7F' ? this.state.atendenteResponder : 'Você'}</TextResponder>
                                                    {/*  </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}> */}

                                                    <DivTruncarTexto /* style={{ display: 'flex', height: 52, flex: 1, alignItems: 'center', justifyContent: 'flex-start' }} */>


                                                        <TextResponder style={{ color: '#000' }}>
                                                            {this.state.commentResponderType === 'imagem' && <i className="icon-imagewpp" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'video' && <i className="icon-video-camera" style={{ fontSize: 14, color: '#000' }} />}

                                                            {this.state.commentResponderType === 'location' && <i className="icon-localization" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'contato' && <i className="icon-man-user" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'audio' && <i className="icon-microphone" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponderType === 'arquivo' && <i className="icon-documentwpp" style={{ fontSize: 14, color: '#000' }} />}
                                                            {this.state.commentResponder}</TextResponder>

                                                    </DivTruncarTexto>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', width: 40, justifyContent: 'center', alignItems: 'center' }}>
                                            <i onClick={() => { this.zeraMensagemResponder() }} className="icon-close" style={{ fontSize: 13, color: '#000', cursor: 'pointer' }} />
                                        </div>
                                    </div>}
                                <DivInputComentarChamado >
                                    <InputDescricaoChamadoComentaChamado
                                        autoFocus
                                        type="textarea"
                                        onChange={(value) => { this.props.modificaInputComentario(value.target.value) }}
                                        corLetraDinamico={this.props.coresLetraPadrao}
                                        value={this.props.inputComentario}
                                        corLetraSecundaria={this.props.coresLetraSecundario}
                                        corBordaDinamica={this.props.corSegundoBotaoPadrao}
                                        placeholder={"Digite aqui sua Mensagem"}
                                    />
                                </DivInputComentarChamado>
                            </ContainerInputEBotaoComent>
                            <DivBotaoSalvarComentario>
                                {/*  <ContainerPermitir>
                                    <TextPermitir corLetraDinamica={this.props.coresLetraPadrao}>Fechar:</TextPermitir>

                                    <CheckBoxWrapper>
                                        <CheckBox id="checkbox" type="checkbox" checked={this.props.switchComentario} onChange={(value) => {  this.props.ativaDesativaSwitchComentario(value.target.checked);  }} />
                                        <CheckBoxLabel htmlFor="checkbox" />
                                    </CheckBoxWrapper>
                                </ContainerPermitir> */}
                                {this.renderBotaoSalvarComentario()}

                            </DivBotaoSalvarComentario>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>

                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.modalReabrirChamado} onClose={() => { }} center>
                    <ContainerModalPrioridadeAtendimento backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Reabrir Atendimento</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => { this._toggleModalReabrirChamado() }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        <ContainerAtendentes>
                            <DivTextoReabrirAtendimento >
                                <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Deseja realmente reabrir o atendimento?</TextNovoAtendimento>
                            </DivTextoReabrirAtendimento>
                            <DivInputMotivoCancelamento>

                                <ButtonNaoCancelarAtendimento onClick={() => { this._toggleModalReabrirChamado() }} borderColorDinamico={this.props.corBotaoPadrao} backgroundBotao={this.props.backgroundPrimeiro}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.corBotaoPadrao}>Não</TextBotaoCancelarAtendimento>
                                </ButtonNaoCancelarAtendimento>
                                <ButtonCancelarAtendimento onClick={() => {
                                    this._toggleModalReabrirChamado();
                                    this.props.reabrirChamado(this.props.keyAtendimento, this.props.dadosAtendimentos[0].keyAtendente, this.props.dadosAtendimentos[0].keySetor, this.props.dadosAtendimentos[0]);
                                }} backgroundBotao={this.props.corBotaoPadrao}>
                                    <TextBotaoCancelarAtendimento colorDinamico={this.props.letraBotaoPadrao}>Sim</TextBotaoCancelarAtendimento>
                                </ButtonCancelarAtendimento>
                            </DivInputMotivoCancelamento>
                        </ContainerAtendentes>
                    </ContainerModalPrioridadeAtendimento>
                </Modal>
                <Modal focusTrapped={false} showCloseIcon={false} open={this.state.viewMicrophone} onClose={() => { }} center>
                    <ContainerModalGravarAudio backgroundDinamico={this.props.backgroundPrimeiro}>
                        <ContainerIconeFecharModal backgroundDinamico={this.props.backgroundSegundo}>
                            <TextNovoAtendimento corLetraDinamico={this.props.coresLetraPadrao}>Gravar Áudio</TextNovoAtendimento>
                            <IconeFechar backgroundDinamico={this.props.coresLetraPadrao} onClick={() => {
                                this.onStopRecord(true)
                                this._toggleModalGravarAudio()

                            }} className="icon-close" />
                        </ContainerIconeFecharModal>

                        {/* <Recorder
                            record={false}
                            title={"New recording"}
                            audioURL={this.state.audioDetails.url}
                            showUIAudio
                            hideHeader
                            handleAudioStop={data => this.handleAudioStop(data)}
                            handleOnChange={(value) => this.handleOnChange(value, 'firstname')}
                            handleAudioUpload={data => this.handleAudioUpload(data)}
                            handleRest={() => this.handleRest()}
                            handleAudioStart={() => {}} /> */}
                        <div style={{ backgroundColor: this.state.statusAudio == 'gravando' ? '#EC6A69' : this.props.backgroundPrimeiro }} >


                            {this.state.statusAudio !== 'gravacao' && this.state.statusAudio !== 'executandoAudio' && <div style={{ display: 'flex', height: 180, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{
                                    color: this.props.coresLetraPadrao,
                                    fontSize: 45,
                                    fontWeight: '200',
                                    letterSpacing: 3,
                                }}>{this.transformaMinutosEmSegundos(this.state.recordSecs)}</p>

                            </div>}
                            <div style={{
                                display: 'flex',
                                height: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                marginLeft: 5,
                            }}>
                                <div></div>
                                {this.renderTextGravacaoAudio()}
                                <div ></div>
                            </div>
                            {this.renderBotaoGravarAudio()}
                        </div>


                    </ContainerModalGravarAudio>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            modalAlterarAtendimento: state.ChamadoReducer.modalAlterarAtendimento,
            modalAtribuirAtendimento: state.ChamadoReducer.modalAtribuirAtendimento,
            modalAddTempoGasto: state.ChamadoReducer.modalAddTempoGasto,
            modalStatusAtendimento: state.ChamadoReducer.modalStatusAtendimento,
            modalPrioridadeAtendimento: state.ChamadoReducer.modalPrioridadeAtendimento,
            modalCancelarAtendimento: state.ChamadoReducer.modalCancelarAtendimento,
            modalComentarioVisible: state.ChamadoReducer.modalComentarioVisible,
            inputComentario: state.ChamadoReducer.inputComentario,
            comentarioPublicoSimOuNao: state.ChamadoReducer.comentarioPublicoSimOuNao,
            visibilidadeBotaoAddDataVencimento: state.ChamadoReducer.visibilidadeBotaoAddDataVencimento,
            nomeRota: state.HeaderReducer.nomeRota,

            keyAtendimento: state.ChamadoReducer.keyAtendimento,
            numeroAtendimento: state.ChamadoReducer.numeroAtendimento,
            dadosAtendimentos: state.ChamadoReducer.dadosAtendimentos,
            loadingTelaAcoesAtendimento: state.ChamadoReducer.loadingTelaAcoesAtendimento,
            atendentesAcoesChamado: state.ChamadoReducer.atendentesAcoesChamado,
            verificaChamadoAlteradoSucesso: state.ChamadoReducer.verificaChamadoAlteradoSucesso,
            arrayArquivosUpados1: state.ChamadoReducer.arrayArquivosUpados1,
            arrayArquivosUpados2: state.ChamadoReducer.arrayArquivosUpados2,
            arrayArquivosUpados3: state.ChamadoReducer.arrayArquivosUpados3,
            acoesDoAtendimento: state.ChamadoReducer.acoesDoAtendimento,
            motivoCancelamento: state.ChamadoReducer.motivoCancelamento,
            mensagemErroMotivoCancelamento: state.ChamadoReducer.mensagemErroMotivoCancelamento,
            loadingModalCancelarAtendimento: state.ChamadoReducer.loadingModalCancelarAtendimento,

            nomeArquivoAnexadoComentario: state.ChamadoReducer.nomeArquivoAnexadoComentario,
            switchComentario: state.ChamadoReducer.switchComentario,
            inputComentario: state.ChamadoReducer.inputComentario,
            modalComentarioVisible: state.ChamadoReducer.modalComentarioVisible,
            resArquivoAnexadoComentario: state.ChamadoReducer.resArquivoAnexadoComentario,
            comentarioPublicoSimOuNao: state.ChamadoReducer.comentarioPublicoSimOuNao,
            verificaInternetSalvarComentario: state.ChamadoReducer.verificaInternetSalvarComentario,
            arquivosUpadosVinculadoAoChamado: state.ChamadoReducer.arquivosUpadosVinculadoAoChamado,
            loadingArquivosUpadosVinculadoAoChamado: state.ChamadoReducer.loadingArquivosUpadosVinculadoAoChamado,
            premium: state.PremiumReducer.premium,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
            verificaSeAtribuirAtendenteObrigatorio: state.ChamadoReducer.verificaSeAtribuirAtendenteObrigatorio,
            verificaInternetAcoesAtendimento: state.ChamadoReducer.verificaInternetAcoesAtendimento,

            clientesNovoChamado: state.ChamadoReducer.clientesNovoChamado,
            atendenteNovoChamado: state.ChamadoReducer.atendenteNovoChamado,
            setorNovoChamado: state.ChamadoReducer.setorNovoChamado,
            clienteSelecionado: state.ChamadoReducer.clienteSelecionado,
            atendenteSelecionado: state.ChamadoReducer.atendenteSelecionado,
            localAtendimentoSelecionado: state.ChamadoReducer.localAtendimentoSelecionado,
            localAtendimento: state.ChamadoReducer.localAtendimento,
            prioridadeSelecionada: state.ChamadoReducer.prioridadeSelecionada,
            prioridade: state.ChamadoReducer.prioridade,
            setorSelecionado: state.ChamadoReducer.setorSelecionado,
            descricaoAtendimento: state.ChamadoReducer.descricaoAtendimento,
            dataVencimento: state.ChamadoReducer.dataVencimento,
            retornaTelaClienteParaNovoAtendimentos: state.ChamadoReducer.retornaTelaClienteParaNovoAtendimentos,
            tamanhoTotalARquivoAnexado: state.ChamadoReducer.tamanhoTotalARquivoAnexado,
            verificaSeJaFezAvaliacaoChamado: state.ChamadoReducer.verificaSeJaFezAvaliacaoChamado,
            verificaSeTemPermissaoParaAvaliarChamado: state.ChamadoReducer.verificaSeTemPermissaoParaAvaliarChamado,
            verificaAgrupaAtendenteSetor: state.PremiumReducer.verificaAgrupaAtendenteSetor,
            criarChamadoProblemaEstatico: state.PremiumReducer.criarChamadoProblemaEstatico,
            modalAvaliarChamado: state.ChamadoReducer.modalAvaliarChamado,
            comentarioAvaliacao: state.ChamadoReducer.comentarioAvaliacao,

            loadingCarregaProblemasEstaticos: state.ChamadoReducer.loadingCarregaProblemasEstaticos,
            problemasEstatico: state.ChamadoReducer.problemasEstatico,
            problemaEstaticoSelecionado: state.ChamadoReducer.problemaEstaticoSelecionado,
            nomeProblemaEstaticoSelecionado: state.ChamadoReducer.nomeProblemaEstaticoSelecionado,

            solucaoAtendimento: state.ChamadoReducer.solucaoAtendimento,


            loadingCarregandoMaisConversasChatAcoesChamado: state.ChamadoReducer.loadingCarregandoMaisConversasChatAcoesChamado,
            referenciaUltimaKeyChatAppAcoesChamado: state.ChamadoReducer.referenciaUltimaKeyChatAppAcoesChamado,
            arrayConversasAntigasChatAcoesChamado: state.ChamadoReducer.arrayConversasAntigasChatAcoesChamado,
            loadingCarregandoRespostasAntigasAcoesChamado: state.ChamadoReducer.loadingCarregandoRespostasAntigasAcoesChamado,
            idRespostaAntigaAcoesChamado: state.ChamadoReducer.idRespostaAntigaAcoesChamado,

        }
    );
};
export default connect(mapStateToProps, {
    modificaVisibilidadeModalAlterarAtendimento, modificaVisibilidadeModalAtribuirAtendimento,
    modificaVisibilidadeModalAddTempoGastoAtendimento, modificaVisibilidadeModalFinalizarAtendimento,
    modificaVisibilidadeModalPrioridadeAtendimento, modificaVisibilidadeModalCancelarAtendimento,
    modificavisibilidadeModalComentario, modificaInputComentario, modificaComentarioPublicoSimOuNao,
    modificaVisibilidadeBotaoAddVencimento, modificaNomeRotaNoHeader, listaChamadoParaTelaAcoesChamado,
    modificaClienteSelecionado, modificaAtendenteSelecionado, modificaLocalSelecionado, modificaPrioridadeSelecionado,
    modificaDataVencimentoTelaChamadoAbertura, modificaSetorSelecionado, alteraDescricaoChamado,
    modificaKeyEmpresaVinculadaSelecionadaAoAbrirChamado, alterarChamado, salvarComentario2,
    exportaAtendimentoParaPDF, atribuiAtendimentoAh, atribuirTempoEsperaAoChamado,
    atribuiStatusAtendimento, alterarPrioridadeAtendimento, alterarDataVencimentoAtendimento, modificaMotivoCancelamentoTelaAcoesAtendimento,
    cancelarChamado, ativaDesativaSwitchComentario, reabrirChamado,
    modificaRetornaTelaClienteParaNovoAtendimento,
    zeraInformacoesCadastroClienteExport, modificaVisibleModalNovoCLiente, modificaNumeroAtendimento,
    listaSetorTelaNovoChamado, abreModalAvaliarChamado, modificaComentarioAvaliacao,
    cadastraAvaliacaoChamado, modificaProblemaEstaticoSelecionado, modificaNomeProblemaEstaticoSelecionado,
    listaProblemasEstaticosTelaNovoChamado, alterarChamado2, alteraNumeroAtendimento, alteraKeyAtendimento,
    modificaResolucaoProblema, listaAcoesChamadoConversasAcoesChamado, listaConversasAntigasResponderAcoes,
    enviaReferenciaultimaKeyAcoesChamadoExportAcoesChamado, enviaConversasAntigasChatExportAcoesChamado,
    deletaMensagemDropDesk
})(withRouter(AcoesChamado));
