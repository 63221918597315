import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import firebase from '../../servicos/FirebaseConnection';
import {
    ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal,
    ContainerIconeEFrase, TextCliente,
    SubContainerIconeEFraseClienteAtendente,
    ContainerSeta, TextEmpresa
} from './styled';
import {
    navegaTelaALterarChamado, navegaTelaAlterarCliente
} from '../../actions/ChamadoAction';
import imgTeste from '../../imagens/userPadrao.png'

class FlatListClientes extends React.Component {
    state = {
        fotoCliente: null,
        empresa: this.props.data.empresa,
        nome: this.props.data.nome,
        keyFoto: this.props.data.key,
        imagemLink:false
    }

    componentDidMount() {
       /*  console.log(this.props.data);
        console.log(this.props.clientes) */

        //cliente do chamado da moda antiga, ainda nao foi alterado na versao nova, fazer o metodo tradicional
        if (this.props.data.urlImagemNuvem == undefined || this.props.data.urlImagemNuvem == null) {
          /*   console.log('----- NO CHAMADO CARREGA IMAGEM MODA ANTIGA -----') */

            firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.state.keyFoto}/imagem.jpg`).getDownloadURL().then((url) => {
                setTimeout(() => {
                    this.setState({ fotoCliente: url });
                    if (this.props.clientes.length >= 1) {
                        if (this.props.clientes[0].key == this.props.data.key) {
                            this.props.navegaTelaAlterarCliente(this.state.fotoCliente, this.props.data.empresa,
                                this.props.data.nome, this.props.data.email, this.props.data.permissaoAPP,
                                this.props.data.senha, this.props.data.senha, this.props.data.key, this.props.data.keyEmpresaVinculada, this.props.data.empresaPrincipal, this.props.data.telefoneCliente, this.props.data.enderecoCliente)
                        }
                    }
                }, 0);
            }).catch(() => {
                setTimeout(() => {
                    const primeiraLetra = this.state.nome.substring(0, 1).trim().toLowerCase();
                    this.verificaSeEhAlphabeto(primeiraLetra);
                    if (this.props.clientes[0].key == this.props.data.key) {
                        this.props.navegaTelaAlterarCliente(this.state.fotoCliente, this.props.data.empresa,
                            this.props.data.nome, this.props.data.email, this.props.data.permissaoAPP,
                            this.props.data.senha, this.props.data.senha, this.props.data.key, this.props.data.keyEmpresaVinculada, this.props.data.empresaPrincipal, this.props.data.telefoneCliente, this.props.data.enderecoCliente)
                    }
                    /*  firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
           
                       this.setState({ fotoCliente: { uri: url } });
                     }).catch(() => {
           
                       const fotoURI = { uri: 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af' }
                       this.setState({ fotoCliente: fotoURI });
                     }) */
                }, 0);
            });


        } else {
            //aqui o campo urlImagemNuvem já existe no banco, entao o cliente já foi alterado ou foi criado no novo modelo

            //aqui irei verificar se o campo urlimagemnuvem tem o link da imagem na nuvem, se tiver eu pesquiso pela imagem se nao eu pego a imagem da letra
            if (this.props.data.urlImagemNuvem !== '') {
                //tem link
                setTimeout(() => {
                   /*  console.log('----- NO CHAMADO CARREGA IMAGEM NOVA COM LINK -----'); */
                    /*  firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.state.keyFoto}/imagem.jpg`).getDownloadURL().then((url) => {
                         this.setState({ fotoCliente: { uri: url } });
                     }).catch(() => { */
                  /*   console.log(this.props.data.key)
                    console.log(this.props.data.urlImagemNuvem) */
                    this.setState({ fotoCliente: this.props.data.urlImagemNuvem });
                    this.setState({ imagemLink: true })
                    if (this.props.clientes[0].key == this.props.data.key) {
                        this.props.navegaTelaAlterarCliente(this.state.fotoCliente, this.props.data.empresa,
                            this.props.data.nome, this.props.data.email, this.props.data.permissaoAPP,
                            this.props.data.senha, this.props.data.senha, this.props.data.key, this.props.data.keyEmpresaVinculada, this.props.data.empresaPrincipal, this.props.data.telefoneCliente, this.props.data.enderecoCliente)
                    }
                    /*  const primeiraLetra = this.state.nome.substring(0, 1).trim().toLowerCase();
                     this.verificaSeEhAlphabeto(primeiraLetra); */
                    /* }) */
                }, 0);


            } else {
                setTimeout(() => {
                   /*  console.log('----- NO CHAMADO CARREGA IMAGEM NOVA POREM CAMPO VAZIO ENTAO CARREGO SOMENTE COM LETRA OU USER PADRAO-----'); */
                    const primeiraLetra = this.state.nome.substring(0, 1).trim().toLowerCase();
                    this.verificaSeEhAlphabeto(primeiraLetra);
                    if (this.props.clientes[0].key == this.props.data.key) {
                        this.props.navegaTelaAlterarCliente(this.state.fotoCliente, this.props.data.empresa,
                            this.props.data.nome, this.props.data.email, this.props.data.permissaoAPP,
                            this.props.data.senha, this.props.data.senha, this.props.data.key, this.props.data.keyEmpresaVinculada, this.props.data.empresaPrincipal, this.props.data.telefoneCliente, this.props.data.enderecoCliente)
                    }
                }, 0);
            }


        }


    }

    navegaTelaAlterarClienteLocal(e) {
        e.preventDefault();
        /*   if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarCliente == undefined) {
            alert('Verifique se há conexão com a INTERNET e depois tente novamente.');
          } else {
            if (this.props.permissoesAcessarTelasAtendente[0].permissaoAlterarCadastrarCliente) { */
        this.props.navegaTelaAlterarCliente(this.state.fotoCliente, this.props.data.empresa,
            this.props.data.nome, this.props.data.email, this.props.data.permissaoAPP,
            this.props.data.senha, this.props.data.senha, this.props.data.key, this.props.data.keyEmpresaVinculada, this.props.data.empresaPrincipal, this.props.data.telefoneCliente, this.props.data.enderecoCliente)
        /*  } else {
           Alert.alert(
             'Atenção',
             'Você não tem permissão para Cadastrar/Alterar Clientes.',
   
             [{ text: 'OK', onPress: () => { } }],
           );
         }
       } */

    }
    verificaSeEhAlphabeto = (ch) => {
        // console.log(ch)
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //console.log('no metodo')
        // console.log(verificaSeEhAlphabeto)
        if (verificaSeEhAlphabeto) {
            //console.log('é do alfabeto')
            switch (ch) {
                case "a":
                    this.setState({ fotoCliente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoCliente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoCliente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoCliente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoCliente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoCliente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoCliente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoCliente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoCliente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoCliente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoCliente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoCliente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoCliente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoCliente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoCliente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoCliente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoCliente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoCliente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoCliente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoCliente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoCliente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoCliente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoCliente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoCliente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoCliente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoCliente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoCliente: require('../../imagens/userPadrao.png') });
                    /* console.log('dentro do default') */
                    break;

            }
        } else {
            //   console.log('não é do alfabeto')
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoCliente: urlIMG });
        }

    }
    renderImagem() {
        if (this.state.fotoCliente == null ) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else{
            return (
                <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 , backgroundColor: 'rgba(159,173,183, 0.3)'}} />
            )
        }
    }
  /*   renderImagem() {
        if (this.state.fotoCliente == null && this.state.imagemLink == false) {
            return (
                <div style={{ height: 40, width: 40, borderRadius: '50%', backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else if(this.state.imagemLink){
            return (
                <img src={this.props.data.urlImagemNuvem} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} />
            )
        }else{
            return (
                <img src={this.state.fotoCliente} alt="foto cliente" style={{ height: 40, width: 40, borderRadius: 50 }} />
            )
        }
    } */

    render() {
        return (

            <ContainerChamado backGroundDinamico={this.props.backgroundMaisEscuro} type="button" onClick={(e) => { this.navegaTelaAlterarClienteLocal(e) }}>
                {this.props.data.key == this.props.keyCliente && <div style={{ width: 5, backgroundColor: this.props.corBotaoPadrao, height: 58, borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}></div>}
                <ContainerImagem>
                    {this.renderImagem()}

                </ContainerImagem>
                <ContainerDadosChamado>
                    <ContaineDadosChamadosPrincipal>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextCliente data-tip="Nome" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.nome}</TextCliente>
                        </SubContainerIconeEFraseClienteAtendente>
                        <SubContainerIconeEFraseClienteAtendente >
                            <TextEmpresa data-tip="Empresa" data-for="tip-top" corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.empresa}</TextEmpresa>
                        </SubContainerIconeEFraseClienteAtendente>
                    </ContaineDadosChamadosPrincipal>
                    <ContainerSeta>
                        <i className="icon-nextbold" style={{ fontSize: 10, color: this.props.coresLetraSecundario }} />
                    </ContainerSeta>

                </ContainerDadosChamado>


            </ContainerChamado>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            keyCliente: state.ChamadoReducer.keyCliente,
            email: state.LoginReducer.email,
            clientes: state.ChamadoReducer.clientes,
        }
    );
};
export default connect(mapStateToProps, {
    navegaTelaALterarChamado, navegaTelaAlterarCliente
})(withRouter(FlatListClientes));
