import React,{memo} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import firebase from '../../servicos/FirebaseConnection';
import ReactTooltip from "react-tooltip";
import {
    Title, ContainerChamado, ContainerImagem, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase, TextCliente, TextProblema, ContainerIconeEFraseClienteAtendente,
    SubContainerIconeEFraseClienteAtendente, TextNumeroChamado,
    ContainerNumeroChamado, ContainerDataAbertura, TextAbertura, TextDataHoraAbertura,
    ContainerAberturaFechamento, TextAtendente

} from './styled';
import {
    navegaTelaALterarChamado
} from '../../actions/ChamadoAction';
import imgTeste from '../../imagens/userPadrao.png'

class FlatListChamadoTodos extends React.Component {
    state = {
        fotoCliente: null,
    }

    componentDidMount() {
        ReactTooltip.rebuild();
        /*  console.log('dentro do did mount')
         console.log(this.props.data.length) */
        /*   if (this.props.data.length > 0) { */
        //cliente do chamado da moda antiga, ainda nao foi alterado na versao nova, fazer o metodo tradicional
        if (this.props.data.urlImagemNuvem == undefined && this.props.data.cliente !== "Não informado") {
            //  console.log('----- NO CHAMADO ABERTO CARREGA IMAGEM MODA ANTIGA -----')

            firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.props.data.keyCliente}/imagem.jpg`).getDownloadURL().then((url) => {
                this.setState({ fotoCliente: url });
            }).catch(() => {
                const primeiraLetra = this.props.data.cliente.substring(0, 1).trim().toLowerCase();
                this.verificaSeEhAlphabeto(primeiraLetra);

                /*  firebase.storage().ref(`letras/${primeiraLetra}.png`).getDownloadURL().then((url) => {
       
                   this.setState({ fotoCliente: { uri: url } });
                 }).catch(() => {
       
                   const fotoURI = { uri: 'https://firebasestorage.googleapis.com/v0/b/dark-4b307.appspot.com/o/imagemPadraoDark%2Fuser.png?alt=media&token=5494861d-8431-480d-ac62-442c8b2c39af' }
                   this.setState({ fotoCliente: fotoURI });
                 }) */
            });


        } else {
            //aqui o campo urlImagemNuvem já existe no banco, entao o cliente já foi alterado ou foi criado no novo modelo

            //aqui irei verificar se o campo urlimagemnuvem tem o link da imagem na nuvem, se tiver eu pesquiso pela imagem se nao eu pego a imagem da letra
            if (this.props.data.urlImagemNuvem !== '' && this.props.data.urlImagemNuvem !== undefined) {
                //tem link

                // console.log('----- NO CHAMADO CARREGA IMAGEM NOVA COM LINK -----');
                firebase.storage().ref().child(`${this.props.data.chaveUnica}/${this.props.data.keyCliente}/imagem.jpg`).getDownloadURL().then((url) => {
                    /*  console.log(url) */
                    this.setState({ fotoCliente: url });
                }).catch(() => {
                    if (this.props.data.urlImagemNuvem.includes('whatsapp')) {
                        this.setState({ fotoCliente: this.props.data.urlImagemNuvem });
                    } else {
                        const primeiraLetra = this.props.data.cliente.substring(0, 1).trim().toLowerCase();
                        this.verificaSeEhAlphabeto(primeiraLetra);
                    }
                })



            } else {
                //  console.log('----- NO CHAMADO CARREGA IMAGEM NOVA POREM CAMPO VAZIO ENTAO CARREGO SOMENTE COM LETRA OU USER PADRAO-----');
                const primeiraLetra = this.props.data.cliente.substring(0, 1).trim().toLowerCase();
                this.verificaSeEhAlphabeto(primeiraLetra);
            }


        }
    }
    /*   } */
    verificaSeEhAlphabeto = (ch) => {
        // console.log(ch)
        let verificaSeEhAlphabeto = typeof ch === "string" && ch.length === 1
            && (ch >= "a" && ch <= "z");
        //console.log('no metodo')
        // console.log(verificaSeEhAlphabeto)
        if (verificaSeEhAlphabeto) {
            //console.log('é do alfabeto')
            switch (ch) {
                case "a":
                    this.setState({ fotoCliente: require('../../imagens/a.png') });
                    break;
                case "b":
                    this.setState({ fotoCliente: require('../../imagens/b.png') });
                    break;
                case "c":
                    this.setState({ fotoCliente: require('../../imagens/c.png') });
                    break;
                case "d":
                    this.setState({ fotoCliente: require('../../imagens/d.png') });
                    break;
                case "e":
                    this.setState({ fotoCliente: require('../../imagens/e.png') });
                    break;
                case "f":
                    this.setState({ fotoCliente: require('../../imagens/f.png') });
                    break;
                case "g":
                    this.setState({ fotoCliente: require('../../imagens/g.png') })
                    break;
                case "h":
                    this.setState({ fotoCliente: require('../../imagens/h.png') });
                    break;
                case "i":
                    this.setState({ fotoCliente: require('../../imagens/i.png') });
                    break;
                case "j":
                    this.setState({ fotoCliente: require('../../imagens/j.png') });
                    break;
                case "k":
                    this.setState({ fotoCliente: require('../../imagens/k.png') });
                    break;
                case "l":
                    this.setState({ fotoCliente: require('../../imagens/l.png') });
                    break;
                case "m":
                    this.setState({ fotoCliente: require('../../imagens/m.png') });
                    break;
                case "n":
                    this.setState({ fotoCliente: require('../../imagens/n.png') });
                    break;
                case "o":
                    this.setState({ fotoCliente: require('../../imagens/o.png') });
                    break;
                case "p":
                    this.setState({ fotoCliente: require('../../imagens/p.png') });
                    break;
                case "q":
                    this.setState({ fotoCliente: require('../../imagens/q.png') });
                    break;
                case "r":
                    this.setState({ fotoCliente: require('../../imagens/r.png') });
                    break;
                case "s":
                    this.setState({ fotoCliente: require('../../imagens/s.png') });
                    break;
                case "t":
                    this.setState({ fotoCliente: require('../../imagens/t.png') });
                    break;
                case "u":
                    this.setState({ fotoCliente: require('../../imagens/u.png') });
                    break;
                case "v":
                    this.setState({ fotoCliente: require('../../imagens/v.png') });
                    break;
                case "x":
                    this.setState({ fotoCliente: require('../../imagens/x.png') });
                    break;
                case "z":
                    this.setState({ fotoCliente: require('../../imagens/z.png') });
                    break;
                case "w":
                    this.setState({ fotoCliente: require('../../imagens/w.png') });
                    break;
                case "y":
                    this.setState({ fotoCliente: require('../../imagens/y.png') });
                    break;
                default:
                    this.setState({ fotoCliente: require('../../imagens/userPadrao.png') });
                    /*  console.log('dentro do default') */
                    break;

            }
        } else {
            //   console.log('não é do alfabeto')
            let urlIMG = require('../../imagens/userPadrao.png')
            this.setState({ fotoCliente: urlIMG });
        }

    }
    estiloTextoStatus(status) {
        if (status == "Aberto") {
            return "#00BFFF"
        } else if (status == "Atendendo") {
            return "#FFA500"

        } else if (status == "Fechado") {
            return "#00FF7F"

        } else if (status == "Cancelado") {
            return "#f70e4c"
        }
    }
    estiloTextoPrioridade(textoPrioridade) {
        if (textoPrioridade == "Baixa") {
            return {
                color: "#FFF",
                fontSize: 13,
                marginLeft: 4,
                fontWeight: 'bold'
            };
        } else if (textoPrioridade == "Urgente") {
            return {
                color: "#f70e4c",
                fontSize: 13,
                marginLeft: 4,
                fontWeight: 'bold'
            };
        } else if (textoPrioridade == "Alta") {
            return {
                color: "#FFA500",
                fontSize: 13,
                marginLeft: 4,
                fontWeight: 'bold'
            };
        } else if (textoPrioridade == "Normal") {
            return {
                color: "#B0C4DE",
                fontSize: 13,
                marginLeft: 4,
                fontWeight: 'bold'
            };
        }
        else if (textoPrioridade == "Não informado") {
            return {
                color: this.props.coresLetraPadrao,
                fontSize: 13,
                marginLeft: 4,
                fontWeight: 'bold'
            };
        }
    }
    estiloDivTextoPrioridade(textoPrioridade) {
        if (textoPrioridade == "Baixa") {
            return {
                height: 6,
                width: 6,
                backgroundColor: "#FFF",
            };
        } else if (textoPrioridade == "Urgente") {
            return {
                height: 6,
                width: 6,
                backgroundColor: "#f70e4c",
            };
        } else if (textoPrioridade == "Alta") {
            return {
                height: 6,
                width: 6,
                backgroundColor: "#FFA500",
            };
        } else if (textoPrioridade == "Normal") {
            return {
                height: 6,
                width: 6,
                backgroundColor: "#B0C4DE",
            };
        }
        else if (textoPrioridade == "Não informado") {
            return {
                width: 6,
                height: 6,
                backgroundColor: this.props.coresLetraPadrao,
            };
        }
    }
    renderDataHoraAbertura(status) {
        // console.log(status)
        if (status == 'Cancelado') {
            return (
                <ContainerAberturaFechamento>
                    <ContainerDataAbertura>
                        <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                        <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataHoraAbertura}</TextDataHoraAbertura>
                    </ContainerDataAbertura>
                    <ContainerDataAbertura>
                        <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Cancelamento: </TextAbertura>
                        <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataHoraCancelamento}</TextDataHoraAbertura>
                    </ContainerDataAbertura>
                </ContainerAberturaFechamento>
            )
        } else if (status == 'Fechado') {
            return (
                <ContainerAberturaFechamento>
                    <ContainerDataAbertura>
                        <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                        <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataHoraAbertura}</TextDataHoraAbertura>
                    </ContainerDataAbertura>
                    <ContainerDataAbertura>
                        <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Fechamento: </TextAbertura>
                        <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataHoraFechamento}</TextDataHoraAbertura>
                    </ContainerDataAbertura>
                </ContainerAberturaFechamento>
            )
        } else {
            return (
                <ContainerDataAbertura>
                    <TextAbertura corLetraDinamica={this.props.coresLetraPadrao}>Abertura: </TextAbertura>
                    <TextDataHoraAbertura corLetraDinamica={this.props.coresLetraSecundario}>{this.props.data.dataHoraAbertura}</TextDataHoraAbertura>
                </ContainerDataAbertura>
            )
        }
    }
    navegaTelaAcoesChamado(key, numerochamado) {

    }
    renderImagem() {
        if (this.state.fotoCliente == null) {
            return (
                <div style={{ height: 60, width: 60, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        } else {
            return (
                <img src={this.state.fotoCliente} alt="Foto Cliente" style={{ height: 60, width: 60, borderRadius: 50, backgroundColor: 'rgba(159,173,183, 0.3)', border: 'none' }} />
            )
        }
    }
    hideTooltips() {
        ReactTooltip.hide()
        /* setTimeout(
            () => {
                ReactTooltip.rebuild();
                ReactTooltip.show();
    
            },
            100
        ) */
    }
    render() {
        return (

            <ContainerChamado type="button" onClick={() => {
                if (this.props.data.channelChamado !== undefined && this.props.data.channelChamado.includes('Chat') && this.props.data.status == 'Aberto') {
                    toastr.warning('Aviso', 'Este chamado está em aberto na fila de espera do Chat Online, portanto ainda não pode ser visualizado.')

                } else if (this.props.data.channelChamado !== undefined && this.props.data.channelChamado.includes('Chat') && this.props.data.status == 'Atendendo') {
                    toastr.warning('Aviso', 'Este chamado está em atendimento através do Chat Online, portanto ainda não pode ser visualizado.')
                } else if (this.props.data.channelChamado !== undefined && this.props.data.channelChamado.includes('Chat')) {
                    toastr.warning('Aviso', 'Este chamado ainda não foi finalizado pelo atendente, portanto ainda não pode ser visualizado.')
                } else {
                    this.props.navegaTelaALterarChamado(this.props.data.key, this.props.data.numeroChamado, this.props.history);
                    this.hideTooltips();
                }
            }} backGroundDinamico={this.props.backgroundMaisEscuro}>

                <ContainerImagem>
                    {this.renderImagem()}
                </ContainerImagem>
                <ContainerDadosChamado>
                    <ContaineDadosChamadosPrincipal>
                        <ContainerIconeEFraseClienteAtendente>
                            <SubContainerIconeEFraseClienteAtendente >
                                <i className="icon-man-user" style={{ color: this.props.coresLetraSecundario, fontSize: 11 }} />
                                <TextCliente data-tip="Cliente" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.cliente}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente>
                            {/*  <SubContainerIconeEFraseClienteAtendente >
                                <i className="icon-hotel1" style={{ marginLeft: 15, color: this.props.coresLetraSecundario, fontSize: 11 }} />
                                <TextCliente data-tip="Empresa" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.empresa}</TextCliente>
                            </SubContainerIconeEFraseClienteAtendente> */}
                        </ContainerIconeEFraseClienteAtendente>


                        <ContainerIconeEFrase >
                            {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                            <TextProblema data-tip="Descrição do Problema" data-for="tip-top" corLetraDinamica={this.props.corLetraDescricaoDosCampos}>{this.props.data.descricaoProblema}</TextProblema>
                        </ContainerIconeEFrase>
                        <ContainerIconeEFrase >
                            <i data-tip="Atendente" data-for="tip-top" className="icon-atendimentos" style={{ color: this.props.coresLetraSecundario, fontSize: 11 }} />
                            <TextAtendente data-tip="Atendente" data-for="tip-top" corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.atendente}</TextAtendente>
                        </ContainerIconeEFrase>
                        {this.renderDataHoraAbertura(this.props.data.status)}

                    </ContaineDadosChamadosPrincipal>
                    <ContaineDadosChamadosSecundario>
                        <ContainerIconeEFrase data-tip="Status" data-for="tip-top">
                            <div style={{ height: 6, width: 6, backgroundColor: this.estiloTextoStatus(this.props.data.status) }}></div>
                            <TextCliente style={{ marginLeft: 4 }} corLetraDinamica={this.estiloTextoStatus(this.props.data.status)}>{this.props.data.status}</TextCliente>
                        </ContainerIconeEFrase>

                        <ContainerIconeEFrase data-tip="Setor" data-for="tip-top">
                            <i className="icon-icon" style={{ color: this.props.coresLetraSecundario, fontSize: 8, marginLeft: -2 }} />
                            <TextCliente corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.setor}</TextCliente>
                        </ContainerIconeEFrase>
                        {/*  <ContainerIconeEFrase data-tip="Prioridade" data-for="tip-top">
                            <div style={this.estiloDivTextoPrioridade(this.props.data.prioridade)}></div>
                            <p style={this.estiloTextoPrioridade(this.props.data.prioridade)}>{this.props.data.prioridade}</p>
                        </ContainerIconeEFrase> */}
                    </ContaineDadosChamadosSecundario>
                </ContainerDadosChamado>
                <ContainerNumeroChamado>
                    <p style={{ fontSize: 14, color: this.props.coresLetraPadrao }}>#</p><TextNumeroChamado corLetraDinamica={this.props.coresLetraPadrao}>{this.props.data.numeroChamado}</TextNumeroChamado>
                </ContainerNumeroChamado>

            </ContainerChamado>

        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            coresLetraSecundario: state.CoresPadraoSistema.coresLetraSecundario,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            corLetraDescricaoDosCampos: state.CoresPadraoSistema.corLetraDescricaoDosCampos,
            nomeRota: state.HeaderReducer.nomeRota,
            email: state.LoginReducer.email,
        }
    );
};
export default withRouter(connect(mapStateToProps, {
    navegaTelaALterarChamado
})(memo(FlatListChamadoTodos)));
