import React from 'react';
import { connect } from 'react-redux';

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FlatList from 'flatlist-react';
import FlatListChamadoFechado from "./FlatListChamadoFechado";
import ReactTooltip from "react-tooltip";
import firebase from '../../servicos/FirebaseConnection';
import MoonLoader from "react-spinners/MoonLoader";
import SelectSearch from 'react-select-search';


import { modificaPesquisaTeste } from '../../actions/HeaderAction';
import {
    zeraMensagemSemInternetChamadoAberto, navegaTelaNovoChamado,
    modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,
    
    ativaDesativaLoadingFinalFlatListChamadoFechadoExport,listaOsChamados
} from "../../actions/ChamadoAction";

import {
    Title, Container, IconeSair, ContainerInputSearch, InputFiltroPorNumero, BotaoPesquisarChamadoPorNumero,
    InputSearch, DivInputSearch, IconeSetaPraBaixo,
    TextProblemaLoading, ContainerDadosChamado,
    ContaineDadosChamadosPrincipal, ContaineDadosChamadosSecundario,
    ContainerIconeEFrase,
    ContainerImagemLoading, ImageLoading, TextClienteLoading, ContainerChamadoLoading,
    ContainerListaVazia
} from './styled';

class ChamadoFechado extends React.Component {
    state = {
        filtroTeste: false,
        hasMoreItems: false,
        offset: 0,
        search: '',
        maisDados: [],
        loading: true,
        chamados:
            [
                {
                    key: '1', cliente: 'HevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHevertonHeverton', numeroChamado: 1, empresa: 'Hera Engenharia de Software LTDAHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTDHera Engenharia de Software LTD',
                    setor: 'ERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERPERP', descricaoProblema: 'Computador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não ligaComputador Não liga', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Aberto', atendente: 'João VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão VictorJoão Victor', prioridade: 'Alta'
                },
                {
                    key: '2', cliente: 'Thalita Amorim', numeroChamado: 2, empresa: 'Descanse em paz',
                    setor: 'Funeraria', descricaoProblema: 'Sistema não está imprimindo na impressora A4', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Fechado', atendente: 'Genilson Candido', prioridade: 'Normal', dataHoraFechamento: '19/10/2020 12:12:01'
                },
                {
                    key: '3', cliente: 'Sérgio Andrade', numeroChamado: 3, empresa: 'DropDesk',
                    setor: 'App', descricaoProblema: 'Aplicativo não transmite dados', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Fechado', atendente: 'Ronaldo', prioridade: 'Urgente', dataHoraFechamento: '19/10/2020 12:12:01'
                },
                {
                    key: '4', cliente: 'João Victor', numeroChamado: 4, empresa: 'Jiu Jtsu',
                    setor: 'Faixa', descricaoProblema: 'Faixa ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Fechado', atendente: 'Carlos Magno', prioridade: 'Baixa', dataHoraFechamento: '19/10/2020 12:12:01'
                },
                {
                    key: '5', cliente: 'Não informado', numeroChamado: 5, empresa: 'Não informado',
                    setor: 'Não informado', descricaoProblema: 'No Break Ruim', dataHoraAbertura: '19/10/2020 12:12:01',
                    status: 'Fechado', atendente: 'Não informado', prioridade: 'Não informado', dataHoraFechamento: '19/10/2020 12:12:01'
                },
                /* {key:'6',cliente:'Aldair Fernandes',numeroChamado:6,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'7',cliente:'Aldair Fernandes',numeroChamado:7,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'8',cliente:'Aldair Fernandes',numeroChamado:8,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'9',cliente:'Aldair Fernandes',numeroChamado:9,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},
                 {key:'10',cliente:'Aldair Fernandes',numeroChamado:10,empresa:'Hera Engenharia de Software LTDA',
                 setor:'Financeiro',descricaoProblema:'Computador Não liga',dataHoraAbertura:'19/10/2020 12:12:01',
                 status:'Aberto',atendente:'João Victor',prioridade:'Urgente'},  */
            ]
    }
    componentDidMount() {
        ReactTooltip.rebuild();
    }
    componentWillMount() {
       // this.props.listaOsUltimosChamadosFechado(0, '', 'willmount', [], this.props.verificaAtendenteVeSomenteProprioAtendimento, this.props.contaPrincipal);
    }
    componentWillUnmount() {/* 
        const chaveUnica2 = JSON.parse(localStorage.getItem('chaveUnicaCliente'));
        if (chaveUnica2) {
            const chaveUnica = chaveUnica2.replace(/"/g, "");
            firebase.database().ref(`${chaveUnica}/chamados`).off("value");
        } else {

        } */

    }
    updateSearch = search => {
        let state = this.state;
        state.search = search;
        this.setState({ state });

        // this.searchText(search)
    };
   

      renderCarregaMaisResultados() {
        if (this.props.loadingMaisResultadosChamados) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                    <MoonLoader
                        css={{ margin: 10 }}
                        size={40}
                        color={this.props.corBotaoPadrao}
                        loading={true}
                    />
                </div>
            )
        } else {
            if (this.props.referenciaUltimaKey != '' && this.props.referenciaUltimaKey != undefined) {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',cursor:'pointer',height: 30, margin: 20, backgroundColor: this.props.backgroundSegundo, border: `1px solid ${this.props.coresLetraPadrao}`, width: 200, justifyContent: 'center', alignItems: 'center'
                        }} onClick={() => {
                            this.props.listaOsChamados(1, this.props.referenciaUltimaKey, this.props.chamados, this.props.chamadosEmAtendimentos, this.props.chamadosFechado, this.props.chamadosCancelados, this.props.chamadoTodos);
                        }}><p style={{ fontSize: 12, fontWeight: "bold", color: this.props.coresLetraPadrao, fontFamily: 'Source Sans Pro' }}>CARREGAR MAIS CHAMADOS</p></div>
                    </div>
                )

            } else {
                return (
                    null
                )
            }
        }
    }
  
    renderLoadingTelaFechado() {
        if (this.props.loadingTelaFechado) {
            return (
                <div style={{ flex: 1,display:'flex',alignItems:'center',flexDirection:'column'  }}>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                    <ContainerChamadoLoading type="button" backgroundinamico={this.props.backgroundMaisEscuro}>
                        <ContainerImagemLoading>
                            <ImageLoading backgroundinamico={this.props.backgroundMaisEscuro}></ImageLoading>
                        </ContainerImagemLoading>
                        <ContainerDadosChamado>
                            <ContaineDadosChamadosPrincipal>
                                <ContainerIconeEFrase>
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>


                                <ContainerIconeEFrase >
                                    {/*   <i className="icon-comment2" style={{ color: this.props.coresLetraPadrao, fontSize: 11 }} /> */}
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextProblemaLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextProblemaLoading>
                                </ContainerIconeEFrase>

                            </ContaineDadosChamadosPrincipal>
                            <ContaineDadosChamadosSecundario>
                                <ContainerIconeEFrase >

                                    <TextClienteLoading style={{ marginLeft: 4 }} backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>

                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                                <ContainerIconeEFrase >
                                    <TextClienteLoading backgroundinamico={this.props.backgroundMaisEscuro}></TextClienteLoading>
                                </ContainerIconeEFrase>
                            </ContaineDadosChamadosSecundario>
                        </ContainerDadosChamado>
                    </ContainerChamadoLoading>
                </div>
            )
        } else {
            return (
                <Container>
                    
                    
                    <FlatList
                        list={this.props.chamadosFechado}
                        renderItem={(item) => <FlatListChamadoFechado data={item} key={item.key + item.alterado} />}
                        renderWhenEmpty={() =>  {
                            if (this.props.filtroSelecionadoFechado !== '' ||  this.state.search !== '') {
                                return (
                                    <ContainerListaVazia>
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum registro encontrado</p>
                                    </ContainerListaVazia>
                                )
                            } else {
                                return (
                                    <ContainerListaVazia >
                                        <i className="icon-box" style={{ color: this.props.coresLetraPadrao, fontSize: 110 }} />
                                        <p style={{ color: this.props.coresLetraPadrao, fontSize: 20 }}>Nenhum Chamado Fechado</p>
                                    </ContainerListaVazia>
                                )
                            }
                        }}
                      /*   hasMoreItems={this.props.loadingFimDaPaginaFechado}
                        loadMoreItems={() => this.exibeUltimosChamadosAbertos()}
                        paginationLoadingIndicator={<MoonLoader
                            css={{ margin: 10 }}
                            size={40}
                            color={this.props.corBotaoPadrao}
                            loading={this.props.loadingFimDaPaginaFechado}
                        />} */
                    //sortBy={["firstName", { key: "lastName", descending: true }]}
                    // groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                    />
                        {this.renderCarregaMaisResultados()}
                </Container>
            )
        }
    }

    render() {


        return (
            <>
                {this.renderLoadingTelaFechado()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return (
        {
            backgroundPrimeiro: state.CoresPadraoSistema.backgroundPrimeiro,
            backgroundSegundo: state.CoresPadraoSistema.backgroundSegundo,
            backgroundMaisEscuro: state.CoresPadraoSistema.backgroundMaisEscuro,
            coresLetraPadrao: state.CoresPadraoSistema.coresLetraPadrao,
            corBotaoPadrao: state.CoresPadraoSistema.corBotaoPadrao,
            corSegundoBotaoPadrao: state.CoresPadraoSistema.corSegundoBotaoPadrao,
            letraBotaoPadrao: state.CoresPadraoSistema.letraBotaoPadrao,
            nomeRota: state.HeaderReducer.nomeRota,
            testePesquisa: state.HeaderReducer.testePesquisa,
            email: state.LoginReducer.email,

            chamadosCancelados: state.ChamadoReducer.chamadosCancelados,
            chamadosEmAtendimentos: state.ChamadoReducer.chamadosEmAtendimentos,
            chamadosFechado: state.ChamadoReducer.chamadosFechado,
            chamadoTodos: state.ChamadoReducer.chamadoTodos,
            chamados: state.ChamadoReducer.chamados,
            loadingMaisResultadosChamados: state.ChamadoReducer.loadingMaisResultadosChamados,
            referenciaUltimaKey: state.ChamadoReducer.referenciaUltimaKey,

            verificaConexaoInternetListaChamadoFechado: state.ChamadoReducer.verificaConexaoInternetListaChamadoFechado,
            loadingTelaFechado: state.ChamadoReducer.loadingTelaFechado,
            refreshControlFlatListChamadoFechado: state.ChamadoReducer.refreshControlFlatListChamadoFechado,
            referenciaUltimaKeyFechado: state.ChamadoReducer.referenciaUltimaKeyFechado,
            loadingFimDaPaginaFechado: state.ChamadoReducer.loadingFimDaPaginaFechado,
            premium: state.PremiumReducer.premium,
            verificaAgrupaAtendenteSetor: state.ChamadoReducer.verificaAgrupaAtendenteSetor,
            verificaAtendenteVeSomenteProprioAtendimento: state.ChamadoReducer.verificaAtendenteVeSomenteProprioAtendimento,
            contaPrincipal: state.AtendenteReducer.contaPrincipal,
            filtroChamado: state.ChamadoReducer.filtroChamado,
            chamadosComFiltro: state.ChamadoReducer.chamadosComFiltro,
            filtroSelecionadoFechado: state.ChamadoReducer.filtroSelecionadoFechado,
            selectTextChamado: state.ChamadoReducer.selectTextChamado,
            pesquisaTextChamado: state.ChamadoReducer.pesquisaTextChamado,
            licensawebouapp: state.PremiumReducer.licensawebouapp,
        }
    );
};
export default connect(mapStateToProps, {
    modificaPesquisaTeste, zeraMensagemSemInternetChamadoAberto,
    navegaTelaNovoChamado, modificaFiltroChamado, modificaSelectTextChamado, modificaPesquisaTextChamado,
    ativaLoadingTelaAberturaChamadoRemoveFiltro, modificaFiltroSelecionadoTelaChamadoAberto, modificaFiltroSelecionadoTelaChamadoCancelado,
    modificaFiltroSelecionadoTelaChamadoEmAtendimento, modificaFiltroSelecionadoTelaChamadoFechado, modificaFiltroSelecionadoTelaChamadoTodos,

    ativaDesativaLoadingFinalFlatListChamadoFechadoExport,listaOsChamados
})(ChamadoFechado);
