import {
    MODIFICANOMESETOR, MODIFICAOBSERVACAO, ATIVALOADINGBOTAOCADASTRASETOR, MENSAGEMCADASTRASETORERROR,
    MENSAGEMCADASTRASETORSUCESSO, ZERAINFORMACOESCADASTROSETOR, MODIFICADIGITOCONSULTASETOR, ENVIADADOSPARASETORREDUCER,
    ZERAMENSAGEMSUCESSOERRROSETOR, ALTERAOBSSETORTELAALTERAR, ALTERANOMESETORTELAALTERAR, ATIVALOADINGBOTAOALTERARSETOR,
    MENSAGEMALTERASETORERROR, MENSAGEMALTERASETORSUCESSO, ALTERAKEYSETORTELAALTERAR, SEMCONEXAOINTERNETCONSULTASETOR,
    SEMCONEXAOINTERNETCADASTRASETOR, SEMCONEXAOINTERNETALTERASETOR, ATIVADESATIVALOADINGTELACONSULTASETOR,
    ENVIAULTIMAREFERENCIAKEYSETOR, ATIVADESATIVALOADINGFIMDAPAGINACONSULTASETOR, ATIVADESATIVALOADINGLISTASETORSECTIONEDTELACONSULTASETOR,
    ENVIASETORPARASECTIONED, MODIFICASETORSELECIONADOSECTIONED, MODIFICANOMESETORSELECIONADOSECTIONED,
    ATIVADESATIVALOADINGREFRESHCONTROLCONSULTASETOR, MUDAVISIBILIDADEMODALEXCLUIRSETOR, ATIVADESATIVALOADINGCONTEUDOEXCLUIRSETOR,
    ATIVADESATIVALOADINGVINCULAATENDENTESETOR, ENVIAATENDENTESECTIONEDVINCULAATENDENTESETOR, MODIFICAATENDENTEVINCULADOSELECIONADOSETOR,
    MODIFICAFILTROSETORESTELASETORES, MODIFICAVISIBLEMODALNOVOSETOR,
    MODIFICANOMESETORNOVOCADASTRO, MODIFICAOBSERVACAONOVOCADASTRO, MODIFICAATENDENTEVINCULADOSELECIONADOSETORNOVOCADASTRO
} from '../actions/types';

const INITIAL_STATE = {
    setores: [],
    nomeSetor: '',
    observacaoSetor: '',
    mensagemErroCadastrarSetor: '',
    mensagemSucessoCadastrarSetor: '',
    mensagemErroAlterarSetor: '',
    mensagemSucessoAlterarSetor: '',
    inputDigitoConsultaSetor: '',
    loadingBotaoCadastraSetor: false,
    loadingBotaoAlterarSetor: false,
    backgroundMensagemErrorTelaCadastraSetor: null,
    backgroundMensagemErrorTelaAlterarSetor: null,
    keySetor: '',
    verificaConexaoInternetConsultaSetor: '',
    verificaConexaoInternetCadastraSetor: '',
    verificaConexaoInternetAlteraSetor: '',
    loadingConsultaSetor: false,
    referenciaUltimaKeySetor: '',
    loadingFimDaPaginaConsultaSetor: false,
    loadingCarregarSetoresSectionedConsultaSetores: false,
    setoresSectionedConsulta: [],
    setorSelecionadosectioned: '',
    nomeSetoresSelecinadoSectioned: '',
    refreshControlConsultaSetor: false,
    visibleModalExcluirSetor: false,
    loadingConteudoExcluirSetor: false,
    atendentesVinculadosSetor: [],
    loadingSectionedAtendenteVinculadoSetor: true,
    atendenteVinculadoSelecionado: [],
    filtroSetor: '',
    modalNovoSetor: false,

    nomeSetorNovoCadastro: '',
    observacaoSetorNovoCadastro: '',
    atendenteVinculadoSelecionadoNovoCadastro: ''

};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MODIFICANOMESETOR:
            return { ...state, nomeSetor: action.payload };
        case MODIFICAOBSERVACAO:
            return { ...state, observacaoSetor: action.payload };
        case ATIVALOADINGBOTAOCADASTRASETOR:
            return { ...state, loadingBotaoCadastraSetor: action.payload};
        case MENSAGEMCADASTRASETORERROR:
            return { ...state, backgroundMensagemErrorTelaCadastraSetor: '#DC143C', loadingBotaoCadastraSetor: false, mensagemSucessoCadastrarSetor: '', mensagemErroCadastrarSetor: action.payload };
        case MENSAGEMCADASTRASETORSUCESSO:
            return { ...state, backgroundMensagemErrorTelaCadastraSetor: '#00FF7F', loadingBotaoCadastraSetor: false, mensagemErroCadastrarSetor: '', mensagemSucessoCadastrarSetor: action.payload };
        case ZERAINFORMACOESCADASTROSETOR:
            return { ...state, nomeSetorNovoCadastro: '', observacaoSetorNovoCadastro: '', mensagemErroCadastrarSetor: '', atendenteVinculadoSelecionadoNovoCadastro: '' };
        case MODIFICADIGITOCONSULTASETOR:
            return { ...state, inputDigitoConsultaSetor: action.payload };
        case ENVIADADOSPARASETORREDUCER:
            return { ...state, setores: [...action.payload] };
        case ZERAMENSAGEMSUCESSOERRROSETOR:
            return {
                ...state, mensagemErroCadastrarSetor: '', backgroundMensagemErrorTelaCadastraSetor: null,
                nomeSetor: '', observacaoSetor: '', mensagemSucessoCadastrarSetor: '', atendenteVinculadoSelecionado: []
            };
        case ALTERANOMESETORTELAALTERAR:
            return { ...state, nomeSetor: action.payload };
        case ALTERAOBSSETORTELAALTERAR:
            return { ...state, observacaoSetor: action.payload };
        case ATIVALOADINGBOTAOALTERARSETOR:
            return { ...state, loadingBotaoAlterarSetor: action.payload }
        case MENSAGEMALTERASETORERROR:
            return { ...state, mensagemErroAlterarSetor: action.payload, loadingBotaoAlterarSetor: false, mensagemSucessoAlterarSetor: '', backgroundMensagemErrorTelaAlterarSetor: '#DC143C' }
        case MENSAGEMALTERASETORSUCESSO:
            return { ...state, mensagemSucessoAlterarSetor: action.payload, loadingBotaoAlterarSetor: false, mensagemErroAlterarSetor: '', backgroundMensagemErrorTelaAlterarSetor: null }
        case ALTERAKEYSETORTELAALTERAR:
            return { ...state, keySetor: action.payload }
        case SEMCONEXAOINTERNETCONSULTASETOR:
            return { ...state, verificaConexaoInternetConsultaSetor: action.payload }
        case SEMCONEXAOINTERNETCADASTRASETOR:
            return { ...state, verificaConexaoInternetCadastraSetor: action.payload }
        case SEMCONEXAOINTERNETALTERASETOR:
            return { ...state, verificaConexaoInternetAlteraSetor: action.payload }
        case ATIVADESATIVALOADINGTELACONSULTASETOR:
            return { ...state, loadingConsultaSetor: action.payload }
        case ENVIAULTIMAREFERENCIAKEYSETOR:
            return { ...state, referenciaUltimaKeySetor: action.payload }
        case ATIVADESATIVALOADINGFIMDAPAGINACONSULTASETOR:
            return { ...state, loadingFimDaPaginaConsultaSetor: action.payload }
        case ATIVADESATIVALOADINGLISTASETORSECTIONEDTELACONSULTASETOR:
            return { ...state, loadingCarregarSetoresSectionedConsultaSetores: action.payload }

        case ENVIASETORPARASECTIONED:
            return { ...state, setoresSectionedConsulta: action.payload, loadingCarregarSetoresSectionedConsultaSetores: false };

        case MODIFICASETORSELECIONADOSECTIONED:
            return { ...state, setorSelecionadosectioned: action.payload }
        case MODIFICANOMESETORSELECIONADOSECTIONED:
            return { ...state, nomeSetoresSelecinadoSectioned: action.payload }
        case ATIVADESATIVALOADINGREFRESHCONTROLCONSULTASETOR:
            return { ...state, refreshControlConsultaSetor: action.payload }
        case MUDAVISIBILIDADEMODALEXCLUIRSETOR:
            return { ...state, visibleModalExcluirSetor: action.payload }
        case ATIVADESATIVALOADINGCONTEUDOEXCLUIRSETOR:
            return { ...state, loadingConteudoExcluirSetor: action.payload }
        case ATIVADESATIVALOADINGVINCULAATENDENTESETOR:
            return { ...state, loadingSectionedAtendenteVinculadoSetor: action.payload }
        case ENVIAATENDENTESECTIONEDVINCULAATENDENTESETOR:
            return { ...state, atendentesVinculadosSetor: action.payload }
        case MODIFICAATENDENTEVINCULADOSELECIONADOSETOR:
            return { ...state, atendenteVinculadoSelecionado: action.payload }

        case MODIFICAFILTROSETORESTELASETORES:
            return { ...state, filtroSetor: action.payload }

        case MODIFICAVISIBLEMODALNOVOSETOR:
            return { ...state, modalNovoSetor: action.payload }

        case MODIFICANOMESETORNOVOCADASTRO:
            return { ...state, nomeSetorNovoCadastro: action.payload }
        case MODIFICAOBSERVACAONOVOCADASTRO:
            return { ...state, observacaoSetorNovoCadastro: action.payload }
        case MODIFICAATENDENTEVINCULADOSELECIONADOSETORNOVOCADASTRO:
            return { ...state, atendenteVinculadoSelecionadoNovoCadastro: action.payload }
        default:
            return state;
    }
};